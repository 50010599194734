import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../../../components/Shared/Loader/Loader";
import { useTranslation } from "react-i18next";
import AddExtraInformationForm from "./AddExtraInformationDynamicType.js";
import Pagination from "../../../../components/Shared/Pagination/Pagination.jsx";
import { Card } from "../../../../components/card/card";
import ModalComponent from "../../../../components/Modal/ModalComponent";
import useGetDynamicForm from "../../../../hooks/Forms/useGetDynamicForm";
import Breadcrumb from "../../../../components/Shared/Breadcrumb";

// يستقبل الجدول الجاي منه مع الموديول والأسم
const ExtraInformationDynamicType = ({ moduleId, TableId, Name }) => {
  const [Show, setShow] = useState(false);
  const { t, i18n } = useTranslation();
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const Navigate = useNavigate();

  const { DynamicExtraType, loading } = useGetDynamicForm();

  //_________________________________________________________________________________________________

  const filtered = DynamicExtraType?.slice()
    ?.filter((item) => {
      const searchLowerCase = search.toLowerCase();
      return (
        item.name?.toLowerCase().includes(searchLowerCase) ||
        item.name2?.toLowerCase().toString().includes(searchLowerCase)
      );
    })
    ?.filter((item) => item.moduleId === moduleId);

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filtered?.slice(indexOfFirstData, indexOfLastData);

  //________________________________
  var BcrumbList = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: Name,
      link: null,
    },
  ];

  return (
    <>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName={Name} />

      <Card>
        <Loader loading={loading} />
        <div className="tab-content p-4">
          <div className="tab-pane fade show active">
            <div className="row">
              <div className="col-7 col-md-6 d-flex justify-content-start">
                <div className="input-group mb-4">
                  <div className="flex-fill position-relative">
                    <div className="input-group mb-4">
                      <div className="flex-fill position-relative">
                        <div className="input-group">
                          <div
                            className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                            style={{ zIndex: 1020, right: "10px" }}
                          >
                            <i className="fa fa-search opacity-5"></i>
                          </div>
                          <input
                            type="text"
                            className="form-control ps-35px"
                            placeholder={t("Search")}
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-5 col-md-6 text-end">
                <a
                  href="#/"
                  className="btn btn-outline-theme"
                  data-bs-toggle="modal"
                  data-bs-target="#modalAddForm"
                  onClick={() => setShow(true)}
                  // hidden={hiddenAddForm}
                >
                  <i className="fa fa-plus-circle fa-fw me-1"></i>
                  {t("AddForm")}
                </a>
              </div>
            </div>

            {/* //  START TABLE   */}
            <div className="table-responsive">
              <table className="table">
                <thead className="table-dark">
                  <tr>
                    <th scope="col">{t("Type")}</th>
                    <th scope="col">{t("Icon")}</th>

                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {currentData?.map((item, index) => (
                    <tr key={item.id}>
                      <td className="align-middle">
                        {i18n.language === "ar" ? item.name2 : item.name}
                      </td>
                      <td className="align-middle">
                        <div className="text-theme fs-5">
                          <i className={item.icon}></i>
                        </div>
                      </td>
                      <td>
                        <div className="btn-group">
                          <button
                            type="button"
                            //  hidden={hiddenAddFormColumn}
                            onClick={() =>
                              Navigate(`/NTX/Forms/${item.id}/AddFormColumns`)
                            }
                            className="btn btn-outline-lime"
                          >
                            <i className="fab fa-lg fa-fw me-2 fa-elementor"></i>
                            {t("fieldsForm")}
                          </button>

                          <button
                            type="button"
                            // hidden={hiddenEditForm}
                            onClick={() => Navigate(`${item.id}/Edit`)}
                            className="btn btn-outline-lime"
                          >
                            <i className="far fa-lg fa-fw me-2 fa-edit"></i>
                            {t("Button.Edit")}
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <Pagination
              filteredData={filtered}
              dataPerPage={dataPerPage}
              handlePagination={(pageNumber) => setCurrentPage(pageNumber)}
              currentPage={currentPage}
            />
          </div>
        </div>
      </Card>

      <ModalComponent title={`اضافة ${Name}`} id="modalAddForm">
        {Show && (
          <AddExtraInformationForm moduleId={moduleId} TableId={TableId} />
        )}
      </ModalComponent>
    </>
  );
};

export default ExtraInformationDynamicType;
