import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../../components/Shared/Loader/Loader';
// import { getPayrolls } from "../../../../store/Payroll/PayrollSlice.js";
import { useTranslation } from 'react-i18next';
import Pagination from '../../../../components/Shared/Pagination/Pagination.jsx';
import useLookupModule from '../../../../hooks/Lookups/use-Lookup-Module';
import { Card } from '../../../../components/card/card';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
// import { GetReportsList } from '../../../../store/NTX/Reports/reportSlice';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import {
  ChangeNotificationActive,
  GetNotificationList,
} from '../../../../store/NTX/Notifications/notificationSlice';
import showConfirmation from '../../../../components/Shared/Alert/Confirmation';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import { NotificationListModal } from '../../../../store/NTX/Notifications/notificationModels';
import Loading from '../../../../components/Shared/Loader/Loading';

interface LookupModule {
  Module: any[];
}

function Index() {
  const { t, i18n } = useTranslation();
  const [search, setSearch] = useState<string>('');
  // const [search, setSearch] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [dataPerPage] = useState<number>(10);
  const Navigate = useNavigate();
  const dispatch = useAppDispatch();

  //__________________________________________________
  const { NotificationsList, loading, changeActiveloading, error } = useAppSelector(
    (state) => state.Notifications
  );
  useEffect(() => {
    if (NotificationsList?.length === 0) {
      dispatch(GetNotificationList());
    }
  }, [dispatch]);

  const [selectedValue, setSelectedValue] = useState('');

  const { Module }: LookupModule = useLookupModule();

  //_________________________________________________________________________________________________

  const filteredSearch = NotificationsList?.slice()
    ?.filter((item) => {
      const searchLowerCase = search.toLowerCase();
      return (
        item.name?.toLowerCase().includes(searchLowerCase) ||
        item.name2?.toLowerCase().toString().includes(searchLowerCase)
      );
    })
    ?.filter((f) => (selectedValue === '' ? true : f.moduleId === selectedValue));

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);
  // NotificationListModal
  const handleChangeNotificationActive = async (item: NotificationListModal) => {
    let msg = item.isActive
      ? 'هل أنت متأكد من إيقاف تفعيل هذا الإشعار'
      : 'هل أنت متأكد من  تفعيل هذا الإشعار';
    showConfirmation(msg, async () => {
      try {
        const res = await dispatch(
          ChangeNotificationActive({ notificationId: item.notificationId })
        ).unwrap();

        if (res.succeeded === true) {
          CustomAlert({ action: 'Add', msg: 'نجحت العملية' });
          dispatch(GetNotificationList());
        } else {
          CustomAlert({ action: 'Error' });
        }
      } catch (err) {
        CustomAlert({ action: 'Error' });
        // handle error here
      }
    });
  };

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: t('إدارة الإشعارات'),
      link: null,
    },
  ];

  return (
    <>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName={t('إدارة الإشعارات')} />

        <div className="ms-auto">
          <button className="btn btn-theme" onClick={() => Navigate(`AddNotifications`)}>
            <i className="fa fa-plus-circle fa-fw me-1"></i>
            {t('إضافة إشعار جديـد')}
          </button>
        </div>
      </div>
      <Card>
        <Loading loading={loading} error={error}>
          <div className="tab-content p-4">
            <div className="row">
              <div className="col-7 col-md-6 d-flex justify-content-start">
                <div className="input-group ">
                  <div className="flex-fill position-relative">
                    <div className="input-group mb-4">
                      <select
                        className="btn btn-outline-default p-0 dropdown-toggle"
                        value={selectedValue}
                        onChange={(event) => setSelectedValue(event.target.value)}>
                        <option className="dropdown-item" value={''}>
                          {t('All')}
                        </option>
                        {Module &&
                          Module?.map((item) => (
                            <option value={item.id} key={item.id} className="dropdown-item">
                              {i18n.language === 'ar' ? item.name2 : item.name}
                            </option>
                          ))}
                      </select>

                      <div className="flex-fill position-relative">
                        <div className="input-group">
                          <div
                            className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                            style={{ zIndex: 1020, right: '10px' }}>
                            <i className="fa fa-search opacity-5"></i>
                          </div>
                          <input
                            type="text"
                            className="form-control ps-35px"
                            placeholder={t('Search')}
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-5 col-md-6 text-end"></div>
            </div>

            {/* //  START TABLE   */}
            <div className="table-responsive">
              <table className="table table-hover text-nowrap text-center ">
                <thead className="table-dark">
                  <tr>
                    <th scope="col">{t('اسم الإشعار')}</th>
                    <th scope="col">{t('ايقونة الإشعار')}</th>
                    <th scope="col">{t('نوع الإشعار')}</th>
                    <th scope="col">{t('حالة الإشعار')}</th>

                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {currentData?.map((item, index) => (
                    <tr key={item.notificationId}>
                      <td className="align-middle">
                        {i18n.language === 'ar' ? item.name2 : item.name}
                      </td>
                      <td className="align-middle">
                        <div className="text-theme fs-3">
                          <i className={item.icon}></i>
                        </div>
                      </td>

                      {/* <td className="align-middle">إشعار نظام</td> */}
                      <td className="align-middle">
                        <div className=" fs-3">
                          <i
                            className={`bi bi-envelope-at mx-2 text-${
                              item.sendEmail ? 'theme' : 'light  opacity-3'
                            }`}></i>

                          <i
                            className={`bi bi-chat-left-dots mx-2  text-${
                              item.sendSMS ? 'theme' : 'light  opacity-3'
                            }`}></i>

                          <i
                            className={`bi bi-whatsapp mx-2 text-${
                              item.sendWhatsApp ? 'theme' : 'light opacity-3'
                            }`}></i>

                          <i
                            className={`bi bi-bell mx-2 text-${
                              item.pushNotification ? 'theme' : 'light  opacity-3'
                            }`}></i>
                        </div>
                      </td>
                      <td className="align-middle ps-5">
                        {/* {item.isActive ? <span className="badge bg-success">Active</span> : <span className="badge bg-danger">not Active</span>} */}
                        {item.isActive ? (
                          <span className="text-center badge d-block rounded-0 pt-5px w-70px bg-success text-theme-900">
                            {t('isActive')}
                          </span>
                        ) : (
                          <span className=" text-center badge d-block rounded-0 pt-5px w-70px bg-secondary  ">
                            {t('InActive')}
                          </span>
                        )}
                      </td>
                      <td className="text-end ">
                        <div className="btn-group">
                          <button
                            disabled={changeActiveloading}
                            type="button"
                            className="btn btn-outline-theme"
                            onClick={() => handleChangeNotificationActive(item)}>
                            {changeActiveloading ? <div className=""></div> : null}
                            {item.isActive ? t('ايقاف الإشعار') : t('تفعيــل الإشعار')}
                          </button>
                          <button
                            className="btn btn-outline-theme"
                            // onClick={() => Navigate(`${item.notificationId}/UpdateNotifications`)}
                            onClick={() => {
                              const newLocation = `Notifications/${item.notificationId}/UpdateNotifications`;
                              window.location.href = newLocation;
                            }}>
                            <i className="  bi bi-pencil-fill fa-fw me-1"></i>
                            {t('تعديل الإشعار')}
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <Pagination
              filteredData={filteredSearch}
              dataPerPage={dataPerPage}
              handlePagination={(pageNumber: number) => setCurrentPage(pageNumber)}
              currentPage={currentPage}
            />
          </div>{' '}
        </Loading>
      </Card>
    </>
  );
}

export default Index;
