import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useTranslation } from 'react-i18next';
import useGetItemsList from '../../../../hooks/INV/MasterItems/useGetItemsList';
import Loading from '../../../../components/Shared/Loader/Loading';
import { Form } from 'react-bootstrap';
import useGetUnitsOfMeasureList from '../../../../hooks/INV/useGetUnitsOfMeasureList';
import useGetItemConversionList from '../../../../hooks/INV/ItemConversion/useGetItemConversionList';

interface LinesProps {
  data: any;
}

const Lines: React.FC<LinesProps> = ({ data }) => {
  const { i18n, t } = useTranslation();
  const { ItemsOptions, loading, error } = useGetItemsList();
  const { ItemConversionList, loading: loading2, error: error2 } = useGetItemConversionList();
  const { UnitsOfMeasureList: UomList } = useGetUnitsOfMeasureList();

  const GetUom = (line: any) => {
    const uomIdForItem = ItemsOptions?.find((f) => f.value === line?.invItemId)?.uom;
    // تصفية العناصر من قائمة التحويلات بناءً على itemId
    const filteredList = ItemConversionList?.filter((f) => f.itemId === line.invItemId);
    // العثور على الوحدة المقابلة من قائمة UomList بناءً على uomId
    const filteredList2 = UomList?.find((f) => f.id === uomIdForItem);

    // دمج القائمتين في قائمة ثالثة
    const combinedList = [...filteredList];

    // إذا كانت filteredList2 موجودة وغير موجودة في filteredList، يتم إضافتها إلى القائمة
    if (filteredList2 && !filteredList.some((f) => f.uom === filteredList2.id)) {
      combinedList.push({
        id: filteredList2.id,
        itemId: line.invItemId,
        uom: filteredList2.id,
        conversionValue: 1, // أو أي قيمة افتراضية مناسبة للتحويل
        barcode: '',
        uomName: filteredList2.name,
        uomName2: filteredList2.name2,
      });
    }

    return combinedList;
  };

  const calculateTotalSum = () => {
    let total = data?.reduce((sum: any, line: any) => sum + line.total, 0);
    const formattedTotal = new Intl.NumberFormat('en-US').format(total);
    return formattedTotal;
  };

  // console.log(data);

  return (
    <>
      <Loading loading={loading} error={error} Type="Dots">
        <>
          <div className="row">
            <div className="col-12 col-md-3 mb-2">
              <div className="rounded-2 p-2 bg-inverse bg-opacity-10">
                <div className="d-flex justify-content-between">
                  <div className="fw-bold">{t('Total')}:</div>
                  <div className="text-theme fs-5 fw-bold">{calculateTotalSum()}</div>
                </div>
              </div>
            </div>

            <div className="col-lg-3"></div>
            <div className="col-lg-6 text-end "></div>
          </div>

          <div className="">
            <div className={`table-responsive rounded-2 text-center  `}>
              {/* <PerfectScrollbar
                  style={{ overflow: 'visible' }}
                  option={{ suppressScrollX: false, suppressScrollY: true }}> */}
              <div style={{ width: '180%' }}>
                <table className="table table-borderless text-nowrap   mb-2">
                  <thead className="bg-theme bg-opacity-30">
                    <tr>
                      <th className="align-middle  small" style={{ width: '2%' }}>
                        #
                      </th>
                      <th className="align-middle  small" style={{ width: '6%' }}>
                        النوع
                      </th>

                      <th className="align-middle  small" style={{ width: '21%' }}>
                        {t('الوصف')}
                      </th>
                      <th className="align-middle  small" style={{ width: '6%' }}>
                        {t('الوحدة')}
                      </th>
                      <th className="align-middle  small" style={{ width: '4%' }}>
                        {t('الكمية')}
                      </th>
                      <th className="align-middle  small" style={{ width: '4%' }}>
                        {t('السعر')}
                      </th>
                      <th className="align-middle  small" style={{ width: '4%' }}>
                        {t('نسبة الخصم')}
                      </th>
                      <th className="align-middle  small" style={{ width: '4%' }}>
                        {t('قيمة الخصم')}
                      </th>
                      <th className="align-middle  small" style={{ width: '4%' }}>
                        VAT
                        <br />%
                      </th>
                      <th className="align-middle  small" style={{ width: '4%' }}>
                        {t('VAT')}
                      </th>

                      <th className="align-middle small" style={{ width: '6%' }}>
                        {t('القيمة')}
                      </th>
                      {/* <th className="align-middle small" style={{ width: '24%' }}>
                          {t('الوصف')}
                        </th> */}
                      <th className="align-middle small" style={{ width: '10%' }}>
                        {t('الحســـاب')}
                      </th>
                    </tr>
                  </thead>

                  <tbody className="fw-bold">
                    {data?.map((line: any, index: any) => {
                      return (
                        <>
                          <tr key={index}>
                            <td className="align-middle small">{line?.lineNumber}</td>
                            <td className="align-middle small ">
                              <Form.Select
                                className="form-select"
                                name="lineType"
                                value={line.lineType || ''}>
                                <option value="Item_Inv">
                                  {i18n.language === 'en' ? 'Item Inv' : 'صنف مخزني'}
                                </option>

                                <option value="Non_Inv">
                                  {i18n.language === 'en' ? 'non inventory item' : 'صنف غير مخزني'}
                                </option>
                                {/* <option value="Item">
                                      {i18n.language === 'en' ? 'Service  Item' : 'صنف خدمات'}
                                    </option> */}
                                {/* <option value="Tax">
                                      {i18n.language === 'en' ? 'Tax' : 'ضريبة'}
                                    </option> */}
                                <option value="Expenses">
                                  {i18n.language === 'en' ? 'Expenses' : 'مصروفات'}
                                </option>
                              </Form.Select>
                            </td>

                            <td className={`align-middle small`}>
                              <Form.Control
                                disabled={line.lineType === 'Item_Inv'}
                                className={`form-control  `}
                                as="textarea"
                                rows={1}
                                value={line.description || ''}
                              />
                            </td>

                            <td className={`align-middle small `}>
                              <select className={`form-select text-center`} value={line.uom || ''}>
                                <option value="">
                                  {i18n.language === 'ar' ? 'إختر' : 'Choose'}
                                </option>

                                {UomList?.map((item, idx) => (
                                  <option key={++idx} value={item.id}>
                                    {i18n.language === 'en' ? item.name : item.name2}
                                  </option>
                                ))}
                              </select>
                            </td>

                            <td className={`align-middle small  `}>
                              <input
                                type="text"
                                className={`form-control text-center px-0  `}
                                value={line.qty}
                              />
                            </td>

                            <td className={`align-middle small`}>
                              <input
                                type="text"
                                className={`form-control text-center px-0 `}
                                value={line.price}
                              />
                            </td>

                            <td className={`align-middle small`}>
                              <input
                                type="text"
                                className={`form-control text-center px-0 `}
                                value={line.discountRate}
                              />
                            </td>

                            <td className={`align-middle small`}>{line.discountAmount}</td>

                            <td className={`align-middle small`}>
                              <input
                                type="text"
                                className={`form-control text-center px-0  `}
                                value={line.vatRate}
                              />
                            </td>

                            <td className={`align-middle smal  `}>{line.vatAmount}</td>

                            <td className="align-middle small">
                              <input
                                type="text"
                                className="form-control text-center px-0"
                                value={line.total}
                              />
                            </td>

                            <td className="align-middle small">
                              <Form.Control
                                readOnly
                                className="form-control text-center"
                                type="text"
                                value={line.codeCombinationId || ''}
                              />
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              {/* </PerfectScrollbar> */}
            </div>
          </div>
        </>
      </Loading>
    </>
  );
};

export default Lines;
