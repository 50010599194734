import React, { useState } from 'react';
// import Select from 'react-select';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import Loading from '../../../../components/Shared/Loader/Loading';
import { Form } from 'react-bootstrap';
import NeatixModal from '../../../../components/Modal/NeatixModal';
import CodeCombination from './CodeCombination';
import PopoverCustom from '../../../../components/Shared/Popover/PopoverCustom';
import useGetCostCenters from '../../../../hooks/Finance/ChartAccountsDetails/useGetCostCenters';
import useGetCompanies from '../../../../hooks/Finance/ChartAccountsDetails/useGetCompanies';
import useGetBranches from '../../../../hooks/Finance/ChartAccountsDetails/useGetBranches';
import useGetAccounts from '../../../../hooks/Finance/ChartAccountsDetails/useGetAccounts';
import SwalAlert from '../../../../components/Shared/Alert/SwalAlert';

interface LinesProps {
  formik: FormikProps<any>;
}

const ApInvoiceItemsLines: React.FC<LinesProps> = ({ formik }) => {
  const { values } = formik;
  const { OptionsCostCenters } = useGetCostCenters();
  const { OptionsCompanies } = useGetCompanies();
  const { OptionsBranches } = useGetBranches();
  const { OptionsAccounts } = useGetAccounts();
  const [shawModalAcaount, setShawModalAcaount] = useState(false);
  const [selectedLineIndex, setSelectedLineIndex] = useState<number | null>(null);

  const { i18n, t } = useTranslation();

  // console.log(values.includeVatFlag);

  const handleLineChange = (index: number, field: string, value: any) => {
    const updatedLines = [...values.apInvoiceLinesList];
    const line = updatedLines[index];

    updatedLines[index] = {
      ...line,
      [field]: value,
    };

    if (updatedLines[index].lineType === 'Item') {
      // إعادة حساب القيم تلقائيا عند تغيير الكمية، السعر، نسبة الخصم
      const qty = Number(updatedLines[index].qty);
      const price = Number(updatedLines[index].price);
      const discountRate = Number(updatedLines[index].discountRate);
      const vatRate = Number(updatedLines[index].vatRate);

      // حساب قيمة الخصم
      const discountAmount = (price * qty * discountRate) / 100;

      // حساب الإجمالي بعد الخصم
      const total = price * qty - discountAmount;

      // حساب ضريبة القيمة المضافة ❤
      const vatAmount = (total * vatRate) / 100;

      // تحديث السطر بالقيم المحسوبة
      updatedLines[index] = {
        ...updatedLines[index],
        discountAmount,
        total,
        vatAmount,
      };
    }

    // تحديث القيم في النموذج
    formik?.setFieldValue('apInvoiceLinesList', updatedLines);
  };

  const handleAddLine = () => {
    const newLine = {
      lineType: 'Item',
      id: null,
      lineNumber: values.apInvoiceLinesList?.length + 1,
      invItemId: null,
      uom: '',
      price: 0,
      qty: 0,
      discountRate: 0,
      discountAmount: 0,
      vatRate: 15,
      total: 0,
      vatAmount: 0,
      description: '',
      currencyCode: '',
      currencyConversionRate: 1,
      purHeaderId: null,
      purLineId: null,
      accountId: null,
      costCenterId: null,
      //
      companyId: '', // غير موجود في البوست
      branchId: '', // غير موجود في البوست
      codeCombination: '', // غير موجود في البوست
    };
    formik.setFieldValue('apInvoiceLinesList', [...formik.values.apInvoiceLinesList, newLine]);
  };

  const handleDeleteLine = (index: number) => {
    const updatedLines = values.apInvoiceLinesList?.filter((_: any, i: number) => i !== index);
    formik?.setFieldValue('apInvoiceLinesList', updatedLines);
  };

  const calculateTotalSum = () => {
    let total = values.apInvoiceLinesList?.reduce((sum: any, line: any) => sum + line.total, 0);
    const formattedTotal = new Intl.NumberFormat('en-US').format(total);
    return formattedTotal;
  };

  const [accountDetails, setAccountDetails] = useState({
    companyId: '',
    branchId: '',
    costCenterId: '',
    accountId: '',
  });

  const handleAccountClick = (index: number) => {
    if (!values.organizationId) {
      SwalAlert({ text: 'حدد الفرع' });
      return;
    }

    const selectedLine = formik.values.apInvoiceLinesList[index];
    // إعداد القيم لتمريرها إلى المكون
    const companyId = selectedLine.companyId || '';
    const branchId = selectedLine.branchId || '';
    const accountId = selectedLine.accountId || '';
    const costCenterId = selectedLine.costCenterId || '';

    // تمرير القيم إلى المكون وفتح الـ Modal
    setSelectedLineIndex(index);
    setShawModalAcaount(true);
    setAccountDetails({ accountId, costCenterId, companyId, branchId });
  };

  const handleAccountSelect = (account: any) => {
    if (selectedLineIndex !== null) {
      const updatedLines = [...values.apInvoiceLinesList];

      updatedLines[selectedLineIndex].companyId = account?.company?.id || '';
      updatedLines[selectedLineIndex].branchId = account?.branch?.id || '';
      updatedLines[selectedLineIndex].costCenterId = account?.costCenter?.id || '';
      updatedLines[selectedLineIndex].accountId = account?.account?.id || '';

      // var codeCombination = `${account?.company?.accountNumber} | ${account?.branch?.accountNumber} |${account?.costCenter?.accountNumber} |${account?.account?.accountNumber}`;
      const codeCombination = [
        account?.company?.accountNumber,
        account?.branch?.accountNumber,
        account?.costCenter?.accountNumber,
        account?.account?.accountNumber,
      ]
        .filter(Boolean)
        .join('  -  ');

      updatedLines[selectedLineIndex].codeCombination = codeCombination || '';

      formik.setFieldValue('apInvoiceLinesList', updatedLines);
    }
    setShawModalAcaount(false); // Close modal after selection
  };

  const handleShowAccountDetails = (index: number) => {
    const selectedLine = values.apInvoiceLinesList[index];
    const { companyId, branchId, accountId, costCenterId } = selectedLine;

    return (
      <div className="text-start">
        <p className="m-0">{OptionsCompanies?.find((f) => f.value === companyId)?.accountShow}</p>
        <p className="m-0">{OptionsBranches?.find((f) => f.value === branchId)?.accountShow}</p>
        <p className="m-0">{OptionsAccounts?.find((f) => f.value === accountId)?.accountShow}</p>
        <p className="m-0">
          {OptionsCostCenters?.find((f) => f.value === costCenterId)?.accountShow}
        </p>
      </div>
    );
  };

  //_________________

  return (
    <>
      <Loading loading={false} error={null} Type="Dots">
        <>
          <div className="row">
            <div className="col-12 col-md-3 mb-2">
              <div className="rounded-2 p-2 bg-inverse bg-opacity-10">
                <div className="d-flex justify-content-between">
                  <div className="fw-bold">{t('Total')}:</div>
                  <div className="text-theme fs-5 fw-bold">{calculateTotalSum()}</div>
                </div>
              </div>
            </div>

            <div className="col-lg-3"></div>
            <div className="col-lg-6 text-end ">
              <div className="text-end mb-2">
                <button type="button" className="btn btn-outline-theme  " onClick={handleAddLine}>
                  {/* <i className="bi bi-plus-square-dotted fa-lg me-2"></i> */}
                  {t('إضافة صنف')}
                </button>
              </div>
            </div>
          </div>

          {values.apInvoiceLinesList?.length > 0 && (
            <div className="">
              <div
                className={`table-responsive rounded-2 text-center ${
                  formik.errors.apInvoiceLinesList ? ' border-bottom border-2 border-danger' : ''
                } `}>
                {/* <PerfectScrollbar
                  style={{ overflow: 'visible' }}
                  option={{ suppressScrollX: false, suppressScrollY: true }}> */}
                  <div style={{ width: '180%' }}>
                    <table className="table table-borderless text-nowrap   mb-2">
                      <thead className="bg-theme bg-opacity-30">
                        <tr>
                          <th className="align-middle  small" style={{ width: '2%' }}>
                            #
                          </th>
                          <th className="align-middle  small" style={{ width: '6%' }}>
                            النوع
                          </th>

                          <th className="align-middle  small" style={{ width: '21%' }}>
                            {t('الوصف')}
                          </th>

                          <th className="align-middle  small" style={{ width: '4%' }}>
                            {t('الكمية')}
                          </th>
                          <th className="align-middle  small" style={{ width: '4%' }}>
                            {t('السعر')}
                          </th>
                          <th className="align-middle  small" style={{ width: '4%' }}>
                            {t('نسبة الخصم')}
                          </th>
                          <th className="align-middle  small" style={{ width: '4%' }}>
                            {t('قيمة الخصم')}
                          </th>
                          {values.includeVatFlag && (
                            <>
                              <th className="align-middle  small" style={{ width: '4%' }}>
                                VAT
                                %
                              </th>
                              <th className="align-middle  small" style={{ width: '4%' }}>
                                {t('VAT')}
                              </th>
                            </>
                          )}
                          <th className="align-middle small" style={{ width: '6%' }}>
                            {t('القيمة')}
                          </th>
                          {/* <th className="align-middle small" style={{ width: '24%' }}>
                          {t('الوصف')}
                        </th> */}
                          <th className="align-middle small" style={{ width: '10%' }}>
                            {t('الحســـاب')}
                          </th>
                          <th className="align-middle text-end" style={{ width: '2%' }}></th>
                        </tr>
                      </thead>

                      <tbody className="fw-bold">
                        {values.apInvoiceLinesList
                          ?.slice()

                          ?.map((line: any, index: any) => {
                            const Index = index;

                            return (
                              <>
                                <tr key={index}>
                                  <td className="align-middle small">{index + 1}</td>
                                  <td className="align-middle small ">
                                    <Form.Select
                                      className="form-select"
                                      name="lineType"
                                      value={line.lineType || ''}
                                      onChange={(e) =>
                                        handleLineChange(Index, 'lineType', e.target.value)
                                      }>
                                      <option value="Item">
                                        {i18n.language === 'en' ? 'Item' : 'صنف'}
                                      </option>

                                      <option value="Tax">
                                        {i18n.language === 'en' ? 'Tax' : 'ضريبة'}
                                      </option>
                                      <option value="Expenses">
                                        {i18n.language === 'en' ? 'Expenses' : 'مصروفات'}
                                      </option>
                                    </Form.Select>
                                  </td>

                                  <td className={`align-middle small`}>
                                    <Form.Control
                                      className={`form-control`}
                                      as="textarea"
                                      rows={1}
                                      value={line.description || ''}
                                      onChange={(e) => {
                                        handleLineChange(Index, 'description', e.target.value);
                                      }}
                                    />
                                  </td>

                                  <td className={`align-middle small  `}>
                                    <input
                                      // disabled={line.lineType !== 'Item_Inv'}
                                      type="text"
                                      className={`form-control text-center px-0  `}
                                      value={line.qty}
                                      onChange={(e) =>
                                        handleLineChange(Index, 'qty', Number(e.target.value))
                                      }
                                      onInput={(e) => {
                                        const input = e.target as HTMLInputElement;
                                        input.value = input.value.replace(/[^0-9]/g, '');
                                      }}
                                    />
                                  </td>

                                  <td className={`align-middle small`}>
                                    <input
                                      // disabled={line.lineType !== 'Item_Inv'}
                                      type="text"
                                      className={`form-control text-center px-0 `}
                                      value={line.price}
                                      onChange={(e) =>
                                        handleLineChange(Index, 'price', Number(e.target.value))
                                      }
                                      onInput={(e) => {
                                        const input = e.target as HTMLInputElement;
                                        input.value = input.value.replace(/[^0-9]/g, '');
                                      }}
                                    />
                                  </td>

                                  <td className={`align-middle small`}>
                                    <input
                                      // disabled={line.lineType !== 'Item_Inv'}
                                      type="text"
                                      className={`form-control text-center px-0 `}
                                      value={line.discountRate}
                                      onChange={(e) =>
                                        handleLineChange(
                                          Index,
                                          'discountRate',
                                          Number(e.target.value)
                                        )
                                      }
                                      onInput={(e) => {
                                        const input = e.target as HTMLInputElement;
                                        input.value = input.value.replace(/[^0-9]/g, '');
                                      }}
                                    />
                                  </td>

                                  <td className={`align-middle small`}>{line.discountAmount}</td>

                                  {values.includeVatFlag && (
                                    <>
                                      <td className={`align-middle small`}>
                                        <input
                                          // disabled={line.lineType !== 'Item_Inv'}
                                          type="text"
                                          className={`form-control text-center px-0  `}
                                          value={line.vatRate}
                                          onChange={(e) =>
                                            handleLineChange(
                                              Index,
                                              'vatRate',
                                              Number(e.target.value)
                                            )
                                          }
                                          onInput={(e) => {
                                            const input = e.target as HTMLInputElement;
                                            input.value = input.value.replace(/[^0-9]/g, '');
                                          }}
                                        />
                                      </td>
                                      <td className={`align-middle smal  `}>{line.vatAmount}</td>
                                    </>
                                  )}

                                  <td className="align-middle small">
                                    <input
                                      type="text"
                                      className="form-control text-center px-0"
                                      value={line.total}
                                      onChange={(e) =>
                                        handleLineChange(Index, 'total', Number(e.target.value))
                                      }
                                      onInput={(e) => {
                                        const input = e.target as HTMLInputElement;
                                        input.value = input.value.replace(/[^0-9]/g, '');
                                      }}
                                    />
                                  </td>

                                  <td className="align-middle small">
                                    {line.codeCombination ? (
                                      <PopoverCustom
                                        childern={
                                          <Form.Control
                                            readOnly
                                            className="form-control text-center"
                                            type="text"
                                            // onChange={() => true}
                                            onClick={() => handleAccountClick(index)}
                                            value={line.codeCombination || ''}
                                          />
                                        }
                                        Header="تفاصيل الحـــساب"
                                        Body={handleShowAccountDetails(index)}
                                      />
                                    ) : (
                                      <Form.Control
                                        readOnly
                                        className="form-control text-center"
                                        type="text"
                                        // onChange={() => true}
                                        onClick={() => handleAccountClick(index)}
                                        value={line.codeCombination || ''}
                                      />
                                    )}
                                  </td>

                                  <td className="align-middle small">
                                    <button
                                      type="button"
                                      className="btn btn-outline-danger"
                                      onClick={() => handleDeleteLine(Index)}>
                                      <i className="fas fa-trash-alt"></i>
                                    </button>
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                {/* </PerfectScrollbar> */}
              </div>
            </div>
          )}
        </>
      </Loading>

      <NeatixModal
        show={shawModalAcaount}
        setShow={setShawModalAcaount}
        size="md"
        title="الحســــاب"
        headerClassName="bg-theme bg-opacity-25 rounded-top-3 p-2"
        bodyClassName="p-0">
        <div className="p-2">
          <CodeCombination
            onAccountSelect={handleAccountSelect}
            accountDetails={accountDetails}
            organizationId={values?.organizationId} // الفرع
          />
        </div>
      </NeatixModal>
    </>
  );
};

export default ApInvoiceItemsLines;
