import React, { useEffect, useState } from 'react';
import useGetNtxSettings from '../../../hooks/NTX/useGetNtxSettings';
import { SettingsList } from '../../../store/NTX/Settings/settingsModels';
import { useTranslation } from 'react-i18next';
import CustomAlert from '../../../components/Shared/Alert/CustomAlert';
import { useAppDispatch } from '../../../store/hooks';
import {
  GetNtxSettingsList,
  UpdateNtxSettingValue,
} from '../../../store/NTX/Settings/settingsSlice';
import { Form } from 'react-bootstrap';

interface props {
  settingId: string;
  settingType: 'radio' | 'text';
  onClose:()=> void;
}

const UpdateNtxSettings: React.FC<props> = (props) => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  console.log(props.settingType);

  const [settingState, setSettingState] = useState<SettingsList[]>([]);
  const [selectedValue, setSelectedValue] = useState<string | null>(null);
  const [textValue, setTextValue] = useState<string>(''); // For text input value

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { SettingsLiat } = useGetNtxSettings();

  useEffect(() => {
    var state = SettingsLiat?.filter((f) => f.id === props.settingId);
    setSettingState(state);
  }, [SettingsLiat, props.settingId]);

  // useEffect(() => {
  //   var value: any = settingState[0]?.getFlexValueList?.find(
  //     (f) => f.id?.toLowerCase() === settingState[0]?.value?.toLowerCase()
  //   )?.id;
  //   setSelectedValue(value);
  // }, [settingState]);

  useEffect(() => {
    if (props.settingType === 'radio') {
      const value: any = settingState[0]?.getFlexValueList?.find(
        (f) => f.id?.toLowerCase() === settingState[0]?.value?.toLowerCase()
      )?.id;
      setSelectedValue(value);
    } else if (props.settingType === 'text') {
      setTextValue(settingState[0]?.value || '');
    }
  }, [settingState, props.settingType]);

  const submitNtxSetting = () => {
    try {
      setIsSubmitting(true);

      const settingValue = props.settingType === 'radio' ? selectedValue : textValue;

      dispatch(
        UpdateNtxSettingValue({
          organizationSettingId: settingState[0].id,
          // settingValue: selectedValue,
          settingValue,
        })
      )
        .unwrap()
        .then((res) => {
          if (res?.succeeded === true) {
            CustomAlert({ action: 'Edit', msg: 'تم تعديل الإعداد بنجاح' });
            dispatch(GetNtxSettingsList());
            document.getElementById('closeModalForm')?.click();
            setIsSubmitting(false);
          } else {
            setIsSubmitting(false);
            CustomAlert({ action: 'Error', msg: res?.message });
          }
        })
        .catch((error: any) => {
          CustomAlert({ action: 'Error' });
        });
    } catch (err: any) {}
  };

  return (
    <>
      <div className="text-center bg-inverse bg-opacity-10 rounded-0 p-2 fw-bold fs-5 mb-2">
        {i18n.language === 'ar'
          ? SettingsLiat?.find((f) => f.id === props.settingId)?.name2
          : SettingsLiat?.find((f) => f.id === props.settingId)?.name}
      </div>
      {props.settingType === 'text' ? (
        <Form.Control
          className="form-control"
          type="text"
          value={textValue}
          onChange={(e) => setTextValue(e.target.value)}
        />
      ) : (
        <>
          <hr className="mt-2 mb-2" />
          {settingState &&
            settingState?.map((item) =>
              item.getFlexValueList?.map((value) => (
                <div key={value.id}>
                  <div className="row align-items-center">
                    <div className="col-8 pt-1 pb-1">
                      {i18n.language === 'ar' ? value.name2 : value.name}
                    </div>
                    <div className="col-4 d-flex align-items-center">
                      <div className="form-check form-switch ms-auto">
                        <input
                          type="radio"
                          className="form-check-input me-2"
                          name={value.id}
                          checked={value.id === selectedValue}
                          onChange={(e) => setSelectedValue(e.target.name)}
                        />
                        <label className="form-check-label" htmlFor="shippingFree">
                          &nbsp;
                        </label>
                      </div>
                    </div>
                  </div>

                  <hr className="mt-2 mb-2" />
                </div>
              ))
            )}
        </>
      )}

      <div className="row mt-2">
        <div className="col-lg-6">
          <button
            disabled={isSubmitting}
            type="button"
            className="btn btn-theme w-100"
            onClick={() => submitNtxSetting()}>
            {isSubmitting ? (
              <div className="spinner-border spinner-border-sm me-2"></div>
            ) : (
              <i className="fa fa-save fa-fw me-1"></i>
            )}
            {t('Save')}
          </button>
        </div>
        <div className="col-lg-6">
          <button
            type="button"
            className=" btn btn-default w-100"
        
            onClick={props.onClose}>
            <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
          </button>
        </div>
      </div>
    </>
  );
};

export default UpdateNtxSettings;
