import React from 'react';
import useGetInstallmentPaymentById from '../../../../hooks/SU/useGetInstallmentPaymentById';
import { Form } from 'react-bootstrap';
import useGetBanks from '../../../../hooks/Lookups/use-get-Banks';
import useLookupCurrency from '../../../../hooks/Lookups/use-Lookup-Currency';
import useGetPaymentMethod from '../../../../hooks/Payrolls/use-Get-PaymentMethod';
import { useTranslation } from 'react-i18next';
import FileList from '../../../Attachments/FileList';
import Loader from '../../../../components/Shared/Loader/Loader';

interface props {
  ID: string;
}

const ViewPaymentDetails: React.FC<props> = (props) => {
  const { t, i18n } = useTranslation();
  const { Banks } = useGetBanks();
  const { Currency } = useLookupCurrency();
  const { PaymentMethodList } = useGetPaymentMethod();

  const { InstallmentPaymentById: data, loading2 } = useGetInstallmentPaymentById(props.ID);

  console.log(data);

  return (
    <div>
      <Loader loading={loading2} />
      <div className="table-responsive rounded-3 mb-2 text-center">
        <table className="table  table-sm table-bordered">
          <thead className="bg-inverse bg-opacity-10 ">
            <tr>
              <th className="pt-2 pb-2">رقم العقد</th>
              <th className="pt-2 pb-2">رقم الدفعة</th>
              <th className="pt-2 pb-2">{t('Date')}</th>
              <th className="pt-2 pb-2">{t('InstallmentValue')}</th>
              {/* <th className="pt-2 pb-2">{t("TypeInstallment")}</th> */}
              <th className="pt-2 pb-2">{t('Date_from')}</th>
              <th className="pt-2 pb-2">{t('End_Date')}</th>
            </tr>
          </thead>

          <tbody>
            {data &&
              data?.paymentDetailsList?.map((item: any) => (
                <tr key={item.id}>
                  <td className="align-middle">{data?.contrcatCode}</td>
                  <td className="align-middle">{item.installmentNo}</td>
                  <td className="align-middle">{item.installmentDate}</td>
                  <td className="align-middle">{item.installmentValue}</td>
                  {/* <td className="align-middle">
                {item.contractInstallmentType === 4
                        ? "تأمين"
                        : item.contractInstallmentType === 2
                        ? "خدمات"
                        : item.contractInstallmentType === 3
                        ? "سعي المكتب"
                        : "قسط عادي"}
                  </td> */}
                  <td className="align-middle">{item.installmentDateForm}</td>
                  <td className="align-middle">{item.installmentDateTo}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <div className="row text-center">
        <div className="col-xl-4 mx-xl-auto ">
          <div className="form-group mb-3">
            <label className="form-label">{t('Total')}</label>
            <Form.Control
              className="form-control text-center"
              type="number"
              readOnly
              value={data?.amount}
            />
          </div>
        </div>
        <div className="col-xl-4 mx-xl-auto">
          <div className="form-group mb-3">
            <label className="form-label">{t('PaymentDate')}</label>
            <Form.Control
              className="form-control text-center"
              type="date"
              readOnly
              value={data?.paymentDate}
            />
          </div>
        </div>
        <div className="col-xl-4">
          <div className="form-group mb-3">
            <label className="form-label">{t('PaymentMethod')}</label>
            <Form.Select
              className="form-control text-center"
              value={data?.paymentMethodId}
              disabled>
              {PaymentMethodList?.map((item: any, idx: any) => (
                <option key={++idx} value={item.id}>
                  {i18n.language === 'ar' ? item.name : item.name2}
                </option>
              ))}
            </Form.Select>
          </div>
        </div>
      </div>
      <div className="row text-center">
        <div className="col-xl-4 mx-xl-auto">
          <div className="form-group mb-3">
            <label className="form-label">{t('Name of the bank')}</label>
            <Form.Select className="form-select text-center" value={data?.bankId} disabled>
              {Banks &&
                Banks?.map((item: any, idx: any) => (
                  <option key={++idx} value={item?.id}>
                    {i18n.language === 'ar' ? item?.name2 : item?.name}
                  </option>
                ))}
            </Form.Select>
          </div>
        </div>
        <div className="col-xl-4">
          <div className="form-group mb-3">
            <label className="form-label">{t('Currency')}</label>
            <Form.Select className="form-select text-center" value={data?.currencyCode} disabled>
              <option value="" key={null}>
                {i18n.language === 'ar' ? 'إختر' : 'Choose'}
              </option>
              {Currency?.map((item: any) => (
                <option key={item.currencyCode} value={item.currencyCode}>
                  {item.description}
                </option>
              ))}
            </Form.Select>
          </div>
        </div>
        <div className="col-xl-4">
          <div className="form-group mb-3">
            <label className="form-label">{t('BankReference')}</label>
            <Form.Control
              className="form-control text-center"
              type="text"
              value={data?.bankReference}
              disabled
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-5 mx-xl-auto">
          <FileList Attachments={data?.attachments} />
        </div>
      </div>

      <hr />
      <div className="text-center">
        <button
          type="button"
          className=" btn me-1 btn-default mb-1"
          data-bs-dismiss="modal"
          id="closeModalForm">
          <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
        </button>
      </div>
    </div>
  );
};

export default ViewPaymentDetails;
