import React, { useEffect } from "react";
import * as Yup from "yup";
import { Card, CardBody } from "../../../../components/card/card";
import Breadcrumb from "../../../../components/Shared/Breadcrumb";
import Loader from "../../../../components/Shared/Loader/Loader";
import { Form } from "react-bootstrap";
import { useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  AddUserRole,
  DeleteUserRole,
  GetRoleByUserId,
  GetRoleList,
} from "../../../../store/NTX/Security/Rules/RulesSlice";
import CustomAlert from "../../../../components/Shared/Alert/CustomAlert";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import showConfirmation from "../../../../components/Shared/Alert/Confirmation";

const RulesUser = () => {
  const { userId } = useParams<string>();
  const Navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const name = location.state;

  const { RoleByUserIdList, RulesList, loading } = useAppSelector(
    (state) => state.Rules
  );

  useEffect(() => {
    if (RulesList?.length === 0) {
      dispatch(GetRoleList());
    }
    if (userId) {
      dispatch(GetRoleByUserId(userId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik({
    initialValues: {
      userId: "",
      ruleId: "",
    },

    validationSchema: Yup.object().shape({
      ruleId: Yup.string()
        .notOneOf(["إختر", "Choose"], "Required")
        .required(() => t("Required")),
    }),
    onSubmit: (values) => {
      dispatch(
        AddUserRole({
          userId: userId,
          roleId: values.ruleId,
        })
      )
        .unwrap()
        .then((res) => {
          if (res.succeeded === true) {
            formik.resetForm();
            CustomAlert({ action: "Add" });
            dispatch(GetRoleByUserId(userId || ""));
          } else {
            CustomAlert({ action: "Error" });
          }
        })
        .catch((error) => {
          console.log(error);
          CustomAlert({ action: "Error" });
          formik.setSubmitting(false);
        });
    },
  });

  //_______________

  const handleDeleteUserRule = (id: any) => {
    showConfirmation(t("MsgConfirm"), () => {
      dispatch(DeleteUserRole({ roleId: id, userId: userId }))
        .unwrap()
        .then((res) => {
          if (res?.succeeded) {
            CustomAlert({ action: "Delete" });
            dispatch(GetRoleByUserId(userId || ""));
          } else {
            CustomAlert({ action: "Error" });
          }
        })
        .catch((error) => {
          console.log(error);
          CustomAlert({ action: "Error" });
        });
    });
  };
  //_______________
  var BcrumbList = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "UserManagement",
      link: "/Security/Users",
    },
    {
      name: "UserRules",
      link: null,
    },
  ];

  return (
    <div>
      <Breadcrumb
        BreadcrumbList={BcrumbList}
        PageName="UserRules"
        subtitle={name.name}
      />

      <Card>
        <CardBody>
          <Loader loading={loading} />
          <Form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-xl-6 m-auto">
                <div className="form-group mb-3">
                  <label className="form-label">{t("RulesPermissions")}</label>
                  <Form.Select
                    className="form-select"
                    name="ruleId"
                    onChange={formik.handleChange}
                    value={formik.values.ruleId}
                    isInvalid={
                      !!(formik.touched.ruleId && formik.errors.ruleId)
                    }
                  >
                    <option value={""}>
                      {i18n.language === "ar" ? "إختر" : "Choose"}
                    </option>
                    {RulesList &&
                      RulesList?.map((item, idx) => (
                        <option key={++idx} value={item.id}>
                          {i18n.language === "ar" ? item.name2 : item.name}
                        </option>
                      ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.ruleId}
                  </Form.Control.Feedback>
                </div>
              </div>
            </div>

            <div className="text-center">
              <button
                type="submit"
                className="btn me-1 btn-theme mb-1"
                disabled={formik.isSubmitting}
              >
                {formik.isSubmitting ? (
                  <div className="spinner-border spinner-border-sm me-2"></div>
                ) : (
                  <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
                )}
                {t("Add")}
              </button>
              <button
                type="button"
                className=" btn me-1 btn-default mb-1"
                onClick={() => Navigate("/Security/Users", { replace: true })}
              >
                <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
              </button>
            </div>
          </Form>

          <div className="mb-3" />

          <div className=" col-8 m-auto border border-theme rounded-2 pt-0">
            <div className="table-responsive rounded-2">
              <table className="table  table-hover text-nowrap mb-0 ">
                <thead className="bg-theme bg-opacity-60">
                  <tr>
                    <th scope="col">{t("Name")}</th>
                    <th scope="col">{t("Start Date")}</th>
                    <th scope="col">{t("End_Date")}</th>

                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {/* <Loading loading={loading} error={error}> */}
                  {RoleByUserIdList?.map((item, index) => (
                    <tr key={item.id}>
                      {/* <td className="align-middle">{++index}</td> */}
                      <td className="align-middle">
                        {i18n.language === "ar" ? item.name2 : item.name}
                      </td>

                      <td className="align-middle">{item.startDate}</td>
                      <td className="align-middle">{item.endDate}</td>

                      <td>

                        <button
                          type="button"
                          className="btn btn-outline-danger m-0"
                          onClick={() => handleDeleteUserRule(item.id)}
                        >
                          <i className="fas fa-trash-alt fa-fw fa-lg"></i>
                          {/* {t('Button.Delete')} */}
                        </button>
                      </td>
                    </tr>
                  ))}
                  {/* </Loading> */}
                </tbody>
              </table>
            </div>{" "}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default RulesUser;
