import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { GetCurrency } from '../../store/Lookup/lookupSlice';
import { useTranslation } from 'react-i18next';

const useLookupCurrency = () => {
  const dispatch = useDispatch();

  const {} = useTranslation();
  useEffect(() => {
    dispatch(GetCurrency());
  }, [dispatch]);

  const { Currency, loading, error } = useSelector((state) => state.Lookup);

  // const CurrencyList = Currency.map((m) => ({
  //   id: m.id,
  //   currencyCode: m.currencyCode,
  //   description: m,
  //   enabledFlag: '',
  // }));

  return { Currency, loading, error };
};

export default useLookupCurrency;
