import React, { useState } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import useGetItemsList from '../../../../../hooks/INV/MasterItems/useGetItemsList';
import { TransactionsLinesList } from '../../../../../store/Sales/SalesInvoices/SalesInvoicesModels';
import { useAppDispatch } from '../../../../../store/hooks';
 import Loading from '../../../../../components/Shared/Loader/Loading';
 import useGetUnitsOfMeasureList from '../../../../../hooks/INV/useGetUnitsOfMeasureList';

interface LinesProps {
  LinesList: any[];
}

const InvoiceLines: React.FC<LinesProps> = ({ LinesList }) => {
  //________________________________________________________________

  const { i18n, t } = useTranslation();
  const { ItemsOptions, loading, error } = useGetItemsList();
  const dispatch = useAppDispatch();

  const [Lines, setLines] = useState<TransactionsLinesList[]>(LinesList || []);
 
  const {UnitsOfMeasureList ,  loading: loading2, error: error2} = useGetUnitsOfMeasureList()


 

  return (
    <>
      <Loading loading={loading || loading2} error={error || error2}>
        <div className="mb-3">
          {/* <div className="row">
            <div className="col-lg-3  ">
              <div className="form-group mb-3 rounded-4 p-2 bg-inverse bg-opacity-10">
                <label className="fw-bold">{t('Total')} : </label>
                <div className="d-flex justify-content-center">
                  <div className="text-theme fs-4 fw-bold">{calculateTotalSum()}</div>
                </div>
              </div>
            </div>

            <div className="col-lg-3"></div>
            <div className="col-lg-6 text-end "></div>
          </div> */}

          {Lines?.length > 0 && (
            <div className="">
              <div className={`table-responsive rounded-2 text-center `}>
                <table className="table table-borderless  table-hover mb-0 ">
                  <thead className="bg-theme bg-opacity-30">
                    <tr>
                      <th className="align-middle" style={{ width: '2%' }}>
                        #
                      </th>
                      <th className="align-middle" style={{ width: '30%' }}>
                        {t('اسم الصنف')}
                      </th>
                      <th className="align-middle" style={{ width: '15%' }}>
                        {t('الوحدة')}
                      </th>
                      <th className="align-middle" style={{ width: '5%' }}>
                        {t('الكمية')}
                      </th>
                      <th className="align-middle" style={{ width: '5%' }}>
                        {t('السعر')}
                      </th>
                      <th className="align-middle" style={{ width: '5%' }}>
                        {t('نسبة الخصم')}
                      </th>
                      <th className="align-middle" style={{ width: '5%' }}>
                        {t('قيمة الخصم')}
                      </th>
                      <th className="align-middle" style={{ width: '5%' }}>
                        {t('VAT %')}
                      </th>
                      <th className="align-middle" style={{ width: '5%' }}>
                        {t('VAT')}
                      </th>
                      <th className="align-middle" style={{ width: '5%' }}>
                        {t('Total')}
                      </th>
                      <th className="align-middle" style={{ width: '20%' }}>
                        {t('الوصف')}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="fw-bold">
                    {Lines.slice()
                      .reverse()
                      ?.map((line, index) => {
                        const Index = Lines?.length - 1 - index;

                        return (
                          <tr key={index}>
                            <td className="align-middle bg-theme bg-opacity-30">
                              {Lines?.length - index}
                            </td>
                            <td className="align-middle">
                              <Select
                                isDisabled
                                classNamePrefix="react-select"
                                className="w-100"
                                isSearchable
                                isClearable
                                // options={ItemsOptions}
                                options={ItemsOptions?.filter(
                                  (f) => !Lines.some((line) => line.itemId === f.value)
                                )}
                                value={
                                  line.itemId === null
                                    ? null
                                    : ItemsOptions?.find((option) => option.value === line.itemId)
                                }
                                placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                                }}
                              />
                            </td>
                            <td className="align-middle">
                              <select
                                disabled
                                className="form-select text-center"
                                value={line.uom || ''}>
                                <option value="">
                                  {i18n.language === 'ar' ? 'إختر' : 'Choose'}
                                </option>
                                {UnitsOfMeasureList?.map(
                                  (item, idx) => (
                                    <option key={++idx} value={item.id}>
                                      {i18n.language === 'en' ? item.name : item.name2}
                                    </option>
                                  )
                                )}
                              </select>
                            </td>
                            <td className="align-middle">
                              <input
                                readOnly
                                type="text"
                                className="form-control text-center px-0"
                                value={line.qty}
                                onInput={(e) => {
                                  // إلغاء أي إدخال غير رقمي يدويًا
                                  const input = e.target as HTMLInputElement;
                                  input.value = input.value.replace(/[^0-9]/g, '');
                                }}
                              />
                            </td>
                            <td className="align-middle">
                              <input
                                readOnly
                                type="text"
                                className="form-control text-center px-0"
                                // size={line.price.toString()?.length || 1}
                                value={line.price}
                                onInput={(e) => {
                                  // إلغاء أي إدخال غير رقمي يدويًا
                                  const input = e.target as HTMLInputElement;
                                  input.value = input.value.replace(/[^0-9]/g, '');
                                }}
                              />
                            </td>
                            <td className="align-middle">
                              <input
                                readOnly
                                type="text"
                                className="form-control text-center px-0"
                                value={line.discountRate}
                                onInput={(e) => {
                                  // إلغاء أي إدخال غير رقمي يدويًا
                                  const input = e.target as HTMLInputElement;
                                  input.value = input.value.replace(/[^0-9]/g, '');
                                }}
                              />
                            </td>
                            <td className="align-middle">{line.discountAmount}</td>
                            <td className="align-middle">
                              <input
                                readOnly
                                type="text"
                                className="form-control text-center px-0"
                                value={line.vatRate}
                                onInput={(e) => {
                                  // إلغاء أي إدخال غير رقمي يدويًا
                                  const input = e.target as HTMLInputElement;
                                  input.value = input.value.replace(/[^0-9]/g, '');
                                }}
                              />
                            </td>
                            <td className="align-middle">{line.vatAmount}</td>
                            <td className="align-middle">{line.total}</td>
                            <td className="align-middle">
                              <input
                                readOnly
                                type="text"
                                className="form-control "
                                value={line.description}
                              />
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </Loading>
    </>
  );
};

export default InvoiceLines;
