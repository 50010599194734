import React, { useState } from 'react';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { Card, CardBody } from '../../../../components/card/card';
import { useTranslation } from 'react-i18next';
import Pagination from '../../../../components/Shared/Pagination/Pagination';
import { Link, useNavigate } from 'react-router-dom';
import Loading from '../../../../components/Shared/Loader/Loading';

import useGetPurchaseBillList from '../../../../hooks/PUR/useGetPurchaseBillList';
import useGetApInvoicesList from '../../../../hooks/Finance/ApInvoices/useGetApInvoicesList';
import NeatixModal from '../../../../components/Modal/NeatixModal';
import PurchaseRequestList from '../../../PUR/Transactions/PurchaseRequest/PurchaseRequestList';

const Index = () => {
  const [showPurchaseRequest, setShowPurchaseRequest] = useState(false);

  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const { t, i18n } = useTranslation();
  const Navigate = useNavigate();

  // const { PurchaseBillList, loading, error } = useGetPurchaseBillList();

  const { ApInvoicesList, loading, error } = useGetApInvoicesList();

  const filteredSearch = ApInvoicesList?.slice()?.filter((item) => {
    const searchLowerCase = search.toLowerCase();
    return (
      item.peopleName2?.toLowerCase().includes(searchLowerCase) ||
      item.peopleName?.toLowerCase().toString().includes(searchLowerCase)
    );
  });

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'Ar Transactions',
      link: null,
    },
  ];

  return (
    <div>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName="Ar Transactions" />

        <div className="ms-auto">
          <div className="btn-group" role="group" style={{ direction: 'ltr' }}>
            <button
              type="button"
              className="btn btn-theme dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false">
              {/* <i className="fa bi bi-printer-fill fa-fw ms-3 fs-5"></i> */}
              {t('_____')}
            </button>
            <ul className="dropdown-menu text-center">
              <li>
                <Link
                  className="dropdown-item text-start"
                  to="AddArTransactions"
                  // onClick={() => setShowPurchaseRequest(true)}
                  >
                  جديد
                  <i className="fa fa-plus fa-fw me-2 text-theme"></i>
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item text-start"
                  to=""
                  onClick={() => setShowPurchaseRequest(true)}
                  >
                  من طلب شراء
                  <i className="fa fa-cloud-download-alt fa-fw me-2 text-theme"></i>
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item text-start"
                  to=""
                  // onClick={() => setShowPurchaseQuotation(true)}
                >
                  من أمر شراء
                  <i className="fa fa-cloud-download-alt fa-fw me-2 text-theme"></i>
                </Link>
              </li>
            </ul>
          </div>
        </div>
        
        
        {/* <div className="ms-auto">
          <Link to="AddApInvoices" className="btn me-1 btn-outline-theme">
            <i className="fa fa-plus-circle fa-fw me-1"></i>
            Add ApInvoices
          </Link>
        </div> */}

      </div>
      <Card>
        <Loading loading={loading} error={error}>
          <CardBody>
            <div className=" p-2">
              <div className="row mb-2">
                <div className="col-lg-6">
                  <div className="input-group flex-nowrap">
                    <span className="input-group-text fw-bold">
                      {t('Search')}
                      {/* <i className="fa fa-search  text-theme ms-3"></i> */}
                    </span>
                    <div className="input-group">
                      <div className="flex-fill position-relative">
                        <div className="input-group border-0 ">
                          <div
                            className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                            style={{ zIndex: 1020, right: '10px' }}>
                            <i className="fa fa-search opacity-5 text-theme"></i>
                          </div>
                          <input
                            type="text"
                            className="form-control ps-35px"
                            // placeholder={t('Search')}
                            value={search}
                            onChange={(e) => {
                              setSearch(e.target.value);
                              setCurrentPage(1);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* //  START TABLE   */}
              <div className="table-responsive">
                <table className="table table-hover text-nowrap text-center">
                  <thead className="table-dark">
                    <tr>
                      <th scope="col">{t('__')}</th>
                      <th scope="col">{t('__')}</th>
                      <th scope="col">{t('__')}</th>
                      <th scope="col">{t('__')}</th>
                      <th scope="col">{t('__')}</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData?.map((item, index) => (
                      <tr key={item.id}>
                        <td className="align-middle"> {item.invoiceNum} </td>
                        <td className="align-middle"> {item.invoiceDate?.toString()} </td>
                        <td className="align-middle"> {item.invoiceType} </td>
                        <td className="align-middle"> {item.organizationId} </td>
                        {/* <td className="align-middle"> {item.dueDate?.toString()}</td> */}

                        <td className="text-end">
                          <button
                            type="button"
                            className="btn btn-outline-theme mx-1"
                            onClick={() => Navigate(`${item.id}/UpdatePurchaseBill`)}>
                            {/* <i className="far fa-lg fa-fw me-2 fa-edit"></i> */}
                            {t('عرض التفاصيل')}
                          </button>

                          {/* <DynamicPrint
                            PrintTemplateTypeId="868E12B7-A5D3-41F9-AA87-45BB552B3AAB" // قوالب عرض السعر
                            ReferenceId={item.id || ''}
                          /> */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Pagination
                filteredData={filteredSearch}
                dataPerPage={dataPerPage}
                handlePagination={(pageNumber: any) => setCurrentPage(pageNumber)}
                currentPage={currentPage}
              />
            </div>
          </CardBody>
        </Loading>
      </Card>

      <NeatixModal
        show={showPurchaseRequest}
        setShow={setShowPurchaseRequest}
        bodyClassName="p-0"
        size="lg"
        showHeader={false}>
        <PurchaseRequestList Route="ApInvoice" />
      </NeatixModal>
    </div>
  );
};

export default Index;
