import React, { useEffect, useState } from "react";
import { useAppDispatch } from "../../../../store/hooks";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import CustomAlert from "../../../../components/Shared/Alert/CustomAlert";
import { Form } from "react-bootstrap";
import { SourceModels } from "../../../../store/Finance/JeSource/SourceModels";
import useGetGlJeSourceList from "../../../../hooks/Finance/useGetGlJeSourceList";
import { GetGlJeSourceList, UpdatGlJeSource } from "../../../../store/Finance/JeSource/sourceSlice";

interface props {
  ID?: string;
}

const EditJeSource: React.FC<props> = ({ ID }) => {
  const { i18n, t } = useTranslation();
  const dispatch = useAppDispatch();
  const { GlJeSourceList } = useGetGlJeSourceList()
  const [ JeSourceById , setJeSourceById ] = useState<SourceModels>()

  useEffect(()=> {
    var dataById = GlJeSourceList?.find((f) => f.id === ID);
    setJeSourceById(dataById)
 
  },[GlJeSourceList, ID])
 
 
 

  const formik = useFormik({
    initialValues: {
      id: JeSourceById ? JeSourceById.id : "",
      name2: JeSourceById ? JeSourceById.name2 : "",
      name: JeSourceById ? JeSourceById.name : "",
      enabledFlag: JeSourceById ? JeSourceById.enabledFlag : false,

    },
    enableReinitialize:true ,
    //   validationSchema: AddEquipmentsModelFLeetSchema(t),
    onSubmit: (values) => {
      dispatch(
        UpdatGlJeSource({
          id : values.id , 
          name: values.name,
          name2: values.name2,
          enabledFlag: values.enabledFlag,
        })
      )
        .unwrap()
        .then((res: any) => {
          if (res?.succeeded === true) {
            CustomAlert({ action: "Edit" });
            document.getElementById("closeModalForm")?.click();
            formik.resetForm();
            dispatch(GetGlJeSourceList());
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: "Error", msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: "Error" });
        });
    },
  });

  return (
    <div>
      <Form onSubmit={formik.handleSubmit}>
      <div className="row">
          <div className="col-xl-5">
            <div className="form-group mb-3">
              <label className="form-label">{t("LocalName")}</label>
              <Form.Control
                className="form-control text-center"
                type="text"
                name="name2"
                onChange={formik.handleChange}
                value={formik.values.name2}
                isInvalid={!!(formik.touched.name2 && formik.errors.name2)}
              />
            </div>
          </div>

          <div className="col-xl-5">
            <div className="form-group mb-3">
              <label className="form-label">{t("GlobalName")}</label>
              <Form.Control
                className="form-control text-center"
                type="text"
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                isInvalid={!!(formik.touched.name && formik.errors.name)}
              />
            </div>
          </div>

          <div className="col-xl-2">
            <div className="form-group mb-3">
              <label className="form-label">{t("Active")}</label>
              <Form.Check
                style={{ margin: "10px 20px" }}
                type="switch"
                id="custom-switch"
                name="enabledFlag"
                // value={formik.values.enabledFlag}
                checked={formik.values.enabledFlag}
                onChange={formik.handleChange}
              />
            </div>
          </div>
        </div>

        <div className="text-center">
          <button
            disabled={formik.isSubmitting}
            type="submit"
            className="btn me-1 btn-outline-theme mb-1"
          >
            {formik.isSubmitting ? (
              <div className="spinner-border spinner-border-sm me-2"></div>
            ) : (
              <i className="fa fa-save fa-fw me-1"></i>
            )}
            {t("Save")}
          </button>
          <button
            type="button"
            className=" btn me-1 btn-outline-default mb-1"
            data-bs-dismiss="modal"
            id="closeModalForm"
          >
            <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
          </button>
        </div>
      </Form>
    </div>
  );
};

export default EditJeSource;
