import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ntxAPI } from '../../../../API/axiosNeatex';
import { ArTransactionsModels } from './ArTransactionsModels';
import { APIResponse } from '../../../Shared/shared';

export const addArTransactions = createAsyncThunk(
  'ArTransactions/addArTransactions',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post<APIResponse<any>>('Ar/AddArTransactions', item);
      if (data?.succeeded) {
        return data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const updatArTransactions = createAsyncThunk(
  'ArTransactions/updatArTransactions',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post<APIResponse<any>>('Ar/UpdatArTransactions', item);
      if (data?.succeeded) {
        return data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const addArReceiptSchedules = createAsyncThunk(
  'ArTransactions/addArReceiptSchedules',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post<APIResponse<any>>('Ar/AddArReceiptSchedules', item);
      if (data?.succeeded) {
        return data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getArTransactionsList = createAsyncThunk(
  'ArTransactions/getArTransactionsList',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get<APIResponse<ArTransactionsModels[]>>(
        'Ar/GetArTransactionsList'
      );
      if (data?.succeeded) {
        return data?.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getArTransactionsById = createAsyncThunk(
  'ArTransactions/getArTransactionsById',
  async (Id: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get<APIResponse<ArTransactionsModels>>(
        `Ar/GetArTransactionsById?Id=${Id}`
      );
      if (data?.succeeded) {
        return data?.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState: {
  ArTransactionsList: ArTransactionsModels[];
  ArTransactionsById: ArTransactionsModels;
  loading: boolean;
  error: any | null;
} = {
  ArTransactionsList: [],
  ArTransactionsById: {} as ArTransactionsModels,
  loading: false,
  error: null,
};

const ArReceiptsSlice = createSlice({
  name: 'ArTransactions',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder

      .addCase(getArTransactionsList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        getArTransactionsList.fulfilled,
        (state, action: PayloadAction<ArTransactionsModels[]>) => {
          state.loading = false;
          state.ArTransactionsList = action.payload;
        }
      )
      .addCase(getArTransactionsList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getArTransactionsById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        getArTransactionsById.fulfilled,
        (state, action: PayloadAction<ArTransactionsModels>) => {
          state.loading = false;
          state.ArTransactionsById = action.payload;
        }
      )
      .addCase(getArTransactionsById.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default ArReceiptsSlice.reducer;
