import { useFormik } from 'formik';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import Select from 'react-select';
import { Card, CardFooter } from '../../../../components/card/card';
import { CardBody, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useLookupCurrency from '../../../../hooks/Lookups/use-Lookup-Currency';
import useGetPersons from '../../../../hooks/HR/use-get-persons';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { AddInvoiceTransactions } from '../../../../store/Sales/SalesInvoices/salesInvoicesSlice';
import { useAppDispatch } from '../../../../store/hooks';
import useGetPaymentMethod from '../../../../hooks/Payrolls/use-Get-PaymentMethod';
import { AddInvoiceSalesSchema } from '../../../ValidationForm/validationSchema';
import SwalAlert from '../../../../components/Shared/Alert/SwalAlert';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../../../components/Shared/Loader/Loading';
import useGetQuotationSalesById from '../../../../hooks/Sales/useGetQuotationSalesById';
import { TreeSelect } from 'antd';
import useGetCustomersList from '../../../../hooks/NTX/Peoples/useGetCustomersList';
import useGetPeopleLocationsList from '../../../../hooks/NTX/Peoples/useGetPeopleLocationsList';
import SalesInvoicesLines from './SalesInvoicesLines';
import useGetPaymentTermsList from '../../../../hooks/Sales/useGetItemsList';
import useGetInventoryWithPermission from '../../../../hooks/NTX/Organization/useGetInventoryWithPermission';
import useGetBranchOrgWithPermission from '../../../../hooks/NTX/Organization/useGetBranchOrgWithPermission';

// type Props = {
//   quoteInvoiceId: string;
// };{quoteInvoiceId}: Props

const Add = () => {
  const { t, i18n } = useTranslation();
  const Navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { PaymentMethodList } = useGetPaymentMethod();
  const { Currency } = useLookupCurrency();
  const { dataEmployees, loading } = useGetPersons();
  const { CustomersList, treeCustomerData, loading: loading2 } = useGetCustomersList();
  const { PaymentTermsList, loading: loading4, error: error4 } = useGetPaymentTermsList();

  const { InventoryOptions, loading: loading3 } = useGetInventoryWithPermission();
  const { BranchOrgOptions: BranchsOptions, loading: loading5 } = useGetBranchOrgWithPermission();

  //#region useParams quoteInvoice
  const { id } = useParams();
  const { TransactionsQuotationById: data } = useGetQuotationSalesById({ id: id });
  //#endregion

  //#region Formik

  const formik = useFormik({
    initialValues: {
      no: 0, // رقم الفاتورة
      code: '',

      organizationId: data.organizationId ?? '', // الفروع
      peopleId: data.peopleId ?? '', //العميل
      recipientname: data.recipientname ?? '', // اسم العميل وممكن يتعدل
      billToLocationId: data.billToLocationId ?? null,
      shipToLocationId: data.shipToLocationId ?? null,
      referenceId: data.id ?? null,
      transactionDate: new Date().toISOString().split('T')[0],
      deliveryDate: '', //  تاريخ التوصبل
      expirationDate: '', //  تجاهلها
      dueDate: '', //  تاريخ الإستحقاق
      paymentMethodId: data.paymentMethodId ?? '',
      paymentTermsId: '', //  شروط الدفع .. الخاص بالعميل من جدول العميل تلقائيا
      description: data.description ?? '', //  وصف الفاتورة
      grossSale: data.grossSale ?? '', //
      discountRate: data.discountRate ?? '', //   نسبة الخصم
      discountAmount: data.discountAmount ?? '', //   مبلغ الخصم
      vat: data.vat ?? '', //   قيمة الضريبة
      netSale: data.netSale ?? '', //   الصافي مجموع اللاينس
      contractId: data.contractId ?? '', //   العقد
      ameStatus: '', //
      personId: data.personId ?? '', //   المندوب
      paymentTerms: '', //
      deliveryTerm: '', //
      inventoryId: data.inventoryId ?? '', //
      waybill: data.waybill ?? '', //
      phone: data.phone ?? '', //
      currencyCode: data.currencyCode ?? 'SR', //
      currencyConversionRate: data.currencyConversionRate ?? '', //
      batchId: '', //
      // transactionsLinesList: [] as TransactionsLinesList[],
      transactionsLinesList:
        data?.transactionsLinesList?.map((item) => ({
          id: item.id,
          // transactionId: item.transactionId,
          itemId: item.itemId,
          lineNumber: item.lineNumber,
          uom: item.uom,
          description: item.description,
          price: item.price,
          qty: item.qty,
          discountRate: item.discountRate,
          discountAmount: item.discountAmount,
          vatRate: item.vatRate,
          total: item.total,
          vatAmount: item.vatAmount,
          // unitCost: item.unitCost,
          // codeCombinationId: item.codeCombinationId,
          // currencyCode: item.currencyCode,
          // currencyConversionRate: item.currencyConversionRate,
          // lotId: item.lotId,
          // headerId: item.headerId,
          // lineId: item.lineId,
          inventoryId: item.inventoryId,
        })) ?? [],
    },

    enableReinitialize: true,
    validationSchema: AddInvoiceSalesSchema(t),
    onSubmit: (values) => {
      const grossSale = values.transactionsLinesList.reduce(
        (s, a) => Number(s) + Number(a.total),
        0
      );

      if (values.transactionsLinesList?.length === 0) {
        SwalAlert({ text: 'أدخل أصناف' });
        formik.setSubmitting(false);

        return;
      }

      dispatch(
        AddInvoiceTransactions({
          organizationId: values.organizationId,
          peopleId: values.peopleId,
          recipientname: values.recipientname,
          billToLocationId: values.billToLocationId,
          shipToLocationId: values.shipToLocationId,
          referenceId: values.referenceId,
          transactionDate: values.transactionDate,
          deliveryDate: values.deliveryDate,
          expirationDate: values.expirationDate || null,
          dueDate: values.dueDate,
          paymentTermsId: values.paymentTermsId,
          description: values.description,
          // grossSale: values.grossSale || 0,
          grossSale: grossSale,
          discountRate: values.discountRate || 0,
          discountAmount: values.discountAmount || 0,
          vat: values.vat || 0,
          netSale: values.netSale || 0,
          contractId: values.contractId || null,
          ameStatus: values.ameStatus || 0,
          personId: values.personId,
          paymentTerms: values.paymentTerms,
          deliveryTerm: values.deliveryTerm,
          inventoryId: values.inventoryId,
          waybill: values.waybill,
          phone: values.phone,
          currencyCode: values.currencyCode,
          currencyConversionRate: values.currencyConversionRate || 0,
          batchId: values.batchId || null,

          transactionsLinesList: values.transactionsLinesList?.map((item, index) => ({
            transactionId: null,
            itemId: item.itemId,
            lineNumber: ++index,
            // lineNumber: item.lineNumber,
            uom: item.uom,
            description: item.description,
            price: Number(item.price),
            qty: Number(item.qty),
            discountRate: Number(item.discountRate),
            discountAmount: Number(item.discountAmount),
            vatRate: Number(item.vatRate),
            total: Number(item.total),
            vatAmount: Number(item.vatAmount),
            // unitCost: Number(item.unitCost),
            // codeCombinationId: null,
            codeCombinationId: '54F915DF-8A22-486D-B860-08DC2A376B50',
            currencyCode: 'SR',
            currencyConversionRate: 0,
            lotId: null,
            headerId: null,
            lineId: null,
            inventoryId: values.inventoryId,
          })),
        })
      )
        .unwrap()
        .then((res: any) => {
          if (res?.succeeded === true) {
            CustomAlert({ action: 'Add' });
            Navigate('/SalesTransactions/SalesInvoices', { replace: true });
            formik.resetForm();
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error', msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });
  const { values, handleChange: formikhandleChange, errors, touched } = formik;
  //#endregion

  const { billToLocation, shipToLocation } = useGetPeopleLocationsList({
    peopleId: values.peopleId,
  });

  const CustomerName = CustomersList?.find((f) => f.id === values.peopleId);

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'فواتيـر مبيعات',
      link: '/SalesTransactions/SalesInvoices',
    },
    {
      name: 'إضافة فاتورة جديدة',
      link: null,
    },
  ];

  return (
    <>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName="إضافة فاتورة جديدة" />
        {Object.keys(data)?.length > 0 && (
          <div className="d-flex p-0 m-0 bg-theme bg-opacity-15 rounded-0 p-1">
            رقم المرجع : {data.code}
          </div>
        )}
      </div>

      <Card>
        <Loading loading={loading || loading2 || loading3}>
          <CardBody>
            <Form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('تاريخ الفاتورة')}</label>
                    <Form.Control
                      className="form-control  text-center"
                      type="date"
                      name="transactionDate"
                      onChange={formikhandleChange}
                      value={values.transactionDate || ''}
                      isInvalid={!!(touched.transactionDate && errors.transactionDate)}
                    />
                  </div>
                </div>

                <div className="col-lg-2">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('تاريخ الإستحقاق')}</label>
                    <Form.Control
                      className="form-control  text-center"
                      type="date"
                      name="dueDate"
                      onChange={formikhandleChange}
                      value={values.dueDate || ''}
                      isInvalid={!!(touched.dueDate && errors.dueDate)}
                      // isValid={formik.touched.dueDate && !formik.errors.dueDate}
                    />
                  </div>
                </div>

                <div className="col-lg-2">
                  <div className="form-group mb-3">
                    <label className="form-label"> {t('Currency')} </label>
                    <Form.Select
                      className="form-select"
                      name="currencyCode"
                      onChange={formikhandleChange}
                      value={values.currencyCode || ''}
                      // isInvalid={!!(touched.currencyCode && errors.currencyCode)}
                    >
                      <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>

                      {Currency &&
                        Currency?.map((item: any, idx: number) => (
                          <option key={++idx} value={item.currencyCode}>
                            {item.description}
                          </option>
                        ))}
                    </Form.Select>
                    <div className="text-danger">{formik.errors.currencyCode}</div>
                  </div>
                </div>

                <div className="col-xl-2">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('PaymentMethod')}</label>
                    <Form.Select
                      className="form-select"
                      name="paymentMethodId"
                      onChange={formik.handleChange}
                      value={formik.values.paymentMethodId}
                      isInvalid={
                        !!(formik.touched.paymentMethodId && formik.errors.paymentMethodId)
                      }>
                      <option key={null}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>

                      {PaymentMethodList?.map((item: any, idx: number) => (
                        <option key={++idx} value={item.id}>
                          {i18n.language === 'ar' ? item.name : item.name2}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('المخزن')}</label>

                    <Select
                      className={`${
                        formik.touched.inventoryId && formik.errors.inventoryId
                          ? 'border border-danger'
                          : ''
                      }`}
                      classNamePrefix="react-select"
                      isLoading={loading3}
                      isSearchable={true}
                      isClearable
                      options={InventoryOptions}
                      value={InventoryOptions?.find(
                        (f: any) => f.value === formik.values.inventoryId
                      )}
                      onChange={(option: any) => {
                        formik.setFieldValue('inventoryId', option === null ? null : option.value);
                      }}
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                    />
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('العميل')}</label>

                    <TreeSelect
                      showSearch
                      // value={value}
                      className="w-100"
                      treeLine
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                      allowClear
                      // treeDefaultExpandAll
                      onChange={(option: any) => {
                        formik.setFieldValue('peopleId', option === null ? null : option);
                      }}
                      treeData={treeCustomerData}
                      treeNodeFilterProp="title"
                    />
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label"> {t('موقع الشـحن')} </label>
                    <Form.Select
                      className="form-select"
                      name="shipToLocationId"
                      onChange={formikhandleChange}
                      value={values.shipToLocationId || ''}>
                      <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>

                      {shipToLocation &&
                        shipToLocation?.map((item: any, idx: number) => (
                          <option key={++idx} value={item.id}>
                            {i18n.language === 'en' ? item.name : item.name2}
                          </option>
                        ))}
                    </Form.Select>
                    <div className="text-danger">
                      {/* {formik.touched.currencyCode && formik.errors.currencyCode} */}
                    </div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label"> {t('موقع الفوترة')} </label>
                    <Form.Select
                      className="form-select"
                      name="billToLocationId"
                      onChange={formikhandleChange}
                      value={values.billToLocationId || ''}>
                      <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>

                      {billToLocation &&
                        billToLocation?.map((item: any, idx: number) => (
                          <option key={++idx} value={item.id}>
                            {i18n.language === 'en' ? item.name : item.name2}
                          </option>
                        ))}
                    </Form.Select>
                    <div className="text-danger">
                      {/* {formik.touched.currencyCode && formik.errors.currencyCode} */}
                    </div>
                  </div>
                </div>

                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('شروط الدفع')}</label>
                    <Form.Select
                      className="form-select"
                      name="paymentTermsId"
                      onChange={formik.handleChange}
                      value={formik.values.paymentTermsId}
                      isInvalid={!!(formik.touched.paymentTermsId && formik.errors.paymentTermsId)}>
                      <option key={null}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>

                      {PaymentTermsList?.map((item: any, idx: number) => (
                        <option key={++idx} value={item.id}>
                          {i18n.language === 'en  ' ? item.name : item.name2}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('الفرع')}</label>

                    <Select
                      className={`${
                        formik.touched.organizationId && formik.errors.organizationId
                          ? 'border border-danger'
                          : ''
                      }`}
                      classNamePrefix="react-select"
                      isLoading={loading3}
                      isSearchable={true}
                      isClearable
                      options={BranchsOptions}
                      value={BranchsOptions?.find(
                        (f: any) => f.value === formik.values.organizationId
                      )}
                      onChange={(option: any) => {
                        formik.setFieldValue(
                          'organizationId',
                          option === null ? null : option.value
                        );
                      }}
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                    />
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('مندوب المبيعات')}</label>
                    <Select
                      className={`${
                        formik.touched.personId && formik.errors.personId
                          ? 'border border-danger'
                          : ''
                      }`}
                      classNamePrefix="react-select"
                      isLoading={loading}
                      isSearchable={true}
                      isClearable
                      name="personId"
                      options={dataEmployees}
                      value={dataEmployees?.find((f: any) => f.value === formik.values.personId)}
                      onChange={(option: any) => {
                        formik.setFieldValue('personId', option === null ? null : option.value);
                      }}
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                    />
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('اسم العميل')}</label>
                    <Form.Control
                      autoComplete="off"
                      className="form-control text-center"
                      type="text"
                      name="recipientname"
                      onChange={formikhandleChange}
                      value={values.recipientname || CustomerName?.name}
                      isInvalid={!!(touched.recipientname && errors.recipientname)}
                    />
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('تاريخ التوصيل')}</label>
                    <Form.Control
                      className="form-control  text-center"
                      type="date"
                      name="deliveryDate"
                      onChange={formikhandleChange}
                      value={values.deliveryDate || ''}
                      isInvalid={!!(touched.deliveryDate && errors.deliveryDate)}
                    />
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('Note')}</label>
                    <Form.Control
                      autoComplete="off"
                      className="form-control"
                      as="textarea"
                      rows={1}
                      name="description"
                      onChange={formik.handleChange}
                      value={values.description}
                      isInvalid={!!(touched.description && errors.description)}
                    />
                  </div>
                </div>
              </div>

              <CardFooter className="px-0">
                <div className="row">
                  <SalesInvoicesLines formik={formik} />
                </div>
              </CardFooter>

              <div className="text-center mt-2">
                <button
                  disabled={formik.isSubmitting}
                  type="submit"
                  className="btn btn-lg me-1 btn-theme mb-1">
                  {formik.isSubmitting ? (
                    <div className="spinner-border spinner-border-sm me-2"></div>
                  ) : (
                    <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
                  )}
                  {t('Add')}
                </button>
                <button
                  type="button"
                  className=" btn btn-lg me-1 btn-default mb-1"
                  onClick={() => Navigate('/SalesTransactions/SalesInvoices', { replace: true })}>
                  <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
                </button>
              </div>
            </Form>
          </CardBody>
        </Loading>
      </Card>
    </>
  );
};

export default Add;
