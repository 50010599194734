import React, { useEffect, useRef } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import useGetItemsList from '../../../../hooks/INV/MasterItems/useGetItemsList';
import Loading from '../../../../components/Shared/Loader/Loading';
import { Form } from 'react-bootstrap';
import useGetUnitsOfMeasureList from '../../../../hooks/INV/useGetUnitsOfMeasureList';
import useGetItemConversionList from '../../../../hooks/INV/ItemConversion/useGetItemConversionList';

interface LinesProps {
  formik: FormikProps<any>;
  isUpdate?: boolean;
}

const PRLines: React.FC<LinesProps> = ({ formik, isUpdate = false }) => {
  const { values, errors, touched } = formik;
  //________________________________________________________________
  const { UnitsOfMeasureList: UomList } = useGetUnitsOfMeasureList();
  const { i18n, t } = useTranslation();
  const { ItemsOptions, loading, error } = useGetItemsList();
  const { ItemConversionList, loading: loading2,  } = useGetItemConversionList();

  //#region   // تحديث ارتفاع textarea بناءً على المحتوى
  const textareaRefs = useRef<(HTMLTextAreaElement | null)[]>([]);
  useEffect(() => {
    textareaRefs.current.forEach((textarea: any) => {
      if (textarea) {
        textarea.style.height = 'auto'; // إعادة التعيين للسماح بإعادة الحساب
        textarea.style.height = `${textarea.scrollHeight}px`; // تعيين الارتفاع بناءً على المحتوى
      }
    });
  }, [values.transactionsLinesList, ItemsOptions]);
  //#endregion

  //#region  تصفية الأصناف اذا تم تغيير الفئة
  useEffect(() => {
    if (!isUpdate) {
      formik?.setFieldValue('transactionsLinesList', [
        {
          type: 'Inv',
          itemId: null,
          lineNumber: values?.transactionsLinesList?.length + 1,
          uom: '',
          price: 0,
          qty: 0,
          discountRate: 0,
          discountAmount: 0,
          vatRate: 15,
          total: 0,
          vatAmount: 0,
          description: '',
          id: null, //
          inventoryId: '', //
        },
      ]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.categoryId]);
  //#endregion

  const GetUom = (line: any) => {
    const uomIdForItem = ItemsOptions?.find((f) => f.value === line?.itemId)?.uom;

    // تصفية العناصر من قائمة التحويلات بناءً على itemId
    const filteredList = ItemConversionList?.filter((f) => f.itemId === line.itemId);
    // العثور على الوحدة المقابلة من قائمة UomList بناءً على uomId
    const filteredList2 = UomList?.find((f) => f.id === uomIdForItem);

    // دمج القائمتين في قائمة ثالثة
    const combinedList = [...filteredList];

    // إذا كانت filteredList2 موجودة وغير موجودة في filteredList، يتم إضافتها إلى القائمة
    if (filteredList2 && !filteredList.some((f) => f.uom === filteredList2.id)) {
      combinedList.push({
        id: filteredList2.id,
        itemId: line.itemId,
        uom: filteredList2.id,
        conversionValue: 1, // أو أي قيمة افتراضية مناسبة للتحويل
        barcode: '',
        uomName: filteredList2.name,
        uomName2: filteredList2.name2,
      });
    }

    return combinedList;
  };

  
  const handleLineChange = (index: number, field: string, value: any) => {
    const updatedLines = [...values.transactionsLinesList];
 
    if (field === 'itemId') {
      if (updatedLines[index].type === 'Inv') {
        var desc = ItemsOptions?.find((f) => f.value === value)?.itemName;
      }
      updatedLines[index] = {
        ...updatedLines[index],
        itemId: value,
        description: desc,
        // uom: GetUom(updatedLines)[0],
      };
      formik?.setFieldValue('transactionsLinesList', updatedLines);
      return;
    }

    if (field === 'type') {
      if (value === 'Non_Inv') {
        updatedLines[index] = {
          ...updatedLines[index],
          type: value,
          itemId: null,
          description: '',
          uom: 'c0391001-ffc5-4a91-9470-cb9e8ac2a650', // حبة   Each
        };
        formik?.setFieldValue('transactionsLinesList', updatedLines);
        return;
      }
    }

    updatedLines[index] = {
      ...updatedLines[index],
      [field]: value,
    };

    formik?.setFieldValue('transactionsLinesList', updatedLines);
  };

  const handleAddLine = () => {
    const newLine = {
      type: 'Inv',
      itemId: null,
      lineNumber: values.transactionsLinesList?.length + 1,
      uom: '',
      price: 1,
      qty: 0,
      discountRate: 0,
      discountAmount: 0,
      vatRate: 15,
      total: 0,
      vatAmount: 0,
      description: '',
      id: null, //
      inventoryId: '', //
    };
    formik.setFieldValue('transactionsLinesList', [
      ...formik.values.transactionsLinesList,
      newLine,
    ]);
  };

  const handleDeleteLine = (index: number) => {
    const updatedLines = values.transactionsLinesList?.filter((_: any, i: number) => i !== index);
    formik?.setFieldValue('transactionsLinesList', updatedLines);
  };

 
  return (
    <>
      <Loading loading={loading || loading2} error={error } Type="Dots">
        <>
          <div className="row">
            <div className="col-12 col-md-3 mb-2"></div>

            <div className="col-lg-3"></div>
            <div className="col-lg-6 text-end ">
              <div className="text-end mb-2">
                <button type="button" className="btn btn-outline-theme  " onClick={handleAddLine}>
                  {/* <i className="bi bi-plus-square-dotted fa-lg me-2"></i> */}
                  {t('إضافة صنف')}
                </button>
              </div>
            </div>
          </div>

          {values.transactionsLinesList?.length > 0 && (
            <div className="">
              <div
                className={`table-responsive rounded-2 text-center ${
                  touched.transactionsLinesList && errors.transactionsLinesList
                    ? ' border-bottom border-2 border-danger'
                    : ''
                } `}>
                <table className="table table-borderless  text-nowrap  table-hover mb-0">
                  <thead className="bg-theme bg-opacity-30">
                    <tr>
                      <th className="align-middle  small" style={{ width: '2%' }}>
                        #
                      </th>
                      <th className="align-middle  small" style={{ width: '10%' }}>
                        {t('النوع')}
                      </th>

                      {/* <th className="align-middle  small" style={{ width: '18%' }}>
                        {t('اسم الصنف')}
                      </th> */}
                      <th className="align-middle small" style={{ width: '30%' }}>
                        {t('الوصف')}
                      </th>
                      <th className="align-middle  small" style={{ width: '10%' }}>
                        {t('الوحدة')}
                      </th>
                      <th className="align-middle  small" style={{ width: '5%' }}>
                        {t('الكمية')}
                      </th>

                      <th className="align-middle text-end" style={{ width: '2%' }}></th>
                    </tr>
                  </thead>

                  <tbody className="fw-bold">
                    {values?.transactionsLinesList
                      ?.slice()
                      .reverse()
                      ?.map((line: any, index: any) => {
                        const Index = values?.transactionsLinesList?.length - 1 - index;

                        return (
                          <tr key={index}>
                            <td className="align-middle bg-theme bg-opacity-30 small">
                              {values?.transactionsLinesList?.length - index}
                            </td>

                            <td className="align-middle small">
                              <select
                                className="form-select text-center"
                                value={line.type || ''}
                                onChange={(e) => handleLineChange(Index, 'type', e.target.value)}>
                                <option value="Inv">
                                  {i18n.language === 'en' ? 'inventory item' : 'صنف مخزني'}
                                </option>
                                <option value="Non_Inv">
                                  {i18n.language === 'en' ? 'non inventory item' : 'صنف غير مخزني'}
                                </option>
                              </select>
                            </td>

                            <td className="align-middle small">
                              {line.type === 'Inv' ? (
                                <Select
                                  isDisabled={line.type !== 'Inv'}
                                  classNamePrefix="react-select"
                                  className={`w-100 ${
                                    line.type !== 'Inv' && 'bg-inverse bg-opacity-10'
                                  } `}
                                  isSearchable
                                  isClearable
                                  options={ItemsOptions?.filter(
                                    (f) =>
                                      !values.transactionsLinesList?.some(
                                        (line: any) => line.itemId === f.value
                                      ) &&
                                      (formik.values.categoryId === null ||
                                        f.categoryId?.toLowerCase() ===
                                          formik.values.categoryId?.toLowerCase())
                                  )}
                                  onChange={(option) =>
                                    handleLineChange(
                                      Index,
                                      'itemId',
                                      option === null ? null : option.value
                                    )
                                  }
                                  value={
                                    line.itemId === null
                                      ? null
                                      : ItemsOptions?.find((option) => option.value === line.itemId)
                                  }
                                  placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                                  menuPortalTarget={document.body}
                                  styles={{
                                    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                                  }}
                                />
                              ) : (
                                <Form.Control
                                  disabled={line.type === 'Inv'}
                                  className={`form-control ${
                                    line.type === 'Inv' && 'bg-inverse bg-opacity-10'
                                  } `}
                                  as="textarea"
                                  rows={1}
                                  value={line.description}
                                  onChange={(e) => {
                                    handleLineChange(Index, 'description', e.target.value);
                                  }}
                                  onInput={(e) => {
                                    const textarea = e.target as HTMLTextAreaElement;
                                    textarea.style.height = 'auto'; // إعادة ضبط الارتفاع
                                    textarea.style.height = textarea.scrollHeight + 'px'; // تعيين الارتفاع حسب المحتوى
                                  }}
                                  ref={(el: any) => (textareaRefs.current[index] = el)}
                                  style={{ overflow: 'hidden', height: 'auto' }}
                                />
                              )}
                            </td>

                            <td className="align-middle small">
                              {line.type === 'Inv' ? (
                                <select
                                  disabled={line.type !== 'Inv'}
                                  className={`form-select text-center ${
                                    line.type !== 'Inv' && 'bg-inverse bg-opacity-10'
                                  } `}
                                  value={line.uom || ''}
                                  onChange={(e) => handleLineChange(Index, 'uom', e.target.value)}>
                                  <option value="">
                                    {i18n.language === 'ar' ? 'إختر' : 'Choose'}
                                  </option>

                                  {GetUom(line)?.map((item, idx) => (
                                    <option key={++idx} value={item.uom}>
                                      {i18n.language === 'en' ? item.uomName : item.uomName2}
                                    </option>
                                  ))}
                                </select>
                              ) : (
                                <select
                                  // disabled={line.type !== 'Inv'}
                                  className={`form-select text-center`}
                                  value={line.uom || ''}
                                  onChange={(e) => handleLineChange(Index, 'uom', e.target.value)}>
                                  <option value="">
                                    {i18n.language === 'ar' ? 'إختر' : 'Choose'}
                                  </option>

                                  {UomList?.map((item, idx) => (
                                    <option key={++idx} value={item.id}>
                                      {i18n.language === 'en' ? item.name : item.name2}
                                    </option>
                                  ))}
                                </select>
                              )}
                            </td>

                            <td className="align-middle small">
                              <input
                                type="text"
                                className="form-control text-center px-0"
                                value={line.qty}
                                onChange={(e) =>
                                  handleLineChange(Index, 'qty', Number(e.target.value))
                                }
                                onInput={(e) => {
                                  // إلغاء أي إدخال غير رقمي يدويًا
                                  const input = e.target as HTMLInputElement;
                                  input.value = input.value.replace(/[^0-9]/g, '');
                                }}
                              />
                            </td>

                            {/* <td className="align-middle small"></td> */}

                            <td className="align-middle small">
                              <button
                                type="button"
                                className="btn btn-outline-danger"
                                onClick={() => handleDeleteLine(Index)}>
                                <i className="fas fa-trash-alt"></i>
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </>
      </Loading>
    </>
  );
};

export default PRLines;
