import { useAppDispatch } from '../../../../../store/hooks';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import CustomAlert from '../../../../../components/Shared/Alert/CustomAlert';
import { Form } from 'react-bootstrap';
import Loading from '../../../../../components/Shared/Loader/Loading';
import {
  updateApExpenseCategoryItems,
  GetApExpenseCategoryItemsByExpenseCategoryId,
} from '../../../../../store/Finance/Expense/ApExpenseCategoryItems/ExpenseCategoryItemsSlice';
import { AddApExpenseCategoryItemsSchema } from '../../../../ValidationForm/validationSchema';
// import { AddApExpenseCategorySchema } from '../../../../ValidationForm/validationSchema';

interface props {
  setCategoryItems?: any;
  CategoryItems?: any;
}

const UpdateExpenseCategoryItems = ({ setCategoryItems, CategoryItems }: props) => {
  const { i18n, t } = useTranslation();
  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      id: CategoryItems.id ?? '',
      expenseCategoryId: CategoryItems.expenseCategoryId ?? '',
      name: CategoryItems.name ?? '',
      name2: CategoryItems.name2 ?? '',
    },
    enableReinitialize: true,
    validationSchema: AddApExpenseCategoryItemsSchema(t),
    onSubmit: (values) => {
      dispatch(
        updateApExpenseCategoryItems({
          id: CategoryItems?.id,
          expenseCategoryId: CategoryItems?.expenseCategoryId,
          name: values.name,
          name2: values.name2,
        })
      )
        .unwrap()
        .then((res: any) => {
          // debugger
          if (res?.succeeded === true) {
            CustomAlert({ action: 'Edit' });
            dispatch(
              GetApExpenseCategoryItemsByExpenseCategoryId(CategoryItems?.expenseCategoryId ?? '')
            );
            setCategoryItems(null);
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error', msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });

  // console.log(formik.errors);

  return (
    <>
      <Loading loading={false} error={null}>
        <Form onSubmit={formik.handleSubmit}>
          <div className="row align-items-end">
            <div className="col-lg-5 ">
              <Form.Control
                className="form-control  "
                type="text"
                name="name2"
                onChange={formik.handleChange}
                value={formik.values.name2}
                isInvalid={!!(formik.touched.name2 && formik.errors.name2)}
              />
            </div>
            <div className="col-lg-5">
              <Form.Control
                className="form-control  "
                type="text"
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                isInvalid={!!(formik.touched.name && formik.errors.name)}
              />
            </div>

            <div className="col-xl-2 text-end  ">
              <button disabled={formik.isSubmitting} type="submit" className="btn me-1 btn-theme">
                {formik.isSubmitting ? (
                  <div className="spinner-border spinner-border-sm me-2"></div>
                ) : (
                  <i className="fa fa-save fa-fw me-1"></i>
                )}
                {t('Save')}
              </button>
            </div>
          </div>
        </Form>
      </Loading>
    </>
  );
};

export default UpdateExpenseCategoryItems;
