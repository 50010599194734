import React, { useEffect } from "react";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import useLookupCurrency from "../../../../hooks/Lookups/use-Lookup-Currency";
import { FormPayrollElement } from "../../../ValidationForm/validationSchema";
import CustomAlert from "../../../../components/Shared/Alert/CustomAlert";
import {
  AddElementType,
  GetClassification,
} from "../../../../store/HR/Payroll/payrollElementSlice";

const AddPayrollElement = () => {
  const dispatch = useDispatch();

  // Translations
  const { t, i18n } = useTranslation();

  // get GetCurrency
  const { Currency } = useLookupCurrency();

  // get ClassificationList
  useEffect(() => {
    dispatch(GetClassification());
  }, [dispatch]);
  const { ClassificationList } = useSelector((state) => state.PyrollElement);


  const formik = useFormik({
    initialValues: {
      name: "",
      name2: "",
      currencyCode: "",
      classificationId: "",
      description: "",
      isRecurring: false,
      uom: "",
    },
    validationSchema: FormPayrollElement(t),
    onSubmit: (values) => {
    

      dispatch(
        AddElementType({
          name: values.name,
          name2: values.name2,
          currencyCode: values.currencyCode,
          classificationId: values.classificationId, // values.classificationId
          description: values.description,
          isRecurring: values.isRecurring,
          uom: values.uom,
        })
      )
        .unwrap()
        .then(() => {
          formik.resetForm();
          document.getElementById("closeModalElement").click();
          CustomAlert({ action: "Add" });
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: "Error" });
        });
    },
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <div className="row">
        <div className="col-xl-5">
          <div className="form-group mb-3">
            <label className="form-label">{t("LocalName")}</label>
            <Form.Control
              className="form-control"
              type="text"
              name="name2"
              onChange={formik.handleChange}
              value={formik.values.name2}
              isInvalid={formik.touched.name2 && formik.errors.name2}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.name2}
            </Form.Control.Feedback>
          </div>
        </div>
        <div className="col-xl-5">
          <div className="form-group mb-3">
            <label className="form-label">{t("GlobalName")}</label>
            <Form.Control
              className="form-control"
              type="text"
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              isInvalid={formik.touched.name && formik.errors.name}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.name}
            </Form.Control.Feedback>
          </div>
        </div>

        <div className="col-xl-2">
          <div className="form-group mb-3">
            <label className="form-label">  </label>
            <Form.Check
              style={{ margin: "10px 20px" }}
              className="form-check"
              type="checkbox"
              label={t("Recurring")}
              name="isRecurring"
              onChange={formik.handleChange}
              value={formik.values.isRecurring}
            />
          </div>
        </div>
      </div>




      <div className="row">
      <div className="col-xl-4">
        <div className="form-group mb-3">
          <label className="form-label">{t("Classification")}</label>
          <Form.Select
            className="form-select"
            name="classificationId"
            onChange={formik.handleChange}
            value={formik.values.classificationId}
            isInvalid={
              formik.touched.classificationId && formik.errors.classificationId
            }
          >
            <option value="">
              {i18n.language === "ar" ? "إختر" : "Choose"}
            </option>
            {ClassificationList?.map((item, idx) => (
              <option key={++idx} value={item.id}>
                {i18n.language === "ar" ? item.name2 : item.name}
              </option>
            ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            {formik.errors.classificationId}
          </Form.Control.Feedback>
        </div>
      </div>

        <div className="col-xl-4">
          <div className="form-group mb-3">
            <label className="form-label">{t("Currency")}</label>
            <Form.Select
              className="form-select"
              name="currencyCode"
              onChange={formik.handleChange}
              value={formik.values.currencyCode}
              isInvalid={
                formik.touched.currencyCode && formik.errors.currencyCode
              }
            >
              <option value="" key={null}>
                {i18n.language === "ar" ? "إختر" : "Choose"}
              </option>
              {Currency?.map((item) => (
                <option key={item.currencyCode} value={item.currencyCode}>
                  {" "}
                  {item.description}{" "}
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {formik.errors.currencyCode}
            </Form.Control.Feedback>
          </div>
        </div>
      <div className="col-xl-4">
        <div className="form-group mb-3">
          <label className="form-label">{t("InputOfType")}</label>
          <Form.Select
            className="form-select"
            name="uom"
            onChange={formik.handleChange}
            value={formik.values.uom}
            isInvalid={formik.touched.uom && formik.errors.uom}
          >
            <option value="">
              {i18n.language === "ar" ? "إختر" : "Choose"}
            </option>

            <option key={1} value="Text">
              {i18n.language === "ar" ? "نص" : "Text"}
            </option>

            <option key={2} value="Date">
              {i18n.language === "ar" ? "تاريخ" : "Date"}
            </option>

            <option key={3} value="Percentage">
              {i18n.language === "ar" ? "نسبة مئوية" : "Percentage"}
            </option>

            <option key={4} value="NoBasic">
              {i18n.language === "ar" ? "عدد مرات راتب أساسي" : "No Basic"}
            </option>

            <option key={5} value="Money">
              {i18n.language === "ar" ? "قيمة مالية" : "Money"}
            </option>

            <option key={6} value="Number">
              {i18n.language === "ar" ? "رقم" : "Number"}
            </option>

            <option key={7} value="Time">
              {i18n.language === "ar" ? "وقت" : "Time"}
            </option>

            <option key={8} value="Day">
              {i18n.language === "ar" ? "يوم" : "Day"}
            </option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            {formik.errors.uom}
          </Form.Control.Feedback>
        </div>
      </div>

      </div>



      <div className="col-xl-12">
        <div className="form-group mb-3">
          <label className="form-label">{t("Note")}</label>
          <Form.Control
            className="form-control"
            type="text"
            name="description"
            onChange={formik.handleChange}
            value={formik.values.description}
          />
        </div>
      </div>

      <div className="text-center">
        <button
          disabled={formik.isSubmitting}
          type="submit"
          className="btn me-1 btn-theme mb-1"
        >
          <i className="fa fa-save fa-fw me-1"></i>{" "}
          {formik.isSubmitting ? "..." : "حفظ"}
        </button>
        <button
          type="button"
          className=" btn me-1 btn-default mb-1"
          data-bs-dismiss="modal"
          id="closeModalElement"
        >
          <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
        </button>
 
      </div>
    </Form>
  );
};

export default AddPayrollElement;
