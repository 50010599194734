import React, { useCallback, useEffect, useState } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { ntxAPI } from '../../../../API/axiosNeatex';
import { FormikProps } from 'formik';

interface Props {
  formik: FormikProps<any>;
}

interface Option {
  value: string;
  label: string;
}

const Reference: React.FC<Props> = ({ formik }) => {
  const { values } = formik;
  const { t, i18n } = useTranslation();
  const [data, setData] = useState<Option[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchUrl = useCallback((): string | null => {
    switch (values.code) {
      case 'INV': // فاتورة مبيعات
        return `SalesInvoice/GetTransactionsInvoiceListByInventoryIdAndInventoryFlag?InventoryId=${values.inventoryId}&InventoryFlag=false`; // فاتورة مبيعات
      case 'SOI': // أمر بيع
        return `SalesOrderSalse/GetTransactionsOrderSalseListByInventoryIdAndInventoryFlag?InventoryId=${values.inventoryId}&InventoryFlag=false`; // أمر بيع
      case 'IOI': // مستند تحويل
        return `InvMove/GetTransactionsMoveListByInventoryIdAndInventoryFlag?InventoryId=${values.inventoryId}&InventoryFlag=false`; // مستند تحويل
      case 'SPR': // مردود مشتريات
        return `PurchasesReturns/GetListByInventoryIdAndInventoryFlag?InventoryId=${values.inventoryId}&InventoryFlag=false`; // مردود مشتريات
      case 'BIL': // فاتورة مشتريات
        return `PurchaseBill/GetListByInventoryIdAndInventoryFlag?InventoryId=${values.inventoryId}&InventoryFlag=false`;
      case 'POR': // أمر شـراء
        return `PurchaseOrder/GetListByInventoryIdAndInventoryFlag?InventoryId=${values.inventoryId}&InventoryFlag=false`;
      case 'CRT': // مردود مبيعات
        return `SalesReturns/GetTransactionsReturnsListByInventoryIdAndInventoryFlag?InventoryId=${values.inventoryId}&InventoryFlag=false`;
      case 'IOR': // استلام تحويــل
        return `InvIssue/GetTransactionsIssueListByInventoryIdAndInventoryFlag?InventoryId=${values.inventoryId}&InventoryFlag=false`;

      case 'CCA':
      case 'MII':
      case 'MOI':
        return null; // These cases intentionally don't fetch data
      default:
        return null;
    }
  }, [values.code]);

  const GetData = useCallback(async () => {
    const url = fetchUrl();

    if (!url) {
      setData([]);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const { data } = await ntxAPI.get(url);
      // debugger;

      if (data?.succeeded) {
        const formattedData = data.data
          ?.map((item: any) => ({
            value: item.id,
            label: item.code,
            transactionTypeId: item.transactionTypeId,
          }))
          ?.filter((f: any) => {
            if (values.code === 'IOR') {
              return f.transactionTypeId === 'ae3fe2fd-f2cb-44f3-9ae2-a01e466df8b6';
            }
            return true;
          });
        setData(formattedData);
      } else {
        setError('Error !!');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Error !!');
    } finally {
      setIsLoading(false);
    }
  }, [fetchUrl]);

  useEffect(() => {
    GetData();
  }, [GetData]);

  useEffect(() => {
    formik?.setFieldValue('referenceId', null);
    // formik?.setFieldValue('materialTransactionsLineList', []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.code]);

  // console.log(values.referenceId);
  // console.log(values.code);

  return (
    <>
      {/* {error && <div>{error}</div>} */}

      <Select
        isLoading={isLoading}
        classNamePrefix="react-select"
        isClearable
        isSearchable
        options={data}
        value={data?.find((f) => f.value === values?.referenceId) || null}
        onChange={(option: Option | null) => {
          formik.setFieldValue('referenceId', option ? option.value : null);
        }}
        placeholder={isLoading ? 'جاري تحميل البيانات' : error ? 'فشل تحميل البيانات' : 'اختر'}
        className={`border ${isLoading ? ' border-theme ' : ''} ${error ? ' border-danger ' : ''}`}
      />
    </>
  );
};

export default Reference;
