import React, { Fragment, memo, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useGetMyNotificationMessage from '../../../hooks/NTX/useGetMyNotificationMessage';
// import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
// import Popover from 'react-bootstrap/Popover';
// import NeatixModal from '../../Modal/NeatixModal';
// import NotificationList from './NotificationList';
import { useDispatch } from 'react-redux';
import {
  ChangeIsRead,
  GetMyNotificationMessageList,
} from '../../../store/NTX/Notifications/notificationSlice';
import NeatixModal from '../../Modal/NeatixModal';
import NotificationList from './NotificationList';
import { Card, CardBody } from '../../card/card';

function NotificationIcon() {
  const dispach = useDispatch();
  const [show, setShow] = useState(false);
  // const { t, i18n } = useTranslation();

  //#region
  const { t, i18n } = useTranslation();
  const { MyNotificationMessageList: data, loading } = useGetMyNotificationMessage();
  const [notificationData, setNotificationData] = useState([]);
  var Ids = data?.filter((f) => f.isRead === false)?.map((item) => item?.id);
  useEffect(() => {
    const intervalId = setInterval(() => {
      updateNotificationTime();
    }, 60000); // Update every one minute

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [data]); // Re-run effect when notification list changes

  useEffect(() => {
    // Initial update of notification time
    updateNotificationTime();
  }, [data]);

  function updateNotificationTime() {
    if (data) {
      const updatedData = data
        ?.slice()
        ?.sort((a, b) => new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime())
        ?.slice(0, 5)
        ?.map((item) => ({
          icon: item.icon + ' text-theme',
          title: i18n.language === 'en' ? item.notificationName : item.notificationName2,
          time: getTimePeriod(item.creationDate),
          isRead: item.isRead,
        }));

      setNotificationData(updatedData);
    }
  }

  function getTimePeriod(givenDateString) {
    // Parse the given date string into a JavaScript Date object
    const givenDate = new Date(givenDateString);

    // Get the current date
    const currentDate = new Date();

    // Calculate the time difference in milliseconds
    const timeDifference = currentDate.getTime() - givenDate.getTime();

    // Convert milliseconds to seconds, minutes, hours, and days
    const secondsDifference = Math.floor(timeDifference / 1000);
    const minutesDifference = Math.floor(secondsDifference / 60);
    const hoursDifference = Math.floor(minutesDifference / 60);
    const daysDifference = Math.floor(hoursDifference / 24);

    // Format the time difference into a human-readable string
    let timePeriod;
    if (daysDifference > 0) {
      timePeriod = daysDifference + ' days ago';
    } else if (hoursDifference > 0) {
      timePeriod = hoursDifference + ' hours ago';
    } else if (minutesDifference > 0) {
      timePeriod = minutesDifference + ' minutes ago';
    } else {
      timePeriod = secondsDifference + ' seconds ago';
    }

    return timePeriod;
  }

  //#endregion

  return (
    <Fragment>
      <div className="menu-item dropdown dropdown-mobile-full">
        <a href="#/" data-bs-toggle="dropdown" data-bs-display="static" className="menu-link">
          <div className="menu-icon">
            <i className="bi bi-bell nav-icon"></i>
          </div>
          {Ids?.length > 0 && <div className="menu-badge bg-theme"></div>}
        </a>
        <div className="dropdown-menu dropdown-menu-end mt-1 w-300px fs-11px pt-1 text-start ">
          <h6 className="dropdown-header fs-10px mb-1 ">{t('NOTIFICATIONS')}</h6>

          <div className="dropdown-divider mt-1"></div>
          {loading ? (
            <div className="text-center h-100">
              <div className="spinner-grow text-success"></div>
              <div className="spinner-grow text-danger"></div>
              <div className="spinner-grow text-warning"></div>
            </div>
          ) : (
            <>
              {notificationData?.length > 0 ? (
                notificationData?.map((notification, index) => (
                  <Link
                    to="#/"
                    key={index}
                    className="d-flex align-items-center py-10px dropdown-item text-wrap fw-semibold">
                    <div className="fs-20px">
                      <i className={notification.icon}></i>
                    </div>
                    <div className="flex-1 flex-wrap ps-3">
                      <div className="mb-1 text-inverse">
                        {!notification.isRead && (
                          <i
                            className="bi bi-circle-fill text-primary me-2"
                            style={{ fontSize: '8px' }}></i>
                        )}
                        {notification.title}
                      </div>
                      <div className="small text-inverse text-opacity-50">{notification.time}</div>
                    </div>

                    <div className="ps-2 fs-16px">
                      {i18n.language === 'en' ? (
                        <i className="bi bi-chevron-right"></i>
                      ) : (
                        <i className="bi bi-chevron-left"></i>
                      )}
                    </div>
                  </Link>
                ))
              ) : (
                <div className="dropdown-notification-item p-3 text-center">
                  لا يوجد لديك إشعارات
                </div>
              )}
              <hr className="mb-0 mt-2" />
              <div className="py-10px mb-n2 d-flex justify-content-between">
                <Link
                  to="#/"
                  className="text-decoration-none fw-bold mx-2 "
                  onClick={() => setShow(true)}
                  // data-bs-toggle="modal"
                  // data-bs-target="#modalNotificationList"
                >
                  عرض الكل
                </Link>
                <Link
                  to="#/"
                  className="text-decoration-none fw-bold mx-2 "
                  onClick={() => {
                    dispach(ChangeIsRead({ notificationMessageIds: Ids }));
                    dispach(GetMyNotificationMessageList());
                  }}>
                  تمييز الكل كـمقروء
                </Link>
              </div>
            </>
          )}
        </div>
      </div>

      <NeatixModal
        setShow={setShow}
        show={show}
        size="xl"
        // title="قائمة الإشعارات"
        showHeader={false}
        bodyClassName="p-0">
        <Card>
          <CardBody>
            <NotificationList setShow={setShow} />
          </CardBody>
        </Card>
      </NeatixModal>
    </Fragment>
  );
}

export default memo(NotificationIcon);

// const popover = (
//   <Popover id="popover-basic">
//     <Popover.Header as="h3">تنبيــــه</Popover.Header>
//     <Popover.Body>
//       فشل في تحديث البيانات
//       <br />
//       سيم تسجيل خروجك بعد لحظــات
//     </Popover.Body>
//   </Popover>
// );
