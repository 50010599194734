import ControlledStack from './ControlledStack';

const ControlledExample = ({ items, setItems }) => {
  //

  console.log(items);

  const removeItem = (itemId) => {
    setItems((prevItems) => prevItems?.filter((item) => item.id !== itemId));
  };

  return (
    <div>
      <div   style={{ display: 'flex' }}>
        <ControlledStack
          items={items}
          addItem={(item) => {
            setItems((items) => [...items, item]);
          }}
          changeItems={(items) => setItems(items)}
          removeItem={removeItem}
        />
      </div>
    </div>
  );
};

export default ControlledExample;
