import React from "react";
import NtxPeoples from "../../../NTX/Peoples/NtxPeoples";
import { Card, CardBody } from "../../../../components/card/card";
import Breadcrumb from "../../../../components/Shared/Breadcrumb";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const Update = () => {
  const { t } = useTranslation();
  var fieldList = [
    // "typeCode",
    // "customerNumber",
    // "personId",
    // "billToLocationId",
    // "shipToLocationId",
    // "termsId", // الشروط للعملاء . لها جدول محدد
    // "setOfBooksId", // جدول الاستاذ العام
    // "creditLimit", // سقف الدين
    // // "paymentPriority", // أولوية الدفع لووكآب  موجود في المورد وليس في العميل
    // "invoiceCurrencyCode", //
    // "paymentCurrencyCode", //
    // // "invoiceLimit", // موجود في المورد وليس في العميل ونوعه رقم
    // "bankAccountName", //
    // "bankAccountNum", //
    // "vatCode", //  الرقم الضريبي للعميل
    // "tradingName", // الاسم التجاري للعميل
    // "workReference", // اسم الشخص
    // "companyRegistrationNumber", // السجل التجاري للعميل
    // "peopleCategory",
    // "accountId",

    "typeCode",
    "customerNumber",
    "personId",
    "billToLocationId",
    "shipToLocationId",
    "termsId", // الشروط للعملاء . لها جدول محدد
    "setOfBooksId", // جدول الاستاذ العام
    "creditLimit", // سقف الدين
    // "paymentPriority", // أولوية الدفع لووكآب  موجود في المورد وليس في العميل
    "invoiceCurrencyCode", //
    "paymentCurrencyCode", //
    // "invoiceLimit", // موجود في المورد وليس في العميل ونوعه رقم 
    "bankAccountName", // 
    "bankAccountNum", // 
    "vatCode", //  الرقم الضريبي للعميل
    "tradingName", // الاسم التجاري للعميل
    "workReference", // اسم الشخص
    "companyRegistrationNumber", // السجل التجاري للعميل
    "peopleCategory",
    "peopleCategory",
    "priceListId",
    "accountId",

  ];

  var BcrumbList = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: t("العملاء"),
      link: "/SalesSettings/Customers",
    },
    {
      name: t("تعديل عميل"),
      link: null,
    },
  ];

  
  const location = useLocation();
  const {name} = location?.state;

  return (
    <div>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName="تعديل عميل" subtitle={name} />

      <Card>
        <CardBody>
          <NtxPeoples
            typeCode="CUSTOMERS"
            isUpdate={true}
            fieldList={fieldList}
          />
        </CardBody>
      </Card>
    </div>
  );
};

export default Update;
