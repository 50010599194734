import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useLookupModule from "../../../../hooks/Lookups/use-Lookup-Module";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { useFormik } from "formik";
import CustomAlert from "../../../../components/Shared/Alert/CustomAlert";
import { GetRoleList, UpdateAuthRule } from "../../../../store/NTX/Security/Rules/RulesSlice";
import { RulesModels } from "../../../../store/NTX/Security/Rules/RulesModels";

type Props = {
  rulesId: string;
};

const UpdateRules: React.FC<Props> = (props) => {
  const { t, i18n } = useTranslation();
  const { Module } = useLookupModule();
  const dispatch = useAppDispatch();
  const [RulesById, setRulesById] = useState<RulesModels>();
  const { RulesList } = useAppSelector((state) => state.Rules);

  useEffect(() => {
    var rule = RulesList?.find( f=> f.id === props.rulesId);

    setRulesById(rule);
  }, [RulesList, props.rulesId]);


  const formik = useFormik({
    initialValues: {
      id: RulesById ? RulesById.id : "",
      name: RulesById ? RulesById.name : "",
      name2: RulesById ? RulesById.name2 : "",
      startDate: RulesById ? RulesById.startDate : "",
      endDate: RulesById ? RulesById.endDate : "",
      description: RulesById ? RulesById.description : "",
      moduleId: RulesById ? RulesById.moduleId : "",
    },

    // validationSchema: formResponsibilitySchema(t),
    enableReinitialize: true,
    onSubmit: (values) => {
 

      dispatch(
        UpdateAuthRule({
          id: values.id,
          name: values.name,
          name2: values.name2,
          startDate: values.startDate, //!values.isExpiry ? null :
          endDate: values.endDate, //!values.isExpiry ? null :
          description: values.description,
          moduleId: values.moduleId,
        })
      )
        .unwrap()
        .then((res: any) => {
          if (res.succeeded === true) {
            CustomAlert({ action: "Edit" });
            document.getElementById("closeModal")?.click();
            formik.resetForm();
            dispatch(GetRoleList());
          } else {
            CustomAlert({ action: "Error" });
          }
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: "Error" });
        });
    },
  });

  return (
    <div>
      <Form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-xl-6">
            <div className="form-group mb-3">
              <label className="form-label">{t("LocalName")}</label>
              <Form.Control
                className="form-control"
                type="text"
                name="name2"
                onChange={formik.handleChange}
                value={formik.values.name2}
                isInvalid={!!(formik.touched.name2 && formik.errors.name2)}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.name2}
              </Form.Control.Feedback>
            </div>
          </div>

          <div className="col-xl-6">
            <div className="form-group mb-3">
              <label className="form-label">{t("GlobalName")}</label>
              <Form.Control
                className="form-control"
                type="text"
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                isInvalid={!!(formik.touched.name && formik.errors.name)}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.name}
              </Form.Control.Feedback>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-4">
            <div className="form-group mb-3">
              <label className="form-label">{t("Application")}</label>
              <Form.Select
                className="form-select"
                name="moduleId"
                value={formik.values.moduleId}
                onChange={formik.handleChange}
                isInvalid={
                  !!(formik.touched.moduleId && formik.errors.moduleId)
                }
              >
                <option value="">
                  {i18n.language === "ar" ? "إختر" : "Choose"}
                </option>
                {Module &&
                  Module?.map((item: any, idx: any) => (
                    <option key={++idx} value={item.id}>
                      {i18n.language === "ar" ? item.name2 : item.name}
                    </option>
                  ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {formik.errors.moduleId}
              </Form.Control.Feedback>
            </div>
          </div>
            <div className="col-xl-4">
              <div className="form-group mb-3">
                <label className="form-label">{t("Start Date")}</label>
                <Form.Control
                  className="form-control"
                  type="date"
                  name="startDate"
                  onChange={formik.handleChange}
                  value={formik.values.startDate}
                  isInvalid={
                    !!(formik.touched.startDate && formik.errors.startDate)
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.startDate}
                </Form.Control.Feedback>
              </div>
            </div>

            <div className="col-xl-4">
              <div className="form-group mb-3">
                <label className="form-label">{t("Date_TO")}</label>
                <Form.Control
                  className="form-control"
                  type="date"
                  name="endDate"
                  onChange={formik.handleChange}
                  value={formik.values.endDate}
                  isInvalid={
                    !!(formik.touched.endDate && formik.errors.endDate)
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.endDate}
                </Form.Control.Feedback>
              </div>
            </div>
          </div>

     
        <div className="col-xl-12">
          <div className="form-group mb-3">
            <label className="form-label">ملاحظات</label>
            <Form.Control
              className="form-control"
              as="textarea"
              rows={3}
              name="description"
              onChange={formik.handleChange}
              value={formik.values.description}
            />
          </div>
        </div>

        <div className="text-center">
          <button
            disabled={formik.isSubmitting}
            type="submit"
            className="btn me-1 btn-theme mb-1"
          >
                {formik.isSubmitting ? (
                  <div className="spinner-border spinner-border-sm me-2"></div>
                ) : (
                  <i className="fa fa-save fa-fw me-2"></i>
                )}
                {t("Save")}
          </button>
          <button
            type="button"
            className=" btn me-1 btn-default mb-1"
            data-bs-dismiss="modal"
            id="closeModal"
          >
            <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
          </button>
        </div>
      </Form>
    </div>
  );
};

export default UpdateRules;
