import { HasPermission } from "../Helper/Permisstions/Permisstions";


 



const Menu = [

 


//HR
{
System:"HR",
isActive: false,
menu :[
{ is_header: true, title: "HumanResources", isActive:true},
   {
    path: "dashboard",
    icon: "fas fa-home",
    title: "HomePage",
  },

  {
    path: "HR/Employees",
    icon: "bi bi-people",
    title: "employees",
    isActive: HasPermission('ViewlistHrPerson'),
    // isActive:  usePermissions().HasPermission('ViewlistHrPerson'),
  },

  {
    path: "HR/Delegation",
    icon: "bi bi-shield-check",
    title: "Delegations", 
    isActive: true,
  },

//
  {
    path: "/Org",
    icon: "bi bi-building",
    title: "Organizations",
    children: [
      {
        path: "/Org/Departments",
        title: "List_Organizations",
        isActive: HasPermission('ListHrDepartments'), 
      },

      {
        path: "/Org/OrganizationStructure",
        title: "OrganizationalStructure",
        isActive: HasPermission('ViewStructureHrDepartments'),
      },

    ],
  },


  {
    path: "/HRsettings",
    icon: "bi bi-gear",
    title: "hrSettings",
    children: [
      
      {
        path: "HRsettings/Job",
        title: "Jobs",
        isActive: HasPermission('ViewlistHrPerJob'),
      },
      {
        path: "HRsettings/JobGrade",
        title: "Jobgrades",
        isActive: HasPermission('ViewlistHrPerGrade'),
      },
      {
        path: "HRsettings/Location",
        title: "Locations",
        isActive: HasPermission('listHrLocation'),
      },
      {
        path: "HRsettings/Position",
        title: "Positions",
        isActive: HasPermission('listHrPerPosition'),
      },
      {
        path: "HRsettings/StaffGroup",
        title: "Staffgroup",
        isActive: HasPermission('listHrPerGroups'),
      },



    ],
  },

// { is_divider: true , isActive : true }, 
// { is_header: true, title: 'الموارد البشرية', isActive : true   },



  

  {
    path: "HRAme/",
    icon: "fa-solid fa-code-compare",
    title: "selfService",
    children: [
      {
        path: "HRAme/Requests",
        title: "NewRequest",
        isActive: true,
      },
      {
        path: "SelfService/MyRequests",
        title: "MyRequests",
        isActive: true,
      },
      {
        path: "SelfService/AllRequests",
        title: "RequestsTracking",
        isActive: true,
      },
    ],
  },



  {
    path: "/RAQ",
    icon: "far fa-clock",
    title: "attendancePlan",
    children: [
      {
        path: "RAQ/RotationPlan",
        title: "RotationPlan",  
        isActive: HasPermission('ViewListRaqRotationPlans'),
      },
      {
        path: "RAQ/AttendancAnalysis",
        title: "AttendanceAnalysis",
        isActive: HasPermission('AttendanceDataAnalysisReport'),
      },
      {
        path: "RAQ/UpdateRaqeebData",
        title: "UpdateAttendanceData",
        isActive: HasPermission("RAQAddUpdateAnalysisResults"),
      },
    ],
  },


  {
    path: "/Pay",
    icon: "fas fa-money-bill-alt",
    title: "PayrollManagement",
    children: [
      {
        path: "Pay/payrolls",
        title: "PayrollsSettings",
        isActive: HasPermission('PayViewPayrollList'),
        // isActive:false,
      },


      {
        path: "Pay/PayrollElement",
        title: "PayrollElements",
        isActive: HasPermission('PayViewListPayrollElement'),
        // isActive:false,
      },


	  {
        path: "Pay/payrollsRun",
        title: "RunPayrolls",
        isActive: HasPermission('PayCreatRunPayroll'),
        // isActive:false,
      },
{
        path: "Pay/QuickPay",
        title: "QuickPay",
        isActive: HasPermission('PayCreatePayrollQuickPay'),
        // isActive:false,
      },

      
      {
        path: "Pay/PayrollElementEntry",
        title: "PayrollElementEntry",
        isActive: HasPermission('PayPayrollElementEntries'),
        // isActive:false,
      },
    ],
  },


  {
    path: "HR/Reports",
    icon: "bi bi-clipboard-data-fill",
    title: "Reports",
    // isActive:false,
    isActive: true,
  },

  
  {
    path: "HrDashboard",
    icon: "bi bi-cpu",
    title: "Dashboard",
    // isActive:false,
    isActive: true,
    // highlight: true,
  },
  // {
  //   path: "HR/Test",
  //   icon: "bi bi-people",
  //   title: "TEST",
  //   isActive: true,
  // },
]
},













//NTX
{
  System:"NTX",
  isActive: false,
  menu :[
  { is_header: true, title: "GeneralSystem" ,isActive:true },
     {
      path: "dashboard",
      icon: "fas fa-home",
      title: "HomePage",
      
    },

  
    {
      path: "/Forms",
      icon: "bi bi-code-square",
      title: "SystemListsAndForms",
      children: [
        {
          path: "Forms/ExtraInformationForm",
          title: "ExtraInfoForm",
          isActive: HasPermission('NtxViewExtraInformation'),
        },

        {
          path: "Forms/ExtraRecordsForm",
          title: "ExtraRecordForm",
          isActive: HasPermission('NtxViewExtraInformationRecord'),
        },
  
       
        {
          path: "NTX/ListSystem",
          title: "SystemLists",
          isActive: HasPermission('NtxViewSystemlistD'),
        },
      ],
    },


  
    // { is_header: true, title: "المستخدمين والصلاحيــات" ,isActive:true },

    {
      path: "/Security",
      icon: "bi bi-person-fill-lock",
      title: "UserManagement",
      children: [
   
       
        {
          path: "Security/Users",
          title: "users",
          isActive: HasPermission('NtxViewuserslist'),
          // isActive: true,
        },
        {
          path: "Security/AuthRules",
          title: "RulesPermissions",
          isActive: HasPermission('NtxViewlistpermissionsRule'),
        },
      ],
    },


    {
      path: "/RPT",
      icon: "bi bi-clipboard2-data-fill",
      title: "التقارير وقوالب الطباعة",
      children: [

   {
          path: "RPT/Reports",
          title: "Reports_Management",
          // isActive:false,
          isActive: HasPermission('NtxViewReportslist'),
   },       
   {
          path: "RPT/PrintingForms",
          title: "إدارة قوالب الطباعة",
          // isActive:false,
          isActive: true,
   },       
      ],
    },


    {
      path: "/NTF",
      icon: "bi bi-bell",
      title: " الإشعارات",
      children: [
   {
          path: "NTF/Notifications",
          title: "إدارة الإشعارات",
          // isActive:false,
          isActive: true,
   },       
      ],
    },

    {
      path: "NTX/Lookup",
      icon: "bi bi-list-task",
      title: "GeneralSystemLists",
      // isActive: true,
      isActive: HasPermission('NtxViewgeneralsystemList'),
    },


    {
      path: "/NTX",
      icon: "bi bi-code-square",
      title: "الاعدادات العامة",
      children: [
        {
          path: "NTX/ExtraInformationForm",
          title: "البنوك",
          isActive: false,
        },

        {
          path: "NTX/ExtraRecordsForm",
          title: "العملات",
          isActive: false,
        },
  
      ],
    },

    {
      path: "/NtxDashboardManagement",
      icon: "bi bi-speedometer2",
      title: "إدارة لوحة البيانات",
      // isActive:false,
      isActive:true
    },
  
  ] 
},
 











 




//AME
  {
    System:"AME",
    isActive: true,
    menu :[
    { is_header: true, title: "ApprovalsManagement" , isActive:true},
       {
        path: "dashboard",
        icon: "fas fa-home",
        title: "HomePage",
      },
    
      // {
      //   path: "/NTX",
      //   icon: "bi bi-code-square",
      //   title: "RequestForms",
      //   children: [
      //     {
      //       path: "NTX/Forms",
      //       title: "RequestForms",
      //       isActive: HasPermission('AmeViewListApplicationForms'),
      //     },
      //   ],
      // },

      {
        path: "NTX/Forms",
        icon: "fas fa-file-alt",
        title: "RequestForms",
         isActive: HasPermission('AmeViewListApplicationForms'),
      },

      // { is_header: true, title: "RequestSettings" , isActive:true},
   
 
 
// {
//   path: "Ame/Conditions",
//   icon: "fas fa-tasks",
//   title: "الشروط",
//   //  isActive: HasPermission('AmeOrderSettingsList'),
//    isActive: true,
// },
// {
//   path: "Ame/Rules",
//   icon: "fas fa-gavel",
//   title: "القواعد",
//   //  isActive: HasPermission('AmeOrderSettingsList'),
//    isActive: true,
// },
 

{
  path: "Ame/RequestSetting",
  icon: "fas fa-cogs",
  title: "RequestSettings",
   isActive: HasPermission('AmeOrderSettingsList'),
},
{
  path: "Ame/ApprovalGroup",
  icon: "fas fa-users",
  title: "ApprovalGroup",
   isActive: HasPermission('AmeListApprovalGroups'),
},
{
  path: "Ame/FollowRequests",
  icon: "fas fa-clipboard-list",
  title: "FollowRequests",
  isActive: true,
},

  // {
  //   path: "",
  //   icon: "bi bi-clipboard-data-fill",
  //   title: "Reports",
  //   isActive: true,
  // },

  
  // {
  //   path: "",
  //   icon: "bi bi-cpu",
  //   title: "لوحة البيانات",
  //   isActive: true,
  // },



    ]
  },


















  //SU
  {
    System:"SU",
    isActive: true,
    menu :[
      { is_header: true, title: "SupportingUnits" ,isActive:true },
      {
        path: "dashboard",
        icon: "fas fa-home",
        title: "HomePage",
      },

       {
        path: "/SU/Contracts",
        icon: "bi bi-newspaper",
        title: "RealEstateContracts",
        isActive : true
      },

      {
        path: "/SU/RealEstate",
        icon: "bi bi-buildings",
        title: "RealEstate",
        isActive : true
      },

       {
        path: "/SU/SU_ExtraInformationDynamicType",
        icon: "bi bi-building-gear",
        title: "Types_Real_Estate",
        isActive : true
      },


      {
        path: "/SU/FollowPayments",
        icon: "bi bi-building-check",
        title: "متابعة الإيجارات",
        children: [
          {
            path: "/SU/FollowPayments/InstallmentUnPayment",
            title: "دفع الأقساط",
            isActive: true,
          },
          {
            path: "/SU/FollowPayments/InstallmentPayment",
            title: "عرص الأقساط المدفوعة",
            isActive: true,
          },
        ],
      },



      {
        path: "/SUSettings",
        icon: "bi bi-gear",
        title: "Settings",
        children: [

          {
            path: "SUSettings/Branches",
            // icon: "bi bi-sign-intersection-y-fill",
            title: "الفروع",
            isActive : true
          },

          {
            path: "SUSettings/Locations",
            title: "Locations",
            isActive: true,
          },

        ],
      },

      
    
      
 
      {
        path: "SU/Reports",
        icon: "bi bi-people",
        title: "Reports",
        isActive: true,
      },
    //  {
    //     path: "#/",
    //     icon: "bi bi-cpu",
    //     title: "لوحة البيانات",
    //     // isActive:false,
    //     isActive: true,
    //   },


    ]
  },













  
//FLEET
{
  System:"FLEET",
  isActive: false,
  menu :[
    { is_header: true, title: "FleetManagement" ,isActive:true },
    {
      path: "dashboard",
      icon: "fas fa-home",
      title: "HomePage",
    },


    {
      path: "/FLEETSettings",
      icon: "bi bi-gear",
      title: "Settings",
      children: [
        {
          path: "FLEETSettings/Manufacturers",
          title: "Manufacturers",
          isActive: true,

        },


        {
          path: "FLEETSettings/Model",
          title: "Model",
          isActive: true,

        },
        {
          path: "FLEETSettings/FleetElementScan",
          title: "InspectionItems",
          isActive: true,

        },
        {
          path: "FLEETSettings/WorkShop",
          title: "Workshops",
          isActive: true,
        },
        {
          path: "FLEETSettings/Locations",
          title: "Locations",
          isActive: true,
        },
        {
          path: "FLEETSettings/Suppliers",
          title: "Suppliers",
          isActive: true,
  
        },
        {
          path: "FLEETSettings/Drivers",
          title: "Drivers",
          isActive: true,
        },
        {
          path: "FLEETSettings/Owners",
          title: "Owners",
          isActive: true,
        },
      ],
    },

    {
      path: "FLEET/Equipments",
      icon: "fas fa-fw fa-car", 
      title: "Assets_and_Equipment",
      isActive: true,
    },
  




 


    {
      path: "FLEET/Maintenance/",
      icon: "bi bi-tools",
      title: "Maintenance",
      children: [
        {
          path: "FLEET/Maintenance/Requests",
          title: "NewRequest",
          isActive: true,
        },
        {
          path: "FLEET/Maintenance/MaintenanceOrder",
          title: "MaintenanceOrders",
          isActive: true,

        },
        {
          path: "SelfService/MyRequests",
          title: "MyRequests",
          isActive: true,
        },
      ],
    },

    

    {
      path: "FLEET/EquipmentInspection",
      icon: "bi bi-gear-wide-connected",
      title: "EquipmentInspection",
      isActive: true,
    },
    {
      path: "FLEET/Reports",
      icon: "bi bi-clipboard2-data-fill",
      title: "Reports",
      isActive: true,
    },
    {
      path: "FLEET/FleetDashboard",
      icon: "bi bi-cpu",
      title: "لوحة البيانات",
      // isActive:false,
      isActive: true,
    },
  ] 
},
 




  
//Finance
{
  System:"Finance",
  isActive: false,
  menu :[
    { is_header: true, title: "النظام المالي" ,isActive:true },
     {
      path: "dashboard",
      icon: "fas fa-home",
      title: "HomePage",
    },


    {
      path: "FinanceSettings",
      icon: "bi bi-gear",
      title: "Settings",
      children: [
        {
          path: "FinanceSettings/FiscalYear",
          title: "السنة المالية",
          isActive: true,
        },
        {
          path: "FinanceSettings/AccountingPeriod",
          title: "الفترات المحاسبية",
          isActive: true,
        },
        {
          path: "FinanceSettings/ChartAccounts",
          title: "الدليل المحاسبي",
          isActive: true,
        },
        {
          path: "FinanceSettings/Ledgers",
          title: "الأستاذ العام",
          isActive: true,
        },
        {
          path: "FinanceSettings/DailyRates",
          title: "المعدل اليومي للعملات",
          isActive: true,
        },
        {
          path: "FinanceSettings/JeCategory",
          title: "تصنيفات القيود",
          isActive: true,
        },
        {
          path: "FinanceSettings/JeSource",
          title: "مصادر القيود",
          isActive: true,
        },
        {
          path: "FinanceSettings/VAT",
          title: "ضريبة القيمة المضافة",
          isActive: true,
        },
        {
          path: "FinanceSettings/ConsolidationSet",
          title: "الربط بين الإدارات",
          isActive: true,
        },
        {
          path: "FinanceSettings/TypesOfExpenses",
          title: "أنواع المصروفات",
          isActive: true,
        },
        {
          path: "FinanceSettings/BankAccounts",
          title: "الحسابات البنكية",
          isActive: true,
        },
        {
          path: "FinanceSettings/CashBoxes",
          title: "صناديق النقديــة",
          isActive: true,
        },
      ],
    },



    {
      path: "Finance/Journals",
      icon: "bi bi-cash-coin",
      title: "القيود",
      children: [
        {
          path: "Finance/Journals",
          title: "القيود اليومية",
          isActive: true,
        },
        {
          path: "Finance/PostingJournals",
          title: "ترحيــل القيود",
          isActive: true,
        },
      ]
    },

    {
      path: "Finance/Ap/",
      icon: "bi bi-cash-coin",
      title: "المدفوعات",
      children: [
        {
          path: "Finance/Ap/ApInvoices",
          title: "Ap Invoice - فواتير المدفوعات",
          isActive: true,
        },
        {
          path: "Finance/Ap/ApPayments",
          title: "ApPayments",
          isActive: true,
        },
        {
          path: "Finance/Ap/ApExpense",
          title: "ApExpense",
          isActive: true,
        },
        {
          path: "Finance/Ap/InvoicePayments",
          title: "سداد الفواتيـر",
          isActive: true,
        },
      ]
    },
    
    {
      path: "Finance/Ar/",
      icon: "bi bi-cash-coin",
      title: "المستحقات",
      children: [
        {
          path: "Finance/Ar/ArTransactions",
          title: "ArTransactions",
          isActive: true,
        },
        {
          path: "Finance/Ar/ArReceipts",
          title: "ArReceipts",
          isActive: true,
        },
        // {
        //   path: "/",
        //   title: "سداد فواتير العملاء",
        //   isActive: true,
        // },
      ]
    },
    {
      path: "Finance/FA/",
      icon: "bi bi-aspect-ratio-fill",
      title: "الأصول",
      children: [
        {
          path: "Finance/FA/FaCategories",
          title: "فئات الأصول",
          isActive: true,
        },
        {
          path: "Finance/FA/FaAssets",
          title: "الأصول",
          isActive: true,
        },
 
      ]
    },

  ] 
},
 








  //Integration
  {
    System:"Integration",
    isActive: true,
    menu :[
      { is_header: true, title: "التكــامل مع الأنظمة" ,isActive:true },
       {
        path: "dashboard",
        icon: "fas fa-home",
        title: "Dashboard",
      },

       {
        path: "/Integration",
        icon: "fas fa-home",
        title: "التكامل",
        isActive : true
      },
    ]
  },








  //SAL
  {
    System:"SAL",
    menu :[
      
      { is_header: true, title: "المبيعات" ,isActive:true },

       {
        path: "dashboard",
        icon: "fas fa-home",
        title: "Home",
        isActive:true
       },

       { is_header: true, title: "العمليات" ,isActive:true },
      



     
      
       
          {
            path: "SalesTransactions/Quotation",
             icon: "fas fa-file-alt",
            title: "عروض الأسعار",
            isActive: true,
          },
 
          {
            path: "SalesTransactions/SellOrder",
             icon: "fas fa-shopping-cart",
            title: "أوامر البيع",
            isActive: true,
          },
        
          {
            path: "SalesTransactions/SalesInvoices",
             icon: "fas fa-file-invoice-dollar",
            title: "فواتير المبيعات",
            isActive: true,
          },
          // {
          //   path: "SalesTransactions/DeliveryPermission",
          //    icon: "fas fa-truck",
          //   title: "إذن تسليم",
          //   isActive: true,
          // },
          {
            path: "SalesTransactions/SalesReturn",
             icon: "fas fa-undo",
            title: "مردود المبيعات",
            isActive: true,
          },

    

          // {
          //   path: "SalesTransactions/Contracts",
          //    icon: "fas fa-file-signature",
          //   title: "العقود",
          //   isActive: true,
          // },
          // {
          //   path: "/",
          //   title: "متابعة الفواتيــر",
          //   isActive: true,
          // },
       


          { is_header: true, title: "الإعدادات" ,isActive:true },
  
          {
            path: "SalesSettings/Customers",
            icon: "fas fa-users",
            title: "العملاء",
            isActive: true,
          },
          // {
          //   path: "/",
          //   title: "أنواع العقود",
          //   isActive: true,
          // },
          // {
          //   path: "/",
          //   title: "سياسة العمولات",
          //   isActive: true,
          // },
          {
            path: "SalesSettings/PriceList",
            icon: "fas fa-tags",
            title: "قائمة الأسعار",
            isActive: true,
          },
          // {
          //   path: "SalesSettings/Vouchers",
          //   icon: "fas fa-ticket-alt",
          //   title: "القسـائم",
          //   isActive: true,
          // },
          {
            path: "SalesSettings/Vouchers2",
            icon: "fas fa-ticket-alt",
            title: "القسـائم",
            isActive: true,
          },
          // {
          //   path: "/",
          //   title: "المستهدفات",
          //   isActive: true,
          // },


          { is_header: true, title: "_" ,isActive:true },
  
          {
            path: "Sales/SalesRequests",
            icon: "fas fa-clipboard-list",
            title: "الطلبات",
            isActive: true,
          },
          {
            path: "Sales/SalesReports",
            icon: "fas fa-chart-bar",
            title: "التقارير",
            isActive: true,
          },
          {
            path: "Sales/SalesDashboards",
            icon: "fas fa-tachometer-alt",
            title: "لوحة البيانات",
            isActive: true,
          },

    ]
  },













  //INV
  {
    System:"INV",
    isActive: true,
    menu :[
      { is_header: true, title: "المخــازن" ,isActive:true },
       {
        path: "dashboard",
        icon: "fas fa-home",
        title: "Dashboard",
      },


      { is_header: true, title: "الإعدادات" ,isActive:true },
    
          {
            path: "INV/InventorySettings/Inventory",
            title: "المخازن",
             icon: "fas fa-warehouse",
            isActive: true,
          },
          {
            path: "INV/InventorySettings/Categories",
            title: "الفئات",
             icon: "fas fa-list",
            isActive: true,
          },
          {
            path: "INV/InventorySettings/MasterItems",
            title: "الأصناف",
             icon: "fas fa-boxes",
            isActive: true,
          },
   
          {
            path: "INV/InventorySettings/UnitsOfMeasure",
            title: "وحدات القياس",
             icon: "fas fa-ruler",
            isActive: true,
          },
          {
            path: "INV/InventorySettings/TransactionTypes",
            title: "أنواع الحركات المخزنية",
             icon: "fas fa-exchange-alt",
            isActive: true,
          },
          {
            path: "INV/InventorySettings/MasterItems",
            title: "الباتشات وتواريخ الصلاحيــة",
             icon: "fas fa-calendar-alt",
            isActive: false,
          },
      
          { is_header: true, title: "العمليــات" ,isActive:true },

          {
            path: "INV/Transactions/InvIssue",
            title: "إذن صرف",
             icon: "fas fa-sign-out-alt",
            isActive: true,
          },
          {
            path: "INV/Transactions/InvReceive",
            title: "إذن إضافة",
             icon: "fas fa-sign-in-alt",
            isActive: true,
          },
          {
            path: "INV/Transactions/InvMove",
            title: "مستندات التحويل",
             icon: "fas fa-arrows-alt-h",
            isActive: true,
          },
          {
            path: "INV/Transactions/AVC",
            title: "تحديث الأسعار",
             icon: "fas fa-arrows-alt-h",
            isActive: true,
          },
          {
            path: "INV/Transactions/Allocate",
            title: "حجز",
             icon: "fas fa-arrows-alt-h",
            isActive: true,
          },
 
          // {
          //   path: "INV/Transactions/PermissionReceive",
          //   title: "إذن إستلام",
          //   isActive: true,
          // },
          // {
          //   path: "/",
          //   title: "تسليم الفواتير",
          //   isActive: true,
          // },
          // {
          //   path: "/",
          //   title: "إعادة إحتساب التكاليف",
          //   isActive: true,
          // },
          // {
          //   path: "/",
          //   title: "إغلاق المخزون",
          //   isActive: true,
          // },
          // {
          //   path: "/",
          //   title: "الحجــز",
          //   isActive: true,
          // },
          // {
          //   path: "/",
          //   title: "الفرز / الفـك",
          //   isActive: true,
          // },
  
      // {
      //   path: "InventorySettings",
      //   icon: "bi bi-shop",
      //   title: "التحويلات المخزنية",
      //   children: [
      //     {
      //       path: "/",
      //       title: "مستندات التحويل",
      //       isActive: true,
      //     },
      //     {
      //       path: "/",
      //       title: "طلبات تحويل بإنتظار الصرف",
      //       isActive: true,
      //     },
      //     {
      //       path: "/",
      //       title: "طلبات تحويل بإنتظار الإضافة",
      //       isActive: true,
      //     },
      //   ]
      // },
      { is_header: true, title: "جرد المخزون" ,isActive:true },

          {
            path: "INV/InventoryCount",
            title: "جرد المخزن",
             icon: "fas fa-snowflake",
            isActive: true,
          },
          {
            path: "/qw",
            title: "الجرد الفعلي",
             icon: "fas fa-clipboard-check",
            isActive: true,
          },
          {
            path: "/we",
            title: "نتائج الجرد",
             icon: "fas fa-chart-bar",
            isActive: true,
          },
    
    ]
  },


















 //PUR
  {
    System:"PUR",
    menu :[
      
        { is_header: true, title: "المشتريات" ,isActive:true },

       {
        path: "dashboard",
        icon: "fas fa-home",
        title: "Home",
        isActive:true
       },

       { is_header: true, title: "العمليات" ,isActive:true },
      


       
       {
        path: "PurchasesTransactions/PurchaseRequests",
        icon: "fas fa-shopping-cart",
        title: "طلبات الشـراء",
        isActive: true,
      },
      {
        path: "PurchasesTransactions/Quotation",
         icon: "fas fa-tags",
        title: "عروص الأسعار",
        isActive: true,
      },
      {
        path: "PurchasesTransactions/PurchaseOrder",
         icon: "fas fa-file-invoice-dollar",
        title: "أوامر الشراء",
        isActive: true,
      },
      {
        path: "PurchasesTransactions/PurchaseBill",
         icon: "fas fa-file-invoice-dollar",
        title: "فواتير المشتريات",
        isActive: true,
      },
      // {
      //   path: "",
      //    icon: "fas fa-truck",
      //   title: "الإستلام",
      //   isActive: true,
      // },
      // {
      //   path: "",
      //   title: "فواتير المشتريات",
      //   isActive: true,
      // },
      {
        path: "PurchasesTransactions/PurchaseReturn",
         icon: "fas fa-undo",
        title: "مردود مشتريات",
        isActive: true,
      },
      // {
      //   path: "",
      //   title: "إشعار خـصم",
      //   isActive: true,
      // },
      // {
      //   path: "",
      //    icon: "fas fa-tasks",
      //   title: "متابعة أوامر الشراء",
      //   isActive: true,
      // },
       


          { is_header: true, title: "الإعدادات" ,isActive:true },
  
          {
            path: "PurchasesSettings/SUPPLIERS",
             icon: "fas fa-users",
            title: "الموردين",
            isActive: true,
          },
          // {
          //   path: "InventorySettings/Inventory",
          //    icon: "fas fa-receipt",
          //   title: "أنواع المصروفات",
          //   isActive: true,
          // },
          // {
          //   path: "InventorySettings/MasterItems",
          //    icon: "fas fa-gift",
          //   title: "الحوافز",
          //   isActive: true,
          // },


          { is_header: true, title: "_" ,isActive:true },
  
          {
            path: "",
            icon: "fas fa-clipboard-list",
            title: "الطلبات",
            isActive: true,
          },
          {
            path: "",
            icon: "fas fa-chart-bar",
            title: "التقارير",
            isActive: true,
          },
          {
            path: "",
            icon: "fas fa-tachometer-alt",
            title: "لوحة البيانات",
            isActive: true,
          },

    ]
  },
















  //POS
  {
    System:"POS",
    isActive: true,
    menu :[
      { is_header: true, title: "نقاط البيـــع" ,isActive:true },
       {
        path: "dashboard",
        icon: "fas fa-home",
        title: "Dashboard",
      },

      {
        path: "Pos/PosSettings",
        icon: "bi bi-gear",
        title: "إعدادات نقاط البيع" ,
        isActive: true,
      },

      {
        path: "Pos/PosCard",
        icon: "bi bi-bag-check",
        title: "نقـاط البيــع",
        isActive: true,
      },
    ]
  },







  


 
];



export default Menu;













  // //PUR
  // {
  //   System:"PUR",
  //   isActive: true,
  //   menu :[
  //     { is_header: true, title: "إدارة المشتريــات" ,isActive:true },
  //      {
  //       path: "dashboard",
  //       icon: "fas fa-home",
  //       title: "Dashboard",
  //     },

  //     {
  //       path: "InventorySettings",
  //       icon: "bi bi-gear",
  //       title: "الإعدادات",
  //       children: [
  //         {
  //           path: "InventorySettings/Categories",
  //           title: "الموردين",
  //           isActive: true,
  //         },
  //         {
  //           path: "InventorySettings/Inventory",
  //           title: "أنواع المصروفات",
  //           isActive: true,
  //         },
  //         {
  //           path: "InventorySettings/MasterItems",
  //           title: "الحوافز",
  //           isActive: true,
  //         },
  //       ]
  //     },

  //     {
  //       path: "InventorySettings",
  //       icon: "bi bi-gear",
  //       title: "العمليــات",
  //       children: [
  //         {
  //           path: "InventorySettings/MasterItems",
  //           title: "طلبات الشـراء",
  //           isActive: true,
  //         },
  //         {
  //           path: "InventorySettings/MasterItems",
  //           title: "عروص الأسعار",
  //           isActive: true,
  //         },
  //         {
  //           path: "InventorySettings/MasterItems",
  //           title: "أوامر الشراء",
  //           isActive: true,
  //         },
  //         {
  //           path: "InventorySettings/MasterItems",
  //           title: "الإستلام",
  //           isActive: true,
  //         },
  //         // {
  //         //   path: "InventorySettings/MasterItems",
  //         //   title: "فواتير المشتريات",
  //         //   isActive: true,
  //         // },
  //         {
  //           path: "InventorySettings/MasterItems",
  //           title: "مردود مشتريات",
  //           isActive: true,
  //         },
  //         // {
  //         //   path: "InventorySettings/MasterItems",
  //         //   title: "إشعار خـصم",
  //         //   isActive: true,
  //         // },
  //         {
  //           path: "InventorySettings/MasterItems",
  //           title: "متابعة أوامر الشراء",
  //           isActive: true,
  //         },
  //       ]
  //     },

      
  //   ]
  // },