
import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import useGetItemsList from '../../../../hooks/INV/MasterItems/useGetItemsList';
import Loading from '../../../../components/Shared/Loader/Loading';
import { Form } from 'react-bootstrap';
import useGetUnitsOfMeasureList from '../../../../hooks/INV/useGetUnitsOfMeasureList';
import useGetItemConversionList from '../../../../hooks/INV/ItemConversion/useGetItemConversionList';
import NeatixModal from '../../../../components/Modal/NeatixModal';
import CodeCombination from './CodeCombination';
import PopoverCustom from '../../../../components/Shared/Popover/PopoverCustom';
import useGetCostCenters from '../../../../hooks/Finance/ChartAccountsDetails/useGetCostCenters';
import useGetCompanies from '../../../../hooks/Finance/ChartAccountsDetails/useGetCompanies';
import useGetBranches from '../../../../hooks/Finance/ChartAccountsDetails/useGetBranches';
import useGetAccounts from '../../../../hooks/Finance/ChartAccountsDetails/useGetAccounts';
import SwalAlert from '../../../../components/Shared/Alert/SwalAlert';



interface LinesProps {
  formik: FormikProps<any>;
}

const ApInvoiceItemsLines: React.FC<LinesProps> = ({ formik }) => {
  const { values } = formik;

 
  const { OptionsCostCenters } = useGetCostCenters();
  const { OptionsCompanies } = useGetCompanies();
  const { OptionsBranches } = useGetBranches();
  const { OptionsAccounts } = useGetAccounts();

  const [shawModalAcaount, setShawModalAcaount] = useState(false);
  const [selectedLineIndex, setSelectedLineIndex] = useState<number | null>(null) 

  const { i18n, t } = useTranslation();
  const { ItemsOptions, loading, error } = useGetItemsList();
  const { ItemConversionList, loading: loading2, error: error2 } = useGetItemConversionList();
  const { UnitsOfMeasureList: UomList } = useGetUnitsOfMeasureList();

  const GetUom = (line: any) => {
    const uomIdForItem = ItemsOptions?.find((f) => f.value === line?.invItemId)?.uom;
    // تصفية العناصر من قائمة التحويلات بناءً على itemId
    const filteredList = ItemConversionList?.filter((f) => f.itemId === line.invItemId);
    // العثور على الوحدة المقابلة من قائمة UomList بناءً على uomId
    const filteredList2 = UomList?.find((f) => f.id === uomIdForItem);

    // دمج القائمتين في قائمة ثالثة
    const combinedList = [...filteredList];

    // إذا كانت filteredList2 موجودة وغير موجودة في filteredList، يتم إضافتها إلى القائمة
    if (filteredList2 && !filteredList.some((f) => f.uom === filteredList2.id)) {
      combinedList.push({
        id: filteredList2.id,
        itemId: line.invItemId,
        uom: filteredList2.id,
        conversionValue: 1, // أو أي قيمة افتراضية مناسبة للتحويل
        barcode: '',
        uomName: filteredList2.name,
        uomName2: filteredList2.name2,
      });
    }

    return combinedList;
  };

  const handleLineChange = (index: number, field: string, value: any) => {
    const updatedLines = [...values.arTransactionsLinesList];
    const line = updatedLines[index];

    if (field === 'lineType') {
      if (value !== 'Item_Inv') {
        updatedLines[index] = {
          ...updatedLines[index],
          [field]: value,
          invItemId: null,
          description: '',
          uom: null,
          price: 0,
          discountAmount: 0,
          discountRate: 0,
          vatAmount: 0,
          total: 0,
          qty: 0,
        };
        formik?.setFieldValue('arTransactionsLinesList', updatedLines);
        return;
      }
    }

    if (field === 'invItemId') {
      // الحصول على وصف العنصر بناءً على قيمته
      const itemName = ItemsOptions?.find((f) => f.value === value)?.itemName;
      const itemCode = ItemsOptions?.find((f) => f.value === value)?.itemCode;
      // const price = ItemsOptions?.find((f) => f.value === value)?.salesPrice || 0;
      // const total = Number(updatedLines[index].qty) * Number(price);

      // تحديث العنصر مع الوصف والسعر والقيمة
      updatedLines[index] = {
        ...updatedLines[index],
        invItemId: value,
        description: itemName + ' | ' + '(' + itemCode + ')' || '', // تحديث حقل الوصف
        // price: Number(price),
        // total,
      };

      formik.setFieldValue('arTransactionsLinesList', updatedLines);
      return;
    }

    // debugger
    // تحديث القيمة بناءً على الحقل الذي تم تغييره
    updatedLines[index] = {
      ...line,
      [field]: value,
    };

    if (updatedLines[index].lineType === 'Item_Inv') {
      // إعادة حساب القيم تلقائيا عند تغيير الكمية، السعر، نسبة الخصم
      const qty = Number(updatedLines[index].qty);
      const price = Number(updatedLines[index].price);
      const discountRate = Number(updatedLines[index].discountRate);
      const vatRate = Number(updatedLines[index].vatRate);

      // حساب قيمة الخصم
      const discountAmount = (price * qty * discountRate) / 100;

      // حساب الإجمالي بعد الخصم
      const total = price * qty - discountAmount;

      // حساب ضريبة القيمة المضافة ❤
      const vatAmount = (total * vatRate) / 100;

      // تحديث السطر بالقيم المحسوبة
      updatedLines[index] = {
        ...updatedLines[index],
        discountAmount,
        total,
        vatAmount,
      };
    }

    // تحديث القيم في النموذج
    formik?.setFieldValue('arTransactionsLinesList', updatedLines);
  };

  const handleAddLine = () => {
    const newLine = {
      lineType: 'Item',
      id: null,
      lineNumber: values.arTransactionsLinesList?.length + 1,
      invItemId: null,
      uom: '',
      price: 0,
      qty: 0,
      discountRate: 0,
      discountAmount: 0,
      vatRate: 15,
      total: 0,
      vatAmount: 0,
      description: '',
      currencyCode: '',
      currencyConversionRate: 1,
      purHeaderId: null,
      purLineId: null,
      accountId: null,
      costCenterId: null,
      //
      companyId: '', // غير موجود في البوست
      branchId: '', // غير موجود في البوست
      codeCombination: '', // غير موجود في البوست
    };
    formik.setFieldValue('arTransactionsLinesList', [...formik.values.arTransactionsLinesList, newLine]);
  };

  const handleDeleteLine = (index: number) => {
    const updatedLines = values.arTransactionsLinesList?.filter((_: any, i: number) => i !== index);
    formik?.setFieldValue('arTransactionsLinesList', updatedLines);
  };

  const calculateTotalSum = () => {
    let total = values.arTransactionsLinesList?.reduce((sum: any, line: any) => sum + line.total, 0);
    const formattedTotal = new Intl.NumberFormat('en-US').format(total);
    return formattedTotal;
  };

  const [accountDetails, setAccountDetails] = useState({
    companyId: '',
    branchId: '',
    costCenterId: '',
    accountId: '',
  });

  const handleAccountClick = (index: number) => {
    if (!values.organizationId) {
      SwalAlert({ text: 'حدد الفرع' });
      return;
    }

    const selectedLine = formik.values.arTransactionsLinesList[index];
    // إعداد القيم لتمريرها إلى المكون
    const companyId = selectedLine.companyId || '';
    const branchId = selectedLine.branchId || '';
    const accountId = selectedLine.accountId || '';
    const costCenterId = selectedLine.costCenterId || '';

    // تمرير القيم إلى المكون وفتح الـ Modal
    setSelectedLineIndex(index);
    setShawModalAcaount(true);
    setAccountDetails({ accountId, costCenterId, companyId, branchId });
  };

  const handleAccountSelect = (account: any) => {
    if (selectedLineIndex !== null) {
      const updatedLines = [...values.arTransactionsLinesList];

      updatedLines[selectedLineIndex].companyId = account?.company?.id || '';
      updatedLines[selectedLineIndex].branchId = account?.branch?.id || '';
      updatedLines[selectedLineIndex].costCenterId = account?.costCenter?.id || '';
      updatedLines[selectedLineIndex].accountId = account?.account?.id || '';

      var codeCombination = `${account?.company?.accountNumber} | ${account?.branch?.accountNumber} |${account?.costCenter?.accountNumber} |${account?.account?.accountNumber}`;
      updatedLines[selectedLineIndex].codeCombination = codeCombination || '';
      formik.setFieldValue('arTransactionsLinesList', updatedLines);
    }
    setShawModalAcaount(false); // Close modal after selection
  };

  const handleShowAccountDetails = (index: number) => {
    const selectedLine = values.arTransactionsLinesList[index];
    const { companyId, branchId, accountId, costCenterId } = selectedLine;

    return (
      <div className="text-start">
        <p className="m-0">{OptionsCompanies?.find((f) => f.value === companyId)?.accountShow}</p>
        <p className="m-0">{OptionsBranches?.find((f) => f.value === branchId)?.accountShow}</p>
        <p className="m-0">{OptionsAccounts?.find((f) => f.value === accountId)?.accountShow}</p>
        <p className="m-0">
          {OptionsCostCenters?.find((f) => f.value === costCenterId)?.accountShow}
        </p>
      </div>
    );
  };

  //_________________

  return (
    <>
      <Loading loading={loading} error={error} Type="Dots">
        <>
          <div className="row">
            <div className="col-12 col-md-3 mb-2">
              <div className="rounded-2 p-2 bg-inverse bg-opacity-10">
                <div className="d-flex justify-content-between">
                  <div className="fw-bold">{t('Total')}:</div>
                  <div className="text-theme fs-5 fw-bold">{calculateTotalSum()}</div>
                </div>
              </div>
            </div>

            <div className="col-lg-3"></div>
            <div className="col-lg-6 text-end ">
              <div className="text-end mb-2">
                <button type="button" className="btn btn-outline-theme  " onClick={handleAddLine}>
                  {/* <i className="bi bi-plus-square-dotted fa-lg me-2"></i> */}
                  {t('إضافة صنف')}
                </button>
              </div>
            </div>
          </div>

          {values.arTransactionsLinesList?.length > 0 && (
            <div className="">
              <div
                className={`table-responsive rounded-2 text-center ${
                  formik.errors.arTransactionsLinesList ? ' border-bottom border-2 border-danger' : ''
                } `}>
                {/* <PerfectScrollbar
                  style={{ overflow: 'visible' }}
                  option={{ suppressScrollX: false, suppressScrollY: true }}> */}
                <div style={{ width: '180%' }}>
                  <table className="table table-borderless text-nowrap   mb-2">
                    <thead className="bg-theme bg-opacity-30">
                      <tr>
                        <th className="align-middle  small" style={{ width: '2%' }}>
                          #
                        </th>
                        <th className="align-middle  small" style={{ width: '6%' }}>
                          النوع
                        </th>

                        <th className="align-middle  small" style={{ width: '21%' }}>
                          {t('اسم الصنف')}
                        </th>
                        <th className="align-middle  small" style={{ width: '6%' }}>
                          {t('الوحدة')}
                        </th>
                        <th className="align-middle  small" style={{ width: '4%' }}>
                          {t('الكمية')}
                        </th>
                        <th className="align-middle  small" style={{ width: '4%' }}>
                          {t('السعر')}
                        </th>
                        <th className="align-middle  small" style={{ width: '4%' }}>
                          {t('نسبة الخصم')}
                        </th>
                        <th className="align-middle  small" style={{ width: '4%' }}>
                          {t('قيمة الخصم')}
                        </th>
                        <th className="align-middle  small" style={{ width: '4%' }}>
                          VAT
                          <br />%
                        </th>
                        <th className="align-middle  small" style={{ width: '4%' }}>
                          {t('VAT')}
                        </th>

                        <th className="align-middle small" style={{ width: '6%' }}>
                          {t('القيمة')}
                        </th>
                        <th className="align-middle small" style={{ width: '24%' }}>
                          {t('الوصف')}
                        </th>
                        <th className="align-middle small" style={{ width: '10%' }}>
                          {t('الحســـاب')}
                        </th>
                        <th className="align-middle text-end" style={{ width: '2%' }}></th>
                      </tr>
                    </thead>

                    <tbody className="fw-bold">
                      {values.arTransactionsLinesList
                        ?.slice()

                        ?.map((line: any, index: any) => {
                          const Index = index;

                          return (
                            <>
                              <tr key={index}>
                                <td className="align-middle small">{index + 1}</td>
                                <td className="align-middle small ">
                                  <Form.Select
                                    className="form-select"
                                    name="lineType"
                                    value={line.lineType || ''}
                                    onChange={(e) =>
                                      handleLineChange(Index, 'lineType', e.target.value)
                                    }>
                                    <option value="Item_Inv">
                                      {i18n.language === 'en' ? 'Item Inv' : 'صنف مخزني'}
                                    </option>
                                    <option value="Item">
                                      {i18n.language === 'en' ? 'Service  Item' : 'صنف خدمات'}
                                    </option>
                                    <option value="Tax">
                                      {i18n.language === 'en' ? 'Tax' : 'ضريبة'}
                                    </option>
                                    <option value="Freight">
                                      {i18n.language === 'en' ? 'Freight' : 'شحن'}
                                    </option>
                                  </Form.Select>
                                </td>

                                <td className={`align-middle small  `}>
                                  <Select
                                    isDisabled={line.lineType !== 'Item_Inv'}
                                    classNamePrefix="react-select"
                                    className={`w-100 ${
                                      line.lineType !== 'Item_Inv' ? 'bg-inverse bg-opacity-10' : ''
                                    }`}
                                    isSearchable
                                    isClearable
                                    options={ItemsOptions?.filter(
                                      (f) =>
                                        !values.transactionsLinesList?.some(
                                          (line: any) => line.itemId === f.value
                                        )
                                    )}
                                    onChange={(option) =>
                                      handleLineChange(
                                        Index,
                                        'invItemId',
                                        option === null ? null : option.value
                                      )
                                    }
                                    value={
                                      line.invItemId === null
                                        ? null
                                        : ItemsOptions?.find(
                                            (option) => option.value === line.invItemId
                                          )
                                    }
                                    placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                                    menuPortalTarget={document.body}
                                    styles={{
                                      menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                                    }}
                                  />
                                </td>

                                <td className={`align-middle small `}>
                                  <select
                                    disabled={line.lineType !== 'Item_Inv'}
                                    className={`form-select text-center  ${
                                      line.lineType !== 'Item_Inv' ? 'bg-inverse bg-opacity-10' : ''
                                    }`}
                                    value={line.uom || ''}
                                    onChange={(e) =>
                                      handleLineChange(Index, 'uom', e.target.value)
                                    }>
                                    <option value="">
                                      {i18n.language === 'ar' ? 'إختر' : 'Choose'}
                                    </option>

                                    {GetUom(line)?.map((item, idx) => (
                                      <option key={++idx} value={item.uom}>
                                        {i18n.language === 'en' ? item.uomName : item.uomName2}
                                      </option>
                                    ))}
                                  </select>
                                </td>

                                <td className={`align-middle small  `}>
                                  <input
                                    disabled={line.lineType !== 'Item_Inv'}
                                    type="text"
                                    className={`form-control text-center px-0  ${
                                      line.lineType !== 'Item_Inv' ? 'bg-inverse bg-opacity-10' : ''
                                    }`}
                                    value={line.qty}
                                    onChange={(e) =>
                                      handleLineChange(Index, 'qty', Number(e.target.value))
                                    }
                                    onInput={(e) => {
                                      const input = e.target as HTMLInputElement;
                                      input.value = input.value.replace(/[^0-9]/g, '');
                                    }}
                                  />
                                </td>

                                <td className={`align-middle small`}>
                                  <input
                                    disabled={line.lineType !== 'Item_Inv'}
                                    type="text"
                                    className={`form-control text-center px-0  ${
                                      line.lineType !== 'Item_Inv' ? 'bg-inverse bg-opacity-10' : ''
                                    }`}
                                    value={line.price}
                                    onChange={(e) =>
                                      handleLineChange(Index, 'price', Number(e.target.value))
                                    }
                                    onInput={(e) => {
                                      const input = e.target as HTMLInputElement;
                                      input.value = input.value.replace(/[^0-9]/g, '');
                                    }}
                                  />
                                </td>

                                <td className={`align-middle small`}>
                                  <input
                                    disabled={line.lineType !== 'Item_Inv'}
                                    type="text"
                                    className={`form-control text-center px-0  ${
                                      line.lineType !== 'Item_Inv' ? 'bg-inverse bg-opacity-10' : ''
                                    }`}
                                    value={line.discountRate}
                                    onChange={(e) =>
                                      handleLineChange(
                                        Index,
                                        'discountRate',
                                        Number(e.target.value)
                                      )
                                    }
                                    onInput={(e) => {
                                      const input = e.target as HTMLInputElement;
                                      input.value = input.value.replace(/[^0-9]/g, '');
                                    }}
                                  />
                                </td>

                                <td className={`align-middle small`}>{line.discountAmount}</td>

                                <td className={`align-middle small`}>
                                  <input
                                    disabled={line.lineType !== 'Item_Inv'}
                                    type="text"
                                    className={`form-control text-center px-0  ${
                                      line.lineType !== 'Item_Inv' ? 'bg-inverse bg-opacity-10' : ''
                                    }`}
                                    value={line.vatRate}
                                    onChange={(e) =>
                                      handleLineChange(Index, 'vatRate', Number(e.target.value))
                                    }
                                    onInput={(e) => {
                                      const input = e.target as HTMLInputElement;
                                      input.value = input.value.replace(/[^0-9]/g, '');
                                    }}
                                  />
                                </td>

                                <td className={`align-middle smal  `}>{line.vatAmount}</td>

                                <td className="align-middle small">
                                  <input
                                    type="text"
                                    className="form-control text-center px-0"
                                    value={line.total}
                                    onChange={(e) =>
                                      handleLineChange(Index, 'total', Number(e.target.value))
                                    }
                                    onInput={(e) => {
                                      const input = e.target as HTMLInputElement;
                                      input.value = input.value.replace(/[^0-9]/g, '');
                                    }}
                                  />
                                </td>

                                <td className={`align-middle small `}>
                                  <Form.Control
                                    disabled={line.lineType === 'Item_Inv'}
                                    className={`form-control  ${
                                      line.lineType === 'Item_Inv' ? 'bg-inverse bg-opacity-10' : ''
                                    }`}
                                    as="textarea"
                                    rows={1}
                                    value={line.description || ''}
                                    onChange={(e) => {
                                      handleLineChange(Index, 'description', e.target.value);
                                    }}
                                  />
                                </td>

                                <td className="align-middle small">
                                  {line.codeCombination ? (
                                    <PopoverCustom
                                      childern={
                                        <Form.Control
                                          readOnly
                                          className="form-control text-center"
                                          type="text"
                                          // onChange={() => true}
                                          onClick={() => handleAccountClick(index)}
                                          value={line.codeCombination || ''}
                                        />
                                      }
                                      Header="تفاصيل الحـــساب"
                                      Body={handleShowAccountDetails(index)}
                                    />
                                  ) : (
                                    <Form.Control
                                      readOnly
                                      className="form-control text-center"
                                      type="text"
                                      // onChange={() => true}
                                      onClick={() => handleAccountClick(index)}
                                      value={line.codeCombination || ''}
                                    />
                                  )}
                                </td>

                                <td className="align-middle small">
                                  <button
                                    type="button"
                                    className="btn btn-outline-danger"
                                    onClick={() => handleDeleteLine(Index)}>
                                    <i className="fas fa-trash-alt"></i>
                                  </button>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                {/* </PerfectScrollbar> */}
              </div>
            </div>
          )}
        </>
      </Loading>

      <NeatixModal
        show={shawModalAcaount}
        setShow={setShawModalAcaount}
        size="md"
        title="الحســــاب"
        headerClassName="bg-theme bg-opacity-25 rounded-top-3 p-2"
        bodyClassName="p-0">
        <div className="p-2">
          <CodeCombination
            onAccountSelect={handleAccountSelect}
            accountDetails={accountDetails}
            organizationId={values?.organizationId} // الفرع
          />
        </div>
      </NeatixModal>
    </>
  );
};

export default ApInvoiceItemsLines;





  // const handleLineChange = (index: number, field: string, value: any) => {
  //   if (field === 'invItemId') {
  //     const updatedLines = [...values.arTransactionsLinesList];

  //     let price = ItemsOptions?.find((f) => f.value === value)?.salesPrice;
  //     let total = Number(updatedLines[index].qty) * Number(price);
  //     updatedLines[index] = {
  //       ...updatedLines[index],
  //       invItemId: value,
  //       price: Number(price),
  //       total,
  //     };

  //     formik?.setFieldValue('arTransactionsLinesList', updatedLines);

  //     return;
  //   }

  //   const updatedLines = [...values.arTransactionsLinesList];
  //   updatedLines[index] = {
  //     ...updatedLines[index],
  //     [field]: value,
  //   };

  //   // Recalculate total and VAT if needed
  //   if (field === 'qty' || field === 'price' || field === 'vatRate') {
  //     const total = Number(updatedLines[index].qty) * Number(updatedLines[index].price);
  //     const vatAmount = (total * Number(updatedLines[index].vatRate)) / 100;
  //     updatedLines[index] = {
  //       ...updatedLines[index],
  //       total,
  //       vatAmount,
  //     };
  //   }

  //   // setLines(updatedLines);
  //   formik?.setFieldValue('arTransactionsLinesList', updatedLines);
  // };



// {/* <th className="align-middle  small" style={{ width: '30%' }}>
//   {t('اسم الصنف')}
// </th>
// <th className="align-middle  small" style={{ width: '15%' }}>
//   {t('الوحدة')}
// </th>
// <th className="align-middle  small" style={{ width: '5%' }}>
//   {t('الكمية')}
// </th>
// <th className="align-middle  small" style={{ width: '5%' }}>
//   {t('السعر')}
// </th>
// <th className="align-middle  small" style={{ width: '5%' }}>
//   {t('نسبة الخصم')}
// </th>
// <th className="align-middle  small" style={{ width: '5%' }}>
//   {t('قيمة الخصم')}
// </th>
// <th className="align-middle  small" style={{ width: '5%' }}>
//   {t('VAT %')}
// </th>
// <th className="align-middle  small" style={{ width: '5%' }}>
//   {t('VAT')}
// </th> */}

// <td className="align-middle small">
// <Select
//   classNamePrefix="react-select"
//   className="w-100"
//   isSearchable
//   isClearable
//   options={ItemsOptions?.filter(
//     (f) =>
//       !values.arTransactionsLinesList.some(
//         (line: any) => line.invItemId === f.value
//       )
//   )}
//   onChange={(option) =>
//     handleLineChange(
//       Index,
//       'invItemId',
//       option === null ? null : option.value
//     )
//   }
// value={
//   line.invItemId === null
//     ? null
//     : ItemsOptions?.find((option) => option.value === line.invItemId)
// }
//   placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
//   menuPortalTarget={document.body}
//   styles={{
//     menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
//   }}
// />
// </td>
// <td className="align-middle small">
// <select
//   className="form-select text-center"
//   value={line.uom || ''}
//   onChange={(e) => handleLineChange(Index, 'uom', e.target.value)}>
//   <option value="">
//     {i18n.language === 'ar' ? 'إختر' : 'Choose'}
//   </option>
//   {/* {ItemConversionList?.filter((f) => f.itemId === line.itemId)?.map(
//     (item, idx) => (
//       <option key={++idx} value={item.uom}>
//         {i18n.language === 'en' ? item.uomName : item.uomName2}
//       </option>
//     )
//   )} */}
//   {GetUom(line)?.map((item, idx) => (
//     <option key={++idx} value={item.uom}>
//       {i18n.language === 'en' ? item.uomName : item.uomName2}
//     </option>
//   ))}
// </select>
// </td>
// <td className="align-middle small">
// <input
//   type="text"
//   className="form-control text-center px-0"
//   value={line.qty}
//   onChange={(e) =>
//     handleLineChange(Index, 'qty', Number(e.target.value))
//   }
//   onInput={(e) => {
//     // إلغاء أي إدخال غير رقمي يدويًا
//     const input = e.target as HTMLInputElement;
//     input.value = input.value.replace(/[^0-9]/g, '');
//   }}
// />
// </td>
// <td className="align-middle small">
// <input
//   type="text"
//   className="form-control text-center px-0"
//   value={line.price}
//   onChange={(e) =>
//     handleLineChange(Index, 'price', Number(e.target.value))
//   }
//   onInput={(e) => {
//     // إلغاء أي إدخال غير رقمي يدويًا
//     const input = e.target as HTMLInputElement;
//     input.value = input.value.replace(/[^0-9]/g, '');
//   }}
// />
// </td>
// <td className="align-middle small">
// <input
//   type="text"
//   className="form-control text-center px-0"
//   value={line.discountRate}
//   onChange={(e) =>
//     handleLineChange(Index, 'discountRate', Number(e.target.value))
//   }
//   onInput={(e) => {
//     // إلغاء أي إدخال غير رقمي يدويًا
//     const input = e.target as HTMLInputElement;
//     input.value = input.value.replace(/[^0-9]/g, '');
//   }}
// />
// </td>
// <td className="align-middle small">{line.discountAmount}</td>
// <td className="align-middle small">
// <input
//   type="text"
//   className="form-control text-center px-0"
//   value={line.vatRate}
//   onChange={(e) =>
//     handleLineChange(Index, 'vatRate', Number(e.target.value))
//   }
//   onInput={(e) => {
//     // إلغاء أي إدخال غير رقمي يدويًا
//     const input = e.target as HTMLInputElement;
//     input.value = input.value.replace(/[^0-9]/g, '');
//   }}
// />
// </td>
// <td className="align-middle small">{line.vatAmount}</td>
