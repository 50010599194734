import React, { Fragment } from 'react';
import Modal from 'react-bootstrap/Modal';

function NeatixModal({
  size, // sm  - lg  - xl
  show,
  setShow,
  title = '',
  children,
  bodyClassName = '',
  headerClassName = '',
  showHeader = true,
  fullscreen = false,
  backdrop = false,
  keyboard = true,  // اذا كان fslse  يتم منع الاغلاق بواسطة لوحة المفاتيح
}) {

  const handleClose = () => {
    if (backdrop === 'static') {
      // Prevent closing if certain conditions are not met
      return; // Example: you can add your own conditions here
    }
    setShow(false); // Allow closing the modal
  };

  return (
    <Fragment>
      <Modal
        size={size}
        show={show}
        onHide={handleClose}
        // onHide={() => setShow(false)}
        aria-labelledby="neatix-modal-title"
        backdrop={backdrop ? 'static' : true}
        keyboard={keyboard}
        fullscreen={fullscreen}>
        {showHeader && (
          <Modal.Header closeButton className={headerClassName}>
            <Modal.Title id="neatix-modal-title">{title}</Modal.Title>
          </Modal.Header>
        )}

        <Modal.Body className={bodyClassName}>{children}</Modal.Body>
      </Modal>
    </Fragment>
  );
}

export default NeatixModal;
