/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  GetEquipmentsRoutesById,
  GetEquipmentsRoutesList,
  GetListLocations,
  UpdatEquipmentsRoutes,
} from "../../../store/FLEET/EquipmentsRoutes/equipmentsRoutesSlice";
import { useFormik } from "formik";
import CustomAlert from "../../../components/Shared/Alert/CustomAlert";
import Breadcrumb from "../../../components/Shared/Breadcrumb";
import { Card, CardBody } from "../../../components/card/card";
import { Form } from "react-bootstrap";
import RouteJourneyPlanList from "./RouteJourneyPlanList";
import Loader from "../../../components/Shared/Loader/Loader";
import { EquipmentRoutesSchema } from "../../ValidationForm/validationSchema";
import Swal from "sweetalert2";

const EditEquipmentRoutes = () => {
  const { Id, equipmentRoutesID } = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const Navigate = useNavigate();
  const [JourneyPlanList, setJourneyPlanList] = useState<any[]>([]);

  useEffect(() => {
    dispatch(GetListLocations());
    if (equipmentRoutesID) {
      dispatch(GetEquipmentsRoutesById(equipmentRoutesID));
    }
  }, []);
  const { EquipmentsRoutesById, loading } = useAppSelector(
    (state) => state.EquipmentsRoutes
  );

  useEffect(() => {
    setJourneyPlanList(EquipmentsRoutesById?.routeJourneyPlanList ?? []);
  }, [EquipmentsRoutesById]);

  const formik = useFormik({
    initialValues: {
      fleetEquipmentsId: EquipmentsRoutesById?.fleetEquipmentsId ?? "",
      name: EquipmentsRoutesById?.name ?? "",
      name2: EquipmentsRoutesById?.name2 ?? "",
      startTime: EquipmentsRoutesById?.startTime ?? "",
      endTime: EquipmentsRoutesById?.endTime ?? "",
      description: EquipmentsRoutesById?.description ?? "",
      routeJourneyPlanList: {
        fleetEquipmentsRouteId: "",
        referenceId: "",
        referenceType: "",
        sequence: "",
      },
    },
    enableReinitialize: true,
    validationSchema: EquipmentRoutesSchema(t),
    onSubmit: (values) => {
      if (JourneyPlanList?.length < 2) {
        Swal.fire("يجب اختيار  (ماذا اكتب)");
        formik.setSubmitting(false);
        return;
      }

      dispatch(
        UpdatEquipmentsRoutes({
          id: equipmentRoutesID,
          fleetEquipmentsId: Id,
          name: values.name,
          name2: values.name2,
          startTime: values.startTime,
          endTime: values.endTime,
          description: values.description,
          routeJourneyPlanList: JourneyPlanList?.map((item) => ({
            id: item.id,
            // fleetEquipmentsRouteId: item.fleetEquipmentsRouteId,
            referenceId: item.referenceId,
            referenceType: item.referenceType,
            sequence: item.sequence,
          })),
        })
      )
        .unwrap()
        .then((res) => {
          if (res?.succeeded === true) {
            CustomAlert({ action: "Edit" });
            dispatch(GetEquipmentsRoutesList());
            formik.resetForm();
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: "Error", msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: "Error" });
        });
    },
  });

  //____________________________
  var BcrumbList = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "Equipment",
      link: "/FLEET/Equipments",
    },
    {
      name: "Journeys",
      link: null,
    },
    {
      name: "Edit",
      link: null,
    },
  ];
  return (
    <div>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName="Journeys" />
      <Card>
        <CardBody>
          <Loader loading={loading} />
          <Form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-xl-4">
                <div className="form-group mb-3">
                  <label className="form-label">{t("LocalName")}</label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="name2"
                    onChange={formik.handleChange}
                    value={formik.values.name2}
                    isInvalid={!!(formik.touched.name2 && formik.errors.name2)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.name2}
                  </Form.Control.Feedback>
                </div>
              </div>

              <div className="col-xl-4">
                <div className="form-group mb-3">
                  <label className="form-label">{t("GlobalName")}</label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="name"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    isInvalid={!!(formik.touched.name && formik.errors.name)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.name}
                  </Form.Control.Feedback>
                </div>
              </div>

              <div className="col-xl-2">
                <div className="form-group mb-3">
                  <label className="form-label">{t("StartTime")}</label>
                  <Form.Control
                    className="form-control"
                    type="time"
                    name="startTime"
                    onChange={formik.handleChange}
                    value={formik.values.startTime}
                    isInvalid={
                      !!(formik.touched.startTime && formik.errors.startTime)
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.startTime}
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="col-xl-2">
                <div className="form-group mb-3">
                  <label className="form-label">{t("EndTime")}</label>
                  <Form.Control
                    className="form-control"
                    type="time"
                    name="endTime"
                    onChange={formik.handleChange}
                    value={formik.values.endTime}
                    isInvalid={
                      !!(formik.touched.endTime && formik.errors.endTime)
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.endTime}
                  </Form.Control.Feedback>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="form-group mb-3">
                  <label className="form-label">{t("Note")}</label>
                  <Form.Control
                    className="form-control"
                    as="textarea"
                    rows={2}
                    name="description"
                    onChange={formik.handleChange}
                    value={formik.values.description}
                  />
                </div>
              </div>
            </div>

            <RouteJourneyPlanList
              JourneyPlanList={JourneyPlanList}
              setJourneyPlanList={setJourneyPlanList}
            />

            <hr />
            <div className="text-center mt-2">
              <button
                disabled={formik.isSubmitting}
                type="submit"
                className="btn me-1 btn-theme mb-1"
              >
                {formik.isSubmitting ? (
                  <div className="spinner-border spinner-border-sm me-2"></div>
                ) : (
                  <i className="fa fa-save fa-fw me-2"></i>
                )}
                {t("Save")}
              </button>
              <button
                type="button"
                className=" btn me-1 btn-default mb-1"
                onClick={() => Navigate(-1)}
              >
                <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
              </button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
};

export default EditEquipmentRoutes;
