import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { GetPosCashersByPosId, deletePosCashers } from '../../../../store/Sales/Pos/PosCashierSlice';
import { Link } from 'react-router-dom';
import Loading from '../../../../components/Shared/Loader/Loading';
import showConfirmation from '../../../../components/Shared/Alert/Confirmation';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import useGetCashierListByPosId from '../../../../hooks/POS/useGetCashierListByPosId';

type Props = {
  posId: string;
};

const PosCashierList = ({ posId }: Props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();

  const {PosCashierList , error , loading} = useGetCashierListByPosId({posId : posId});

  // useEffect(() => {
  //   dispatch(GetPosCashersByPosId(posId));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [posId]);

  // const { PosCashierListByPosId, loading, error } = useAppSelector((a) => a.PosCashier);

 


  const handleDelete = (id:any) => {
    showConfirmation(t('MsgConfirm'), () => {
      dispatch(deletePosCashers(id))
      .unwrap()
      .then(() => {
        CustomAlert({action:"Delete"})
        dispatch(GetPosCashersByPosId(posId))
      })
      .catch((error) => {
        console.log(error);
        CustomAlert({action:"info"})
      });
    });
  };

  return (
    <Loading loading={loading} error={error}>
      <div>
        <div className="table-responsive rounded-1 text-center ">
          {
            PosCashierList?.length >0 ? (
          <table className="table table-hover">
            <thead className="bg-theme bg-opacity-60">
              <tr>
                <th scope="col">الكاشير</th>
                <th scope="col">{t('من تاريخ')}</th>
                <th scope="col">{t('الى تاريخ')}</th>
                <th scope="col"></th>
              </tr>
            </thead>

            <tbody>
              {PosCashierList?.map((item, index) => (
                <tr key={index}>
                  <td>{i18n.language === 'en' ? item.personName : item.personName2}</td>
                  <td>{item.startDate}</td>
                  <td>{item.endDate}</td>
                  <td>
                    <Link
                      to=""
                      style={{ color: 'red' }}
                      onClick={() => handleDelete(item.id)}
                    >
                      <i className="fas fa-lg fa-fw me-3 fa-trash-alt"></i>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
            ) : 
            <div className="text-center bg-inverse bg-opacity-10 rounded-2 p-3">
           لا توجد بيانات متاحة
          </div>
          }

        </div>
      </div>
    </Loading>
  );
};

export default PosCashierList;
