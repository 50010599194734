import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { Card, CardBody } from '../../../../components/card/card';
import Pagination from '../../../../components/Shared/Pagination/Pagination';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
// import MedModalComponent from "../../../../components/Modal/MedModalComponent";
import Loader from '../../../../components/Shared/Loader/Loader';
import useGetInventoryCountOpenList from '../../../../hooks/INV/InventoryCount/useGetInventoryCountOpenList';

const Index = () => {
  // const [Show, setShow] = useState(false);
  // const [inventoryId, setInventoryId] = useState("");
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const { t, i18n } = useTranslation();
  const Navigate = useNavigate();

  // const { Organizations, loading } = useGetOrganizations();

  const { InventoryCountOpenList, error, loading } = useGetInventoryCountOpenList();

  const filteredSearch = InventoryCountOpenList?.slice()?.filter((item) => {
    const searchLowerCase = search.toLowerCase();
    return item.name?.toLowerCase().includes(searchLowerCase);
  });

  // console.log(InventoryCountOpenList);

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);
  //________________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'InventoryCount',
      link: null,
    },
  ];

  return (
    <div>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName="InventoryCount" />

        <div className="ms-auto">
          <Link to="AddInventoryCount" className="btn me-1 btn-outline-theme">
            <i className="fa fa-plus-circle fa-fw me-1"></i>
            {t('إضافة جرد جديد')}
          </Link>
        </div>
      </div>

      <Card>
        {/* <Loader loading={loading} /> */}

        <CardBody>
          <div className="row">
            <div className="col-7 col-md-6 d-flex justify-content-start">
              <div className="input-group mb-2">
                <div className="flex-fill position-relative">
                  <div className="input-group">
                    <div
                      className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                      style={{ zIndex: 1020, right: '10px' }}>
                      <i className="fa fa-search opacity-5"></i>
                    </div>
                    <input
                      type="text"
                      className="form-control ps-35px"
                      onChange={(e) => setSearch(e.target.value)}
                      placeholder={t('Search') + ' ...'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* //  START TABLE   */}
          <div className="table-responsive">
            <table className="table table-hover">
              <thead className="table-dark">
                <tr>
                  <th scope="col">{t('الأسم')}</th>

                  <th scope="col">{t('الفرع')}</th>
                  <th scope="col">{t('المخزن')}</th>
                  <th scope="col">{t('الذي قام بالجرد')}</th>
                  <th scope="col">{t('تاريخ البدء')}</th>
                  <th scope="col">{t('تاريخ التجميد')}</th>

                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {currentData?.map((item, index: number) => (
                  <tr key={++index}>
                    <td className="align-middle">
                      {i18n.language === 'en' ? item.name : item.name2}
                    </td>
                    <td className="align-middle">
                      {i18n.language === 'en' ? item.branchName : item.branchName2}
                    </td>
                    <td className="align-middle">
                      {i18n.language === 'en' ? item.inventoryName : item.inventoryName2}
                    </td>
                    <td className="align-middle">
                      {i18n.language === 'en' ? item.personName : item.personName2}
                    </td>
                    <td className="align-middle">{item.startDate?.split('T')[0]}</td>
                    <td className="align-middle">{item.freezeDate?.split('T')[0]}</td>

                    <td className="align-middle text-end">
                      <div className="btn-group">
                        <button
                          type="button"
                          className="btn btn-outline-theme"
                          // onClick={() => setInventoryId(item.id)} //Departments/:id/Edit
                        >
                          <i className="far fa-lg me-2 fa-fw fa-edit"></i>
                          {t('Button.Edit')}
                        </button>
                        <button
                          type="button"
                          className="btn btn-outline-theme"
                          onClick={()=> Navigate(`${item.id}/InventoryCountEntries`)}
                         >
                          <i className="fas fa-lg me-2 fa-fw fa-boxes"></i>
                          {t('إدخالات الجرد')}
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination
            filteredData={filteredSearch}
            dataPerPage={dataPerPage}
            handlePagination={(pageNumber: any) => setCurrentPage(pageNumber)}
            currentPage={currentPage}
          />
        </CardBody>
      </Card>
    </div>
  );
};

export default Index;
