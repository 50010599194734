import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { Form } from 'react-bootstrap';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import useGetOrganizations from '../../../../hooks/HR/use-get-organizations';
import { GetPosById, addPos, updatePos } from '../../../../store/Sales/Pos/PosSlice';
import useGetPrintTemplateList from '../../../../hooks/NTX/useGetPrintTemplateList';
import { useParams } from 'react-router-dom';
import Loading from '../../../../components/Shared/Loader/Loading';
import { get_POS_TYPE } from '../../../../store/Lookup/lookupSlice';

const UpdatePos = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { posId } = useParams<string>();
  const { OrganizationInventory, ShopsOptions } = useGetOrganizations();
  const { PrintTemplateList } = useGetPrintTemplateList();

  useEffect(() => {
    dispatch(get_POS_TYPE());
  }, []);

  const { POS_TYPE } = useAppSelector((state) => state.Lookup);

  useEffect(() => {
    if (posId) {
      dispatch(GetPosById(posId));
    }
  }, [posId]);
  const { PosById: data, loading, error } = useAppSelector((a) => a.Pos);

  const formik = useFormik({
    initialValues: {
      id: data ? data.id : '',
      posType: data ? data.posType : '',
      name: data ? data.name : '',
      name2: data ? data.name2 : '',
      organizationId: data ? data.organizationId : '',
      isActive: data ? data.isActive : false,
      imageItemShow: data ? data.imageItemShow : false,
      imageCategoryShow: data ? data.imageCategoryShow : false,
      printTemplateId: data ? data.printTemplateId : '',
      inventoryId: data ? data.inventoryId : '',
    },
    enableReinitialize: true,
    // validationSchema: formOrganization(t),

    onSubmit: (values) => {
      dispatch(
        updatePos({
          id: values.id,
          posType: values.posType,
          name: values.name,
          name2: values.name2,
          organizationId: values.organizationId,
          isActive: values.isActive,
          imageItemShow: values.imageItemShow,
          imageCategoryShow: values.imageCategoryShow,
          printTemplateId: values.printTemplateId,
          inventoryId: values.inventoryId,
        })
      )
        .unwrap()
        .then((res) => {
          if (res.succeeded === true) {
            formik.resetForm();
            // document.getElementById("AddPriceList")?.click();
            CustomAlert({ action: 'Edit' });
            formik.setSubmitting(false);
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error' });
          }
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
      formik.setSubmitting(false);
    },
  });

  return (
    <div>
      <Loading loading={loading} error={error}>
        <Form onSubmit={formik.handleSubmit}>
          <div className="row mb-3">
            <div className="col-lg-4">
              <div className="form-group mb-3">
                <label className="form-label">{t('LocalName')}</label>
                <Form.Control
                  className="form-control fw-bold"
                  type="text"
                  name="name2"
                  onChange={formik.handleChange}
                  value={formik.values.name2 || ''}
                  isInvalid={!!(formik.touched.name2 && formik.errors.name2)}
                />
                <Form.Control.Feedback type="invalid">{formik.errors.name2}</Form.Control.Feedback>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="form-group mb-3">
                <label className="form-label">{t('GlobalName')}</label>
                <Form.Control
                  className="form-control fw-bold"
                  type="text"
                  name="name"
                  onChange={formik.handleChange}
                  value={formik.values.name || ''}
                  isInvalid={!!(formik.touched.name && formik.errors.name)}
                />
                <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="form-group mb-3">
                <label className="form-label"> {t('Type')} </label>
                <Form.Select
                  className="form-select"
                  name="posType"
                  onChange={formik.handleChange}
                  value={formik.values.posType || ''}
                  isInvalid={!!(formik.touched.posType && formik.errors.posType)}>
                  <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>

                  {POS_TYPE &&
                    POS_TYPE?.map((item: any, idx) =>
                      item.list?.map((list: any) => (
                        <option key={++idx} value={list.lookupCode}>
                          {i18n.language === 'ar' ? list.meaning2 : list.meaning}
                        </option>
                      ))
                    )}
                </Form.Select>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="form-group mb-3">
                <label className="form-label">{t('المخزن')}</label>
                <Form.Select
                  className="form-select "
                  name="inventoryId"
                  onChange={formik.handleChange}
                  value={formik.values.inventoryId || ''}
                  isInvalid={!!(formik.touched.inventoryId && formik.errors.inventoryId)}>
                  <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                  {OrganizationInventory &&
                    OrganizationInventory?.map((item: any) => (
                      <option key={item.id} value={item.id}>
                        {i18n.language === 'en' ? item.name : item.name2}
                      </option>
                    ))}
                </Form.Select>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="form-group mb-3">
                <label className="form-label">{t('الفرع')}</label>
                <Form.Select
                  className="form-select "
                  name="organizationId"
                  onChange={formik.handleChange}
                  value={formik.values.organizationId || ''}
                  isInvalid={!!(formik.touched.organizationId && formik.errors.organizationId)}>
                  <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                  {ShopsOptions &&
                    ShopsOptions?.map((item: any) => (
                      <option key={item.id} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                </Form.Select>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="form-group mb-3">
                <label className="form-label">{t('قالب الطباعة')}</label>
                <Form.Select
                  className="form-select "
                  name="printTemplateId"
                  onChange={formik.handleChange}
                  value={formik.values.printTemplateId || ''}
                  isInvalid={!!(formik.touched.printTemplateId && formik.errors.printTemplateId)}>
                  <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                  {PrintTemplateList &&
                    PrintTemplateList?.map((item: any) => (
                      <option key={item.id} value={item.id}>
                        {i18n.language === 'en' ? item.name : item.name2}
                      </option>
                    ))}
                </Form.Select>
              </div>
            </div>

            <div className="col-xl-4 text-center">
              <div className="form-group  mb-2 bg-inverse bg-opacity-5 rounded-3 pb-1">
                <Form.Check
                  style={{ margin: '10px 20px' }}
                  className="pt-3"
                  type="checkbox"
                  label="تفعيل"
                  name="isActive"
                  checked={formik.values.isActive}
                  onChange={formik.handleChange}
                />
              </div>
            </div>

            <div className="col-xl-4 text-center">
              <div className="form-group  mb-2 bg-inverse bg-opacity-5 rounded-3 pb-1">
                <Form.Check
                  style={{ margin: '10px 20px' }}
                  className="pt-3"
                  type="checkbox"
                  label="عرض صورة المنتج"
                  name="imageItemShow"
                  checked={formik.values.imageItemShow}
                  onChange={formik.handleChange}
                />
              </div>
            </div>

            <div className="col-xl-4 text-center">
              <div className="form-group  mb-2 bg-inverse bg-opacity-5 rounded-3 pb-1">
                <Form.Check
                  style={{ margin: '10px 20px' }}
                  className="pt-3"
                  type="checkbox"
                  label="عرض صورة الفئة"
                  name="imageCategoryShow"
                  checked={formik.values.imageCategoryShow}
                  onChange={formik.handleChange}
                />
              </div>
            </div>
          </div>
          {/* 
        <div className="text-center">
          <button
            disabled={formik.isSubmitting}
            type="submit"
            className="btn me-1 btn-theme mb-1"
            id="SaveSubmit">
            {formik.isSubmitting ? (
              <div className="spinner-border spinner-border-sm me-2"></div>
            ) : (
              <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
            )}
            {t('Add')}
          </button>
 
        </div> */}
        </Form>
      </Loading>
    </div>
  );
};

export default UpdatePos;
