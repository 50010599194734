import { debug } from 'console';
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-querybuilder';
import { TagsInput } from 'react-tag-input-component';

interface Props {
  fields: Field[];
  params: any[];
  setCheckValidationStep2: React.Dispatch<React.SetStateAction<boolean>>;
  setDataStep2: React.Dispatch<React.SetStateAction<any[]>>;
  notificationParametersById: any[];
}

interface SelectedValue {
  id?: any;
  paramName: string;
  paramLabel: any;
  paramValue: string;
  inputType: string;
  beginWith: string;
  endWith: string;
  hasIn: boolean;
  dateType?: any;
  currentDateWithValue: any;
}

const Step2: React.FC<Props> = ({
  params,
  setCheckValidationStep2,
  setDataStep2,
  fields,
  notificationParametersById,
}) => {
  const { t } = useTranslation();

  const [tagsPerRow, setTagsPerRow] = useState<string[][]>([]);

  const handleGetParamLable = (ParamName: string) => {
    let fieldName: string = ParamName.replace(/_[^_]*$/, '');
    return fields?.find((f) => f.name === fieldName)?.label;
  };

  const [ReportParamerersList, setReportParamerersList] = useState<SelectedValue[]>(
    notificationParametersById?.map((item, idx) => ({
      id: item.id,
      paramLabel: handleGetParamLable(item.paramName),
      paramName: item.paramName,
      paramValue: item.paramValue,
      // inputType: item.inputType,
      inputType: params?.find((f) => f.paramName === item.paramName)?.inputType || 'text',
      //
      beginWith: item.beginWith,
      endWith: item.endWith,
      hasIn: item.hasIn,
      currentDateWithValue: item.currentDateWithValue,
    }))
  );

  useEffect(() => {
    const newList = params?.map((item, idx) => ({
      id: ReportParamerersList?.find((f) => f.paramName === item.paramName)?.id || null,
      paramLabel: handleGetParamLable(item.paramName),
      paramName: item.paramName,
      paramValue:
        ReportParamerersList?.find((f) => f.paramName === item.paramName)?.paramValue || '',
      inputType: item.inputType,
      beginWith: item.beginWith,
      endWith: item.endWith,
      hasIn: item.hasIn,
      currentDateWithValue: '',
    }));

    setReportParamerersList([...newList]);
  }, [params]);

  const handleTagsInputChange = (tags: string[], index: number) => {
    const updatedTagsPerRow = [...tagsPerRow];
    updatedTagsPerRow[index] = tags;
    setTagsPerRow(updatedTagsPerRow);

    const updatedParamsList = [...ReportParamerersList];
    updatedParamsList[index].paramValue = tags.join(', ');
    setReportParamerersList(updatedParamsList);
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    index: number
  ) => {
    const { name, value, type } = event.target;

    const updatedParamsList = [...ReportParamerersList];
    if (name === 'currentDateWithValue') {
      updatedParamsList[index] = {
        ...updatedParamsList[index],
        paramValue: '',
      };
    }

    updatedParamsList[index] = {
      ...updatedParamsList[index],
      [name]: value,
      // [name]: type === "checkbox" ? (event.target as HTMLInputElement).checked : value,
    };

    setReportParamerersList(updatedParamsList);
  };

  //________________بداية كود عملية التحقق validation ___________
  const [validationErrors, setValidationErrors] = useState<
    | {
        paramValue: string[];
        paramLabel: string[];
      }
    | undefined
  >(undefined);

  useEffect(() => {
    const updatedValidationErrors: {
      paramValue: string[];
      paramLabel: string[];
    } = {
      paramValue: [],
      paramLabel: [],
    };

    ReportParamerersList.forEach((param, index) => {
      if (!param.paramValue) {
        updatedValidationErrors.paramValue[index] = '*';
      }
    });

    const hasValidationErrors = Object.values(updatedValidationErrors)?.some(
      (errorArray) => errorArray?.length > 0
    );

    setCheckValidationStep2(!hasValidationErrors || ReportParamerersList?.length === 0);
    // setCheckValidationStep2(true);

    setValidationErrors(updatedValidationErrors);
  }, [ReportParamerersList]);

  //نهاية  كود عملية التحقق validation ___________

  //______  جلب البيانات من اجل ارسالها للسيرفر
  useEffect(() => {
    setDataStep2(ReportParamerersList);
  }, [ReportParamerersList]);

  return (
    <div>
      {ReportParamerersList?.length > 0 ? (
        <div className="table-responsive  rounded-2 pt-0 border  ">
          <table className="table table-borderless table-hover m-0 ">
            <thead className="bg-theme bg-opacity-60">
              <tr>
                <th scope="col" style={{ textAlign: 'center', width: '40%' }}>
                  {t('Parameter_Name')}
                </th>
                <th scope="col" style={{ textAlign: 'center', width: '60%' }}>
                  {t('Parameter_Value')}
                </th>
              </tr>
            </thead>
            <tbody>
              {params &&
                ReportParamerersList?.map((item, index) => (
                  <tr key={index}>
                    <td className="align-middle" style={{ textAlign: 'center', width: '40%' }}>
                      {item.paramLabel}
                    </td>

                    <td style={{ width: '60%' }} className="align-middle">
                      <div className=" d-flex">
                        {ReportParamerersList[index]?.inputType === 'date' && (
                          <select
                            className="form-select"
                            name="currentDateWithValue"
                            value={ReportParamerersList[index]?.currentDateWithValue}
                            onChange={(event) => handleInputChange(event, index)}>
                            <option value="staticDate">قيمة ثابته</option>
                            <option value="CurrentDateWithValue">
                              التاريخ الحالي بالإضافة الى
                            </option>
                          </select>
                        )}

                        {item.hasIn === false ? (
                          <input
                            style={{
                              textAlign: 'center',
                            }}
                            className="form-control "
                            name="paramValue"
                            type={
                              item.inputType === 'date'
                                ? ReportParamerersList[index]?.currentDateWithValue ===
                                  'CurrentDateWithValue'
                                  ? 'number'
                                  : 'date'
                                : item.inputType === 'time'
                                ? 'time'
                                : item.inputType === 'number'
                                ? 'number'
                                : 'text'
                            }
                            value={ReportParamerersList[index]?.paramValue || ''}
                            onChange={(event) => handleInputChange(event, index)}
                            required
                          />
                        ) : (
                          <div style={{ maxWidth: '250px' }}>
                            <TagsInput
                              key={index}
                              value={tagsPerRow[index] || []}
                              onChange={(tags) => handleTagsInputChange(tags, index)}
                            />
                          </div>
                        )}
                      </div>
                      <div className="text-danger">{validationErrors?.paramValue[index]}</div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="border border-theme text-center bg-inverse bg-opacity-10 rounded-2 p-3 fw-bold">
          {t(
            'يرجى إضافة قواعد لتخصيص إشعاراتك. ابدأ الآن بتحديد القواعد المناسبة لتحسين تجربة إشعاراتك وتخصيصها وفقًا لاحتياجاتك.'
          )}
        </div>
      )}
    </div>
  );
};

export default memo(Step2);
