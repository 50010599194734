import { Fragment, useState } from 'react';
import Breadcrumb from '../../../../../components/Shared/Breadcrumb';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Card, CardBody } from '../../../../../components/card/card';
import ModalComponent from '../../../../../components/Modal/ModalComponent';
import Pagination from '../../../../../components/Shared/Pagination/Pagination';
import useGetPeoplesList from '../../../../../hooks/NTX/useGetPeoplesList';
import AddBranchesCustomer from './AddBrancheSuppliers';
import PeoplesContacts from '../../../../NTX/Peoples/PeoplesContacts/PeoplesContacts';
import useGetSuppliersList from '../../../../../hooks/NTX/Peoples/useGetSuppliersList';

const Index = () => {
  const [peopleData, setPeopleData] = useState<any>('');

  const [Show, setShow] = useState(false);
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

   const {SuppliersList} = useGetSuppliersList();

  const filteredSearch = SuppliersList?.slice()
    ?.filter((f) => {
      const searchLowerCase = search.toLowerCase();
      return (
        f?.name?.toLowerCase()?.includes(searchLowerCase) ||
        f?.name2?.toLowerCase()?.toString()?.includes(searchLowerCase)
      );
    })
    ?.filter((f) => f.parentId === id);

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);
  //_______________________________________

  const location = useLocation();
  const { name } = location?.state;

  const titleModal = () => {
    return (
      <div className="">
        بيانات التواصل للعميــل :{'  '}
        <span className="text-theme">
          {`(${i18n.language === 'en' ? peopleData?.name : peopleData?.name2})`}
        </span>
      </div>
    );
  };
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: t('الموردين'),
      link: '/SalesSettings/Customers',
    },
    {
      name: t('الفروع'),
      link: null,
    },
  ];
  return (
    <Fragment>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName={t('فروع المورد')} subtitle={name} />

        <div className="ms-auto">
          <Link
            to=""
            className="btn me-1 btn-theme"
            data-bs-toggle="modal"
            data-bs-target="#modalAddCustomers"
            onClick={() => setShow(true)}>
            <i className="fa fa-plus-circle fa-fw me-1"></i>
            إضافة فرع للمورد {name}
          </Link>
          <Link
            to="/PurchasesSettings/SUPPLIERS"
            
            className="btn me-1 btn-outline-secondary"
            // onClick={() => navigate(-1) }
            replace>
            {t('Back')}
          </Link>
        </div>
      </div>

      <Card>
        <CardBody>
          <div className="tab-content  ">
            <div className="row">
              <div className="col-7 col-md- d-flex justify-content-start">
                <div className="input-group mb-2">
                  <div className="flex-fill position-relative">
                    <div className="input-group">
                      <div
                        className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                        style={{ zIndex: 1020, right: '10px' }}>
                        <i className="fa fa-search opacity-5"></i>
                      </div>
                      <input
                        type="text"
                        className="form-control ps-35px"
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder={t('Search') + ' ...'}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="table-responsive">
              <table className="table table-hover text-nowrap">
                <thead className="table-dark">
                  <tr>
                    <th scope="col">{t('Name')}</th>
                    <th scope="col">{t('رقم العميل')}</th>
                    <th scope="col">{t('الأسم التجاري')}</th>
                    <th scope="col">{t('قائمة الأسعار')}</th>

                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {currentData?.map((item, index) => (
                    <tr key={item.id}>
                      <td className="align-middle">
                        {i18n.language === 'en' ? item.name : item.name2}
                      </td>
                      <td className="align-middle">{item.customerNumber || '0000'}</td>
                      <td className="align-middle">{item.tradingName}</td>
                      <td className="align-middle">
                        {i18n.language === 'en' ? item.priceListName : item.priceListName2}
                      </td>
                      <td className="text-end">
                        <div className="btn-group">
                          <Link
                            to={``}
                            data-bs-toggle="modal"
                            data-bs-target="#CustomersContactsTest"
                            className="btn btn-outline-theme"
                            onClick={() => setPeopleData(item)}>
                            <i className="far fa-lg me-2  bi bi-telephone"></i>
                            {t('بيانات التواصل')}
                          </Link>

                          <Link
                            to={`/PurchasesSettings/SUPPLIERS/${item.id}/UpdateSupplier`}
                            className="btn btn-outline-theme"
                            state={{
                              name: i18n.language === 'en' ? item.name : item.name2,
                            }}>
                            <i className="far fa-lg fa-fw  me-2 fa-edit"></i>
                            {t('Button.Edit')}
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Pagination
              filteredData={filteredSearch}
              dataPerPage={dataPerPage}
              handlePagination={(pageNumber: number) => setCurrentPage(pageNumber)}
              currentPage={currentPage}
            />
          </div>
        </CardBody>
      </Card>

      <ModalComponent title={` إضافة فرع للمورد ${name}`} id="modalAddCustomers">
        {Show && <AddBranchesCustomer />}
      </ModalComponent>

      <ModalComponent title={titleModal()} id="CustomersContactsTest">
        {peopleData?.id && <PeoplesContacts peopleId={peopleData.id} />}
      </ModalComponent>
    </Fragment>
  );
};

export default Index;
