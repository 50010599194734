import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { GetRoleList } from '../../../../store/NTX/Security/Rules/RulesSlice';
import useLookupModule from '../../../../hooks/Lookups/use-Lookup-Module';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { Card } from '../../../../components/card/card';
// import Loader from '../../../../components/Shared/Loader/Loader';
import MedModalComponent from '../../../../components/Modal/MedModalComponent';
import AddRule from './AddRule';
import Pagination from '../../../../components/Shared/Pagination/Pagination';
import UpdateRules from './UpdateRules';
import { usePermissions } from '../../../../Helper/Permisstions/usePermissions';
import Loading from '../../../../components/Shared/Loader/Loading';

const Index = () => {
  const { HasPermission } = usePermissions();

  const { t, i18n } = useTranslation();
  const Navigate = useNavigate();
  const [rulesId, setRulesId] = useState<string>();
  //////////////////////////////////////////////////////////////////////////////////////////////////////////
  const [search, setSearch] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const { RulesList, loading, error } = useAppSelector((state) => state.Rules);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (RulesList?.length === 0) {
      dispatch(GetRoleList());
    }
  }, [dispatch]);

  const filteredSearch = RulesList?.slice()?.filter((item) => {
    const searchLowerCase = search.toLowerCase();
    return (
      item.name?.toLowerCase().toString().includes(searchLowerCase) ||
      item.name2?.toLowerCase().toString().includes(searchLowerCase)
    );
  });

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  const { Module } = useLookupModule();
  useEffect(() => {
    if (Module?.length) {
      setSelectedValue(Module[0].id);
    }
  }, [Module]);

  //________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'RulesPermissions',
      link: null,
    },
  ];

  return (
    <div>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName="RulesPermissions" />

        <div className="ms-auto">
          {HasPermission('NtxAddpermissionsRule') && (
            <Link
              to=""
              className="btn btn-theme"
              data-bs-toggle="modal"
              data-bs-target="#modalAddRule">
              <i className="fa fa-plus-circle fa-fw me-1"></i>
              {t('AddUserRules')}
            </Link>
          )}
        </div>
      </div>

      <Card>
        <Loading loading={loading} error={error}>
          <div className="tab-content p-4">
            <div className="tab-pane fade show active">
              <div className="row">
                <div className="col-7 col-md-6 d-flex justify-content-start">
                  <div className="input-group mb-2">
                    <div className="flex-fill position-relative">
                      <div className="input-group mb-2">
                        <select
                          className="btn btn-outline-default p-0 dropdown-toggle"
                          id="my-select"
                          value={selectedValue}
                          onChange={(e) => setSelectedValue(e.target.value)}>
                          {Module &&
                            Module?.map((item: any) => (
                              <option value={item.id} key={item.id} className="dropdown-item">
                                {i18n.language === 'en' ? item.name : item.name2}
                              </option>
                            ))}
                        </select>

                        <div className="flex-fill position-relative">
                          <div className="input-group">
                            <div
                              className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                              style={{ zIndex: 1020, right: '10px' }}>
                              <i className="fa fa-search opacity-5"></i>
                            </div>
                            <input
                              type="text"
                              className="form-control ps-35px"
                              placeholder={t('Search')}
                              value={search}
                              onChange={(e) => setSearch(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* //  START TABLE   */}
              <div className="table-responsive">
                <table className="table text-nowrap">
                  <thead className="table-dark">
                    <tr>
                      <th scope="col">{t('Name')}</th>
                      <th scope="col">{t('Start Date')}</th>
                      <th scope="col">{t('End_Date')}</th>

                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* <Loading loading={loading} error={error}> */}
                    {currentData?.map((item, index) => (
                      <tr key={item.id}>
                        {/* <td className="align-middle">{++index}</td> */}
                        <td className="align-middle">
                          {i18n.language === 'ar' ? item.name2 : item.name}
                        </td>

                        <td className="align-middle">{item.startDate}</td>
                        <td className="align-middle">{item.endDate}</td>

                        <td className="text-end ">
                          {HasPermission('NtxEditpermissionsRule') && (
                            <div className="btn-group">
                              <button
                                type="button"
                                className="btn btn-outline-theme"
                                data-bs-toggle="modal"
                                data-bs-target="#modalEditRule"
                                onClick={() => setRulesId(item.id)}>
                                <i className="far fa-lg fa-fw me-2 fa-edit"></i>
                                {t('Button.Edit')}
                              </button>

                              <button
                                type="button"
                                onClick={() =>
                                  Navigate(`${item.id}/PermissionRule`, {
                                    state: {
                                      name: i18n.language === 'en' ? item.name : item.name2,
                                    },
                                  })
                                }
                                className="btn btn-outline-theme">
                                <i className="fas fa-lg fa-fw me-2 fa-tasks"></i>
                                {t('Permissions')}
                              </button>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                    {/* </Loading> */}
                  </tbody>
                </table>
              </div>
              <Pagination
                filteredData={filteredSearch}
                dataPerPage={dataPerPage}
                handlePagination={(pageNumber: any) => setCurrentPage(pageNumber)}
                currentPage={currentPage}
              />

              {/* </CardBody> */}
            </div>
          </div>
        </Loading>
      </Card>

      <MedModalComponent title={t('AddUserRules')} id="modalAddRule">
        <AddRule />
      </MedModalComponent>

      <MedModalComponent title={t('EditUserRules')} id="modalEditRule">
        {rulesId && <UpdateRules rulesId={rulesId} />}
      </MedModalComponent>
    </div>
  );
};

export default Index;
