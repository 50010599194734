
import { useTranslation } from "react-i18next";
import "react-datetime/css/react-datetime.css";
import Select from "react-select";
import useLookupTitle from "../../../../../hooks/Lookups/use-Lookup-Title";
import useLookupSex from "../../../../../hooks/Lookups/use-Lookup-Sex";
import useLookupMarStatus from "../../../../../hooks/Lookups/use-Lookup-MarStatus";
import useLookupNationality from "../../../../../hooks/Lookups/use-Lookup-Nationality";
import Form from "react-bootstrap/Form";

import useLookupReligions from "../../../../../hooks/Lookups/use-Lookup-Religions";

const AddEmployee = ({formik}) => {
  // lookup Data
  const { TITLE } = useLookupTitle();
  const { SEX } = useLookupSex();
  const { MAR_STATUS } = useLookupMarStatus();
  const { dataNATIONALITY , loading } = useLookupNationality();
  const { RELIGIONS } = useLookupReligions();

  const { t, i18n } = useTranslation();

 

  return (
    <>
      <div className="row">
        <div className="col-xl-2">
          <div className="form-group mb-3">
            <label className="form-label">{t("title")}</label>
            <Form.Select
              className="form-select"
              name="TITLE"
              onChange={formik.handleChange}
              value={formik.values.TITLE}
              isInvalid={formik.touched.TITLE && formik.errors.TITLE}
            >
              <option key={null}>
                {i18n.language === "ar" ? "إختر" : "Choose"}
              </option>
              {TITLE &&
                TITLE?.map((item, idx) =>
                  item.list?.map((list) => (
                    <option key={++idx} value={list.lookupCode}>
                      {i18n.language === "ar" ? list.meaning2 : list.meaning}
                    </option>
                  ))
                )}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {formik.errors.TITLE}
            </Form.Control.Feedback>
          </div>
        </div>

        <div className="col-xl-5">
          <div className="form-group mb-3 has-validation">
            <label className="form-label">{t("LocalName")}</label>

            <Form.Control
              type="text"
              className="form-control"
              name="NAME2"
              onChange={formik.handleChange}
              value={formik.values.NAME2}
              isInvalid={formik.touched.NAME2 && formik.errors.NAME2}
              autoComplete="off"
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.NAME2}
            </Form.Control.Feedback>
          </div>
        </div>

        <div className="col-xl-5">
          <div className="form-group mb-3">
            <label className="form-label">{t("GlobalName")}</label>
            <Form.Control
              style={{ direction: "ltr" }}
              type="text"
              className="form-control"
              // placeholder="الاسم بالانجليزي"
              name="NAME"
              onChange={formik.handleChange}
              value={formik.values.NAME}
              isInvalid={formik.touched.NAME && formik.errors.NAME}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.NAME}
            </Form.Control.Feedback>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-3">
          <div className="form-group mb-3">
            <label className="form-label">{t("Identity")}</label>
            <Form.Control
              className="form-control"
              type="text"
              // placeholder="الهوية"
              name="NATIONAL_IDENTIFIER"
              onChange={formik.handleChange}
              value={formik.values.NATIONAL_IDENTIFIER}
              isInvalid={
                formik.touched.NATIONAL_IDENTIFIER &&
                formik.errors.NATIONAL_IDENTIFIER
              }
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.NATIONAL_IDENTIFIER}
            </Form.Control.Feedback>
          </div>
        </div>

        <div className="col-xl-3">
          <div className="form-group mb-3">
            <label className="form-label">{t("Marital Status")}</label>
            <Form.Select
              className="form-select"
              name="MARITAL_STATUS"
              onChange={formik.handleChange}
              value={formik.values.MARITAL_STATUS}
              isInvalid={
                formik.touched.MARITAL_STATUS && formik.errors.MARITAL_STATUS
              }
            >
              <option key={null}>
                {i18n.language === "ar" ? "إختر" : "Choose"}
              </option>
              {MAR_STATUS &&
                MAR_STATUS?.map((item, idx) =>
                  item.list?.map((list) => (
                    <option key={++idx} value={list.lookupCode}>
                      {i18n.language === "en" ? list.meaning : list.meaning2}
                    </option>
                  ))
                )}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {formik.errors.MARITAL_STATUS}
            </Form.Control.Feedback>
          </div>
        </div>

        <div className="col-xl-3">
          <div className="form-group mb-3">
            <label className="form-label">{t("Gender")}</label>
            <Form.Select
              className="form-select"
              name="GENDER"
              onChange={formik.handleChange}
              value={formik.values.GENDER}
              isInvalid={formik.touched.GENDER && formik.errors.GENDER}
            >
              <option key={null}>
                {i18n.language === "ar" ? "إختر" : "Choose"}
              </option>
              {SEX &&
                SEX?.map((item, idx) =>
                  item.list?.map((list) => (
                    <option key={++idx} value={list.lookupCode}>
                      {i18n.language === "en" ? list.meaning : list.meaning2}
                    </option>
                  ))
                )}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {formik.errors.GENDER}
            </Form.Control.Feedback>
          </div>
        </div>

        <div className="col-xl-3">
          <div className="form-group mb-3">
            <label className="form-label">{t("Nationality")}</label>
            <Select
              classNamePrefix="react-select"
              isRtl
              isSearchable={true}
              options={dataNATIONALITY}
              onChange={(option) =>
                formik.setFieldValue("NATIONALITY", option.value)
              }
              placeholder={i18n.language === "ar" ? "إختر" : "Choose"}
            />
              {formik.errors.NATIONALITY && formik.touched.NATIONALITY && (
                <div style={{ color: "red" }}>{formik.errors.NATIONALITY}</div>
              )}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-2">
          <div className="form-group mb-3">
            <label className="form-label">{t("Religion")}</label>
            <Form.Select
              className="form-select"
              name="Religion"
              onChange={formik.handleChange}
              value={formik.values.Religion || ""}
              isInvalid={formik.touched.Religion && formik.errors.Religion}
            >
              <option key={null}>
                {i18n.language === "ar" ? "إختر" : "Choose"}
              </option>
              {RELIGIONS?.map((item, idx) =>
                item.list?.map((list) => (
                  <option key={++idx} value={list.lookupCode}>
                    {i18n.language === "ar" ? list.meaning2 : list.meaning}
                  </option>
                ))
              )}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {formik.errors.Religion}
            </Form.Control.Feedback>
          </div>
        </div>

        <div className="col-xl-3">
          <div className="form-group mb-3">
            <label className="form-label">{t("HiringDate")}</label>
            <Form.Control
              type="date"
              id="START_DATE"
              className="form-control"
              name="START_DATE"
              onChange={formik.handleChange}
              value={formik.values.START_DATE}
              isInvalid={formik.touched.START_DATE && formik.errors.START_DATE}
            />

            <div>
              <Form.Control.Feedback type="invalid">
                {formik.errors.START_DATE}
              </Form.Control.Feedback>
            </div>
          </div>
        </div>

        <div className="col-xl-3">
          <div className="form-group mb-3">
            <label className="form-label">{t("DateofBirth")}</label>

            <Form.Control
              type="date"
              id="birthdaytime"
              className="form-control"
              name="DATE_OF_BIRTH"
              onChange={formik.handleChange}
              value={formik.values.DATE_OF_BIRTH}
              isInvalid={
                formik.touched.DATE_OF_BIRTH && formik.errors.DATE_OF_BIRTH
              }
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.DATE_OF_BIRTH}
            </Form.Control.Feedback>
          </div>
        </div>

        <div className="col-xl-2">
          <div className="form-group mb-3">
            <label className="form-label">{t("BirthCountry")}</label>
            <Select
              classNamePrefix="react-select"
              isRtl
              isSearchable={true}
              options={dataNATIONALITY}
              onChange={(option) =>
                formik.setFieldValue("COUNTRY_OF_BIRTH", option.value)
              }
              placeholder={i18n.language === "ar" ? "إختر" : "Choose"}
            />
                          {formik.errors.COUNTRY_OF_BIRTH && formik.touched.COUNTRY_OF_BIRTH && (
                <div style={{ color: "red" }}>{formik.errors.COUNTRY_OF_BIRTH}</div>
              )}
          </div>
        </div>

        <div className="col-xl-2">
          <div className="form-group mb-3">
            <label className="form-label">{t("CityOfbirth")}</label>
            <Form.Control
              type="text"
              className="form-control"
              name="TOWN_OF_BIRTH"
              onChange={formik.handleChange}
              value={formik.values.TOWN_OF_BIRTH}
              isInvalid={
                formik.touched.TOWN_OF_BIRTH && formik.errors.TOWN_OF_BIRTH
              }
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.TOWN_OF_BIRTH}
            </Form.Control.Feedback>
          </div>
        </div>
      </div>


 

    </>
  );
};

export default AddEmployee;
