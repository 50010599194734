import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Installments = ({ installments, setInstallments }) => {

    const { t, i18n } = useTranslation();

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deletingRowIndex, setDeletingRowIndex] = useState(-1);

  //________________________________________________________________________________________

  // State to track which installment is in edit mode
  const [editIndex, setEditIndex] = useState(-1);

  // State to store temporary edited values
  const [editedInstallment, setEditedInstallment] = useState({});
  const [editedVATValue, setEditedVATValue] = useState(0);

  // Function to handle the edit button click
  const handleEditClick = (index) => {
    setEditIndex(index);
    const currentInstallment = installments[index];
    setEditedInstallment(currentInstallment);
    setEditedVATValue(
      (currentInstallment.installmentValue * currentInstallment.vatRate) / 100
    );
  };

  const handleSaveClick = (index) => {
    // Create a new installment object with updated values
    const updatedInstallment = {
      ...installments[index], // Copy existing values
      installmentValue: parseFloat(editedInstallment.installmentValue) ,
      vatAmount:
        (parseFloat(editedInstallment.installmentValue) * 15 ) / 100,
    //   installmentNo:editedInstallment.installmentNo ,

      installmentDate: editedInstallment.installmentDate,
      installmentDateForm: editedInstallment.installmentDateForm,
      installmentDateTo: editedInstallment.installmentDateTo,
      // contractInstallmentType: editedInstallment.contractInstallmentType,
      description: editedInstallment.description,
       
    };
  
    // Update the installments array with the new installment
    const updatedInstallments = [...installments];
    updatedInstallments[index] = updatedInstallment;
  
    setInstallments(updatedInstallments);
    setEditIndex(-1); // Exit edit mode
  };


  // Function to handle the cancel button click
  const handleCancelClick = () => {
    setEditIndex(-1); // Exit edit mode
  };

  const handleAddRowClick = () => {
    const newInstallment = {
      contractLeaseId: null,
      collectingId: null,
      contractInstallmentType: 1,
      installmentNo: installments?.length - 2,
      installmentDate: "",
      installmentValue: "",
      installmentDateForm: "",
      installmentDateTo: "",
      // subTotal: item.installmentValue + item.vatAmount, // 0,//????????????
      vatRate: "15",
      vatAmount: "",
      description: "",
    };

    const updatedInstallments = [...installments, newInstallment];
    setInstallments(updatedInstallments);
    setEditIndex(updatedInstallments?.length - 1); // Enter edit mode for the new row
  };


  //_______________________________________________________________________________

  const handleDeleteRowClick = (index) => {
    setShowDeleteConfirmation(true);
    setDeletingRowIndex(index);
  };
  //_______________________________________________________________________________

  const confirmDeleteRow = () => {
    const updatedInstallments = [...installments];
    updatedInstallments.splice(deletingRowIndex, 1); // Remove the row at the specified index
    
  // Filter and update installmentNo values for installments where isInstallment is true
  const filteredInstallments = updatedInstallments?.filter(installment => installment.isInstallment);
  filteredInstallments.forEach((installment, index) => {
    installment.installmentNo = index + 1;
  });

  // Update the installments array with filtered and updated installments
  updatedInstallments.forEach((installment, index) => {
    if (installment.isInstallment) {
      installment.installmentNo = filteredInstallments?.findIndex(inst => inst === installment) + 1;
    }
  });

    setInstallments(updatedInstallments);
    setShowDeleteConfirmation(false);
    setDeletingRowIndex(-1);
  };


  return (
    <>
      {installments?.length > 0 && (
        <button
          className=" btn me-1 btn-default mb-1"
          onClick={handleAddRowClick}
        >
           {t('AddInstallment')}
        </button>
      )}
      <div className="table-responsive text-center">
        <table className="table table-striped table-bordered mb-2px text-nowrap">
          <thead>
            <tr>
              <th>#</th>
              <th>{t('Date')}</th>
              <th>{t('InstallmentValue')}</th>
              <th>{t('VAT_Percentage')}</th>
              <th>{t('VAT_Value')}</th>
              <th>{t('TypeInstallment')}</th>
              <th>{t('Date_from')}</th>
              <th>{t('End_Date')}</th>
              <th>{t('Note')}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {installments?.map((installment, index) => (
              <tr key={index}>
                <td>{installment.installmentNo}</td>
                <td>
                  {editIndex === index ? (
                    <input
                      style={{ width: "100%" }}
                      type="date"
                      value={editedInstallment.installmentDate}
                      onChange={(e) =>
                        setEditedInstallment({
                          ...editedInstallment,
                          installmentDate: e.target.value,
                        })
                      }
                    />
                  ) : (
                    installment.installmentDate
                  )}
                </td>
                <td>
                  {editIndex === index ? (
                    <input
                      style={{ width: "100%" }}
                      type="number"
                      value={editedInstallment.installmentValue}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setEditedInstallment({
                          ...editedInstallment,
                          installmentValue: newValue,
                        });
                        setEditedVATValue(
                          (parseFloat(newValue) * editedInstallment.vatRate) /
                            100
                        );
                      }}
                    />
                  ) : (
                    installment.installmentValue
                  )}
                </td>
                <td>{installment.vatRate}</td>
                <td>
                  {editIndex === index ? editedVATValue : installment.vatAmount}
                </td>
                <td>
                  {/* {editIndex === index ? (
                    <input
                      style={{ width: "100%" }}
                      type="text"
                      value={editedInstallment.contractInstallmentType}
                      onChange={(e) =>
                        setEditedInstallment({
                          ...editedInstallment,
                          contractInstallmentType: e.target.value,
                        })
                      }
                    />
                  ) : (
                    installment.contractInstallmentType
                  )} */}
                  {
                    (installment.contractInstallmentType === 4 || installment.contractInstallmentType === "تأمين")  ? "تأمين"
                    
                    : (installment.contractInstallmentType === 2 || installment.contractInstallmentType === "خدمات") ? "خدمات"
                    
                    : (installment.contractInstallmentType === 3 || installment.contractInstallmentType === "سعي المكتب") ?"سعي المكتب"
                    
                    : "قسط عادي"
                  }
                </td>
                <td>
                  {editIndex === index ? (
                    <input
                      style={{ width: "100%" }}
                      type="date"
                      value={editedInstallment.installmentDateForm}
                      // value={selectedDate}
                      onChange={(e) =>
                        setEditedInstallment({
                          ...editedInstallment,
                          installmentDateForm: e.target.value,
                        })
                      }
                    />
                  ) : (
                    installment.installmentDateForm
                  )}
                </td>
                <td>
                  {editIndex === index ? (
                    <input
                      style={{ width: "100%" }}
                      type="date"
                      value={editedInstallment.installmentDateTo}
                      onChange={(e) =>
                        setEditedInstallment({
                          ...editedInstallment,
                          installmentDateTo: e.target.value,
                        })
                      }
                    />
                  ) : (
                    installment.installmentDateTo
                  )}
                </td>
                <td>
                  {editIndex === index ? (
                    <input
                      style={{ width: "100%" }}
                      type="text"
                      value={editedInstallment.description}
                      onChange={(e) =>
                        setEditedInstallment({
                          ...editedInstallment,
                          description: e.target.value,
                        })
                      }
                    />
                  ) : (
                    installment.description
                  )}
                </td>

                <td>
                  {editIndex === index ? (
                    <>
                      <Link
                        className="text-decoration-none"
                        onClick={() => handleSaveClick(index)}
                      >
                        <i className="far fa-lg fa-fw me-3 fa-save"></i>
                      </Link>

                      <Link
                        style={{ color: "red" }}
                        onClick={handleCancelClick}
                      >
                        <i className="far fa-lg fa-fw me-3 fa-window-close"></i>
                      </Link>
                    </>
                  ) : (
                    <>
                      {deletingRowIndex === index ? (
                        // Render confirmation dialog
                        <div>
                          <Link
                            style={{ color: "green" }}
                            onClick={confirmDeleteRow}
                          >
                            <i className="fas fa-lg fa-fw me-3 fa-check"></i>
                          </Link>

                          <Link
                            style={{ color: "red" }}
                            onClick={() => setDeletingRowIndex(-1)}
                          >
                            <i className="far fa-lg fa-fw me-3 fa-window-close"></i>
                          </Link>
                        </div>
                      ) : (
                        // Render edit and delete buttons
                        <>
                          <Link
                            className="text-decoration-none"
                            onClick={() => handleEditClick(index)}
                          >
                            <i className="fas fa-lg fa-fw me-3 fa-edit"></i>
                          </Link>

                          <Link
                            style={{ color: "red" }}
                            onClick={() => handleDeleteRowClick(index)}
                          >
                            <i className="fas fa-lg fa-fw me-3 fa-trash-alt"></i>
                          </Link>
                        </>
                      )}
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default Installments
