import React from 'react';
import NtxPeoples from '../../../NTX/Peoples/NtxPeoples';
import NtxCustomers from '../../../NTX/Peoples/NtxCustomers';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { useTranslation } from 'react-i18next';

const Add = () => {
  // var fieldList = [
  //   "typeCode",
  //   "customerNumber",
  //   "personId",
  //   "billToLocationId",
  //   "shipToLocationId",
  //   "termsId", // الشروط للعملاء . لها جدول محدد
  //   "setOfBooksId", // جدول الاستاذ العام
  //   "creditLimit", // سقف الدين
  //   // "paymentPriority", // أولوية الدفع لووكآب  موجود في المورد وليس في العميل
  //   "invoiceCurrencyCode", //
  //   "paymentCurrencyCode", //
  //   // "invoiceLimit", // موجود في المورد وليس في العميل ونوعه رقم
  //   "bankAccountName", //
  //   "bankAccountNum", //
  //   "vatCode", //  الرقم الضريبي للعميل
  //   "tradingName", // الاسم التجاري للعميل
  //   "workReference", // اسم الشخص
  //   "companyRegistrationNumber", // السجل التجاري للعميل
  //   "peopleCategory",
  //   "peopleCategory",
  //   "priceListId",
  //   "accountId",

  // ];
  const { t, i18n } = useTranslation();
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: t('العملاء'),
      link: '/SalesSettings/Customers',
    },
    {
      name: t('إضافة عميل جديد'),
      link: null,
    },
  ];

  return (
    <div>
      {/* <NtxPeoples typeCode="CUSTOMERS" isUpdate={false} fieldList={fieldList} /> */}

      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName={t('إضافة عميل جديد')} />
      </div>

      <hr className="mb-3" />

      <NtxCustomers isUpdate={false} parentId={null} />
    </div>
  );
};

export default Add;
