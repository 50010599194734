import React, { useState } from 'react';
import ViewAbsence from '../../../HR/Employees/Absence/ViewAbsence/ViewAbsence';
import ViewWorkRequest from '../../../FLEET/WorkRequests/ViewWorkRequest/ViewWorkRequest';
import NeatixModal from '../../../../components/Modal/NeatixModal';
// import ViewAbsence from '../../HR/Employees/Absence

const ViewStaticForm = ({ data }) => {
  const [show, setShow] = useState();
  let Component = null;

  // console.log(data);
  switch (data.dynamicFormId) {
    case '19b2d5d2-8864-4e5d-b07e-4c1713d07a3c':
      Component = <ViewAbsence ReferenceId={data.staticRefrenceId} />;
      break;
    case 'd9e918b3-b706-4281-a6e6-5101be1bcd63':
      Component = <ViewWorkRequest ReferenceId={data.staticRefrenceId} isAme={true} />;
      break;
    default:
      <div> {Component} </div>;
  }

  return (
    <div>
      <button type="button" className="btn btn-outline-theme rounded-0 w-100 " onClick={() => setShow(true)}>
        عرض تفاصيــل الطلب
      </button>

      <NeatixModal
      title={`${data.requestName} (${data.requestNumber}) ` }
        show={show}
        setShow={setShow}
        // fullscreen={true}
        size="lg"
        headerClassName="bg-theme bg-opacity-25 p-3"
        bodyClassName="p-3">
        {Component}
      </NeatixModal>
    </div>
  );
};

export default ViewStaticForm;
