/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';

import { useDispatch } from 'react-redux';
import { GetReportSourceFeild } from '../../../../../store/NTX/Reports/reportSlice';
import BuilderQuerys from './Step2/BuilderQuerys';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, CardHeader } from '../../../../../components/card/card';
import Step3 from './Step3/Step3';
import Step2 from './Step2/Step2';
import Step1 from './Sterp1/Step1';
import { Link, useNavigate } from 'react-router-dom';
import CustomAlert from '../../../../../components/Shared/Alert/CustomAlert';
import Loader from '../../../../../components/Shared/Loader/Loader';
import { useSelector } from 'react-redux';
import Breadcrumb from '../../../../../components/Shared/Breadcrumb';
import { AddNtxPrintTemplate } from '../../../../../store/NTX/PrintTemplate/PrintTemplateSlice';
import ToastSwal from '../../../../../components/Shared/Alert/ToastSwal';

const AddPrintingForms = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [fieldsName, setFieldsName] = useState([]);
  const [params, setParams] = useState([]);

  //_____________________ Start States Check Validation  _____________________
  const [checkValidationStep1, setCheckValidationStep1] = useState(false);
  const [checkValidationStep2, setCheckValidationStep2] = useState(false);
  const [checkValidationStep3, setCheckValidationStep3] = useState(false);
  //_____________________ End States Check Validation  _____________________

  //_____________________ Start States for Post _____________________
  const [DataStep1, setDataStep1] = useState({}); // for submit
  const [DataStep2, setDataStep2] = useState([]); // for submit
  const [DataStep3, setDataStep3] = useState([]); // for submit
  const [Query, setQuery] = useState(''); // for submit  from step2
  const [queryBuilderReact, setQueryBuilderReact] = useState(''); // for submit  from step2
  //_____________________ End States for Post _____________________

  //___ Start Steps Wiserd___________
  const [currentStep, setCurrentStep] = useState(1);
  const handleNextStep = async () => {
    if (currentStep === 1) {
      if (checkValidationStep1) {
        await dispatch(
          GetReportSourceFeild({
            typeForm: DataStep1?.typeForm,
            viewName: DataStep1?.viewName,
            formTypeId: DataStep1?.FormTypeId || null,
            formRecordId: DataStep1?.FormRecordId || null,
            tableId: DataStep1?.tableId,
            hasExtraInformation: DataStep1?.hasExtraInformation === 'true', //boolean
            hasExtraInformationRecord: DataStep1?.hasExtraInformationRecord === 'true', //boolean
            hasExtraInformationType: DataStep1?.hasExtraInformationType === 'true', //boolean
          })
        )
          .unwrap()
          .then((res) => {
            // debugger
            if (res?.succeeded === true) {
              let x = res?.data?.map((item) => ({
                name: item.value,
                label: item.name,
                inputType: item.inputType,
                defaultValue: '',
              }));
              setFieldsName(x);
            }
          })
          .catch((error) => {
            console.log(error);
          });
        setCurrentStep(2);
      } else {
        ToastSwal({
          icon: 'warning',
          position: 'top-start',
          title: 'يرجى التحقق من تعبئة جميع الحقول',
        });
        return;
      }
    } else if (currentStep === 2) {
      if (checkValidationStep2) {
        setCurrentStep(3);
      } else {
        ToastSwal({
          icon: 'warning',
          position: 'top-start',
          title: 'يرجى التحقق من تعبئة جميع الحقول',
        });
        return;
      }
    } else if (currentStep === 3) {
      if (checkValidationStep3) {
        dispatch(
          AddNtxPrintTemplate({
            name: DataStep1.name,
            name2: DataStep1.name2,
            moduleId: DataStep1.moduleId,
            // tableName: DataStep1.tableName,
            reportSourceId: DataStep1.reportSourceId,
            dynamicFormType: DataStep1.typeForm,

            dynamicFormId:
              DataStep1.typeForm === '0'
                ? null
                : DataStep1.typeForm === '3'
                ? DataStep1.FormRecordId
                : DataStep1.typeForm === '4'
                ? DataStep1.FormTypeId
                : '',

            query: Query,
            description: 'description',
            pageType: DataStep1.pageSize,
            reportType: 'Type1',
            queryBuilderReact: queryBuilderReact,
            icon: DataStep1.icon,
            printTemplateTypeId: DataStep1.templateType,
            reportColumnsList: DataStep3?.map((item) => ({
              name: item.AliasName,
              name2: item.AliasName,
              reportId: null,
              sqlName: item.Name,
              sequenceNum: item.Sequance,
              dataType: item.inputType,
            })),
            reportParamerersList: DataStep2?.map((item) => ({
              reportId: null,
              paramName: item.paramName,
              paramValue: item.isStatic ? item.paramValue : '',
              flexValueSetId: item.flexValueSetId,
              isStatic: item.isStatic,
              lable: item.lable,
              lable2: item.lable, // lable2 = lable  based on hamed say
              sequenceNum: item.sequenceNum,
              isVisible: item.isVisible,
              hasIn: item.hasIn,
              endWith: item.endWith,
              beginWith: item.beginWith,
              requiredFlag: item.requiredFlag,
              language: parseInt(item.language),
            })),
          })
        )
          .unwrap()
          .then((res) => {
            if (res.succeeded === true) {
              Navigate('/RPT/PrintingForms', { replace: true });
              CustomAlert({ action: 'Add' });
            } else {
              CustomAlert({ action: 'Error' });
            }
          })
          .catch((error) => {
            console.log(error);
            CustomAlert({ action: 'Error' });
          });
      } else {
        ToastSwal({
          icon: 'warning',
          position: 'top-start',
          title: 'يرجى التحقق من تعبئة جميع الحقول',
        });
        return;
      }
    }
  };

  const handlePreviousStep = () => {
    if (currentStep === 2) {
      // Move from step 2 to step 1
      setCurrentStep(1);
    } else if (currentStep === 3) {
      // Move from step 3 to step 2
      setCurrentStep(2);
    } else if (currentStep === 4) {
      // Move from step 4 to step 3
      setCurrentStep(3);
    }
    // You can add more conditions for additional steps if needed
  };
  //___ End Steps Wiserd___________

  const loading = useSelector((state) => state.Reports.loading);

  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: t('قوالب الطباعة'),
      link: '/RPT/PrintingForms',
    },
    {
      name: t('إضافة قالب طباعة جديـد'),
      link: null,
    },
  ];

  return (
    <div>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName={t('إضافة قالب طباعة جديـد')} />

      {/* //_________________________________________________ */}
      <Card>
        <CardHeader>
          <div className="nav-wizards-container" dir="ltr">
            <nav className="nav nav-wizards-1 mb-2">
              <div className="nav-item col">
                <Link
                  className={`nav-link ${currentStep === 3 || currentStep === 4 ? 'active' : ''}`}>
                  <div className="nav-no">3</div>
                  <div className="nav-text">{t('أعمدة النوذج')}</div>
                </Link>
              </div>

              <div className="nav-item col">
                <Link
                  className={`nav-link ${
                    currentStep === 2 || currentStep === 3 || currentStep === 4 ? 'active' : ''
                  }  `}>
                  <div className="nav-no">2</div>
                  <div className="nav-text">{t('متغيرات النموذج')}</div>
                </Link>
              </div>

              <div className="nav-item col">
                <Link
                  className={`nav-link ${
                    currentStep === 1 || currentStep === 2 || currentStep === 3 || currentStep === 4
                      ? 'active'
                      : ''
                  }`}>
                  <div className="nav-no">1</div>
                  <div className="nav-text">{t('معلومات النموذج')}</div>
                </Link>
              </div>
            </nav>
          </div>
        </CardHeader>

        <CardBody style={{ minHeight: '18rem' }}>
          <Loader loading={loading} />
          {/* ______________ Step 1 _______________ */}

          <div style={{ display: currentStep === 1 ? 'block' : 'none' }}>
            <Step1
              // setTableViewName={setTableViewName}
              setCheckValidationStep1={setCheckValidationStep1}
              setDataStep1={setDataStep1}
            />
            {/* <p>All fields filled: {checkValidationStep1 ? "true" : "false"}</p> */}
          </div>

          {/* ______________ Step 2 _______________ */}

          <div style={{ display: currentStep === 2 ? 'block' : 'none' }}>
            <div className="row">
             

              <div className="col-xl-12">
                {fieldsName && (
                  <BuilderQuerys
                    fields={fieldsName}
                    setParams={setParams}
                    setQuery={setQuery}
                    setQueryBuilderReact={setQueryBuilderReact}
                  />
                )}
              </div>
              
            </div>
            <div className="mb-3" />
            <div className="row">
              <div className="col-xl-12">
                {
                  // params?.length > 0 &&
                  <Step2
                    params={params}
                    fields={fieldsName}
                    setCheckValidationStep2={setCheckValidationStep2}
                    setDataStep2={setDataStep2}
                  />
                }
              </div>
            </div>
          </div>

          {/* ______________ Step 3 _______________ */}

          <div style={{ display: currentStep === 3 ? 'block' : 'none' }}>
            {fieldsName && (
              <Step3
                fields={fieldsName}
                setCheckValidationStep3={setCheckValidationStep3}
                setDataStep3={setDataStep3}
              />
            )}
          </div>
        </CardBody>

        <hr className="mb-3" style={{ zIndex: '-1' }} />
        <div className="text-center mb-4">
          <div className="btn-group btn-group-lg">
            <button type="button" className="btn btn-secondary mb-1" onClick={handlePreviousStep}>
              <i className="fas fa-lg fa-fw me-2 fa-arrow-alt-circle-right"></i>
              {t('Previous')}
            </button>

            <button
              type="button"
              className="btn btn-light   mb-1"
              onClick={() => Navigate('/RPT/PrintingForms', { replace: true })}>
              {t('Cancel')}
            </button>

            <button type="submit" className="btn me-1 btn-theme mb-1" onClick={handleNextStep}>
              {currentStep === 3 ? t('Button.Save') : t('Next')}
              {currentStep !== 3 ? (
                <i
                  className="fas fa-lg fa-fw me-0 fa-arrow-alt-circle-left"
                  style={{ padding: '0px 12px 0px 0px' }}></i>
              ) : (
                <i
                  className="far fa-lg fa-fw me-2 fa-save"
                  style={{ padding: '0px 12px 0px 0px' }}></i>
              )}
            </button>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default AddPrintingForms;

// query: `SELECT TABLE_NAME ,COLUMN_NAME as value,COLUMN_NAME as name ,(case when DATA_TYPE in ('smallint','int','float','decimal','numeric','bigint') then 'number' when DATA_TYPE in ('date','datetime') then 'date' when DATA_TYPE in  ('bit') then 'checkbox' when DATA_TYPE in ('time','') then 'time' else 'text' end )inputType FROM INFORMATION_SCHEMA.COLUMNS WHERE TABLE_NAME = N'HR_PERSON_VW'
// union all
// SELECT
// (select CONCAT(TABLE_NAME,'_VW')TABLE_NAME from NTX_TABLES_FORM where id=N'CD5AA419-0E0B-48FE-A66F-F004D97964A2') as TABLE_NAME,cast(dbo.NTX_DYNAMIC_FORM_COLUMN.ID as nvarchar(50)) as value,
// CONCAT(dbo.NTX_DYNAMIC_FORM.NAME, ' | ',dbo.NTX_DYNAMIC_FORM_COLUMN.LABLE) as name,
// (case when dbo.NTX_FLEX_VALUE_SETS.VALIDATION_TYPE='NUMBER' then 'number'
// when dbo.NTX_FLEX_VALUE_SETS.VALIDATION_TYPE='BOOLEAN' then 'checkbox'
// when dbo.NTX_FLEX_VALUE_SETS.VALIDATION_TYPE='TIME' then 'time'
// when dbo.NTX_FLEX_VALUE_SETS.VALIDATION_TYPE='DATE' then 'date' else 'text' end)
// inputType
// FROM        dbo.NTX_DYNAMIC_FORM INNER JOIN
//                   dbo.NTX_DYNAMIC_FORM_COLUMN ON dbo.NTX_DYNAMIC_FORM.ID = dbo.NTX_DYNAMIC_FORM_COLUMN.DYNAMIC_FORM_ID INNER JOIN
//                   dbo.NTX_FLEX_VALUE_SETS ON dbo.NTX_DYNAMIC_FORM_COLUMN.FLEX_VALUE_SET_ID = dbo.NTX_FLEX_VALUE_SETS.ID
// WHERE     (dbo.NTX_DYNAMIC_FORM.IS_DELETE = 0) AND (dbo.NTX_DYNAMIC_FORM_COLUMN.IS_DELETE = 0) AND (dbo.NTX_DYNAMIC_FORM.TABLE_ID IS NOT NULL) AND (dbo.NTX_DYNAMIC_FORM.DYNAMIC_FORM_TYPES_ID in (3)) AND (dbo.NTX_DYNAMIC_FORM.TABLE_ID = N'${DataStep1.FormRecordId}')`,
