import { useFormik } from 'formik';
import React from 'react';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CustomAlert from '../../../components/Shared/Alert/CustomAlert';
import { Card, CardBody } from '../../../components/card/card';
import { UpdateAprovalGroup } from '../../../store/Ame/approvalGroupSlice';
import { DynamicFormSchema } from '../../ValidationForm/validationSchema';

const EditApprovalGroup = ({ data }) => {
  // const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const Navigate = useNavigate();

  // useEffect(() => {
  //   dispatch(GetApprovalGroupById(Id));
  //  }, []);
  // const { ApprovalGroupListById } = useSelector((state) => state.ApprovalGroup);

  const formik = useFormik({
    initialValues: {
      name2: data ? data.name2 : '',
      name: data ? data.name : '',
      description: data ? data.description : '',
    },
    enableReinitialize: true,
    validationSchema: DynamicFormSchema(t),
    onSubmit: (values, { resetForm }) => {
      dispatch(
        UpdateAprovalGroup({
          id: data?.id,
          name: values.name,
          name2: values.name2,
          description: values.description,
        })
      )
        .unwrap()
        .then(() => {
          CustomAlert({ action: 'Edit' });
          Navigate(-1, { replace: true });
          resetForm();
        })
        .catch((error) => {
          CustomAlert({ action: 'Error' });
          console.log(error);
        });
    },
  });

  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-xl-6">
            <div className="form-group mb-3">
              <label className="form-label" htmlFor="exampleFormControlSelect1">
                {t('LocalName')}
              </label>
              <Form.Control
                className="form-control"
                type="text"
                name="name2"
                onChange={formik.handleChange}
                value={formik.values.name2 || ''}
                isInvalid={!!formik.errors.name2}
              />
              <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
            </div>
          </div>

          <div className="col-xl-6">
            <div className="form-group mb-3">
              <label className="form-label" htmlFor="exampleFormControlSelect1">
                {t('GlobalName')}
              </label>
              <Form.Control
                className="form-control"
                type="text"
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name || ''}
                isInvalid={!!formik.errors.name}
              />
              <Form.Control.Feedback type="invalid">{formik.errors.name2}</Form.Control.Feedback>
            </div>
          </div>

          <div className="col-xl-12">
            <div className="form-group mb-3">
              <label className="form-label" htmlFor="exampleFormControlSelect1">
                {t('Note')}
              </label>
              <Form.Control
                className="form-control"
                as="textarea"
                rows={3}
                name="description"
                onChange={formik.handleChange}
                value={formik.values.description || ''}
              />

              <div></div>
            </div>
          </div>
        </div>
        <div className="text-center">
          <button type="submit" className="btn me-1 btn-theme mb-1">
            <i className="fa fa-save fa-fw me-1"></i> {t('Save')}
          </button>

          <button
            type="button"
            className=" btn me-1 btn-default mb-1"
            onClick={() => Navigate(-1, { replace: true })}>
            <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
          </button>
        </div>
      </Form>
    </>
  );
};

export default EditApprovalGroup;
