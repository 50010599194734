import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { Card, CardBody } from '../../../../components/card/card';
import { GetAmeCondition } from '../../../../store/Ame/ameSlice';
import {
  GetAmeConditionUsageListByRuleId,
  insertAmeConditionUsage,
} from '../../../../store/Ame/ameConditionUsageSlice';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import AmeConditionUsageList from './AmeConditionUsageList';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';

const AddAmeConditionUsage = () => {
  const Navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { ruleId } = useParams();
  const { id } = useParams();
  const { AmeRuleListByDynamicFormId: AmeRuleList } = useSelector((state) => state.AmeRule);
  const dispatch = useDispatch();
  //

  const { AmeConditionList } = useSelector((state) => state.Ame);
  useEffect(() => {
    dispatch(GetAmeCondition(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Get Ame Condition List for fill <Select>
  const { ConditionUsageListByRuleId } = useSelector((state) => state.AmeConditionUsage);
  useEffect(() => {
    dispatch(GetAmeConditionUsageListByRuleId(ruleId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik({
    initialValues: {
      ruleId: ruleId,
      conditionId: '',
    },
    validationSchema: Yup.object().shape({
      conditionId: Yup.string().required(() => t('Required')),
    }),
    onSubmit: (values) => {
      const existingUsage = ConditionUsageListByRuleId?.find(
        (usage) => usage.conditionId === values.conditionId
      );

      if (existingUsage) {
        CustomAlert({ action: 'Error', msg: 'مضاف مسبقاً' });
        formik.setSubmitting(false);
        return;
      }

      dispatch(
        insertAmeConditionUsage({
          ruleId: ruleId,
          conditionId: formik.values.conditionId,
        })
      )
        .unwrap()
        .then(() => {
          formik.resetForm();
          CustomAlert({ action: 'Add' });
          dispatch(GetAmeConditionUsageListByRuleId(ruleId));
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });

  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: t('RequestsSettings'),
      link: '/Ame/RequestSetting',
    },
    {
      name: t('RequestRules'),
      link: '',
    },
    {
      name: t('RuleConditions'),
      link: null,
    },
  ];
  return (
    <>
      <Breadcrumb
        BreadcrumbList={BcrumbList}
        PageName={t('RuleConditions')}
        subtitle={
          i18n.language === 'en'
            ? AmeRuleList?.find((f) => f.id === ruleId)?.name
            : AmeRuleList?.find((f) => f.id === ruleId)?.name2
        }
      />

      <Card>
        <CardBody>
          <Form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-xl-3"></div>
              <div className="col-xl-6">
                <div className="form-group mb-3">
                  <label className="form-label" htmlFor="exampleFormControlSelect1">
                    {t('Condition')}
                  </label>
                  <Form.Select
                    className="form-select"
                    name="conditionId"
                    onChange={formik.handleChange}
                    value={formik.values.conditionId}
                    isInvalid={!!formik.errors.conditionId}>
                    <option value={null}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                    {AmeConditionList?.map((item) => (
                      <option key={item.id} value={item.id}>
                        {i18n.language === 'ar' ? item.name2 : item.name}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.conditionId}
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="col-xl-3"></div>
            </div>
            <div className="text-center">
              <button
                disabled={formik.isSubmitting}
                type="submit"
                className="btn me-1 btn-theme mb-1">
                {formik.isSubmitting ? (
                  <div className="spinner-border spinner-border-sm me-2"></div>
                ) : (
                  <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
                )}
                {t('Add')}
              </button>
              <button
                type="button"
                className=" btn me-1 btn-default mb-1"
                onClick={() => Navigate(-1, { replace: true })}>
                <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}{' '}
              </button>
            </div>
          </Form>

          <div className="row">
            <div className="col-10 m-auto">
              <div className=" ">
                <AmeConditionUsageList data={ConditionUsageListByRuleId} />
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default AddAmeConditionUsage;
