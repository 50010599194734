import React, { useState } from 'react';
import { Card, CardBody } from '../../../components/card/card';
import { NavScrollTo } from '../../../components/nav-scroll-to/nav-scroll-to';
import useGetNtxSettings from '../../../hooks/NTX/useGetNtxSettings';
import { useTranslation } from 'react-i18next';
import SmallModalComponent from '../../../components/Modal/SmallModalComponent';
import UpdateNtxSettings from './UpdateNtxSettings';
import Loader from '../../../components/Shared/Loader/Loader';
import NtxLanguage from './Language/NtxLanguage';
import { usePermissions } from '../../../Helper/Permisstions/usePermissions';
import { Link } from 'react-router-dom';
import NeatixModal from '../../../components/Modal/NeatixModal';

const NtxSettings = () => {
  const { HasPermission } = usePermissions();
  const { t, i18n } = useTranslation();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [settingId, setSettingId] = useState<string>();
  const [settingType, setSettingType] = useState<any>('');
  const { HRSettingList, SettingsLiat, NTXSettingList, loading, error } = useGetNtxSettings();

  const getDisplayValue = (item: any, language: string) => {
    const valueList = item.getFlexValueList || [];

    if (valueList.length > 0) {
      const matchedItem = valueList.find(
        (f: any) => f?.id?.toLowerCase() === item?.value?.toLowerCase()
      );

      return language === 'ar' ? matchedItem?.name2 || item.value : matchedItem?.name || item.value;
    }

    return item.value;
  };

  const handleEditClick = async (id: string, type: 'radio' | 'text') => {
    await setSettingId(id);
    await setSettingType(type);
    setShowModal(true);
  };

  return (
    <div>
      {loading ? (
        <Loader loading={true} />
      ) : (
        <>
          <div className="container p-0">
            <div className="row justify-content-center p-0">
              <div className="col-xl-10">
                <div className="row">
                  <div className="col-xl-9">
                    <div id="languages" className="mb-5">
                      <h5>
                        <i className="fa fa-language fa-fw text-theme"></i> {t('SystemLanguage')}
                      </h5>
                      <p> </p>
                      <Card>
                        <div className="list-group list-group-flush">
                          <div className="list-group-item d-flex align-items-center">
                            <div className="flex-1 text-break">
                              <div> {t('Language')}</div>
                              <div className="text-inverse text-opacity-50">
                                {t('English')} , {t('Arabic')}
                              </div>
                            </div>

                            <div className="flex-1 text-center text-break bg-inverse bg-opacity-10 rounded-5 p-1 me-2 shadow border-bottom border-theme">
                              <div className=" text-theme">
                                {i18n.language === 'ar' ? t('Arabic') : t('English')}
                              </div>
                            </div>

                            <div>
                              <a
                                href="#modalLanguage"
                                data-bs-toggle="modal"
                                className="btn btn-outline-default w-100px">
                                {t('Change')}
                              </a>
                            </div>
                          </div>
                        </div>
                      </Card>
                    </div>

                    {HasPermission('NtxViewSettingList') && (
                      <>
                        <div id="hr" className="mb-5">
                          <h5>
                            <i className="far fa-user fa-fw text-theme"></i> {t('HumanResources')}
                          </h5>
                          <p></p>
                          <Card>
                            <div className="list-group list-group-flush">
                              {HRSettingList?.map((item: any) => (
                                <div
                                  className="list-group-item d-flex align-items-center"
                                  key={item.id}>
                                  <div className="flex-1 text-break">
                                    <div className="text-inverse text-opacity-100">
                                      {i18n.language === 'ar' ? item.name2 : item.name}
                                    </div>
                                    <div className="text-inverse text-opacity-50">
                                      {i18n.language === 'ar'
                                        ? item.description2
                                        : item.description}
                                    </div>
                                  </div>
                                  <div className="flex-1 text-center text-break bg-inverse bg-opacity-10 rounded-4 p-1 me-2 shadow border-bottom border-theme ">
                                    <div className=" text-theme">
                                      {i18n.language === 'ar'
                                        ? item.getFlexValueList?.find(
                                            (f: any) =>
                                              f?.id?.toLowerCase() === item?.value?.toLowerCase()
                                          )?.name2
                                        : item.getFlexValueList?.find(
                                            (f: any) =>
                                              f?.id?.toLowerCase() === item?.value?.toLowerCase()
                                          )?.name}
                                    </div>
                                  </div>

                                  {HasPermission('NtxEditSetting') && (
                                    <div>
                                      <Link
                                        to="#modalEdit"
                                        className="btn btn-outline-default w-100px"
                                        onClick={() =>
                                          handleEditClick(
                                            item.id,
                                            item.getFlexValueList?.length === 0 ? 'text' : 'radio'
                                          )
                                        }>
                                        {t('Edit')}
                                      </Link>
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                          </Card>
                        </div>

                        <div id="NTX" className="mb-5">
                          <h5>
                            <i className="far fa-user fa-fw text-theme"></i> {t('GeneralSystem')}
                          </h5>
                          <p></p>
                          <Card>
                            <div className="list-group list-group-flush">
                              {NTXSettingList?.map((item: any) => (
                                <div
                                  className="list-group-item d-flex align-items-center"
                                  key={item.id}>
                                  <div className="flex-1 text-break">
                                    <div className="text-inverse text-opacity-100">
                                      {i18n.language === 'ar' ? item.name2 : item.name}
                                    </div>
                                    <div className="text-inverse text-opacity-50">
                                      {i18n.language === 'ar'
                                        ? item.description2
                                        : item.description}
                                    </div>
                                  </div>
                                  <div className="flex-1 text-center text-break bg-inverse bg-opacity-10 rounded-4 p-1 me-2 shadow border-bottom border-theme ">
                                    <div className=" text-theme">
                                      {/* {i18n.language === 'ar'
                                        ? item.getFlexValueList?.find(
                                            (f: any) =>
                                              f?.id?.toLowerCase() === item?.value?.toLowerCase()
                                          )?.name2
                                        : item.getFlexValueList?.find(
                                            (f: any) =>
                                              f?.id?.toLowerCase() === item?.value?.toLowerCase()
                                          )?.name || item.value} */}
                                      {getDisplayValue(item, i18n.language)}
                                    </div>
                                  </div>

                                  {HasPermission('NtxEditSetting') && (
                                    <div>
                                      <Link
                                        to=""
                                        className="btn btn-outline-default w-100px"
                                        onClick={() =>
                                          handleEditClick(
                                            item.id,
                                            item.getFlexValueList?.length === 0 ? 'text' : 'radio'
                                          )
                                        }>
                                        {t('Edit')}
                                      </Link>
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                          </Card>
                        </div>
                      </>
                    )}
                  </div>

                  <div className="col-xl-3">
                    <NavScrollTo>
                      <nav className="nav">
                        <a className="nav-link" href="#hr" data-toggle="scroll-to">
                          {t('HumanResources')}
                        </a>

                        <a className="nav-link" href="#notifications" data-toggle="scroll-to">
                          {t('ApprovalsManagement')}
                        </a>
                        {/* <a
                          className="nav-link"
                          href="#privacyAndSecurity"
                          data-toggle="scroll-to"
                        >
                          {t("SupportingUnits")}
                        </a> */}
                        {/* <a
                          className="nav-link"
                          href="#payment"
                          data-toggle="scroll-to"
                        >
                          {t("FleetManagement")}
                        </a> */}
                        <a className="nav-link" href="#shipping" data-toggle="scroll-to">
                          {t('GeneralSystem')}
                        </a>
                        <a className="nav-link" href="#languages" data-toggle="scroll-to">
                          {t('SystemLanguage')}
                        </a>
                        {/* <a className="nav-link" href="#system" data-toggle="scroll-to">System</a> */}
                      </nav>
                    </NavScrollTo>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <SmallModalComponent
            id="modalEdit"
            title={
              i18n.language === 'ar'
                ? SettingsLiat?.find((f) => f.id === settingId)?.name2
                : SettingsLiat?.find((f) => f.id === settingId)?.name
            }>
            {settingId && <UpdateNtxSettings settingId={settingId} settingType={settingType} />}
          </SmallModalComponent> */}

          <NeatixModal
            show={showModal}
            setShow={setShowModal}
            size="md"
            bodyClassName="p-0"
            showHeader={false}>
            <Card>
              <CardBody>
                {settingId && (
                  <UpdateNtxSettings
                    settingId={settingId}
                    settingType={settingType}
                    onClose={() => setShowModal(false)}
                  />
                )}
              </CardBody>
            </Card>
          </NeatixModal>

          <SmallModalComponent id="modalLanguage" title={t('SystemLanguage')}>
            <NtxLanguage />
          </SmallModalComponent>
        </>
      )}
    </div>
  );
};

export default NtxSettings;

// <div className="container">
// <div className="row justify-content-center">
//   <div className="col-xl-10">
//     <div className="row">
//       <div className="col-xl-9">
//         <div id="hr" className="mb-5">
//           <h4>
//             <i className="far fa-user fa-fw text-theme"></i> إدارة
//             الموارد البشرية
//           </h4>
//           <p></p>
//           <Card>
//             <div className="list-group list-group-flush">
//               {HRSettingList?.map((item) => (
//                 <div
//                   className="list-group-item d-flex align-items-center"
//                   key={item.id}
//                 >
//                   <div className="flex-1 text-break">
//                     <div className="text-inverse text-opacity-100">
//                       {i18n.language === "ar" ? item.name2 : item.name}
//                     </div>
//                     <div className="text-inverse text-opacity-50">
//                       {i18n.language === "ar"
//                         ? item.description2
//                         : item.description}
//                     </div>
//                   </div>
//                   <div className="flex-1 text-center text-break bg-inverse bg-opacity-10 rounded-5 p-1 me-2">
//                     <div className="text-inverse text-opacity-80">
//                       {i18n.language === "ar"
//                         ? item.getFlexValueList?.find(
//                             (f) => f.id.toLowerCase() === item.value.toLowerCase()
//                           )?.name2
//                         : item.getFlexValueList?.find(
//                             (f) => f.id.toLowerCase() === item.value.toLowerCase()
//                           )?.name}
//                     </div>
//                   </div>

//                   <div>
//                     <a
//                       href="#modalEdit"
//                       data-bs-toggle="modal"
//                       className="btn btn-outline-default w-100px"
//                       onClick={() => setSettingId(item.id)}
//                     >
//                          {/* <i className="far fa-lg fa-fw  fa-edit"></i> */}
//                       {t('Edit')}
//                     </a>
//                   </div>
//                 </div>
//               ))}
//             </div>
//           </Card>
//         </div>

//         <div id="languages" className="mb-5">
//           <h4>
//             <i className="fa fa-language fa-fw text-theme"></i>{" "}
//             لغة النظام
//           </h4>
//           <p> </p>
//           <Card>
//             <div className="list-group list-group-flush">
//               <div className="list-group-item d-flex align-items-center">
//                 <div className="flex-1 text-break">
//                   <div>اللغة</div>
//                   <div className="text-inverse text-opacity-50">
//                     {t("English")} ,  {t("Arabic")}
//                   </div>
//                 </div>

//                 <div className="flex-1 text-center text-break bg-inverse bg-opacity-10 rounded-5 p-1 me-2">
//                     <div className="text-inverse text-opacity-80">
//                       {i18n.language === "ar"?
//                         t("Arabic")
//                         : t("English")}
//                     </div>
//                   </div>

//                 <div>
//                   <a
//                     href="#modalLanguage"
//                     data-bs-toggle="modal"
//                     className="btn btn-outline-default w-100px"
//                   >
//                      {t("Change")}
//                   </a>
//                 </div>
//               </div>

//             </div>
//           </Card>
//         </div>

//         {/* <div id="system" className="mb-5">
//           <h4>
//             <i className="far fa-hdd fa-fw text-theme"></i> System
//           </h4>
//           <p>System storage, bandwidth and database setting</p>
//           <Card>
//             <div className="list-group list-group-flush">
//               <div className="list-group-item d-flex align-items-center">
//                 <div className="flex-1 text-break">
//                   <div>Web storage</div>
//                   <div className="text-inverse text-opacity-50">
//                     40.8gb / 100gb
//                   </div>
//                 </div>
//                 <div>
//                   <a
//                     href="#modalEdit"
//                     data-bs-toggle="modal"
//                     className="btn btn-outline-default w-100px"
//                   >
//                     Manage
//                   </a>
//                 </div>
//               </div>
//               <div className="list-group-item d-flex align-items-center">
//                 <div className="flex-1 text-break">
//                   <div>Monthly bandwidth</div>
//                   <div className="text-inverse text-opacity-50">
//                     Unlimited
//                   </div>
//                 </div>
//               </div>
//               <div className="list-group-item d-flex align-items-center">
//                 <div className="flex-1 text-break">
//                   <div>Database</div>
//                   <div className="text-inverse text-opacity-50">
//                     MySQL version 8.0.19
//                   </div>
//                 </div>
//                 <div>
//                   <a
//                     href="#modalEdit"
//                     data-bs-toggle="modal"
//                     className="btn btn-outline-default w-100px disabled"
//                   >
//                     Update
//                   </a>
//                 </div>
//               </div>
//               <div className="list-group-item d-flex align-items-center">
//                 <div className="flex-1 text-break">
//                   <div>Platform</div>
//                   <div className="text-inverse text-opacity-50">
//                     PHP 7.4.4, NGINX 1.17.0
//                   </div>
//                 </div>
//                 <div>
//                   <a
//                     href="#modalEdit"
//                     data-bs-toggle="modal"
//                     className="btn btn-outline-success w-100px"
//                   >
//                     Update
//                   </a>
//                 </div>
//               </div>
//             </div>
//           </Card>
//         </div> */}
//       </div>

//       <div className="col-xl-3">
//         <NavScrollTo>
//           <nav className="nav">
//             <a className="nav-link" href="#hr" data-toggle="scroll-to">
//               إدارة الموارد البشرية
//             </a>
//             <a
//               className="nav-link"
//               href="#notifications"
//               data-toggle="scroll-to"
//             >
//               ادارة الموافقات
//             </a>
//             <a
//               className="nav-link"
//               href="#privacyAndSecurity"
//               data-toggle="scroll-to"
//             >
//               الخدمات المساندة
//             </a>
//             <a
//               className="nav-link"
//               href="#payment"
//               data-toggle="scroll-to"
//             >
//               إدارة الأسطول
//             </a>
//             <a
//               className="nav-link"
//               href="#shipping"
//               data-toggle="scroll-to"
//             >
//               إدارة النظام
//             </a>
//             <a
//               className="nav-link"
//               href="#languages"
//               data-toggle="scroll-to"
//             >
//               لغة النظام
//             </a>
//             {/* <a className="nav-link" href="#system" data-toggle="scroll-to">System</a> */}
//           </nav>
//         </NavScrollTo>
//       </div>
//     </div>
//   </div>
// </div>
// </div>

// <SmallModalComponent
// id="modalEdit"
// title={
//   i18n.language === "ar"
//     ? SettingsLiat?.find((f) => f.id === settingId)?.name2
//     : SettingsLiat?.find((f) => f.id === settingId)?.name
// }
// >
// {settingId && <UpdateNtxSettings settingId={settingId} />}
// </SmallModalComponent>

// <SmallModalComponent
// id="modalLanguage"
// title="لغة النظام"
// >
// <NtxLanguage/>
// </SmallModalComponent>
