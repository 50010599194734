import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import AddForm from './AddForm.js';
import Pagination from '../../../../components/Shared/Pagination/Pagination.jsx';
import useLookupModule from '../../../../hooks/Lookups/use-Lookup-Module.js';
import { Card } from '../../../../components/card/card.jsx';
// import ModalComponent from '../../../../components/Modal/ModalComponent.jsx';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import useGetDynamicForm from '../../../../hooks/Forms/useGetDynamicForm.js';
import { usePermissions } from '../../../../Helper/Permisstions/usePermissions.ts';
import Loading from '../../../../components/Shared/Loader/Loading';
import NeatixModal from '../../../../components/Modal/NeatixModal.jsx';
import RequestsNotification from './RequestsNotification/RequestsNotification';
import EditForm from './EditForm.js';
import MedModalComponent from '../../../../components/Modal/MedModalComponent.jsx';

const Forms = () => {
  const { HasPermission } = usePermissions();

  const [formId, setFormId] = useState('');
  const [DataDynamicForm, setDataDynamicForm] = useState();
  const [Show, setShow] = useState(false);
  const [Show2, setShow2] = useState(false);
  const { t, i18n } = useTranslation();
  const [search, setSearch] = useState('');

  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const Navigate = useNavigate();
  const { loading, DynamicFormAme, error } = useGetDynamicForm();
  const [selectedValue, setSelectedValue] = useState(null);
  const { OptionsModule, loading: loading2 } = useLookupModule();

  //_________________________________________________________________________________________________
  const filteredSearch = DynamicFormAme?.slice()
    ?.filter((item) => {
      const searchLowerCase = search.toLowerCase();
      return (
        item.name?.toLowerCase().includes(searchLowerCase) ||
        item.name2?.toLowerCase().toString().includes(searchLowerCase)
      );
    })
    ?.filter((f) => (selectedValue === null ? true : f.moduleId === selectedValue));

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  const titleModal = () => {
    return (
      <div className="">
        {t('NotificationSettings')}
        {'  '}
        <span className="text-theme">
          {`(${i18n.language === 'en' ? DataDynamicForm?.name : DataDynamicForm?.name2})`}
        </span>
      </div>
    );
  };

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: t('RequestForms'),
      link: null,
    },
  ];

  return (
    <>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName={t('RequestForms')} />

        <div className="ms-auto">
          {HasPermission('AmeAddForm') && (
            <Link
              to=""
              className="btn btn-theme"
              data-bs-toggle="modal"
              data-bs-target="#modalAddForm"
              onClick={() => setShow(true)}>
              <i className="fa fa-plus-circle fa-fw me-1"></i>
              {t('AddForm')}
            </Link>
          )}
        </div>
      </div>

      <Card>
        <Loading loading={loading} error={error}>
          <>
            <div className="tab-content p-3">
              <div className="row mb-2">
                <div className="col-lg-6">
                  <div className="input-group flex-nowrap">
                    <span className="input-group-text fw-bold">
                      {t('Search')}
                      {/* <i className="fa fa-search  text-theme ms-3"></i> */}
                    </span>
                    <div className="input-group">
                      <div className="flex-fill position-relative">
                        <div className="input-group border-0 ">
                          <div
                            className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                            style={{ zIndex: 1020, right: '10px' }}>
                            <i className="fa fa-search opacity-5 text-theme"></i>
                          </div>
                          <input
                            type="text"
                            className="form-control ps-35px"
                            // placeholder={t('Search')}
                            value={search}
                            onChange={(e) => {
                              setSearch(e.target.value);
                              setCurrentPage(1);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="input-group flex-nowrap">
                    <span className="input-group-text  fw-bold">{t('Application')}</span>
                    <Select
                      classNamePrefix="react-select"
                      className="w-100"
                      isLoading={loading2}
                      isSearchable={true}
                      isClearable
                      options={[...OptionsModule]}
                      onChange={(option) => {
                        setSelectedValue(option === null ? null : option.value);
                      }}
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                    />
                  </div>
                </div>
              </div>

              {/* //  START TABLE   */}
              <div className="table-responsive">
                <table className="table text-nowrap">
                  <thead className="table-dark">
                    <tr>
                      <th scope="col" style={{ width: '25%' }}>
                        {t('Name')}
                      </th>
                      <th scope="col" style={{ width: '25%' }}>
                        {t('Application')}
                      </th>
                      <th scope="col" style={{ width: '10%' }}>
                        {t('Icon')}
                      </th>
                      <th scope="col" style={{ width: '40%' }}></th>
                    </tr>
                  </thead>

                  <tbody>
                    {currentData?.map((item, index) => (
                      <tr key={item.id}>
                        <td className="align-middle">
                          {i18n.language === 'ar' ? item.name2 : item.name}
                        </td>

                        <td className="align-middle">
                          {i18n.language === 'ar' ? item.moduleName2 : item.moduleName}
                        </td>
                        <td className="align-middle">
                          <div className="text-theme fs-4">
                            <i className={item.icon}></i>
                          </div>
                        </td>
                        <td className="text-end ">
                          <div className="">
                            {HasPermission('AmeEditForm') && (
                              <button
                                type="button"
                                // onClick={() => Navigate(`${item.id}/EditForm`)}
                                onClick={() => setFormId(item.id)}
                                className="btn  btn-outline-theme me-1"
                                data-bs-toggle="modal"
                                data-bs-target="#modalEditForm"
                                data-toggle="tooltip"
                                title={t('Button.Edit')}>
                                <i className="far fa-lg fa-fw fa-edit me-1"></i>
                                {t('Button.Edit')}
                              </button>
                            )}

                            {HasPermission('AmeAddFieldsForms') && (
                              <button
                                hidden={item?.isStatic}
                                type="button"
                                onClick={() => Navigate(`${item.id}/AddFormColumns`)}
                                className="btn btn-outline-theme me-1"
                                data-toggle="tooltip"
                                title={t('fieldsForm')}>
                                <i className="fab fa-lg fa-fw fa-elementor ms-1"></i>
                                {t('fieldsForm')}
                              </button>
                            )}

                            {HasPermission('AmeEditForm') && (
                              <button
                                type="button"
                                onClick={() => {
                                  setDataDynamicForm(item);
                                  setShow2(true);
                                }}
                                className="btn btn-outline-theme ms-1"
                                data-toggle="tooltip">
                                <i className="far fa-lg fa-fw bi bi-bell me-1"></i>
                                {t('NotificationSettings')}
                              </button>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <Pagination
                filteredData={filteredSearch}
                // ?.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))}
                dataPerPage={dataPerPage}
                handlePagination={(pageNumber) => setCurrentPage(pageNumber)}
                currentPage={currentPage}
              />
            </div>
          </>
        </Loading>
      </Card>

      <MedModalComponent title={t('AddForm')} id="modalAddForm">
        {Show && <AddForm />}
      </MedModalComponent>
      <MedModalComponent title={t('EditForm')} id="modalEditForm">
        {formId && <EditForm id={formId} />}
      </MedModalComponent>

      <NeatixModal
        show={Show2}
        setShow={setShow2}
        bodyClassName="p-1"
        size="lg"
        title={titleModal()}
        // title={`اعدادات الإشعار (${
        //   i18n.language === 'en' ? DataDynamicForm?.name : DataDynamicForm?.name2
        // })`}
      >
        <RequestsNotification DataDynamicForm={DataDynamicForm} setShow={setShow2} />
      </NeatixModal>
    </>
  );
};

export default Forms;
