import React, { Fragment, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ToastSwal from '../../../../../components/Shared/Alert/ToastSwal';

const Step3 = ({ fields, setCheckValidationStep3, setDataStep3, DataById }) => {
  const { t, i18n } = useTranslation();

  const [tableFields, setTableFields] = useState(DataById);
  const [sqlName, setsqlName] = useState('');
  const [inputType, setInputType] = useState('');
  const [aliasName, setAliasName] = useState('');
  const [label, setLabel] = useState('');

  useEffect(() => {
    setTableFields((prevState) =>
      prevState?.map((item) => ({
        ...item,
        Label: fields?.find((f) => f.name === item.sqlName)?.label,
      }))
    );
  }, [fields, DataById]);

  const handleAddToTable = (e) => {
    e.preventDefault();
    if (sqlName && aliasName) {
      // Check if the already exists in the table array

      const Exists = tableFields?.some((item) => item.sqlName === sqlName);
      if (Exists) {
        ToastSwal({ icon: 'info', title: 'تمت إضافته مسبقاً', position: 'top' });
        return;
      }

      const newData = {
        id: null,
        sqlName: sqlName,
        name: aliasName,
        Label: label,
        AliasName: aliasName,
        Sequance: tableFields?.length + 1,
        inputType: inputType,
      };
      setTableFields([...tableFields, newData]);
      setsqlName('');
      setAliasName('');
      setLabel('');
    }
  };

  const handleDeleteRowClick = (sqlNameToDelete) => {
    const updatedTableFields = tableFields?.filter((item) => item.sqlName !== sqlNameToDelete);
    setTableFields(updatedTableFields);
  };

  //_______________________ Stard Validation Table  ______________________________________
  useEffect(() => {
    setCheckValidationStep3(tableFields?.length > 0);
    setDataStep3(tableFields);
  }, [tableFields, setCheckValidationStep3]);
  //_______________________ End Validation Table  ______________________________________

  return (
    <Fragment>
      <Form onSubmit={handleAddToTable}>
        <div className="row align-items-end ">
          <div className="col-xl-5">
            <div className="form-group mb-3">
              <label className="form-label">{t('Column')}</label>
              <Form.Select
                className="form-select"
                value={sqlName}
                onChange={(e) => {
                  setsqlName(e.target.value);
                  setInputType(e.target.selectedOptions[0].dataset.inputType);
                  setLabel(e.target.selectedOptions[0].dataset.label);
                }}
                required>
                <option>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                {fields &&
                  fields?.map((item, idx) => (
                    <option
                      value={item.name}
                      key={idx}
                      data-input-type={item.inputType}
                      data-label={item.label}>
                      {i18n.language === 'ar' ? item.label : item.label}
                    </option>
                  ))}
              </Form.Select>
            </div>
          </div>
          <div className="col-xl-5">
            <div className="form-group mb-3">
              <label className="form-label">{t('Alias_Name')}</label>
              <Form.Control
                className="form-control"
                type="text"
                value={aliasName}
                onChange={(e) => setAliasName(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="col-xl-2 text-end  ">
            <div className=" form-group mb-3 d-flex justify-content-end ">
              <button type="submit" className="btn me-1 btn-outline-theme w-100">
                <i className="fa fa-plus-circle fa-fw me-1"></i>
                {t('Add')}
              </button>
            </div>
          </div>
        </div>
 
      </Form>

      {tableFields?.length > 0 ? (
        <div className="table-responsive rounded-2 text-center border ">
          <table className="table table-hover m-0">
            <thead className="bg-theme bg-opacity-60">
              <tr>
                <th scope="col"></th>
                <th scope="col">{t('Column')}</th>
                <th scope="col">{t('Alias_Name')}</th>
                {/* <th scope="col">Sequance</th> */}
                <th scope="col"></th>
              </tr>
            </thead>

            <tbody>
              {tableFields?.map((item, index) => (
                <tr key={++index}>
                  <td className="align-middle">
                    <a
                      href="/#"
                      title="مقبض السحب"
                      style={{
                        textDecoration: 'none',
                        cursor: 'grab',
                        pointerEvents: 'none',
                      }}>
                      ⁞⁞
                    </a>
                  </td>
                  <td>{item.Label}</td>
                  <td>{item.name}</td>

                  {/* <td>{item.Sequance}</td> */}
                  <td>
                    <Link
                      style={{ color: 'red' }}
                      onClick={() => handleDeleteRowClick(item.sqlName)}>
                      <i className="fas fa-lg fa-fw me-3 fa-trash-alt"></i>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="border border-theme text-center bg-inverse bg-opacity-10 rounded-2 p-3 fw-bold">
          {t(
            ' يرجى إضافة الأعمدة التي تريد تضمينها في رسالة الإشعار من خلال واجهة التحرير في الخطوة التالية.'
          )}
        </div>
      )}
    </Fragment>
  );
};

export default memo(Step3);
