import React, { useEffect, useState } from 'react';
import * as yup from 'yup'; //
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import useLookupModule from '../../../../../../hooks/Lookups/use-Lookup-Module';
import { GetReportSourceList } from '../../../../../../store/NTX/Reports/reportSlice';
import { DataIcons } from '../../../../../../assets/icons';
import { useSelector } from 'react-redux';
import useGetDynamicForm from '../../../../../../hooks/Forms/useGetDynamicForm';

const Step1 = ({ setCheckValidationStep1, setDataStep1 }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { Module } = useLookupModule();
  const { DynamicFormList } = useGetDynamicForm();

  useEffect(() => {
    dispatch(GetReportSourceList());
  }, [dispatch]);
  const { ReportSourceList } = useSelector((state) => state.Reports);

  //_______________________________________________
  // Define a validation schema using yup
  const validationSchema = yup.object().shape({
    name: yup.string().required(t('Name is required')),
    name2: yup.string().required(t('Name2 is required')),
    moduleId: yup.string().required(t('Module is required')),
    reportSourceId: yup.string().required(t('Table Name is required')),
    pageSize: yup.string().required(t('page Size is required')),

    FormRecordId: yup.string().when('typeForm', {
      is: '3',
      then: () =>
        yup
          .string()
          .notOneOf(['إختر', 'Choose'], 'Required')
          .required(() => t('Required')),
    }),
  });

  const [formData, setFormData] = useState({
    name: '',
    name2: '',
    moduleId: '',
    viewName: '',
    pageSize: '',
    icon: '',
    reportSourceId: '',

    typeForm: '0',
    tableId: null,
    hasExtraInformation: 'false',
    hasExtraInformationRecord: 'false',
    hasExtraInformationType: 'false',
    FormRecordId: '',
    FormTypeId: '',
  });

  const handleInputChange = async (e) => {
    const { name, value, options } = e.target;

    if (options !== undefined && name === 'reportSourceId') {
      let selectedOption = options[options.selectedIndex];
      setFormData({
        ...formData,
        typeForm: '0',
        FormRecordId: '',
        [name]: value,
        viewName: selectedOption.getAttribute('data-view-name') || null,
        tableId: selectedOption.getAttribute('data-table-id') || null,
        hasExtraInformation: selectedOption.getAttribute('data-has-extra-information') || 'false',
        hasExtraInformationRecord:
          selectedOption.getAttribute('data-has-extra-information-record') || 'false',
        hasExtraInformationType:
          selectedOption.getAttribute('data-has-extra-information-type') || 'false',
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  // Validate function
  const areAllFieldsFilled = () => {
    try {
      validationSchema.validateSync(formData, { abortEarly: false });
      return true; // All fields are filled
    } catch (error) {
      return false; // Some field is empty
    }
  };

  useEffect(() => {
    setCheckValidationStep1(areAllFieldsFilled());
    setDataStep1(formData);
  }, [formData]);
  //________________________

  var FormRecordListByTableId = DynamicFormList?.filter(
    (f) => f.dynamicFormTypesId === 3 && f.tableId === formData.tableId
  );

  var FormTypeListByTableId = DynamicFormList?.filter(
    (f) => f.dynamicFormTypesId === 4 && f.tableId === formData.tableId
  );

  return (
    <>
      <div className="row">
        <div className="col-xl-4">
          <div className="form-group mb-3">
            <label className="form-label">{t('LocalName')}</label>
            <Form.Control
              className="form-control"
              type="text"
              name="name2"
              value={formData.name2}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="col-xl-4">
          <div className="form-group mb-3">
            <label className="form-label">{t('GlobalName')}</label>
            <Form.Control
              className="form-control"
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="col-xl-4">
          <div className="form-group mb-3">
            <label className="form-label">{t('Application')}</label>
            <Form.Select
              className="form-select"
              name="moduleId"
              value={formData.moduleId}
              onChange={handleInputChange}>
              <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
              {Module &&
                Module?.map((item, idx) => (
                  <option key={++idx} value={item.id}>
                    {i18n.language === 'ar' ? item.name2 : item.name}
                  </option>
                ))}
            </Form.Select>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-4">
          <div className="form-group mb-3">
            <label className="form-label">{t('Data_Source')}</label>
            <Form.Select
              className="form-select mb-3"
              name="reportSourceId"
              value={formData.reportSourceId}
              // onChange={(e) => handleDataSourceSelect(e)}
              onChange={(e) => handleInputChange(e)}>
              <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
              {ReportSourceList &&
                ReportSourceList?.map((item, idx) => (
                  <option
                    key={item.id}
                    value={item.id}
                    data-view-name={item.viewName}
                    data-table-id={item.tableId}
                    data-has-extra-information={item.hasExtraInformation}
                    data-has-extra-information-record={item.hasExtraInformationRecord}
                    data-has-extra-information-type={item.hasExtraInformationType}>
                    {i18n.language === 'ar' ? item.name2 : item.name}
                  </option>
                ))}
            </Form.Select>
          </div>
        </div>

        <div className="col-xl-4">
          <div className="form-group mb-3">
            <label className="form-label">{t('Layout')}</label>
            <Form.Select
              className="form-select mb-3"
              name="pageSize"
              value={formData.pageSize}
              onChange={(e) => handleInputChange(e)}>
              <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
              <option value="Landscape">Landscape</option>
              <option value="Portrait">Portrait</option>
            </Form.Select>
          </div>
        </div>

        <div className="col-xl-4" style={{ zIndex: 0 }}>
          <div className="form-group mb-3">
            <label className="form-label">{t('Icon')}</label>

            <Select
              classNamePrefix="react-select"
              // classNamePrefix="select"
              isSearchable={true}
              required
              options={DataIcons}
              onChange={(option) => setFormData({ ...formData, icon: option.value })}
              placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              }}
            />
          </div>
        </div>
      </div>

      {(formData.hasExtraInformation === 'true' ||
        formData.hasExtraInformationRecord === 'true' ||
        formData.hasExtraInformationType === 'true') && (
        <div className="row">
          <div className="col-xl-4">
            <div className="form-group mb-3">
              <label className="form-label">نوع البيانات</label>
              <Form.Select
                className="form-select mb-3"
                name="typeForm"
                value={formData.typeForm}
                onChange={(e) => handleInputChange(e)}>
                <option value="0">
                  {i18n.language === 'ar' ? 'البيانات الأساسية' : 'Basic Data'}
                </option>
                {formData.hasExtraInformation === 'true' && (
                  <option value="2">
                    {i18n.language === 'ar' ? ' مع البيانات الاضافية' : 'with Extra Information'}
                  </option>
                )}
                {formData.hasExtraInformationRecord === 'true' && (
                  <option value="3">
                    {i18n.language === 'ar'
                      ? ' مع السجلات الإضافية'
                      : 'with Extra Information Record'}
                  </option>
                )}
                {formData.hasExtraInformationType === 'true' && (
                  <option value="4">
                    {i18n.language === 'ar' ? 'مع الأنواع الإضافية' : 'with Extra Information Type'}
                  </option>
                )}
              </Form.Select>
            </div>
          </div>

          <div className="col-xl-4">
            {formData.hasExtraInformationRecord === 'true' &&
              formData.tableId !== null &&
              formData.typeForm === '3' && (
                <div className="form-group mb-3">
                  <label className="form-label">{t('Record')}</label>
                  <Form.Select
                    className="form-select mb-3"
                    name="FormRecordId"
                    value={formData.FormRecordId}
                    onChange={(e) => handleInputChange(e)}>
                    <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                    {DynamicFormList &&
                      FormRecordListByTableId?.map((item, idx) => (
                        <option key={item.id} value={item.id}>
                          {i18n.language === 'ar' ? item.name2 : item.name}
                        </option>
                      ))}
                  </Form.Select>
                </div>
              )}

            {formData.hasExtraInformationType === 'true' &&
              formData.tableId !== null &&
              formData.typeForm === '4' && (
                <div className="form-group mb-3">
                  <label className="form-label">{t('Type')}</label>
                  <Form.Select
                    className="form-select mb-3"
                    name="FormTypeId"
                    value={formData.FormTypeId}
                    onChange={(e) => handleInputChange(e)}>
                    <option value="">{i18n.language === 'ar' ? 'كل الأنواع' : 'All Types'}</option>
                    {DynamicFormList &&
                      FormTypeListByTableId?.map((item, idx) => (
                        <option key={item.id} value={item.id}>
                          {i18n.language === 'ar' ? item.name2 : item.name}
                        </option>
                      ))}
                  </Form.Select>
                </div>
              )}
          </div>
        </div>
      )}
    </>
  );
};
export default Step1;
