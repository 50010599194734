import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  GetReportById,
  GetReportSourceFeild,
  UpdatNtxReports,
  cleanReportsListById,
} from '../../../../../store/NTX/Reports/reportSlice';
import BuilderQuerys from './Step2/BuilderQuerys';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, CardHeader } from '../../../../../components/card/card';
import Step3 from './Step3/Step3';
import Step2 from './Step2/Step2';
import Step1 from './Sterp1/Step1';
import { Link, useNavigate, useParams } from 'react-router-dom';
import CustomAlert from '../../../../../components/Shared/Alert/CustomAlert';
import { useSelector } from 'react-redux';
import Loader from '../../../../../components/Shared/Loader/Loader';
import showConfirmation from '../../../../../components/Shared/Alert/Confirmation';
import Breadcrumb from '../../../../../components/Shared/Breadcrumb';
import i18n from '../../../../../i18n';

const UpdateReport = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fieldsName, setFieldsName] = useState([]);
  const [params, setParams] = useState([]);

  //_____________________ States Check Validation  _____________________
  const [checkValidationStep1, setCheckValidationStep1] = useState(false);
  const [checkValidationStep2, setCheckValidationStep2] = useState(false);
  const [checkValidationStep3, setCheckValidationStep3] = useState(false);
  //_____________________ States Check Validation  _____________________

  const [DataStep1, setDataStep1] = useState({}); // for submit
  const [DataStep2, setDataStep2] = useState([]); // for submit
  const [DataStep3, setDataStep3] = useState([]); // for submit
  const [Query, setQuery] = useState(''); // for submit  from step2
  const [queryBuilderReact, setQueryBuilderReact] = useState(''); // for submit  from step2

  //___ Start Steps Wiserd___________
  const [currentStep, setCurrentStep] = useState(1);
  const handleNextStep = async () => {
    if (currentStep === 1) {
      if (checkValidationStep1) {
        await dispatch(
          GetReportSourceFeild({
            typeForm: DataStep1?.typeForm,
            viewName: DataStep1?.viewName,
            formTypeId: DataStep1?.dynamicFormId || null,
            formRecordId: DataStep1?.dynamicFormId || null,
            tableId: DataStep1?.tableId,
            hasExtraInformation: DataStep1?.hasExtraInformation, //boolean
            hasExtraInformationRecord: DataStep1?.hasExtraInformationRecord, //boolean
            hasExtraInformationType: DataStep1?.hasExtraInformationType, //boolean
          })
        )
          .unwrap()
          .then((res) => {
            // debugger
            if (res?.succeeded === true) {
              let x = res?.data?.map((item) => ({
                name: item.value,
                label: item.name,
                inputType: item.inputType,
                defaultValue: '',
              }));
              setFieldsName(x);
            }
          })
          .catch((error) => {
            console.log(error);
          });
        setCurrentStep(2);
      } else {
        alert('أكمل جميع الحقول');
        return;
      }
    } else if (currentStep === 2) {
      if (checkValidationStep2 || params?.length === 0) {
        setCurrentStep(3);
      } else {
        alert('أكمل جميع البيانات');
        return;
      }
    } else if (currentStep === 3) {
      ////// No Thing
    }
  };

  const handlePreviousStep = () => {
    if (currentStep === 2) {
      // Move from step 2 to step 1
      setCurrentStep(1);
    } else if (currentStep === 3) {
      // Move from step 3 to step 2
      setCurrentStep(2);
    } else if (currentStep === 4) {
      // Move from step 4 to step 3
      setCurrentStep(3);
    }
    // You can add more conditions for additional steps if needed
  };
  //___ End Steps Wiserd___________

  const { id } = useParams();
  useEffect(() => {
    dispatch(cleanReportsListById());
    dispatch(GetReportById(id));
  }, []);
  const { ReportsListById, loading } = useSelector((state) => state.Reports);

  //___ Submit and post to server___________
  const SaveUpdate = (e) => {
    showConfirmation('هل أنت متأكد من حفظ التغييرات التي اجريتها على هذا التقرير ؟', async () => {
      if (checkValidationStep3) {
        setIsSubmitting(true);
        dispatch(
          UpdatNtxReports({
            dynamicFormId: DataStep1.dynamicFormId,
            reportSourceId: DataStep1.reportSourceId,
            dynamicFormType: DataStep1.typeForm,
            id: ReportsListById.id,
            name: DataStep1.name,
            name2: DataStep1.name2,
            moduleId: DataStep1.moduleId,
            tableName: DataStep1.tableName,
            query: Query,
            description: 'description',
            pageType: DataStep1.pageSize,
            reportType: 'Type1',
            queryBuilderReact: queryBuilderReact,
            icon: DataStep1.icon,
            reportColumnsList: DataStep3?.map((item) => ({
              id: item.id,
              name: item.name,
              name2: item.name2,
              dataType: item.dataType,
              sqlName: item.sqlName,
              sequenceNum: item.sequenceNum,
            })),
            reportParamerersList: DataStep2?.map((item) => ({
              id: item.id,
              paramName: item.paramName,
              paramValue: item.paramValue,
              flexValueSetId: item.flexValueSetId,
              isStatic: item.isStatic,
              lable: item.lable,
              lable2: item.lable, // lable2 = lable  based on hamed say
              sequenceNum: item.sequenceNum,
              isVisible: item.isVisible,
              hasIn: item.hasIn,
              endWith: item.endWith,
              beginWith: item.beginWith,
              requiredFlag: item.requiredFlag,
              language: parseInt(item.language),
            })),
          })
        )
          .unwrap()
          .then((res) => {
            if (res.succeeded === true) {
              Navigate('/RPT/Reports', { replace: true });
              CustomAlert({ action: 'Edit' });
              setIsSubmitting(false);
            } else {
              CustomAlert({ action: 'Error' });
              setIsSubmitting(false);
            }
          })
          .catch((error) => {
            CustomAlert({ action: 'Error' });
            setIsSubmitting(false);
            console.log(error);
          });
      }
    });
  };

  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: t('Reports_Management'),
      link: '/NTX/Reports',
    },
    {
      name: t('Edit_Reports'),
      link: null,
    },
  ];

  return (
    <div>
      <Breadcrumb
        BreadcrumbList={BcrumbList}
        PageName={t('Edit_Reports')}
        subtitle={i18n.language === 'en' ? ReportsListById.name : ReportsListById.name2}
      />

      {/* //_________________________________________________ */}

      <Card>
        <CardHeader>
          <div className="nav-wizards-container" dir="ltr">
            <nav className="nav nav-wizards-1 mb-2">
              <div className="nav-item col">
                <Link
                  className={`nav-link ${currentStep === 3 || currentStep === 4 ? 'active' : ''}`}>
                  <div className="nav-no">3</div>
                  <div className="nav-text">{t('Report_columns')}</div>
                </Link>
              </div>

              <div className="nav-item col">
                <Link
                  className={`nav-link ${
                    currentStep === 2 || currentStep === 3 || currentStep === 4 ? 'active' : ''
                  }  `}>
                  <div className="nav-no">2</div>
                  <div className="nav-text">{t('Report_variants')}</div>
                </Link>
              </div>

              <div className="nav-item col">
                <Link
                  className={`nav-link ${
                    currentStep === 1 || currentStep === 2 || currentStep === 3 || currentStep === 4
                      ? 'active'
                      : ''
                  }`}>
                  <div className="nav-no">1</div>
                  <div className="nav-text">{t('Report_information')}</div>
                </Link>
              </div>
            </nav>
          </div>
        </CardHeader>

        <CardBody style={{ minHeight: '20rem' }}>
          <Loader loading={loading} />
          {/* ______________ Step 1 _______________ */}

          <div style={{ display: currentStep === 1 ? 'block' : 'none' }}>
            <Step1
              setCheckValidationStep1={setCheckValidationStep1}
              setDataStep1={setDataStep1}
              DataById={ReportsListById}
            />
          </div>

          {/* ______________ Step 2 _______________ */}

          <div style={{ display: currentStep === 2 ? 'block' : 'none' }}>
            <div className="row">
              <div className="col-xl-12 mx-auto">
                {fieldsName && ReportsListById && Object.keys(ReportsListById)?.length > 0 && (
                  <BuilderQuerys
                    fields={fieldsName}
                    setParams={setParams}
                    setQuery={setQuery}
                    setQueryBuilderReact={setQueryBuilderReact}
                    queryBuilderReact={ReportsListById?.queryBuilderReact}
                  />
                )}
              </div>
            </div>
            <div className="mb-3" />
            <div className="row">
              <div className="col-xl-12">
                {fieldsName?.length > 0 && (
                  <Step2
                    params={params}
                    fields={fieldsName}
                    setCheckValidationStep2={setCheckValidationStep2}
                    setDataStep2={setDataStep2}
                    ReportParamerersById={ReportsListById?.reportParamerersList}
                  />
                )}
              </div>
            </div>
          </div>

          {/* ______________ Step 3 _______________ */}

          <div style={{ display: currentStep === 3 ? 'block' : 'none' }}>
            {fieldsName && Object.keys(ReportsListById)?.length > 0 && (
              <Step3
                fields={fieldsName}
                setCheckValidationStep3={setCheckValidationStep3}
                setDataStep3={setDataStep3}
                DataById={ReportsListById.reportColumnsList}
              />
            )}
          </div>
        </CardBody>

        <hr className="mb-3" style={{ zIndex: '-1' }} />
        <div className="text-center mb-3">
          <div className="btn-group btn-group-lg">
            <button type="button" className="btn btn-secondary mb-1" onClick={handlePreviousStep}>
              <i className="fas fa-lg fa-fw me-2 fa-arrow-alt-circle-right"></i>
              {t('Previous')}
            </button>
            <button
              type="button"
              className="btn btn-light  mb-1"
              onClick={() => Navigate('/RPT/Reports', { replace: true })}>
              {t('Cancel')}
            </button>

            <button
              type="submit"
              className="btn me-1 btn-theme mb-1"
              onClick={handleNextStep}
              disabled={currentStep === 3}>
              {currentStep === 4 ? t('Button.Save') : t('Next')}
              {currentStep !== 4 ? (
                <i
                  className="fas fa-lg fa-fw me-0 fa-arrow-alt-circle-left"
                  style={{ padding: '0px 12px 0px 0px' }}></i>
              ) : (
                <i
                  className="far fa-lg fa-fw me-2 fa-save"
                  style={{ padding: '0px 12px 0px 0px' }}></i>
              )}
            </button>

            {currentStep === 3 && (
              <button
                disabled={isSubmitting}
                type="button"
                className="btn btn-theme  mb-1"
                onClick={SaveUpdate}>
                {t('Save')}
              </button>
            )}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default UpdateReport;
