import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

 import Pagination from "../../../../components/Shared/Pagination/Pagination.jsx";
import { Card, CardBody } from "../../../../components/card/card.jsx";
import Loader from "../../../../components/Shared/Loader/Loader.js";
import { GetContractLease } from "../../../../store/SU/contractLeaseSlice.js";
import Breadcrumb from "../../../../components/Shared/Breadcrumb";

const Contracts = () => {
  // const [Show, setShow] = useState(false);
  const { t, i18n } = useTranslation();
  const Navigate = useNavigate();
  /////////////////////////////////////////////////////
  const [search, setSearch] = useState("");
  // const [sortBy, setSortBy] = useState("");
  // const [sortOrder, setSortOrder] = useState("asc");
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);

  const { ContractLeaseList, loading } = useSelector(
    (state) => state.Contracts
  );

  const dispatch = useDispatch();
  useEffect(() => {
    if(ContractLeaseList?.length === 0){
      dispatch(GetContractLease());
    }
  }, [dispatch]);

  //_____________________
//   function filterData(data, filterValue) {
//     return data?.filter(item => {
//       return Object.values(item).some(value => {
//           if (typeof value === 'number' && value === filterValue) {
//               return true;
//           } else if (typeof value === 'string' && value.includes(filterValue)) {
//               return true;
//           } else if (value instanceof Date) {
//               return value.toISOString().includes(filterValue);
//           }
//           return false;
//       });
//   });
// }
// const filteredData = filterData(ContractLeaseList,  search);
  //_____________________
  const filteredSearch = ContractLeaseList?.slice()?.filter((item) => {
    const searchLowerCase = search.toLowerCase();
    return (
      item.name?.toLowerCase().includes(searchLowerCase) ||
      item.name2?.toLowerCase().toString().includes(searchLowerCase) ||
      item.contractLeaseId?.toLowerCase().includes(searchLowerCase)
    );
  });

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  // report to xls
 
  //_____________________
  var BcrumbList = [
    {
      name: t('Home') ,
      link: "/",
    },
    {
      name: "Contracts",
      link: null,
    },
  ];

  return (
    <>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName= "Contracts" />
      <Card>
        <CardBody>
          <div className="tab-content">
            <div className="tab-pane fade show active">
              <div className="row">
                <div className="col-7 col-md-6 d-flex justify-content-start">
                  <div className="input-group mb-4">
                    <div className="flex-fill position-relative">
                      <div className="input-group">
                        <div
                          className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                          style={{ zIndex: 1020, right: "10px" }}
                        >
                          <i className="fa fa-search opacity-5"></i>
                        </div>
                        <input
                          type="text"
                          className="form-control ps-35px"
                          onChange={(event) => setSearch(event.target.value)}
                          placeholder={t("Search") + " ..."}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-5 col-md-6 text-end flex ">
                  <Link to="AddContarcts" className="btn btn-theme me-1">
                    <i className="fa fa-plus-circle fa-fw me-1"></i>
                    {t("Add_Contract")}
                  </Link>

                </div>
              </div>

              <Loader loading={loading} />

              {/* //  START TABLE   */}
              <div className="table-responsive">
                <table className="table ">
                  <thead className="table-dark">
                    <tr>
                      <th scope="col">رقم العقد</th>
                      <th scope="col">رقم الايجار</th>
                      <th scope="col">بداية العقد</th>
                      <th scope="col">نهاية العقد</th>
                      <th scope="col">المستأجر</th>
                      <th scope="col">اسم العقار</th>
                      <th scope="col">قيمة الايجار</th>
                      <th scope="col">الفرع</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td className="align-middle">
                            {item.contractLeaseCode}
                          </td>
                          <td className="align-middle">{item.ejarCode}</td>
                          <td className="align-middle">
                            {item.periodFromDate}
                          </td>
                          <td className="align-middle">{item.periodToDate}</td>
                          <td className="align-middle">{item.represented}</td>

                          <td className="align-middle">
                            {i18n.language === "en" ? item.name : item.name2}
                          </td>
                          <td className="align-middle">{item.annualRent_RealEstate}</td>
                          <td className="align-middle">
                            {i18n.language === "en"
                              ? item.organizationName
                              : item.organizationName2}
                          </td>
                          <td>
                            <div className="btn-group">
                              <button
                                type="button"
                                className="btn btn-outline-theme btn-lg"
                                onClick={() =>
                                  Navigate(
                                    `${item.contractLeaseId}/UpdateContract`
                                  )
                                }
                              >
                                <i className="far fa-lg fa-fws fa-edit text-theme"></i>
                                {/* {t("Button.Edit")} */}
                              </button>
                              {/* <button
                                type="button"
                                className="btn btn-outline-theme btn-lg"
                                // onClick={() =>
                                //   Navigate(`${item.id}/UpdateContract`)
                                // }
                              >
                                <i className="far fa-lg fa-fw me-2 bi bi-arrow-clockwise text-danger"></i>
                                تجديد العقد
                              </button> */}
                            </div>
                          </td>
                        </tr>
                      );
                    })}

                  </tbody>
                </table>
              </div>
              <Pagination
                filteredData={filteredSearch}
                dataPerPage={dataPerPage}
                handlePagination={(pageNumber) => setCurrentPage(pageNumber)}
                currentPage={currentPage}
              />
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default Contracts;

// <tr key={index} className="table-info">
// <td className="align-middle">
// {item.contractLeaseCode}
// </td>
// <td className="align-middle">
// {item.ejarCode}
// </td>
// <td className="align-middle">
//   {item.periodFromDate}
// </td>
// <td className="align-middle">
//   {item.periodToDate}
// </td>
// <td className="align-middle">
//   {item.represented}
// </td>

// <td className="align-middle">
//    {i18n.language === "en" ? item.name : item.name2}
// </td>
//  <td className="align-middle">
//   {item.annualRent}
// </td>
//  <td className="align-middle">
// {i18n.language === "en" ? item.organizationName : item.organizationName2}
// </td>
// <td>
// <div className="btn-group">

//   <button
//     type="button"
//     className="btn btn-outline-lime"
//     onClick={() => Navigate(`${item.contractLeaseId}/UpdateContract`)}
//   >
//     <i className="far fa-lg fa-fw me-2 fa-edit"></i>
//     {t("Button.Edit")}
//   </button>
//   <button
//     type="button"
//     className="btn btn-outline-lime"
//     onClick={() => Navigate(`${item.id}/UpdateContract`)}
//   >
//     <i className="far fa-lg fa-fw me-2 bi bi-arrow-clockwise"></i>
//    تجديد العقد
//   </button>
// </div>
// </td>
// </tr>
