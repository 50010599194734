import { useFormik } from 'formik';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import Select from 'react-select';
import { Card, CardFooter } from '../../../../components/card/card';
import { CardBody, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { useAppDispatch } from '../../../../store/hooks';
import SwalAlert from '../../../../components/Shared/Alert/SwalAlert';
import { useNavigate } from 'react-router-dom';
import Loading from '../../../../components/Shared/Loader/Loading';
import { addPurchaseRequest } from '../../../../store/purchases/PurchaseRequests/purchaseRequestsSlice';
import useGetCategoriesList from '../../../../hooks/INV/useGetCategoriesList';
import PRLines from './PRLines';
import { AddPurchaseRequestSchema } from '../../../ValidationForm/validationSchema';
import useGetBranchOrgWithPermission from '../../../../hooks/NTX/Organization/useGetBranchOrgWithPermission';
import useGetInventoryWithPermission from '../../../../hooks/NTX/Organization/useGetInventoryWithPermission';

const Add = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { BranchOrgOptions: BranchsOptions } = useGetBranchOrgWithPermission();
  const { InventoryOptions, loading: loading3 } = useGetInventoryWithPermission();
  const { CategoriesOptions, loading: loading4 } = useGetCategoriesList();

  //#region Formik
  const formik = useFormik({
    initialValues: {
      isAme: false,
      categoryId: null,

      organizationId: '',
      peopleId: '',
      recipientname: '',
      billToLocationId: '', // معرف الموقع الذي سيتم إرسال الفاتورة إليه (غير مطلوب دائمًا في طلب الشراء).
      shipToLocationId: '', //  معرف الموقع الذي سيتم شحن المواد إليه.
      referenceId: '', // معرف مرجعي للربط بين الطلبات المختلفة (إذا كان موجودًا).
      transactionDate: '', //  تاريخ تقديم طلب الشراء.
      deliveryDate: '', // التاريخ المتوقع لتسليم المواد المطلوبة.
      expirationDate: '',
      dueDate: '',
      paymentTermsId: '', // معرف شروط الدفع المتوقعة (إذا كانت معروفة في هذه المرحلة).
      paymentMethodId: '',
      description: '', // صف عام لطلب الشراء.
      grossAmount: '', // إجمالي المبلغ المتوقع (قد لا يكون محددًا في طلب الشراء).
      discountRate: '',
      discountAmount: '',
      vat: '',
      netAmount: '',
      contractId: '',
      ameStatus: '',
      personId: '',
      paymentTerms: '',
      deliveryTerm: '',
      inventoryId: '',
      waybill: '',
      phone: '',
      currencyCode: '',
      currencyConversionRate: '',
      batchId: '',
      transactionsLinesList: [
        {
          description: '',
          discountAmount: 0,
          discountRate: 0,
          itemId: '',
          lineNumber: 0,
          price: 1,
          qty: 1,
          total: 0,
          uom: '',
          vatAmount: 0,
          vatRate: 0,
          type: 'Inv',
        },
      ],
    },

    enableReinitialize: true,
    validationSchema: AddPurchaseRequestSchema(t),
    onSubmit: (values) => {
      // const { transactionsLinesList, ...restValues } = values;

      if (values.transactionsLinesList?.length === 0) {
        SwalAlert({ text: 'أدخل أصناف' });
        formik.setSubmitting(false);
        return;
      }

      dispatch(
        addPurchaseRequest({
          // no: values.no,
          // code: values.code + values.no, // Qut-24-1233
          // name: values.name,
          // name2: values.name2,
          // transTypeId: values.transTypeId,

          isAme: false,
          categoryId: values.categoryId,

          organizationId: values.organizationId,
          peopleId: values.peopleId || null,
          recipientname: values.recipientname,
          billToLocationId: null,
          shipToLocationId: null,
          referenceId: null,
          transactionDate: values.transactionDate,
          deliveryDate: values.deliveryDate || null,
          expirationDate: values.expirationDate || null,
          dueDate: values.dueDate || null,
          paymentTermsId: null,
          paymentMethodId: null,
          description: values.description,
          grossAmount: 0,
          discountRate: values.discountRate || 0,
          discountAmount: values.discountAmount || 0,
          vat: values.vat || 0,
          netAmount: 0,
          contractId: values.contractId || null,
          ameStatus: values.ameStatus || 0,
          personId: null,
          paymentTerms: values.paymentTerms,
          deliveryTerm: values.deliveryTerm,
          inventoryId: values.inventoryId,
          waybill: values.waybill,
          phone: values.phone,
          currencyCode: values.currencyCode,
          currencyConversionRate: values.currencyConversionRate || 0,
          batchId: values.batchId || null,
          transactionsLinesList: formik.values.transactionsLinesList?.map((item, idx) => ({
            itemId: item.itemId,
            // lineNumber: item.lineNumber,
            lineNumber: ++idx,
            uom: item.uom,
            description: item.description,
            // price: Number(item.price) || 0,
            price: 0,
            qty: Number(item.qty),
            discountRate: 0,
            discountAmount: 0,
            vatRate: 0,
            total: 0,
            vatAmount: 0,
            // unitCost: Number(item.unitCost),
            codeCombinationId: '54F915DF-8A22-486D-B860-08DC2A376B50',
            // currencyCode: 'SR',
            // currencyConversionRate: 0,
            // lotId: null,
            // headerId: null,
            // lineId: null,
            inventoryId: values.inventoryId,
          })),
        })
      )
        .unwrap()
        .then((res: any) => {
          // debugger;
          if (res?.succeeded === true) {
            CustomAlert({ action: 'Add' });
            formik.resetForm();
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error', msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });
  const { values, handleChange: formikhandleChange, errors, touched } = formik;

  // console.log(values.transactionsLinesList);

  //#endregion

  //#region BcrumbList
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'طلبات الشراء',
      link: '/PurchasesTransactions/PurchaseRequests',
    },
    {
      name: 'طلب شراء جديد',
      link: null,
    },
  ];

  return (
    <div>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName="طلب شراء جديد" />

      <Card>
        <Loading loading={loading3}>
          <CardBody>
            <Form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('تاريخ طلب الشراء')}</label>
                    <Form.Control
                      className="form-control  text-center"
                      type="date"
                      name="transactionDate"
                      onChange={formikhandleChange}
                      value={values.transactionDate || ''}
                    />

                    <div className="text-danger">
                      {touched.transactionDate && errors.transactionDate}
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('تاريخ التوريد')}</label>
                    <Form.Control
                      className="form-control  text-center"
                      type="date"
                      name="deliveryDate"
                      onChange={formikhandleChange}
                      value={values.deliveryDate || ''}
                    />
                    <div className="text-danger">{touched.deliveryDate && errors.deliveryDate}</div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('الفرع')}</label>

                    <Select
                      classNamePrefix="react-select"
                      isLoading={loading3}
                      isSearchable={true}
                      isClearable
                      options={BranchsOptions}
                      onChange={(option: any) => {
                        formik.setFieldValue(
                          'organizationId',
                          option === null ? null : option.value
                        );
                      }}
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                    />
                    <div className="text-danger">
                      {touched.organizationId && errors.organizationId}
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('المخزن')}</label>
                    <Select
                      classNamePrefix="react-select"
                      isLoading={loading3}
                      isSearchable={true}
                      isClearable
                      options={InventoryOptions}
                      onChange={(option: any) => {
                        formik.setFieldValue('inventoryId', option === null ? null : option.value);
                      }}
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                    />
                    <div className="text-danger">{touched.inventoryId && errors.inventoryId}</div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('الفئة')}</label>
                    <Select
                      classNamePrefix="react-select"
                      isLoading={loading4}
                      isSearchable={true}
                      isClearable
                      options={CategoriesOptions}
                      onChange={(option: any) => {
                        formik.setFieldValue('categoryId', option === null ? null : option.value);
                      }}
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                    />
                    <div className="text-danger">
                      {touched.categoryId && formik.errors.categoryId}
                    </div>
                  </div>
                </div>

                <div className="col-lg-9">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('Note')}</label>
                    <Form.Control
                      autoComplete="off"
                      className="form-control"
                      as="textarea"
                      rows={1}
                      name="description"
                      onChange={formik.handleChange}
                      value={values.description}
                      isInvalid={!!(touched.description && errors.description)}
                    />
                  </div>
                </div>
              </div>

              {formik.values.categoryId !== null && (
                <CardFooter className="px-1">
                  <PRLines formik={formik} />
                  {touched.transactionsLinesList && errors.transactionsLinesList?.length && (
                    <div className="text-danger"> * مطلوب </div>
                  )}
                </CardFooter>
              )}

              <div className="text-center mt-2">
                <button
                  disabled={formik.isSubmitting}
                  type="submit"
                  className="btn btn-lg me-1 btn-theme mb-1">
                  {formik.isSubmitting ? (
                    <div className="spinner-border spinner-border-sm me-2"></div>
                  ) : (
                    <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
                  )}
                  {t('Add')}
                </button>
                <button
                  type="button"
                  className=" btn btn-lg me-1 btn-default mb-1"
                  onClick={() =>
                    navigate('/PurchasesTransactions/PurchaseRequests', { replace: true })
                  }>
                  <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
                </button>
              </div>
            </Form>
          </CardBody>
        </Loading>
      </Card>
    </div>
  );
};

export default Add;
