import { useAppDispatch } from '../../../../../store/hooks';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import CustomAlert from '../../../../../components/Shared/Alert/CustomAlert';
import { Form } from 'react-bootstrap';
import Loading from '../../../../../components/Shared/Loader/Loading';
import {
  addApExpenseCategoryItems,
  GetApExpenseCategoryItemsByExpenseCategoryId,
} from '../../../../../store/Finance/Expense/ApExpenseCategoryItems/ExpenseCategoryItemsSlice';
import { AddApExpenseCategoryItemsSchema } from '../../../../ValidationForm/validationSchema';
// import { AddApExpenseCategorySchema } from '../../../../ValidationForm/validationSchema';

interface props {
  expenseCategoryId?: string;
}

const AddExpenseCategoryItems = ({ expenseCategoryId }: props) => {
  const { i18n, t } = useTranslation();
  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      expenseCategoryId: '',
      name: '',
      name2: '',
    },
    validationSchema: AddApExpenseCategoryItemsSchema(t),
    onSubmit: (values) => {
      dispatch(
        addApExpenseCategoryItems({
          expenseCategoryId: expenseCategoryId,
          name: values.name,
          name2: values.name2,
        })
      )
        .unwrap()
        .then((res: any) => {
          // debugger
          if (res?.succeeded === true) {
            CustomAlert({ action: 'Add' });
            dispatch(GetApExpenseCategoryItemsByExpenseCategoryId(expenseCategoryId ?? ''));
            formik.resetForm();
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error', msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });

  // console.log(formik.errors);

  return (
    <>
      <Loading loading={false} error={null}>
        <Form onSubmit={formik.handleSubmit}>
          <div className="row align-items-end">
            <div className="col-xl-4">
              <div className="form-group mb-3">
                <label className="form-label">{t('LocalName')}</label>
                <Form.Control
                  className="form-control  "
                  type="text"
                  name="name2"
                  onChange={formik.handleChange}
                  value={formik.values.name2}
                  isInvalid={!!(formik.touched.name2 && formik.errors.name2)}
                />
              </div>
            </div>
            <div className="col-xl-4">
              <div className="form-group mb-3">
                <label className="form-label">{t('GlobalName')}</label>
                <Form.Control
                  className="form-control  "
                  type="text"
                  name="name"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  isInvalid={!!(formik.touched.name && formik.errors.name)}
                />
              </div>
            </div>

            <div className="col-xl-4 text-end  ">
              <div className=" form-group mb-3 d-flex justify-content-end ">
                <button
                  disabled={formik.isSubmitting}
                  type="submit"
                  className="btn me-1 btn-theme w-100">
                  {formik.isSubmitting ? (
                    <div className="spinner-border spinner-border-sm me-2"></div>
                  ) : (
                    <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
                  )}
                  {t('Add')}
                </button>
                <button
                  type="button"
                  className=" btn me-1 btn-default w-100"
                  data-bs-dismiss="modal"
                  id="closeModalForm">
                  <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
                </button>
              </div>
            </div>

          </div>
        </Form>
      </Loading>
    </>
  );
};

export default AddExpenseCategoryItems;
