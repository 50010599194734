import React, { useEffect, useState } from "react";
import { Card } from "../../../../components/card/card";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { GetFleetCategory } from "../../../../store/Lookup/lookupSlice";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Breadcrumb from "../../../../components/Shared/Breadcrumb";
import Loader from "../../../../components/Shared/Loader/Loader";

const Index = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(GetFleetCategory());
  }, []);
  const { FleetCategory , loading } = useAppSelector((state) => state.Lookup);

  const { t, i18n } = useTranslation();
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const Navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState("");

  //_________________________________________________________________________________________________

  //   const filtered = FleetCategory?.slice()
  //     ?.filter((item : any) => {
  //       const searchLowerCase = search.toLowerCase();
  //       return (
  //         item.name?.toLowerCase().includes(searchLowerCase) ||
  //         item.name2?.toLowerCase().toString().includes(searchLowerCase)
  //       );
  //     })
  // ?.filter((item) => (selectedValue === "" ? true : item.moduleId === selectedValue));
  // ?.filter((item) => item.moduleId === selectedValue);

  //   const indexOfLastData = currentPage * dataPerPage;
  //   const indexOfFirstData = indexOfLastData - dataPerPage;
  //   const currentData = filtered?.slice(indexOfFirstData, indexOfLastData);

  //____________________________
  var BcrumbList = [
    {
      name: "Home",
      link: "/",
    },
    {
      name:t("InspectionItems") ,
      link: null,
    },
  ];

  return (
    <>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName={t("InspectionItems")} />

      <Card>
        <Loader loading={loading}/>
        <div className=" p-3">
        
            <div className="row">
              <div className="col-7 col-md-6 d-flex justify-content-start">
                <div className="input-group mb-2">
                  <div className="flex-fill position-relative">
                    <div className="input-group ">
                      <Form.Select
                        className="btn btn-outline-default dropdown-toggle p-0"
                        value={selectedValue}
                        onChange={(e) => setSelectedValue(e.target.value)}
                      >
                        <option key={null}>
                          {i18n.language === "ar" ? "إختر" : "Choose"}
                        </option>
                        {FleetCategory &&
                          FleetCategory?.map((item: any, idx) =>
                            item.list?.map((list: any) => (
                              <option key={++idx} value={list.lookupCode}>
                                {i18n.language === "ar"
                                  ? list.meaning2
                                  : list.meaning}
                              </option>
                            ))
                          )}
                      </Form.Select>

                      <div className="flex-fill position-relative">
                        <div className="input-group">
                          <div
                            className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                            style={{ zIndex: 1020, right: "10px" }}
                          >
                            <i className="fa fa-search opacity-5"></i>
                          </div>
                          <input
                            type="text"
                            className="form-control ps-35px"
                            placeholder={t("Search")}
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-5 col-md-6 text-end">
                <a
                  href="#/"
                  className="btn btn-outline-theme"
                  data-bs-toggle="modal"
                  data-bs-target="#modalAddForm"
                  // hidden={hiddenAddForm}
                >
                  <i className="fa fa-plus-circle fa-fw me-1"></i>
                  {t("AddForm")}
                </a>
              </div> */}
            </div>

            {/* //  START TABLE   */}
            <div className="table-responsive">
              <table className="table">
                <thead className="table-dark">
                  <tr>
                    <th scope="col" style={{ width: "20%" }}>
                      {t("Name")}
                    </th>

                    <th scope="col" style={{ width: "20%" }}></th>
                  </tr>
                </thead>
                <tbody>
                  {FleetCategory &&
                    FleetCategory?.map((item: any, idx) =>
                      item.list?.map((list: any) => (
                        <tr key={list.lookupCode}>
                          <td className="align-middle">
                            {i18n.language === "ar"
                              ? list.meaning2
                              : list.meaning}
                          </td>
                          <td className="align-middle">
 
                              <div className="btn-group">
                                <button
                                  type="button"
                                  onClick={() =>
                                    Navigate(
                                      `${list.lookupCode}/AddScanElement`
                                    )
                                  }
                                  className="btn btn-lg btn-outline-theme"
                                  data-toggle="tooltip"
                                  title={t('InspectionItems')}
                                >
                                  <i className="fab fa-lg fa-fw bi bi-list-ul"></i>
                                  {t('InspectionItems')}
                                </button>

                              </div>

                          </td>
                        </tr>
                      ))
                    )}
                </tbody>
              </table>
            </div>

            {/* <Pagination
                filteredData={filtered}
                // ?.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))}
                dataPerPage={dataPerPage}
                handlePagination={(pageNumber) => setCurrentPage(pageNumber)}
                currentPage={currentPage}
              /> */}
          </div>
       
      </Card>
    </>
  );
};

export default Index;
