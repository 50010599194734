import React, { useEffect, useState } from 'react';
// import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { Card, CardBody } from '../../../../components/card/card';
// import Loader from '../../../../components/Shared/Loader/Loader';
import { useTranslation } from 'react-i18next';
import Pagination from '../../../../components/Shared/Pagination/Pagination';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
// import { GetPriceListList } from '../../../../store/INV/PriceList/PriceListSlice';
// import MedModalComponent from '../../../../components/Modal/MedModalComponent';

import { GetDiscountVoucherList } from '../../../../store/Sales/DiscountVouchers/DiscountVouchersSlice';
import useGetDiscountVoucherTypesList from '../../../../hooks/Sales/Vouchers/useGetDiscountVoucherTypesList';
import useGetVoucherList from '../../../../hooks/Sales/Vouchers/useGetVoucherList';
import ModalComponent from '../../../../components/Modal/ModalComponent';
import Add from './Add';

const Index = () => {
  const [Show, setShow] = useState(false);
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const Navigate = useNavigate();
  const [id, setId] = useState('');

  const { DiscountVouchersList, loading, error } = useGetVoucherList();

  const filteredSearch = DiscountVouchersList?.slice()?.filter((item) => {
    const searchLowerCase = search.toLowerCase();
    return (
      item.name?.toLowerCase().includes(searchLowerCase) ||
      item.name2?.toLowerCase().toString().includes(searchLowerCase)
    );
  });

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);
   return (
    <div>
      <Card>
        <CardBody className="p-3">
          <div className="row mb-2">
            <div className="col-lg-10">
              <div className="input-group flex-nowrap">
                <span className="input-group-text fw-bold">
                  {t('Search')}
                  {/* <i className="fa fa-search  text-theme ms-3"></i> */}
                </span>
                <div className="input-group">
                  <div className="flex-fill position-relative">
                    <div className="input-group border-0 ">
                      <div
                        className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                        style={{ zIndex: 1020, right: '10px' }}>
                        <i className="fa fa-search opacity-5 text-theme"></i>
                      </div>
                      <input
                        type="text"
                        className="form-control ps-35px"
                        // placeholder={t('Search')}
                        value={search}
                        onChange={(e) => {
                          setSearch(e.target.value);
                          setCurrentPage(1);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2 text-end">
              <Link
                to={`BuyXgetY`}
                // type="button"
                className="btn btn-theme mb-2"
                // data-bs-toggle="modal"
                // data-bs-target="#AddBuyXgetY"
              >
                <i className="far fa-lg fa-fw fa-plus me-2"></i>
                {t('Add')}
              </Link>
            </div>
          </div>

          <div className="table-responsive">
            <table className="table text-nowrap">
              <thead className="table-dark">
                <tr>
                  <th scope="col">{t('Name')}</th>
                  <th scope="col">{t('من تاريخ')}</th>
                  <th scope="col">{t('إلى تاريخ')}</th>
                  <th scope="col"></th>
                </tr>
              </thead>

              <tbody>
                {currentData?.map((item, index) => (
                  <tr key={item.id}>
                    <td className="align-middle">
                      {i18n.language === 'ar' ? item.name2 : item.name}
                    </td>
                    <td className="align-middle">{item.validationDateFrom}</td>
                    <td className="align-middle">{item.validationDateTo}</td>
                    <td className="align-middle text-end">
                      <button
                        type="button"
                        onClick={() => {
                          // setDataDynamicForm(item);
                          // setShow2(true);
                        }}
                        className="btn btn-outline-theme ms-1"
                        data-toggle="tooltip">
                        {/* <i className="far fa-lg fa-fw bi bi-bell me-1"></i> */}
                        {t('Button.Edit')}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <Pagination
            filteredData={filteredSearch}
            // ?.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))}
            dataPerPage={dataPerPage}
            handlePagination={(pageNumber: any) => setCurrentPage(pageNumber)}
            currentPage={currentPage}
          />
        </CardBody>
      </Card>

      <ModalComponent title={''} id="AddBuyXgetY">
        {true && <Add />}
      </ModalComponent>

      {/* <MedModalComponent title={'تعديل'} id="EditPriceList">
        {id && <Update Id={id} />}
      </MedModalComponent>  */}
    </div>
  );
};

export default Index;
