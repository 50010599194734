/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { ntxAPI } from "../../../../../API/axiosNeatex";
import SkeletonLoader from "../../../../../components/Shared/SkeletonLoader/SkeletonLine";

const LookupTable = ({ formik }) => {
  const { t, i18n } = useTranslation();
  const [lookupList, setLookupList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  
  useEffect(() => {
    
    async function GetTableData() {
      setIsLoading(true)
      try {
        const { data } = await ntxAPI.get(
          `Lookup/GetByTypes?LookupTypes=${formik.values.lookupTypeForGetDataByType}`
        );
        let DataList = data?.map((item, idx) =>
          item.list
            ?.map((list) => ({
              value: list.lookupCode,
              label: i18n.language === "ar" ? list.meaning2 : list.meaning,
            }))
            .flat()
        );

        setLookupList(DataList[0]);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    }

    GetTableData();
  }, [formik.values.lookupTypeForGetDataByType]);

  // Conditionally render the entire component based on loading state or absence of lookupList
  if (isLoading || lookupList?.length === 0) {
    return <SkeletonLoader />;
  } else {
    return (
      <>
        <div className="form-group mt-2">
          <label className="form-label">{t('Type')}</label>
          <div className="row">
            {isLoading ? (
              <SkeletonLoader />
            ) : (
              lookupList?.length > 0 && (
                <Select
                  classNamePrefix="react-select"
                  isSearchable={true}
                  options={lookupList}
                  onChange={(option) =>
                    formik.setFieldValue("lookupCode", option.value)
                  }
                  placeholder={i18n.language === "ar" ? "إختر" : "Choose"}
                />
              )
            )}
            {formik.errors.lookupCode && formik.touched.lookupCode && (
              <div style={{ color: "red" }}>{formik.errors.lookupCode}</div>
            )}
          </div>
        </div>
      </>
    );
  }
};

export default LookupTable;
