import { useFormik } from 'formik';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import Select from 'react-select';
import { Card } from '../../../../components/card/card';
import { CardBody, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useLookupCurrency from '../../../../hooks/Lookups/use-Lookup-Currency';
// import PurchaseLines from '../TransactionsLines/PurchaseLines';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { useAppDispatch } from '../../../../store/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../../../components/Shared/Loader/Loading';
import { useState } from 'react';
import useGetSuppliersList from '../../../../hooks/NTX/Peoples/useGetSuppliersList';
import { TreeSelect } from 'antd';

import useGetPaymentMethod from '../../../../hooks/Payrolls/use-Get-PaymentMethod';

import useGetOrganizationWithPermissionByType from '../../../../hooks/NTX/Organization/useGetOrganizationWithPermissionByType';
import confirmAction from '../../../../components/Shared/Alert/confirmAction';
import {
  addApPayments,
  getApPaymentsList,
} from '../../../../store/Finance/AP/ApPayments/ApPaymentsSlice';
import useGetBankAccountsList from '../../../../hooks/Finance/BankAccounts/useGetBankAccountsList';
import useGetPaymentTypeList from '../../../../hooks/Finance/ApPayments/useGetPaymentTypeList';
import useGetCostCenters from '../../../../hooks/Finance/ChartAccountsDetails/useGetCostCenters';
import useGetAccounts from '../../../../hooks/Finance/ChartAccountsDetails/useGetAccounts';
import { AddApPaymentsSchema } from '../../../ValidationForm/validationSchema';
import { addArReceipts } from '../../../../store/Finance/AR/ArReceipts/ArReceiptsSlice';

const Add = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { Currency, loading: loading4 } = useLookupCurrency();
  const { DataOptions: BranchsOptions, loading: loading3 } =
    useGetOrganizationWithPermissionByType('150');
  const { treeSuppliersData, error: error2, loading: loading2 } = useGetSuppliersList();
  const { PaymentMethodList } = useGetPaymentMethod();
  // const { referenceId } = useParams();
  const { TreeCostCenters } = useGetCostCenters();
  const { OptionsAccounts, loading, error } = useGetAccounts();

  const { groupedOptions, loading: loading5, error: error5 } = useGetBankAccountsList();

  const { PaymentTypeList, loading2: loading6, error: error6 } = useGetPaymentTypeList();

  //#region Formik
  const formik = useFormik({
    initialValues: {
      customerBankAccount: '',
      withCommit: '',
      depositDate: '',
      bankAccountId: '',
      depositMethodId: '',
      amount: 0,
      currencyCode: '',
      currencyConversionRate: 1,
      peopleId: '',
      organizationId: '',
      accountId: '',
      costCenterId: '',
      paymentType: '',
    },

    enableReinitialize: true,
    // validationSchema: AddApPaymentsSchema(t),

    onSubmit: async (values) => {
      let isConfirmation = await confirmAction('هل أنت متأكد من أنك تريد إضافة هذا السداد؟');
      if (!isConfirmation) {
        formik.setSubmitting(false);
        return;
      }

      dispatch(
        addArReceipts({
          bankAccountId: values.bankAccountId,
          customerBankAccount: values.customerBankAccount,
          depositMethodId:  values.depositMethodId,
          depositDate: values.depositDate,
          amount: values.amount,
          currencyCode: values.currencyCode ,
          currencyConversionRate: values.currencyConversionRate,
          peopleId:  values.peopleId,
          organizationId: values.organizationId,
          accountId: values.accountId,
          costCenterId: values.costCenterId,
          paymentType: values.paymentType,
          // withCommit: true,
        })
      )
        .unwrap()
        .then((res: any) => {
          // debugger;
          // console.log(res);

          if (res?.succeeded === true) {
            CustomAlert({ action: 'Add' });
            dispatch(getApPaymentsList());
            formik.resetForm();
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error', msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });
  const { values, handleChange: formikhandleChange, errors, touched, setFieldValue } = formik;
  //#endregion

  //#region BcrumbList
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'Ar Receipts',
      link: '/Finance/Ar/ArReceipts',
    },
    {
      name: 'Add Ar Receipts',
      link: null,
    },
  ];
  //#endregion

  return (
    <>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName="Add Ar Receipts" />
      </div>

      <Card>
        <Loading loading={loading5 || loading2 || loading3 || loading4}>
          <CardBody>
            <Form onSubmit={formik.handleSubmit}>
              <div className="row">
                {/* التاريخ */}
                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('التاريخ')}</label>
                    <Form.Control
                      className="form-control"
                      type="date"
                      name="depositDate"
                      onChange={formikhandleChange}
                      value={values.depositDate || ''}
                      // isInvalid={!!(touched.depositDate && errors.depositDate)}
                    />
                    <div className="text-danger small">{formik.errors.depositDate}</div>
                  </div>
                </div>

                {/* المبلغ */}
                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('amount')}</label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      name="amount"
                      onChange={formikhandleChange}
                      value={values.amount || ''}
                      onInput={(e) => {
                        const input = e.target as HTMLInputElement;
                        input.value = input.value.replace(/[^0-9]/g, '');
                      }}
                    />
                    <div className="text-danger small">{errors.amount}</div>
                  </div>
                </div>

                {/* نوع الدفع */}
                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('paymentType')}</label>
                    <Form.Select
                      className="form-select"
                      name="paymentType"
                      onChange={formikhandleChange}
                      value={values.paymentType || ''}>
                      <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                      {PaymentTypeList?.map((item, idx) => (
                        <option key={idx} value={item.id}>
                          {i18n.language === 'en' ? item.name : item.name2}
                        </option>
                      ))}
                    </Form.Select>
                    <div className="text-danger small">{formik.errors.paymentType}</div>
                  </div>
                </div>

                {/* طريقة الدفع */}
                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('depositMethodId')}</label>
                    <Form.Select
                      className="form-select"
                      name="depositMethodId"
                      onChange={formik.handleChange}
                      value={formik.values.depositMethodId || ''}
                      // isInvalid={
                      //   !!(formik.touched.depositMethodId && formik.errors.depositMethodId)
                      // }
                    >
                      <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                      {PaymentMethodList?.map((item: any, idx: number) => (
                        <option key={++idx} value={item.id}>
                          {i18n.language === 'ar' ? item.name : item.name2}
                        </option>
                      ))}
                    </Form.Select>
                    <div className="text-danger small">{formik.errors.depositMethodId}</div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 col-12">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('bankAccountId')}</label>

                    <Select
                      classNamePrefix="react-select"
                      isLoading={loading5}
                      isSearchable={true}
                      isClearable
                      options={groupedOptions}
                      value={BranchsOptions?.find(
                        (f: any) => f.value === formik.values.bankAccountId
                      )}
                      onChange={(option: any) =>
                        setFieldValue('bankAccountId', option === null ? null : option.value)
                      }
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                    />
                    <div className="text-danger small">{formik.errors.bankAccountId}</div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 col-12">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('العميــل')}</label>
                    <TreeSelect
                      showSearch
                      className="w-100"
                      treeLine
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                      allowClear
                      treeDefaultExpandAll={false} // لا تقم بتوسيع كل العقد افتراضيًا
                      // loadData={} // يتم تحميل البيانات عند الطلب
                      // onSearch={} // تنفيذ البحث عند كتابة المستخدم
                      value={values.peopleId}
                      onChange={(option) => {
                        formik.setFieldValue('peopleId', option === null ? null : option);
                      }}
                      treeData={treeSuppliersData}
                      treeNodeFilterProp="title"
                    />
                    <div className="text-danger small">{formik.errors.peopleId}</div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('رقم الحســاب البنكي للعميـل')}</label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      name="customerBankAccount"
                      onChange={formikhandleChange}
                      value={values.customerBankAccount || ''}
                      onInput={(e) => {
                        const input = e.target as HTMLInputElement;
                        input.value = input.value.replace(/[^0-9]/g, '');
                      }}
                    />
                    <div className="text-danger small">{errors.customerBankAccount}</div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('الفرع')}</label>

                    <Select
                      classNamePrefix="react-select"
                      isLoading={loading3}
                      isSearchable={true}
                      isClearable
                      options={BranchsOptions}
                      value={BranchsOptions?.find(
                        (f: any) => f.value === formik.values.organizationId
                      )}
                      onChange={(option: any) =>
                        setFieldValue('organizationId', option === null ? null : option.value)
                      }
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                    />
                    <div className="text-danger small">{formik.errors.organizationId}</div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 col-12">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('Accounts')}</label>
                    <Select
                      classNamePrefix="react-select"
                      className=" w-100"
                      isLoading={loading}
                      isSearchable={true}
                      isClearable
                      name="accountId"
                      options={OptionsAccounts}
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                      onChange={(option) =>
                        setFieldValue('accountId', option === null ? null : option.value)
                      }
                      // value={OptionsAccounts?.find((f) => f.value === selectedAccounts.account.id)}
                    />
                    <div className="text-danger small">{formik.errors.accountId}</div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('CostCenter')}</label>
                    <TreeSelect
                      showSearch
                      treeData={TreeCostCenters}
                      style={{ borderRadius: '1px !important' }}
                      className="custom-tree-select w-100  "
                      treeLine
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                      allowClear
                      treeDefaultExpandAll
                      getPopupContainer={(triggerNode) => triggerNode.parentNode}
                      onChange={(option) =>
                        setFieldValue('costCenterId', option === null ? null : option)
                      }
                      value={formik.values.costCenterId}
                    />
                    <div className="text-danger small">{formik.errors.costCenterId}</div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 col-12">
                  <div className="form-group mb-3">
                    <label className="form-label"> {t('Currency')} </label>
                    <Form.Select
                      className="form-select"
                      name="currencyCode"
                      onChange={formikhandleChange}
                      value={values.currencyCode || ''}
                      // isInvalid={!!(touched.currencyCode && errors.currencyCode)}
                    >
                      <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>

                      {Currency &&
                        Currency?.map((item: any, idx: number) => (
                          <option key={++idx} value={item.currencyCode}>
                            {item.description}
                          </option>
                        ))}
                    </Form.Select>
                    <div className="text-danger small">{formik.errors.currencyCode}</div>
                  </div>
                </div>

                <div className="col-lg-2 col-md-6 col-12">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('معدل العملة')}</label>
                    <Form.Control
                      className="form-control  text-center"
                      type="text"
                      name="currencyConversionRate"
                      onChange={formikhandleChange}
                      value={values.currencyConversionRate || ''}
                      // isInvalid={!!(touched.currencyConversionRate && errors.currencyConversionRate)}
                      onInput={(e) => {
                        const input = e.target as HTMLInputElement;
                        input.value = input.value.replace(/[^0-9]/g, '');
                      }}
                    />
                    <div className="text-danger small">{formik.errors.currencyConversionRate}</div>
                  </div>
                </div>
              </div>

              <div className="col-12 text-center mt-2">
                <button
                  disabled={formik.isSubmitting}
                  type="submit"
                  className="btn btn-lg me-1 btn-theme mb-1">
                  {formik.isSubmitting ? (
                    <div className="spinner-border spinner-border-sm me-2"></div>
                  ) : (
                    <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
                  )}
                  {t('Add')}
                </button>
                <button
                  type="button"
                  className="btn btn-lg me-1 btn-default mb-1"
                  onClick={() => navigate('/Finance/Ap/ApPayments', { replace: true })}>
                  <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
                </button>
              </div>
            </Form>
          </CardBody>
        </Loading>
      </Card>
    </>
  );
};

export default Add;
//  <div className="col-lg-3 col-md-4 col-12">
//     <div className="form-group mb-3">
//       <label className="form-label">{t('التاريخ')}</label>
//       <Form.Control
//         className="form-control "
//         type="date"
//         name="paymentDate"
//         onChange={formikhandleChange}
//         value={values.paymentDate || ''}
//         isInvalid={!!(touched.paymentDate && errors.paymentDate)}
//       />

//       <div className="text-danger">{formik.errors.paymentDate}</div>
//     </div>
//   </div>

//   <div className="col-lg-3">
//     <div className="form-group mb-3">
//       <label className="form-label">{t('amount')}</label>
//       <Form.Control
//         className="form-control"
//         type="text"
//         name="amount"
//         onChange={formikhandleChange}
//         value={values.amount || ''}
//         // isInvalid={!!(touched.amount && errors.amount)}
//         onInput={(e) => {
//           const input = e.target as HTMLInputElement;
//           input.value = input.value.replace(/[^0-9]/g, '');
//         }}
//       />
//       <div className="text-danger">{errors.amount}</div>
//     </div>
//   </div>

//   <div className="col-lg-3">
//     <div className="form-group mb-3">
//       <label className="form-label"> {t('paymentType')} </label>
//       <Form.Select
//         className="form-select"
//         name="paymentType"
//         onChange={formikhandleChange}
//         value={values.paymentType || ''}
//         // isInvalid={!!(touched.paymentType && errors.paymentType)}
//       >
//         <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>

//         {PaymentTypeList &&
//           PaymentTypeList?.map((item, idx: number) => (
//             <option key={++idx} value={item.id}>
//               {i18n.language === 'en' ? item.name : item.name2}
//             </option>
//           ))}
//       </Form.Select>
//       <div className="text-danger">{formik.errors.paymentType}</div>
//     </div>
//   </div>
//   <div className="col-xl-3">
//     <div className="form-group mb-3">
//       <label className="form-label">{t('PaymentMethod')}</label>
//       <Form.Select
//         className="form-select"
//         name="paymentMethodId"
//         onChange={formik.handleChange}
//         value={formik.values.paymentMethodId || ''}
//         isInvalid={
//           !!(formik.touched.paymentMethodId && formik.errors.paymentMethodId)
//         }>
//         <option key={null}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>

//         {PaymentMethodList?.map((item: any, idx: number) => (
//           <option key={++idx} value={item.id}>
//             {i18n.language === 'ar' ? item.name : item.name2}
//           </option>
//         ))}
//       </Form.Select>
//     </div>
//   </div>
