import React, { useState } from 'react';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { Card, CardBody } from '../../../../components/card/card';
import { useTranslation } from 'react-i18next';
import Pagination from '../../../../components/Shared/Pagination/Pagination';
import { Link, useNavigate } from 'react-router-dom';
import NeatixModal from '../../../../components/Modal/NeatixModal';
import QuoteInvoiceList from '../Quotation/QuoteInvoiceList';
// import DynamicPrint from '../../../../components/DynamicPrint/DynamicPrint';
import useGetInvoiceList from '../../../../hooks/Sales/useGetInvoiceList';
// import { SaleTransactionsModels } from '../../../../store/Sales/SalesInvoices/SaleTransactionsModels';

const Index = () => {
  // const [quoteInvoice, setQuoteInvoice] = useState<SaleTransactionsModels>();

  const [show, setShow] = useState(false);
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const { t, i18n } = useTranslation();
  const Navigate = useNavigate();

  const { SalesInvoicesList, loading } = useGetInvoiceList();

  const filteredSearch = SalesInvoicesList?.slice()
    ?.filter((item) => {
      const searchLowerCase = search.toLowerCase();
      return (
        item.name?.toLowerCase().includes(searchLowerCase) ||
        item.name2?.toLowerCase().toString().includes(searchLowerCase)
      );
    })
    ?.sort((a, b) => b.no - a.no);

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'فواتيـر مبيعات',
      link: null,
    },
  ];

  return (
    <div>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName="فواتير مبيعات" />
        <div className="ms-auto">
          <div className="btn-group" role="group" style={{ direction: 'ltr' }}>
            <button
              type="button"
              className="btn btn-theme dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false">
              {/* <i className="fa bi bi-printer-fill fa-fw ms-3 fs-5"></i> */}
              {t('إضافة فاتورة')}
            </button>
            <ul className="dropdown-menu text-center">
              <li>
                <Link
                  className="dropdown-item"
                  to="/SalesTransactions/SalesInvoices/AddSalesInvoices"
                  // state={quoteInvoice}
                >
                  فاتورة جديدة
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="" onClick={() => setShow(true)}>
                  {/* <i className="bi bi-2-circle ms-2 fs-5"></i> */}
                  إنشاء فاتورة من عرض سـعر
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <Card>
        <CardBody>
          <div className="tab-content p-2">
            <div className="row">
              <div className="col-7 col-md-6 d-flex justify-content-start">
                <div className="input-group mb-4">
                  <div className="flex-fill position-relative">
                    <div className="input-group">
                      <div
                        className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                        style={{ zIndex: 1020, right: '10px' }}>
                        <i className="fa fa-search opacity-5"></i>
                      </div>
                      <input
                        type="text"
                        className="form-control ps-35px"
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder={t('Search') + ' ...'}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* //  START TABLE   */}
            <div className="table-responsive">
              <table className="table table-hover text-nowrap">
                <thead className="table-dark">
                  <tr>
                    <th scope="col">{t('رقم الفاتورة ')}</th>
                    <th scope="col">{t('تاريخ الفاتورة ')}</th>
                    <th scope="col">{t('العميـل ')}</th>
                    <th scope="col">{t('الفرع ')}</th>
                    <th scope="col">{t('قيمة الفاتورة ')}</th>
                    <th scope="col">{t('حالة الصرف في المخزن')}</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {currentData?.map((item, index) => (
                    <tr key={item.id}>
                      <td className="align-middle"> {item.code} </td>
                      <td className="align-middle"> {item.transactionDate} </td>
                      <td className="align-middle"> {item.peopleName2} </td>
                      <td className="align-middle"> {item.organizationName2} </td>
                      <td className="align-middle"> {item.grossSale}</td>

                      <td className="align-middle">
                        {item.inventoryFlag ? (
                          <span className=" badge border border-success  text-success  px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center ">
                            <i className="fa fa-check-circle fs-15px fa-fw me-5px"></i>
                            {t('تم الصرف')}
                          </span>
                        ) : (
                          <span className=" badge border border-danger  text-danger  px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center ">
                            <i className="fa fa-times-circle fs-15px fa-fw me-5px"></i>
                            {t('لم يتم الصرف')}
                          </span>
                        )}
                      </td>

                      <td className="text-end">
                        <button
                          type="button"
                          className="btn btn-outline-theme mx-1"
                          onClick={() => Navigate(`${item.id}/UpdateSalesInvoices`)}>
                          <i className="far fa-lg fa-fw me-2 fa-eye"></i> {t('عرض التفاصيل')}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Pagination
              filteredData={filteredSearch}
              dataPerPage={dataPerPage}
              handlePagination={(pageNumber: any) => setCurrentPage(pageNumber)}
              currentPage={currentPage}
            />
          </div>
        </CardBody>
      </Card>

      <NeatixModal show={show} setShow={setShow} bodyClassName="p-0" size="lg" showHeader={false}>
        <QuoteInvoiceList RefrenceType="SalesInvoices" />
      </NeatixModal>
    </div>
  );
};

export default Index;
