import { useFormik } from 'formik';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import Select from 'react-select';
import { Card } from '../../../../components/card/card';
import { CardBody, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useLookupCurrency from '../../../../hooks/Lookups/use-Lookup-Currency';
import { useAppDispatch } from '../../../../store/hooks';
import { useNavigate } from 'react-router-dom';
import Loading from '../../../../components/Shared/Loader/Loading';
import useGetPaymentMethod from '../../../../hooks/Payrolls/use-Get-PaymentMethod';
import confirmAction from '../../../../components/Shared/Alert/confirmAction';
import useGetBankAccountsList from '../../../../hooks/Finance/BankAccounts/useGetBankAccountsList';
import useGetPaymentTypeList from '../../../../hooks/Finance/ApPayments/useGetPaymentTypeList';
import { AddPaymentSchedulesSchema } from '../../../ValidationForm/validationSchema';
import useConversionRateByOrganization from '../../../../hooks/Finance/DailyRates/useConversionRateByOrganization';
import { addApPaymentSchedules } from '../../../../store/Finance/AP/ApInvoices/ApInvoicesSlice';
import React from 'react';
// import { useForm, Controller } from 'react-hook-form';

interface Props {
  schedulesDetails: any;
}

const AddPaymentSchedules = ({ schedulesDetails }: Props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { Currency, loading: loading3 } = useLookupCurrency();
  const { PaymentMethodList, loading: loading4 } = useGetPaymentMethod();
  const { groupedOptions, loading: loading1, error: error1 } = useGetBankAccountsList();
  const { PaymentTypeList, loading2, error: error2 } = useGetPaymentTypeList();

  //#region Formik
  const formik = useFormik({
    initialValues: {
      apPaymentScheduleId: schedulesDetails?.id,
      apBankAccountId: '',
      paymentMethodId: '',
      paymentDate: '',
      paymentType: '',
      amount: Number(schedulesDetails?.amount),
      currencyCode: '',
      currencyConversionRate: 1,
    },

    enableReinitialize: true,
    validationSchema: AddPaymentSchedulesSchema(t),

    onSubmit: async (values) => {
      let isConfirmation = await confirmAction('هل أنت متأكد من أنك تريد إضافة هذا السداد؟');
      if (!isConfirmation) {
        formik.setSubmitting(false);
        return;
      }

      try {
        const res = await dispatch(
          addApPaymentSchedules({
            apPaymentScheduleId: values.apPaymentScheduleId,
            apBankAccountId: values.apBankAccountId,
            paymentMethodId: values.paymentMethodId,
            paymentDate: values.paymentDate,
            paymentType: values.paymentType,
            amount: values.amount,
            currencyCode: values.currencyCode,
            currencyConversionRate: conversionRate,
          })
        ).unwrap();

        if (res?.succeeded === true) {
          CustomAlert({ action: 'Add' });
          formik.resetForm();
        } else {
          CustomAlert({ action: 'Error', msg: res?.message });
        }
      } catch (error) {
        CustomAlert({ action: 'Error' });
      } finally {
        formik.setSubmitting(false);
      }
    },
  });
  const { values, handleChange: formikhandleChange, errors, touched, setFieldValue } = formik;
  //#endregion

  const conversionRate = useConversionRateByOrganization({
    organizationId: schedulesDetails.organizationId,
    currencyCode: values.currencyCode,
    date: values.paymentDate,
  });

  return (
    <>
      <Card>
        <Loading loading={loading1 || loading2 || loading3 || loading4}>
          <CardBody>
            <Form onSubmit={formik.handleSubmit}>
              <div className="row">
                {/* apBankAccountId */}
                <div className="col-lg-6">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('apBankAccountId')}</label>
                    <Select
                      classNamePrefix="react-select"
                      isLoading={loading1}
                      isSearchable={true}
                      isClearable
                      options={groupedOptions}
                      value={groupedOptions?.find((f: any) => f.value === values.apBankAccountId)}
                      onChange={(option: any) =>
                        setFieldValue('apBankAccountId', option === null ? null : option.value)
                      }
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                    />
                    <div className="text-danger small">
                      {touched.apBankAccountId && errors.apBankAccountId}
                    </div>
                  </div>
                </div>

                {/* طريقة الدفع */}
                <div className="col-lg-6">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('PaymentMethod')}</label>
                    <Form.Select
                      className="form-select"
                      name="paymentMethodId"
                      onChange={formik.handleChange}
                      value={values.paymentMethodId || ''}>
                      <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                      {PaymentMethodList?.map((item: any, idx: number) => (
                        <option key={++idx} value={item.id}>
                          {i18n.language === 'ar' ? item.name : item.name2}
                        </option>
                      ))}
                    </Form.Select>
                    <div className="text-danger small">
                      {touched.paymentMethodId && errors.paymentMethodId}
                    </div>
                  </div>
                </div>

                {/* التاريخ */}
                <div className="col-lg-6">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('التاريخ')}</label>
                    <Form.Control
                      className="form-control"
                      type="date"
                      name="paymentDate"
                      onChange={formikhandleChange}
                      value={values.paymentDate || ''}
                    />
                    <div className="text-danger small">
                      {touched.paymentDate && errors.paymentDate}
                    </div>
                  </div>
                </div>

                {/* نوع الدفع */}
                <div className="col-lg-6">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('نوع الدفغ')}</label>
                    <Form.Select
                      className="form-select"
                      name="paymentType"
                      onChange={formikhandleChange}
                      value={values.paymentType || ''}>
                      <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                      {PaymentTypeList?.map((item, idx) => (
                        <option key={idx} value={item.id}>
                          {i18n.language === 'en' ? item.name : item.name2}
                        </option>
                      ))}
                    </Form.Select>
                    <div className="text-danger small">
                      {touched.paymentType && errors.paymentType}
                    </div>
                  </div>
                </div>

                {/* المبلغ */}
                <div className="col-lg-4">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('المبلغ')}</label>
                    <Form.Control
                      className="form-control text-theme fw-bold fs-5"
                      type="text"
                      name="amount"
                      onChange={formikhandleChange}
                      value={values.amount || ''}
                      onInput={(e) => {
                        const input = e.target as HTMLInputElement;
                        input.value = input.value.replace(/[^0-9]/g, '');
                      }}
                    />
                    <div className="text-danger small">{touched.amount && errors.amount}</div>
                  </div>
                </div>

                {/* ('Currency') */}
                <div className="col-lg-4">
                  <div className="form-group mb-3">
                    <label className="form-label"> {t('Currency')} </label>
                    <Form.Select
                      className="form-select"
                      name="currencyCode"
                      onChange={formikhandleChange}
                      value={values.currencyCode || ''}>
                      <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>

                      {Currency &&
                        Currency?.map((item: any, idx: number) => (
                          <option key={++idx} value={item.currencyCode}>
                            {item.description}
                          </option>
                        ))}
                    </Form.Select>
                    <div className="text-danger small">
                      {touched.currencyCode && errors.currencyCode}
                    </div>
                  </div>
                </div>
                {/* ('معدل العملة') */}
                <div className="col-lg-4">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('معدل العملة')}</label>
                    <Form.Control
                      // readOnly
                      className="form-control  text-center"
                      type="text"
                      name="currencyConversionRate"
                      value={conversionRate}
                    />
                    <div className="text-danger small">
                      {touched.currencyConversionRate && errors.currencyConversionRate}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <button
                    disabled={formik.isSubmitting}
                    type="submit"
                    className="btn btn-lg btn-theme  w-100 rounded-0">
                    {formik.isSubmitting ? (
                      <div className="spinner-border spinner-border-sm me-2"></div>
                    ) : (
                      <i className="fas fa-lg fa-fw me-2 fa-check-circle"></i>
                    )}
                    {t('سداد')}
                  </button>
                </div>
                <div className="col-6">
                  <button
                    type="button"
                    className="btn btn-lg  btn-default   w-100 rounded-0"
                    onClick={() => navigate('/Finance/Ap/ApPayments', { replace: true })}>
                    <i className="fas fa-times-circle fa-fw me-1"></i> {t('Cancel')}
                  </button>
                </div>
              </div>
            </Form>
          </CardBody>
        </Loading>
      </Card>
    </>
  );
};

export default React.memo(AddPaymentSchedules);

// interface Props {
//   schedulesDetails: any;
// }

// const AddPaymentSchedules = ({ schedulesDetails }: Props) => {
//   const { t, i18n } = useTranslation();
//   const dispatch = useAppDispatch();
//   const navigate = useNavigate();
//   const { Currency, loading: loading3 } = useLookupCurrency();
//   const { PaymentMethodList, loading: loading4 } = useGetPaymentMethod();
//   const { groupedOptions, loading: loading1, error: error1 } = useGetBankAccountsList();
//   const { PaymentTypeList, loading2, error: error2 } = useGetPaymentTypeList();

//   const {
//     handleSubmit,
//     control,
//     formState: { errors ,  },
//     reset,

//   } = useForm({
//     defaultValues: {
//       apPaymentScheduleId: schedulesDetails?.id,
//       apBankAccountId: '',
//       paymentMethodId: '',
//       paymentDate: '',
//       paymentType: '',
//       amount: Number(schedulesDetails?.amount),
//       currencyCode: '',
//       currencyConversionRate: 1,
//     },
//   });
//   const onSubmit = async (data: any) => {
//     const isConfirmation = await confirmAction('هل أنت متأكد من أنك تريد إضافة هذا السداد؟');
//     if (!isConfirmation) return;

//     debugger;
//     dispatch(
//       addApPaymentSchedules({
//         apPaymentScheduleId: data.apPaymentScheduleId,
//         apBankAccountId: data.apBankAccountId,
//         paymentMethodId: data.paymentMethodId,
//         paymentDate: data.paymentDate,
//         paymentType: data.paymentType,
//         amount: data.amount,
//         currencyCode: data.currencyCode,
//       })
//     )
//       .unwrap()
//       .then((res) => {
//         if (res?.succeeded) {
//           CustomAlert({ action: 'Add' });
//           reset(); // Reset form after successful submission
//         } else {
//           CustomAlert({ action: 'Error', msg: res?.message });
//         }
//       })
//       .catch(() => CustomAlert({ action: 'Error' }));
//   };

//   // const conversionRate = useConversionRate({
//   //   organizationId: schedulesDetails.organizationId,
//   //   currencyCode: values.currencyCode,
//   //   date: values.paymentDate,
//   // });

//   return (
//     <>
//       <Card>
//         <Loading loading={loading1 || loading2 || loading3 || loading4}>
//           <CardBody>
//             <Form onSubmit={handleSubmit(onSubmit)}>
//               <div className="row">
//                 {/* Bank Account Select */}
//                 <div className="col-lg-6">
//                   <div className="form-group mb-3">
//                     <label className="form-label">{t('apBankAccountId')}</label>
//                     <Controller
//                       name="apBankAccountId"
//                       control={control}
//                       render={({ field }) => (
//                         <Select
//                           {...field}
//                           classNamePrefix="react-select"
//                           // isLoading={loadingBankAccounts}
//                           isClearable
//                           options={groupedOptions}
//                           placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
//                         />
//                       )}
//                     />
//                     <div className="text-danger small">
//                       {errors.apBankAccountId && t('Required field')}
//                     </div>
//                   </div>
//                 </div>

//                 {/* Payment Method Select */}
//                 <div className="col-lg-6">
//                   <div className="form-group mb-3">
//                     <label className="form-label">{t('PaymentMethod')}</label>
//                     <Controller
//                       name="paymentMethodId"
//                       control={control}
//                       render={({ field }) => (
//                         <Form.Select {...field} className="form-select">
//                           <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
//                           {PaymentMethodList?.map((item: any) => (
//                             <option key={item.id} value={item.id}>
//                               {i18n.language === 'ar' ? item.name : item.name2}
//                             </option>
//                           ))}
//                         </Form.Select>
//                       )}
//                     />
//                     <div className="text-danger small">
//                       {errors.paymentMethodId && t('Required field')}
//                     </div>
//                   </div>
//                 </div>

//                 {/* Payment Date */}
//                 <div className="col-lg-6">
//                   <div className="form-group mb-3">
//                     <label className="form-label">{t('التاريخ')}</label>
//                     <Controller
//                       name="paymentDate"
//                       control={control}
//                       render={({ field }) => (
//                         <Form.Control {...field} type="date" className="form-control" />
//                       )}
//                     />
//                     <div className="text-danger small">
//                       {errors.paymentDate && t('Required field')}
//                     </div>
//                   </div>
//                 </div>

//                 {/* Payment Type */}
//                 <div className="col-lg-6">
//                   <div className="form-group mb-3">
//                     <label className="form-label">{t('نوع الدفع')}</label>
//                     <Controller
//                       name="paymentType"
//                       control={control}
//                       render={({ field }) => (
//                         <Form.Select {...field} className="form-select">
//                           <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
//                           {PaymentTypeList?.map((item) => (
//                             <option key={item.id} value={item.id}>
//                               {i18n.language === 'en' ? item.name : item.name2}
//                             </option>
//                           ))}
//                         </Form.Select>
//                       )}
//                     />
//                     <div className="text-danger small">
//                       {errors.paymentType && t('Required field')}
//                     </div>
//                   </div>
//                 </div>

//                 {/* Amount Input */}
//                 <div className="col-lg-4">
//                   <div className="form-group mb-3">
//                     <label className="form-label">{t('المبلغ')}</label>
//                     <Controller
//                       name="amount"
//                       control={control}
//                       render={({ field }) => (
//                         <Form.Control
//                           {...field}
//                           type="text"
//                           className="form-control text-theme fw-bold fs-5"
//                           onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
//                             const input = e.target;
//                             input.value = input.value.replace(/[^0-9]/g, '');
//                           }}
//                         />
//                       )}
//                     />
//                     <div className="text-danger small">{errors.amount && t('Required field')}</div>
//                   </div>
//                 </div>

//                 {/* Currency Select */}
//                 <div className="col-lg-4">
//                   <div className="form-group mb-3">
//                     <label className="form-label">{t('Currency')}</label>
//                     <Controller
//                       name="currencyCode"
//                       control={control}
//                       render={({ field }) => (
//                         <Form.Select {...field} className="form-select">
//                           <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
//                           {Currency?.map((item: any) => (
//                             <option key={item.currencyCode} value={item.currencyCode}>
//                               {item.description}
//                             </option>
//                           ))}
//                         </Form.Select>
//                       )}
//                     />
//                     <div className="text-danger small">
//                       {errors.currencyCode && t('Required field')}
//                     </div>
//                   </div>
//                 </div>

//                 {/* Conversion Rate Input */}
//                 <div className="col-lg-4">
//                   <div className="form-group mb-3">
//                     <label className="form-label">{t('معدل العملة')}</label>
//                     <Controller
//                       name="currencyConversionRate"
//                       control={control}
//                       render={({ field }) => (
//                         <Form.Control
//                           {...field}
//                           type="text"
//                           className="form-control text-center"
//                           readOnly
//                           // value={conversionRate}
//                         />
//                       )}
//                     />
//                     <div className="text-danger small">
//                       {errors.currencyConversionRate && t('Required field')}
//                     </div>
//                   </div>
//                 </div>
//               </div>

//               {/* Submit and Cancel Buttons */}
//               <div className="row">
//                 <div className="col-6">
//                   <button type="submit" className="btn btn-lg btn-theme  w-100 rounded-0">
//                     <i className="fas fa-lg fa-fw me-2 fa-check-circle"></i>

//                     {t('سداد')}
//                   </button>
//                 </div>
//                 <div className="col-6">
//                   <button
//                     type="button"
//                     className="btn btn-lg  btn-default   w-100 rounded-0"
//                     onClick={() => navigate('/Finance/Ap/ApPayments', { replace: true })}>
//                     <i className="fas fa-times-circle fa-fw me-1"></i> {t('Cancel')}
//                   </button>
//                 </div>
//               </div>
//             </Form>
//           </CardBody>
//         </Loading>
//       </Card>
//     </>
//   );
// };

// export default React.memo(AddPaymentSchedules);
