/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import Attachment from '../../../Attachments/Attachment';
import { GetPrintTemplateColumnsById } from '../../../../store/NTX/PrintTemplate/PrintTemplateSlice';
import { ntxAPI } from '../../../../API/axiosNeatex';
import showConfirmation from '../../../../components/Shared/Alert/Confirmation';
import FileList from '../../../Attachments/FileList';
// import Loader from '../../../../components/Shared/Loader/Loader';
import Loading from '../../../../components/Shared/Loader/Loading';
import { Card } from '../../../../components/card/card';
// import AttachmentList from "../../Contracts/Attachment/AttachmentList";
// import Attachment from "./Attachment";

interface Props {
  templateId: string;
}

const MngTemplates = ({ templateId }: Props) => {
  const [attachments, setAttachments] = useState<any[]>([]);
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const formData = new FormData();

  const {
    PrintTemplateColumnsList: data,
    loadingUpdate,
    error,
  } = useAppSelector((state) => state.PrintTemplate);
  useEffect(() => {
    if (templateId) {
      dispatch(GetPrintTemplateColumnsById(templateId));
    }
  }, [templateId]);

  const onSave = () => {
    formData.append('Id', templateId);
    formData.append('TemplateFile', attachments[0]?.attachment[0]);

    if (attachments?.length > 0) {
      const errorMessage =
        i18n.language === 'en'
          ? 'Invalid file type. Please select a .docx file.'
          : 'نوع الملف غير صالح. يرجى اختيار ملف .docx.';
      const file = attachments[0]?.attachment[0];

      // Get the file extension
      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (fileExtension !== 'docx') {
        CustomAlert({ action: 'Error', msg: errorMessage, Timer: 5000 });
        return; // Exit the function early
      }
    }

    showConfirmation('هل أنت متأكد', async () => {
      const { data } = await ntxAPI.post('Reports/AddUpdatePrintTemplateFile', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (data?.succeeded === true) {
        CustomAlert({ action: 'Add', msg: 'تمت العملية بنجاح' });
      } else {
        CustomAlert({ action: 'Error' });
      }
    });
  };

  // if (loadingUpdate) {
  //   return <Loader loading={loadingUpdate} />;
  // }

  return (
    <Loading loading={loadingUpdate} error={error} Type="Dots">
      <>
        <div className="text-center bg-inverse bg-opacity-10 rounded-0 p-2 fw-bold mb-2">
          إعدادات قالب الطباعة
        </div>
        <Card className="mb-2">
          <h6 className=" p-0 mt-2">
            <i className="fas fa-lg fa-fw me-2 bi bi-database-fill-exclamation text-theme fs-3"></i>{' '}
            أعمدة التقرير
          </h6>

          <div className="table-responsive   text-center ">
            <table className="table table-sm table-hover text-nowrap ">
              <thead className="bg-theme bg-opacity-60">
                <tr>
                  <th className="">العمود</th>
                  <th className="">الإسم المستعار</th>
                  <th className="pt-2 pb-2">{t('النوع')}</th>
                </tr>
              </thead>

              <tbody>
                {data?.reportColumnsList
                  ?.slice()
                  ?.sort((a, b) => a.sequenceNum - b.sequenceNum)
                  ?.map((item, index) => (
                    <tr key={item.id}>
                      <td className="align-middle">{item.sqlName}</td>
                      <td className="align-middle">
                        {i18n.language === 'en' ? item.name : item.name2}
                      </td>
                      <td className="align-middle">{item.dataType}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </Card>
        <Card>
          <h6 className=" p-0 mt-2">
          <i className="fas fa-lg fa-fw me-2 bi bi-file-earmark-fill text-theme fs-3"></i>              نموذج التقرير
          </h6>

          <div className="row">
            <div className="col-xl-1">
            </div>
            <div className="col-xl-5">
              {data.templateFile && <FileList Attachments={[data?.templateFile]} />}
            </div>
          </div>

          <div className="row m-2">
            <div className="col-lg-10">
              <Attachment setAttachments={setAttachments} />
            </div>
            <div className="col-lg-2">
              <div className="w-100">
                <button
                  // disabled={formik.isSubmitting}
                  type="submit"
                  className="btn btn-lg me-1 btn-theme mb-1 w-100 rounded-0"
                  onClick={onSave}>
                  <i className="fa fa-save fa-fw me-2"></i>
                  {t('Save')}
                </button>
                <button
                  type="button"
                  className=" btn me-1 btn-default mb-1 w-100"
                  data-bs-dismiss="modal">
                  <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
                </button>
              </div>
            </div>
          </div>
        </Card>
      </>
    </Loading>
  );
};

export default MngTemplates;
