import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { CardBody, Card } from '../../../components/card/card';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { GetLookupManagList } from '../../../store/NTX/Lookup/lookupsSlice';
import { useTranslation } from 'react-i18next';
import Pagination from '../../../components/Shared/Pagination/Pagination';
import SmallModalComponent from '../../../components/Modal/SmallModalComponent';
import AddLookup from './AddLookup';
import UpdateLookup from './UpdateLookup';
import { Form } from 'react-bootstrap';
import useLookupModule from '../../../hooks/Lookups/use-Lookup-Module';
import Breadcrumb from '../../../components/Shared/Breadcrumb';
import { usePermissions } from '../../../Helper/Permisstions/usePermissions';
import Loading from '../../../components/Shared/Loader/Loading';

const Lookup = () => {
  const { HasPermission } = usePermissions();

  const [search, setSearch] = useState('');
  const [dataPerPage] = useState(10);
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [categoryValue, setCategoryValue] = useState<string | null>();
  const [categoryName, setCategoryName] = useState<string>();
  const [moduleId, setModuleId] = useState<string>();
  const [ID, setID] = useState<string>(); //      استعماله في مكون التعديل
  const [optionSelect, setOptionSelect] = useState<any>();  
  const { Module } = useLookupModule();
  const { LookupData, loading, error } = useAppSelector((state) => state.LookupData);
  useEffect(() => {
    dispatch(GetLookupManagList());
  }, [dispatch]);

  useEffect(() => {
    if (Module?.length) {
      setModuleId(Module[0].id);
    }
  }, [Module]);

  useEffect(() => {
    const options = LookupData?.map((item) => ({
      value: item.categoryValue,
      label: item.categoryName,
      moduleId: item.moduleId,
    }))?.filter((f) => f.moduleId === moduleId);
    setOptionSelect(options);
  }, [LookupData, moduleId]);

  const FilterByCategory = LookupData?.filter((f) => f.categoryValue === categoryValue)[0]
    ?.lookupsList;

  //____________________
  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = FilterByCategory?.filter(
    (item) =>
      item?.meaning.toLowerCase().includes(search.toLowerCase()) ||
      item?.meaning2.toLowerCase().includes(search.toLowerCase())
  )?.slice(indexOfFirstData, indexOfLastData);

  //________________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'GeneralSystemLists',
      link: null,
    },
  ];

  // console.log(optionSelect);
  // console.log(categoryValue);
  

  return (
    <>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName="GeneralSystemLists" />

      <Card>
        <Loading loading={loading} error={error}>
          <CardBody>
            <div className=""  >
              <div className="row  ">
                <div className="col-xl-3  ">
                  <div className="input-group flex-nowrap mb-2">
                    <span className="input-group-text">{t('Search')}</span>
                    <input
                      type="text"
                      className="form-control "
                      onChange={(e) => {
                        setSearch(e.target.value);
                        setCurrentPage(1);
                      }}
                    />
                  </div>
                </div>

                <div className="col-xl-4  ">
                  <div className="input-group flex-nowrap mb-2">
                    <span className="input-group-text">{t('Application')}</span>
                    <Select
                      classNamePrefix="react-select"
                      className="w-100"
                      // isLoading={loading2}
                      isSearchable={true}
                      isClearable
                      options={
                        Module &&
                        Module?.map((item: any) => ({
                          value: item.id,
                          label: i18n.language === 'ar' ? item.name2 : item.name,
                        }))
                      }
                      onChange={(option: any) => {
                        setCategoryValue('');
                        setModuleId(option === null ? null : option.value);
                        if(option === null){setCategoryValue(null)}
                      }}
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                    />
                  </div>
                </div>

                <div className="col-xl-4  ">
                  <div className="input-group flex-nowrap mb-2">
                    <span className="input-group-text">{t('Type')}</span>
                    <Select
                      isSearchable
                      options={optionSelect}
                      classNamePrefix="react-select"
                      className='w-100'
                      name="Select"
                      value={optionSelect?.find((f:any)=> f.value === categoryValue) || null}
                      onChange={(option) => {
                        setCategoryValue(option?.value);
                        setCurrentPage(1);
                        setCategoryName(option?.label);
                      }}
                      placeholder="اخـتــر ... "
                      defaultValue={{
                        value: LookupData[0]?.categoryValue,
                        label: LookupData[0]?.categoryName,
                      }}
                    />
                  </div>
                </div>

                {HasPermission('NtxAddgeneralsystemList') && (
                  <div className="col-xl-1 text-end" >
                    <button
                      type="button"
                      className="btn  w-100 btn-theme mb-2"
                      data-bs-toggle="modal"
                      data-bs-target="#AddLookup">
                      <i className="far fa-lg fa-fw   fa-plus"></i>
                      {/* {t('Add')} */}
                    </button>
                  </div>
                )}
              </div>

              <div className="table-responsive">
                <table className="table text-nowrap">
                  <thead className="table-dark">
                    <tr>
                      <th scope="col" style={{ width: '30%' }}>
                        {t('LocalName')}
                      </th>
                      <th scope="col" style={{ width: '30%' }}>
                        {t('GlobalName')}
                      </th>
                      <th scope="col" style={{ width: '15%' }}>
                        {t('Code')}
                      </th>
                      <th scope="col" style={{ width: '25%' }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData?.map((item, index) => (
                      <tr key={item.id}>
                        <td className="align-middle">{item?.meaning2}</td>
                        <td className="align-middle">{item?.meaning}</td>
                        <td className="align-middle">{item?.lookupCode}</td>
                        <td className='text-end'>
                          {HasPermission('NtxEditgeneralsystemList') && (
                            <div className="btn-group">
                              <button
                                type="button"
                                className="btn btn-outline-theme"
                                data-bs-toggle="modal"
                                data-bs-target="#UpdateLookup"
                                onClick={() => setID(item.id)}>
                                <i className="far fa-lg fa-fw me-2 fa-edit"></i>
                                {t('Button.Edit')}
                              </button>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {FilterByCategory?.length > 0 && (
                <Pagination
                  filteredData={FilterByCategory}
                  dataPerPage={dataPerPage}
                  handlePagination={(pageNumber: number) => setCurrentPage(pageNumber)}
                  currentPage={currentPage}
                />
              )}
            </div>
          </CardBody>
        </Loading>
      </Card>

      <SmallModalComponent id={'AddLookup'} title={t('Add')}>
        {categoryValue && categoryName && (
          <AddLookup
            categoryName={categoryName}
            categoryValue={categoryValue}
            FilterByCategory={FilterByCategory}
          />
        )}
      </SmallModalComponent>

      <SmallModalComponent id={'UpdateLookup'} title={t('Edit')}>
        {ID && categoryValue && categoryName && (
          <UpdateLookup
            categoryName={categoryName}
            categoryValue={categoryValue}
            FilterByCategory={FilterByCategory}
            ID={ID}
          />
        )}
      </SmallModalComponent>
    </>
  );
};

export default Lookup;
