import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { TagsInput } from "react-tag-input-component";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import {
  GetParametersByReportId,
  GetReportsChartData,
  GetReportsData,
} from "../../../../../../store/NTX/Reports/ReportData/reportdataSlice";
import TypeT from "./TypeT";
import TypeD from "./TypeD";
import Loader from "../../../../../../components/Shared/Loader/Loader";
import TabsReports from "../TabsReports/TabsReports";
import Breadcrumb from "../../../../../../components/Shared/Breadcrumb";

const ReportsBuilder: React.FC = () => {
  const { t, i18n } = useTranslation();
  const Navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { ReportId } = useParams<{ ReportId: string }>();
  const [formData, setFormData] = useState<any>({});
  const [DataReport, setDataReport] = useState<object>({});

  const [DataChartReport, setDataChartReport] = useState<any[]>([]);

  useEffect(() => {
    if (ReportId) {
      dispatch(GetParametersByReportId(ReportId));
    }
  }, [ReportId]);

  const {
    ParameterList,
    loading,
    loadingGetReportsData: IsExecut,
  } = useAppSelector((state) => state.ReportData);

  const sortedList = ParameterList?.parameterList
    ?.slice()
    .sort((a, b) => a.sequnce - b.sequnce);

  const handleInputChange = (e: any) => {
    const { name, type, value, checked } = e.target;
    if (type === "checkbox") {
      const checkboxValue = checked ? "1" : "0";
      setFormData({ ...formData, [name]: checkboxValue });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setDataReport([]);

    let List = sortedList?.map((item, index) => ({
      id: item.id,
      value: formData[item.id],
    }));

    const RequestData = {
      reportQuery: {
        reportId: ReportId,
        parameterList: List?.filter((f) => f?.value?.trim() !== "")?.map(
          (item) => ({
            id: item.id,
            value: item.value,
          })
        ),
      },
    };

    try {
      let resDataReport;
      resDataReport = await dispatch(GetReportsData(RequestData));
      if (resDataReport.payload.succeeded === true) {
        setDataReport(resDataReport.payload.data);
      } else if (resDataReport.payload.succeeded === false) {
      }

      if (ParameterList && ParameterList?.chartList?.length > 0) {
        const responsePromises = ParameterList?.chartList?.map(async (item) => {
          const Request = {
            reportChartId: item.id,
            parameterList: List?.filter((f) => f?.value?.trim() !== "")?.map(
              (param) => ({
                id: param.id,
                value: param.value,
              })
            ),
          };

          const res = await dispatch(GetReportsChartData(Request));
          return res.payload.data; // Return the response data
        });
        const responses = await Promise.all(responsePromises);

        setDataChartReport(responses);
      }

      // setIsExecut(false);
    } catch (error) {
      // setIsExecut(false);
      // setIsSubmitting(false);
      console.error("An error occurred while submitting the form:", error);
    }
  };

  //________________________________
  var BcrumbList = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "Reports",
      link: `/${localStorage.CurrentSystem}/Reports`,
    },
  ];

  return (
    <>
      <Breadcrumb
        BreadcrumbList={BcrumbList}
        PageName={
          loading
            ? ""
            : i18n.language === "ar"
            ? ParameterList?.name2
            : ParameterList?.name
        }
      />
      <hr className="mb-2 shadow-lg" />

      <Loader loading={loading} />

      <div className=" bg-inverse bg-opacity-5 shadow border border-theme rounded-2 p-3">
        <Form onSubmit={handleSubmit}>
          <div className="row">
            {ParameterList &&
              // eslint-disable-next-line array-callback-return
              sortedList?.map((item, index) => {
                if (item.hasIn === false) {
                  switch (item.dataType) {
                    case "DATE":
                      return (
                        <div key={item.id} className="col-xl-6">
                          <div className="form-group mb-3">
                            <label className="form-label">
                              {i18n.language === "ar"
                                ? item.lableText2
                                : item.lableText}
                            </label>
                            <Form.Control
                              className="form-control"
                              required={item.requiredFlag}
                              type="Date"
                              name={item.id}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      );
                    case "NUMBER":
                      return (
                        <div key={item.id} className="col-xl-6">
                          <div className="form-group mb-3">
                            <label className="form-label">
                              {i18n.language === "ar"
                                ? item.lableText2
                                : item.lableText}
                            </label>
                            <Form.Control
                              className="form-control"
                              required={item.requiredFlag}
                              type="number"
                              name={item.id}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      );
                    case "TEXT":
                      return (
                        <div key={item.id} className="col-xl-6">
                          <div className="form-group mb-3">
                            <label className="form-label">
                              {i18n.language === "ar"
                                ? item.lableText2
                                : item.lableText}
                            </label>
                            <Form.Control
                              className="form-control"
                              required={item.requiredFlag}
                              type="text"
                              name={item.id}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      );
                    case "TIME":
                      return (
                        <div key={item.id} className="col-xl-6">
                          <div className="form-group mb-3">
                            <label className="form-label">
                              {i18n.language === "ar"
                                ? item.lableText2
                                : item.lableText}
                            </label>
                            <Form.Control
                              className="form-control"
                              required={item.requiredFlag}
                              type="time"
                              name={item.id}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      );
                    case "BOOLEAN":
                      return (
                        <div key={item.id} className="col-xl-6">
                          <div className="form-group mb-3">
                            <label className="form-label">
                              {i18n.language === "ar"
                                ? item.lableText2
                                : item.lableText}
                            </label>
                            <Form.Check
                              style={{ margin: "10px 20px" }}
                              type="switch"
                              // label={
                              //   i18n.language === "ar" ? item.lable2 : item.lable
                              // }
                              name={item.id}
                              defaultChecked={false}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      );
                    case "T":
                      return (
                        <div key={item.id} className="col-xl-6">
                          <TypeT
                            key={item.id}
                            item={item}
                            formData={formData}
                            setFormData={setFormData}
                          />
                        </div>
                      );
                    case "D":
                      return (
                        <div key={item.id} className="col-xl-6">
                          <TypeD
                            key={item.id}
                            item={item}
                            formData={formData}
                            setFormData={setFormData}
                          />
                        </div>
                      );
                    case "L":
                      return (
                        <div key={item.id} className="col-xl-6">
                          {/* <TypeL
                                 key={item.id}
                                 item={item}
                                formData={formData}
                            setFormData={setFormData}
                               /> */}
                        </div>
                      );
                  }
                } else if (item.hasIn === true) {
                  return (
                    <div key={item.id} className="col-xl-6">
                      <div className="form-group mb-3">
                        <label className="form-label">
                          {i18n.language === "ar"
                            ? item.lableText2
                            : item.lableText}
                        </label>
                        <TagsInput
                          key={item.id}
                          // value={tags}
                          // value={}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              [item.id]: e
                                ?.map((item: any) => `'${item}'`)
                                .join(","),
                            })
                          }
                        />
                      </div>
                    </div>
                  );
                }
              })}
          </div>

          <div className="text-center">
            <button
              disabled={IsExecut}
              type="submit"
              className="btn me-1 btn-theme mb-1"
            >
              {IsExecut ? (
                <div className="spinner-border spinner-border-sm me-2"></div>
              ) : (
                <i className="bi bi-caret-left-fill me-2"></i>
              )}
              {t("ViewReport")}
            </button>

            <button
              type="button"
              className=" btn me-1 btn-default mb-1"
              onClick={() => Navigate(-1)}
            >
              <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
            </button>
          </div>
        </Form>
      </div>

      <div className="mb-2" />

      {DataChartReport?.length > 0 || Object.keys(DataReport)?.length > 0 ? (
        <TabsReports
          DataReport={DataReport}
          chartList={ParameterList?.chartList}
          DataChartReport={DataChartReport}
        />
      ) : (
        <></>
        // <div className="text-center text-bg-info col-2 mx-auto">
        //   <p className="m-1">No Data</p>
        // </div>
      )}
    </>
  );
};

export default ReportsBuilder;
