/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import Pagination from '../../../../components/Shared/Pagination/Pagination';
import { Card, CardBody } from '../../../../components/card/card';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import Loader from '../../../../components/Shared/Loader/Loader';
import { GetScanningListByFleetEquipmentsId } from '../../../../store/FLEET/EquipmentInspection/equipmentInspectionSlice';
import useGetEquipmentsList from '../../../../hooks/FLeet/useGetEquipmentsList';

const ScanningList = () => {

    const { fleetEquipmentsId } = useParams();
    const [search, setSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [dataPerPage] = useState(10);
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch();
    const Navigate = useNavigate();
  
    useEffect(() => {
        if(fleetEquipmentsId){
            dispatch(GetScanningListByFleetEquipmentsId(fleetEquipmentsId));
        }
    }, [fleetEquipmentsId]);


    const { EquipmentsList } = useGetEquipmentsList()
    const EquipmentData = EquipmentsList?.find((f)=> f.id === fleetEquipmentsId)
    
    const { PeriodicallyScanningList , loading } = useAppSelector(
      (state) => state.EquipmentInspection
    );
    
    const data = PeriodicallyScanningList?.map(item => {
      const totalCount = item.countTrue + item.countFalse;
      const countTruePercentage = Math.ceil((item.countTrue / totalCount) * 100 || 0);
      const countFalsePercentage = Math.floor((item.countFalse / totalCount) * 100 || 0);
      return { ...item, countTruePercentage, countFalsePercentage };
    });
    
  
    const filteredSearch = data?.slice()?.filter((item) => {
      const searchLowerCase = search.toLowerCase();
      return (
        item.driverName?.toLowerCase().includes(searchLowerCase) ||
        item.driverName2?.toLowerCase().toString().includes(searchLowerCase)
      );
    });
  
    const indexOfLastData = currentPage * dataPerPage;
    const indexOfFirstData = indexOfLastData - dataPerPage;
    const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);


    // const totalCount = (data[0]?.countFalse || 0) + (data[0]?.countTrue || 0);
    // const countTruePercentage = (data[0]?.countTrue || 0) / totalCount * 100;

    
   
    
    //________________________________
    var BcrumbList = [
      {
        name: "Home",
        link: "/",
      },
      {
        name: "EquipmentInspection",
        link: "/FLEET/EquipmentInspection",
      },
      {
        name: "PreviousInspections",
        link: null,
      },
    ];
  
  return (
    <div>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName="PreviousInspections" />

      <Card>
        {/* <Loading loading={loading} error={error} SkeletonType="Table"> */}
        <Loader loading={loading} />
        <CardBody>
          <div className=" p-2">
            <div >
              <div className="row">
                <div className="col-7 col-md-6 d-flex justify-content-start">
                  <div className="input-group mb-4">
                    <div className="flex-fill position-relative">
                      <div className="input-group">
                        <div
                          className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                          style={{ zIndex: 1020, right: "10px" }}
                        >
                          <i className="fa fa-search opacity-5"></i>
                        </div>
                        <input
                          type="text"
                          className="form-control ps-35px"
                          onChange={(e) => setSearch(e.target.value)}
                          placeholder={t("Search") + " ..."}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* //  START TABLE   */}
              <div className="table-responsive">
                <table className="table table-hover text-nowrap">
                  <thead className="table-dark">
                    <tr>
                      <th scope="col" style={{width : "20%"}}>{t('EquipmentName')}</th>
                      <th scope="col" style={{width : "20%"}}>{t("Driver")}</th>
                      <th scope="col" style={{width : "20%"}}>{t('InspectionDate')}</th>
                      <th scope="col" className='text-center' style={{width : "25%"}}>{t('InspectionLevel')}</th>
                      {/* <th scope="col">countFalse</th> */}
                      <th scope="col" style={{width : "15%"}}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData?.map((item, index) => (
                      <tr key={item.id}>
                        <td className="align-middle">{ i18n.language === 'ar' ? EquipmentData?.name2 : EquipmentData?.name }</td>
                        <td className="align-middle">{ i18n.language === 'ar' ? item.driverName2 : item.driverName }</td>
                        <td className="align-middle">{item.scanningDate}</td>
                        <td className="align-middle text-center">
                        <div className="progress">
                            <div className="progress-bar bg-success" style={{width : `${item.countTruePercentage}%`}}>{item.countTruePercentage}%</div>
                            <div className="progress-bar bg-danger" style={{width : `${item.countFalsePercentage}%`}}></div>
                        </div>
                          </td>
                        {/* <td className="align-middle">{item.countFalse}</td> */}
                        <td  className='text-center'  >
                          <button
                              type="button"
                              className="btn btn-outline-secondary btn-lg m-0"
                              onClick={() => Navigate(`${item.id}/Edit`)}
                              data-toggle="tooltip"
                              title="Edit"
                            >
                              <i className="far fa-lg fa-fw fa-edit text-success"></i>
                            </button> 
                          
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <Pagination
                filteredData={filteredSearch}
                dataPerPage={dataPerPage}
                handlePagination={(pageNumber: any) =>
                  setCurrentPage(pageNumber)
                }
                currentPage={currentPage}
              />
            </div>
          </div>
        </CardBody>
        {/* </Loading> */}
      </Card>

      {/* <ModalComponent title={t("AddEquipment")} id="modalِAddEquipments">
        {Show && <AddEquipments />}
      </ModalComponent> */}
    </div>
  )
}

export default ScanningList
