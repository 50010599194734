import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Pagination from '../../../../components/Shared/Pagination/Pagination';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { getAssetsList } from '../../../../store/Finance/FixedAssets/Assets/assetsSlice';
import {
  GetMaterialTransactionsPaged,
  setPageIndex,
} from '../../../../store/INV/MaterialTransactions/MaterialTransactionsSlice';
import ServerPagination from '../../../../components/Shared/Pagination/ServerPagination';
import useMaterialTransactions from '../../../../hooks/INV/InvIssue/useMaterialTransactions';

const Test_Index = () => {
  const { t, i18n } = useTranslation();

  // const dispatch = useAppDispatch();

  // const { data, totalItems, pageIndex, pageSize } = useAppSelector(
  //   (state) => state.MaterialTransactions
  // );
  // useEffect(() => {
  //   dispatch(GetMaterialTransactionsPaged({ pageIndex, pageSize }));
  // }, [dispatch, pageIndex, pageSize]);


  // const handlePageChange = (newPageIndex: number) => {
  //   dispatch(setPageIndex(newPageIndex));
  // };

  const { data, totalItems, pageIndex, pageSize, handlePageChange } = useMaterialTransactions();

  return (
    <div>
 
      <div>
        <h2>Material Transactions</h2>

        <div>
          <ul>
            {data?.map((transaction) => (
              <li key={transaction.id}>
                {transaction.code} - {transaction.transactionDate} - {transaction.description}
              </li>
            ))}
          </ul>

          {/* استخدام مكون Pagination هنا */}
          <ServerPagination
            currentPage={pageIndex}
            totalItems={totalItems}
            pageSize={pageSize}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default Test_Index;
