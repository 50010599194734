import { useFormik } from "formik";
import  { useEffect } from "react";
import CustomAlert from "../../../../components/Shared/Alert/CustomAlert";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserById,
  getUsers,
  GetUserTypes,
  UpdateAuthUser,
} from "../../../../store/NTX/Security/usersSlice";
import useGetPersons from "../../../../hooks/HR/use-get-persons.js";
import { formAddUserSchema } from "../../../ValidationForm/validationSchema";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardBody } from "../../../../components/card/card";
import Loader from "../../../../components/Shared/Loader/Loader.js";
import Breadcrumb from "../../../../components/Shared/Breadcrumb";
import Select from "react-select";
import { ntxAPI } from "../../../../API/axiosNeatex.js";
import showConfirmation from "../../../../components/Shared/Alert/Confirmation";

function EditUser() {
  const Navigate = useNavigate();
  const { userId } = useParams();

  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();

  const { dataEmployees, loading: empLoading } = useGetPersons();
  //
  const { UserTypesList } = useSelector((state) => state.Users);
  const { User, loading } = useSelector((state) => state.Users);
  useEffect(() => {
    dispatch(getUserById(userId));
    dispatch(GetUserTypes());
  }, [dispatch, userId]);

  ///
  const formik = useFormik({
    initialValues: {
      usersName: User ? User.usersName : "",
      usersPass: User ? User.usersPass : "",
      emailAddress: User ? User.emailAddress : "",
      usersMobile: User ? User.usersMobile : "",
      description: User ? User.description : "",
      userTypeId: User ? User.userTypeId : "",
      referenceId: User ? User.referenceId : "",
      isActive: User ? User.isActive : false,
      startDate: User ? User.startDate : "",
      endDate: User ? User.endDate : "",
      usersImage: User ? User.usersImage : "",
    },

    validationSchema: formAddUserSchema(t),
    enableReinitialize: true,
    onSubmit: (values) => {
      dispatch(
        UpdateAuthUser({
          id: userId,
          userTypeId: values.userTypeId,
          referanceId: values.referenceId,
          usersName: values.usersName,
          usersImage: values.usersImage,
          emailAddress: values.emailAddress,
          usersMobile: values.usersMobile,
          startDate: values.startDate,
          description: values.description,
          isActive: values.isActive,

          //
          // lastLogonDate: null,
          // flgPass: null,
          // refreshTokenExpairyDate: null,
          // refreshToken: null,
        })
      )
        .unwrap()
        .then(() => {
          CustomAlert({ action: "Add" });
          dispatch(getUsers());
          Navigate(-1, { replace: true });
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: "Error" });
        });
    },
  });

  const handleResetPassword = () => {
    showConfirmation("هل أنت متأكد من إعادة تعيين كلمة المرور", async () => {
      const { data } = await ntxAPI.post("Security/ResetPassword", {
        userId: userId,
      });

      if (data?.succeeded === true) {
        CustomAlert({ action: "Add", msg: "تمت إعادة التعيين بنجاح" });
      } else {
        CustomAlert({ action: "Error" });
      }
    });
  };

  //_______________
  var BcrumbList = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "users",
      link: "/Security/Users",
    },
    {
      name: "UserEdit",
      link: null,
    },
  ];
  return (
    <>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName="UserEdit" />

      <Card>
        <CardBody>
          <Loader loading={loading} />
          <Form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-xl-5">
                <div className="form-group mb-3">
                  <label className="form-label">{t("UserName")}</label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="usersName"
                    onChange={formik.handleChange}
                    value={formik.values.usersName || ""}
                    isInvalid={
                      formik.touched.usersName && formik.errors.usersName
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.usersName}
                  </Form.Control.Feedback>
                </div>
              </div>

              {/* <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label"> {t('Password')} </label>
                  <Form.Control
                    className="form-control"
                    type="password"
                    name="usersPass"
                    onChange={formik.handleChange}
                    value={formik.values.usersPass || ""}
                    isInvalid={
                      formik.touched.usersPass && formik.errors.usersPass
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.usersPass}
                  </Form.Control.Feedback>
                </div>
              </div> */}

              <div className="col-xl-5">
                <div className="form-group mb-3">
                  <label className="form-label">{t("EmployeeName")}</label>
                  <Select
                    classNamePrefix="react-select"
                    isLoading={empLoading}
                    isSearchable={true}
                    isClearable
                    name="referenceId"
                    options={dataEmployees}
                    onChange={(option) => {
                      formik.setFieldValue(
                        "referenceId",
                        option === null ? null : option.value
                      );
                    }}
                    value={dataEmployees?.find(
                      (f) => f.value === formik.values.referenceId
                    )}
                    placeholder={i18n.language === "ar" ? "إختر" : "Choose"}
                  />
                  {/* <Form.Select
                    className="form-select"
                    name="referenceId"
                    onChange={formik.handleChange}
                    value={formik.values.referenceId || ""}
                    isInvalid={
                      formik.touched.referenceId && formik.errors.referenceId
                    }
                  >
                    <option key={null}>
                      {i18n.language === "ar" ? "إختر" : "Choose"}
                    </option>
                    {Employees?.map((item) => (
                      <option key={item.id} value={item.id}>
                        {i18n.language === "ar" ? item.name2 : item.name}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.referenceId}
                  </Form.Control.Feedback> */}
                </div>
              </div>
              <div className="col-xl-2">
                <div className="form-group mb-3">
                  <label className="form-label">{t("TypeOfUser")}</label>
                  <Form.Select
                    className="form-select"
                    name="userTypeId"
                    onChange={formik.handleChange}
                    value={formik.values.userTypeId || ""}
                    isInvalid={
                      formik.touched.userTypeId && formik.errors.userTypeId
                    }
                  >
                    <option value={null}>
                      {i18n.language === "ar" ? "إختر" : "Choose"}
                    </option>
                    {UserTypesList?.map((item) => (
                      <option key={item.id} value={item.id}>
                        {i18n.language === "ar" ? item.name2 : item.name}
                      </option>
                    ))}
                  </Form.Select>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t("Email")}</label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="emailAddress"
                    onChange={formik.handleChange}
                    value={formik.values.emailAddress || ""}
                    isInvalid={
                      formik.touched.emailAddress && formik.errors.emailAddress
                    }
                  />
                </div>
              </div>
              <div className="col-xl-2">
                <div className="form-group mb-3">
                  <label className="form-label">{t("MobileNumber")}</label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="usersMobile"
                    onChange={formik.handleChange}
                    value={formik.values.usersMobile || ""}
                    isInvalid={
                      formik.touched.usersMobile && formik.errors.usersMobile
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.usersMobile}
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t("Start Date")}</label>
                  <Form.Control
                    type="date"
                    className="form-control"
                    name="startDate"
                    onChange={formik.handleChange}
                    value={formik.values.startDate || ""}
                    isInvalid={
                      formik.touched.startDate && formik.errors.startDate
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.startDate}
                  </Form.Control.Feedback>
                </div>
              </div>

              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t("Date_TO")}</label>

                  <Form.Control
                    type="date"
                    className="form-control"
                    name="endDate"
                    onChange={formik.handleChange}
                    value={formik.values.endDate || ""}
                    isInvalid={formik.touched.endDate && formik.errors.endDate}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.endDate}
                  </Form.Control.Feedback>
                </div>
              </div>

              <div className="col-xl-1">
                <div className="form-group mb-3">
                  <label className="form-label" style={{ margin: "10px 22px" }}>
                    {" "}
                    {t("Active")}
                  </label>
                  <Form.Check
                    style={{ margin: "10px 20px" }}
                    type="switch"
                    label=""
                    name="isActive"
                    checked={formik.values.isActive}
                    onChange={formik.handleChange}
                    value={formik.values.isActive || ""}
                    isInvalid={
                      formik.touched.isActive && formik.errors.isActive
                    }
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="form-group mb-3">
                  <label className="form-label">{t("Note")}</label>
                  <Form.Control
                    className="form-control"
                    as="textarea"
                    rows={3}
                    name="description"
                    onChange={formik.handleChange}
                    value={formik.values.description || ""}
                  />
                  <div></div>
                </div>
              </div>
            </div>

            <div className="text-center">
              <button
                disabled={formik.isSubmitting}
                type="submit"
                className="btn me-1 btn-theme mb-1"
                id="SaveSubmit"
              >
                {formik.isSubmitting ? (
                  <div className="spinner-border spinner-border-sm me-2"></div>
                ) : (
                  <i className="fa fa-save fa-fw me-2"></i>
                )}
                {t("Save")}
              </button>

              <button
                type="button"
                className=" btn me-1 btn-default mb-1"
                onClick={() => Navigate(-1, { replace: true })}
              >
                <i className="fa fa-power-off fa-fw me-1"></i>
                {t("Cancel")}
              </button>

              <button
                type="button"
                className=" btn me-1 btn-theme mb-1 "
                onClick={handleResetPassword}
              >
                <i className="fbi bi-arrow-clockwise me-1"></i>
                {t("ResetPassword")}
              </button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </>
  );
}

export default EditUser;
