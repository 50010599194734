import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { GetEquipmentsOdometerList, GetEquipmentsOdometerListByFleetEquipmentsId } from "../../../store/FLEET/EquipmentsOdometer/equipmentsOdometerSlice";
import Pagination from "../../../components/Shared/Pagination/Pagination";

interface props {
  setID: React.Dispatch<React.SetStateAction<string>>; // For Update
}

const EquipmentsOdometerList: React.FC<props> = (props) => {
  const { Id } = useParams();
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  // const Navigate = useNavigate();

  useEffect(() => {
    if (Id) {
      dispatch(GetEquipmentsOdometerListByFleetEquipmentsId(Id))
    }
    // dispatch(GetEquipmentsOdometerList())
  }, [Id]);
  const { OdometerListByFleetEquipmentsId } = useAppSelector(
    (state) => state.EquipmentsOdometer
  );

  

  const filteredSearch = OdometerListByFleetEquipmentsId?.slice()
    ?.filter((item) => {
      const searchLowerCase = search.toLowerCase();
      return (
          item.id?.toLowerCase().includes(searchLowerCase) ||
          item.description?.toLowerCase().includes(searchLowerCase) ||
          item.peopleName2?.toLowerCase().includes(searchLowerCase) ||
        item.peopleName?.toLowerCase().toString().includes(searchLowerCase)
      );
    })
 
  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  return (
    <div>

      <div className="col-3 mx-auto mb-2">
        <div className="input-group">
            <div className="input-group">
              <div
                className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                style={{ zIndex: 1020, right: "10px" }}
              >
                <i className="fa fa-search opacity-5"></i>
              </div>
              <input
                type="text"
                className="form-control ps-35px"
                onChange={(e) => setSearch(e.target.value)}
                placeholder={t("Search") + " ..."}
              />
            </div>
        </div>
      </div>

      
      <div className="table-responsive rounded-2">
        <table className="table">
          <thead className="table-dark">
            <tr>
              <th scope="col">{t("DriverName")}</th>
              <th scope="col">{t("Odometer")}</th>
              <th scope="col">{t("DateOfReading")}</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {currentData?.map((item, index) => (
              <tr key={item.id}>
                <td className="align-middle">
                  {i18n.language === "en" ? item.peopleName : item.peopleName2}
                </td>
                <td className="align-middle">
                  {item.odometer === null ? "" : String(item.odometer)}
                </td>
                <td className="align-middle">{item.readDate}</td>
                <td>
                  <button
                    type="button"
                    className="btn btn-outline-lime"
                    data-bs-toggle="modal"
                    data-bs-target="#UpdateEquipmentsOdometer"
                    onClick={() => props.setID(item.id)}
                  >
                    <i className="far fa-lg fa-fw  fa-edit"></i>
                    {/* {t("Button.Edit")} */}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="me-3 pb-2 ms-2"> 
      <Pagination
        filteredData={filteredSearch}
        dataPerPage={dataPerPage}
        handlePagination={(pageNumber: any) => setCurrentPage(pageNumber)}
        currentPage={currentPage}
        />
        </div>
    </div>
  );
};

export default EquipmentsOdometerList;
