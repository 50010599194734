import { useFormik } from 'formik';
import { useAppDispatch } from '../../../../../store/hooks';
import {
  addAssets,
  getAssetsList,
} from '../../../../../store/Finance/FixedAssets/Assets/assetsSlice';
import CustomAlert from '../../../../../components/Shared/Alert/CustomAlert';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import useGetFACategoriesList from '../../../../../hooks/Finance/FixedAssets/useGetFACategoriesList';
import useGetAccounts from '../../../../../hooks/Finance/ChartAccountsDetails/useGetAccounts';
import useGetGlLedgersList from '../../../../../hooks/Finance/useGetGlLedgersList';
import useGetDepreciationMethod from '../../../../../hooks/Lookups/use-Get_DEPRECIATION_METHOD';
import useGetBranchOrgWithPermission from '../../../../../hooks/NTX/Organization/useGetBranchOrgWithPermission';
import useGetNewUsed from '../../../../../hooks/Lookups/use-Get_NEW_USED';
import useGetOwnedLeased from '../../../../../hooks/Lookups/use-Get_OWNED_LEASED';
import useGetAssetsList from '../../../../../hooks/Finance/FixedAssets/useGetAssetsList';
import { Card, CardBody } from '../../../../../components/card/card';
import Breadcrumb from '../../../../../components/Shared/Breadcrumb';
import { AddAssetsSchema } from '../../../../ValidationForm/validationSchema';
import { Link, useParams } from 'react-router-dom';
import Update from './Update';

const AssetsDetails = () => {
  const { assetsId } = useParams();
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const { BranchOrgOptions: BranchsOptions, loading: loading3 } = useGetBranchOrgWithPermission();
  const { FACategoriesList, loading, error } = useGetFACategoriesList();
  const { OptionsAccounts } = useGetAccounts();
  const { LedgersList } = useGetGlLedgersList();
  const { DEPRECIATION_METHOD } = useGetDepreciationMethod();
  const { NEW_USED } = useGetNewUsed();
  const { OWNED_LEASED } = useGetOwnedLeased();
  const { OptionsAssets } = useGetAssetsList();

  //#region Formik
  const formik = useFormik({
    initialValues: {
      name: '',
      name2: '',
      assetNumber: '',
      tagNumber: '',
      serialNumber: '',
      categoryId: '',
      organizationId: '',
      parentAssetId: '',
      datePlacedInService: '',
      ownedLeased: '',
      leasedId: '',
      newUsed: '',
      originalCost: '',
      adjustedCost: '',
      salvageValue: '',
      lifeInMonths: '',
      depreciationMethod: '',
      percentage: '',
      description: '',
      assetCostAccount: '',
      deprnExpenseAccount: '',
      deprnReserveAccount: '',
      glLedgerId: '',
    },

    // enableReinitialize: true,
    validationSchema: AddAssetsSchema(t),

    onSubmit: (values) => {
      dispatch(
        addAssets({
          name: values.name,
          name2: values.name2,
          assetNumber: values.assetNumber,
          tagNumber: values.tagNumber,
          serialNumber: values.serialNumber,
          categoryId: values.categoryId,
          organizationId: values.organizationId,
          parentAssetId: null,
          // parentAssetId: values.parentAssetId  ,
          datePlacedInService: values.datePlacedInService,
          ownedLeased: values.ownedLeased,
          leasedId: null,
          newUsed: values.newUsed,
          originalCost: values.originalCost,
          adjustedCost: values.adjustedCost,
          salvageValue: values.salvageValue,
          lifeInMonths: values.lifeInMonths,
          depreciationMethod: values.depreciationMethod,
          percentage: values.percentage,
          description: values.description,
          assetCostAccount: values.assetCostAccount,
          deprnExpenseAccount: values.deprnExpenseAccount,
          deprnReserveAccount: values.deprnReserveAccount,
          glLedgerId: values.glLedgerId,
        })
      )
        .unwrap()
        .then((res) => {
          debugger;
          if (res?.succeeded === true) {
            CustomAlert({ action: 'Add' });
            dispatch(getAssetsList());
            formik.resetForm();
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error', msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });
  const { values, handleChange, errors, touched, setFieldValue } = formik;
  //#endregion

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'Fa Assets',
      link: null,
    },
    {
      name: 'Add Assets',
      link: null,
    },
  ];

  return (
    <>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName="تفــاصيـــل الأصل" />
      </div>
      <Card>
        <CardBody className="pt-1">
          <ul className="nav nav-tabs nav-tabs-v2 ps-4 pe-4 ">
            <li className="nav-item me-3">
              <Link to="#homev2WithCard" className="nav-link active" data-bs-toggle="tab">
                <i className="fa fa-home me-2"></i>
                بيانات الأصل
              </Link>
            </li>
            <li className="nav-item me-3">
              <Link to="#profilev2WithCard" className="nav-link" data-bs-toggle="tab">
                <i className="fa fa-tools me-2"></i>
                الإضافات
              </Link>
            </li>
          </ul>
          <div className="tab-content p-4">
            <div className="tab-pane fade show active" id="homev2WithCard">
              <Update/>
            </div>
            <div className="tab-pane fade" id="profilev2WithCard">
              ...
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default AssetsDetails;

// {/* تابع لـ  */}
// <div className="col-lg-4">
//   <div className="form-group mb-3">
//     <label className="form-label"> {t('تابع لـ ')}</label>
//     <Select
//       classNamePrefix="react-select"
//       // isLoading={loading3}
//       isSearchable={true}
//       isClearable
//       options={OptionsAssets}
//       value={OptionsAssets?.find((f: any) => f.value === formik.values.parentAssetId)}
//       onChange={(option: any) =>
//         setFieldValue('parentAssetId', option === null ? null : option.value)
//       }
//       placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
//     />
//   </div>
// </div>
