import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getPaymentTypeList } from '../../../store/Finance/AP/ApPayments/ApPaymentsSlice';

const useGetPaymentTypeList = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (PaymentTypeList?.length === 0) {
      dispatch(getPaymentTypeList());
    }
  }, []);
  const { PaymentTypeList, loading2, error } = useAppSelector((state) => state.ApPayments);

  return { PaymentTypeList, loading2, error };
};

export default useGetPaymentTypeList;
