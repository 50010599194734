import React, { useEffect, useState } from 'react';
import { Card, CardBody } from '../../../../components/card/card';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { useTranslation } from 'react-i18next';
import Pagination from '../../../../components/Shared/Pagination/Pagination';
import Select from 'react-select';
import { Form } from 'react-bootstrap';
import useGetCategoriesList from '../../../../hooks/INV/useGetCategoriesList';
import { useLocation, useParams } from 'react-router-dom';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import Loading from '../../../../components/Shared/Loader/Loading';
import useGetPriceListItems from '../../../../hooks/INV/PriceList/useGetPriceListItems';
import { PriceListItemsModels } from '../../../../store/INV/PriceList/PriceListModels';
import { ntxAPI } from '../../../../API/axiosNeatex';

const DetailsPriceList = () => {
  const { id } = useParams<{ id: string }>();
  const [categoryId, setCategoryId] = useState<any>();
  const { t, i18n } = useTranslation();
  const [isSubmitting, setIssubmitting] = useState(false);

  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const [items, setItems] = useState<PriceListItemsModels[]>();

  const { CategoriesOptions, loading: loading2 } = useGetCategoriesList();
  const { PriceListItems, loading, error } = useGetPriceListItems({ priceListId: id ?? '' });
  const [showOnlyEnabled, setShowOnlyEnabled] = useState(false); // حالة جديدة للتحكم في عرض الأصناف المفعلة فقط

  useEffect(() => {
    setItems(PriceListItems);
  }, [PriceListItems]);

  //#region
  const filteredSearch = items?.slice()?.filter((item) => {
    const searchLowerCase = search.toLowerCase();
    const matchesSearch =
      item.name?.toLowerCase().includes(searchLowerCase) ||
      item.name2?.toLowerCase().includes(searchLowerCase) ||
      item.barcode?.toLowerCase().includes(searchLowerCase);
    const matchesSource = categoryId ? item.categoryId === categoryId : true;
    const matchesEnabled = showOnlyEnabled ? item.enabledFlag === true : true; // فلتر الأصناف المفعلة

    return matchesSearch && matchesSource && matchesEnabled;
  });

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);
  //#endregion

  const handleCheckboxChange = (Items: PriceListItemsModels) => {
    setItems((prevItems) =>
      prevItems?.map((item) =>
        item.itemId === Items.itemId && item.unitOfMeasure === Items.unitOfMeasure
          ? { ...item, enabledFlag: !item.enabledFlag }
          : item
      )
    );
  };
  const handleCheckboxAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    setItems((prevItems) =>
      prevItems?.map((item) => (true ? { ...item, enabledFlag: e.target.checked } : item))
    );
  };

  const handleChange = (row: PriceListItemsModels, value: string) => {
    setItems((prevItemsList) =>
      prevItemsList?.map((item) => {
        if (row.itemId === item.itemId && row.unitOfMeasure === item.unitOfMeasure) {
          return {
            ...item,
            unitPrice: parseFloat(value),
          };
        }
        return item;
      })
    );
  };

  const onSubmit = async () => {
    // Validate the 'items' array to ensure it contains valid data
    if (!items) {
      CustomAlert({ action: 'info', msg: 'لا توجد عناصر لتحديث قائمة السعر' });
      return;
    }

    // Construct the body payload
    let body = {
      priceListId: id,
      priceListItemsList: items?.map(({ id, itemId, unitOfMeasure, unitPrice, enabledFlag }) => ({
        id,
        itemId,
        unitOfMeasure,
        unitPrice,
        enabledFlag,
      })),
    };
    setIssubmitting(true);
    try {
      const { data } = await ntxAPI.post('Inv/AddUpdatePriceListItem', body);

      if (data?.succeeded) {
        CustomAlert({ action: 'Add', msg: 'تم حفظ التغييرات على قائمة السعر بنجاح' });
      } else {
        CustomAlert({ action: 'Error', msg: 'حدث خطأ أثناء حفظ التغييرات' });
      }
      setIssubmitting(false);
    } catch (error) {
      setIssubmitting(false);

      CustomAlert({ action: 'Error', msg: 'فشل الاتصال بالسيرفر. حاول مرة أخرى لاحقًا' });
      console.error('API Error:', error);
    }
  };
  //________________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'قوائم الأسعار',
      link: '/SalesSettings/PriceList',
    },
    {
      name: 'التفاصيـل',
      link: null,
    },
  ];

  const location = useLocation();
  const { name } = location?.state;

  return (
    <div>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName={t('الأسعار للقائمة')} subtitle={name} />
      <Card>
        <Loading loading={loading} error={error}>
          <CardBody>
            <div className="row mb-3">
              <div className="col-lg-2 text-center pt-2">
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={(e) => {
                      setShowOnlyEnabled(e.target.checked);
                      setCurrentPage(1);
                    }}
                  />
                  <label className="form-check-label">{t('الأصناف المفعلة فقط')}</label>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="input-group flex-nowrap">
                  <span className="input-group-text fw-bold">{t('Search')}</span>
                  <div className="input-group">
                    <div className="flex-fill position-relative">
                      <div className="input-group border-0 ">
                        <div
                          className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                          style={{ zIndex: 1020, right: '10px' }}>
                          <i className="fa fa-search opacity-5 text-theme"></i>
                        </div>
                        <input
                          type="text"
                          className="form-control ps-35px m-0"
                          // placeholder={t('Search')}
                          value={search}
                          onChange={(e) => {
                            setSearch(e.target.value);
                            setCurrentPage(1);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-5">
                <div className="input-group flex-nowrap">
                  <span className="input-group-text  fw-bold">{t('الفئة')}</span>
                  <Select
                    classNamePrefix="react-select"
                    className={`w-100 `}
                    isLoading={loading2}
                    isSearchable={true}
                    isClearable
                    options={CategoriesOptions}
                    value={CategoriesOptions?.find((f) => f.value === categoryId)}
                    onChange={(option) => {
                      setCategoryId(option?.value);
                      setCurrentPage(1);
                    }}
                    placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                  />
                </div>
              </div>

              <div className="col-lg-2 text-end">
                <button
                  className="btn  rounded-1 btn-theme w-100"
                  onClick={onSubmit}
                  disabled={isSubmitting}>
                  {isSubmitting ? (
                    <div className="spinner-border spinner-border-sm me-2"></div>
                  ) : (
                    <i className="fa fa-save fa-fw me-2"></i>
                  )}
                  {t('Save')}
                </button>
              </div>
            </div>

            <div className="table-responsive">
              <table className="table text-center">
                <thead className=" align-middle  table-dark">
                  <tr>
                    <th className="">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          onChange={(e) => handleCheckboxAll(e)}
                        />
                      </div>
                    </th>
                    <th className="">اسم الصنف</th>
                    <th className="">الوحدة</th>
                    <th className="">الباركود</th>
                    <th className="" style={{ width: '10%' }}>
                      السعر
                    </th>
                    {/* <th className="">السعر الجديد</th> */}
                  </tr>
                </thead>
                <tbody>
                  {currentData &&
                    currentData?.map((item, idx) => (
                      <tr
                        className={`${item.enabledFlag ? 'bg-theme bg-opacity-15' : ''} `}
                        key={++idx}>
                        <td className="w-10px align-middle">
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              onChange={(e) => handleCheckboxChange(item)}
                              checked={item.enabledFlag}
                            />
                          </div>
                        </td>

                        <td className="align-middle">
                          {i18n.language === 'en' ? item.name : item.name2}
                        </td>
                        <td className="align-middle">
                          {i18n.language === 'en'
                            ? item.unitOfMeasureName
                            : item.unitOfMeasureName2}
                        </td>
                        <td className="align-middle">{item.barcode}</td>
                        <td>
                          <Form.Control
                            className={`form-control  form-control-sm text-center text-theme fs-5  ${
                              item.enabledFlag ? 'border border-2 border-theme' : ''
                            } `}
                            type="text"
                            value={item.enabledFlag ? item.unitPrice : ''}
                            onChange={(e) => handleChange(item, e.target.value)}
                            disabled={!item.enabledFlag}
                            onInput={(e) => {
                              const input = e.target as HTMLInputElement;
                              input.value = input.value.replace(/[^0-9]/g, '');
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>

            <Pagination
              filteredData={filteredSearch || []}
              dataPerPage={dataPerPage}
              handlePagination={(pageNumber: any) => setCurrentPage(pageNumber)}
              currentPage={currentPage}
            />
          </CardBody>
        </Loading>
      </Card>
    </div>
  );
};

export default DetailsPriceList;
