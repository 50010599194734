import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { GetEquipmentsWorkRequestsList } from "../../../store/FLEET/EquipmentsWorkRequests/equipmentsWorkRequestSlice";
import { CardBody, Card } from "../../../components/card/card";
import Breadcrumb from "../../../components/Shared/Breadcrumb";
import Pagination from "../../../components/Shared/Pagination/Pagination";

interface props {
  // setID: React.Dispatch<React.SetStateAction<string>>; // For Update
}

const WorkRequestList: React.FC<props> = (props) => {
  const { Id } = useParams();
  const [Show, setShow] = useState(false);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const Navigate = useNavigate();

  useEffect(() => {
    dispatch(GetEquipmentsWorkRequestsList());
  }, []);
  const { EquipmentsWorkRequestsList } = useAppSelector(
    (state) => state.EquipmentsWorkRequest
  );

  const filteredSearch = EquipmentsWorkRequestsList?.slice()?.filter((item) => {
    const searchLowerCase = search.toLowerCase();
    return (
      //   emp.name?.toLowerCase().includes(searchLowerCase) ||
      item.requestTitle?.toLowerCase().toString().includes(searchLowerCase)
    );
  });

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  //________________________________
  var BcrumbList = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "MaintenanceOrders",
      link: "/FLEET/Maintenance/MaintenanceOrder",
    },
    {
      name: "MaintenanceRequests",
      link: null,
    },
  ];

  return (
    <>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName="MaintenanceRequests" />
      <Card>
        <CardBody>
          <div className="tab-content p-4">
            <div className="tab-pane fade show active">
              <div className="row">
                <div className="col-7 col-md-6 d-flex justify-content-start">
                  <div className="input-group mb-4">
                    <div className="flex-fill position-relative">
                      <div className="input-group">
                        <div
                          className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                          style={{ zIndex: 1020, right: "10px" }}
                        >
                          <i className="fa fa-search opacity-5"></i>
                        </div>
                        <input
                          type="text"
                          className="form-control ps-35px"
                          onChange={(e) => setSearch(e.target.value)}
                          placeholder={t("Search") + " ..."}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-5 col-md-6 text-end">
                  {/* <div
                    className="btn-group"
                    role="group"
                    style={{ direction: "ltr" }}
                  >
                    <button
                      type="button"
                      className="btn btn-theme dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      خيارات
                    </button>
                    <ul className="dropdown-menu">
                      <li>
                        <Link className="dropdown-item" to="">
                          <i className="far fa-lg fa-fw ms-2 me-2 fa-edit"></i>
                          اضافة أمر جديد
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          to="/FLEET/WorkRequests"
                        >
                          <i className="fas fa-lg fa-fw ms-2 me-2 fa-road"></i>
                          إضافة أمر من طلب صيانة
                        </Link>
                      </li>
                    </ul>
                  </div> */}
                </div>
              </div>
              {/* //  START TABLE   */}
              <div className="table-responsive">
                <table className="table">
                  <thead className="table-dark">
                    <tr>
                      <th scope="col">{t("RequestNumber")}</th>
                      <th scope="col">{t("TypeOfRequest")}</th>
                      <th scope="col">{t("RequestDescription")}</th>
                      <th scope="col">{t("RequestDate")}</th>
                      <th scope="col">{t("Equipment")}</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData?.map((item, index) => (
                      <tr key={item.id}>
                        <td className="align-middle">{item.requestCode}</td>
                        <td className="align-middle">
                          {i18n.language === "ar"
                            ? item.requestTypeName2
                            : item.requestTypeName}
                        </td>
                        <td className="align-middle">{item.requestTitle}</td>
                        <td className="align-middle">
                          {i18n.language === "en"
                            ? item.requestDate
                            : item.requestDate}
                        </td>
                        <td className="align-middle">
                          {i18n.language === "ar"
                            ? item.fleetEquipmentsName2
                            : item.fleetEquipmentsName}
                        </td>

                        <td>
                          <div className="btn-group">
                            <button
                              type="button"
                              className="btn btn-outline-lime"
                              onClick={() =>
                                Navigate(
                                  `${item.id}/AddMaintenanceOrder/${item.fleetEquipmentsId}`
                                )
                              }
                            >
                              <i className="far fa-lg fa-fw me-2 fa-edit"></i>
                              {t('MaintenanceOrder')}
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Pagination
                filteredData={filteredSearch}
                dataPerPage={dataPerPage}
                handlePagination={(pageNumber: any) =>
                  setCurrentPage(pageNumber)
                }
                currentPage={currentPage}
              />
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default WorkRequestList;
