import React, { useMemo } from 'react';
import { useResolvedPath, useMatch, NavLink, useLocation, matchPath } from 'react-router-dom';
import useGetSysName from '../../hooks/useGetSysName';
import menus from './../../config/app-menu.jsx';
import { useTranslation } from 'react-i18next';


// const menus = [
// 	{ is_header: true, title: 'Navigation' },
// 	{ path: '/dashboard', icon: 'bi bi-cpu', title: 'Dashboard' },
// 	{ path: '/analytics', icon: 'bi bi-bar-chart', title: 'Analytics' },
// 	{ path: '/email', icon: 'bi bi-envelope', title: 'Email',
// 		children: [
// 			{ path: '/email/inbox', title: 'Inbox' }, 
// 			{ path: '/email/compose', title: 'Compose' }, 
// 			{ path: '/email/detail', title: 'Detail' }
// 		]
// 	}, 
// 	{ is_divider: true }, 
// 	{ is_header: true, title: 'Components' }, 
// 	{ path: '/widgets', icon: 'bi bi-columns-gap', title: 'Widgets' }, 
// 	{ path: '/pos', icon: 'bi bi-bag-check', title: 'POS System',
// 		highlight: true,
// 		children: [
// 			{ path: '/pos/customer-order', title: 'Customer Order' }, 
// 			{ path: '/pos/kitchen-order', title: 'Kitchen Order' }, 
// 			{ path: '/pos/counter-checkout', title: 'Counter Checkout' }, 
// 			{ path: '/pos/table-booking', title: 'Table Booking' }, 
// 			{ path: '/pos/menu-stock', title: 'Menu Stock' }
// 		]
// 	}, 
// 	{ path: '/ui', icon: 'fa fa-heart', title: 'UI Kits',
// 		children: [
// 			{ path: '/ui/bootstrap', title: 'Bootstrap' }, 
// 			{ path: '/ui/buttons', title: 'Buttons' }, 
// 			{ path: '/ui/card', title: 'Card' }, 
// 			{ path: '/ui/icons', title: 'Icons' }, 
// 			{ path: '/ui/modal-notifications', title: 'Modal & Notifications' }, 
// 			{ path: '/ui/typography', title: 'Typography' }, 
// 			{ path: '/ui/tabs-accordions', title: 'Tabs & Accordions' }
// 		]
// 	}, 
// 	{ path: '/form', icon: 'bi bi-pen', title: 'Forms',
// 		children: [
// 			{ path: '/form/elements', title: 'Form Elements' }, 
// 			{ path: '/form/plugins', title: 'Form Plugins' }, 
// 			{ path: '/form/wizards', title: 'Wizards' }
// 		]
// 	}, 
// 	{ path: '/table', icon: 'bi bi-grid-3x3', title: 'Tables',
// 		children: [
// 			{ path: '/table/elements', title: 'Table Elements' },
// 			{ path: '/table/plugins', title: 'Table Plugins' }
// 		]
// 	}, 
// 	{ path: '/chart', icon: 'bi bi-pie-chart', title: 'Charts',
// 		children: [
// 			{ path: '/chart/chart-js', title: 'Chart.js' },
// 			{ path: '/chart/chart-apex', title: 'Apexcharts.js' }
// 		]
// 	}, 
// 	{ path: '/map', icon: 'bi bi-compass', title: 'Map' }, 
// 	{ path: '/layout', icon: 'bi bi-layout-sidebar', title: 'Layout',
// 		children: [
// 			{ path: '/layout/starter-page', title: 'Starter Page' }, 
// 			{ path: '/layout/fixed-footer', title: 'Fixed Footer' }, 
// 			{ path: '/layout/full-height', title: 'Full Height' }, 
// 			{ path: '/layout/full-width', title: 'Full Width' }, 
// 			{ path: '/layout/boxed-layout', title: 'Boxed Layout' }, 
// 			{ path: '/layout/collapsed-sidebar', title: 'Collapsed Sidebar' }, 
// 			{ path: '/layout/top-nav', title: 'Top Nav' }, 
// 			{ path: '/layout/mixed-nav', title: 'Mixed Nav' }, 
// 			{ path: '/layout/mixed-nav-boxed-layout', title: 'Mixed Nav Boxed Layout' }
// 		]
// 	}, 
// 	{ path: '/pages', icon: 'bi bi-collection', title: 'Pages',
// 		children: [
// 			{ path: '/pages/scrum-board', title: 'Scrum Board' }, 
// 			{ path: '/pages/products', title: 'Products' }, 
// 			{ path: '/pages/product-details', title: 'Product Details' }, 
// 			{ path: '/pages/orders', title: 'Orders' }, 
// 			{ path: '/pages/order-details', title: 'Order Details' }, 
// 			{ path: '/pages/gallery', title: 'Gallery' }, 
// 			{ path: '/pages/search-results', title: 'Search Results' }, 
// 			{ path: '/pages/coming-soon', title: 'Coming Soon Page' }, 
// 			{ path: '/pages/error', title: 'Error Page' }, 
// 			{ path: '/pages/login', title: 'Login' }, 
// 			{ path: '/pages/register', title: 'Register' }, 
// 			{ path: '/pages/messenger', title: 'Messenger' }, 
// 			{ path: '/pages/data-management', title: 'Data Management' },
// 			{ path: '/pages/file-manager', title: 'File Manager' }, 
// 			{ path: '/pages/pricing', title: 'Pricing Page' }
// 		]
// 	}, 
// 	{ is_divider: true }, 
// 	{ is_header: true, title: 'Users' }, 
// 	{ path: '/profile', icon: 'bi bi-people', title: 'Profile' }, 
// 	{ path: '/calendar', icon: 'bi bi-calendar4', title: 'Calendar' }, 
// 	{ path: '/settings', icon: 'bi bi-gear', title: 'Settings' }, 
// 	{ path: '/helper', icon: 'bi bi-gem',	title: 'Helper' }
// ];




function NavItem({ menu, ...props }) {

	const { t } = useTranslation();

	let path = (menu.path) ? menu.path : '';
	let resolved = useResolvedPath(path);
  let match = useMatch({ path: resolved.pathname });
	let location = useLocation();
	
	if (menu.is_header || menu.is_divider) {
		return '';
	}
  
	let match2 = matchPath({path: path, end: false, },location.pathname);

	let icon = menu.icon && <div className="menu-icon"><i className={menu.icon}></i></div>;
	let img = menu.img && <div className="menu-icon-img"><img src={menu.img} alt="" /></div>;
	let caret = (menu.children && !menu.badge) && <div className="menu-caret"><b className="caret"></b></div>;
	let label = menu.label && <span className="menu-label ms-5px">{menu.label}</span>;
	let badge = menu.badge && <div className="menu-badge">{menu.badge}</div>;
	let highlight = menu.highlight && <i className="fa fa-paper-plane text-theme"></i>;
	// let title = menu.title && <div className="menu-text">{menu.title} {label} {highlight}</div>;
	let title = menu.title && <div className="menu-text">{t(menu.title)} {highlight} </div>;

	
	return (
		<div className={'menu-item' + ((match || match2) ? ' active' : '') + (menu.children ? ' has-sub' : '')}>
			<NavLink className="menu-link" to={menu.path} {...props}>
				{ img } { icon } { title }{ caret } { badge }
			</NavLink>

			
		
			{menu.children && (
				<div className="menu-submenu">
					{menu.children?.map((submenu, i) => (
						(submenu.isActive === true )?
						<NavItem key={i} menu={submenu} /> : null
					))}
				</div>
			)}
		</div>
	);
}

function TopNavNav() {
	// function handle menu button action - next / prev
	const handleMenuButtonAction = (element, direction) => {
		var obj = element.closest('.menu');
		var objStyle = window.getComputedStyle(obj);
		var bodyStyle = window.getComputedStyle(document.querySelector('body'));
		var targetCss = (bodyStyle.getPropertyValue('direction') === 'rtl') ? 'margin-right' : 'margin-left';
		var marginLeft = parseInt(objStyle.getPropertyValue(targetCss));  
		var containerWidth = document.querySelector('.app-top-nav').clientWidth - document.querySelector('.app-top-nav').clientHeight * 2;
		var totalWidth = 0;
		var finalScrollWidth = 0;
		var controlPrevObj = obj.querySelector('.menu-control-start');
		var controlPrevWidth = (controlPrevObj) ? controlPrevObj.clientWidth : 0;
		var controlNextObj = obj.querySelector('.menu-control-end');
		var controlNextWidth = (controlPrevObj) ? controlNextObj.clientWidth : 0;
		var controlWidth = controlPrevWidth + controlNextWidth;
		
		var elms = [].slice.call(obj.querySelectorAll('.menu-item'));
		if (elms) {
			elms?.map(function(elm) {
				if (!elm.classList.contains('.menu-control')) {
					totalWidth += elm.clientWidth;
				}
				return true;
			});
		}

		switch (direction) {
			case 'next':
				var widthLeft = totalWidth + marginLeft - containerWidth;
				if (widthLeft <= containerWidth) {
					finalScrollWidth = widthLeft - marginLeft - controlWidth;
					setTimeout(function() {
						obj.querySelector('.menu-control.menu-control-end').classList.remove('show');
					}, 300);
				} else {
					finalScrollWidth = containerWidth - marginLeft - controlWidth;
				}

				if (finalScrollWidth !== 0) {
					obj.style.transitionProperty = 'height, margin, padding';
					obj.style.transitionDuration = '300ms';
					if (bodyStyle.getPropertyValue('direction') !== 'rtl') {
						obj.style.marginLeft = '-' + finalScrollWidth + 'px';
					} else {
						obj.style.marginRight = '-' + finalScrollWidth + 'px';
					}
					setTimeout(function() {
						obj.style.transitionProperty = '';
						obj.style.transitionDuration = '';
						obj.querySelector('.menu-control.menu-control-start').classList.add('show');
					}, 300);
				}
				break;
			case 'prev':
				widthLeft = -marginLeft;

				if (widthLeft <= containerWidth) {
					obj.querySelector('.menu-control.menu-control-start').classList.remove('show');
					finalScrollWidth = 0;
				} else {
					finalScrollWidth = widthLeft - containerWidth + controlWidth;
				}
				
				obj.style.transitionProperty = 'height, margin, padding';
				obj.style.transitionDuration = '300ms';
				
				if (bodyStyle.getPropertyValue('direction') !== 'rtl') {
					obj.style.marginLeft = '-' + finalScrollWidth + 'px';
				} else {
					obj.style.marginRight = '-' + finalScrollWidth + 'px';
				}
				
				setTimeout(function() {
					obj.style.transitionProperty = '';
					obj.style.transitionDuration = '';
					obj.querySelector('.menu-control.menu-control-end').classList.add('show');
				}, 300);
				break;
			default:
				break;
		}
	}
	
	const handleButtonClick = (event, action) => {
		handleMenuButtonAction(event.target, action);
	}




	const SysName = useGetSysName();
const SystemMenu = menus?.find(item => item.System === SysName )
  const filteredMenu = useMemo(() => {
    return SystemMenu?.menu?.filter(
      (item) =>
        // item.title === "Navigation" ||
        item.title === "HomePage" ||
        item.children?.some((child) => child.isActive) ||
        item.isActive
    );
  }, [SystemMenu]);

	
	return (
		<div className="menu ">
			{filteredMenu?.map((menu, i) => (
				<NavItem key={i} menu={menu} />
			))}
			<div className="menu-item menu-control menu-control-start">
				<button className="btn btn-link menu-link" onClick={(event) => handleButtonClick(event, 'prev')}><i className="bi bi-caret-left"></i></button>
			</div>
			<div className="menu-item menu-control menu-control-end">
				<button className="btn btn-link menu-link" onClick={(event) => handleButtonClick(event, 'next')}><i className="bi bi-caret-right"></i></button>
			</div>
		</div>
	);
}

export default TopNavNav;