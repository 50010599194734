import React from 'react';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { Form } from 'react-bootstrap';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../store/hooks';
import { useFormik } from 'formik';
import useGetChartAccountsDetailsList from '../../../../hooks/Finance/useGetChartAccountsDetailsList';
import { ChartAccountsDetailsModels } from '../../../../store/Finance/ChartAccounts/ChartAccountsModels';
import {
  AddGlChartOfAccountsDetails,
  UpdatGlChartOfAccountsDetails,
} from '../../../../store/Finance/ChartAccounts/ChartAccountsDetailsSlice';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import { Card, CardFooter, CardHeader } from '../../../../components/card/card';

type Props = {
  treeDetails: ChartAccountsDetailsModels;
  breadcrumb: any[];
  isAdd: boolean;
};

const CompaniesAndBranchesForm: React.FC<Props> = ({ treeDetails, breadcrumb, isAdd }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  // const { ChartAccountsId } = useParams();
  const { ChartAccountsDetailsList: data } = useGetChartAccountsDetailsList();

  //#region GetNextAccountNumber
  function getNextAccountNumber(item: ChartAccountsDetailsModels) {
    const filteredAccounts = data?.filter(
      (account) => account.accountParant === item.id && account.accountType !== 'Future'
    );

    if (item.accountType === 'Company') {
      // Find the maximum account number among the children
      const maxAccountNumber = filteredAccounts.reduce((max, account) => {
        const accountNumber = parseInt(account.accountNumber);
        return accountNumber > max ? accountNumber : max;
      }, 0);
      if (maxAccountNumber === 0) {
        return item.accountNumber + '01';
      }
      // Increment the maximum account number by 1
      const nextAccountNumber = (maxAccountNumber + 1).toString().padStart(4, '0');
      return nextAccountNumber;
    }
    // else if (item.accountType === 'Branch') {
    //   // Find the maximum account number among the children
    //   const maxAccountNumber = filteredAccounts.reduce((max, account) => {
    //     const accountNumber = parseInt(account.accountNumber);
    //     return accountNumber > max ? accountNumber : max;
    //   }, 0);
    //   if (maxAccountNumber === 0) {
    //     return item.accountNumber + '01';
    //   }
    //   // Increment the maximum account number by 1
    //   const nextAccountNumber = (maxAccountNumber + 1).toString().padStart(6, '0');
    //   return nextAccountNumber;
    // }
    else if (item.accountType === 'Companies') {
      // Find the maximum account number among the children
      const maxAccountNumber = filteredAccounts.reduce((max, account) => {
        const accountNumber = parseInt(account.accountNumber);
        return accountNumber > max ? accountNumber : max;
      }, 0);

      // Increment the maximum account number by 1
      const nextAccountNumber = (maxAccountNumber + 1).toString().padStart(2, '0');
      return nextAccountNumber;
    }
  }
  //#endregion

  //#region  formik

  const formik = useFormik({
    initialValues: {
      id: isAdd ? null : treeDetails ? treeDetails.id : '',
      name: isAdd ? '' : treeDetails ? treeDetails.name : '',
      name2: isAdd ? '' : treeDetails ? treeDetails.name2 : '',

      chartOfAccountsId: treeDetails ? treeDetails.chartOfAccountsId : '',

      accountNumber: isAdd
        ? getNextAccountNumber(treeDetails)
        : treeDetails
        ? treeDetails.accountNumber
        : '',

      accountParant: isAdd
        ? treeDetails
          ? treeDetails.id
          : ''
        : treeDetails
        ? treeDetails.accountParant
        : '',

      accountType: !isAdd
        ? treeDetails.accountType
        : treeDetails.accountParant === null && treeDetails.accountType === 'Companies'
        ? 'Company'
        : treeDetails.accountParant !== null && treeDetails.accountType === 'Company'
        ? 'Branch'
        : '',

      // enabledFlag: treeDetails ? treeDetails.enabledFlag : false, // تفعيل وغير تفعيل
      enabledFlag: isAdd ? true : treeDetails.enabledFlag, // تفعيل وغير تفعيل

      secondaryFlag: true, //
      primaryParant: null, //
      budgetingAllowedFlag: false, // لا احتاجه في هذا الفورم .. في الحسابات فقط
      currencyCode: '', // لا احتاجه في هذا الفورم .. في الحسابات فقط
      accountlevel: 0, // لا احتاجه في هذا الفورم .. في الحسابات فقط
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const requestBody = {
        id: values.id,
        name: values.name,
        name2: values.name2,
        chartOfAccountsId: values.chartOfAccountsId,
        accountNumber: values.accountNumber,
        accountParant: values.accountParant,
        accountType: values.accountType,
        secondaryFlag: values.secondaryFlag,
        primaryParant: values.primaryParant,
        // sequenceNum: values.sequenceNum,
        budgetingAllowedFlag: values.budgetingAllowedFlag,
        currencyCode: '',
        enabledFlag: values.enabledFlag,
        accountlevel: values.accountlevel,
      };

      const actionToDispatch = isAdd
        ? AddGlChartOfAccountsDetails(requestBody)
        : UpdatGlChartOfAccountsDetails(requestBody);

      dispatch(actionToDispatch)
        .unwrap()
        .then((res) => {
          if (res?.succeeded === true) {
            CustomAlert({ action: isAdd ? 'Add' : 'Edit' });
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error' });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });

  //#endregion

  console.log(treeDetails);

  return (
    <div>
      <Card>
        <CardHeader className="p-0">
          <div className=" d-flex rounded-0  bg-theme bg-opacity-20  px-3 pt-2">
            <div className="flex-grow-1">
              <Breadcrumb
                BreadcrumbList={breadcrumb}
                subtitle={breadcrumb[breadcrumb?.length - 1]?.name}
              />
            </div>
            {isAdd && <>اضافة فرع جديــد</>}
          </div>
        </CardHeader>
        <Form onSubmit={formik.handleSubmit} className="bg-inverse bg-opacity-5 rounded-3 p-3">
          <div className="row">
            <div className="col-xl-6">
              <div className="form-group mb-3">
                <label className="form-label">الأسم المحلي</label>
                <Form.Control
                  className="form-control"
                  type="text"
                  name="name2"
                  onChange={formik.handleChange}
                  value={formik.values.name2 || ''}
                  isInvalid={!!(formik.touched.name2 && formik.errors.name2)}
                  isValid={formik.touched.name2 && !formik.errors.name2}
                />
                <Form.Control.Feedback type="invalid">{formik.errors.name2}</Form.Control.Feedback>
                <Form.Control.Feedback type="valid">
                  {formik.touched.name2 && !formik.errors.name2}
                </Form.Control.Feedback>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="form-group mb-3">
                <label className="form-label">الأسم العالمي</label>
                <Form.Control
                  className="form-control"
                  type="text"
                  name="name"
                  onChange={formik.handleChange}
                  value={formik.values.name || ''}
                  isInvalid={!!(formik.touched.name && formik.errors.name)}
                  isValid={formik.touched.name && !formik.errors.name}
                />
                <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
                <Form.Control.Feedback type="valid">
                  {formik.touched.name && !formik.errors.name}
                </Form.Control.Feedback>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-4">
              <div className="form-group mb-3">
                <label className="form-label">
                  {treeDetails.accountType === 'Company' && !isAdd
                    ? 'رقم الشركة'
                    : treeDetails.accountType === 'Branch'
                    ? 'رقم الفرع'
                    : treeDetails.accountType === 'Company' && isAdd
                    ? 'رقم الفرع'
                    : ''}
                </label>
                <div className="input-group flex-nowrap">
                  <Form.Control
                    disabled
                    className="form-control text-center"
                    type="text"
                    name="accountNumber"
                    onChange={formik.handleChange}
                    value={formik.values.accountNumber || ''}
                    isInvalid={!!(formik.touched.accountNumber && formik.errors.accountNumber)}
                  />
                </div>
              </div>
            </div>

            <div className="col-xl-6">
              <div className="form-group mb-3">
                <label className="form-label"> الحسـاب الرئيسي </label>
                <Form.Select
                  disabled
                  className="form-select"
                  name="accountParant"
                  onChange={formik.handleChange}
                  value={formik.values.accountParant || ''}
                  isInvalid={!!(formik.touched.accountParant && formik.errors.accountParant)}>
                  <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                  {data &&
                    data?.map((item, idx) => (
                      <option key={++idx} value={item.id}>
                        {i18n.language === 'en' ? item.name : item.name2}
                      </option>
                    ))}
                </Form.Select>
              </div>
            </div>

            <div className="col-xl-2">
              <div className="form-group mb-3">
                <label className="form-label" style={{ margin: '0px 22px' }}>
                  {t('Active')}
                </label>
                <Form.Check
                  className="ps-5"
                  style={{ margin: '10px 20px' }}
                  type="checkbox"
                  label=""
                  name="enabledFlag"
                  onChange={formik.handleChange}
                  checked={formik.values.enabledFlag}
                />
              </div>
            </div>
          </div>

          <CardFooter className=" bg-theme bg-opacity-10">
            <div className="text-center">
              {!isAdd && (
                <button
                  disabled={formik.isSubmitting || !treeDetails.enabledFlag}
                  type="submit"
                  className="btn me-1 btn-theme mb-1">
                  {formik.isSubmitting ? (
                    <div className="spinner-border spinner-border-sm me-2"></div>
                  ) : (
                    <i className="fa fa-save fa-fw me-2"></i>
                  )}
                  {t('Edit')}
                </button>
              )}

              {isAdd && (
                <button
                  disabled={formik.isSubmitting}
                  type="submit"
                  className="btn me-1 btn-theme mb-1">
                  {formik.isSubmitting ? (
                    <div className="spinner-border spinner-border-sm me-2"></div>
                  ) : (
                    <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
                  )}
                  {t('Add')}
                </button>
              )}
            </div>
          </CardFooter>
        </Form>
      </Card>
    </div>
  );
};

export default CompaniesAndBranchesForm;

const validationSchema = Yup.object().shape({
  name: Yup.string().required('*'),
  name2: Yup.string().required('*'),
});
