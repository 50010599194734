import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { GetScanningElementList } from "../../../../store/FLEET/FleetScan/fleetScanSlice";
import { useFormik } from "formik";
import Breadcrumb from "../../../../components/Shared/Breadcrumb";
import { Card, CardBody } from "../../../../components/card/card";
import { Form } from "react-bootstrap";
import { GetNtxPeoplesList } from "../../../../store/NTX/Peoples/peoplesSlice";
import { useTranslation } from "react-i18next";
// import * as Yup from "yup";
import { AddFleetInspectionSchema } from "../../../ValidationForm/validationSchema";
import { AddPeriodicallyScanning } from "../../../../store/FLEET/EquipmentInspection/equipmentInspectionSlice";
import CustomAlert from "../../../../components/Shared/Alert/CustomAlert";
import { useNavigate, useParams } from "react-router-dom";
import useGetEquipmentsList from "../../../../hooks/FLeet/useGetEquipmentsList";

const AddScan = () => {
  const [date, setDate] = useState("");
  const Navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { fleetEquipmentsId } = useParams();
  useEffect(() => {
    dispatch(GetScanningElementList());
    dispatch(GetNtxPeoplesList(""));
  }, [dispatch]);

  useEffect(() => {
    // Set the initial date to today
    const today = new Date();
    const formattedDate = today.toISOString().split("T")[0];
    setDate(formattedDate);
  }, []); // Empty dependency array ensures this effect runs once on mount

  const { EquipmentsList } = useGetEquipmentsList()
  const { PeoplesList } = useAppSelector((state) => state.Peoples);
  const { ScanElementList } = useAppSelector((state) => state.FleetScan);
  const EquipmentData = EquipmentsList?.find((f) => f.id === fleetEquipmentsId);

  const formik = useFormik({
    initialValues: {
      fleetEquipmentsId: fleetEquipmentsId,
      driverId: "",
      scanningCode: "",
      scanningDate: date,
      description: "",
      details: ScanElementList?.map((item) => ({
        // scanningMasterId: null,
        scanningElementId: item.id,
        value: "",
        description: "",
      })),
    },
    enableReinitialize: true,
    validationSchema: AddFleetInspectionSchema(t),
    onSubmit: (values) => {
      dispatch(
        AddPeriodicallyScanning({
          fleetEquipmentsId: values.fleetEquipmentsId,
          driverId: values.driverId,
          scanningCode: values.scanningCode,
          scanningDate: values.scanningDate,
          description: values.description,
          details: values.details?.map((m) => ({
            // scanningMasterId: null,
            scanningElementId: m.scanningElementId,
            value: m.value === "1" ? true : false,
            description: m.description,
          })),
        })
      )
        .unwrap()
        .then((res) => {
          if (res?.succeeded === true) {
            formik.resetForm();
            CustomAlert({ action: "Add" });
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: "Error", msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: "Error" });
        });
    },
  });


  //________________________________
  var BcrumbList = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "EquipmentInspection",
      link: "/FLEET/EquipmentInspection",
    },
    {
      name: i18n.language === "ar" ? EquipmentData?.name2 : EquipmentData?.name,
      link: null,
    },
  ];

  return (
    <div>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName="EquipmentInspection" />

      <Card>
        <CardBody className="p-4">
          <div className="table-responsive text-center rounded-top-2 pb-4">
            <table className="table table-bordered bg-inverse bg-opacity-10 ">
              <thead className="">
                <tr className="bg-inverse bg-opacity-15">
                  <th scope="col">{t("EquipmentName")}</th>
                  <th scope="col">{t("Plate_Number")}</th>
                  <th scope="col">{t("AssetNumber")}</th>
                  <th scope="col">{t("ServiceDate")}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="align-middle">
                    {i18n.language === "ar"
                      ? EquipmentData?.name2
                      : EquipmentData?.name}
                  </td>
                  <td className="align-middle">{EquipmentData?.plateNo}</td>
                  <td className="align-middle">{EquipmentData?.code}</td>
                  <td className="align-middle">{EquipmentData?.serviceDate}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <Form onSubmit={formik.handleSubmit}>
            <div className="row mb-4">
              <div className="col-xl-4">
                <div className="input-group c">
                  <span className="input-group-text">{t("Driver")}</span>
                  <Form.Select
                    className="form-select"
                    name="driverId"
                    onChange={formik.handleChange}
                    value={formik.values.driverId || ""}
                    isInvalid={
                      !!(formik.touched.driverId && formik.errors.driverId)
                    }
                  >
                    <option value="">
                      {i18n.language === "ar" ? "إختر" : "Choose"}
                    </option>
                    {PeoplesList &&
                      PeoplesList?.filter((f) => f.typeCode === "DRIVERS")?.map(
                        (item, idx) => (
                          <option key={++idx} value={item.id}>
                            {i18n.language === "ar" ? item.name2 : item.name}
                          </option>
                        )
                      )}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.driverId}
                  </Form.Control.Feedback>
                </div>
              </div>

              <div className="col-xl-4">
                <div className="input-group c">
                  <span className="input-group-text">
                    {t("InspectionDate")}
                  </span>

                  <Form.Control
                    className="form-control"
                    type="date"
                    name="scanningDate"
                    onChange={formik.handleChange}
                    value={formik.values.scanningDate}
                    isInvalid={
                      !!(
                        formik.touched.scanningDate &&
                        formik.errors.scanningDate
                      )
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.scanningDate}
                  </Form.Control.Feedback>
                </div>
              </div>

              <div className="col-xl-4">
                <div className="input-group c">
                  <span className="input-group-text">{t("Note")}</span>

                  <Form.Control
                    className="form-control"
                    type="text"
                    name="description"
                    onChange={formik.handleChange}
                    value={formik.values.description}
                    isInvalid={
                      !!(
                        formik.touched.description && formik.errors.description
                      )
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.description}
                  </Form.Control.Feedback>
                </div>
              </div>
            </div>

            {ScanElementList &&
              ScanElementList?.map((item, index) => (
                <div
                  key={index}
                  className="row mb-2 bg-inverse bg-opacity-10 rounded-3 p-2"
                >
                  <div className="col-xl-2">
                    {i18n.language === "en" ? item.name : item.name2}
                  </div>
                  <div className="col-xl-5">
                    {i18n.language === "en"
                      ? item.description
                      : item.description2}
                  </div>

                  <div className="col-xl-2 bg-inverse bg-opacity-10 rounded-3 pt-3 text-center">
                    <div className="form-group mb-3">
                      <div className="form-check form-check-inline">
                        <input
                          // style={{backgroundColor : "green"}}
                          className="form-check-input"
                          name={`details[${index}].value`}
                          type="radio"
                          value="1"
                          onChange={formik.handleChange}
                        />
                        <label className="form-check-label">نعم</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          name={`details[${index}].value`}
                          type="radio"
                          value="0"
                          onChange={formik.handleChange}
                        />
                        <label className="form-check-label">لا</label>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-3">
                    <div className="input-group">
                      <Form.Control
                        className="form-control"
                        as="textarea"
                        rows={2}
                        name={`details[${index}].description`}
                        onChange={formik.handleChange}
                        placeholder="ملاحظات .."
                      />
                    </div>
                  </div>
                </div>
              ))}

            <div className="text-danger">
              {formik.touched.details &&
                formik.errors.details?.length &&
                `*${t("AllItemsRequired")}`}
            </div>
            <div className="text-center">
              <button
                disabled={formik.isSubmitting}
                type="submit"
                className="btn me-1 btn-theme mb-1"
              >
                {formik.isSubmitting ? (
                  <div className="spinner-border spinner-border-sm me-2"></div>
                ) : (
                  <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
                )}
                {t("Add")}
              </button>
              <button
                type="button"
                className=" btn me-1 btn-default mb-1"
                onClick={() => Navigate(-1)}
              >
                <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
              </button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
};

export default AddScan;
