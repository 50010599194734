import { useFormik } from 'formik';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../store/hooks';
import { Form } from 'react-bootstrap';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import { addPosCashers } from '../../../../store/Sales/Pos/PosCashierSlice';
import useGetPersons from '../../../../hooks/HR/use-get-persons';
import Loading from '../../../../components/Shared/Loader/Loading';


type Props = {
  posId: string;
};

const PosCashier = ({ posId }: Props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();

  const { dataEmployees, loading: loading2 , error } = useGetPersons();


  const formik = useFormik({
    initialValues: {
      posId: posId,
      personId: '',
      startDate: '',
      endDate: '',
    },

    // validationSchema: formOrganization(t),
    enableReinitialize: true,
    onSubmit: (values) => {
      dispatch(
        addPosCashers({
          posId: values.posId,
          personId: values.personId,
          startDate: values.startDate,
          endDate: values.endDate,
        })
      )
        .unwrap()
        .then((res) => {
          if (res.succeeded === true) {
            formik.resetForm();
            // document.getElementById("AddPriceList")?.click();
            CustomAlert({ action: 'Add' });
            formik.setSubmitting(false);
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error' });
          }
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
      formik.setSubmitting(false);
    },
  });

  return (
    <Loading loading={loading2} error={error}>
      <div>
        <Form onSubmit={formik.handleSubmit}>
          <div className="row mb-3">
            
            <div className="col-lg-6">
              <div className="form-group mb-3">
                <label className="form-label">{t('الكاشيــر')}</label>
                <Select
                  classNamePrefix="react-select"
                  isLoading={loading2}
                  isSearchable={true}
                  isClearable
                  name="personId"
                  options={dataEmployees}
                  onChange={(option: any) => {
                    formik.setFieldValue('personId', option === null ? null : option.value);
                  }}
                  placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                />
              </div>
            </div>

            <div className="col-lg-3">
              <div className="form-group mb-3">
                <label className="form-label">{t('من تاريــخ')}</label>
                <Form.Control
                  className="form-control  "
                  type="date"
                  name="startDate"
                  onChange={formik.handleChange}
                  value={formik.values.startDate}
                  isInvalid={!!(formik.touched.startDate && formik.errors.startDate)}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.startDate}
                </Form.Control.Feedback>
              </div>
            </div>

            <div className="col-lg-3">
              <div className="form-group mb-3">
                <label className="form-label">{t('الى تاريــخ')}</label>
                <Form.Control
                  className="form-control  "
                  type="date"
                  name="endDate"
                  onChange={formik.handleChange}
                  value={formik.values.endDate}
                  isInvalid={!!(formik.touched.endDate && formik.errors.endDate)}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.endDate}
                </Form.Control.Feedback>
              </div>
            </div>
          </div>

          <div className="text-center">
            <button
              disabled={formik.isSubmitting}
              type="submit"
              className="btn me-1 btn-theme mb-1"
              id="SaveSubmit">
              {formik.isSubmitting ? (
                <div className="spinner-border spinner-border-sm me-2"></div>
              ) : (
                <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
              )}
              {t('Add')}
            </button>
            {/* <button
            type="button"
            className=" btn me-1 btn-default mb-1"
            data-bs-dismiss="modal"
            id="closeModalForm">
            <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
          </button> */}
          </div>
        </Form>

        {/* <div className="table-responsive rounded-2 text-center border border-theme">
          <table className="table ">
            <thead className="bg-theme bg-opacity-60">
              <tr>
                <th scope="col">personId</th>
                <th scope="col">{t('startDate')}</th>
                <th scope="col">{t('endDate')}</th>
                <th scope="col"></th>
              </tr>
            </thead>

            <tbody>
              {PosCashierListByPosId?.map((item, index) => (
                <tr key={index}>
                  <td>{i18n.language === 'en' ? item.personName : item.personName}</td>
                  <td>{item.StartDate}</td>
                  <td>{item.EndDate}</td>
                  <td>
                    <Link
                      to=""
                      style={{ color: 'red' }}
                      // onClick={() => handleDeleteRowClick(index)}
                    >
                      <i className="fas fa-lg fa-fw me-3 fa-trash-alt"></i>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div> */}
      </div>
    </Loading>
  );
};

export default PosCashier;
