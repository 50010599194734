import React, { useEffect, useState } from "react";
import { Card, CardBody } from "../../../../../components/card/card";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  DeleteFlexValue,
  GetFlexValue,
  insertFlexValue,
} from "../../../../../store/NTX/FlexValue/FlixValueSlice";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import Loader from "../../../../../components/Shared/Loader/Loader";
import CustomAlert from "../../../../../components/Shared/Alert/CustomAlert";
import { AddFlexValueSchema } from "../../../../ValidationForm/validationSchema";
import Breadcrumb from "../../../../../components/Shared/Breadcrumb";
import showConfirmation from "../../../../../components/Shared/Alert/Confirmation";

export const AddSubLists = () => {
  const Navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();

  const [dataByFlexValue, setDataByFlexValue] = useState([]);

  useEffect(() => {
    dispatch(GetFlexValue(id));
  }, [dispatch, id]);
  const { FlexValueList, loading } = useSelector((state) => state.FlexValue);
  // const data = FlexValueList && FlexValueList?.filter((f) => f.parentId === null);

  const formik = useFormik({
    initialValues: {
      name2: "",
      name: "",
      parentId: "",
      imgUrl: "",
    },
    validationSchema: AddFlexValueSchema(t),
    onSubmit: (values, { resetForm }) => {
      dispatch(
        insertFlexValue({
          flexValueSetId: id,
          name2: values.name2,
          name: values.name,
          parentId: values.parentId,
          imgUrl: values.imgUrl,
          enabledFlag: true,
        })
      )
        .unwrap()
        .then(() => {
          CustomAlert({ action: "Add" });
          resetForm();
        })
        .catch((error) => {
          console.log(error);
          CustomAlert({ action: "Error" });
        });
    },
  });

  // ______ handle Delete Flex Value
  const handleDeleteFlexValue = (item) => {
    showConfirmation(t("MsgConfirm"), () => {
      dispatch(DeleteFlexValue(item.id))
        .unwrap()
        .then(() => {
          dispatch(GetFlexValue(id));
          CustomAlert({ action: "Delete" });
        })
        .catch((error) => {
          console.log(error);
          CustomAlert({ action: "Error" });
        });
    });
  };

  //_______________________

  // const [selectedValue, setSelectedValue] = useState("");

  useEffect(() => {
    const filteredDataByFlexValue = FlexValueList?.filter(
      (item) => item.parentId === formik.values.parentId
    );
    setDataByFlexValue(filteredDataByFlexValue);
  }, [FlexValueList, formik.values.parentId]);

  //________________________________
  var BcrumbList = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: t("SystemLists"),
      link: "/NTX/ListSystem",
    },
    {
      name: FlexValueList[0]?.flexValueSetName,
      link: null,
    },
  ];

  return (
    <>
      {/* <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#/">{t("Home")}</a>
            </li>
            <li className="breadcrumb-item active">{t("Add System List")}</li>
          </ul>
          <h1 className="page-header mb-0">{t("Add System List")}</h1>
        </div>
      </div> */}

      <Breadcrumb BreadcrumbList={BcrumbList} PageName={t("Sub_Lists")} />

      <Card>
        <CardBody>
          <Loader loading={loading} />

          <Form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-xl-4">
                <div className="form-group mb-3">
                  <label className="form-label">{t("LocalName")}</label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="name2"
                    onChange={formik.handleChange}
                    value={formik.values.name2}
                    isInvalid={formik.touched.name2 && formik.errors.name2}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.name2}
                  </Form.Control.Feedback>
                </div>
              </div>

              <div className="col-xl-4">
                <div className="form-group mb-3">
                  <label className="form-label">{t("GlobalName")}</label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="name"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    isInvalid={formik.touched.name && formik.errors.name}
                    // required
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.name}
                  </Form.Control.Feedback>
                </div>
              </div>

              <div className="col-xl-4">
                <div className="form-group mb-3">
                  <label className="form-label">.</label>
                  <Form.Select
                    className="form-select"
                    name="parentId"
                    onChange={formik.handleChange}
                    value={formik.values.parentId}
                    isInvalid={
                      formik.touched.parentId && formik.errors.parentId
                    }
                  >
                    <option value={""} key={null}>
                      {i18n.language === "ar" ? "إختر" : "Choose"}
                    </option>
                    {FlexValueList &&
                      FlexValueList?.filter((f) => f.parentId === null)?.map(
                        (item) => (
                          <option key={item.id} value={item.id}>
                            {i18n.language === "ar" ? item.name2 : item.name}
                          </option>
                        )
                      )}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.parentId}
                  </Form.Control.Feedback>
                </div>
              </div>
            </div>

            <div className="text-center">
              <button
                disabled={formik.isSubmitting}
                type="submit"
                className="btn me-1 btn-outline-theme mb-1"
              >
                <i className="fas fa-plus fa-fw me-1"></i>
                {formik.isSubmitting ? "Adding..." : t("Add")}
              </button>
              <button
                type="button"
                className=" btn me-1 btn-outline-default mb-1"
                onClick={() => Navigate(-1, { replace: true })}
              >
                <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
              </button>
            </div>
          </Form>

          <hr className="mb-2" />

          <div className="row text-center">
            <div className="col-lg-10 mx-auto p-0 m-0 bg-inverse bg-opacity-10 rounded-4">
              <div className="table-responsive rounded-4 ">
                <table className="table">
                  <thead className="table-dark">
                    <tr>
                      {/* <th scope="col">الرقم</th> */}
                      <th scope="col">{t("Value")}</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>

                  <tbody>
                    {dataByFlexValue?.map((item, idx) => (
                      <tr key={item.id}>
                        {/* <td className="align-middle">{++idx}</td> */}
                        <td className="align-middle">
                          {i18n.language === "ar" ? item.name2 : item.name}
                        </td>
                        <td className="align-middle">
                          <button
                            type="button"
                            className="btn btn-outline-danger m-0"
                            onClick={() => handleDeleteFlexValue(item)}
                          >
                            <i className="fas fa-trash-alt fa-fw fa-lg"></i>
                            {/* {t('Button.Delete')} */}
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};
