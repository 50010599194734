import { useFormik } from 'formik';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import Select from 'react-select';
import { Card, CardFooter } from '../../../../components/card/card';
import { CardBody, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useLookupCurrency from '../../../../hooks/Lookups/use-Lookup-Currency';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { useAppDispatch } from '../../../../store/hooks';
import SwalAlert from '../../../../components/Shared/Alert/SwalAlert';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Loading from '../../../../components/Shared/Loader/Loading';
import { addApInvoices } from '../../../../store/Finance/AP/ApInvoices/ApInvoicesSlice';
import useGetSuppliersList from '../../../../hooks/NTX/Peoples/useGetSuppliersList';
import { TreeSelect } from 'antd';
import useGetPaymentTermsList from '../../../../hooks/Sales/useGetItemsList';
import useGetPaymentMethod from '../../../../hooks/Payrolls/use-Get-PaymentMethod';
import ApInvoiceLines from './ApInvoiceLines';
import ApPaymentSchedules from './ApPaymentSchedules';
import useGetOrganizationWithPermissionByType from '../../../../hooks/NTX/Organization/useGetOrganizationWithPermissionByType';
import confirmAction from '../../../../components/Shared/Alert/confirmAction';
import useGetGlDailyRatesList from '../../../../hooks/Finance/DailyRates/useGetGlDailyRatesList';
import useGetGlLedgersList from '../../../../hooks/Finance/useGetGlLedgersList';
import useGetConsolidationSet from '../../../../hooks/Finance/ConsolidationSet/useGetConsolidationSet';
import { useEffect, useState } from 'react';
import useGetPurchaseTransactionsById from '../../../../hooks/PUR/PurchaseTransactions/useGetPurchaseTransactionsById';
import ApInvoiceItemsLines from './ApInvoiceItemsLines';
import { AddApInvoiceSchema } from '../../../ValidationForm/validationSchema';
import { addArTransactions } from '../../../../store/Finance/AR/ArTransactions/ArTransactionsSlice';

const Add = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { Currency, loading: loading4 } = useLookupCurrency();
  const [conversionRate, setConversionRate] = useState<number>(1);
  const { DailyRatesList } = useGetGlDailyRatesList();
  const { LedgersList } = useGetGlLedgersList();
  const { GetLedgarId } = useGetConsolidationSet();
  const { DataOptions: BranchsOptions, loading: loading } =
    useGetOrganizationWithPermissionByType('150');
  const { treeSuppliersData, error: error2, loading: loading2 } = useGetSuppliersList();
  const { PaymentTermsList } = useGetPaymentTermsList();
  const { PaymentMethodList } = useGetPaymentMethod();
  const { referenceId } = useParams();

  // const { PurchaseRequestsById: data } = useGetPurchaseRequestById({ id: referenceId }); // طلب شراء
  const { TransactionsById: data } = useGetPurchaseTransactionsById({ id: referenceId }); //

  //#region Formik
  const formik = useFormik({
    initialValues: {
      peopleId: data.peopleId ?? '',
      referenceType: data.code ?? '', // نوع المرجع - حسب ما يأتي
      referenceId: data.id ?? '',
      organizationId: data.organizationId ?? '',
      paymentTermsId: '',
      paymentMethodId: data.paymentMethodId ?? '',
      currencyCode: data.currencyCode ?? 'SR',
      currencyConversionRate: data.currencyConversionRate ?? 1,
      transactionNum: '',
      transactionType: '',
      salesrepId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
      transactionDate: '2024-09-24T14:04:44.044Z',
      billToCustomerId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
      shipToCustomerId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
      transactionStatus: 0,
      freightAmount: 0,

      arTransactionsLinesList:
        data.transactionsLinesList?.map((line) => ({
          lineNumber: line.lineNumber,
          lineType: 'Item_Inv',
          invItemId: line.itemId,
          uom: line.uom,
          description: line.description,
          price: line.price,
          qty: line.qty,
          discountRate: line.discountRate,
          discountAmount: line.discountAmount,
          vatRate: line.vatRate,
          total: line.total,
          vatAmount: line.vatAmount,
          accountId: null,
          costCenterId: null,
          currencyCode: data.currencyCode ?? '',
          currencyConversionRate: data.currencyConversionRate ?? 1,
          salHeaderId: data.id ?? '',
          salLineId: line.id,
          codeCombination: '',
          unitCost: 0,
        })) ??
        [
          // {
          //   lineNumber: '',
          //   lineType: 'Item',
          //   invItemId: null,
          //   uom: null,
          //   description: '',
          //   price: 0,
          //   qty: 0,
          //   discountRate: 0,
          //   discountAmount: 0,
          //   vatRate: 0,
          //   total: 0,
          //   vatAmount: 0,
          //   accountId: null,
          //   costCenterId: null,
          //   currencyCode: data.currencyCode ?? '',
          //   currencyConversionRate: data.currencyConversionRate ?? '',
          //   purHeaderId: null,
          //   purLineId: null,
          //   codeCombination: '',
          // },
        ],
      apPaymentSchedulesList: [],
    },

    enableReinitialize: true,
    // validationSchema: AddApInvoiceSchema(t),
    onSubmit: (values) => {
      // const { transactionsLinesList, ...restValues } = values;

      if (values.arTransactionsLinesList?.length === 0) {
        SwalAlert({ text: '' });
        formik.setSubmitting(false);
        return;
      }

      dispatch(
        addArTransactions({
          salesrepId: null,
          billToCustomerId: null,
          shipToCustomerId: null,
          transactionStatus: 0,
          freightAmount: 0,

          transactionNum: values.transactionNum,
          peopleId: values.peopleId,
          transactionType: values.transactionType,
          referenceType: values.referenceType,
          referenceId: values.referenceId || null,
          transactionDate: values.transactionDate,
          organizationId: values.organizationId,
          paymentTermsId: values.paymentTermsId,
          paymentMethodId: values.paymentMethodId,
          currencyCode: values.currencyCode,
          currencyConversionRate: values.currencyConversionRate || 1,
          arTransactionsLinesList: values.arTransactionsLinesList?.map((line, idx) => ({
            // lineNumber: line.lineNumber ,
            lineNumber: ++idx,
            lineType: line.lineType,
            invItemId: line.invItemId,
            uom: line.uom || null,
            description: line.description,
            price: line.price,
            qty: line.qty,
            discountRate: line.discountRate,
            discountAmount: line.discountAmount,
            vatRate: line.vatRate,
            total: line.total,
            vatAmount: line.vatAmount,
            accountId: line.accountId,
            costCenterId: line.costCenterId,
            currencyCode: line.currencyCode,
            currencyConversionRate: line.currencyConversionRate || 1,
            salHeaderId: line.salHeaderId,
            salLineId: line.salLineId,
            unitCost: 0,
          })),
          arReceiptSchedulesList:
            values.apPaymentSchedulesList?.map((item: any) => ({
              amount: item.amount,
              dueDate: item.dueDate,
            })) || null,
        })
      )
        .unwrap()
        .then((res: any) => {
          debugger;
          // console.log(res);

          if (res?.succeeded === true) {
            CustomAlert({ action: 'Add' });
            formik.resetForm();
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error', msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });
  const { values, handleChange: formikhandleChange, errors, touched, setFieldValue } = formik;
  //#endregion

  // console.log(LedgersList?.find((f) => f.id === GetLedgarId(values.organizationId))?.currencyCode);
  useEffect(() => {
    const GetConversionRate = () => {
      let rate = 1;

      // ابحث عن الدفتر (ledger) بناءً على organizationId
      const ledger = LedgersList?.find((f) => f.id === GetLedgarId(values.organizationId));

      if (!ledger) {
        return rate; // إذا لم يتم العثور على دفتر، العودة مع معدل 1
      }

      const currencyCodeFrom = ledger.currencyCode;
      const currencyCodeTo = values.currencyCode;
      const JuDate = new Date(values.transactionDate);

      // إذا كانت المعلومات غير كاملة، العودة بمعدل 1
      if (!currencyCodeFrom || !currencyCodeTo || !JuDate) {
        return rate;
      }

      // إذا كان نفس العملة، العودة بمعدل 1
      if (currencyCodeFrom === currencyCodeTo) {
        return rate;
      }

      // البحث عن معدل التحويل في القائمة
      const foundRate = DailyRatesList?.find((rate) => {
        const startDate = new Date(rate.conversionStartDate);
        const endDate = rate.conversionEndDate ? new Date(rate.conversionEndDate) : null;

        return (
          rate.fromCurrencyCode === currencyCodeFrom &&
          rate.toCurrencyCode === currencyCodeTo &&
          startDate <= JuDate &&
          (!endDate || endDate >= JuDate)
        );
      });

      // إذا تم العثور على معدل تحويل، يتم تعيينه
      if (foundRate) {
        rate = foundRate.conversionRate;
      }

      return rate;
    };

    const conversionRate = GetConversionRate();
    // debugger
    setConversionRate(conversionRate); // تحديث معدل التحويل

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.currencyCode, values.transactionDate, values.organizationId]);
  // console.log(conversionRate);

  const handleChangeOrganizationId = async (option: any) => {
    // فحص اذا كانت هناك بنود في الفاتورة
    if (values.arTransactionsLinesList?.length > 0) {
      let isConfirmation = await confirmAction(
        'هل تريد المتابعة؟ سيتم حذف الحسابات ومراكز التكلفة والفروع والشركات المرتبطة بجميع البنود في الفاتورة. لا يمكن التراجع عن هذه العملية.'
      );
      if (!isConfirmation) {
        setFieldValue('organizationId', null);
        return;
      } else {
        setFieldValue('organizationId', option === null ? null : option.value);
        const updatedLines = values.arTransactionsLinesList?.map((item) => ({
          ...item,
          accountId: null,
          costCenterId: null,
          companyId: null,
          branchId: null,
          codeCombination: '',
        }));
        setFieldValue('apInvoiceLinesList', updatedLines);
      }
    } else {
      setFieldValue('organizationId', option === null ? null : option.value);
    }
  };

  //#region BcrumbList
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'Ap Invoices',
      link: '/Finance/Ar/ArTransactions',
    },
    {
      name: 'Add ArTransactions',
      link: null,
    },
  ];
  //#endregion



  return (
    <>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName="Add ArTransactions" />
      </div>

      <Card>
        <Loading loading={loading || loading2 || loading || loading4}>
          <CardBody>
            <Form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-lg-2">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('التاريخ')}</label>
                    <Form.Control
                      className="form-control "
                      type="date"
                      name="transactionDate"
                      onChange={formikhandleChange}
                      value={values.transactionDate || ''}
                      // isInvalid={!!(touched.transactionDate && errors.transactionDate)}
                    />

                    <div className="text-danger small">{formik.errors.transactionDate}</div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label"> {t('توع الفاتورة - invoiceType')} </label>
                    <Form.Select
                      className="form-select"
                      name="transactionType"
                      onChange={formikhandleChange}
                      value={values.transactionType || ''}
                      // isInvalid={!!(touched.transactionType && errors.transactionType)}
                    >
                      <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                      <option value="standard">
                        {i18n.language === 'en' ? 'Standard' : 'قياسي'}
                      </option>
                      <option value="debit_memo">
                        {i18n.language === 'en' ? 'Debit Memo' : 'إشعار مدين'}
                      </option>
                      <option value="Recurring">
                        {i18n.language === 'en' ? 'Recurring' : 'دوري'}
                      </option>
                    </Form.Select>
                    <div className="text-danger small">{formik.errors.transactionType}</div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('peopleId  ')}</label>
                    <TreeSelect
                      showSearch
                      className="w-100"
                      treeLine
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                      allowClear
                      treeDefaultExpandAll={false} // لا تقم بتوسيع كل العقد افتراضيًا
                      // loadData={} // يتم تحميل البيانات عند الطلب
                      // onSearch={} // تنفيذ البحث عند كتابة المستخدم
                      value={values.peopleId}
                      onChange={(option) => {
                        formik.setFieldValue('peopleId', option === null ? null : option);
                      }}
                      treeData={treeSuppliersData}
                      treeNodeFilterProp="title"
                    />
                    <div className="text-danger small">{formik.errors.peopleId}</div>
                  </div>
                </div>


                <div className="col-lg-3">
                  <div className="form-group flex-nowrap mb-3">
                    <label className="form-label">{t('رقم العملية transaction Num')}</label>

                    <div className="input-group flex-nowrap">
                      <Form.Control
                        autoComplete="off"
                        className="form-control text-center"
                        type="text"
                        name="transactionNum"
                        onChange={formikhandleChange}
                        value={values.transactionNum || ''}
                        // isInvalid={!!(touched.transactionNum && errors.transactionNum)}
                      />
                      {/* <div className="text-danger small">{formik.errors.transactionNum}</div> */}
                    </div>
                    <div className="text-danger small">{formik.errors.transactionNum}</div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('الفرع')}</label>

                    <Select
                      classNamePrefix="react-select"
                      isLoading={loading}
                      isSearchable={true}
                      isClearable
                      options={BranchsOptions}
                      value={BranchsOptions?.find(
                        (f: any) => f.value === formik.values.organizationId
                      )}
                      onChange={(option: any) => handleChangeOrganizationId(option)}
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                    />
                    <div className="text-danger small">{formik.errors.organizationId}</div>
                  </div>
                </div>
                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">شروط الدفع</label>
                    <Form.Select
                      className="form-select"
                      name="paymentTermsId"
                      onChange={formik.handleChange}
                      value={formik.values.paymentTermsId || ''}
                      // isInvalid={!!(formik.errors.paymentTermsId && formik.touched.paymentTermsId)}
                    >
                      <option value={''}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                      {PaymentTermsList &&
                        PaymentTermsList?.map((item: any, idx: number) => (
                          <option key={++idx} value={item.id}>
                            {i18n.language === 'ar' ? item.name2 : item.name}
                          </option>
                        ))}
                    </Form.Select>
                    <div className="text-danger small">{formik.errors.paymentTermsId}</div>
                  </div>
                </div>
                <div className="col-xl-2">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('PaymentMethod')}</label>
                    <Form.Select
                      className="form-select"
                      name="paymentMethodId"
                      onChange={formik.handleChange}
                      value={formik.values.paymentMethodId || ''}
                      isInvalid={
                        !!(formik.touched.paymentMethodId && formik.errors.paymentMethodId)
                      }>
                      <option key={null}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>

                      {PaymentMethodList?.map((item: any, idx: number) => (
                        <option key={++idx} value={item.id}>
                          {i18n.language === 'ar' ? item.name : item.name2}
                        </option>
                      ))}
                    </Form.Select>
                    <div className="text-danger small">{formik.errors.paymentMethodId}</div>
                  </div>
                </div>

                <div className="col-lg-2">
                  <div className="form-group mb-3">
                    <label className="form-label"> {t('Currency')} </label>
                    <Form.Select
                      className="form-select"
                      name="currencyCode"
                      onChange={formikhandleChange}
                      value={values.currencyCode}
                      // isInvalid={!!(touched.currencyCode && errors.currencyCode)}
                    >
                      <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>

                      {Currency &&
                        Currency?.map((item: any, idx: number) => (
                          <option key={++idx} value={item.currencyCode}>
                            {item.description}
                          </option>
                        ))}
                    </Form.Select>
                    <div className="text-danger small">{formik.errors.currencyCode}</div>
                  </div>
                </div>

                <div className="col-lg-2">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('معدل العملة')}</label>
                    <Form.Control
                      className="form-control  text-center"
                      type="text"
                      readOnly
                      // name="currencyConversionRate"
                      // onChange={formikhandleChange}
                      // value={values.currencyConversionRate || ''}
                      value={conversionRate}
                    />
                    <div className="text-danger small">{formik.errors.currencyConversionRate}</div>
                  </div>
                </div>
              </div>

              <CardFooter className="mt-2 mb-2">
                <ul className="nav nav-tabs nav-tabs-v2 p-0">
                  <li className="nav-item me-3">
                    <Link to="#apInvoiceLines" className="nav-link active" data-bs-toggle="tab">
                      <i className="fas fa-file-invoice me-2 text-theme"></i>
                      Ap Invoice Lines
                    </Link>
                  </li>
                  <li className="nav-item me-3">
                    <Link to="#apPaymentSchedules" className="nav-link" data-bs-toggle="tab">
                      <i className="fas fa-calendar-alt me-2 text-theme"></i>
                      Ap Payment Schedules
                    </Link>
                  </li>
                </ul>
                <div className="tab-content py-4 px-0">
                  <div className="tab-pane fade show active" id="apInvoiceLines">
                    {/* {Object.keys(data)?.length === 0 ? (
                      <ApInvoiceLines formik={formik} />
                    ) : (
                      <ApInvoiceItemsLines formik={formik} />
                    )} */}
                    <ApInvoiceItemsLines formik={formik} />

                    {formik.errors.arTransactionsLinesList?.length && (
                      <div className="text-danger"> * مطلوب </div>
                    )}
                  </div>
                  <div className="tab-pane fade" id="apPaymentSchedules">
                    <ApPaymentSchedules formik={formik} />
                  </div>
                </div>
              </CardFooter>

              <div className="text-center mt-2">
                <button
                  disabled={formik.isSubmitting}
                  type="submit"
                  className="btn btn-lg me-1 btn-theme mb-1">
                  {formik.isSubmitting ? (
                    <div className="spinner-border spinner-border-sm me-2"></div>
                  ) : (
                    <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
                  )}
                  {t('Add')}
                </button>
                <button
                  type="button"
                  className=" btn btn-lg me-1 btn-default mb-1"
                  onClick={() => navigate('/Finance/Ap/ApInvoices', { replace: true })}>
                  <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
                </button>
              </div>
            </Form>
          </CardBody>
        </Loading>
      </Card>
    </>
  );
};

export default Add;

// <div className="ms-auto">
// <div className="btn-group" role="group" style={{ direction: 'ltr' }}>
//   <button
//     type="button"
//     className="btn btn-theme dropdown-toggle"
//     data-bs-toggle="dropdown"
//     aria-expanded="false">
//     {/* <i className="fa bi bi-printer-fill fa-fw ms-3 fs-5"></i> */}
//     {t('_____')}
//   </button>
//   <ul className="dropdown-menu text-center">
//     <li>
//       <Link
//         className="dropdown-item text-start"
//         to=""
//         // onClick={() => setShowPurchaseRequest(true)}
//         >
//         من طلب شراء
//         <i className="fa fa-cloud-download-alt fa-fw me-2 text-theme"></i>
//       </Link>
//     </li>
//     <li>
//       <Link
//         className="dropdown-item text-start"
//         to=""
//         // onClick={() => setShowPurchaseQuotation(true)}
//       >
//         من أمر شراء
//         <i className="fa fa-cloud-download-alt fa-fw me-2 text-theme"></i>
//       </Link>
//     </li>
//   </ul>
// </div>
// </div>
