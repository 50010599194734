import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useLookupModule from '../../../../hooks/Lookups/use-Lookup-Module';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { Card, CardBody } from '../../../../components/card/card';
import Pagination from '../../../../components/Shared/Pagination/Pagination';
import Loading from '../../../../components/Shared/Loader/Loading';
import useGetDashboardsList from '../../../../hooks/NTX/useGetDashboardsList';

const Index = () => {
  const { t, i18n } = useTranslation();
  const [search, setSearch] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [dataPerPage] = useState<number>(10);
  const Navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState('');
  const { Module } = useLookupModule();
  //___________________________________________س_______

  const { DashBoardList, loading, error } = useGetDashboardsList();

  //_________________________________________________________________________________________________

  const filteredSearch = DashBoardList?.slice()
    ?.filter((item) => {
      const searchLowerCase = search.toLowerCase();
      return (
        item.name?.toLowerCase().includes(searchLowerCase) ||
        item.name2?.toLowerCase().toString().includes(searchLowerCase)
      );
    })
    ?.filter((f) => (selectedValue === '' ? true : f.moduleId === selectedValue));

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: t('إدارة لوحة البيانات'),
      link: null,
    },
  ];

  return (
    <>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName={t('إدارة لوحة البيانات')} />
        <div className="ms-auto">
          <button className="btn btn-theme" onClick={() => Navigate(`AddUpdate`)}>
            <i className="fa fa-plus-circle fa-fw me-1"></i>
            {t('اضافة لوحة بيانات جديدة')}
          </button>
        </div>
      </div>

      <Card>
        <Loading loading={loading} error={error}>
          <CardBody>
            <div className=" p-2">
              <div className="row ">
                <div className="col-7 col-md-6 d-flex justify-content-start">
                  <div className="input-group ">
                    <div className="flex-fill position-relative">
                      <div className="input-group mb-2">
                        <select
                          className="btn btn-default p-0 dropdown-toggle"
                          value={selectedValue}
                          onChange={(event) => setSelectedValue(event.target.value)}>
                          <option className="dropdown-item" value={''}>
                            {t('All')}
                          </option>
                          {Module &&
                            Module?.map((item: any) => (
                              <option value={item.id} key={item.id} className="dropdown-item">
                                {i18n.language === 'ar' ? item.name2 : item.name}
                              </option>
                            ))}
                        </select>

                        <div className="flex-fill">
                          <div className="input-group">
                            <div
                              className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                              style={{ zIndex: 1020, right: '10px' }}>
                              <i className="fa fa-search opacity-5"></i>
                            </div>
                            <input
                              type="text"
                              className="form-control ps-35px"
                              placeholder={t('Search')}
                              value={search}
                              onChange={(e) => setSearch(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* //  START TABLE   */}
              <div className="table-responsive">
                <table className="table table-hover text-nowrap">
                  <thead className="table-dark">
                    <tr>
                      <th scope="col">{t('Name')}</th>
                      <th scope="col">{t('Application')}</th>

                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {DashBoardList &&
                      currentData?.map((item, index) => {
                        const module = Module?.find(
                          (f: { id: string }) => f?.id === item?.moduleId
                        );

                        return (
                          <tr key={item.id}>
                            <td className="align-middle">
                              {i18n.language === 'ar' ? item.name2 : item.name}
                            </td>
                            <td className="align-middle">
                              {module ? (i18n.language === 'en' ? module.name : module.name2) : ''}
                            </td>
                            <td className="text-end">
                              <button
                                type="button"
                                className="btn btn-outline-theme"
                                onClick={() => Navigate(`${item.id}/UpdateDashBoard`)}>
                                <i className="far fa-lg fa-fw me-2 fa-edit"></i>
                                {t('Edit')}
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              <Pagination
                filteredData={filteredSearch}
                dataPerPage={dataPerPage}
                handlePagination={(pageNumber: number) => setCurrentPage(pageNumber)}
                currentPage={currentPage}
              />
            </div>
          </CardBody>
        </Loading>
      </Card>
    </>
  );
};

export default Index;
