import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ntxAPI } from '../../../../API/axiosNeatex';
import { ArReceiptsModels } from './ArReceiptsModels';
import { APIResponse } from '../../../Shared/shared';

export const addArReceipts = createAsyncThunk(
  'ArReceipts/addArReceipts',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post<APIResponse<any>>('Ar/AddArReceipts', item);
      if (data?.succeeded) {
        return data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateArReceipts = createAsyncThunk(
  'ArReceipts/updateArReceipts',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post<APIResponse<any>>('Ar/UpdateArReceipts', item);
      if (data?.succeeded) {
        return data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getArReceiptsList = createAsyncThunk(
  'ArReceipts/getArReceiptsList',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get<APIResponse<ArReceiptsModels[]>>('Ar/GetArReceiptsList');
      if (data?.succeeded) {
        return data?.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getArReceiptsById = createAsyncThunk(
  'ArReceipts/getArReceiptsById',
  async (Id: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get<APIResponse<ArReceiptsModels>>(
        `Ar/GetArReceiptsById?Id=${Id}`
      );
      if (data?.succeeded) {
        return data?.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState: {
  ArReceiptsList: ArReceiptsModels[];
  ArReceiptsById: ArReceiptsModels;
  loading: boolean;
  error: any | null;
} = {
  ArReceiptsList: [],
  ArReceiptsById: {} as ArReceiptsModels,
  loading: false,
  error: null,
};

const ArReceiptsSlice = createSlice({
  name: 'ArReceipts',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder

      .addCase(getArReceiptsList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getArReceiptsList.fulfilled, (state, action: PayloadAction<ArReceiptsModels[]>) => {
        state.loading = false;
        state.ArReceiptsList = action.payload;
      })
      .addCase(getArReceiptsList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getArReceiptsById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getArReceiptsById.fulfilled, (state, action: PayloadAction<ArReceiptsModels>) => {
        state.loading = false;
        state.ArReceiptsById = action.payload;
      })
      .addCase(getArReceiptsById.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default ArReceiptsSlice.reducer;
