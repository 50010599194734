import React from 'react';
import InvoiceLines from './InvoiceLines';
import { useTranslation } from 'react-i18next';

interface Props {
  data: any;
}

const InvoiceDetails = ({ data }: Props) => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <div className="row">
        <div className="col-12 col-md-4">
          <div className="input-group input-group-sm flex-nowrap mb-2">
            <span
              className="input-group-text  d-flex justify-content-center"
              style={{ width: '130px' }}>
              رقم الفاتورة
            </span>
            <input
              type="text"
              className="form-control form-control-sm"
              value={data.code}
              disabled
            />
          </div>

          <div className="input-group input-group-sm flex-nowrap mb-2">
            <span
              className="input-group-text d-flex justify-content-center "
              style={{ width: '130px' }}>
              تاريخ الفاتورة
            </span>
            <input
              type="text"
              className="form-control form-control-sm"
              value={data.transactionDate}
              disabled
            />
          </div>

          <div className="input-group input-group-sm flex-nowrap mb-2">
            <span
              className="input-group-text  d-flex justify-content-center"
              style={{ width: '130px' }}>
              تاريخ الإستحقاق
            </span>
            <input
              type="text"
              className="form-control form-control-sm"
              value={data.dueDate || ''}
              disabled
            />
          </div>
        </div>

        <div className="col-12 col-md-4">
          <div className="input-group input-group-sm flex-nowrap mb-2">
            <span
              className="input-group-text d-flex justify-content-center"
              style={{ width: '130px' }}>
              العملة
            </span>
            <input
              type="text"
              className="form-control form-control-sm"
              value={data.currencyCode}
              disabled
            />
          </div>

          <div className="input-group input-group-sm flex-nowrap mb-2">
            <span
              className="input-group-text d-flex justify-content-center "
              style={{ width: '130px' }}>
              المخزن
            </span>
            <input
              type="text"
              className="form-control form-control-sm"
              defaultValue={i18n.language === 'en' ? data.inventoryName : data.inventoryName2}
              disabled
            />
          </div>

          <div className="input-group input-group-sm flex-nowrap mb-2">
            <span
              className="input-group-text d-flex justify-content-center "
              style={{ width: '130px' }}>
              العميـل
            </span>
            <input
              type="text"
              className="form-control form-control-sm"
              defaultValue={i18n.language === 'en' ? data.peopleName : data.peopleName2}
              disabled
            />
          </div>
        </div>

        <div className="col-12 col-md-4">
          <div className="input-group input-group-sm flex-nowrap mb-2">
            <span
              className="input-group-text d-flex justify-content-center "
              style={{ width: '130px' }}>
              الفرع
            </span>
            <input
              type="text"
              className="form-control form-control-sm"
              defaultValue={i18n.language === 'en' ? data.organizationName : data.organizationName2}
              disabled
            />
          </div>

          <div className="input-group input-group-sm flex-nowrap mb-2">
            <span
              className="input-group-text d-flex justify-content-center "
              style={{ width: '130px' }}>
              مندوب المبيعات
            </span>
            <input
              type="text"
              className="form-control form-control-sm"
              defaultValue={i18n.language === 'en' ? data.personName : data.personName2}
              disabled
            />
          </div>

          <div className="input-group input-group-sm flex-nowrap mb-2">
            <span
              className="input-group-text d-flex justify-content-center "
              style={{ width: '130px' }}>
              اسم العميل
            </span>
            <input
              type="text"
              className="form-control form-control-sm"
              value={data.recipientname}
              disabled
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-4">
          <div className="input-group input-group-sm flex-nowrap">
            <span
              className="input-group-text d-flex justify-content-center "
              style={{ width: '130px' }}>
              ملاحظات
            </span>
            <input
              type="text"
              className="form-control form-control-sm"
              value={data.description}
              disabled
            />
          </div>
        </div>
        <div className="col-12 col-md-4">
          <div className="input-group input-group-sm flex-nowrap">
            <span
              className="input-group-text d-flex justify-content-center "
              style={{ width: '130px' }}>
              الإجـمالي
            </span>
            <input
              type="text"
              className="form-control form-control-sm"
              value={data.grossSale}
              disabled
            />
          </div>
        </div>
        <div className="col-12 col-md-4">
          <div className="input-group input-group-sm flex-nowrap mb-2">
            <span
              className="input-group-text d-flex justify-content-center "
              style={{ width: '130px' }}>
              تاريخ التوصيل
            </span>
            <input
              type="text"
              className="form-control form-control-sm"
              value={data.deliveryDate}
              disabled
            />
          </div>
        </div>
      </div>

      <InvoiceLines LinesList={data.transactionsLinesList} />
    </>
  );
};

export default InvoiceDetails;
