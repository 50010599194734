/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { ntxAPI } from "../../../../API/axiosNeatex";
import SkeletonLoader from "../../../../components/Shared/SkeletonLoader/SkeletonLine.js";

const TypeT = ({ item, handleSelectChange, value }) => {
  const {  i18n } = useTranslation();
  const [TableDataList, setTableDataList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function GetTableData() {
      try {
        const { data } = await ntxAPI.get(
          `NtxSetting/GetDataByTabelId?TabelId=${item?.tableId}&LookupType=${item?.lookupType}`
        );

        var DataList = data?.map((item) => ({
          value: item.id,
          label: i18n.language === "ar" ? item.name2 : item.name,
        }));

        setTableDataList(DataList);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    }

    GetTableData();
  }, [item?.lookupType, item?.tableId]);

  return (
    <>
      <div className="form-group mb-3">
        <div className="row">
          <label className="form-label">
            {i18n.language === "ar" ? item.lableText2 : item.lableText}
          </label>
        </div>
        <div className="row">
          {isLoading ? (
            <SkeletonLoader />
          ) : (
            <Select
              classNamePrefix="react-select"
              isSearchable={true}
              options={TableDataList}
              name={item.dynamic_form_column_id}
              required={item.requiredFlag}
              onChange={handleSelectChange}
              placeholder={i18n.language === "ar" ? "إختر" : "Choose"}
              value={TableDataList?.find((f) => f.value === value)}
              // defaultValue={TableDataList?.find(f => f.value === value)}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default TypeT;
 