import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-querybuilder';
import { GetFlexValueSetList } from '../../../../../../store/NTX/FlexValue/flexValueSetSlice';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { TagsInput } from 'react-tag-input-component';

interface Props {
  fields: Field[];
  params: any[];
  setCheckValidationStep2: React.Dispatch<React.SetStateAction<boolean>>;
  setDataStep2: React.Dispatch<React.SetStateAction<any[]>>;
}

interface SelectedValue {
  paramName: string;
  paramLabel: any;
  paramValue: string;
  isStatic: boolean;
  lable?: string;
  lable2?: string;
  flexValueSetId: string;
  reportId: any;
  sequenceNum: number;
  inputType: string;
  isVisible: boolean;
  //
  beginWith: string;
  endWith: string;
  hasIn: boolean;
  requiredFlag: boolean;
  formatType: any; // for show and hidden select
  language: number | string;
}

interface FlexValueSet {
  id: string;
  name: string;
  name2: string;
  validationType: string;
  formatType: string;
  // description: string,
  // tableId: string
}

const Step2: React.FC<Props> = ({ params, setCheckValidationStep2, setDataStep2, fields }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(GetFlexValueSetList());
  }, [dispatch]);

  const { FlexValueSetList } = useAppSelector((state) => state.FlexValueSet) as {
    FlexValueSetList: FlexValueSet[];
  };

  const [tagsPerRow, setTagsPerRow] = useState<string[][]>([]);

  const handleGetParamLable = (ParamName: string) => {
    let fieldName: string = ParamName.replace(/_[^_]*$/, '');
    return fields?.find((f) => f.name === fieldName)?.label;
  };

  const [ReportParamerersList, setReportParamerersList] = useState<SelectedValue[]>(
    params?.map((item, idx) => ({
      paramLabel: handleGetParamLable(item.paramName),
      paramName: item.paramName,
      paramValue: item.value,
      isStatic: false,
      lable: '',
      lable2: '',
      flexValueSetId: '',
      reportId: '',
      sequenceNum: ++idx,
      inputType: item.inputType,
      isVisible: false,
      //
      beginWith: '',
      endWith: '',
      hasIn: false,
      requiredFlag: false,
      language: '',
      formatType: '', // not for post to server
    }))
  );

  useEffect(() => {
    setReportParamerersList((prevList) =>
      params?.map((item, idx) => ({
        formatType: prevList[idx]?.formatType, // not for post to server
        paramLabel: handleGetParamLable(item.paramName),
        paramName: item.paramName,
        //paramValue: item.paramValue || "",
        paramValue: prevList[idx]?.paramValue || '',
        isStatic: prevList[idx]?.isStatic || false,
        lable: prevList[idx]?.lable || '',
        lable2: prevList[idx]?.lable2 || '',
        flexValueSetId: prevList[idx]?.flexValueSetId || '',
        language: prevList[idx]?.language || '1',
        reportId: null,
        sequenceNum: ++idx,
        inputType: item.inputType,
        isVisible: prevList[idx]?.isVisible || false,
        beginWith: item.beginWith,
        endWith: item.endWith,
        hasIn: item.hasIn,
        requiredFlag: prevList[idx]?.requiredFlag || false,
      }))
    );
  }, [params]);

  const handleTagsInputChange = (tags: string[], index: number) => {
    const updatedTagsPerRow = [...tagsPerRow];
    updatedTagsPerRow[index] = tags;
    setTagsPerRow(updatedTagsPerRow);

    const updatedParamsList = [...ReportParamerersList];
    updatedParamsList[index].paramValue = tags.join(', ');
    setReportParamerersList(updatedParamsList);
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    index: number,
    select?: string
  ) => {
    const { name, value, type } = event.target;

    const inputValue = type === 'checkbox' ? (event.target as HTMLInputElement).checked : value;

    const updatedParamsList = [...ReportParamerersList];

    //__ لهندلة formatType لاختيار لغة اذا كان نوع جدول او قائمة نظام
    if (select === 'flexValueSetId') {
      const selectElement = event.target as HTMLSelectElement;
      const selectedOption = selectElement.selectedOptions[0];

      updatedParamsList[index] = {
        ...updatedParamsList[index],
        [name]: value,
      };

      if (selectedOption) {
        updatedParamsList[index] = {
          ...updatedParamsList[index],
          formatType: selectedOption.dataset.formatType || '', //
        };
      }
    } else {
      // Update the corresponding property in the selected row
      updatedParamsList[index] = {
        ...updatedParamsList[index],
        [name]: type === 'checkbox' ? inputValue : value,
      };
    }

    // Update the state with the modified array
    setReportParamerersList(updatedParamsList);
  };

  const onDragEnd = (result: any) => {
    try {
      if (!result?.destination) return;
      const updatedItems = [...ReportParamerersList];
      const [movedRow] = updatedItems?.splice(result.source.index, 1);
      updatedItems?.splice(result.destination.index, 0, movedRow);

      // Update the sequance property based on the new order
      updatedItems?.forEach((item, index) => {
        item.sequenceNum = index + 1;
      });

      // Update the tagsPerRow array based on the new order
      const updatedTags = updatedItems?.map((item) => {
        const index = ReportParamerersList?.findIndex(
          (param) => param.paramName === item.paramName
        );
        return tagsPerRow[index];
      });

      // Set the updated tagsPerRow array
      setTagsPerRow(updatedTags);

      // Set the updated ReportParamerersList
      setReportParamerersList(updatedItems);
    } catch (err) {
      console.log(err);
    }
  };

  //________________بداية كود عملية التحقق validation ___________
  const [validationErrors, setValidationErrors] = useState<
    | {
        paramValue: string[];
        lable: string[];
        flexValueSetId: string[];
      }
    | undefined
  >(undefined);

  useEffect(() => {
    const updatedValidationErrors: {
      paramValue: string[];
      lable: string[];
      flexValueSetId: string[];
    } = {
      paramValue: [],
      lable: [],
      flexValueSetId: [],
    };

    ReportParamerersList.forEach((param, index) => {
      if (!param.lable) {
        updatedValidationErrors.lable[index] = '*';
      }

      if (!param.flexValueSetId) {
        updatedValidationErrors.flexValueSetId[index] = '*';
      }

      if (param.isStatic && !param.paramValue) {
        updatedValidationErrors.paramValue[index] = '*';
      }
    });

    const hasValidationErrors = Object.values(updatedValidationErrors)?.some(
      (errorArray) => errorArray?.length > 0
    );
    setCheckValidationStep2(!hasValidationErrors || ReportParamerersList?.length === 0);

    setValidationErrors(updatedValidationErrors);
  }, [ReportParamerersList]);
  //نهاية  كود عملية التحقق validation ___________

  //______  جلب البيانات من اجل ارسالها للسيرفر
  useEffect(() => {
    setDataStep2(ReportParamerersList);
  }, [ReportParamerersList]);

  return (
    <>
      {ReportParamerersList?.length > 0 ? (
        <div className="table-responsive rounded-2 pt-0  ">
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <table
                  className="table table-hover table-borderless fs-6"
                  {...provided.droppableProps}
                  ref={provided.innerRef}>
                  <thead className="bg-theme bg-opacity-60">
                    <tr>
                      <th className="align-middle" scope="col"></th>
                      <th className="align-middle" scope="col" style={{ textAlign: 'center' }}>
                        {t('Static_Value')}
                      </th>
                      <th className="align-middle" scope="col" style={{ textAlign: 'center' }}>
                        {t('Parameter_Name')}
                      </th>
                      <th className="align-middle" scope="col" style={{ textAlign: 'center' }}>
                        {t('Parameter_Value')}
                      </th>
                      <th className="align-middle" scope="col" style={{ textAlign: 'center' }}>
                        lable
                      </th>
                      <th className="align-middle" scope="col" style={{ textAlign: 'center' }}>
                        {t('Parameter_Type')}
                      </th>
                      <th className="align-middle" scope="col" style={{ textAlign: 'center' }}>
                        {t('Vasible')}
                      </th>
                      <th className="align-middle" scope="col" style={{ textAlign: 'center' }}>
                        {t('Required')}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {params &&
                      ReportParamerersList?.map((item, index) => (
                        <Draggable key={item.paramName} draggableId={item.paramName} index={index}>
                          {(provided, snapshot) => (
                            <tr
                              key={index}
                              // style={{ textAlign: "center"  }}

                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}>
                              <td className="align-middle">
                                {' '}
                                <a
                                  href="/#"
                                  title="مقبض السحب"
                                  style={{
                                    textDecoration: 'none',
                                    cursor: 'grab',
                                    pointerEvents: 'none',
                                  }}>
                                  ⁞⁞
                                </a>{' '}
                              </td>
                              <td className="align-middle" style={{ textAlign: 'center' }}>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="isStatic"
                                  checked={ReportParamerersList[index]?.isStatic || false}
                                  onChange={(event) => handleInputChange(event, index)}
                                />
                              </td>

                              <td className="align-middle" style={{ textAlign: 'center' }}>
                                {item.paramLabel}
                              </td>

                              <td style={{ width: '220px' }} className="align-middle">
                                {item.hasIn === false ? (
                                  <input
                                    style={{
                                      textAlign: 'center',
                                      width: '220px',
                                    }}
                                    className="form-control form-control-sm"
                                    name="paramValue"
                                    disabled={!ReportParamerersList[index]?.isStatic}
                                    type={
                                      item.inputType === 'date'
                                        ? 'date'
                                        : item.inputType === 'time'
                                        ? 'time'
                                        : item.inputType === 'number'
                                        ? 'number'
                                        : 'text'
                                    }
                                    value={
                                      !ReportParamerersList[index]?.isStatic
                                        ? ''
                                        : ReportParamerersList[index]?.paramValue || ''
                                    }
                                    onChange={(event) => handleInputChange(event, index)}
                                    required
                                  />
                                ) : (
                                  <div style={{ maxWidth: '250px' }}>
                                    <TagsInput
                                      key={index}
                                      disabled={!ReportParamerersList[index]?.isStatic}
                                      value={
                                        !ReportParamerersList[index]?.isStatic
                                          ? []
                                          : tagsPerRow[index] || []
                                      }
                                      onChange={(tags) => handleTagsInputChange(tags, index)}
                                    />
                                  </div>
                                )}
                                <div className="text-danger">
                                  {validationErrors?.paramValue[index]}
                                </div>
                              </td>

                              <td style={{ width: '220px' }} className="align-middle">
                                <input
                                  style={{ textAlign: 'center', width: '220px' }}
                                  className="form-control form-control-sm"
                                  type="text"
                                  name="lable"
                                  value={ReportParamerersList[index]?.lable || ''}
                                  onChange={(event) => handleInputChange(event, index)}
                                  required
                                />
                                <div className="text-danger">{validationErrors?.lable[index]}</div>
                              </td>

                              <td className="align-middle" style={{ width: '286px' }}>
                                <div className="d-flex">
                                  <select
                                    style={{ textAlign: 'center' }}
                                    className="form-select"
                                    name="flexValueSetId"
                                    value={ReportParamerersList[index]?.flexValueSetId || ''}
                                    onChange={(event) => {
                                      handleInputChange(event, index, 'flexValueSetId'); // Pass 'select' as the inputType
                                    }}>
                                    <option value="">
                                      {i18n.language === 'ar' ? 'إختر' : 'Choose'}
                                    </option>
                                    {/* __ فلترة القائمة بدون نوع مرفق - ملاحظة  */}
                                    {FlexValueSetList &&
                                      FlexValueSetList?.filter(
                                        (item) =>
                                          item.validationType !== 'NOTE' &&
                                          item.validationType !== 'ATTACHED'
                                      )?.map((item, idx) => (
                                        <option
                                          key={++idx}
                                          value={item.id}
                                          data-format-type={item.formatType}>
                                          {i18n.language === 'ar' ? item.name2 : item.name}
                                        </option>
                                      ))}
                                  </select>

                                  {ReportParamerersList[index]?.formatType === 'D' ||
                                  ReportParamerersList[index]?.formatType === 'T' ? (
                                    <select
                                      className="form-select text-center w-50"
                                      name="language"
                                      value={ReportParamerersList[index]?.language || ''}
                                      onChange={(event) => handleInputChange(event, index)}>
                                      <option value="1">Name</option>
                                      <option value="2">Name2</option>
                                    </select>
                                  ) : (
                                    ''
                                  )}
                                </div>

                                <div className="text-danger">
                                  {validationErrors?.flexValueSetId[index]}
                                </div>
                              </td>

                              <td className="align-middle" style={{ textAlign: 'center' }}>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="isVisible"
                                  checked={ReportParamerersList[index]?.isVisible || false}
                                  onChange={(event) => handleInputChange(event, index)}
                                />
                              </td>
                              <td className="align-middle" style={{ textAlign: 'center' }}>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="requiredFlag"
                                  checked={ReportParamerersList[index]?.requiredFlag || false}
                                  onChange={(event) => handleInputChange(event, index)}
                                />
                              </td>
                            </tr>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </tbody>
                </table>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      ) : (
        <div className="border border-theme text-center bg-inverse bg-opacity-10 rounded-2 p-3 fw-bold">
          {t('لا توجد قواعد مضافة للتقرير بعد. قم بإضافة قواعد لتخصيص تقريرك بسهولة.')}
          {/* {t('لم يتم إضافة أي قواعد للتقرير حتى الآن. يرجى إضافة القواعد لبدء إعداد التقرير.')} */}
        </div>
      )}
    </>
  );
};

export default memo(Step2);
