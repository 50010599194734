import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ntxAPI } from '../../../../API/axiosNeatex';
import { ApPaymentsModels, PaymentTypeModels } from './ApPaymentsModels';
import { APIResponse } from '../../../Shared/shared';

export const addApPayments = createAsyncThunk(
  'ApPayments/addApPayments',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post<APIResponse<any>>('Ap/AddApPayments', item);
      if (data?.succeeded) {
        return data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateApPayments = createAsyncThunk(
  'ApPayments/updateApPayments',
  async (item: any, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.post<APIResponse<any>>('Ap/UpdateApPayments', item);
      if (data?.succeeded) {
        return data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);
export const getApPaymentsList = createAsyncThunk(
  'ApPayments/getApPaymentsList',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get<APIResponse<ApPaymentsModels[]>>('Ap/GetApPaymentsList');
      if (data?.succeeded) {
        return data?.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getApPaymentsByPeopleId = createAsyncThunk(
  'ApPayments/getApPaymentsByPeopleId',
  async (PeopleId: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get<APIResponse<ApPaymentsModels>>(
        `Ap/GetApPaymentsByPeopleId?PeopleId=${PeopleId}`
      );
      if (data?.succeeded) {
        return data?.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getApPaymentsByOrganizationId = createAsyncThunk(
  'ApPayments/getApPaymentsByOrganizationId',
  async (OrganizationId: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get<APIResponse<ApPaymentsModels>>(
        `Ap/GetApPaymentsByOrganizationId?OrganizationId=${OrganizationId}`
      );
      if (data?.succeeded) {
        return data?.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getApPaymentsById = createAsyncThunk(
  'ApPayments/getApPaymentsById',
  async (Id: string, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get<APIResponse<ApPaymentsModels>>(
        `Ap/GetApPaymentsById?Id=${Id}`
      );
      if (data?.succeeded) {
        return data?.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getPaymentTypeList = createAsyncThunk(
  'ApPayments/getPaymentTypeList',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ntxAPI.get<APIResponse<PaymentTypeModels[]>>('Ap/GetPaymentTypeList');
      if (data?.succeeded) {
        return data?.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState: {
  ApPaymentsList: ApPaymentsModels[];
  ApPaymentsById: ApPaymentsModels;
  ApPaymentsByPeopleId: ApPaymentsModels;
  ApPaymentsByOrganizationId: ApPaymentsModels;
  loading: boolean;
  error: any | null;

  PaymentTypeList: PaymentTypeModels[];
  loading2: boolean;
} = {
  ApPaymentsList: [],
  ApPaymentsById: {} as ApPaymentsModels,
  ApPaymentsByPeopleId: {} as ApPaymentsModels,
  ApPaymentsByOrganizationId: {} as ApPaymentsModels,
  loading: false,
  error: null,

  PaymentTypeList: [],
  loading2: false,
};

const ApPaymentsSlice = createSlice({
  name: 'ApPayments',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder

      .addCase(getApPaymentsList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getApPaymentsList.fulfilled, (state, action: PayloadAction<ApPaymentsModels[]>) => {
        state.loading = false;
        state.ApPaymentsList = action.payload;
      })
      .addCase(getApPaymentsList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getApPaymentsById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getApPaymentsById.fulfilled, (state, action: PayloadAction<ApPaymentsModels>) => {
        state.loading = false;
        state.ApPaymentsById = action.payload;
      })
      .addCase(getApPaymentsById.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getApPaymentsByPeopleId.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        getApPaymentsByPeopleId.fulfilled,
        (state, action: PayloadAction<ApPaymentsModels>) => {
          state.loading = false;
          state.ApPaymentsByPeopleId = action.payload;
        }
      )
      .addCase(getApPaymentsByPeopleId.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getApPaymentsByOrganizationId.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        getApPaymentsByOrganizationId.fulfilled,
        (state, action: PayloadAction<ApPaymentsModels>) => {
          state.loading = false;
          state.ApPaymentsByOrganizationId = action.payload;
        }
      )
      .addCase(getApPaymentsByOrganizationId.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })


      ///


      .addCase(getPaymentTypeList.pending, (state, action) => {
        state.loading2 = true;
      })
      .addCase(getPaymentTypeList.fulfilled, (state, action: PayloadAction<PaymentTypeModels[]>) => {
        state.loading2 = false;
        state.PaymentTypeList = action.payload;
      })
      .addCase(getPaymentTypeList.rejected, (state, action: PayloadAction<any>) => {
        state.loading2 = false;
        state.error = action.payload;
      })




  },
});

export default ApPaymentsSlice.reducer;
