import { useFormik } from 'formik';
import React from 'react';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import Select from 'react-select';
import { Card, CardFooter } from '../../../../components/card/card';
import { CardBody, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useLookupCurrency from '../../../../hooks/Lookups/use-Lookup-Currency';
import useGetPersons from '../../../../hooks/HR/use-get-persons';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { useAppDispatch } from '../../../../store/hooks';
// import TransactionsLines2 from '../TransactionsLines/TransactionsLines2';
import ToastSwal from '../../../../components/Shared/Alert/ToastSwal';
import { useNavigate, useParams } from 'react-router-dom';
import { SellOrderSchema } from '../../../ValidationForm/validationSchema';
import Loading from '../../../../components/Shared/Loader/Loading';
import useGetQuotationSalesById from '../../../../hooks/Sales/useGetQuotationSalesById';
import { addSalOrderSalseTransactions } from '../../../../store/Sales/SalesOrder/SalesOrderSlice';
import useGetCustomersList from '../../../../hooks/NTX/Peoples/useGetCustomersList';
import { TreeSelect } from 'antd';
import useGetBranchOrgWithPermission from '../../../../hooks/NTX/Organization/useGetBranchOrgWithPermission';
import useGetInventoryWithPermission from '../../../../hooks/NTX/Organization/useGetInventoryWithPermission';
import SellOrderLines from './SellOrderLines';
import useGetPeopleLocationsList from '../../../../hooks/NTX/Peoples/useGetPeopleLocationsList';
import useGetPaymentMethod from '../../../../hooks/Payrolls/use-Get-PaymentMethod';
import useGetPaymentTermsList from '../../../../hooks/Sales/useGetItemsList';
import SwalAlert from '../../../../components/Shared/Alert/SwalAlert';
import confirmAction from '../../../../components/Shared/Alert/confirmAction';

const Add = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { PaymentMethodList, error: loading1, error: error1 } = useGetPaymentMethod();
  const { PaymentTermsList, loading: loading2, error: error2 } = useGetPaymentTermsList();
  const { Currency, loading: loading3, error: error3 } = useLookupCurrency();
  const { dataEmployees, loading: loading4, error: error4 } = useGetPersons();
  const {
    BranchOrgOptions: BranchsOptions,
    loading: loading5,
    error: error5,
  } = useGetBranchOrgWithPermission();
  const { InventoryOptions, loading: loading6, error: error6 } = useGetInventoryWithPermission();
  const { treeCustomerData, loading: loading7, error: error7 } = useGetCustomersList();

  //#region useParams quoteInvoice
  const { TransactionsQuotationById: data } = useGetQuotationSalesById({ id: id });
  //#endregion

  //#region Formik
  const formik = useFormik({
    initialValues: {
      no: '', // رقم الفاتورة
      code: ' ',

      organizationId: data.organizationId ?? '', // الفروع
      peopleId: data.peopleId ?? '', //العميل
      recipientname: data.recipientname ?? '', // اسم العميل وممكن يتعدل
      billToLocationId: data.billToLocationId ?? null,
      shipToLocationId: data.shipToLocationId ?? null,
      referenceId: data.id ?? null,
      transactionDate: new Date().toISOString().split('T')[0],
      paymentMethodId: data.paymentMethodId ?? '',
      deliveryDate: '', //  تاريخ التوصبل
      expirationDate: '', //  تجاهلها
      dueDate: '', //  تاريخ الإستحقاق
      paymentTermsId: '', //  شروط الدفع .. الخاص بالعميل من جدول العميل تلقائيا
      description: data.description ?? '', //  وصف الفاتورة
      grossSale: data.grossSale ?? '', //
      discountRate: data.discountRate ?? '', //   نسبة الخصم
      discountAmount: data.discountAmount ?? '', //   مبلغ الخصم
      vat: data.vat ?? '', //   قيمة الضريبة
      netSale: data.netSale ?? '', //   الصافي مجموع اللاينس
      contractId: data.contractId ?? '', //   العقد
      ameStatus: '', //
      personId: data.personId ?? '', //   المندوب
      paymentTerms: '', //
      deliveryTerm: '', //
      inventoryId: data.inventoryId ?? '', //
      waybill: data.waybill ?? '', //
      phone: data.phone ?? '', //
      currencyCode: data.currencyCode ?? 'SR', //
      currencyConversionRate: data.currencyConversionRate ?? '', //
      batchId: '', //

      transactionsLinesList:
        data?.transactionsLinesList?.map((item) => ({
          id: item.id,
          // transactionId: item.transactionId,
          itemId: item.itemId,
          lineNumber: item.lineNumber,
          uom: item.uom,
          description: item.description,
          price: item.price,
          qty: item.qty,
          discountRate: item.discountRate,
          discountAmount: item.discountAmount,
          vatRate: item.vatRate,
          total: item.total,
          vatAmount: item.vatAmount,
          codeCombinationId: item?.codeCombinationId,
          inventoryId: item.inventoryId,
        })) ??
        [
          // {
          //   description: '',
          //   discountAmount: 0,
          //   discountRate: 0,
          //   itemId: '',
          //   lineNumber: 0,
          //   price: 0,
          //   qty: 0,
          //   total: 0,
          //   uom: '',
          //   vatAmount: 0,
          //   vatRate: 15,
          // },
        ],
    },
    enableReinitialize: true,
    validationSchema: SellOrderSchema(t),
    onSubmit: (values) => {
      const { transactionsLinesList, ...restValues } = values;

      const grossSale = values.transactionsLinesList.reduce(
        (s, a) => Number(s) + Number(a.total),
        0
      );

      if (values.transactionsLinesList?.length === 0) {
        ToastSwal({ title: 'أدخل أصناف', position: 'bottom-left' });
        formik.setSubmitting(false);

        return;
      }

      dispatch(
        addSalOrderSalseTransactions({
          organizationId: values.organizationId,
          peopleId: values.peopleId,
          recipientname: values.recipientname,
          billToLocationId: values.billToLocationId,
          shipToLocationId: values.shipToLocationId,
          referenceId: values.referenceId,
          paymentMethodId: values.paymentMethodId,
          transactionDate: values.transactionDate,
          deliveryDate: values.deliveryDate || null,
          expirationDate: values.expirationDate || null,
          dueDate: values.dueDate || null,
          paymentTermsId: values.paymentTermsId || null,
          // paymentTermsId: values.paymentTermsId,
          description: values.description,
          grossSale: grossSale,
          discountRate: values.discountRate || 0,
          discountAmount: values.discountAmount || 0,
          vat: values.vat || 0,
          netSale: values.netSale || 0,
          contractId: values.contractId || null,
          ameStatus: values.ameStatus || 0,
          personId: values.personId,
          paymentTerms: values.paymentTerms,
          deliveryTerm: values.deliveryTerm,
          inventoryId: values.inventoryId,
          waybill: values.waybill,
          phone: values.phone,
          currencyCode: values.currencyCode,
          currencyConversionRate: values.currencyConversionRate || 0,
          batchId: values.batchId || null,
          transactionsLinesList: formik.values.transactionsLinesList?.map((item) => ({
            itemId: item.itemId,
            lineNumber: item.lineNumber,
            uom: item.uom,
            description: item.description,
            price: Number(item.price),
            qty: Number(item.qty),
            discountRate: Number(item.discountRate),
            discountAmount: Number(item.discountAmount),
            vatRate: Number(item.vatRate),
            total: Number(item.total),
            vatAmount: Number(item.vatAmount),
            // unitCost: Number(item.unitCost),
            codeCombinationId: '54F915DF-8A22-486D-B860-08DC2A376B50',
            // currencyCode: 'SR',
            // currencyConversionRate: 0,
            // lotId: null,
            // headerId: null,
            // lineId: null,
            inventoryId: values.inventoryId,
          })),
        })
      )
        .unwrap()
        .then((res: any) => {
          debugger;
          if (res?.succeeded === true) {
            CustomAlert({ action: 'Add', msg: 'تم إضافة أمر البيع بنجاح!' });
            formik.resetForm();
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error', msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });
  const { values, handleChange: formikhandleChange, errors, touched, setFieldValue } = formik;
  //#endregion

  const {
    billToLocation,
    shipToLocation,
    // loading: loading8,
    error: error8,
  } = useGetPeopleLocationsList({
    peopleId: values.peopleId,
  });

  const handleChangePeopleAndinventory = async (option: any, field: string) => {

    if(option === values.peopleId || option?.value === values.inventoryId ) return

    if (values.transactionsLinesList?.length > 0) {
      const confirm = await confirmAction('سيتم مسح بنود الفاتورة هل تريد الإستمرار');

      if (!confirm) {
        return;
      }

      formik.setFieldValue('transactionsLinesList', []);
    }
    if (field === 'peopleId') {
      formik.setFieldValue(field, option === null ? null : option);
    } else if (field === 'inventoryId')
      formik.setFieldValue(field, option === null ? null : option.value);
  };

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'أوامر البيع',
      link: '/SalesTransactions/SalesInvoices',
    },
    {
      name: 'إضافة أمر بيع',
      link: null,
    },
  ];

  return (
    <div>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName=" أمر بيــع جديـد" />

      <Card>
        <CardBody>
          <Loading
            loading={
              loading1 || loading2 || loading3 || loading4 || loading5 || loading6 || loading7
            }
            error={error1 || error2 || error3 || error4 || error5 || error6 || error7 || error8}>
            <Form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-lg-2">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('التاريــخ')}</label>
                    <Form.Control
                      className="form-control  text-center"
                      type="date"
                      name="transactionDate"
                      onChange={formikhandleChange}
                      value={values.transactionDate || ''}
                      isInvalid={!!(touched.transactionDate && errors.transactionDate)}
                      // isValid={formik.touched.transactionDate && !formik.errors.transactionDate}
                    />
                  </div>
                </div>

                <div className="col-lg-2">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('تاريخ التوصيـل')}</label>
                    <Form.Control
                      className="form-control  text-center"
                      type="date"
                      name="deliveryDate"
                      onChange={formikhandleChange}
                      value={values.deliveryDate || ''}
                      isInvalid={!!(touched.deliveryDate && errors.deliveryDate)}
                      // isValid={formik.touched.transactionDate && !formik.errors.transactionDate}
                    />
                  </div>
                </div>

                <div className="col-lg-2">
                  <div className="form-group mb-3">
                    <label className="form-label"> {t('Currency')} </label>
                    <Form.Select
                      className="form-select"
                      name="currencyCode"
                      onChange={formikhandleChange}
                      value={values.currencyCode || ''}
                      isInvalid={!!(touched.currencyCode && errors.currencyCode)}>
                      <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>

                      {Currency &&
                        Currency?.map((item: any, idx: number) => (
                          <option key={++idx} value={item.currencyCode}>
                            {item.description}
                          </option>
                        ))}
                    </Form.Select>
                    <div className="text-danger">
                      {formik.touched.currencyCode && formik.errors.currencyCode}
                    </div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('المخزن')}</label>

                    <Select
                      classNamePrefix="react-select"
                      isLoading={loading3}
                      isSearchable={true}
                      isClearable
                      options={InventoryOptions}
                      value={InventoryOptions?.find(
                        (f: any) => f.value === formik.values.inventoryId
                      )}
                      onChange={(option: any) => {
                        // formik.setFieldValue('inventoryId', option === null ? null : option.value);
                        handleChangePeopleAndinventory(option, 'inventoryId');
                      }}
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                    />
                    <div className="text-danger">
                      {formik.touched.inventoryId && formik.errors.inventoryId}
                    </div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('الفرع')}</label>

                    <Select
                      classNamePrefix="react-select"
                      isLoading={loading3}
                      isSearchable={true}
                      isClearable
                      options={BranchsOptions}
                      value={BranchsOptions?.find(
                        (f: any) => f.value === formik.values.organizationId
                      )}
                      onChange={(option: any) => {
                        formik.setFieldValue(
                          'organizationId',
                          option === null ? null : option.value
                        );
                      }}
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                    />
                    <div className="text-danger">
                      {formik.touched.organizationId && formik.errors.organizationId}
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('العميل')}</label>

                    <TreeSelect
                      showSearch
                      // value={value}
                      className="w-100"
                      treeLine
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                      allowClear
                      // treeDefaultExpandAll
                      value={values.peopleId}
                      onChange={(option: any) => {
                        // setFieldValue('peopleId', option === null ? null : option);
                        handleChangePeopleAndinventory(option, 'peopleId');
                      }}
                      treeData={treeCustomerData}
                      treeNodeFilterProp="title"
                    />
                    <div className="text-danger">
                      {formik.touched.peopleId && formik.errors.peopleId}
                    </div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label"> {t('موقع الشـحن')} </label>
                    <Form.Select
                      className="form-select"
                      name="shipToLocationId"
                      onChange={formikhandleChange}
                      value={values.shipToLocationId || ''}>
                      <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>

                      {shipToLocation &&
                        shipToLocation?.map((item: any, idx: number) => (
                          <option key={++idx} value={item.id}>
                            {i18n.language === 'en' ? item.name : item.name2}
                          </option>
                        ))}
                    </Form.Select>
                    <div className="text-danger">
                      {/* {formik.touched.currencyCode && formik.errors.currencyCode} */}
                    </div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label"> {t('موقع الفوترة')} </label>
                    <Form.Select
                      className="form-select"
                      name="billToLocationId"
                      onChange={formikhandleChange}
                      value={values.billToLocationId || ''}>
                      <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>

                      {billToLocation &&
                        billToLocation?.map((item: any, idx: number) => (
                          <option key={++idx} value={item.id}>
                            {i18n.language === 'en' ? item.name : item.name2}
                          </option>
                        ))}
                    </Form.Select>
                    <div className="text-danger">
                      {/* {formik.touched.currencyCode && formik.errors.currencyCode} */}
                    </div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('مندوب المبيعات')}</label>
                    <Select
                      classNamePrefix="react-select"
                      // isLoading={error}
                      isSearchable={true}
                      isClearable
                      name="personId"
                      options={dataEmployees}
                      value={dataEmployees?.find((f: any) => f.value === formik.values.personId)}
                      onChange={(option: any) => {
                        formik.setFieldValue('personId', option === null ? null : option.value);
                      }}
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                    />
                    <div className="text-danger">
                      {formik.touched.personId && formik.errors.personId}
                    </div>
                  </div>
                </div>

                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('PaymentMethod')}</label>
                    <Form.Select
                      className="form-select"
                      name="paymentMethodId"
                      onChange={formik.handleChange}
                      value={formik.values.paymentMethodId}
                      isInvalid={
                        !!(formik.touched.paymentMethodId && formik.errors.paymentMethodId)
                      }>
                      <option key={null}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>

                      {PaymentMethodList?.map((item: any, idx: number) => (
                        <option key={++idx} value={item.id}>
                          {i18n.language === 'ar' ? item.name : item.name2}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                </div>
                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">شروط الدفع</label>
                    <Form.Select
                      className="form-select"
                      name="paymentTermsId"
                      onChange={formik.handleChange}
                      value={formik.values.paymentTermsId}
                      isInvalid={!!(formik.errors.paymentTermsId && formik.touched.paymentTermsId)}>
                      <option value={''}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                      {PaymentTermsList &&
                        PaymentTermsList?.map((item: any, idx: number) => (
                          <option key={++idx} value={item.id}>
                            {i18n.language === 'ar' ? item.name2 : item.name}
                          </option>
                        ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.paymentTermsId}
                    </Form.Control.Feedback>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('Note')}</label>
                    <Form.Control
                      className="form-control"
                      as="textarea"
                      rows={1}
                      name="description"
                      onChange={formik.handleChange}
                      value={values.description}
                    />
                  </div>
                </div>
              </div>

              {values.inventoryId && values.peopleId && (
                <CardFooter>
                  <div className="row  position-relative ">
                    <SellOrderLines formik={formik} />
                    {formik.errors.transactionsLinesList?.length && (
                      <div className="text-danger"> * مطلوب </div>
                    )}
                  </div>
                </CardFooter>
              )}

              <div className="text-center mt-2">
                <button
                  disabled={formik.isSubmitting}
                  type="submit"
                  className="btn btn-lg me-1 btn-theme mb-1 rounded-2">
                  {formik.isSubmitting ? (
                    <div className="spinner-border spinner-border-sm me-2"></div>
                  ) : (
                    <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
                  )}
                  {t('Add')}
                </button>
                <button
                  type="button"
                  className=" btn btn-lg me-1 btn-default mb-1 rounded-2"
                  onClick={() => navigate('/SalesTransactions/SellOrder', { replace: true })}>
                  <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
                </button>
              </div>
            </Form>
          </Loading>
        </CardBody>
      </Card>
    </div>
  );
};

export default Add;
