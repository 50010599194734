
// const baseUrl="https://neatixapi.aljown.com";
// const baseUrl="http://192.168.0.19:8065";
// API_URL:"https://hrapi.az-zad.com/api/",

import { DecryptString } from "../Helper/Encrypt/NtxEncrypt";


// API_URL:"http://192.168.0.59:98/api/";
// const baseUrl="http://192.168.0.59:98";
// const baseUrl="http://192.168.43.112:98";
const baseUrl="https://neatixapi.aljown.com"; 
//  const baseUrl="https://hrapi.az-zad.com";
// const baseUrl="http://localhost:8089";
// const baseUrl="https://localhost:7038";

// 
export const variables = {
  API_URL:`${baseUrl}/api/`,
  URL_NOTIFICATION: `${baseUrl}/notificationHub`,
};

const AuthUser = localStorage?.AuthUser 
? JSON.parse(DecryptString(localStorage?.AuthUser))
: null;
const Token = AuthUser?.token
export const ntxheader = {
  Authorization: `Bearer  ${Token}`,
  TenantId: "DEMO",
  // TenantId: "NTX",               
  //  TenantId: "AZZ",
  // TenantId: "AZZT",
  //  TenantId: "LOCAL",
};
