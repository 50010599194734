import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
// import isOnline from "is-online";
import { LoginAction, setAuthUser } from '../../store/Login/loginSlice';
import Logo from './Logo';
import { EncryptString } from '../../Helper/Encrypt/NtxEncrypt';
import { IsTokenValid } from '../../Helper/Helper';
import ToastSwal from '../../components/Shared/Alert/ToastSwal';

function Login() {
  const [isNewLogin, setIsNewLogin] = useState(true);

  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  //#region formik action login
  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },

    validationSchema: Yup.object({
      username: Yup.string().required(' '),
      password: Yup.string().required(' '),
    }),
    onSubmit: async (values, { setErrors }) => {
      // var onLine = await isOnline();
      // if (!onLine) {
      //   setErrors({ login: "لا يوجد اتصال بالشبكة" });
      //   return;
      // }
      await dispatch(
        LoginAction({
          username: values.username,
          password: values.password,
        })
      )
        .unwrap()
        .then(async (res) => {
          if (res?.isComfirm === false) {
            navigate('ConfirmPassword', {
              replace: true,
              state: {
                Token: res?.token,
                RefrechToken: res?.refreshToken,
                personId: res?.personId,
                Test: EncryptString(res?.permission),
                res: res,
              },
            });
          } else {
            ToastSwal({
              icon: 'success',
              position: 'top',
              title: 'تم تسجيل دخولك بنجاح',
            });
            setIsNewLogin(false);
            await new Promise((resolve) => setTimeout(resolve, 1900));
            localStorage.setItem('CurrentSystem', 'NTX');
            dispatch(setAuthUser(res));
            window.location.replace('/Neatix/Applications');
          }
        })
        .catch((res) => {
          formik.setSubmitting(false);
          if (res === '401') {
            ToastSwal({
              icon: 'error',
              position: 'top',
              title: 'تحقق من بيانات الدخول',
            });
            setErrors({ login: 'تحقق من بيانات الدخول' });
          } else if (res === '404') {
            setErrors({ login: 'خطأ في الإتصال بالسيرفر' });
          } else if (res === '404') {
            setErrors({ login: 'لا يوجد اتصال بالشبكة' });
          }
        });
    },
  });
  //#endregion

  useEffect(() => {
    // window.history.pushState(null , null,"/")
    localStorage.setItem('language', 'ar');
    localStorage.setItem('appMode', 'dark');
    localStorage.setItem('appTheme', 'theme-info');
    document.documentElement.setAttribute('data-bs-theme', 'dark');
    window.history.replaceState(null, null, '/');
  }, []);

  if (localStorage?.AuthUser && isNewLogin) {
    if (IsTokenValid()) {
      return <Navigate to="/Neatix/Applications" />;
      // window.location.replace("/Neatix/Applications");
      // new Promise((resolve) => setTimeout(resolve, 3000));
    } else {
      localStorage.clear();
    }
  }

  return (
    <>
      <div className="login">
        <div className="login-content ">
          <div style={{ marginBottom: '10px' }}>
            <Logo themeClass={localStorage.appTheme ?? 'theme-info'} />
          </div>
            <h5 className='text-theme text-center'>تسجيل الدخول - Sign In</h5>
          <hr />
          <Form onSubmit={formik.handleSubmit} className="">
            {/* <h5 style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span style={{ float: 'right' }}>تسجيـل الدخول</span>
              <span style={{ float: 'left' }}>Sign In</span>
            </h5> */}
            <div className="text-theme text-opacity-50 text-center mb-4"></div>

            <div className="mb-3">
              {/* <label className="form-label">
                اسم المستخدم User Name <span className="text-danger">*</span>
              </label> */}
              <label
                className="form-label"
                style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span style={{ float: 'right' }}>اسم المستخدم</span>
                <span style={{ float: 'left' }}>User Name</span>
              </label>
              <Form.Control
                style={{ textAlign: 'center' }}
                className="form-control form-control-lg bg-white bg-opacity-5"
                type="text"
                name="username"
                onChange={formik.handleChange}
                value={formik.values.username}
                isInvalid={formik.touched.username && formik.errors.username}
                // autoComplete="off"
              />
              <Form.Control.Feedback type="invalid">{formik.errors.username}</Form.Control.Feedback>
            </div>

            <div className="mb-3">
              {/* <label className="form-label">
                  كلمة المرور Password <span className="text-danger">*</span>
                </label> */}

              <label
                className="form-label"
                style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span style={{ float: 'right' }}>كلمة المرور </span>
                <span style={{ float: 'left' }}>Password</span>
              </label>

              <div className="input-group">
                <div
                  className="input-group-text position-absolute  bg-none border-0 pe-0"
                  style={{ zIndex: 1020, right: '10px' }}>
                  <i
                    className={`text-theme fs-4 opacity-10 bi bi-eye${
                      showPassword ? '-slash' : ''
                    }`}
                    onClick={togglePasswordVisibility}
                    style={{ cursor: 'pointer', marginLeft: '-30px' }}
                  />
                </div>

                <Form.Control
                  style={{ textAlign: 'center' }}
                  className="form-control form-control-lg bg-white bg-opacity-5"
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  isInvalid={formik.touched.password && formik.errors.password}
                  // autoComplete="off"
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.password}
                </Form.Control.Feedback>
              </div>
            </div>

            <div className="mb-3">
              <button
                type="submit"
                className="btn btn-outline-theme btn-lg d-block w-100 fw-500 rounded-2"
                disabled={formik.isSubmitting}>
                {!formik.isSubmitting ? (
                  ' تسجيل الدخول'
                ) : (
                  <div className="spinner-border text-primary spinner-border-sm "></div>
                )}
              </button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}

export default Login;
