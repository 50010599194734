import { useFormik } from 'formik';
import * as Yup from 'yup';
// import Select from 'react-select';
import { Col, Form } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { addPosSession } from '../../../store/Sales/Pos/PosSessionSlice';
import CustomAlert from '../../../components/Shared/Alert/CustomAlert';
import { useTranslation } from 'react-i18next';
import useGetCashierListByPosId from '../../../hooks/POS/useGetCashierListByPosId';
import { useNavigate } from 'react-router-dom';
import { getPersonId } from '../../../store/Login/loginSlice';
import { PosModel } from '../../../store/Sales/Pos/PosModels';

type Props = {
  PosModel: PosModel;
};

const OpenSession = ({ PosModel }: Props) => {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const { PosCashierList, loading: loading2 } = useGetCashierListByPosId({ posId: PosModel.id });
  const personId = useAppSelector(getPersonId);
  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      CasherId: '',
      StartDatetime: '',
      OpeningBalance: '',
      OpeningNote: '',
    },

    validationSchema: Yup.object({
      // CasherId: Yup.string().required('Required'),
      // StartDatetime: Yup.string().required('Required'),
      OpeningBalance: Yup.number()
        .typeError('Must be a number')
        .required('Required')
        .min(0, 'Must be greater than or equal to 0'),
      OpeningNote: Yup.string(),
    }),

    onSubmit: (values) => {
      const currentDateTime = new Date().toISOString();
      dispatch(
        addPosSession({
          posId: PosModel.id,
          casherId: PosCashierList?.find((f) => f.personId === personId)?.id,
          startDatetime: currentDateTime,
          openingBalance: Number(values.OpeningBalance),
          openingNote: values.OpeningNote,
          // بالاضافة خلي هذه تقبل null ؟؟؟ احسن
          endDatetime: null,
          closingBalance: null,
          closingNote: null,
        })
      )
        .unwrap()
        .then(async (res) => {
          if (res.succeeded === true) {
            let sessionId = res.data;
            await new Promise((resolve) => setTimeout(resolve, 2000));
            document.getElementById('closeModal')?.click();
            if (PosModel.posType === 'shop') {
              navigate(`/Pos/${PosModel.id}/${sessionId}/PointOfSaleShop`);
            } else {
              navigate(`/Pos/${PosModel.id}/${sessionId}/PointOfSaleRestaurant`);
            }
            // CustomAlert({ action: 'Add' });
            formik.setSubmitting(false);
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error' });
          }
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });

  // const CashierOption = PosCashierList?.map((item) => ({
  //   label: i18n.language === 'en' ? item.personName : item.personName2,
  //   value: item.id,
  //   personId: item.personId,
  // }));

  return (
    <div>
      <Form onSubmit={formik.handleSubmit}>
        <div className="row mb-3">
          {/* <div className="col-lg-6">
            <div className="form-group mb-3">
              <label className="form-label">CasherId</label>
              <Select
                classNamePrefix="react-select"
                isLoading={loading2}
                isSearchable={true}
                isClearable
                options={CashierOption}
                value={CashierOption?.find(f=> f.personId === personId)}
                // onChange={(option: any) => {
                //   formik.setFieldValue('CasherId', option === null ? null : option.value);
                // }}
              />
              <Form.Control.Feedback type="invalid">{formik.errors.CasherId}</Form.Control.Feedback>
            </div>
          </div> */}

          <Col md={7} className="mb-3">
            <div className="form-group ">
              <label className="form-label mb-1">رصيــد الإفتتاح</label>
              <div className="input-group flex-nowrap">
                <Form.Control
                  className="form-control form-control-lg "
                  type="text"
                  name="OpeningBalance"
                  onChange={formik.handleChange}
                  value={formik.values.OpeningBalance}
                  // isInvalid={
                  //   !!(formik.touched.ClosingBalance && formik.errors.ClosingBalance)
                  // }
                />
                <span className="input-group-text">
                  <i className="bi bi-cash-stack fa-lg"></i>
                </span>
              </div>
              <div className="text-danger">{formik.errors.OpeningBalance}</div>
            </div>
          </Col>

          <Col md={12}>
            <div className="form-group mb-3">
              <label className="form-label">ملاحظة الإفتتاح</label>
              <Form.Control
                className="form-control"
                as="textarea"
                rows={2}
                name="OpeningNote"
                onChange={formik.handleChange}
                value={formik.values.OpeningNote}
              />
            </div>
          </Col>

          <hr />
          <div className="text-end ">
            <button
              disabled={formik.isSubmitting}
              type="submit"
              className="btn btn-lg me-1 btn-outline-theme mb-1"
              id="SaveSubmit">
              {formik.isSubmitting ? (
                <div className="spinner-border spinner-border-sm me-2"></div>
              ) : (
                <i className="fas fa-lg fa-fw me-2 bi bi-door-open"></i>
              )}
              {t('فتح الكاشير')}
            </button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default OpenSession;
