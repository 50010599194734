import { useEffect, useContext, useState, useRef } from 'react';
import { Modal } from 'bootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css'; // Import the styles
import { Link } from 'react-router-dom';
import { AppSettings } from '../../../config/app-settings';
import { Card, CardBody, CardExpandToggler, CardHeader } from '../../../components/card/card';
import useGetCategoriesList from '../../../hooks/INV/useGetCategoriesList';
import useGetItemsList from '../../../hooks/INV/MasterItems/useGetItemsList';
import Loader from '../../../components/Shared/Loader/Loader';
import DropdownMenu from './DropdownMenu';
import { Form } from 'react-bootstrap';
import NeatixModal from '../../../components/Modal/NeatixModal';
import PaymentPages from './PaymentPages';
import SwalAlert from '../../../components/Shared/Alert/SwalAlert';

import WrongSound from '../../../assets/Audio/WrongSound.mp3';

const PointOfSaleRestaurant = () => {
  const { CategoriesList } = useGetCategoriesList();
  const { MasterItemsList: ItemsList, loading } = useGetItemsList();

  //________________________________________________________________
  const QtyInputRef = useRef();
  const PriceInputRef = useRef();
  const keyPadInputRef = useRef('');
  const barcodeInputRef = useRef('');
  const scrollContainerRef = useRef(); // Refs for inputs and scroll container

  //________________________________________________________________
  const context = useContext(AppSettings);
  const [categoryId, setCategoryId] = useState('all');
  const ItemsByCategory = ItemsList?.filter((f) => f.categoryId === categoryId);
  //________________________________________________________________

  const [orders, setOrders] = useState([{ id: 1, orderData: [] }]); // Multiple orders state
  const [activeOrderIndex, setActiveOrderIndex] = useState(0); // Active order index
  //________________________________________________________________

  const [selectedItem, setSelectedItem] = useState(null);
  const [updateType, setUpdateType] = useState('');
  const [showKeyPad, setShowKeyPad] = useState(false);
  const [showModalPayment, setShowModalPayment] = useState(false);
  //________________________________________________________________

  //#region Functions --------------------------------

  const getOrderData = () => orders[activeOrderIndex].orderData;
  const setOrderData = (newOrderData) => {
    setOrders((prevOrders) => {
      const newOrders = [...prevOrders];
      newOrders[activeOrderIndex].orderData = newOrderData;
      return newOrders;
    });
  };

  const addNewOrder = () => {
    const newOrder = { id: orders?.length + 1, orderData: [] };
    setOrders([...orders, newOrder]);
    setActiveOrderIndex(orders?.length); // Set the new order as active
  };

  const handleDeleteOrder = () => {
    if (orders?.length > 1) {
      const newOrders = orders?.filter((_, index) => index !== activeOrderIndex);
      setOrders(newOrders);
      setActiveOrderIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
    } else {
      // Optionally, you can clear the order if it's the last remaining order
      setOrderData([]);
    }
  };

  const switchOrder = (index) => {
    setActiveOrderIndex(index);
    setSelectedItem(null);
    setUpdateType('');
    setShowKeyPad(false);
  };

  const getTaxesPrice = () => {
    return getOrderData()
      .reduce((total, order) => {
        return total + parseFloat(order.price) * parseInt(order.quantity) * 0.15;
      }, 0)
      .toFixed(2);
  };

  const getTotalPrice = () => {
    return getOrderData()
      .reduce((total, order) => {
        return total + parseFloat(order.price) * parseInt(order.quantity) * 1.15;
      }, 0)
      .toFixed(2);
  };

  const handleDeleteItems = () => {
    if (selectedItem !== null) {
      const updatedOrderData = getOrderData()?.filter((item) => item.id !== selectedItem);
      setOrderData(updatedOrderData);
      setSelectedItem(null);
    }
  };

  const handleInputChangeTest = (value) => {
    // if (/^\d*$/.test(value) || value === '') {

    if (/^\d*\.?\d*$/.test(value) || value === '') {
      console.log(value);
      if (updateType === 'Qty') {
        const updatedQtyOrderData = getOrderData()?.map((order) => {
          if (order.id === selectedItem) {
            return { ...order, quantity: value !== '' ? parseInt(value) : 0 };
          }
          return order;
        });
        setOrderData(updatedQtyOrderData);
      } else if (updateType === 'Price') {
        const updatedPriceOrderData = getOrderData()?.map((order) => {
          if (order.id === selectedItem) {
            return { ...order, price: value !== '' ? parseFloat(value) : 0 };
          }
          return order;
        });
        setOrderData(updatedPriceOrderData);
      }
    }
  };

  const addToCart = (item) => {
    setSelectedItem(item?.id);
    const existingItemIndex = getOrderData()?.findIndex((orderItem) => orderItem.id === item.id);
    if (existingItemIndex !== -1) {
      const updatedOrderData = getOrderData()?.map((orderItem, index) =>
        index === existingItemIndex ? { ...orderItem, quantity: orderItem.quantity + 1 } : orderItem
      );
      setOrderData(updatedOrderData);
    } else {
      setOrderData([
        ...getOrderData(),
        {
          id: item.id,
          title: item.name2,
          price: item.salesPrice,
          quantity: 1,
        },
      ]);
    }

    // Scroll to the newly added item
    scrollToNewItem();
  };

  const scrollToNewItem = () => {
    if (scrollContainerRef.current) {
      const container = scrollContainerRef.current._container;
      const items = container.querySelectorAll('.list-group-item');
      if (items?.length > 0) {
        const lastItem = items[items?.length - 1];
        const containerRect = container.getBoundingClientRect();
        const itemRect = lastItem.getBoundingClientRect();
        const scrollPosition = itemRect.bottom - containerRect.top - containerRect.height;
        container.scrollTop = scrollPosition > 0 ? scrollPosition : 0;
      }
    }
  };

  const handleButtonNumberClick = async (value) => {
    let inputvalue = keyPadInputRef.current;
    if (value === 'backspace') {
      inputvalue = inputvalue.slice(0, -1);
    } else {
      inputvalue += value;
    }
    keyPadInputRef.current = inputvalue;
    handleInputChangeTest(inputvalue);
  };

  const handleUpdateType = (type) => {
    if (updateType === type) {
      setUpdateType('');
      setShowKeyPad(false);
      barcodeInputRef.current.focus();
    } else {
      setUpdateType(type);
      setShowKeyPad(true);
    }
  };

  var handleChooseCategory = (event, id) => {
    event.preventDefault();

    if (CategoriesList) {
      // for (var i = 0; i < CategoriesList?.length; i++) {
      // 	if (CategoriesList[i].id === id || id === 'all') {
      // 		// CategoriesList[i].hide = false;
      // 	} else {
      // 		// tableData.food[i].hide = true;
      // 	}
      // }

      setCategoryId(id);

      setUpdateType('');
      setShowKeyPad(false);
    }
  };

  useEffect(() => {
    keyPadInputRef.current = '';
    // Focus the input when selectedItem or updateType changes
    if (updateType === 'Qty' && QtyInputRef.current) {
      QtyInputRef.current.focus();
    } else if (updateType === 'Price' && PriceInputRef.current) {
      PriceInputRef.current.focus();
    }
  }, [updateType, selectedItem]);

  useEffect(() => {
    context.setAppHeaderNone(true);
    context.setAppSidebarNone(true);
    context.setAppContentFullHeight(true);
    context.setAppContentClass('p-1 ps-xl-4 pe-xl-4 pt-xl-3 pb-xl-3');
    setModal(new Modal(document.getElementById('modalPosItem')));
    return function cleanUp() {
      context.setAppHeaderNone(false);
      context.setAppSidebarNone(false);
      context.setAppContentFullHeight(false);
      context.setAppContentClass('');
    };

    // eslint-disable-next-line
  }, []);
  //#endregion

  //#region Barcode Handle
  const handleBarcodeInput = (event) => {
    if (event.key === 'Enter') {
      if (document.activeElement !== barcodeInputRef.current) {
        barcodeInputRef.current.focus();
        return;
      }
      processBarcode(barcodeInputRef.current.value);
      barcodeInputRef.current.value = ''; // Clear the input after processing
    }
  };

  const processBarcode = (barcode) => {
    const item = ItemsList?.find((item) => item.barcode === barcode);
    if (item) {
      addToCart(item);
    } else {
      // here i want the alert wit sound
      SwalAlert({ text: 'هذا الصنف غير موجود', icon: 'warning', timer: 1000 });
      playAlertSound();
      console.log('Item not found');
    }
  };

  const playAlertSound = () => {
    const beep = new Audio(WrongSound);
    beep.play();
  };

  //#endregion

  const handleConfirmPayment = () => {
    if (orders?.length > 1) {
      const newOrders = orders?.filter((_, index) => index !== activeOrderIndex);
      setOrders(newOrders);
      setActiveOrderIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
    } else {
      // If it's the last order, just clear the orderData
      setOrderData([]);
    }
    setShowModalPayment(false); // Close the payment modal after handling payment
    setSelectedItem(null);
    setUpdateType('');
    setShowKeyPad(false);
  };

  //__________________________________________

  // const context = useContext(AppSettings);
  const [posMobileSidebarToggled, setPosMobileSidebarToggled] = useState(false);
  const [categoryType, setCategoryType] = useState('all');
  const [tableData, setTableData] = useState([]);
  // const [orderData, setOrderData] = useState([]);
  const [orderHistoryData, setOrderHistoryData] = useState([]);
  const [modal, setModal] = useState();
  const [modalData, setModalData] = useState();
  const [modalQuantity, setModalQuantity] = useState();
  const [modalSelectedSize, setModalSelectedSize] = useState();
  const [modalSelectedAddon, setModalSelectedAddon] = useState([]);

  var toggleMobileSidebar = (event) => {
    event.preventDefault();

    setPosMobileSidebarToggled(true);
  };

  var dismissMobileSidebar = (event) => {
    event.preventDefault();

    setPosMobileSidebarToggled(false);
  };

  var showType = (event, type) => {
    event.preventDefault();

    if (tableData && tableData.food) {
      for (var i = 0; i < tableData.food?.length; i++) {
        if (tableData.food[i].type === type || type === 'all') {
          tableData.food[i].hide = false;
        } else {
          tableData.food[i].hide = true;
        }
      }

      setTableData(tableData);
      setCategoryType(type);
    }
  };

  var showPosItemModal = (event, food) => {
    event.preventDefault();

    if (food.isActive) {
      setModalData(food);
      setModalQuantity(1);
      setModalSelectedAddon([]);

      modal.show();
    }
  };
  // console.log(modalData);
  var getOrderTotal = () => {
    return getOrderData() ? getOrderData()?.length : 0;
  };

  var getOrderHistoryTotal = () => {
    return orderHistoryData ? orderHistoryData?.length : 0;
  };

  var deductQty = (event, id) => {
    event.preventDefault();

    if (getOrderData()) {
      const newData = getOrderData()?.map((obj) => {
        if (obj.id === id) {
          var newQty = parseInt(obj.quantity) - 1;

          if (newQty < 1) {
            newQty = 1;
          }
          return { ...obj, quantity: newQty };
        }

        return obj;
      });

      setOrderData(newData);
    }
  };

  var addQty = (event, id) => {
    event.preventDefault();

    if (getOrderData()) {
      const newData = getOrderData()?.map((obj) => {
        if (obj.id === id) {
          var newQty = parseInt(obj.quantity) + 1;
          return { ...obj, quantity: newQty };
        }

        return obj;
      });

      setOrderData(newData);
    }
  };

  // var getSubTotalPrice = () => {
  // 	var value = 0;

  // 	if (orderData) {
  // 		for (var i = 0; i < orderData?.length; i++) {
  // 			value += parseFloat(orderData[i].price) * parseInt(orderData[i].quantity);
  // 		}
  // 	}
  // 	return value.toFixed(2);
  // }

  // var getTaxesPrice = () => {
  // 	var value = 0;

  // 	if (orderData) {
  // 		for (var i = 0; i < orderData?.length; i++) {
  // 			value += parseFloat(orderData[i].price) * parseInt(orderData[i].quantity) * .06;
  // 		}
  // 	}
  // 	return value.toFixed(2);
  // }

  // var getTotalPrice = () =>  {
  // 	var value = 0;

  // 	if (orderData) {
  // 		for (var i = 0; i < orderData?.length; i++) {
  // 			value += parseFloat(orderData[i].price) * parseInt(orderData[i].quantity);
  // 			value += parseFloat(orderData[i].price) * parseInt(orderData[i].quantity) * .06;
  // 		}
  // 	}
  // 	return value.toFixed(2);
  // }

  var toggleConfirmation = (event, id, value) => {
    event.preventDefault();

    if (getOrderData()) {
      const newData = getOrderData()?.map((obj) => {
        if (obj.id === id) {
          return { ...obj, confirmation: value };
        }
        return obj;
      });

      setOrderData(newData);
    }
  };

  var removeOrder = (event, id) => {
    event.preventDefault();

    if (getOrderData()) {
      const newData = getOrderData()?.filter(function (order) {
        return order.id !== id;
      });

      setOrderData(newData);
    }
  };

  var addModalQty = (event) => {
    event.preventDefault();
    if (modalQuantity) {
      var newQty = parseInt(modalQuantity) + 1;

      setModalQuantity(newQty);
    }
  };

  var deductModalQty = (event) => {
    event.preventDefault();

    if (modalQuantity) {
      var newQty = parseInt(modalQuantity) - 1;

      if (newQty < 1) {
        newQty = 1;
      }
      setModalQuantity(newQty);
    }
  };

  var handleSizeChange = (event) => {
    var value = '';
    if (event.target.checked) {
      value = event.target.value;
    }

    setModalSelectedSize(value);
  };

  var handleAddonChange = (event) => {
    var elms = [].slice.call(document.querySelectorAll('input[name="addon"]'));
    var targetValue = [];

    elms?.map(function (elm) {
      if (elm.checked) {
        targetValue.push(elm.value);
      }
      return true;
    });

    setModalSelectedAddon(targetValue);
  };

  var addToCart2 = (event) => {
    event.preventDefault();

    modal.hide();

    var options = [];
    if (modalSelectedSize) {
      var option = {
        key: 'size',
        value: modalSelectedSize,
      };
      options.push(option);
    }
    if (modalSelectedAddon) {
      for (var i = 0; i < modalSelectedAddon?.length; i++) {
        var option2 = {
          key: 'addon',
          value: modalSelectedAddon[i],
        };
        options.push(option2);
      }
    }



    const newOrder = {
      id: modalData.id,
      title: modalData.name2,
      price: modalData.salesPrice,
      quantity: modalQuantity,
      options: options,
    };

    // Use the existing `orderData` if available, otherwise use an empty array
    const currentOrderData = orders[activeOrderIndex]?.orderData || [];
 
    setTimeout(() => {
    setOrderData([...currentOrderData, newOrder]);
    }, 500);

    // setTimeout(() => {
    // setOrderData([...orderData, {
    // 	"id": (tableData.order?.length + 1),
    // 	"image": modalData.image,
    // 	"title": modalData.title,
    // 	"price": modalData.price,
    // 	"quantity": modalQuantity,
    // 	"options": options
    // }]);
    // }, 500);
  };


  console.log(getOrderData());
  return (
    <>
      <Loader loading={loading} />
      <div className="h-100">
        <Card className="pos">
          <CardBody className="pos-container">
            <div className="pos-menu">
              <div className="logo text-center p-1 text-theme">
                <Link to="">
                  {/* <div className="logo-img"><i className="bi bi-x-diamond" style={{fontSize: '2.1rem'}}></i></div> */}
                  <img
                    className="text-theme"
                    style={{ width: '60%' }}
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAMAAABHPGVmAAAAAXNSR0
IB2cksfwAAAAlwSFlzAAAWJQAAFiUBSVIk8AAAAt9QTFRFAAAA//////////////////////////
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////dplpdAAAAPV0Uk5TAB8+1u1AG93Ts/I2HcXOJQ
+r4jsRyeUi5DIS3icQvSkMv+AsDRXH2ySsOLrRpfE/z/80BhbG7hwImEkowReeRhPDhlrX2bH5Aw
cZ8ASE9WSqGDzmeGgKoMpBo3ZtSOlq6o7AAU+VZ/OC+rBbAlyFBbiHV0XcC1lO+JN/6y7QqWl56H
T2R5tCVFE5+3yBcK/+tMwet8vaK168otIqiC9L2I9TRM21daHvxOG5TK01X4qu/VD0kSbVTfx79z
prbiCD374xkmIJpLtYqGZs5z0UQzPjwjchLXoac7JWnzBSY2HsZSOWDn6Jl41VXXJvmYCnkLac1E
o0J8UoAAAJB0lEQVR4nO2Z/UNT1xnHLyUDGt4yJAFBMMQAUkroNYhBbIuAEiRDK5o5bCka20hLhz
hfwlQEo7Ui1QqBUbRTihOmrW/UysCy2lU3p8WyTa3dOl3Xl3Vt99o/YOd5zk1yEgIkIekv8/mBc8
+59z6ffO89L9974Lh7cS/cioD7vgVGoOg7/mYEBYfcLw71LyMsPCJS8t2oKf5kREtlMRwnCY+d6j
9GnDR+GpQJibHT/cWQJ8mm0aOEcEWMfxgJiVEzSKGE4+SU1Jn+YMjTZA+QIiYd/nJB0gf9oEWeoc
okheQhPmoW1GNSpT6nqNNUMNKzZvM8n41vZk7qg3G+ZWhyRHNJIQEGz9M+lhs472FfMtSPqB4lRd
58noYMegA3TTHfh1o0+aICUkju561RGAntoQsW+kyLOr+oQMtxxWm8PUIWIaVkoY+06L5XupgUxU
t4NkIeg3NLy9LkPmEsK1lOdOi/zzvGimg4+4Oych9QdCtLHydFcQbvHCkBcP6JikcmTdE9WblSTX
SsGsXg+dWgxbBG9tRkKU8b1xIGt6rKBYR/BinPGnOqJ4Oofq7oh4RRs5bJzOLWaYBSoKpVTwKy3l
iLDCZxxY82MJSMjUDZVJpv8FrHZlMdKWp+zGTdspUL28bU63GcbDc2eKtlc2Md+aE1O8z2nJU7SX
tMPEPZBVqqt4sbvNPyvKgeHvluhsG/gKn2GO0tTXuhydBc+qI3lPvE+MC5hH377SlXwDhXv8RgD7
Tg1dUNpqc9p0wV7dPQo+hWJqdFyal3MfW2duF6Q7P5J55SQkXPaKzHD3cwWpLCXmYYnQdtdxgOmQ
s8o7wSvA4G2k+pVwzrYPIeZo43UEbec9C3tEdUXZ5QZqamJJPiVVVbGNXSzbuKqD2UcdT8Myg1x3
qed58REIxT7E4RzwfmYkuLxQWjYgb9BaATJmpOe0zsthkPC8eFLzQWMvVST3JwwyjGli48o8Q1IB
uWTk6yzl2bLJfiEj795zTX8Txs3RPlxDBtxhegz2nCqngN1JLDY90ySnFJaEZm2pJ20HV8a4UDw3
gCW9VPWRvMYGeIzVS4YZM1SSJgtKTa872Gg5J7XcQwqk6iDvWpJluTGJ9UQojisYkYCaepQUxOsd
18pk6Lp8722Rl9J6mONxhu/Dlsy0p5cwKbLE8TPUCPgqRWxjEJNpwvYXQ8TnXsZRiyWUKOoMDxbb
I6QzXXehwjPLD6BMpgXkn/DroU/mLA3qYatGXJTZWOY5TUx9DwxmVhbQ5Q9u+jjCmMjv4LqEP7Fq
uDMpRD9M7uMSm6HOwh+tmdlJIbyPNH0fhwmWUM45d08niS0WHOxCbNS4JNls1TjqHjiAr6et5Rno
9Af8hNe/tiMR68wz6rvfistBcYHY078TL5aSIJ30euItGlFk1+KYxaPS4W86iW6XSxCGX6c/+v6P
t418ToOI/aNOVQiaA2ucylTc4vWky66qXL9L5fy+1nXmljfvQbl7BtPdOfzXTMcDn9WA3BJ7y0ZO
Eoo6RrQMOrKy9R0UirsZ6CV2ON/ZfpnWcZHSW/QYYux9qwQrDJV+ROkGUmnEd1c2xhhUR2MjouUx
1PMDqKfkt11PbbmgSb3FfuBKk3T4FrqzfaQiuceZSds65ia1cp03TtPbys5rh9+ZThXsJQsMUJMr
wkGKYedV2SELs2CmcMaxjKwFuk5bqYZ6Pwfbwub7W1YQSxQ7+z5Dq/FEniyFZSnFtAL4yaYTtjeJ
YxRQO7ucxG3jEK6bWLhOWz9PdQi5O2/cGZQbSkV9yAS3GKb6T36XGYELPLUG6KeeeQRdIMEVDJxi
U468Dh9tEM+LRVALudUCrpE1B2HKFaNhWNSuwQEXRGhInIhAx5Yuw5VwxC6VXAmZmyCvouh8kAbq
ZDfrtxfMrhAEp5s2c3vp/E+DEYZHG3RMDFg3QiUhIGb6KU6u2jtfSzy/5qSrmBPlbYIRkjhgq7b1
mP9fRD1LSSPrHmJmfIyy2stUyJtmW5dGXL4OjcjJbUBXn0SG01iqYPdKilod8Bsf+ylthkpmFFsp
BDfVv13ngMMr5Te5HCGF7TckGLA+U2jP1oKdOSTkeWOkf16vgMokVhAeFTmV5rob9x0dtMxiq62L
I2uepDZPxxIh0Qr8fDLoOhwDYCN9CXOHzR4XG1URsclmRt6McPFXWtav3EDI77U08r6VGGk8KHYt
QenACHW3nHKKT9KdJqkw9hNzxl+si9T+HBylY9ofwZKRW0P+s7eOcQhnTLAag01cJP0Z4auOMWAi
g99aRHGa6SsVFxHXUol4xiEDNO31U7rGn0I3uZ6Y77n/RL714BLX+pqqT+WX/aBYPnuz/Gswc7m3
C7QLvMfR0QJ7L/ClpefAFrn7AfcIw34mdTLQc/lUNxoe8D7ZgZXUWX+Qj5cQZq3Hcxo73t/XCG0k
op+IwWm296xuC4D82fCY+3+HMm60gQMe5M/aLNXW8yr/WUwXEnTc14U/HfGB0jsOQkhDCU04KL+8
j8mecMjlteegi0fMHOJufxzDvMGn/mJjb9XVVXM06useNL1VfkxylzGEogrJjR7Fs5ih5uruhrLz
dXar7s+4oUynIm54IsTsluSMxHjzn32tdjeF834lPzHdK/Prlyxp61MzKJYdDt59Ds+klseNX8w4
iU15i8PcxxZx5cNWXknxLvGWSg/KvvXULR/ZvZ+LCHBUd8Ztlx/WQYsMN57QtCubXOBSMQGbMKb0
96m1v/+V3ot8nSUYxgNKM3Dqf7YCtd/5/GnURLUIoTIwq/cc/9d/WiyTMIJaMSDFtWiANDhqvJx9
tm35rgdjdDssQIlj/rIZaBa/xQm8WF4fUuhtNTYc7Kldn7MTW86a4Nr3chsShgRd96TWCY0IzKE2
VjmlFvIqw3AvKFUopgeNPixzGjXlG2oU3OhCc20EUNb5mPGRw3o7Ab1qdBM1+Ee7PE8H7jawbYZE
seKa5X4s6se0bR84hU9IKBDUCGG4bXu2hXHBe+D9SrVGf9wyA2eYT+M05dK3bL8HoXS3ta4WPllC
dG0fMYrKzXe2oUvaD01d8s8qsOiG/umnb4GUHixFVvTNy9+H+M/wG6XUxANHXSuwAAAABJRU5Erk
Jggg=="
                    alt=""
                  />
                  <div className="logo-text">Neatix - Pos</div>
                </Link>
              </div>

              <div className="nav-container">
                <PerfectScrollbar className="h-100">
                  <ul className="nav nav-tabs">
                    {CategoriesList &&
                      CategoriesList?.map((category, index) => (
                        <li className="nav-item" key={index}>
                          <a
                            className={'nav-link' + (category.id === categoryId ? ' active' : '')}
                            onClick={(event) => handleChooseCategory(event, category.id)}
                            href="#/">
                            <Card>
                              <CardBody>
                                <i
                                  className={`${category?.icon?.replace(/\bfa-lg\b\s*/, '')} fa ${
                                    category.id === categoryId ? ' text-theme' : ''
                                  } `}></i>{' '}
                                {category.name2}
                              </CardBody>
                            </Card>
                          </a>
                        </li>
                      ))}
                  </ul>
                </PerfectScrollbar>
              </div>
            </div>

            <div className="pos-content">
              {!showKeyPad && (
                <PerfectScrollbar className="pos-content-container h-100 p-4">
                  <div className="row gx-4">
                    {ItemsByCategory?.length ? (
                      ItemsByCategory?.map((item, index) => (
                        <div
                          className={
                            'col-xxl-3 col-xl-3 col-lg-6 col-md-3 col-sm-6 pb-4' +
                            (item.hide ? ' d-none' : '')
                          }
                          key={index}>
                          <Card className="h-100">
                            <CardBody className="h-100 p-1">
                              <Link
                                to=""
                                className={'pos-product' + (!item.isActive ? ' not-available' : '')}
                                onClick={(event) => showPosItemModal(event, item)}
                                // onClick={() => addToCart(item)}
                              >
                                <div
                                  className="img shadow"
                                  style={{
                                    backgroundImage:
                                      'url(https://media.licdn.com/dms/image/D4D12AQEFpeQHJkC50g/article-cover_image-shrink_720_1280/0/1667896412196?e=2147483647&v=beta&t=EEBUH-kM2vS3WF2KlDYBY6U6EaNAdj_WSwKLOR7UbMM)',
                                    minHeight: '6.25rem',
                                  }}></div>
                                <div className="info m-0 p-2">
                                  <div className="title mb-1 fs-6">{item.name2}</div>
                                  {/* <div className="desc">{item.name2}</div> */}
                                  <div className="price opacity-50 fs-6">${item.salesPrice}</div>
                                </div>
                                {!item?.isActive && (
                                  <div className="not-available-text">
                                    <div>غير متوفر</div>
                                  </div>
                                )}
                              </Link>
                            </CardBody>
                          </Card>
                        </div>
                      ))
                    ) : (
                      <div className="pt-5 h-100 d-flex align-items-center justify-content-center text-center p-20">
                        <div>
                          <div className="mb-3 mt-n5">
                            <i
                              className="bi bi-ban-fill text-inverse text-opacity-50"
                              style={{ fontSize: '7em' }}></i>
                          </div>
                          <h5>No items available at the moment</h5>
                        </div>
                      </div>
                    )}
                  </div>
                </PerfectScrollbar>
              )}

              {showKeyPad && (
                <CardBody className="h-75 m-5 border border-theme shadow">
                  <table
                    style={{
                      width: '100%',
                      tableLayout: 'fixed',
                      height: '100%',
                      direction: 'ltr',
                    }}
                    className="p-3">
                    <tbody>
                      <tr>
                        {[7, 8, 9]?.map((item) => (
                          <td style={{ padding: '0' }} key={item} className="shadow">
                            <button
                              type="button"
                              className="btn btn-lg btn-outline-theme  fw-bold rounded-0 w-100 h-100"
                              onClick={() => handleButtonNumberClick(item)}>
                              <h1>{item}</h1>
                            </button>
                          </td>
                        ))}
                        <td style={{ padding: '0' }} className="shadow">
                          <button
                            type="button"
                            className="btn btn-lg btn-secondary fw-bold rounded-0 w-100 h-100"
                            onClick={() => handleButtonNumberClick('backspace')}>
                            <h1> ⌫ </h1>
                          </button>
                        </td>
                      </tr>
                      <tr>
                        {[4, 5, 6]?.map((item) => (
                          <td style={{ padding: '0' }} key={item} className="shadow">
                            <button
                              type="button"
                              className="btn btn-lg btn-outline-theme rounded-0 w-100 h-100"
                              onClick={() => handleButtonNumberClick(item)}>
                              <h1>{item}</h1>
                            </button>
                          </td>
                        ))}
                        <td style={{ padding: '0', height: '50px' }} rowSpan="3" className="shadow">
                          <button
                            type="button"
                            className="btn btn-lg btn-outline-theme rounded-0 w-100 h-100"
                            style={{ height: '100%' }}
                            onClick={() => {
                              setUpdateType('');
                              setShowKeyPad(false);
                              barcodeInputRef.current.focus();
                            }}>
                            <h1>Enter</h1>
                          </button>
                        </td>
                      </tr>
                      <tr>
                        {[1, 2, 3]?.map((item) => (
                          <td style={{ padding: '0' }} key={item} className="shadow">
                            <button
                              type="button"
                              className="btn btn-lg btn-outline-theme rounded-0 w-100 h-100"
                              onClick={() => handleButtonNumberClick(item)}>
                              <h1>{item}</h1>
                            </button>
                          </td>
                        ))}
                      </tr>
                      <tr>
                        <td style={{ padding: '0' }} colSpan="2" className="shadow">
                          <button
                            type="button"
                            className="btn btn-lg btn-outline-theme rounded-0 w-100 h-100"
                            onClick={() => handleButtonNumberClick(0)}>
                            <h1>{0}</h1>
                          </button>
                        </td>
                        <td style={{ padding: '0' }} className="shadow">
                          <button
                            type="button"
                            className="btn btn-lg btn-outline-theme  rounded-0 w-100 h-100"
                            onClick={() => handleButtonNumberClick('.')}>
                            <h1>.</h1>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </CardBody>
              )}
            </div>

            {/* pos-sidebar */}
            <div className="pos-sidebar" id="pos-sidebar">
              <div className="h-100 d-flex flex-column p-0">
                <CardHeader className="align-items-center bg-inverse bg-opacity-10 p-0">
                  <div className="pos-sidebar-header m-0 p-0 ">
                    <div className="d-flex justify-content-between w-100 px-0 pt-0">
                      <ul className="nav nav-pills">
                        {orders?.map((order, index) => (
                          <li key={order.id} className="nav-item border">
                            <Link
                              to=""
                              className={`nav-link rounded-0 fs-6 fw-bold px-3 ${
                                activeOrderIndex === index ? 'active' : ''
                              }`}
                              onClick={() => switchOrder(index)}
                              data-bs-toggle="tab">
                              #{order.id}
                            </Link>
                          </li>
                        ))}
                      </ul>
                      <button
                        className="btn btn-sm btn-outline-theme rounded-0"
                        onClick={addNewOrder}>
                        <i className="bi bi-plus fa-lg fw-bold fs-4"></i>
                      </button>
                    </div>
                  </div>
                </CardHeader>

                <div className="pos-sidebar-header px-2 py-1 ">
                  <div className="title ">
                    <div className="input-group w-75 flex-nowrap">
                      <span className="input-group-text p-1">
                        <i className="bi bi-upc fa-lg fs-2"></i>
                      </span>
                      <input
                        className="form-control form-control-sm fw-bold rounded-0 text-center"
                        type="text"
                        ref={barcodeInputRef}
                        onKeyPress={handleBarcodeInput}
                      />
                    </div>
                  </div>
                  <div className="order">
                    <DropdownMenu handleDeleteOrder={handleDeleteOrder} />
                  </div>
                </div>
                <hr className="m-0 p-0" />

                <PerfectScrollbar
                  ref={scrollContainerRef}
                  options={{ suppressScrollX: true }}
                  className="pos-sidebar-body tab-content h-100">
                  <div className="tab-pane fade h-100 show active" id="newOrderTab">
                    {getOrderData() && getOrderData()?.length > 0 ? (
                      getOrderData()?.map((order, index) => (
                        <div className="pos-order" key={index}>
                          <div className="pos-order-product">
                            <div
                              className="img"
                              style={{
                                backgroundImage:
                                  'url(https://media.licdn.com/dms/image/D4D12AQEFpeQHJkC50g/article-cover_image-shrink_720_1280/0/1667896412196?e=2147483647&v=beta&t=EEBUH-kM2vS3WF2KlDYBY6U6EaNAdj_WSwKLOR7UbMM)',
                              }}></div>
                            <div className="flex-1">
                              <div className="h6 mb-1">{order.title}</div>
                              <div className="small">${order.price}</div>
                              <div className="small mb-2">
                                {order.options &&
                                  order.options?.map((option, index) => (
                                    <div key={index}>
                                      - {option.key}: {option.value}
                                    </div>
                                  ))}
                              </div>
                              <div className="d-flex">
                                <a
                                  href="#/"
                                  className="btn btn-outline-theme btn-sm"
                                  onClick={(event) => deductQty(event, order.id)}>
                                  <i className="fa fa-minus"></i>
                                </a>
                                <input
                                  type="text"
                                  className="form-control w-50px form-control-sm mx-2 bg-white bg-opacity-25 bg-white bg-opacity-25 text-center"
                                  value={order.quantity}
                                  readOnly
                                />
                                <a
                                  href="#/"
                                  className="btn btn-outline-theme btn-sm"
                                  onClick={(event) => addQty(event, order.id)}>
                                  <i className="fa fa-plus"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="pos-order-price d-flex flex-column">
                            <div>${(order.price * order.quantity).toFixed(2)}</div>
                            <div className="text-end mt-auto">
                              <button
                                onClick={(event) => toggleConfirmation(event, order.id, true)}
                                className="btn btn-sm btn-outline-gray-500">
                                <i className="fa fa-trash"></i>
                              </button>
                            </div>
                          </div>
                          {order.confirmation && (
                            <div className="pos-order-confirmation text-center d-flex flex-column justify-content-center">
                              <div className="mb-1">
                                <i className="bi bi-trash fs-36px lh-1"></i>
                              </div>
                              <div className="mb-2">Remove this item?</div>
                              <div>
                                <button
                                  onClick={(event) => toggleConfirmation(event, order.id, false)}
                                  className="btn btn-outline-white btn-sm ms-auto me-2 width-100px">
                                  No
                                </button>
                                <button
                                  onClick={(event) => removeOrder(event, order.id)}
                                  className="btn btn-outline-theme btn-sm width-100px">
                                  Yes
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      ))
                    ) : (
                      <div className="h-100 d-flex align-items-center justify-content-center text-center p-20">
                        <div>
                          <div className="mb-3 mt-n5">
                            <i
                              className="bi bi-bag text-white text-opacity-50"
                              style={{ fontSize: '6em' }}></i>
                          </div>
                          <h5>No order found</h5>
                        </div>
                      </div>
                    )}
                  </div>
                </PerfectScrollbar>

                <div className="pos-sidebar-footer">
                  <div className="d-flex align-items-center mb-2">
                    <div className="fw-bold">Total</div>
                    <div className="flex-1 text-end h4 mb-0">${getTotalPrice()}</div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div>Taxes (15%)</div>
                    <div className="flex-1 text-end h6 mb-0">${getTaxesPrice()}</div>
                  </div>

                  <div className="mt-3">
                    <div className="btn-group d-flex">
                      <button
                        disabled={getOrderData()?.length === 0}
                        className="btn btn-danger rounded-0"
                        onClick={() => handleDeleteItems()}>
                        <i className="bi bi-trash fa-lg fw-bold fs-4"></i>
                      </button>
                      <button
                        disabled={getOrderData()?.length === 0}
                        className={`btn rounded-0 m-0  ${
                          updateType === 'Price' ? 'btn-theme' : 'btn-outline-theme'
                        }`}
                        onClick={() => handleUpdateType('Price')}>
                        <span className="fw-bold fs-4">السعر</span>
                      </button>
                      <button
                        disabled={getOrderData()?.length === 0}
                        className={`btn rounded-0 m-0  ${
                          updateType === 'Qty' ? 'btn-theme' : 'btn-outline-theme'
                        }`}
                        onClick={() => handleUpdateType('Qty')}>
                        <span className="fw-bold fs-4">الكمية</span>
                      </button>
                    </div>

                    <div className="btn-group d-flex mt-2">
                      <button
                        disabled={getOrderData()?.length === 0}
                        className="btn btn-outline-primary rounded-0 p-0 "
                        style={{ width: '230px', height: '80px' }}
                        onClick={() => setShowModalPayment(true)}>
                        <span
                          className="fw-bold fs-3 text-start"
                          style={{ letterSpacing: '0.1em' }}>
                          دفع
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>

        <div className="modal modal-pos fade" id="modalPosItem">
          <div className="modal-dialog modal-lg">
            <div className="modal-content border-0">
              {modalData && (
                <Card>
                  <CardBody className="p-0">
                    <button
                      data-bs-dismiss="modal"
                      className="btn-close position-absolute top-0 end-0 m-4">
                      &nbsp;
                    </button>
                    <div className="modal-pos-product">
                      <div className="modal-pos-product-img">
                        <div
                          className="img"
                          style={{
                            backgroundImage:
                              'url(https://media.licdn.com/dms/image/D4D12AQEFpeQHJkC50g/article-cover_image-shrink_720_1280/0/1667896412196?e=2147483647&v=beta&t=EEBUH-kM2vS3WF2KlDYBY6U6EaNAdj_WSwKLOR7UbMM)',
                          }}></div>
                      </div>
                      <div className="modal-pos-product-info">
                        <div className="h4 mb-2">{modalData.name2}</div>
                        <div className="text-white text-opacity-50 mb-2">
                          {modalData.description}
                        </div>
                        <div className="h4 mb-3">SAR {modalData?.salesPrice?.toLocaleString()}</div>
                        <div className="d-flex mb-3">
                          <button
                            className="btn btn-outline-theme"
                            onClick={(event) => deductModalQty(event)}>
                            <i className="fa fa-minus"></i>
                          </button>
                          <input
                            type="text"
                            value={modalQuantity}
                            readOnly
                            className="form-control w-50px fw-bold mx-2 bg-white bg-opacity-25 border-0 text-center"
                          />
                          <button
                            className="btn btn-outline-theme"
                            onClick={(event) => addModalQty(event)}>
                            <i className="fa fa-plus"></i>
                          </button>
                        </div>
                        <hr className="mx-n4" />

                        {/* Radio button */}
                        {modalData && modalData.options && modalData.options.size && (
                          <div className="mb-2">
                            <div className="fw-bold">Size:</div>
                            <div className="option-list">
                              {modalData.options.size?.map((size, index) => (
                                <div className="option" key={index}>
                                  <input
                                    type="radio"
                                    id={'size' + index}
                                    name="size"
                                    className="option-input"
                                    defaultValue={size.text}
                                    onChange={(event) => handleSizeChange(event)}
                                    defaultChecked={index === 0 ? true : false}
                                  />
                                  <label className="option-label" htmlFor={'size' + index}>
                                    <span className="option-text">{size.text}</span>
                                    <span className="option-price">+{size.price}</span>
                                  </label>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}

                        {/* checkbox*/}
                        {modalData && modalData.options && modalData.options.addon && (
                          <div className="mb-2">
                            <div className="fw-bold">Add On:</div>

                            <div className="option-list">
                              {modalData.options.addon?.map((addon, index) => (
                                <div className="option" key={index}>
                                  <input
                                    type="checkbox"
                                    name="addon"
                                    className="option-input"
                                    onChange={(event) => handleAddonChange(event)}
                                    value={addon.text}
                                    id={'addon' + index}
                                  />
                                  <label className="option-label" htmlFor={'addon' + index}>
                                    <span className="option-text">{addon.text}</span>
                                    <span className="option-price">+{addon.price}</span>
                                  </label>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}

                        <hr className="mx-n4" />
                        <div className="row">
                          <div className="col-4">
                            <button
                              className="btn btn-default h4 mb-0 d-block w-100 rounded-0 py-3"
                              data-bs-dismiss="modal">
                              Cancel
                            </button>
                          </div>
                          <div className="col-8">
                            <button
                              className="btn btn-success w-100 d-flex justify-content-center align-items-center rounded-0 py-3 h4 m-0"
                              onClick={(event) => addToCart2(event)}>
                              Add to cart <i className="bi bi-plus fa-2x ms-2 my-n3"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              )}
            </div>
          </div>
        </div>
      </div>

      <NeatixModal
        fullscreen={true}
        setShow={setShowModalPayment}
        show={showModalPayment}
        showHeader={false}>
        <PaymentPages
          order={orders[activeOrderIndex]}
          setShowModalPayment={setShowModalPayment}
          handleConfirmPayment={handleConfirmPayment}
        />
      </NeatixModal>
    </>
  );
};

export default PointOfSaleRestaurant;

//#region  Commants

// {/* <div className="tab-content p-4">
//   <div className="tab-pane fade show active" id="homeWithCard">...</div>
//   <div className="tab-pane fade" id="profileWithCard">...</div>
// </div> */}

// barcode barcode reader
// const handleBarcodeInput = (event) => {
// 	console.log(event.key);
//     barcodeInputRef.current += event.key;
//     if (event.key === 'Enter') {
//       const barcode = barcodeInputRef.current.trim();
//       const matchedItem = ItemsList?.find((item) => item.barcode === barcode);
//       if (matchedItem) {
//         addToCart({ preventDefault: () => {} }, matchedItem);
//       }
//       barcodeInputRef.current = '';
//     }
//   };

//   useEffect(() => {
//     window.addEventListener('keydown', handleBarcodeInput);
//     return () => {
//       window.removeEventListener('keydown', handleBarcodeInput);
//     };
//   }, [ItemsList, orderData]);

// data-bs-toggle="collapse"
// data-bs-target="#inProgress2Board"
// {/* <div className="collapse " id="inProgress2Board"> */}

// <div className="pos-order p-1" key={index}>
// <div className="pos-order-product">
//   {/* <div className="img" style={{backgroundImage: 'url('+ order.image +')'}}></div> */}
//   <div className="flex-1">
// 	<div className="h6 mb-1">{order.title}</div>
// 	<div className="small">${order.price}</div>
// 	<div className="small mb-2">
// 	  {order.options &&
// 		order.options?.map((option, index) => (
// 		  <div key={index}>
// 			- {option.key}: {option.value}
// 		  </div>
// 		))}
// 	</div>
// 	{/* <div className="d-flex">
// 			<a href="#/" className="btn btn-outline-theme btn-sm" onClick={(event) => deductQty(event, order.id)}><i className="fa fa-minus"></i></a>
// 			<input type="text" className="form-control w-50px form-control-sm mx-2 bg-white bg-opacity-25 bg-white bg-opacity-25 text-center" value={order.quantity} readOnly />
// 			<a href="#/" className="btn btn-outline-theme btn-sm" onClick={(event) => addQty(event, order.id)}><i className="fa fa-plus"></i></a>
// 		</div> */}
//   </div>
// </div>
// <div className="pos-order-price d-flex flex-column">
//   <div>${(order.price * order.quantity).toFixed(2)}</div>
//   <div className="text-end mt-auto">
// 	<button
// 	  onClick={(event) => toggleConfirmation(event, order.id, true)}
// 	  className="btn btn-sm btn-outline-gray-500">
// 	  <i className="fa fa-trash"></i>
// 	</button>
//   </div>
// </div>
// {order.confirmation && (
//   <div className="pos-order-confirmation text-center d-flex flex-column justify-content-center p-1">
// 	<div className="mb-1">
// 	  <i className="bi bi-trash fs-36px lh-1"></i>
// 	</div>
// 	{/* <div className="mb-2">Remove this item?</div> */}
// 	<div>
// 	  <button
// 		onClick={(event) => toggleConfirmation(event, order.id, false)}
// 		className="btn btn-outline-gray-500 btn-sm ms-auto me-2 width-100px">
// 		No
// 	  </button>
// 	  <button
// 		onClick={(event) => removeOrder(event, order.id)}
// 		className="btn btn-outline-theme btn-sm width-100px">
// 		Yes
// 	  </button>
// 	</div>
//   </div>
// )}
// </div>

// //___________ Modal

// <div className="modal modal-pos fade" id="modalPosItem">
// <div className="modal-dialog modal-lg">
//   <div className="modal-content border-0">
// 	{modalData && (
// 	  <Card>
// 		<CardBody className="p-0">
// 		  <button
// 			data-bs-dismiss="modaxl"
// 			className="btn-close position-absolute top-0 end-0 m-4">
// 			&nbsp;
// 		  </button>
// 		  <div className="modal-pos-product">
// 			<div className="modal-pos-product-img">
// 			  <div
// 				className="img"
// 				style={{
// 				  backgroundImage:
// 					'url(https://media.licdn.com/dms/image/D4D12AQEFpeQHJkC50g/article-cover_image-shrink_720_1280/0/1667896412196?e=2147483647&v=beta&t=EEBUH-kM2vS3WF2KlDYBY6U6EaNAdj_WSwKLOR7UbMM)',
// 				}}></div>
// 			</div>
// 			<div className="modal-pos-product-info">
// 			  <div className="h4 mb-2">{modalData.name2}</div>
// 			  <div className="text-white text-opacity-50 mb-2">
// 				{modalData.description}
// 			  </div>
// 			  <div className="h4 mb-3">SAR {modalData?.salesPrice?.toLocaleString()}</div>
// 			  <div className="d-flex mb-3">
// 				<button
// 				  className="btn btn-outline-theme"
// 				  onClick={(event) => deductModalQty(event)}>
// 				  <i className="fa fa-minus"></i>
// 				</button>
// 				<input
// 				  type="text"
// 				  value={modalQuantity}
// 				  readOnly
// 				  className="form-control w-50px fw-bold mx-2 bg-white bg-opacity-25 border-0 text-center"
// 				/>
// 				<button
// 				  className="btn btn-outline-theme"
// 				  onClick={(event) => addModalQty(event)}>
// 				  <i className="fa fa-plus"></i>
// 				</button>
// 			  </div>
// 			  <hr className="mx-n4" />

// 			  {/* Radio button */}
// 			  {modalData && modalData.options && modalData.options.size && (
// 				<div className="mb-2">
// 				  <div className="fw-bold">Size:</div>
// 				  <div className="option-list">
// 					{modalData.options.size?.map((size, index) => (
// 					  <div className="option" key={index}>
// 						<input
// 						  type="radio"
// 						  id={'size' + index}
// 						  name="size"
// 						  className="option-input"
// 						  defaultValue={size.text}
// 						  onChange={(event) => handleSizeChange(event)}
// 						  defaultChecked={index === 0 ? true : false}
// 						/>
// 						<label className="option-label" htmlFor={'size' + index}>
// 						  <span className="option-text">{size.text}</span>
// 						  <span className="option-price">+{size.price}</span>
// 						</label>
// 					  </div>
// 					))}
// 				  </div>
// 				</div>
// 			  )}

// 			  {/* checkbox*/}
// 			  {modalData && modalData.options && modalData.options.addon && (
// 				<div className="mb-2">
// 				  <div className="fw-bold">Add On:</div>

// 				  <div className="option-list">
// 					{modalData.options.addon?.map((addon, index) => (
// 					  <div className="option" key={index}>
// 						<input
// 						  type="checkbox"
// 						  name="addon"
// 						  className="option-input"
// 						  onChange={(event) => handleAddonChange(event)}
// 						  value={addon.text}
// 						  id={'addon' + index}
// 						/>
// 						<label className="option-label" htmlFor={'addon' + index}>
// 						  <span className="option-text">{addon.text}</span>
// 						  <span className="option-price">+{addon.price}</span>
// 						</label>
// 					  </div>
// 					))}
// 				  </div>
// 				</div>
// 			  )}

// 			  <hr className="mx-n4" />
// 			  <div className="row">
// 				<div className="col-4">
// 				  <button
// 					className="btn btn-default h4 mb-0 d-block w-100 rounded-0 py-3"
// 					data-bs-dismiss="modal">
// 					Cancel
// 				  </button>
// 				</div>
// 				<div className="col-8">
// 				  <button
// 					className="btn btn-success w-100 d-flex justify-content-center align-items-center rounded-0 py-3 h4 m-0"
// 					onClick={(event) => addToCart(event)}>
// 					Add to cart <i className="bi bi-plus fa-2x ms-2 my-n3"></i>
// 				  </button>
// 				</div>
// 			  </div>
// 			</div>
// 		  </div>
// 		</CardBody>
// 	  </Card>
// 	)}
//   </div>
// </div>
// </div>

// const panelDiget = (newDigit) => {
// 	if (selectedItem !== null) {
// 	  let newQuantity = '';

// 	  // If the current quantity is not null or undefined, concatenate the new digit
// 	  if (orderData[selectedItem]?.quantity !== null && orderData[selectedItem]?.quantity !== undefined) {
// 		newQuantity = orderData[selectedItem].quantity.toString() + newDigit.toString();
// 	  } else {
// 		// If the current quantity is null or undefined, set the new digit as the quantity
// 		newQuantity = newDigit.toString();
// 	  }

// 	  const updatedOrderData = orderData?.map(item =>
// 		item.id === selectedItem ? { ...item, quantity: parseInt(newQuantity) } : item
// 	  );
// 	  setOrderData(updatedOrderData);
// 	}
//   };

// {/* <Container>
//                   <Row xs={4} md={12} lg={12}>
// 				  <Col className="p-0">
//                         <button
//                           type="button"
//                           className="btn btn-lg btn-outline-secondary rounded-0 w-100 h-50px"
// 						  onClick={() => handleButtonClick('backspace')}
//                         >
//                           ⌫
//                         </button>
//                       </Col>
//                     {[9, 8, 7 ]?.map((number) => (
//                       <Col className="p-0" key={number}>
//                         <button
//                           type="button"
//                           className="btn btn-lg btn-outline-secondary rounded-0 w-100 h-50px"
// 						  onClick={() => handleButtonClick(number)}
//                         >
//                           {number}
//                         </button>
//                       </Col>
//                     ))}
//                   </Row>
// 				  <Row xs={4} md={12} lg={12} >
//                     {[6, 5, 4 ]?.map((number) => (
//                       <Col className="p-0" key={number}>
//                         <button
//                           type="button"
//                           className="btn btn-lg btn-outline-secondary rounded-0 w-100 h-50px"
// 						  onClick={() => handleButtonClick(number)}
//                         >
//                           {number}
//                         </button>
//                       </Col>
//                     ))}
//                   </Row>
//                   <Row xs={4} md={12} lg={12}>
//                     {[3, 2, 1 ]?.map((number) => (
//                       <Col className="p-0" key={number}>
//                         <button
//                           type="button"
//                           className="btn btn-lg btn-outline-secondary rounded-0 w-100 h-50px"
// 						  onClick={() => handleButtonClick(number)}
//                         >
//                           {number}
//                         </button>
//                       </Col>
//                     ))}
//                   </Row>

//                   <Row xs={12} md={12} lg={12}>
//                     {["Enter"]?.map((number) => (
//                       <Col className="p-0 m-auto" key={number}>
//                         <button
//                           type="button"
//                           className="btn btn-lg btn-outline-secondary rounded-0 w-100 h-50px"
//                         >
//                           {number}
//                         </button>
//                       </Col>
//                     ))}
//                   </Row>
//                 </Container> */}

//________________________________________________________________
//   const [keyPadInput, setKeyPadInput] = useState('');

//   const handleInputChange = (e) => {
//     const name = e.target.name;
//     const inputValue = e.target.value;

//     // Only allow numeric input or backspace
//     if (/^\d*$/.test(inputValue) || inputValue === '') {
//       if (name === 'Qty') {
//         const updatedQtyOrderData = orderData?.map((order) => {
//           if (order.id === selectedItem) {
//             return { ...order, quantity: inputValue !== '' ? parseInt(inputValue) : 0 };
//           }
//           return order;
//         });
//         setOrderData(updatedQtyOrderData);
//       } else if (name === 'price') {
//         const updatedPriceOrderData = orderData?.map((order) => {
//           if (order.id === selectedItem) {
//             return { ...order, price: inputValue !== '' ? parseInt(inputValue) : 0 };
//           }
//           return order;
//         });
//         setOrderData(updatedPriceOrderData);
//       }
//     }
//   };

//   const handleButtonClick = (value) => {
//     if (value === 'backspace') {
//       setKeyPadInput((prev) => prev.slice(0, -1));
//     } else if (value === 'Enter') {
//       if (selectedItem !== null && keyPadInput !== '') {
//         const updatedOrderData = orderData?.map((order) => {
//           if (order.id === selectedItem) {
//             if (updateType === 'Qty') {
//               return { ...order, quantity: parseInt(keyPadInput) };
//             } else if (updateType === 'Price') {
//               return { ...order, price: parseFloat(keyPadInput) };
//             }
//           }
//           return order;
//         });
//         setOrderData(updatedOrderData);
//         setSelectedItem(null);
//       }
//       setKeyPadInput('');
//     } else {
//       setKeyPadInput((prev) => prev + value);
//     }
//   };

//   useEffect(() => {
//     if (selectedItem !== null) {
//       const updatedOrderData = orderData?.map((order) => {
//         if (order.id === selectedItem) {
//           if (updateType === 'Qty' && keyPadInput !== '') {
//             return { ...order, quantity: parseInt(keyPadInput) };
//           } else if (updateType === 'Price' && keyPadInput !== '') {
//             return { ...order, price: parseFloat(keyPadInput) };
//           }
//         }
//         return order;
//       });
//       setOrderData(updatedOrderData);
//     }
//   }, [keyPadInput]);

//#endregion
