import { useFormik } from 'formik';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import Select from 'react-select';
import { Card } from '../../../../components/card/card';
import { CardBody, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useLookupCurrency from '../../../../hooks/Lookups/use-Lookup-Currency';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { useAppDispatch } from '../../../../store/hooks';
import { useNavigate } from 'react-router-dom';
import Loading from '../../../../components/Shared/Loader/Loading';
import { useEffect, useState } from 'react';
import useGetSuppliersList from '../../../../hooks/NTX/Peoples/useGetSuppliersList';
import { TreeSelect } from 'antd';
import useGetPaymentMethod from '../../../../hooks/Payrolls/use-Get-PaymentMethod';
import confirmAction from '../../../../components/Shared/Alert/confirmAction';
import {
  addApPayments,
  getApPaymentsList,
} from '../../../../store/Finance/AP/ApPayments/ApPaymentsSlice';
import useGetBankAccountsList from '../../../../hooks/Finance/BankAccounts/useGetBankAccountsList';
import useGetPaymentTypeList from '../../../../hooks/Finance/ApPayments/useGetPaymentTypeList';
import useGetCostCenters from '../../../../hooks/Finance/ChartAccountsDetails/useGetCostCenters';
import useGetAccounts from '../../../../hooks/Finance/ChartAccountsDetails/useGetAccounts';
import { AddApPaymentsSchema } from '../../../ValidationForm/validationSchema';
// import useGetGlDailyRatesList from '../../../../hooks/Finance/DailyRates/useGetGlDailyRatesList';
// import useGetGlLedgersList from '../../../../hooks/Finance/useGetGlLedgersList';
// import useGetConsolidationSet from '../../../../hooks/Finance/ConsolidationSet/useGetConsolidationSet';
import useGetBranchOrgWithPermission from '../../../../hooks/NTX/Organization/useGetBranchOrgWithPermission';
import useConversionRateByOrganization from '../../../../hooks/Finance/DailyRates/useConversionRateByOrganization';

const Add = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { Currency, loading: loading4 } = useLookupCurrency();

  const { BranchOrgOptions: BranchsOptions, loading: loading3 } = useGetBranchOrgWithPermission();
  const { treeSuppliersData, error: error2, loading: loading2 } = useGetSuppliersList();
  const { PaymentMethodList } = useGetPaymentMethod();
  const { TreeCostCenters } = useGetCostCenters();
  const { OptionsAccounts, loading, error } = useGetAccounts();
  const { groupedOptions, loading: loading5, error: error5 } = useGetBankAccountsList();
  const { PaymentTypeList, loading2: loading6, error: error6 } = useGetPaymentTypeList();


   

  //#region Formik
  const formik = useFormik({
    initialValues: {
      paymentDate: '',
      apBankAccountId: '',
      paymentMethodId: '',
      amount: 0,
      currencyCode: '',
      currencyConversionRate: 1,
      peopleId: '',
      organizationId: '',
      accountId: '',
      costCenterId: '',
      paymentType: '',
    },

    enableReinitialize: true,
    validationSchema: AddApPaymentsSchema(t),

    onSubmit: async (values) => {
      let isConfirmation = await confirmAction('هل أنت متأكد من أنك تريد إضافة هذا السداد؟');
      if (!isConfirmation) {
        formik.setSubmitting(false);
        return;
      }

      dispatch(
        addApPayments({
          apBankAccountId: values.apBankAccountId,
          paymentMethodId: values.paymentMethodId,
          paymentDate: values.paymentDate,
          amount: values.amount,
          currencyCode: values.currencyCode,
          // currencyConversionRate: values.currencyConversionRate,
          currencyConversionRate: conversionRate,
          peopleId: values.peopleId,
          organizationId: values.organizationId,
          accountId: values.accountId,
          costCenterId: values.costCenterId,
          paymentType: values.paymentType,
        })
      )
        .unwrap()
        .then((res: any) => {
          // debugger;
          // console.log(res);

          if (res?.succeeded === true) {
            CustomAlert({ action: 'Add' });
            dispatch(getApPaymentsList());
            formik.resetForm();
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error', msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });
  const { values, handleChange: formikhandleChange, errors, touched, setFieldValue } = formik;
  //#endregion

  const conversionRate = useConversionRateByOrganization({
    organizationId: values.organizationId,
    currencyCode: values.currencyCode,
    date: values.paymentDate,
  });

  //#region BcrumbList
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'Ap Payments',
      link: '/Finance/Ap/ApPayments',
    },
    {
      name: 'Add Ap Payments',
      link: null,
    },
  ];
  //#endregion

  return (
    <>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName="Add Ap Payments" />
      </div>

      <Card>
        <Loading loading={loading5 || loading2 || loading3 || loading4}>
          <CardBody>
            <Form onSubmit={formik.handleSubmit}>
              <div className="row">
                {/* طريقة الدفع */}
                <div className="col-lg-4  ">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('PaymentMethod')}</label>
                    <Form.Select
                      className="form-select"
                      name="paymentMethodId"
                      onChange={formik.handleChange}
                      value={formik.values.paymentMethodId || ''}
                      // isInvalid={
                      //   !!(formik.touched.paymentMethodId && formik.errors.paymentMethodId)
                      // }
                    >
                      <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                      {PaymentMethodList?.map((item: any, idx: number) => (
                        <option key={++idx} value={item.id}>
                          {i18n.language === 'ar' ? item.name : item.name2}
                        </option>
                      ))}
                    </Form.Select>
                    <div className="text-danger small">{formik.errors.paymentMethodId}</div>
                  </div>
                </div>

                {/* التاريخ */}
                <div className="col-lg-2  ">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('التاريخ')}</label>
                    <Form.Control
                      className="form-control"
                      type="date"
                      name="paymentDate"
                      onChange={formikhandleChange}
                      value={values.paymentDate || ''}
                      // isInvalid={!!(touched.paymentDate && errors.paymentDate)}
                    />
                    <div className="text-danger small">{formik.errors.paymentDate}</div>
                  </div>
                </div>

                {/* المبلغ */}
                <div className="col-lg-2  ">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('المبلغ')}</label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      name="amount"
                      onChange={formikhandleChange}
                      value={values.amount || ''}
                      onInput={(e) => {
                        const input = e.target as HTMLInputElement;
                        input.value = input.value.replace(/[^0-9]/g, '');
                      }}
                    />
                    <div className="text-danger small">{errors.amount}</div>
                  </div>
                </div>

                {/* ('Currency') */}
                <div className="col-lg-2">
                  <div className="form-group mb-3">
                    <label className="form-label"> {t('Currency')} </label>
                    <Form.Select
                      className="form-select"
                      name="currencyCode"
                      onChange={formikhandleChange}
                      value={values.currencyCode || ''}
                      // isInvalid={!!(touched.currencyCode && errors.currencyCode)}
                    >
                      <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>

                      {Currency &&
                        Currency?.map((item: any, idx: number) => (
                          <option key={++idx} value={item.currencyCode}>
                            {item.description}
                          </option>
                        ))}
                    </Form.Select>
                    <div className="text-danger small">{formik.errors.currencyCode}</div>
                  </div>
                </div>
                {/* ('معدل العملة') */}
                <div className="col-lg-2 ">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('معدل العملة')}</label>
                    <Form.Control
                      readOnly
                      className="form-control  text-center"
                      type="text"
                      name="currencyConversionRate"
                      // onChange={formikhandleChange}
                      // value={values.currencyConversionRate || ''}
                      value={conversionRate}
                      // isInvalid={!!(touched.currencyConversionRate && errors.currencyConversionRate)}
                    />
                    <div className="text-danger small">{formik.errors.currencyConversionRate}</div>
                  </div>
                </div>
                {/* apBankAccountId */}
                <div className="col-lg-4 ">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('apBankAccountId')}</label>

                    <Select
                      classNamePrefix="react-select"
                      isLoading={loading5}
                      isSearchable={true}
                      isClearable
                      options={groupedOptions}
                      value={groupedOptions?.find(
                        (f: any) => f?.value === formik.values.apBankAccountId
                      )}
                      onChange={(option: any) =>
                        setFieldValue('apBankAccountId', option === null ? null : option?.value)
                      }
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                    />
                    <div className="text-danger small">{formik.errors.apBankAccountId}</div>
                  </div>
                </div>
                {/* {t('المورد')} */}
                <div className="col-lg-4 ">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('المورد')}</label>
                    <TreeSelect
                      showSearch
                      className="w-100"
                      treeLine
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                      allowClear
                      treeDefaultExpandAll={false} // لا تقم بتوسيع كل العقد افتراضيًا
                      // loadData={} // يتم تحميل البيانات عند الطلب
                      // onSearch={} // تنفيذ البحث عند كتابة المستخدم
                      value={values.peopleId}
                      onChange={(option) => {
                        formik.setFieldValue('peopleId', option === null ? null : option);
                      }}
                      treeData={treeSuppliersData}
                      treeNodeFilterProp="title"
                    />
                    <div className="text-danger small">{formik.errors.peopleId}</div>
                  </div>
                </div>

                {/* ('الفرع') */}
                <div className="col-lg-4  ">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('الفرع')}</label>

                    <Select
                      classNamePrefix="react-select"
                      isLoading={loading3}
                      isSearchable={true}
                      isClearable
                      options={BranchsOptions}
                      value={BranchsOptions?.find(
                        (f: any) => f.value === formik.values.organizationId
                      )}
                      onChange={(option: any) =>
                        setFieldValue('organizationId', option === null ? null : option.value)
                      }
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                    />
                    <div className="text-danger small">{formik.errors.organizationId}</div>
                  </div>
                </div>
                {/* ('Accounts') */}
                <div className="col-lg-4 ">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('Accounts')}</label>
                    <Select
                      classNamePrefix="react-select"
                      className=" w-100"
                      isLoading={loading}
                      isSearchable={true}
                      isClearable
                      name="accountId"
                      options={OptionsAccounts}
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                      onChange={(option) =>
                        setFieldValue('accountId', option === null ? null : option.value)
                      }
                      // value={OptionsAccounts?.find((f) => f.value === selectedAccounts.account.id)}
                    />
                    <div className="text-danger small">{formik.errors.accountId}</div>
                  </div>
                </div>
                {/* ('CostCenter') */}
                <div className="col-lg-4 ">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('CostCenter')}</label>
                    <TreeSelect
                      showSearch
                      treeData={TreeCostCenters}
                      style={{ borderRadius: '1px !important' }}
                      className="custom-tree-select w-100  "
                      treeLine
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                      allowClear
                      treeDefaultExpandAll
                      getPopupContainer={(triggerNode) => triggerNode.parentNode}
                      onChange={(option) =>
                        setFieldValue('costCenterId', option === null ? null : option)
                      }
                      value={formik.values.costCenterId}
                    />
                    <div className="text-danger small">{formik.errors.costCenterId}</div>
                  </div>
                </div>
                {/* نوع الدفع */}
                <div className="col-lg-4">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('نوع الدفغ')}</label>
                    <Form.Select
                      className="form-select"
                      name="paymentType"
                      onChange={formikhandleChange}
                      value={values.paymentType || ''}>
                      <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                      {PaymentTypeList?.map((item, idx) => (
                        <option key={idx} value={item.id}>
                          {i18n.language === 'en' ? item.name : item.name2}
                        </option>
                      ))}
                    </Form.Select>
                    <div className="text-danger small">{formik.errors.paymentType}</div>
                  </div>
                </div>
              </div>

              <div className="col-12 text-center mt-2">
                <button
                  disabled={formik.isSubmitting}
                  type="submit"
                  className="btn btn-lg me-1 btn-theme mb-1">
                  {formik.isSubmitting ? (
                    <div className="spinner-border spinner-border-sm me-2"></div>
                  ) : (
                    <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
                  )}
                  {t('Add')}
                </button>
                <button
                  type="button"
                  className="btn btn-lg me-1 btn-default mb-1"
                  onClick={() => navigate('/Finance/Ap/ApPayments', { replace: true })}>
                  <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
                </button>
              </div>
            </Form>
          </CardBody>
        </Loading>
      </Card>
    </>
  );
};

export default Add;
