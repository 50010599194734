import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import Loader from '../../../../components/Shared/Loader/Loader.js';
import { Card, CardBody } from '../../../../components/card/card.jsx';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import MyRequestsList from './MyRequestsList.js';
import useGetDynamicForm from '../../../../hooks/Forms/useGetDynamicForm.js';

const MyRequests = () => {
  const [search, setSearch] = useState('');
  const { t, i18n } = useTranslation();
  const { loading } = useSelector((state) => state.AmeRequest);
  const { DynamicFormAme, loading: loadingform } = useGetDynamicForm();

  const FormAme = DynamicFormAme?.map((item) => ({
    value: item.id,
    label: i18n.language === 'ar' ? item.name2 : item.name,
    icon: <i className={item.icon + ' text-theme fs-4'}></i>,
  }));

  //________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },

    {
      name: t('MyRequests'),
      link: null,
    },
  ];

  return (
    <>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName={t('MyRequests')} />
      <Card>
        <CardBody>
          <Loader loading={loading} />
          <div className="p-2">
            <div className="row ps-2">
              <div className="col-sm-12 col-md-5 mb-1">
                <div className="input-group flex-nowrap mb-3">
                  <span className="input-group-text">{t('TypeOfRequest')}</span>

                  <Select
                    classNamePrefix="react-select"
                    className="w-100"
                    isLoading={loadingform}
                    isSearchable={true}
                    isClearable
                    options={FormAme}
                    placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                    onChange={(option) => setSearch(option === null ? '' : option.label)}
                    formatOptionLabel={(option) => (
                      <div className="d-flex align-items-center py-1px ">
                        {option.icon}
                        <span className="ms-2">{option.label}</span>
                      </div>
                    )}
                  />
                </div>
              </div>

              <div className="col-sm-12 col-md-3 mb-1">
                <div className="input-group flex-nowrap mb-3">
                  <span className="input-group-text">{t('Date')}</span>
                  <input
                    type="date"
                    className="form-control text-start"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>

              <div className="col-sm-12 col-md-4 mb-1">
                <div className="input-group flex-nowrap mb-3">
                  <span className="input-group-text"> {t('NumberOfRequest')}</span>
                  <input
                    type="text"
                    className="form-control text-start"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <MyRequestsList search={search} />
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default MyRequests;

// <div className="table-responsive">
//   <table className="table ">
//     <thead className="table-dark">
//       <tr>
//         <th scope="col">{t("TypeOfRequest")}</th>
//         <th scope="col">{t("Name")}</th>
//         <th scope="col">{t("Date")}</th>
//         <th scope="col">{t("Status")}</th>
//         <th scope="col"> </th>
//       </tr>
//     </thead>
//     <tbody>
//       {currentData?.map((item, index) => (
//         <tr key={item.transectionHistoryId}>
//           <td className="align-middle">
//             {" "}
//             {i18n.language === "ar"
//               ? item.requestName2
//               : item.requestName}{" "}
//           </td>
//           <td className="align-middle">
//             {i18n.language === "ar"
//               ? item.personName2
//               : item.personName}
//           </td>
//           <td className="align-middle">{item.dateCreated}</td>
//           <td className="align-middle">{item.status}</td>
//           <td className="align-middle">
//             <button
//               onClick={() =>
//                 Navigate(
//                   `/SelfService/Requests/${item.transectionHistoryId}/OnlyViewRequests`
//                 )
//               }
//               type="button"
//               className="btn btn-outline-theme"
//             >
//               <i className="fas fa-lg fa-fw me-2 fa-info-circle"></i>
//               View
//             </button>
//           </td>
//         </tr>
//       ))}
//     </tbody>
//   </table>
// </div>
// <Pagination
//   filteredData={filteredSearch}
//   dataPerPage={dataPerPage}
//   handlePagination={(pageNumber) => setCurrentPage(pageNumber)}
//   currentPage={currentPage}
// />
