import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Card, CardBody } from '../../../../components/card/card';
import Loader from '../../../../components/Shared/Loader/Loader';
import Pagination from '../../../../components/Shared/Pagination/Pagination';
import { GetPersonlListWithPayrol } from '../../../../store/HR/Payroll/runPayrollSlice';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import Loading from '../../../../components/Shared/Loader/Loading';
import MedModalComponent from '../../../../components/Modal/MedModalComponent';
import AddQuickPay from './AddQuickPay';

const QuickPay = () => {
  const { t, i18n } = useTranslation();
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const [person, setPerson] = useState();

  const { PersonlListWithPayrol, loading, error } = useSelector((state) => state.runPayroll);

  useEffect(() => {
    if (PersonlListWithPayrol?.length === 0) {
      dispatch(GetPersonlListWithPayrol());
    }
  }, [dispatch]);

  const filteredPerson = PersonlListWithPayrol?.slice()?.filter((item) => {
    const searchLowerCase = search.toLowerCase();
    return (
      item.personName?.toLowerCase().includes(searchLowerCase) ||
      item.personName2?.toLowerCase().toString().includes(searchLowerCase) ||
      item.employeeNumber?.toString().includes(searchLowerCase)
    );
  });

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredPerson?.slice(indexOfFirstData, indexOfLastData);

  //_____________________

  // const handleNavigate = (item) => {
  //   Navigate(`${item.payrollId}/${item.assignmentId}/AddQuickPay`, {
  //     state: {
  //       ReferenceName: i18n.language === 'en' ? item.personName : item.personName2,
  //     },
  //   });
  // };


 

  const titleModal = () => {
console.log(person);
    return (
      <div className="">
        {t('QuickPay')}
        {'  '}
        <span className="text-theme ms-2">
          {`${i18n.language === 'en' ? person?.personName : person?.personName2}`}
        </span>
      </div>
    );
  };

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },

    {
      name: t('QuickPay'),
      link: null,
    },
  ];

  return (
    <>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName={t('QuickPay')} />
      <Card>
        <Loading loading={loading} error={error}>
          <CardBody>
            <div className="tab-content p-2">
              <div className="row">
                <div className="col-7 col-md-6 d-flex justify-content-start">
                  <div className="input-group mb-2">
                    <div className="flex-fill position-relative">
                      <div className="input-group">
                        <div
                          className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                          style={{ zIndex: 1020, right: '10px' }}>
                          <i className="fa fa-search opacity-5"></i>
                        </div>
                        <input
                          type="text"
                          className="form-control ps-35px"
                          onChange={(e) => setSearch(e.target.value)}
                          placeholder={t('Search') + ' ...'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* //  START TABLE   */}
              <div className="table-responsive">
                <table className="table">
                  <thead className="table-dark">
                    <tr>
                      <th scope="col">{t('EmployeeName')}</th>
                      <th scope="col">{t('EmployeeNumber')}</th>
                      <th scope="col">{t('Payroll')}</th>

                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData?.map((item, index) => (
                      <tr key={++index}>
                        <td className="align-middle">
                          {i18n.language === 'ar' ? item.personName2 : item.personName}
                        </td>
                        <td className="align-middle">{item.employeeNumber}</td>
                        <td className="align-middle">
                          {i18n.language === 'ar' ? item.payrollName2 : item.payrollName}
                        </td>
                        <td className="align-middle text-end">
                          <div className="btn-group">
                            <button
                              type="button"
                              // onClick={() => handleNavigate(item)}
                              data-bs-toggle="modal"
                              data-bs-target="#CreateQuickPay"
                              onClick={() => setPerson(item)}
                              className="btn btn-outline-theme">
                              <i className="fas fa-lg fa-fw me-2 fa-money-bill-alt"></i>
                              {t('QuickPay')}
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Pagination
                filteredData={PersonlListWithPayrol}
                dataPerPage={dataPerPage}
                handlePagination={(pageNumber) => setCurrentPage(pageNumber)}
                currentPage={currentPage}
              />
            </div>
          </CardBody>
        </Loading>
      </Card>

      <MedModalComponent id="CreateQuickPay" title={titleModal()}>
        {person && <AddQuickPay person={person} />}
      </MedModalComponent>
    </>
  );
};

export default QuickPay;
