

import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

import { GetEquipmentsList } from "../../../store/FLEET/Equipments/equipmentsSlice";
import Breadcrumb from "../../../components/Shared/Breadcrumb";
import { Card, CardBody } from "../../../components/card/card";
import { GetNtxPeoplesList } from "../../../store/NTX/Peoples/peoplesSlice";
import { useNavigate } from "react-router-dom";
import OrderMaintenanceForm from "./OrderMaintenanceForm";

const AddOrderMaintenance = () => {

  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(GetNtxPeoplesList(""));
  }, []);



  //_______________
  var BcrumbList = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "MaintenanceOrder",
      link: "/FLEET/MaintenanceOrder",
    },
    {
      name: "AddMaintenanceOrder",
      link: null,
    },
  ];

  return (
    <div>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName="AddMaintenanceOrder" />

      <Card>
        <CardBody>
          
<OrderMaintenanceForm/>
          
        </CardBody>
      </Card>
    </div>
  );
};

export default AddOrderMaintenance;




          // <>
          // <Form onSubmit={formik.handleSubmit}>
          //   <div className="row">
          //     <div className="col-xl-4">
          //       <div className="form-group mb-3">
          //         <label className="form-label">orderCode</label>
          //         <Form.Control
          //           className="form-control"
          //           type="text"
          //           name="orderCode"
          //           onChange={formik.handleChange}
          //           value={formik.values.orderCode}
          //           isInvalid={
          //             !!(formik.touched.orderCode && formik.errors.orderCode)
          //           }
          //         />
          //         <Form.Control.Feedback type="invalid">
          //           {formik.errors.orderCode}
          //         </Form.Control.Feedback>
          //       </div>
          //     </div>

          //     <div className="col-xl-4">
          //       <div className="form-group mb-3">
          //         <label className="form-label"> المعدة</label>
          //         <Form.Select
          //           className="form-select"
          //           name="fleetEquipmentsId"
          //           onChange={formik.handleChange}
          //           value={formik.values.fleetEquipmentsId || ""}
          //           isInvalid={
          //             !!(formik.touched.fleetEquipmentsId && formik.errors.fleetEquipmentsId)
          //           }
          //         >
          //           <option value="">
          //             {i18n.language === "ar" ? "إختر" : "Choose"}
          //           </option>
          //           {EquipmentsList &&
          //             EquipmentsList?.map((item, idx) => (
          //               <option key={++idx} value={item.id}>
          //                 {i18n.language === "ar" ? item.name2 : item.name}
          //               </option>
          //             ))}
          //         </Form.Select>
          //       </div>
          //     </div>

          //     <div className="col-xl-4">
          //       <div className="form-group mb-3">
          //         <label className="form-label">داخلي او خارجي</label>
          //         <Form.Select
          //           className="form-select"
          //           name="isInternal"
          //           onChange={formik.handleChange}
          //           value={formik.values.isInternal || ""}
          //           isInvalid={
          //             !!(formik.touched.isInternal && formik.errors.isInternal)
          //           }
          //         >

          //           <option value="1">داخلي</option>
          //           <option value="0">خارجي</option>
          //         </Form.Select>
          //       </div>
          //     </div>

          //     {formik.values.isInternal === "1" && (
          //       <div className="col-xl-4">
          //         <div className="form-group mb-3">
          //           <label className="form-label"> الورشة </label>
          //           <Form.Select
          //             className="form-select"
          //             name="workshopId"
          //             onChange={formik.handleChange}
          //             value={formik.values.workshopId}
          //             isInvalid={
          //               !!(
          //                 formik.touched.workshopId && formik.errors.workshopId
          //               )
          //             }
          //           >
          //             <option value="">
          //               {i18n.language === "ar" ? "إختر" : "Choose"}
          //             </option>
          //             {Organizations &&
          //             Organizations?.filter((f: any) => f.type === "160")?.map((item : any, idx : any) => (
          //               <option key={++idx} value={item.id}>
          //                 {i18n.language === "ar" ? item.name2 : item.name}
          //               </option>
          //             ))}
          //           </Form.Select>
          //         </div>
          //       </div>
          //     )}
          //     {formik.values.isInternal === "0" && (
          //       <div className="col-xl-4">
          //         <div className="form-group mb-3">
          //           <label className="form-label"> المورد</label>
          //           <Form.Select
          //             className="form-select"
          //             name="supplierId"
          //             onChange={formik.handleChange}
          //             value={formik.values.supplierId || ""}
          //             isInvalid={
          //               !!(
          //                 formik.touched.supplierId && formik.errors.supplierId
          //               )
          //             }
          //           >
          //             <option value="">
          //               {i18n.language === "ar" ? "إختر" : "Choose"}
          //             </option>
          //             {PeoplesList &&
          //               PeoplesList?.filter((f) => f.typeCode === "SUPPLIERS")?.map(
          //                 (item, idx) => (
          //                   <option key={++idx} value={item.id}>
          //                     {i18n.language === "ar" ? item.name2 : item.name}
          //                   </option>
          //                 )
          //               )}
          //           </Form.Select>
          //         </div>
          //       </div>
          //     )}

          //     <div className="col-xl-4">
          //       <div className="form-group mb-3">
          //         <label className="form-label">تاريخ البداية المتوقع</label>
          //         <Form.Control
          //           className="form-control"
          //           type="datetime-local"
          //           name="startCompletionSugg"
          //           onChange={formik.handleChange}
          //           value={formik.values.startCompletionSugg}
          //           isInvalid={
          //             !!(
          //               formik.touched.startCompletionSugg &&
          //               formik.errors.startCompletionSugg
          //             )
          //           }
          //         />
          //         <Form.Control.Feedback type="invalid">
          //           {formik.errors.startCompletionSugg}
          //         </Form.Control.Feedback>
          //       </div>
          //     </div>
          //     <div className="col-xl-4">
          //       <div className="form-group mb-3">
          //         <label className="form-label">تاريخ نهاية المتوقع</label>
          //         <Form.Control
          //           className="form-control"
          //           type="datetime-local"
          //           name="endCompletionSugg"
          //           onChange={formik.handleChange}
          //           value={formik.values.endCompletionSugg}
          //           isInvalid={
          //             !!(
          //               formik.touched.endCompletionSugg &&
          //               formik.errors.endCompletionSugg
          //             )
          //           }
          //         />
          //         <Form.Control.Feedback type="invalid">
          //           {formik.errors.endCompletionSugg}
          //         </Form.Control.Feedback>
          //       </div>
          //     </div>
          //   </div>

          //   <div className="row mt-3">
          //     <div className="col-xl-3">
          //       <div className="input-group">
          //         <span className="input-group-text">سعر القطع</span>
          //         <Form.Control
          //           className="form-control"
          //           type="number"
          //           name="totalItems"
          //           onChange={formik.handleChange}
          //           value={formik.values.totalItems}
          //           isInvalid={
          //             !!(formik.touched.totalItems && formik.errors.totalItems)
          //           }
          //         />
          //         <Form.Control.Feedback type="invalid">
          //           {formik.errors.totalItems}
          //         </Form.Control.Feedback>
          //       </div>
          //     </div>
          //     <div className="col-xl-3">
          //       <div className="input-group">
          //         <span className="input-group-text">أجر العمال</span>
          //         <Form.Control
          //           className="form-control"
          //           type="number"
          //           name="totalAsset"
          //           onChange={formik.handleChange}
          //           value={formik.values.totalAsset}
          //           isInvalid={
          //             !!(formik.touched.totalAsset && formik.errors.totalAsset)
          //           }
          //         />
          //         <Form.Control.Feedback type="invalid">
          //           {formik.errors.totalAsset}
          //         </Form.Control.Feedback>
          //       </div>
          //     </div>
          //     <div className="col-xl-3">
          //       <div className="input-group">
          //         <span className="input-group-text">أجور أخرى</span>
          //         <Form.Control
          //           className="form-control"
          //           type="number"
          //           name="otherAmounts"
          //           onChange={formik.handleChange}
          //           value={formik.values.otherAmounts}
          //           isInvalid={
          //             !!(
          //               formik.touched.otherAmounts &&
          //               formik.errors.otherAmounts
          //             )
          //           }
          //         />
          //         <Form.Control.Feedback type="invalid">
          //           {formik.errors.otherAmounts}
          //         </Form.Control.Feedback>
          //       </div>
          //     </div>
          //     <div className="col-xl-3">
          //       <div className="input-group">
          //         <span className="input-group-text">الإجمالي</span>
          //         <Form.Control
          //           className="form-control"
          //           type="number"
          //           name="totalBudget"
          //           onChange={formik.handleChange}
          //           readOnly
          //           value={formik.values.totalBudget}
          //           isInvalid={
          //             !!(
          //               formik.touched.totalBudget && formik.errors.totalBudget
          //             )
          //           }
          //         />
          //         <Form.Control.Feedback type="invalid">
          //           {formik.errors.totalBudget}
          //         </Form.Control.Feedback>
          //       </div>
          //     </div>
          //   </div>

          //   {/* <div className="col-xl-4">
          //       <div className="form-group mb-3">
          //         <label className="form-label">تاريخ بدء التنفيذ</label>
          //         <Form.Control
          //           className="form-control"
          //           type="datetime"
          //           name="startDateExecution"
          //           onChange={formik.handleChange}
          //           value={formik.values.startDateExecution}
          //           isInvalid={
          //             !!(
          //               formik.touched.startDateExecution &&
          //               formik.errors.startDateExecution
          //             )
          //           }
          //         />
          //         <Form.Control.Feedback type="invalid">
          //           {formik.errors.startDateExecution}
          //         </Form.Control.Feedback>
          //       </div>
          //     </div> */}

          //   <div className="text-center mt-5">
          //     <button
          //       disabled={formik.isSubmitting}
          //       type="submit"
          //       className="btn me-1 btn-outline-theme mb-1"
          //     >
          //       {formik.isSubmitting ? (
          //         <div className="spinner-border spinner-border-sm me-2"></div>
          //       ) : (
          //         <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
          //       )}
          //       {t("Add")}
          //     </button>
          //     <button
          //       type="button"
          //       className=" btn me-1 btn-outline-default mb-1"
          //       onClick={() => Navigate("/FLEET/WorkRequests" , {replace:true})}
          //     >
          //       <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
          //     </button>
          //   </div>
          // </Form>
          // </>