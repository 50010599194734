import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { PeriodsModels } from '../../../../store/Finance/Periods/PeriodsModels';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import { Form } from 'react-bootstrap';
import { UpdatGlPeriods, GetGlPeriodsList } from '../../../../store/Finance/Periods/PeriodsSlice';
import { GlPeriodsSchema } from '../../../ValidationForm/validationSchema';

interface Props {
  DataById: PeriodsModels;
}

const UpdateAccountingPeriod = ({ DataById }: Props) => {
  const { i18n, t } = useTranslation();
  const dispatch = useAppDispatch();

  const { PeriodsList } = useAppSelector((state) => state.Periods);

  const formik = useFormik({
    initialValues: {
      activeFlag: true,
      name: DataById.name ?? '',
      name2: DataById.name2 ?? '',
      startDate: DataById.startDate ?? '',
      endDate: DataById.endDate ?? '',
      periodYear: DataById.periodYear ?? '',
      adjustmentPeriodFlag: DataById.adjustmentPeriodFlag ?? '',
      description: DataById.description ?? '',
    },
    enableReinitialize: true,
    validationSchema: GlPeriodsSchema(t),
    onSubmit: (values) => {
      dispatch(
        UpdatGlPeriods({
          id: DataById.id,
          name: values.name,
          name2: values.name2,
          startDate: values.startDate,
          endDate: values.endDate,
          periodYear: values.periodYear,
          adjustmentPeriodFlag: values.adjustmentPeriodFlag,
          description: '',
          activeFlag: values.activeFlag,
        })
      )
        .unwrap()
        .then((res: any) => {
          if (res?.succeeded === true) {
            CustomAlert({ action: 'Edit' });
            document.getElementById('closeModalForm')?.click();
            formik.resetForm();
            dispatch(GetGlPeriodsList());
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error', msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });

  return (
    <div>
      <Form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-xl-4">
            <div className="form-group mb-3">
              <label className="form-label">{t('LocalName')}</label>
              <Form.Control
                className="form-control text-center"
                type="text"
                name="name2"
                onChange={formik.handleChange}
                value={formik.values.name2}
                isInvalid={!!(formik.touched.name2 && formik.errors.name2)}
              />
              <div className="text-danger small">{formik.touched.name2 && formik.errors.name2}</div>
            </div>
          </div>
          <div className="col-xl-4 ">
            <div className="form-group mb-3">
              <label className="form-label">{t('GlobalName')}</label>
              <Form.Control
                className="form-control text-center"
                type="text"
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                isInvalid={!!(formik.touched.name && formik.errors.name)}
              />
              <div className="text-danger small">{formik.touched.name && formik.errors.name}</div>
            </div>
          </div>

          <div className="col-xl-4">
            <div className="form-group mb-3">
              <label className="form-label">السنة المالية</label>
              <Form.Select
                className="form-select text-center"
                name="periodYear"
                value={formik.values.periodYear || ''}
                onChange={formik.handleChange}
                isInvalid={!!(formik.touched.periodYear && formik.errors.periodYear)}>
                <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                {PeriodsList &&
                  PeriodsList?.filter((f) => f.periodYear === null)?.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </Form.Select>
              <div className="text-danger small">
                {formik.touched.periodYear && formik.errors.periodYear}
              </div>
            </div>
          </div>

          <div className="col-xl-3">
            <div className="form-group mb-3">
              <label className="form-label">{t('Start Date')}</label>
              <Form.Control
                className="form-control text-center"
                type="date"
                name="startDate"
                onChange={formik.handleChange}
                value={formik.values.startDate}
                isInvalid={!!(formik.touched.startDate && formik.errors.startDate)}
              />
              <div className="text-danger small">
                {formik.touched.startDate && formik.errors.startDate}
              </div>
            </div>
          </div>

          <div className="col-xl-3 ">
            <div className="form-group mb-3">
              <label className="form-label">{t('End_Date')}</label>
              <Form.Control
                className="form-control text-center"
                type="date"
                name="endDate"
                onChange={formik.handleChange}
                value={formik.values.endDate}
                isInvalid={!!(formik.touched.endDate && formik.errors.endDate)}
              />
              <div className="text-danger small">
                {formik.touched.endDate && formik.errors.endDate}
              </div>
            </div>
          </div>

          <div className="col-xl-2">
            <div className="form-group mb-3">
              <Form.Check
                style={{ margin: '10px 20px' }}
                className="pt-3"
                type="checkbox"
                label="نشط ؟"
                name="activeFlag"
                checked={formik.values.activeFlag}
                onChange={formik.handleChange}
              />
            </div>
          </div>
          <div className="col-xl-4">
            <div className="form-group mb-3">
              {/* <label className="form-label">{t("adjustment Period")}</label> */}
              <Form.Check
                style={{ margin: '10px 20px' }}
                className="pt-3"
                type="checkbox"
                label="هل هي فترة تعديل؟"
                name="adjustmentPeriodFlag"
                // value={dayValues.isFlexible || ""}
                checked={formik.values.adjustmentPeriodFlag}
                onChange={formik.handleChange}
              />
            </div>
          </div>
        </div>

        <div className="text-center">
          <button
            disabled={formik.isSubmitting}
            type="submit"
            className="btn me-1 btn-outline-theme mb-1">
            {formik.isSubmitting ? (
              <div className="spinner-border spinner-border-sm me-2"></div>
            ) : (
              <i className="fas fa-lg fa-fw me-2 fa fa-save"></i>
            )}
            {t('Save')}
          </button>
          <button
            type="button"
            className=" btn me-1 btn-outline-default mb-1"
            data-bs-dismiss="modal"
            id="closeModalForm">
            <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
          </button>
        </div>
      </Form>
    </div>
  );
};

export default UpdateAccountingPeriod;
