import { useFormik } from 'formik';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import { useDispatch } from 'react-redux';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import useGetPersons from '../../../../hooks/HR/use-get-persons.js';
import { ActionRequest, GetRequestList } from '../../../../store/Ame/ameRequestSlice.js';
import { OptionAme } from '../../../../assets/OptionAme';

const ActionRequestForm = ({ data }) => {
  const { TransactionHistoryId } = useParams();
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { Employees } = useGetPersons();

  const formik = useFormik({
    initialValues: {
      transectionHistoryId: TransactionHistoryId,
      action: '',
      personId: null,
      toPersonId: '',
      userComment: '',
    },
    validationSchema: Yup.object().shape({
      action: Yup.string()
        .required(() => t('Required'))
        .notOneOf(['إختر', 'Choose'], 'Required'),

      toPersonId: Yup.string().when('action', {
        is: (action) => ['QUESTION', 'TRANSFER']?.includes(action),
        then: () => Yup.string().required(() => t('Required')),
      }),
    }),

    onSubmit: (values, { resetForm }) => {
      dispatch(
        ActionRequest({
          transectionHistoryId: values.transectionHistoryId,
          action: values.action,
          personId: null,
          toPersonId:
            values.action === 'TRANSFER' || values.action === 'QUESTION' ? values.toPersonId : null,
          userComment: values.userComment,
        })
      )
        .unwrap()
        .then((res) => {
          resetForm();
          CustomAlert({ action: 'Add', msg: 'تمت العملية بنجاح' });
          dispatch(GetRequestList());
          Navigate(-1, { replace: true });
        })
        .catch((error) => {
          console.log(error);
          CustomAlert({ action: 'Error' });
          formik.setSubmitting(false);
        });
    },
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <div className="row">
        <div className="col-xl-12 mx-auto">
          <div className="form-group mb-3">
            <label className="form-label">{t('RequestAction')}</label>

            <Select
              classNamePrefix="react-select"
              className="text-center"
              isSearchable={false}
              options={
                data.isAnswer === true
                  ? OptionAme?.filter((f) => f.value === 'ANSWER')
                  : data.is_Information
                  ? OptionAme?.filter((f) => f.value === 'NOTIFIED')
                  : OptionAme?.filter((f) => f.value !== 'ANSWER' && f.value !== 'NOTIFIED')
              }
              onChange={(option) => formik.setFieldValue('action', option.value)}
              placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
            />

            <Form.Control.Feedback type="invalid">{formik.errors.action}</Form.Control.Feedback>
          </div>
        </div>

        {(formik.values.action === 'TRANSFER' || formik.values.action === 'QUESTION') && (
          <div className="col-xl-12 position-relative mx-auto">
            <div className="form-group mb-3">
              <label className="form-label">{t('To')}</label>

              <Select
                classNamePrefix="react-select"
                className="text-center"
                isSearchable={true}
                name="toPersonId"
                options={Employees?.map((item) => ({
                  value: item.id,
                  label: i18n.language === 'ar' ? item.name2 : item.name,
                }))}
                onChange={(option) => formik.setFieldValue('toPersonId', option.value)}
                placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
              />
              {formik.errors.toPersonId && formik.touched.toPersonId && (
                <div style={{ color: 'red' }}>{formik.errors.toPersonId}</div>
              )}
            </div>
          </div>
        )}

        <div className="col-xl-12 mx-auto">
          <div className="form-group mb-3">
            <label className="form-label">{t('Note')}</label>
            <Form.Control
              className="form-control"
              as="textarea"
              rows={3}
              name="userComment"
              onChange={formik.handleChange}
              value={formik.values.userComment}
            />
          </div>
        </div>
      </div>

      <div className="row ">
        <div className="col-6 ">
          <button
            type="submit"
            className="btn btn-lg btn-theme rounded-1 w-100 "
            disabled={formik.isSubmitting}>
            <i className="fas fa-check-circle fa-fw me-1"></i>
            {t('Confirm')}
          </button>
        </div>
        <div className="col-6">
          <button
            type="button"
            className=" btn btn-lg  btn-default rounded-1 w-100  "
            onClick={() => Navigate(-1, { replace: true })}>
            <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
          </button>
        </div>
      </div>
    </Form>
  );
};

export default ActionRequestForm;
