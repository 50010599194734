import React, { useEffect, useState } from "react";
import * as yup from "yup"; //
import Form from "react-bootstrap/Form";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import useLookupModule from "../../../../../hooks/Lookups/use-Lookup-Module";
import { GetReportSourceList } from "../../../../../store/NTX/Reports/reportSlice";
import { DataIcons } from "../../../../../assets/icons";
import { useSelector } from "react-redux";
import useGetDynamicForm from "../../../../../hooks/Forms/useGetDynamicForm";
 import Loader from "../../../../../components/Shared/Loader/Loader";
import { Card } from "../../../../../components/card/card";

const Step1 = ({ setCheckValidationStep1, setDataStep1 }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { Module } = useLookupModule();
  const { DynamicFormList } = useGetDynamicForm();

  useEffect(() => {
    dispatch(GetReportSourceList());
  }, [dispatch]);
  const { ReportSourceList , loading } = useSelector((state) => state.Reports);

  //_______________________________________________
  const validationSchema = yup.object().shape({
    name: yup.string().required(t("Name is required")),
    name2: yup.string().required(t("Name2 is required")),
    moduleId: yup.string().required(t("Module is required")),
    reportSourceId: yup.string().required(t("Table Name is required")),
    FormRecordId: yup.string().when("typeForm", {
      is: "3",
      then: () =>
        yup
          .string()
          .notOneOf(["إختر", "Choose"], "Required")
          .required(() => t("Required")),
    }),
  });

  const [formData, setFormData] = useState({
    name: "",
    name2: "",
    moduleId: "",
    viewName: "",
    icon: "",
    //__
    reportSourceId: "",
    typeForm: "0",
    tableId: null,
    hasExtraInformation: "false",
    hasExtraInformationRecord: "false",
    hasExtraInformationType: "false",
    FormRecordId: "",
    FormTypeId: "",
    //__
    sms: false,
    whatsapp: false,
    email: false,
    Notification: false,
  });

  const handleInputChange = async (e) => {
    // const { name, value, options } = e.target;
    const { name, value, type, checked, options } = e.target;

    if (type === "checkbox") {
      setFormData({
        ...formData,
        [name]: checked,
      });
    } else if (options !== undefined && name === "reportSourceId") {
      let selectedOption = options[options.selectedIndex];
      setFormData({
        ...formData,
        typeForm: "0",
        FormRecordId: "",
        [name]: value,
        viewName: selectedOption.getAttribute("data-view-name") || null,
        tableId: selectedOption.getAttribute("data-table-id") || null,
        hasExtraInformation:
          selectedOption.getAttribute("data-has-extra-information") || "false",
        hasExtraInformationRecord:
          selectedOption.getAttribute("data-has-extra-information-record") ||
          "false",
        hasExtraInformationType:
          selectedOption.getAttribute("data-has-extra-information-type") ||
          "false",
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  // Validate function
  const areAllFieldsFilled = () => {
    if (
      !formData.sms &&
      !formData.email &&
      !formData.whatsapp &&
      !formData.Notification
    ) {
      return false;
    }
    try {
      validationSchema.validateSync(formData, { abortEarly: false });
      return true; // All fields are filled
    } catch (error) {
      return false; // Some field is empty
    }
  };

  useEffect(() => {
    setCheckValidationStep1(areAllFieldsFilled());
    setDataStep1(formData);
  }, [formData]);
  //________________________

  var FormRecordListByTableId = DynamicFormList?.filter(
    (f) => f.dynamicFormTypesId === 3 && f.tableId === formData.tableId
  );

  var FormTypeListByTableId = DynamicFormList?.filter(
    (f) => f.dynamicFormTypesId === 4 && f.tableId === formData.tableId
  );
  return (
    <>
    <Loader loading={loading}/>
      <div className="row">
        <div className="col-xl-4">
          <div className="form-group mb-3">
            <label className="form-label">{t("LocalName")}</label>
            <Form.Control
              className="form-control"
              type="text"
              name="name2"
              value={formData.name2}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="col-xl-4">
          <div className="form-group mb-3">
            <label className="form-label">{t("GlobalName")}</label>
            <Form.Control
              className="form-control"
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="col-xl-4">
          <div className="form-group mb-3">
            <label className="form-label">{t("Icon")}</label>

            <Select
              classNamePrefix="react-select"
              isSearchable={true}
              required
              options={DataIcons}
              onChange={(option) =>
                setFormData({ ...formData, icon: option.value })
              }
              placeholder={i18n.language === "ar" ? "إختر" : "Choose"}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-4">
          <div className="form-group mb-3">
            <label className="form-label">{t("Application")}</label>
            <Form.Select
              className="form-select"
              name="moduleId"
              value={formData.moduleId}
              onChange={handleInputChange}
            >
              <option value="">
                {i18n.language === "ar" ? "إختر" : "Choose"}
              </option>
              {Module &&
                Module?.map((item, idx) => (
                  <option key={++idx} value={item.id}>
                    {i18n.language === "ar" ? item.name2 : item.name}
                  </option>
                ))}
            </Form.Select>
          </div>
        </div>

        <div className="col-xl-4">
          <div className="form-group mb-3">
            <label className="form-label">{t("Data_Source")}</label>
            <Form.Select
              className="form-select mb-3"
              name="reportSourceId"
              value={formData.reportSourceId}
              // onChange={(e) => handleDataSourceSelect(e)}
              onChange={(e) => handleInputChange(e)}
            >
              <option value="">
                {i18n.language === "ar" ? "إختر" : "Choose"}
              </option>
              {ReportSourceList &&
                ReportSourceList?.map((item, idx) => (
                  <option
                    key={item.id}
                    value={item.id}
                    data-view-name={item.viewName}
                    data-table-id={item.tableId}
                    data-has-extra-information={item.hasExtraInformation}
                    data-has-extra-information-record={
                      item.hasExtraInformationRecord
                    }
                    data-has-extra-information-type={
                      item.hasExtraInformationType
                    }
                  >
                    {i18n.language === "ar" ? item.name2 : item.name}
                  </option>
                ))}
            </Form.Select>
          </div>
        </div>

        <div className="col-xl-4">
          {(formData.hasExtraInformation === "true" ||
            formData.hasExtraInformationRecord === "true" ||
            formData.hasExtraInformationType === "true") && (
            <div className="row">
              <div className="col-xl-12">
                <div className="form-group mb-3">
                  <label className="form-label">نوع البيانات</label>
                  <Form.Select
                    className="form-select mb-3"
                    name="typeForm"
                    value={formData.typeForm}
                    onChange={(e) => handleInputChange(e)}
                  >
                    <option value="0">
                      {i18n.language === "ar"
                        ? "البيانات الأساسية"
                        : "Basic Data"}
                    </option>
                    {formData.hasExtraInformation === "true" && (
                      <option value="2">
                        {i18n.language === "ar"
                          ? " مع البيانات الاضافية"
                          : "with Extra Information"}
                      </option>
                    )}
                    {formData.hasExtraInformationRecord === "true" && (
                      <option value="3">
                        {i18n.language === "ar"
                          ? " مع السجلات الإضافية"
                          : "with Extra Information Record"}
                      </option>
                    )}
                    {formData.hasExtraInformationType === "true" && (
                      <option value="4">
                        {i18n.language === "ar"
                          ? "مع الأنواع الإضافية"
                          : "with Extra Information Type"}
                      </option>
                    )}
                  </Form.Select>
                </div>
              </div>

              <div className="col-xl-12">
                {formData.hasExtraInformationRecord === "true" &&
                  formData.tableId !== null &&
                  formData.typeForm === "3" && (
                    <div className="form-group mb-3">
                      <label className="form-label">{t("Record")}</label>
                      <Form.Select
                        className="form-select mb-3"
                        name="FormRecordId"
                        value={formData.FormRecordId}
                        onChange={(e) => handleInputChange(e)}
                      >
                        <option value="">
                          {i18n.language === "ar" ? "إختر" : "Choose"}
                        </option>
                        {DynamicFormList &&
                          FormRecordListByTableId?.map((item, idx) => (
                            <option key={item.id} value={item.id}>
                              {i18n.language === "ar" ? item.name2 : item.name}
                            </option>
                          ))}
                      </Form.Select>
                    </div>
                  )}

                {formData.hasExtraInformationType === "true" &&
                  formData.tableId !== null &&
                  formData.typeForm === "4" && (
                    <div className="form-group mb-3">
                      <label className="form-label">{t("Type")}</label>
                      <Form.Select
                        className="form-select mb-3"
                        name="FormTypeId"
                        value={formData.FormTypeId}
                        onChange={(e) => handleInputChange(e)}
                      >
                        <option value="">
                          {i18n.language === "ar" ? "كل الأنواع" : "All Types"}
                        </option>
                        {DynamicFormList &&
                          FormTypeListByTableId?.map((item, idx) => (
                            <option key={item.id} value={item.id}>
                              {i18n.language === "ar" ? item.name2 : item.name}
                            </option>
                          ))}
                      </Form.Select>
                    </div>
                  )}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="row bg-inverse bg-opacity-5 rounded-1 mx-1">
        <span className="text-center fs-4 fw-bold bg-theme bg-opacity-60 rounded-1">
          نوع الإشعار
        </span>
        <div className="row pt-3">
          <div className="col-xl-3 ps-5 text-center">
       

            
            <div className="project">
              <div className="boxes">
                <div className="box">
                  <div>
                    <input
                      type="checkbox"
                      name="Notification"
                      id="Notification"
                      checked={formData.Notification}
                      onChange={handleInputChange}
                    />
                  </div>
                  <label htmlFor="Notification">
                    <i className="fa-solid bi bi-bell"></i>
                    <p>إشعار</p>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-3 ps-5 text-center">
            <div className="project">
              <div className="boxes">
                <div className="box">
                  <div>
                    <input
                      type="checkbox"
                      name="sms"
                      id="sms"
                      checked={formData.sms}
                      onChange={handleInputChange}
                    />
                  </div>
                  <label htmlFor="sms">
                    <i className="fa-solid bi bi-chat-right-text"></i>
                    <p>sms</p>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-3 ps-5 text-center">
            <div className="project">
              <div className="boxes">
                <div className="box">
                  <div>
                    <input
                      type="checkbox"
                      name="whatsapp"
                      id="whatsapp"
                      checked={formData.whatsapp}
                      onChange={handleInputChange}
                    />
                  </div>
                  <label htmlFor="whatsapp">
                    <i className="fa-solid bi bi-whatsapp"></i>
                    <p>واتساب</p>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-3 ps-5 text-center">
            <div className="project">
              <div className="boxes">
                <div className="box">
                  <div>
                    <input
                      type="checkbox"
                      name="email"
                      id="email"
                      checked={formData.email}
                      onChange={handleInputChange}
                    />
                  </div>
                  <label htmlFor="email">
                    <i className="fa-solid bi bi-envelope-at"></i>
                    <p>ايميل</p>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Step1;
