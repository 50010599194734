import React, { useState } from 'react';
import useGetChartAccountsDetailsList from '../../../../hooks/Finance/useGetChartAccountsDetailsList';
import { Form } from 'react-bootstrap';
import { TreeSelect } from 'antd';
import Select from 'react-select';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../../store/hooks';
import {
  DeleteGlConsolidationSet,
  AddConsolidationSet,
  getOrganizationToConsolidationSetList,
} from '../../../../store/Finance/ConsolidationSet/consolidationSetSlice';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import { consolidationSetModels } from '../../../../store/Finance/ConsolidationSet/consolidationSetModels';
import Loading from '../../../../components/Shared/Loader/Loading';
import ToastSwal from '../../../../components/Shared/Alert/ToastSwal';
import useGetGlLedgersList from '../../../../hooks/Finance/useGetGlLedgersList';

interface Props {
  data: consolidationSetModels;
}
const AddUpdateConsolidation = ({ data }: Props) => {
  // console.log(data);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [accountsId, setAccountsId] = useState(data.chartOfAccountsDetailsId);
  const [ledgerId, setLedgerId] = useState(data.ledgerId);

  const { TreeCostCenters, TreeCompanies, TreeBranches, loading, error } =
    useGetChartAccountsDetailsList();

  const { OptionsLedgers, loading: loading2 } = useGetGlLedgersList();

  const { t, i18n } = useTranslation();
  const { type } = useParams();
  const dispatch = useAppDispatch();

  const onSubmit = (e: any) => {
    // setIsSubmitting(true);
    e.preventDefault();

    if (!accountsId) {
      ToastSwal({ title: 'حدد الحسـاب', position: 'top-start' });
      return;
    }
    if (!ledgerId) {
      ToastSwal({ title: 'حدد الأستاذ العام', position: 'top-start' });
      return;
    }

    let body = {
      chartOfAccountsDetailsId: accountsId,
      organizationId: data.organizationId,
      ledgerId: ledgerId,
    };

    dispatch(AddConsolidationSet(body))
      .unwrap()
      .then((res) => {
        if (res.succeeded === true) {
          setIsSubmitting(false);
          CustomAlert({ action: 'Add' });
          dispatch(getOrganizationToConsolidationSetList());
        } else {
          setIsSubmitting(false);
          CustomAlert({ action: 'Error' });
        }
      })
      .catch((error) => {
        setIsSubmitting(false);
        CustomAlert({ action: 'Error' });
      });
  };

  const onDelete = (e: any) => {
    // setIsSubmitting(true);
    e.preventDefault();

    let body = {
      chartOfAccountsDetailsId: data.chartOfAccountsDetailsId,
      organizationId: data.organizationId,
    };

    dispatch(DeleteGlConsolidationSet(body))
      .unwrap()
      .then((res) => {
        if (res.succeeded === true) {
          setIsSubmitting(false);
          CustomAlert({ action: 'Add', msg: 'تم إزالة الربط' });
          dispatch(getOrganizationToConsolidationSetList());
        } else {
          setIsSubmitting(false);
          CustomAlert({ action: 'Error' });
        }
      })
      .catch((error) => {
        setIsSubmitting(false);
        CustomAlert({ action: 'Error' });
      });
  };

  return (
    <>
      <Loading loading={loading} error={error} Type="Dots">
        <Form onSubmit={onSubmit}>
          <div className="row align-items-end">
            <div className="col-lg-12">
              <div className="form-group mb-3">
                <label className="form-label">
                  {type === 'Company'
                    ? 'الشركات'
                    : type === 'CostCenter'
                    ? 'مراكز التكلفة'
                    : type === 'branch'
                    ? 'الفروع'
                    : null}
                </label>
                <TreeSelect
                  showSearch
                  treeData={
                    type === 'Company'
                      ? TreeCompanies
                      : type === 'CostCenter'
                      ? TreeCostCenters
                      : type === 'branch'
                      ? TreeBranches
                      : null
                  }
                  className="custom-tree-select w-100"
                  treeLine
                  // placeholder="حدد الشركة"
                  allowClear
                  treeDefaultExpandAll
                  getPopupContainer={(triggerNode) => triggerNode.parentNode} // يجعل القائمة تظهر داخل نفس عنصر الـ parent
                  value={accountsId}
                  onChange={(option) => {
                    setAccountsId(option);
                  }}
                />
              </div>
            </div>

            <div className="col-lg-12">
              <div className="form-group mb-3">
                <label className="form-label">{t('الأستاذ العام')}</label>

                <Select
                  classNamePrefix="react-select"
                  className="w-100"
                  isLoading={loading2}
                  isSearchable={true}
                  isClearable
                  options={OptionsLedgers}
                  onChange={(option: any) => {
                    setLedgerId(option === null ? null : option.value);
                  }}
                  placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                />
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-end">
            {data.chartOfAccountsDetailsId !== null && (
              <button
                type="button"
                className="btn btn-danger me-1 w-50"
                onClick={(e) => onDelete(e)}>
                <i className="fas fa-unlink me-1"></i>
                إزالة الربط
              </button>
            )}
            <button disabled={isSubmitting} type="submit" className="btn me-1 btn-theme w-50">
              {isSubmitting ? (
                <div className="spinner-border spinner-border-sm me-2"></div>
              ) : (
                <i className="fa fa-link fa-fw me-1"></i>
              )}
              {t('ربط')}
            </button>
          </div>
        </Form>
      </Loading>
    </>
  );
};

export default AddUpdateConsolidation;
