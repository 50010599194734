import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Card } from '../../../components/card/card.jsx';
import { useTranslation } from 'react-i18next';
import { GetApprovalGroupList } from '../../../store/Ame/approvalGroupSlice.js';
import Pagination from '../../../components/Shared/Pagination/Pagination.jsx';
import useLookupModule from '../../../hooks/Lookups/use-Lookup-Module.js';
import AddAprovalGroup from './AddAprovalGroup.js';
import { usePermissions } from '../../../Helper/Permisstions/usePermissions.ts';
import MedModalComponent from '../../../components/Modal/MedModalComponent.jsx';
import Breadcrumb from '../../../components/Shared/Breadcrumb';
import Select from 'react-select';
import Loading from '../../../components/Shared/Loader/Loading';
import EditApprovalGroup from './EditApprovalGroup.js';

const ApprovalGroup = () => {
  const { HasPermission } = usePermissions();
  const [data, setdata] = useState();
  const { t, i18n } = useTranslation();
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const [selectedValue, setSelectedValue] = useState(null);
  const { OptionsModule, Module, loading: loading2 } = useLookupModule();
  const Navigate = useNavigate();
  const dispatch = useDispatch();

  const { ApprovalGroupList, loading, error } = useSelector((state) => state.ApprovalGroup);
  useEffect(() => {
    if (ApprovalGroupList?.length === 0) {
      dispatch(GetApprovalGroupList());
    }
  }, []);

  const filteredSearch = ApprovalGroupList?.slice()
    ?.filter((item) => {
      const searchLowerCase = search.toLowerCase();
      return (
        item.name?.toLowerCase().includes(searchLowerCase) ||
        item.name2?.toLowerCase().toString().includes(searchLowerCase)
      );
    })
    ?.filter((f) => (selectedValue === null ? true : f.moduleId === selectedValue));

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  var BreadcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'ApprovalManagement',
      link: null,
    },
    {
      name: 'ApprovalGroup',
      link: null,
    },
  ];

  return (
    <>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BreadcrumbList} PageName="ApprovalGroup" />
        {HasPermission('AmeAddNewApprovalsGroup') && (
          <div className="ms-auto">
            <Link
              to=""
              className="btn btn-theme"
              data-bs-toggle="modal"
              data-bs-target="#modalAddAprovalGroup">
              <i className="fa fa-plus-circle fa-fw me-1"></i>
              {t('AddGroup')}
            </Link>
          </div>
        )}
      </div>

      <Card>
        <Loading loading={loading} error={error}>
          <div className="tab-content p-3">
            <div className="row mb-2">
              <div className="col-lg-6">
                <div className="input-group flex-nowrap">
                  <span className="input-group-text fw-bold">
                    {t('Search')}
                    {/* <i className="fa fa-search  text-theme ms-3"></i> */}
                  </span>
                  <div className="input-group">
                    <div className="flex-fill position-relative">
                      <div className="input-group border-0 ">
                        <div
                          className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                          style={{ zIndex: 1020, right: '10px' }}>
                          <i className="fa fa-search opacity-5 text-theme"></i>
                        </div>
                        <input
                          type="text"
                          className="form-control ps-35px"
                          // placeholder={t('Search')}
                          value={search}
                          onChange={(e) => {
                            setSearch(e.target.value);
                            setCurrentPage(1);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="input-group flex-nowrap">
                  <span className="input-group-text  fw-bold">{t('Application')}</span>
                  <Select
                    classNamePrefix="react-select"
                    className="w-100"
                    isLoading={loading2}
                    isSearchable={true}
                    isClearable
                    options={[...OptionsModule]}
                    onChange={(option) => {
                      setSelectedValue(option === null ? null : option.value);
                    }}
                    placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                  />
                </div>
              </div>
            </div>

            {/* //  START TABLE   */}
            <div className="table-responsive">
              <table className="table table-hover text-nowrap">
                <thead className="table-dark">
                  <tr>
                    <th scope="col">{t('Name')}</th>
                    <th scope="col" style={{ width: '25%' }}>
                      {t('Application')}
                    </th>

                    <th scope="col" className="text-center ">
                      {t('CountMembers')}
                    </th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {/* <Loading loading={loading} error={error}> */}
                  {currentData?.map((item, index) => (
                    <tr key={item.id}>
                      <td className="align-middle">
                        {i18n.language === 'ar' ? item.name : item.name2}
                      </td>
                      <td className="align-middle">
                        {i18n.language === 'en'
                          ? Module?.find((f) => f.id === item.moduleId)?.name
                          : Module?.find((f) => f.id === item.moduleId)?.name2}
                      </td>
                      <td className="align-middle text-center text-theme fs-4">
                        {item.countMember}
                      </td>
                      <td className="text-end">
                        <div className=" ">
                          {HasPermission('AmeViewListApprovalGroupMembers') && (
                            <button
                              type="button"
                              onClick={() =>
                                Navigate(`${item.id}/AddApprovalMember`, {
                                  state: {
                                    name: i18n.language === 'en' ? item.name : item.name2,
                                  },
                                })
                              }
                              className="btn btn-outline-theme me-2">
                              <i className="fas fa-lg fa-fw me-2 fa-users"></i>
                              {t('GroupMembers')}
                            </button>
                          )}

                          {HasPermission('AmeEditApprovalGroup') && (
                            <button
                              type="button"
                              // onClick={() => Navigate(`${item.id}/EditApprovalGroup`)}
                              onClick={() => setdata(item)}
                              className="btn btn-outline-theme"
                              data-bs-toggle="modal"
                              data-bs-target="#modalEditAprovalGroup">
                              <i className="far fa-lg fa-fw me-2 fa-edit"></i>
                              {t('Button.Edit')}
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                  {/* </Loading> */}
                </tbody>
              </table>
            </div>
            <Pagination
              filteredData={filteredSearch}
              dataPerPage={dataPerPage}
              handlePagination={(pageNumber) => setCurrentPage(pageNumber)}
              currentPage={currentPage}
            />

            {/* </CardBody> */}
          </div>
        </Loading>
      </Card>
      <MedModalComponent title={t('إضافة مجموعة موافقات جدبدة')} id="modalAddAprovalGroup">
        <AddAprovalGroup />
      </MedModalComponent>
      <MedModalComponent title={t('تعديل مجموعة موافقات')} id="modalEditAprovalGroup">
        {data && <EditApprovalGroup data={data} />}
      </MedModalComponent>
    </>
  );
};

export default ApprovalGroup;
