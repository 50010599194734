import React, { useEffect, useState } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Col, Form, Row, Tooltip } from 'react-bootstrap';
import useLookupRewardType from '../../../../hooks/Lookups/use-Lookup-RewardType';
import { useTranslation } from 'react-i18next';

import Select from 'react-select';
import useGetItemsList from '../../../../hooks/INV/MasterItems/useGetItemsList';
import SwalAlert from '../../../../components/Shared/Alert/SwalAlert';
import { Link } from 'react-router-dom';
import { FormikProps } from 'formik';

interface Props {
  formik: FormikProps<any>;
}

const Rewards = ({ formik }: Props) => {
  const { REWARDS_TYPE } = useLookupRewardType();
  const { t, i18n } = useTranslation();
  const [itemId, setItemId] = useState<string>('');
  const [itemQty, setItemQty] = useState(1);
  const { ItemsOptions } = useGetItemsList();

  const handleAddToList = () => {
    const { discountVouchersRewardsList } = formik.values;
    const updatedRewardsItemsList = [
      ...discountVouchersRewardsList.discountVouchersRewardsItemsList,
    ];

    if (updatedRewardsItemsList.some((s) => s.itemId === itemId)) {
      SwalAlert({ text: 'تم إضافة هذه الصنف مسبقاً', icon: 'warning' });
      return;
    }

    if (itemId) {
      updatedRewardsItemsList.push({
        discountVouchersRewardsId: null,
        itemId,
        itemQty,
      });

      formik.setFieldValue('discountVouchersRewardsList', {
        ...discountVouchersRewardsList,
        discountVouchersRewardsItemsList: updatedRewardsItemsList,
      });

      setItemId('');
      setItemQty(1);
    }
  };

  const handleDelete = (id: string) => {
    const updatedRewardsItemsList =
      formik.values.discountVouchersRewardsList.discountVouchersRewardsItemsList?.filter(
        (item: any) => item.itemId !== id
      );

    formik.setFieldValue('discountVouchersRewardsList', {
      ...formik.values.discountVouchersRewardsList,
      discountVouchersRewardsItemsList: updatedRewardsItemsList,
    });
  };

  const getItemsNameById = (categoryId: string) => {
    const item = ItemsOptions?.find((f) => f.value?.toLowerCase() === categoryId?.toLowerCase());
    return item?.label;
  };

  return (
    <>
      <Row>
        <Col md={2}>
          <div className="form-group mb-3">
            <label className="form-label">{t('نوع المكافأة')}</label>

            <Form.Select
              className="form-select"
              name="rewardsType"
              value={formik.values.discountVouchersRewardsList.rewardsType}
              onChange={(e) =>
                formik.setFieldValue('discountVouchersRewardsList', {
                  ...formik.values.discountVouchersRewardsList,
                  rewardsType: e.target.value,
                })
              }>
              <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
              {REWARDS_TYPE &&
                REWARDS_TYPE?.map((item: any, idx: any) =>
                  item.list?.map((list: any) => (
                    <option key={++idx} value={list.lookupCode}>
                      {i18n.language === 'en' ? list.meaning : list.meaning2}
                    </option>
                  ))
                )}
            </Form.Select>
          </div>
        </Col>

        {formik.values.discountVouchersRewardsList.rewardsType === 'DISCOUNT' && (
          <Col lg={3}>
            <Form.Group className="mb-3">
              <Form.Label>{t('نسبة الخصم')}</Form.Label>
              <Form.Control
                className="form-control text-center"
                type="text"
                name="discountPercentage"
                value={formik.values.discountVouchersRewardsList.discountPercentage}
                onChange={(e) =>
                  formik.setFieldValue('discountVouchersRewardsList', {
                    ...formik.values.discountVouchersRewardsList,
                    discountPercentage: Number(e.target.value),
                  })
                }
                onInput={(e) => {
                  // إلغاء أي إدخال غير رقمي يدويًا
                  const input = e.target as HTMLInputElement;
                  input.value = input.value.replace(/[^0-9]/g, '');
                }}
              />
            </Form.Group>
          </Col>
        )}

        {formik.values.discountVouchersRewardsList.rewardsType === 'FREE_ITEM' && (
          <Col lg={10}>
            <Row className="mb-2">
              <Col lg={8}>
                <Form.Group>
                  <Form.Label>{t('الصنف')}</Form.Label>
                  <Select
                    classNamePrefix="react-select"
                    className=" text-center"
                    // isLoading={loading}
                    isSearchable={true}
                    isClearable
                    options={ItemsOptions}
                    onChange={(option: any) => {
                      setItemId(option?.value);
                    }}
                    value={
                      itemId === '' ? null : ItemsOptions?.find((option) => option.value === itemId)
                    }
                    placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                  />
                </Form.Group>
              </Col>

              <Col lg={2}>
                <Form.Group>
                  <Form.Label>{t('الكمية')}</Form.Label>
                  <Form.Control
                    className="form-control text-center"
                    type="text"
                    name="itemQty"
                    value={itemQty}
                    onChange={(e) => {
                      setItemQty(Number(e.target.value));
                    }}
                    onInput={(e) => {
                      // إلغاء أي إدخال غير رقمي يدويًا
                      const input = e.target as HTMLInputElement;
                      input.value = input.value.replace(/[^0-9]/g, '');
                    }}
                  />
                </Form.Group>
              </Col>

              <Col lg={2} className="pt-4 text-end">
                <button
                  type="button"
                  className="btn me-1 btn-outline-theme"
                  onClick={handleAddToList} // Example: Add the first item from CategoriesList
                >
                  {false ? (
                    <div className="spinner-border spinner-border-sm me-2"></div>
                  ) : (
                    <i className="fas fa-lg fa-fw fa-plus"></i>
                  )}
                </button>
              </Col>
            </Row>

            {(formik.values.discountVouchersRewardsList.discountVouchersRewardsItemsList?.length && (
              <Row>
                <div className="mt-1">
                  <div className="table-responsive rounded-2 text-center  ">
                    <table className="table table-sm table-hover   mb-0 ">
                      <thead className="bg-theme bg-opacity-60">
                        <tr>
                          <th style={{ width: '80%' }}>{t('الصنف')}</th>
                          <th style={{ width: '10%' }}>{t('الكمية')}</th>
                          <th style={{ width: '10%' }}></th>
                        </tr>
                      </thead>

                      <tbody>
                        {formik.values.discountVouchersRewardsList.discountVouchersRewardsItemsList?.map(
                          (item: any, index: number) => (
                            <tr key={index}>
                              <td className="align-middle">{getItemsNameById(item.itemId)}</td>
                              <td className="align-middle">{item.itemQty}</td>
                              <td className="ps-4 align-middle">
                                <OverlayTrigger
                                  placement="right"
                                  overlay={<Tooltip id="button-tooltip-2">Delete</Tooltip>}>
                                  <Link
                                    to=""
                                    className="text-danger"
                                    onClick={() => handleDelete(item.itemId)}>
                                    <i className="fas fa-lg fa-fw me-3 fa-trash-alt"></i>
                                  </Link>
                                </OverlayTrigger>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Row>
            )) ||
              ''}
          </Col>
        )}
      </Row>
    </>
  );
};

export default Rewards;
