import React, { useState } from 'react';
import { useAppDispatch } from '../../../../store/hooks';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import {
  GetPeopleLocationsByPeopleId,
  UpdatePeopleLocations,
} from '../../../../store/NTX/Peoples/PeopleLocationsSlice';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import { Form } from 'react-bootstrap';
import { PeopleLocationsModels } from '../../../../store/NTX/Peoples/PeoplesModels';
import Map from '../../../../components/Shared/Map/Map';

interface Props {
  data: PeopleLocationsModels;
}

const EditPeopleLocations = ({ data }: Props) => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();

  const [center, setCenter] = useState({ lat: 24.7135517, lng: 46.6752957 });
  const [lat, setLat] = useState(center.lat);
  const [lng, setLng] = useState(center.lng);

  const formik = useFormik({
    initialValues: {
      peopleId: data.peopleId ?? '',
      latitude: data.latitude ?? '',
      longitude: data.longitude ?? '',
      name: data.name ?? '',
      name2: data.name2 ?? '',
      description: data.description ?? '',
      shipToSiteFlag: data.shipToSiteFlag ?? false,
      receivingSiteFlag: data.receivingSiteFlag ?? false,
      billToSiteFlag: data.billToSiteFlag ?? false,
      country: data.country ?? '',
      addressLine: data.addressLine ?? '',
      townOrCity: data.townOrCity ?? '',
      postalCode: data.postalCode ?? '',
      region: data.region ?? '',
      telephoneNumber: data.telephoneNumber ?? '',
    },
    // validationSchema:  ,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      dispatch(
        UpdatePeopleLocations({
          id: data.id,
          latitude: lat?.toString(),
          longitude: lng?.toString(),
          name: values.name,
          name2: values.name2,
          description: values.description,
          shipToSiteFlag: values.shipToSiteFlag,
          receivingSiteFlag: values.receivingSiteFlag,
          billToSiteFlag: values.billToSiteFlag,
          country: values.country,
          addressLine: values.addressLine,
          townOrCity: values.townOrCity,
          postalCode: values.postalCode,
          region: values.region,
          telephoneNumber: values.telephoneNumber,
        })
      )
        .unwrap()
        .then((res) => {
          // console.log(res);
          // if (res?.succeeded) {
          formik.resetForm();
          CustomAlert({ action: 'Edit' });
          dispatch(GetPeopleLocationsByPeopleId(data.peopleId));

          // } else {
          formik.setSubmitting(false);
          // CustomAlert({ action: 'Error' });
          // }
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });

 
  //___________________________

  return (
    <div>
      <div className="row">
        <div className="col-lg-8">
          <Form onSubmit={formik.handleSubmit}>
            <div className="row align-items-end">
              <div className="col-xl-6">
                <div className="form-group mb-3">
                  <label className="form-label">{t('LocalName')}</label>
                  <Form.Control
                    className="form-control "
                    type="text"
                    name="name2"
                    onChange={formik.handleChange}
                    value={formik.values.name2 || ''}
                    isInvalid={!!(formik.touched.name2 && formik.errors.name2)}
                  />
                </div>
              </div>
              <div className="col-xl-6 ">
                <div className="form-group mb-3">
                  <label className="form-label">{t('GlobalName')}</label>
                  <Form.Control
                    className="form-control     "
                    type="text"
                    name="name"
                    onChange={formik.handleChange}
                    value={formik.values.name || ''}
                    isInvalid={!!(formik.touched.name && formik.errors.name)}
                  />
                </div>
              </div>

              <div className="col-xl-2">
                <div className="form-group mb-3">
                  <label className="form-label">{t('Latitude')}</label>

                  <Form.Control
                    // onClick={() => setShow(true)}
                    className="form-control"
                    type="text"
                    value={lat}
                    name="latitude"
                    readOnly
                    // onChange={(e) => setLat(parseFloat(e.target.value))}
                  />
                </div>
              </div>

              <div className="col-xl-2">
                <div className="form-group mb-3">
                  <label className="form-label">{t('Longitude')}</label>
                  <Form.Control
                    // onClick={() => setShow(true)}
                    className="form-control"
                    type="text"
                    value={lng}
                    readOnly
                    // onChange={(e) => setLng(parseFloat(e.target.value))}
                    name="longitude"
                  />
                </div>
              </div>

              <div className="col-xl-4">
                <div className="form-group mb-3">
                  <label className="form-label">{t('الدولة')}</label>
                  <Form.Control
                    className="form-control "
                    type="text"
                    name="country"
                    onChange={formik.handleChange}
                    value={formik.values.country || ''}
                    isInvalid={!!(formik.touched.country && formik.errors.country)}
                  />
                </div>
              </div>

              <div className="col-xl-4">
                <div className="form-group mb-3">
                  <label className="form-label">{t('عنوان الشارع')}</label>
                  <Form.Control
                    className="form-control "
                    type="text"
                    name="addressLine"
                    onChange={formik.handleChange}
                    value={formik.values.addressLine || ''}
                    isInvalid={!!(formik.touched.addressLine && formik.errors.addressLine)}
                  />
                </div>
              </div>

              <div className="col-xl-4">
                <div className="form-group mb-3">
                  <label className="form-label">{t('المدينة أو البلدة')}</label>
                  <Form.Control
                    className="form-control "
                    type="text"
                    name="townOrCity"
                    onChange={formik.handleChange}
                    value={formik.values.townOrCity || ''}
                    isInvalid={!!(formik.touched.townOrCity && formik.errors.townOrCity)}
                  />
                </div>
              </div>

              <div className="col-xl-4">
                <div className="form-group mb-3">
                  <label className="form-label">{t('رقم الهاتف')}</label>
                  <Form.Control
                    className="form-control "
                    type="text"
                    name="telephoneNumber"
                    onChange={formik.handleChange}
                    value={formik.values.telephoneNumber || ''}
                    isInvalid={!!(formik.touched.telephoneNumber && formik.errors.telephoneNumber)}
                  />
                </div>
              </div>

              <div className="col-xl-4">
                <div className="form-group mb-3">
                  <label className="form-label">{t('الرمز البريدي')}</label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="postalCode"
                    onChange={formik.handleChange}
                    value={formik.values.postalCode || ''}
                  />
                </div>
              </div>

              {/* <div className="col-xl-3">
                      <div className="form-group mb-3">
                        <label className="form-label">{t('المنطقة')}</label>
                        <Form.Control
                          className="form-control"
                          type="text"
                          name="region"
                          onChange={formik.handleChange}
                          value={formik.values.region}
                        />
                      </div>
                    </div> */}

              <div className="col-xl-2 text-center">
                <div className="form-group mb-3">
                  <label className="form-label">موقع الشـحن</label>
                  <Form.Check
                    style={{ margin: '10px 40px' }}
                    type="checkbox"
                    name="shipToSiteFlag"
                    onChange={formik.handleChange}
                    checked={formik.values.shipToSiteFlag}
                  />
                </div>
              </div>
              <div className="col-xl-2 text-center">
                <div className="form-group mb-3">
                  <label className="form-label">موقع الفاتورة</label>
                  <Form.Check
                    style={{ margin: '10px 40px' }}
                    type="checkbox"
                    name="billToSiteFlag"
                    onChange={formik.handleChange}
                    checked={formik.values.billToSiteFlag}
                  />
                </div>
              </div>

              <div className="col-xl-8">
                <div className="form-group mb-3">
                  <label className="form-label">{t('Note')}</label>
                  <Form.Control
                    className="form-control  "
                    type="text"
                    name="description"
                    onChange={formik.handleChange}
                    value={formik.values.description || ''}
                    isInvalid={!!(formik.touched.description && formik.errors.description)}
                  />
                </div>
              </div>
            </div>
            <div className="  text-end mt-1 ">
              {/* <div className=" form-group mb-3 d-flex justify-content-end "> */}
              <button disabled={formik.isSubmitting} type="submit" className="btn me-1 btn-theme  ">
                {formik.isSubmitting ? (
                  <div className="spinner-border spinner-border-sm me-2"></div>
                ) : (
                  <i className="fa fa-save fa-fw me-1"></i>
                )}
                {t('Save')}
              </button>
              <button type="button" className=" btn me-1 btn-default ">
                <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
              </button>
              {/* </div> */}
            </div>
          </Form>
        </div>
        <div className="col-lg-4">
          <Map
            center={center}
            lat={lat}
            lng={lng}
            setCenter={setCenter}
            setLat={setLat}
            setLng={setLng}
          />
        </div>
      </div>
    </div>
  );
};

export default EditPeopleLocations;
