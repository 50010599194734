import { useFormik } from 'formik';
import React, { useState } from 'react';
import Select from 'react-select';

import { DataIcons } from '../../../../assets/icons';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../store/hooks';
import {
  GetCategoriesList,
  UpdatCategories,
} from '../../../../store/INV/Categories/CategoriesSlice';
import { Form } from 'react-bootstrap';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import useGetCategoriesList from '../../../../hooks/INV/useGetCategoriesList';
import { CategoriesModels } from '../../../../store/INV/Categories/CategoriesModels';
import { Card, CardHeader } from '../../../../components/card/card';
import { Link } from 'react-router-dom';
import useGetChartAccountsDetailsList from '../../../../hooks/Finance/useGetChartAccountsDetailsList';
import Loading from '../../../../components/Shared/Loader/Loading';
import { EditCategoriesINVSchema } from '../../../ValidationForm/validationSchema';

type Props = {
  categoryDetails: CategoriesModels | undefined;
};

const EditCategoryInv = ({ categoryDetails }: Props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { CategoriesList, loading: loading2, error } = useGetCategoriesList();
  const { OptionsAccounts, loading, error: error2 } = useGetChartAccountsDetailsList();

  const formik = useFormik({
    initialValues: {
      id: categoryDetails ? categoryDetails.id : '',
      categoryId: categoryDetails ? categoryDetails.categoryId : '',
      name2: categoryDetails ? categoryDetails.name2 : '',
      name: categoryDetails ? categoryDetails.name : '',
      description: categoryDetails ? categoryDetails.description : '',
      icon: categoryDetails ? categoryDetails.icon : '',

      revenueAccountId: categoryDetails ? categoryDetails.revenueAccountId : '',
      expenseAccountId: categoryDetails ? categoryDetails.expenseAccountId : '',
      discountAccountId: categoryDetails ? categoryDetails.discountAccountId : '',
      discountCreditAccountId: categoryDetails ? categoryDetails.discountCreditAccountId : '',
      salesReturnsAccountId: categoryDetails ? categoryDetails.salesReturnsAccountId : '',
      inventoryAccountId: categoryDetails ? categoryDetails.inventoryAccountId : '',

      image: categoryDetails ? categoryDetails.image : '',
    },

    validationSchema: EditCategoriesINVSchema(t),
    enableReinitialize: true,
    onSubmit: (values) => {
      dispatch(
        UpdatCategories({
          id: values.id,
          categoryId: values.categoryId,
          name: values.name,
          name2: values.name2,
          description: values.description,
          icon: values.icon,
          revenueAccountId: values.revenueAccountId,
          expenseAccountId: values.expenseAccountId,
          discountAccountId: values.discountAccountId,
          discountCreditAccountId: values.discountCreditAccountId,
          salesReturnsAccountId: values.salesReturnsAccountId,
          inventoryAccountId: values.inventoryAccountId,
          image: values.image,
        })
      )
        .unwrap()
        .then((res) => {
          if (res.succeeded === true) {
            dispatch(GetCategoriesList());
            CustomAlert({ action: 'Edit' });
            formik.setSubmitting(false);
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error' });
          }
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });

  // console.log(formik.errors);

  return (
    <div>
      <Card className="mb-3">
        <Loading loading={loading || loading2} error={error || error2}>
          <>
            <CardHeader className="d-flex justify-content-between align-items-center ">
              <span className=" fw-bold">التفاصيــل</span>

              <div className=" m-0 bg-theme bg-opacity-20 rounded-0 p-1">
                {i18n.language === 'en' ? categoryDetails?.name : categoryDetails?.name}
              </div>

              <button
                // to=""
                disabled={formik.isSubmitting}
                // className="text-inverse text-opacity-50 text-decoration-none"
                className="btn btn-sm btn-outline-theme "
                onClick={() => formik.handleSubmit()}>
                {formik.isSubmitting ? (
                  <div className="spinner-border spinner-border-sm"></div>
                ) : (
                  <i className="fa fa-save fa-fw me-2"></i>
                )}
                {t('Save')}
              </button>
              {/* <a href="#/" className="text-inverse text-opacity-50 text-decoration-none"><i className="fa fa-fw fa-trash"></i></a> */}
            </CardHeader>

            <div className="list-group list-group-flush">
              <div className="list-group-item d-flex px-3">
                <div className="flex-fill">
                  <Form>
                    <div className="row">
                      <div className="col-xl-6">
                        <div className="form-group mb-3">
                          <label className="form-label">{t('LocalName')}</label>
                          <Form.Control
                            className="form-control "
                            type="text"
                            name="name2"
                            onChange={formik.handleChange}
                            value={formik.values.name2 || ''}
                            isInvalid={!!(formik.touched.name2 && formik.errors.name2)}
                          />
                          <Form.Control.Feedback type="invalid">
                            {formik.errors.name2}
                          </Form.Control.Feedback>
                        </div>
                      </div>

                      <div className="col-xl-6">
                        <div className="form-group mb-3">
                          <label className="form-label">{t('GlobalName')}</label>
                          <Form.Control
                            className="form-control "
                            type="text"
                            name="name"
                            onChange={formik.handleChange}
                            value={formik.values.name || ''}
                            isInvalid={!!(formik.touched.name && formik.errors.name)}
                          />
                          <Form.Control.Feedback type="invalid">
                            {formik.errors.name}
                          </Form.Control.Feedback>
                        </div>
                      </div>

                      <div className="col-xl-6">
                        <div className="form-group mb-3">
                          <label className="form-label"> {t('الفئة الأب')}</label>
                          <Form.Select
                            // disabled
                            className="form-select"
                            name="categoryId"
                            onChange={formik.handleChange}
                            value={formik.values.categoryId ?? ''}
                            isInvalid={!!(formik.touched.categoryId && formik.errors.categoryId)}>
                            {/* <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option> */}
                            <option value="" selected={formik.values.categoryId === null}>
                              {'فئة رئيسية'}
                            </option>
                            {CategoriesList &&
                              CategoriesList?.map((item, idx) => (
                                <option key={++idx} value={item.id}>
                                  {i18n.language === 'en' ? item.name : item.name2}
                                </option>
                              ))}
                          </Form.Select>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="form-group mb-3">
                          <label className="form-label">{t('Icon')}</label>
                          <Select
                            classNamePrefix="react-select"
                            className=""
                            isLoading={loading}
                            isSearchable={true}
                            isClearable
                            name="icon"
                            options={DataIcons}
                            value={DataIcons?.find((f) => f.value === formik.values.icon)}
                            onChange={(option) => formik.setFieldValue('icon', option?.value)}
                            placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                          />
                          <>{formik.errors.icon}</>
                        </div>
                      </div>

                      <div className="col-xl-12">
                        <div className="form-group mb-3">
                          <label className="form-label">{t('Note')}</label>
                          <Form.Control
                            className="form-control "
                            type="text"
                            name="description"
                            onChange={formik.handleChange}
                            value={formik.values.description || ''}
                            isInvalid={!!(formik.touched.description && formik.errors.description)}
                          />
                          <Form.Control.Feedback type="invalid">
                            {formik.errors.description}
                          </Form.Control.Feedback>
                        </div>
                      </div>
                    </div>
                  </Form>

                  <div className="d-flex align-items-center my-3">
                    <div className="fw-400 me-2">الحسابات</div>
                    <div>
                      <Link
                        to=""
                        className="text-inverse text-opacity-50"
                        data-bs-toggle="collapse"
                        data-bs-target="#completedBoard">
                        <i className="fa fa-plus-circle"></i>
                      </Link>
                    </div>
                  </div>
                  <div className="collapse show" id="completedBoard">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group mb-3">
                          <label className="form-label">{t('حساب الإيرادات')}</label>
                          <Select
                            classNamePrefix="react-select"
                            // className="text-center"
                            isLoading={loading}
                            isSearchable={true}
                            isClearable
                            options={OptionsAccounts}
                            value={OptionsAccounts?.find(
                              (f) => f.value === formik.values.revenueAccountId
                            )}
                            onChange={(option) =>
                              formik.setFieldValue('revenueAccountId', option?.value)
                            }
                            placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                          />
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="form-group mb-3">
                          <label className="form-label">{t('حساب المصاريف')}</label>
                          <Select
                            classNamePrefix="react-select"
                            // className="text-center"
                            isLoading={loading}
                            isSearchable={true}
                            isClearable
                            options={OptionsAccounts}
                            value={OptionsAccounts?.find(
                              (f) => f.value === formik.values.expenseAccountId
                            )}
                            onChange={(option) =>
                              formik.setFieldValue('expenseAccountId', option?.value)
                            }
                            placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group mb-3">
                          <label className="form-label">{t('حساب الخصم')}</label>
                          <Select
                            classNamePrefix="react-select"
                            // className="text-center"
                            isLoading={loading}
                            isSearchable={true}
                            isClearable
                            options={OptionsAccounts}
                            value={OptionsAccounts?.find(
                              (f) => f.value === formik.values.discountAccountId
                            )}
                            onChange={(option) =>
                              formik.setFieldValue('discountAccountId', option?.value)
                            }
                            placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                          />
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="form-group mb-3">
                          <label className="form-label">{t('حساب الخصم المكتسب')}</label>
                          <Select
                            classNamePrefix="react-select"
                            // className="text-center"
                            isLoading={loading}
                            isSearchable={true}
                            isClearable
                            options={OptionsAccounts}
                            value={OptionsAccounts?.find(
                              (f) => f.value === formik.values.discountCreditAccountId
                            )}
                            onChange={(option) =>
                              formik.setFieldValue('discountCreditAccountId', option?.value)
                            }
                            placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                          />
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="form-group mb-3">
                          <label className="form-label">{t('حساب مردود المبيعات')}</label>
                          <Select
                            classNamePrefix="react-select"
                            // className="text-center"
                            isLoading={loading}
                            isSearchable={true}
                            isClearable
                            options={OptionsAccounts}
                            value={OptionsAccounts?.find(
                              (f) => f.value === formik.values.salesReturnsAccountId
                            )}
                            onChange={(option) =>
                              formik.setFieldValue('salesReturnsAccountId', option?.value)
                            }
                            placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                          />
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="form-group mb-3">
                          <label className="form-label">{t('حساب المخزون')}</label>
                          <Select
                            classNamePrefix="react-select"
                            // className="text-center"
                            isLoading={loading}
                            isSearchable={true}
                            isClearable
                            options={OptionsAccounts}
                            value={OptionsAccounts?.find(
                              (f) => f.value === formik.values.inventoryAccountId
                            )}
                            onChange={(option) =>
                              formik.setFieldValue('inventoryAccountId', option?.value)
                            }
                            placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </Loading>
      </Card>
    </div>
  );
};

export default EditCategoryInv;
