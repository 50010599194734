/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { GetInstallmentUnPayment, InsertInstallmentPayment } from "../../../../store/SU/installmentPaymentSlice";
import CustomAlert from "../../../../components/Shared/Alert/CustomAlert";
import { useAppDispatch } from "../../../../store/hooks";
 import useGetBanks from "../../../../hooks/Lookups/use-get-Banks";
import useLookupCurrency from "../../../../hooks/Lookups/use-Lookup-Currency";
import useGetPaymentMethod from "../../../../hooks/Payrolls/use-Get-PaymentMethod";
// import AttachmentList from "../../Contracts/Attachment/AttachmentList";
import Attachment from "./Attachment";
import { InstallmentPaymentSchema } from "../../../ValidationForm/validationSchema";

// interface InstallmentPaymentProps {
//     selectedInstallments: any[];
//   }: React.FC<InstallmentPaymentProps>

const AddInstallmentPayment = ({ selectedInstallments }) => {
  const [attachments, setAttachments] = useState([]);
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
 
  const formData = new FormData();

  const { Banks } = useGetBanks();
  const { Currency } = useLookupCurrency();
  const { PaymentMethodList } = useGetPaymentMethod();

 
  const formik = useFormik({
    initialValues: {
      ContractLeaseId: selectedInstallments[0].contractLeaseId,
      ContractInstallmentId: selectedInstallments[0].id,
      paymentDate: "",
      amount: 0,
      PaymentMethodId: "",
      bankId: "",
      bankReference: "",
      currencyId: "",
      exchangeRate: 1,
      Attachments: "",
    },
    validationSchema: InstallmentPaymentSchema(t),
    onSubmit: (values) => {
   

      formData.append("PaymentDate", values.paymentDate);
      formData.append("Amount", values.amount);
      formData.append("PaymentMethodId", values.PaymentMethodId);
      formData.append("BankId", values.bankId);
      formData.append("BankReference", values.bankReference);
      // formData.append("CurrencyId", values.currencyId);
      formData.append("ExchangeRate", values.exchangeRate);
      formData.append("CurrencyCode", values.currencyId);
      formData.append("ContractLeaseId", values.ContractLeaseId);

      attachments.forEach((item, index) => {
        formData.append(`Attachments[${index}].Attachment`, item.attachment[0]);
        formData.append(`Attachments[${index}].Description`, item.description);
      });

      // attachments.forEach((item, index) => {
      //   formData.append(`Attachments[${index}].Attachment`, item.file);
      //   formData.append(`Attachments[${index}].Description`, item.description);
      // });

      selectedInstallments.forEach((item, index) => {
        formData.append(
          `PaymentDetails[${index}].contractInstallmentId`,
          item.id
        );
      });

      dispatch(InsertInstallmentPayment(formData))
        .unwrap()
        .then((res) => {
          if (res?.succeeded === true) {
            CustomAlert({ action: "Add" , msg: "تمت عملية الدفع بنجاح" });
            dispatch(GetInstallmentUnPayment());
            document.getElementById("closeModalForm").click()
            formik.resetForm();
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: "Error", msg: res?.message });
          }
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: "Error" });
        });
      
    },
  });

  useEffect(() => {
    const totalInstallmentValue = selectedInstallments?.reduce(
      (total, installment) => total + installment.installmentValue,
      0
    );
    formik.setFieldValue("amount", totalInstallmentValue);
  }, [selectedInstallments]);

  return (
    <div>
      <div className="table-responsive rounded-3 mb-2">
        <table className="table  table-sm table-bordered">
          <thead className="bg-inverse bg-opacity-10 ">
            <tr>
              <th className="pt-2 pb-2">رقم العقد</th>
              <th className="pt-2 pb-2">رقم الدفعة</th>
              <th className="pt-2 pb-2">{t("Date")}</th>
              <th className="pt-2 pb-2">{t("InstallmentValue")}</th>
              <th className="pt-2 pb-2">{t("TypeInstallment")}</th>
              <th className="pt-2 pb-2">{t("Date_from")}</th>
              <th className="pt-2 pb-2">{t("End_Date")}</th>
            </tr>
          </thead>

          <tbody>
            {selectedInstallments?.map((item, index) => (
              <tr key={item.id}>
                <td className="align-middle">{item.contrcatCode}</td>
                <td className="align-middle">{item.installmentNo}</td>
                <td className="align-middle">{item.installmentDate}</td>
                <td className="align-middle">{item.installmentValue}</td>
                <td className="align-middle">
                {item.contractInstallmentType === 4
                        ? "تأمين"
                        : item.contractInstallmentType === 2
                        ? "خدمات"
                        : item.contractInstallmentType === 3
                        ? "سعي المكتب"
                        : "قسط عادي"}
                  </td>
                <td className="align-middle">{item.installmentDateForm}</td>
                <td className="align-middle">{item.installmentDateTo}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Form>
        <div className="row">
          <div className="col-xl-4 mx-xl-auto ">
            <div className="form-group mb-3">
              <label className="form-label">{t("Total")}</label>
              <Form.Control
                className="form-control "
                type="number" // bg-inverse bg-opacity-5
                readOnly
                name="amount"
                onChange={formik.handleChange}
                value={formik.values.amount}
                isInvalid={!!(formik.touched.amount && formik.errors.amount)}
              />

            </div>
          </div>
          <div className="col-xl-4 mx-xl-auto">
            <div className="form-group mb-3">
              <label className="form-label">{t("PaymentDate")}</label>
              <Form.Control
                className="form-control"
                type="date"
                name="paymentDate"
                onChange={formik.handleChange}
                value={String(formik.values.paymentDate)}
                isInvalid={
                  !!(formik.touched.paymentDate && formik.errors.paymentDate)
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.paymentDate}
              </Form.Control.Feedback>
            </div>
          </div>
          <div className="col-xl-4">
            <div className="form-group mb-3">
              <label className="form-label">{t("PaymentMethod")}</label>
              <Form.Select
                className="form-select"
                name="PaymentMethodId"
                onChange={formik.handleChange}
                value={formik.values.PaymentMethodId}
                isInvalid={
                  !!(
                    formik.touched.PaymentMethodId &&
                    formik.errors.PaymentMethodId
                  )
                }
              >
                <option key={null}>
                  {i18n.language === "ar" ? "إختر" : "Choose"}
                </option>
                {PaymentMethodList?.map((item, idx) => (
                  <option key={++idx} value={item.id}>
                    {i18n.language === "ar" ? item.name : item.name2}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {formik.errors.PaymentMethodId}
              </Form.Control.Feedback>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-4 mx-xl-auto">
            <div className="form-group mb-3">
              <label className="form-label">{t("Name of the bank")}</label>
              <Form.Select
                className="form-select"
                name="bankId"
                onChange={formik.handleChange}
                value={formik.values.bankId || ""}
                isInvalid={!!(formik.touched.bankId && formik.errors.bankId)}
              >
                <option value="">
                  {i18n.language === "ar" ? "إختر" : "Choose"}
                </option>
                {Banks &&
                  Banks?.map((item, idx) => (
                    <option key={++idx} value={item?.id}>
                      {i18n.language === "ar" ? item?.name2 : item?.name}
                    </option>
                  ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {formik.errors.bankId}
              </Form.Control.Feedback>
            </div>
          </div>
          <div className="col-xl-4">
            <div className="form-group mb-3">
              <label className="form-label">{t("Currency")}</label>
              <Form.Select
                className="form-select"
                name="currencyId"
                onChange={formik.handleChange}
                value={formik.values.currencyId}
                isInvalid={
                  formik.touched.currencyId && formik.errors.currencyId
                }
              >
                <option value="" key={null}>
                  {i18n.language === "ar" ? "إختر" : "Choose"}
                </option>
                {Currency?.map((item) => (
                  <option key={item.currencyCode} value={item.currencyCode}>
                    {item.description}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {formik.errors.currencyId}
              </Form.Control.Feedback>
            </div>
          </div>
          <div className="col-xl-4">
            <div className="form-group mb-3">
              <label className="form-label">{t("BankReference")}</label>
              <Form.Control
                className="form-control"
                type="text"
                name="bankReference"
                onChange={formik.handleChange}
                value={formik.values.bankReference}
                isInvalid={
                  !!(
                    formik.touched.bankReference && formik.errors.bankReference
                  )
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.bankReference}
              </Form.Control.Feedback>
            </div>
          </div>
        </div>

        <div className="row">
          <Attachment setAttachments={setAttachments} />
        </div>

        {/* <div className="col-xl-3">
            <div className="form-group mb-3">
              <label className="form-label">exchangeRate</label>
              <Form.Control
                className="form-control"
                type="number"
                name="exchangeRate"
                onChange={formik.handleChange}
                value={formik.values.exchangeRate}
                isInvalid={
                  !!(formik.touched.exchangeRate && formik.errors.exchangeRate)
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.exchangeRate}
              </Form.Control.Feedback>
            </div>
          </div> */}

        <hr />
        <div className="text-center">
          <button
            disabled={formik.isSubmitting}
            type="button"
            className="btn me-1 btn-theme mb-1"
            onClick={formik.submitForm}
          >
            {formik.isSubmitting ? (
              <div className="spinner-border spinner-border-sm me-2"></div>
            ) : (
              <i className="far fa-lg fa-fw me-2 fa-money-bill-alt"></i>
            )}
            دفع
          </button>
          <button
            type="button"
            className=" btn me-1 btn-default mb-1"
            data-bs-dismiss="modal"
            id="closeModalForm"
          >
            <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
          </button>
        </div>
      </Form>
    </div>
  );
};

export default AddInstallmentPayment;
