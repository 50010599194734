import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getOrganizationToConsolidationSetList } from '../../../store/Finance/ConsolidationSet/consolidationSetSlice';
import loginSlice, { getBusinessGroupIdForPerson } from '../../../store/Login/loginSlice';

const useGetConsolidationSet = () => {
  const dispatch = useAppDispatch();
  var businessGroupId = useAppSelector(getBusinessGroupIdForPerson);
  const { ConsolidationSetList, loading, error } = useAppSelector(
    (state) => state.ConsolidationSet
  );

  useEffect(() => {
    if (ConsolidationSetList?.length === 0) {
      dispatch(getOrganizationToConsolidationSetList());
    }
  }, []);

  // console.log(JSON.stringify(ConsolidationSetList));

  // by businessGroupId from res loginSlice
  const Gl_company_id = ConsolidationSetList?.find(
    (f) => f.organizationId === businessGroupId && f.chartOfAccountsDetailsId !== null
  )?.chartOfAccountsDetailsId;

  //  fun Get Gl_branche_id
  const GetGlBrancheId = (HRBrancheId: string) => {
    let Gl_branche_id = ConsolidationSetList?.find(
      (f) => f.organizationId === HRBrancheId && f.chartOfAccountsDetailsId !== null
    )?.chartOfAccountsDetailsId;

    return Gl_branche_id;
  };

  //  fun Get Gl_ledgar_id
  const GetLedgarId = (HRBrancheId: string) => {
    let ledgar_id = ConsolidationSetList?.find(
      (f) =>
        f.organizationId === HRBrancheId &&
        f.chartOfAccountsDetailsId !== null &&
        f.ledgerId !== null
    )?.ledgerId;

    return ledgar_id;
  };

  return { ConsolidationSetList, Gl_company_id, GetGlBrancheId, GetLedgarId, loading, error };
};

export default useGetConsolidationSet;
