import useGetItemsList from '../../../../../hooks/INV/MasterItems/useGetItemsList';

import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import CustomAlert from '../../../../../components/Shared/Alert/CustomAlert';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
  AddUpdateItemAlternative,
  getItemAlternativeByItemId,
} from '../../../../../store/INV/MasterItems/MasterItemsSlice';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Loading from '../../../../../components/Shared/Loader/Loading';

const AlternativeItems = () => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useAppDispatch();
  const [ItemIds, setItemIds] = useState<any[]>([]);
  const { ItemId } = useParams();
  const { ItemsOptions } = useGetItemsList();

  useEffect(() => {
    if (ItemId) {
      dispatch(getItemAlternativeByItemId(ItemId));
    }
  }, [ItemId, dispatch]);

  const { AlternativeError, AlternativeLoading, AlternativeListByItemId } = useAppSelector(
    (a) => a.MasterItemsINV
  );

  const onSubmit = () => {
    setIsSubmitting(true);

    let body = {
      //   inventoryIds: ItemIds,
      //   itemId: ItemId,
    };

    dispatch(AddUpdateItemAlternative(body))
      .unwrap()
      .then(() => {
        setIsSubmitting(false);
        CustomAlert({ action: 'Add' });
      })
      .catch((error: any) => {
        setIsSubmitting(false);

        CustomAlert({ action: 'Error' });
      });
  };

  return (
    <>
      {/* <Loading loading={AlternativeLoading} error={AlternativeError}> */}
        <div className="col-12">
          <label className="form-label">حدد الأصناف البديـلة</label>
          <div className="input-group flex-nowrap">
            <Select
              classNamePrefix="react-select"
              className=" w-100"
              required
              isMulti
              options={ItemsOptions}
              //   value={ItemsOptions?.filter((option: any) => InventoryIds.includes(option.value))} // تحديد القيم الافتراضية
              onChange={(e) => {
                setItemIds(e?.map((m: any) => m.value));
              }}
            />
          </div>
        </div>
        <div className="text-end mt-3">
          <button
            disabled={isSubmitting}
            onClick={() => onSubmit()}
            type="submit"
            className="btn me-1 btn-theme mb-1"
            id="SaveSubmit">
            {isSubmitting ? (
              <div className="spinner-border spinner-border-sm me-2"></div>
            ) : (
              // <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
              <i className="fa fa-save btn-lg fa-fw me-1"></i>
            )}
            {t('Save')}
          </button>
        </div>
      {/* </Loading> */}
    </>
  );
};

export default AlternativeItems;
