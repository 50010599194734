import { useFormik } from 'formik';
import { useEffect } from 'react';
import Select from 'react-select';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CustomAlert from '../../../components/Shared/Alert/CustomAlert';
import { Card, CardBody } from '../../../components/card/card';
import useGetPersons from '../../../hooks/HR/use-get-persons.js';
import {
  GetApprovalMemberByGroupId,
  insertApprovalMember,
} from '../../../store/Ame/AprovalMemberSlice';
import ListApprovalMember from './ListApprovalMember';
import { usePermissions } from '../../../Helper/Permisstions/usePermissions';
import Breadcrumb from '../../../components/Shared/Breadcrumb';
import Loading from '../../../components/Shared/Loader/Loading';

const AddApprovalMember = () => {
  const { HasPermission } = usePermissions();

  const Navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { dataEmployees, loading: loadingEmp } = useGetPersons();

  const location = useLocation();
  const name = location.state;

  //
  const { ApprovalMemberList, loading, error } = useSelector((state) => state.ApprovalMember);
  useEffect(() => {
    dispatch(GetApprovalMemberByGroupId(id));
  }, [, id]);

  const formik = useFormik({
    initialValues: {
      approvalGroupId: '',
      approverId: '',
      tableName: '',
      orderNumber: 0,
      isInformation: false,
    },
    // validationSchema: AddFormColumnSchema(t),
    onSubmit: (values, { resetForm }) => {
      dispatch(
        insertApprovalMember({
          approvalGroupId: id,
          approverId: formik.values.approverId,
          tableName: 'PERSON',
          orderNumber: formik.values.orderNumber,
          isInformation: formik.values.isInformation,
        })
      )
        .unwrap()
        .then(() => {
          resetForm();
          CustomAlert({ action: 'Add' });
          dispatch(GetApprovalMemberByGroupId(id));
        })
        .catch((error) => {
          console.log(error);
          CustomAlert({ action: 'Error' });
          formik.setSubmitting(false);
        });
    },
  });

  //________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/dashboard',
    },
    {
      name: t('ApprovalManagement'),
      link: '/Ame/ApprovalGroup',
    },
    {
      name: t('GroupMembers'),
      link: null,
    },
  ];

  return (
    <>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName={t('GroupMembers')} subtitle={name.name} />
      <Card>
        <Loading loading={loading} error={error}>
          <CardBody>
            {HasPermission('AmeAddMemberApprovalsGroup') && (
              <Form onSubmit={formik.handleSubmit}>
                <div className="row align-items-end">
                  <div className="col-xl-4">
                    <div className="form-group mb-3">
                      <label className="form-label">{t('Member')}</label>

                      <Select
                        classNamePrefix="react-select"
                        isLoading={loadingEmp}
                        isSearchable={true}
                        isClearable
                        name="approverId"
                        options={dataEmployees}
                        onChange={(option) => {
                          formik.setFieldValue('approverId', option === null ? null : option.value);
                        }}
                        placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                      />
                    </div>
                  </div>
                  <div className="col-xl-2">
                    <div className="form-group mb-3">
                      <label className="form-label">{t('Order')}</label>
                      <Form.Control
                        className="form-control text-center"
                        type="number"
                        name="orderNumber"
                        onChange={formik.handleChange}
                        value={formik.values.orderNumber}
                        isInvalid={!!formik.errors.orderNumber}
                      />
                    </div>
                  </div>

                  <div className="col-xl-3 text-start">
                    <div className="form-group mb-3">
                      <div className="form-check form-check-inline  ">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="isInformation"
                          onChange={formik.handleChange}
                          value={formik.values.isInformation}
                        />
                        <label className="form-check-label text-center">
                          {i18n.language === 'ar' ? 'يستلم المعلومات فقط' : 'Information Only'}
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-3 text-end  ">
                    <div className=" form-group mb-3 d-flex justify-content-end ">
                      <button
                        type="submit"
                        className="btn me-1 btn-theme  w-50"
                        disabled={formik.isSubmitting}>
                        <i class="fas fa-plus fa-fw me-1"></i>
                        {t('Add')}
                      </button>

                      <button
                        type="button"
                        className=" btn me-1 btn-default  w-50"
                        onClick={() => Navigate(-1, { replace: true })}>
                        <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
                      </button>
                    </div>
                  </div>
                </div>

                {/* <div className="text-center">
                  <button
                    type="submit"
                    className="btn me-1 btn-theme mb-1"
                    disabled={formik.isSubmitting}>
                    <i class="fas fa-plus fa-fw me-1"></i>
                    {t('Add')}
                  </button>

                  <button
                    type="button"
                    className=" btn me-1 btn-default mb-1"
                    onClick={() => Navigate(-1, { replace: true })}>
                    <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
                  </button>
                </div> */}
              </Form>
            )}

            <div className="mb-4" />

            <div className="row">
              <div className="   rounded-3 text-center  ">
                <ListApprovalMember data={ApprovalMemberList} />
              </div>
            </div>
          </CardBody>
        </Loading>
      </Card>
    </>
  );
};

export default AddApprovalMember;
