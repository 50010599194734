import React, { Fragment, useState } from 'react';
import { useAppDispatch } from '../../../../store/hooks';
import { useTranslation } from 'react-i18next';
import { Card, CardBody } from '../../../../components/card/card';
import { Link, useLocation } from 'react-router-dom';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import NeatixModal from '../../../../components/Modal/NeatixModal';
import useGetPeopleLocationsList from '../../../../hooks/NTX/Peoples/useGetPeopleLocationsList';
import Pagination from '../../../../components/Shared/Pagination/Pagination';
import EditPeopleLocations from './UpdatePeopleLocations';
import { PeopleLocationsModels } from '../../../../store/NTX/Peoples/PeoplesModels';
import ModalComponent from '../../../../components/Modal/ModalComponent';
import Add from './Add';

type Props = {
  peopleId: string | undefined;
};

const PeopleLocations: React.FC<Props> = ({ peopleId }) => {
  const { t, i18n } = useTranslation();
  const [detailes, setDetailes] = useState<PeopleLocationsModels>();

  const { data, loading, error } = useGetPeopleLocationsList({ peopleId: peopleId || '' });

  const [show, setShow] = useState(false);
  const [search, setSearch] = useState('');

  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(5);

  const filteredSearch = data?.slice()?.filter((item) => {
    const searchLowerCase = search.toLowerCase();
    return (
      item.name?.toLowerCase().includes(searchLowerCase) ||
      item.name2?.toLowerCase().includes(searchLowerCase)
    );
  });

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  //____________________________
  const location = useLocation();
  const { name } = location?.state;
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: t('العملاء'),
      link: '/SalesSettings/Customers',
    },
    {
      name: t('مواقع العملاء'),
      link: null,
    },
  ];

  return (
    <Fragment>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName={t('مواقع العميـل')} subtitle={name} />
        <div className="ms-auto">
          <Link
            to=""
            className="btn me-1 btn-theme"
            data-bs-toggle="modal"
            data-bs-target="#modalAdd"
            // onClick={() => setShow(true)}
          >
            <i className="fa fa-plus-circle fa-fw me-1"></i>
            إضافة موقع جديد لـ ( {name} )
          </Link>
        </div>
      </div>

      <>
        <Card>
          <CardBody>
            <Fragment>

            <div className="row mb-2">
                <div className="col-lg-12">
                  <div className="input-group flex-nowrap">
                    <span className="input-group-text fw-bold">
                      {t('Search')}
                      {/* <i className="fa fa-search  text-theme ms-3"></i> */}
                    </span>
                    <div className="input-group">
                      <div className="flex-fill position-relative">
                        <div className="input-group border-0 ">
                          <div
                            className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                            style={{ zIndex: 1020, right: '10px' }}>
                            <i className="fa fa-search opacity-5 text-theme"></i>
                          </div>
                          <input
                            type="text"
                            className="form-control ps-35px"
                            // placeholder={t('Search')}
                            value={search}
                            onChange={(e) => {
                              setSearch(e.target.value);
                              setCurrentPage(1);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
             
              </div>

              <div className="table-responsive">
                <table className="table text-nowrap">
                  <thead className="table-dark">
                    <tr>
                      <th scope="col">{t('Name')}</th>
                      <th scope="col">{t('Address')}</th>
                      <th scope="col">{t('يفوتر له  / يشحن له')}</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData?.map((item, index) => (
                      <tr key={item.id}>
                        <td className="align-middle">
                          {i18n.language === 'en' ? item.name : item.name2}
                        </td>
                        <td className="align-middle">{item.addressLine}</td>
                        <td className="align-middle">
                          {item.billToSiteFlag && 'يفوتر له'}
                          {item.billToSiteFlag && item.shipToSiteFlag && ' / '}

                          {item.shipToSiteFlag && 'يشحن له'}
                        </td>
                        <td className="align-middle text-end">
                          <button
                            type="button"
                            className="btn btn-outline-theme me-1"
                            onClick={() => {
                              setDetailes(item);
                              setShow(true);
                            }}>
                            <i className="far fa-lg fa-fw me-2 fa-eye"></i>

                            {t('ViewDetails')}
                          </button>
                          {/* <button
                    type="button"
                    className="btn btn-outline-theme me-1"
                    onClick={() => {
                      setShow(true);
                      setCenter({
                        lat: item?.latitude || '0',
                        lng: item?.longitude || '0',
                      });
                    }}>
                    <i className="far fa-lg fa-fw me-2 fa-map"></i>

                    {t('عرض الموقع')}
                  </button> */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Pagination
                filteredData={filteredSearch}
                dataPerPage={dataPerPage}
                handlePagination={(pageNumber: any) => setCurrentPage(pageNumber)}
                currentPage={currentPage}
              />
            </Fragment>
          </CardBody>
        </Card>
      </>

      <NeatixModal show={show} setShow={setShow} size="xl" bodyClassName="p-0" showHeader={false}>
        <Card>
          <CardBody>{detailes && <EditPeopleLocations data={detailes} />}</CardBody>
        </Card>
      </NeatixModal>

      <ModalComponent title={t('إضافة موقع عميـل')} id="modalAdd">
        {peopleId && <Add peopleId={peopleId} />}
      </ModalComponent>
    </Fragment>
  );
};

export default PeopleLocations;

// {/* <NeatixModal show={show} setShow={setShow} size="md" bodyClassName="p-0" showHeader={false}>
//   <Card>
//     <CardBody>
//       <Map
//         center={center}
//         lat={lat}
//         lng={lng}
//         setCenter={setCenter}
//         setLat={setLat}
//         setLng={setLng}
//       />
//     </CardBody>
//   </Card>
// </NeatixModal> */}
// const dispatch = useAppDispatch();
// const { t, i18n } = useTranslation();
// const Navigate = useNavigate();

// const [show, setShow] = useState(false);

// const [center, setCenter] = useState({ lat: 24.7135517, lng: 46.6752957 });
// const [lat, setLat] = useState(center.lat);
// const [lng, setLng] = useState(center.lng);

// const formik = useFormik({
//   initialValues: {
//     peopleId: peopleId,
//     latitude: '',
//     longitude: '',
//     name: '',
//     name2: '',
//     description: '',
//     shipToSiteFlag: false,
//     receivingSiteFlag: null,
//     billToSiteFlag: false,
//     country: '',
//     addressLine: '',
//     townOrCity: '',
//     postalCode: '',
//     region: '',
//     telephoneNumber: '',
//   },
//   // validationSchema:  ,
//   onSubmit: (values, { resetForm }) => {
//     dispatch(
//       AddPeopleLocations({
//         peopleId: peopleId,
//         latitude: lat?.toString(),
//         longitude: lng?.toString(),
//         name: values.name,
//         name2: values.name2,
//         description: values.description,
//         shipToSiteFlag: values.shipToSiteFlag,
//         receivingSiteFlag: values.receivingSiteFlag,
//         billToSiteFlag: values.billToSiteFlag,
//         country: values.country,
//         addressLine: values.addressLine,
//         townOrCity: values.townOrCity,
//         postalCode: values.postalCode,
//         region: values.region,
//         telephoneNumber: values.telephoneNumber,
//       })
//     )
//       .unwrap()
//       .then((res) => {
//         // console.log(res);
//         // if (res?.succeeded) {
//         formik.resetForm();
//         CustomAlert({ action: 'Add' });
//         // } else {
//         formik.setSubmitting(false);
//         // CustomAlert({ action: 'Error' });
//         // }
//       })
//       .catch((error) => {
//         formik.setSubmitting(false);
//         CustomAlert({ action: 'Error' });
//       });
//   },
// });

// <Form onSubmit={formik.handleSubmit}>
// <div className="row align-items-end">
//   <div className="col-xl-4">
//     <div className="form-group mb-3">
//       <label className="form-label">{t('LocalName')}</label>
//       <Form.Control
//         className="form-control text-center"
//         type="text"
//         name="name2"
//         onChange={formik.handleChange}
//         value={formik.values.name2}
//         isInvalid={!!(formik.touched.name2 && formik.errors.name2)}
//       />
//     </div>
//   </div>
//   <div className="col-xl-4 ">
//     <div className="form-group mb-3">
//       <label className="form-label">{t('GlobalName')}</label>
//       <Form.Control
//         className="form-control text-center"
//         type="text"
//         name="name"
//         onChange={formik.handleChange}
//         value={formik.values.name}
//         isInvalid={!!(formik.touched.name && formik.errors.name)}
//       />
//     </div>
//   </div>

//   <div className="col-xl-2">
//     <div className="form-group mb-3">
//       <label className="form-label">{t('Latitude')}</label>

//       <Form.Control
//         onClick={() => setShow(true)}
//         className="form-control"
//         type="text"
//         value={lat}
//         name="latitude"
//         // onChange={(e) => setLat(parseFloat(e.target.value))}
//       />
//     </div>
//   </div>

//   <div className="col-xl-2">
//     <div className="form-group mb-3">
//       <label className="form-label">{t('Longitude')}</label>
//       <Form.Control
//         onClick={() => setShow(true)}
//         className="form-control"
//         type="text"
//         value={lng}
//         // onChange={(e) => setLng(parseFloat(e.target.value))}
//         name="longitude"
//       />
//     </div>
//   </div>

//   <div className="col-xl-3">
//     <div className="form-group mb-3">
//       <label className="form-label">{t('الدولة')}</label>
//       <Form.Control
//         className="form-control text-center"
//         type="text"
//         name="country"
//         onChange={formik.handleChange}
//         value={formik.values.country}
//         isInvalid={!!(formik.touched.country && formik.errors.country)}
//       />
//     </div>
//   </div>

//   <div className="col-xl-3">
//     <div className="form-group mb-3">
//       <label className="form-label">{t('عنوان الشارع')}</label>
//       <Form.Control
//         className="form-control text-center"
//         type="text"
//         name="addressLine"
//         onChange={formik.handleChange}
//         value={formik.values.addressLine}
//         isInvalid={!!(formik.touched.addressLine && formik.errors.addressLine)}
//       />
//     </div>
//   </div>

//   <div className="col-xl-3">
//     <div className="form-group mb-3">
//       <label className="form-label">{t('المدينة أو البلدة')}</label>
//       <Form.Control
//         className="form-control text-center"
//         type="text"
//         name="townOrCity"
//         onChange={formik.handleChange}
//         value={formik.values.townOrCity}
//         isInvalid={!!(formik.touched.townOrCity && formik.errors.townOrCity)}
//       />
//     </div>
//   </div>

//   <div className="col-xl-3">
//     <div className="form-group mb-3">
//       <label className="form-label">{t('رقم الهاتف')}</label>
//       <Form.Control
//         className="form-control text-center"
//         type="text"
//         name="telephoneNumber"
//         onChange={formik.handleChange}
//         value={formik.values.telephoneNumber}
//         isInvalid={
//           !!(formik.touched.telephoneNumber && formik.errors.telephoneNumber)
//         }
//       />
//     </div>
//   </div>

//   <div className="col-xl-2">
//     <div className="form-group mb-3">
//       <label className="form-label">{t('الرمز البريدي')}</label>
//       <Form.Control
//         className="form-control"
//         type="text"
//         name="postalCode"
//         onChange={formik.handleChange}
//         value={formik.values.postalCode}
//       />
//     </div>
//   </div>

//   {/* <div className="col-xl-3">
//       <div className="form-group mb-3">
//         <label className="form-label">{t('المنطقة')}</label>
//         <Form.Control
//           className="form-control"
//           type="text"
//           name="region"
//           onChange={formik.handleChange}
//           value={formik.values.region}
//         />
//       </div>
//     </div> */}

//   <div className="col-xl-2 text-center">
//     <div className="form-group mb-3">
//       <label className="form-label">موقع الشـحن</label>
//       <Form.Check
//         style={{ margin: '10px 40px' }}
//         type="checkbox"
//         name="shipToSiteFlag"
//         onChange={formik.handleChange}
//         // value={formik.values.shipToSiteFlag}
//       />
//     </div>
//   </div>
//   <div className="col-xl-2 text-center">
//     <div className="form-group mb-3">
//       <label className="form-label">موقع الفاتورة</label>
//       <Form.Check
//         style={{ margin: '10px 40px' }}
//         type="checkbox"
//         name="billToSiteFlag"
//         onChange={formik.handleChange}
//         // value={formik.values.billToSiteFlag}
//       />
//     </div>
//   </div>

//   <div className="col-xl-6">
//     <div className="form-group mb-3">
//       <label className="form-label">{t('Note')}</label>
//       <Form.Control
//         className="form-control text-center"
//         type="text"
//         name="description"
//         onChange={formik.handleChange}
//         value={formik.values.description}
//         isInvalid={!!(formik.touched.description && formik.errors.description)}
//       />
//     </div>
//   </div>
// </div>
// <div className="  text-center my-2 ">
//   {/* <div className=" form-group mb-3 d-flex justify-content-end "> */}
//   <button
//     disabled={formik.isSubmitting}
//     type="submit"
//     className="btn me-1 btn-theme  ">
  //   {formik.isSubmitting ? (
  //     <div className="spinner-border spinner-border-sm me-2"></div>
  //   ) : (
  //     <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
  //   )}
  //   {t('Add')}
  // </button>
//   <button
//     type="button"
//     className=" btn me-1 btn-default "
//     onClick={() => Navigate('/SalesSettings/Customers', { replace: true })}>
//     <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
//   </button>
//   {/* </div> */}
// </div>
// </Form>
