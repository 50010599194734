import React from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';

import Loading from '../../../../components/Shared/Loader/Loading';
import { Form } from 'react-bootstrap';

import SwalAlert from '../../../../components/Shared/Alert/SwalAlert';
import useGetExpenseCategoryItemsList from '../../../../hooks/Finance/Expense/useGetExpenseCategoryItemsList';

interface LinesProps {
  formik: FormikProps<any>;
}

const ApExpenseLines: React.FC<LinesProps> = ({ formik }) => {
  const { values } = formik;
  const { i18n, t } = useTranslation();

  const {
    ApExpenseCategoryItemsList: data,
    loading,
    error,
  } = useGetExpenseCategoryItemsList({
    expenseCategoryId: values.expenseCategoryId ?? '',
  });
  // console.log(error);

  const handleLineChange = (index: number, field: string, value: any) => {
    const updatedLines = [...values.apExpenseLinesList];
    updatedLines[index] = {
      ...updatedLines[index],
      [field]: value,
    };

    formik?.setFieldValue('apExpenseLinesList', updatedLines);
  };

  const handleAddLine = () => {
    const newLine = {
      expenseCategoryItemId: null,
      description: '',
      amount: 0,
      vat: 0,
      isIncludeVat: true,
      // currencyCode: '',
      // currencyConversionRate: 0,
    };
    formik.setFieldValue('apExpenseLinesList', [...formik.values.apExpenseLinesList, newLine]);
  };

  const handleDeleteLine = (index: number) => {
    const updatedLines = values.apExpenseLinesList?.filter((_: any, i: number) => i !== index);
    formik?.setFieldValue('apExpenseLinesList', updatedLines);
  };

  const calculateTotalSum = () => {
    let total = values.apExpenseLinesList?.reduce((sum: any, line: any) => sum + line.amount, 0);
    const formattedTotal = new Intl.NumberFormat('en-US').format(total);
    return formattedTotal;
  };

  return (
    <>
      <Loading loading={loading} error={error} Type="Dots">
        <>
          <div className="row">
            <div className="col-12 col-md-3 mb-2">
              <div className="rounded-2 p-2 bg-inverse bg-opacity-10">
                <div className="d-flex justify-content-between">
                  <div className="fw-bold">{t('Total')}:</div>
                  <div className="text-theme fs-5 fw-bold">{calculateTotalSum()}</div>
                </div>
              </div>
            </div>

            <div className="col-lg-3"></div>
            <div className="col-lg-6 text-end ">
              <div className="text-end mb-2">
                <button type="button" className="btn btn-outline-theme  " onClick={handleAddLine}>
                  {/* <i className="bi bi-plus-square-dotted fa-lg me-2"></i> */}
                  {t('إضافة صنف')}
                </button>
              </div>
            </div>
          </div>

          {values.apExpenseLinesList?.length > 0 && (
            <div className="">
              <div
                className={`table-responsive rounded-2 text-center ${
                  formik.errors.apExpenseLinesList ? 'border border-2 border-danger' : ''
                } `}>
                <table className="table table-borderless  text-nowrap  table-hover mb-0">
                  <thead className="bg-theme bg-opacity-30">
                    <tr>
                      <th className="align-middle  small" style={{ width: '2%' }}>
                        #
                      </th>
                      <th className="align-middle  small" style={{ width: '20%' }}>
                        expenseCategoryId
                      </th>

                      <th className="align-middle small" style={{ width: '60%' }}>
                        {t('الوصف')}
                      </th>

                      <th className="align-middle small" style={{ width: '8%' }}>
                        {t('amount')}
                      </th>
                      <th className="align-middle small" style={{ width: '8%' }}>
                        {t('Vat')}
                      </th>
                      <th className="align-middle text-end" style={{ width: '2%' }}></th>
                    </tr>
                  </thead>

                  <tbody className="fw-bold">
                    {values.apExpenseLinesList
                      ?.slice()

                      ?.map((line: any, index: any) => {
                        const Index = index;

                        return (
                          <tr key={index}>
                            <td className="align-middle small">{index + 1}</td>
                            <td className="align-middle small">
                              <Form.Select
                                className="form-select"
                                name="expenseCategoryItemId"
                                value={line.expenseCategoryItemId || ''}
                                onChange={(e) =>
                                  handleLineChange(Index, 'expenseCategoryItemId', e.target.value)
                                }>
                                <option value="">
                                  {i18n.language === 'ar' ? 'إختر' : 'Choose'}
                                </option>
                                {data?.map((m) => (
                                  <option value={m.id} key={m.id}>
                                    {i18n.language === 'en' ? m.name : m.name2}
                                  </option>
                                ))}
                              </Form.Select>
                            </td>

                            <td className="align-middle small">
                              <Form.Control
                                className="form-control"
                                as="textarea"
                                rows={1}
                                value={line.description || ''}
                                onChange={(e) => {
                                  handleLineChange(Index, 'description', e.target.value);
                                }}
                              />
                            </td>

                            <td className="align-middle small">
                              <input
                                type="text"
                                className="form-control text-center px-0"
                                value={line.amount || 0}
                                onChange={(e) =>
                                  handleLineChange(Index, 'amount', Number(e.target.value))
                                }
                                onInput={(e) => {
                                  const input = e.target as HTMLInputElement;
                                  input.value = input.value.replace(/[^0-9]/g, '');
                                }}
                              />
                            </td>

                            <td className="align-middle small">
                              <input
                                type="text"
                                className="form-control text-center px-0"
                                value={line.vat || 0}
                                onChange={(e) =>
                                  handleLineChange(Index, 'vat', Number(e.target.value))
                                }
                                onInput={(e) => {
                                  const input = e.target as HTMLInputElement;
                                  input.value = input.value.replace(/[^0-9]/g, '');
                                }}
                              />
                            </td>

                            <td className="align-middle small">
                              <button
                                type="button"
                                className="btn btn-outline-danger"
                                onClick={() => handleDeleteLine(Index)}>
                                <i className="fas fa-trash-alt"></i>
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </>
      </Loading>
    </>
  );
};

export default ApExpenseLines;
