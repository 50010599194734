import React from "react";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useAppDispatch } from "../../../store/hooks";
import { AddNtxLookup } from "../../../store/NTX/Lookup/lookupsSlice";
import CustomAlert from "../../../components/Shared/Alert/CustomAlert";
import * as Yup from "yup";

interface Props {
  categoryName: string | undefined;
  categoryValue: string | undefined;
  FilterByCategory: { lookupCode: string; meaning: string; meaning2: string }[];
}

const AddLookup: React.FC<Props> = ({
  categoryName,
  categoryValue,
  FilterByCategory,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
 

  //#region ValidationFunction
  // Define the type for the validation function
   const ListValidate = FilterByCategory?.map((item) => item.lookupCode);
  type ValidationFunction = (value: string) => string | null;

  const validateLookupCode: ValidationFunction = (value) => {
    // Convert both the input value and the list items to lowercase and remove spaces
    const normalizedValue = value.toLowerCase().replace(/\s/g, "");
    const normalizedList = ListValidate?.map((item) =>
      item.toLowerCase().replace(/\s/g, "")
    );

    if (normalizedList.includes(normalizedValue)) {
      return "Lookup code already exists. Please choose a different code.";
    }
    return null; // Return null for no error
  };

  //#endregion


  
  const formik = useFormik({
    initialValues: {
      lookupType: categoryValue,
      lookupCode: "",
      meaning: "",
      meaning2: "",
      enabledFlag: false,
      description: categoryName,
      // "language": "string",
      // "securityGroupId": "3fa85f64-5717-4562-b3fc-2c963f66afa6"
    },
    // validationSchema: AddLookupSchema(t),
    validationSchema: Yup.object().shape({
      lookupCode: Yup.string()
        .required(() => t("Required"))
        .test(
          "lookupCodeExists",
          "Lookup code already exists",
          (value) => validateLookupCode(value) === null
        ),
      meaning2: Yup.string().required(() => t("Required")),
      meaning: Yup.string().required(() => t("Required")),
      lookupType: Yup.string().required(() => t("Required")),
      description: Yup.string().required(() => t("Required")),
    }),
    onSubmit: (values) => {
      dispatch(
        AddNtxLookup({
          lookupType: categoryValue,
          lookupCode: values.lookupCode,
          meaning: values.meaning,
          meaning2: values.meaning2,
          enabledFlag: true,
          language: null,
          description: values.description,
          securityGroupId: null,
        })
      )
        .unwrap()
        .then(() => {
          formik.resetForm();
          CustomAlert({ action: "Add" });
          document.getElementById("CancelAddLookup")?.click();
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: "Error" });
        });
    },
  });

  return (
    <>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          formik.submitForm();
        }}
      >
        <div className="col-xl-12 mb-2">
          <div className="text-center bg-inverse bg-opacity-10 rounded-2 p-2">
            <h5 className="m-0">{categoryName} </h5>
          </div>
        </div>
        <div className="col-xl-12">
          <div className="form-group mb-3">
            <label className="form-label" >
              {t("LocalName")}
            </label>
            <Form.Control
              className="form-control"
              type="text"
              name="meaning2"
              onChange={formik.handleChange}
              value={formik.values.meaning2 || ""}
              isInvalid={!!(formik.touched.meaning2 && formik.errors.meaning2)}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.meaning2}
            </Form.Control.Feedback>
          </div>
        </div>
        <div className="col-xl-12">
          <div className="form-group mb-3">
            <label className="form-label">{t("GlobalName")}</label>
            <Form.Control
              className="form-control"
              type="text"
              name="meaning"
              onChange={formik.handleChange}
              value={formik.values.meaning || ""}
              isInvalid={!!(formik.touched.meaning && formik.errors.meaning)}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.meaning}
            </Form.Control.Feedback>
          </div>
        </div>
        <div className="col-xl-12">
          <div className="form-group mb-3">
            <label className="form-label" >
              {t("Code")}
            </label>
            <Form.Control
              className="form-control"
              type="text"
              name="lookupCode"
              onChange={formik.handleChange}
              value={formik.values.lookupCode || ""}
              isInvalid={
                !!( formik.errors.lookupCode)
              }
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.lookupCode}
            </Form.Control.Feedback>
          </div>
        </div>

        <hr />

        <div className="text-center">
          <button
            disabled={formik.isSubmitting}
            type="submit"
            className="btn me-1 btn-theme mb-1"
          >
            <i className="fa fa-save fa-fw me-1"></i> {t("Save")}
          </button>
          <button
            type="button"
            className=" btn me-1 btn-default mb-1"
            data-bs-dismiss="modal"
            id="CancelAddLookup"
          >
            <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
          </button>
        </div>
      </Form>
    </>
  );
};

export default AddLookup;
