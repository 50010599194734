import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { useFormik } from 'formik';
import {
  addPeriodOfService,
  getPeriodOfServiceTypeList,
  getPeriodOfServiceDate,
  getStardDate,
  getPeriodOfServiceByPersonId,
  cleanPeriodOfServiceByPersonId,
  cleanPeriodOfServiceTypeList,
} from '../../../../store/HR/Person/EndOfService/endofserviceSlice';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import Loading from '../../../../components/Shared/Loader/Loading';
import { Form } from 'react-bootstrap';
import CalculationOfServicePeriod from './CalculationOfServicePeriod';
import { unwrapResult } from '@reduxjs/toolkit';
import confirmAction from '../../../../components/Shared/Alert/confirmAction';
import { EndOfServiceFormSchema } from '../../../ValidationForm/validationSchema';
import RollBack from './RollBack';
import { getEmployees } from '../../../../store/HR/Person/employeeSlice';
import { usePermissions } from '../../../../Helper/Permisstions/usePermissions';

type Props = {
  personId: string;
  isAme?: boolean;
  setReferenceId?: React.Dispatch<React.SetStateAction<string>>;
};

const EndOfServiceForm = ({ personId, isAme = false, setReferenceId }: Props) => {
  // const { HasPermission } = usePermissions();
  const { HasPermission } = usePermissions();

  const { t, i18n } = useTranslation();
  const Navigate = useNavigate();
  const dispatch = useAppDispatch();

  // var personIdFromToken = useAppSelector(getPersonId);
  // var id = isAme === true ? personIdFromToken : personId;
  // const { AbsenceAttendanceTypeList } = useAbsenceType(id);

  useEffect(() => {
    if (personId) {
      dispatch(getPeriodOfServiceTypeList(personId));
      dispatch(getStardDate(personId));
      dispatch(getPeriodOfServiceDate({ personId }));
    }

    dispatch(getPeriodOfServiceByPersonId(personId || null));

    return () => {
      dispatch(cleanPeriodOfServiceByPersonId());
      dispatch(cleanPeriodOfServiceTypeList());
    };
  }, [dispatch, personId]);

  const { PeriodOfServiceTypeList: data, loading } = useAppSelector((state) => state.EndOfService);
  const { stardDate, loading2 } = useAppSelector((state) => state.EndOfService);
  const { PeriodOfServiceByPersonId: dataByPersonId } = useAppSelector(
    (state) => state.EndOfService
  );

  // console.log(dataByPersonId);
  // console.log(data);

  // _____ فحص ما اذا كان الموظف تم انهاء خدماته ام لا  _________
  const [isTerminated, setIsTerminated] = useState(false); //
  useEffect(() => {
    // setIsTerminated(Object.keys(dataByPersonId)?.length ? true : false);
    setIsTerminated(dataByPersonId.isTerminated);
  }, [dataByPersonId]);
  // _______________________________________

  const formik = useFormik({
    initialValues: {
      personId: personId,
      dateStart: stardDate,
      acceptedTerminationDate: dataByPersonId.isTerminated
        ? dataByPersonId.acceptedTerminationDate
        : '',
      actualTerminationDate: dataByPersonId?.isTerminated
        ? dataByPersonId.actualTerminationDate
        : new Date().toISOString().split('T')[0],
      finalProcessDate: dataByPersonId?.isTerminated ? dataByPersonId.finalProcessDate : '',
      leavingReason: dataByPersonId?.isTerminated ? dataByPersonId.leavingReason : '',
      notifiedTerminationDate: dataByPersonId?.isTerminated
        ? dataByPersonId.notifiedTerminationDate
        : '',
      periodsOfServiceTypeId: dataByPersonId?.isTerminated
        ? dataByPersonId.periodsOfServiceTypeId
        : '',

      status: true,
      isAme: true,
      ameStatus: true,
      elementEntryId: null,
    },

    validationSchema: EndOfServiceFormSchema(t),
    enableReinitialize: true,

    onSubmit: async (values) => {
      if (stardDate > values.actualTerminationDate) {
        CustomAlert({
          action: 'info',
          msg: 'تاريخ الإنهاء يجب أن يكون بعد تاريخ التوظيف',
          Timer: 10000,
        });
        return;
      }

      if (isTerminated) {
        CustomAlert({ action: 'info', msg: 'تم إنهاء خدمات هذا الموظف بالفعل' });
        return;
      }

      try {
        const confirmed = await confirmAction('هل أنت متأكد من إنهاء خدمات هذا الموظف');

        if (confirmed) {
          const response = await dispatch(
            addPeriodOfService({
              personId: values.personId,
              dateStart: stardDate,
              acceptedTerminationDate: values.acceptedTerminationDate,
              actualTerminationDate: values.actualTerminationDate,
              finalProcessDate: values.finalProcessDate,
              leavingReason: values.leavingReason,
              notifiedTerminationDate: values.notifiedTerminationDate,
              isAme: false,
              periodsOfServiceTypeId: values.periodsOfServiceTypeId,
              elementEntryId: null,
            })
          );

          const result = unwrapResult(response);
          // console.log(result);

          if (result.succeeded) {
            CustomAlert({ action: 'Add', msg: 'تمت عملية إنهاء الخدمات ' });
            dispatch(getEmployees());
            Navigate(-1);
          } else {
            CustomAlert({ action: 'Error', msg: result.message });
          }

          // // debugger
          // if (result?.payload?.succeeded === true) {
          //   // اذا كان البوست من شاشة الغياب تظهر رسالة النجاح واذا كان من شاشة الخدمة الذاتية لا تظهر
          //   if (!isAme) {
          // // dispatch(GetAbsenceAttendance(personId));
          //   return;
          // }
          // await setReferenceId(response.payload.data); //
          // } else {
          // formik.resetForm();
          // }
        }
      } catch (error) {
        console.log(error);
        formik.setSubmitting(false);
        CustomAlert({ action: 'Error' });
      } finally {
        formik.setSubmitting(false);
      }
    },
  });

  return (
    <>
      <>
        <Loading loading={loading}>
          <>
            <div className="row  mb-3">
              <CalculationOfServicePeriod
                personId={personId}
                isTerminated={isTerminated}
                formik={formik}
              />
            </div>

            <div className="row">
              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('Reason')}</label>
                  <Form.Select
                    disabled={isTerminated}
                    className="form-select text-center"
                    name="periodsOfServiceTypeId"
                    onChange={formik.handleChange}
                    value={formik.values.periodsOfServiceTypeId}
                    isInvalid={
                      !!(
                        formik.touched.periodsOfServiceTypeId &&
                        formik.errors.periodsOfServiceTypeId
                      )
                    }>
                    <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                    {data &&
                      data?.map((item) => (
                        <option key={item.id} value={item.id}>
                          {i18n.language === 'ar' ? item.name2 : item.name}
                        </option>
                      ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.periodsOfServiceTypeId}
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="col-xl-3">
                <label className="form-label">{t('ApprovalDate')}</label>
                <div className="form-group mb-3">
                  <Form.Control
                    disabled={isTerminated}
                    type="date"
                    className="form-control text-center"
                    name="acceptedTerminationDate"
                    onChange={formik.handleChange}
                    value={formik.values.acceptedTerminationDate}
                    isInvalid={
                      !!(
                        formik.touched.acceptedTerminationDate &&
                        formik.errors.acceptedTerminationDate
                      )
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.acceptedTerminationDate}
                  </Form.Control.Feedback>
                </div>
              </div>

              {/* <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('تاريخ النهاية الفعلي')}</label>
                  <Form.Control
                    type="date"
                    className="form-control text-center"
                    name="actualTerminationDate"
                    onChange={formik.handleChange}
                    value={formik.values.actualTerminationDate}
                    // isInvalid={formik.touched.actualTerminationDate && formik.errors.actualTerminationDate}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.actualTerminationDate}
                  </Form.Control.Feedback>
                </div>
              </div> */}

              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('ProcedureCompletionDate')}</label>
                  <Form.Control
                    disabled={isTerminated}
                    type="date"
                    className="form-control text-center"
                    name="finalProcessDate"
                    onChange={formik.handleChange}
                    value={formik.values.finalProcessDate}
                    isInvalid={
                      !!(formik.touched.finalProcessDate && formik.errors.finalProcessDate)
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.finalProcessDate}
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('NoticeDate')}</label>
                  <Form.Control
                    disabled={isTerminated}
                    type="date"
                    className="form-control text-center"
                    name="notifiedTerminationDate"
                    onChange={formik.handleChange}
                    value={formik.values.notifiedTerminationDate}
                    isInvalid={
                      !!(
                        formik.touched.notifiedTerminationDate &&
                        formik.errors.notifiedTerminationDate
                      )
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.notifiedTerminationDate}
                  </Form.Control.Feedback>
                </div>
              </div>

              <div className="col-xl-12">
                <div className="form-group mb-3 has-validation">
                  <label className="form-label">{t('Note')}</label>

                  <Form.Control
                    disabled={isTerminated}
                    type="text"
                    className="form-control "
                    name="leavingReason"
                    as="textarea"
                    rows={2}
                    onChange={formik.handleChange}
                    value={formik.values.leavingReason}
                    isInvalid={!!(formik.touched.leavingReason && formik.errors.leavingReason)}
                    autoComplete="off"
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.leavingReason}
                  </Form.Control.Feedback>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-center">
              <div className="text-center">
                <button
                  disabled={formik.isSubmitting}
                  hidden={isTerminated}
                  type="button"
                  className="btn btn-lg me-1 btn-theme mb-1"
                  onClick={formik.submitForm}>
                  <i className="fa fa-user-slash  fa-fw me-1"></i>

                  {t('TerminationOfService')}
                </button>

                <button
                  type="button"
                  className=" btn btn-lg me-1 btn-default mb-1"
                  onClick={() => Navigate(-1)}>
                  <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
                </button>
              </div>

              {HasPermission('DeletePeriodOfService') && isTerminated && (
                <RollBack EndOfServiceId={dataByPersonId.id} />
              )}
            </div>

            {/* </Form> */}
          </>
        </Loading>
      </>
    </>
  );
};

export default EndOfServiceForm;
