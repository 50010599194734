import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { GetGlPeriodsList } from '../../../store/Finance/Periods/PeriodsSlice';

const useGetGlPeriodsList = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (PeriodsList?.length === 0) {
      dispatch(GetGlPeriodsList());
    }
  }, []);
  const { PeriodsList, loading, error } = useAppSelector((state) => state.Periods);

  return { PeriodsList, loading, error };
};

export default useGetGlPeriodsList;
