import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Card, CardBody } from '../../../components/card/card';
import Breadcrumb from '../../../components/Shared/Breadcrumb';
import Pagination from '../../../components/Shared/Pagination/Pagination';
import AddEquipments from './AddEquipments';
import ModalComponent from '../../../components/Modal/ModalComponent';
import useGetEquipmentsList from '../../../hooks/FLeet/useGetEquipmentsList';
import Loading from '../../../components/Shared/Loader/Loading';

const Index = () => {
  const [Show, setShow] = useState(false);
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const { t, i18n } = useTranslation();
  const Navigate = useNavigate();

  const { EquipmentsList, loading, error } = useGetEquipmentsList();

  const filteredSearch = EquipmentsList?.slice()?.filter((item) => {
    const searchLowerCase = search.toLowerCase();
    return (
      item.name?.toLowerCase().includes(searchLowerCase) ||
      item.name2?.toLowerCase().toString().includes(searchLowerCase)
    );
  });

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);
  //________________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'Assets_and_Equipment',
      link: null,
    },
  ];

  return (
    <div>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName="Assets_and_Equipment" />

      <Card>
        <Loading loading={loading} error={error}>
          <CardBody>
            <div className=" p-2">
              <div>
                <div className="row">
                  <div className="col-7 col-md-6 d-flex justify-content-start">
                    <div className="input-group mb-4">
                      <div className="flex-fill position-relative">
                        <div className="input-group">
                          <div
                            className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                            style={{ zIndex: 1020, right: '10px' }}>
                            <i className="fa fa-search opacity-5"></i>
                          </div>
                          <input
                            type="text"
                            className="form-control ps-35px"
                            onChange={(e) => setSearch(e.target.value)}
                            placeholder={t('Search') + ' ...'}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-5 col-md-6 text-end">
                    <a
                      href="#/"
                      className="btn me-1 btn-outline-theme"
                      data-bs-toggle="modal"
                      data-bs-target="#modalِAddEquipments"
                      onClick={() => setShow(true)}>
                      <i className="fa fa-plus-circle fa-fw me-1"></i>
                      {t('AddEquipment')}
                    </a>
                  </div>
                </div>
                {/* //  START TABLE   */}
                <div className="table-responsive">
                  <table className="table table-hover">
                    <thead className="table-dark">
                      <tr>
                        <th scope="col">{t('Name')}</th>
                        <th scope="col">{t('Plate_Number')}</th>
                        <th scope="col">{t('AssetNumber')}</th>
                        <th scope="col">{t('ServiceDate')}</th>
                        <th className="text-center">الإجراءات</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentData?.map((item, index) => (
                        <tr key={item.id}>
                          <td className="align-middle">
                            <div className="d-flex align-items-center">
                              <div className="w-50px h-50px bg-white bg-opacity-25 d-flex align-items-center justify-content-center">
                                {item.image === null ? (
                                  <img
                                    alt=""
                                    className="mw-100 mh-100"
                                    src="/assets/img/Image/NoImg2.ico"
                                  />
                                ) : (
                                  <img
                                    alt=""
                                    className="mw-100 mh-100" //img-fluid
                                    src={item?.image?.filePath}
                                  />
                                )}
                              </div>
                              <div className="ms-3">
                                {i18n.language === 'en' ? item.name : item.name2}
                              </div>
                            </div>
                          </td>
                          <td className="align-middle">{item.plateNo}</td>
                          <td className="align-middle">{item.code}</td>
                          <td className="align-middle">{item.serviceDate}</td>
                          <td className="pt-3 text-center">
                            <div className="btn-group">
                              <button
                                type="button"
                                className="btn btn-outline-secondary btn-lg m-0"
                                onClick={() =>
                                  Navigate(`${item.id}/ExtraRecordEquipments`, {
                                    state: {
                                      ReferenceName:
                                        i18n.language === 'en' ? item.name : item.name2,
                                    },
                                  })
                                }
                                data-toggle="tooltip"
                                title="Extra Information">
                                <i className="far fa-lg fa-fw bi bi-info-circle-fill text-primary"></i>
                              </button>

                              <button
                                type="button"
                                className="btn btn-outline-secondary btn-lg m-0"
                                onClick={() =>
                                  Navigate(`${item.id}/EquipmentsOdometer`, {
                                    state: {
                                      ReferenceName:
                                        i18n.language === 'en' ? item.name : item.name2,
                                    },
                                  })
                                }
                                data-toggle="tooltip"
                                title="Odometer">
                                <i className="far fa-lg fa-fw bi bi-speedometer text-primary"></i>
                              </button>

                              <button
                                type="button"
                                className="btn btn-outline-secondary btn-lg m-0"
                                data-toggle="tooltip"
                                title="Assignation"
                                onClick={() =>
                                  Navigate(`${item.id}/EquipmentsAssignation`, {
                                    state: {
                                      ReferenceName:
                                        i18n.language === 'en' ? item.name : item.name2,
                                    },
                                  })
                                }>
                                <i className="fas fa-lg bi bi-car-front-fill text-warning"></i>
                              </button>

                              <button
                                type="button"
                                className="btn btn-outline-secondary btn-lg m-0"
                                onClick={() =>
                                  Navigate(`${item.id}/EquipmentRoutes`, {
                                    state: {
                                      ReferenceName:
                                        i18n.language === 'en' ? item.name : item.name2,
                                    },
                                  })
                                }
                                data-toggle="tooltip"
                                title="Equipment Routes">
                                <i className="fas fa-lg fa-fw fa-road text-danger"></i>
                              </button>

                              <button
                                type="button"
                                className="btn btn-outline-secondary btn-lg m-0"
                                onClick={() => Navigate(`${item.id}/Edit`)}
                                // data-toggle="tooltip"
                                title="Edit">
                                <i className="far fa-lg fa-fw fa-edit text-success"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <Pagination
                  filteredData={filteredSearch}
                  dataPerPage={dataPerPage}
                  handlePagination={(pageNumber: any) => setCurrentPage(pageNumber)}
                  currentPage={currentPage}
                />
              </div>
            </div>
          </CardBody>
        </Loading>
      </Card>

      <ModalComponent title={t('AddEquipment')} id="modalِAddEquipments">
        {Show && <AddEquipments />}
      </ModalComponent>
    </div>
  );
};

export default Index;
