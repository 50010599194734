import React from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../../store/hooks';
import { Form } from 'react-bootstrap';

import CustomAlert from '../../../../../components/Shared/Alert/CustomAlert';
import useGetUnitsOfMeasureList from '../../../../../hooks/INV/useGetUnitsOfMeasureList';
import {
  AddItemConversion,
  GetItemConversionByItemId,
  DeleteItemConversion,
} from '../../../../../store/INV/MasterItems/ItemConversionSlice';
import { useParams } from 'react-router-dom';
import Loading from '../../../../../components/Shared/Loader/Loading';
import useGetItemConversionByItemId from '../../../../../hooks/INV/ItemConversion/useGetItemConversionByItemId';
import { ItemConversionSchema } from '../../../../ValidationForm/validationSchema';
import confirmAction from '../../../../../components/Shared/Alert/confirmAction';

type Props = {
  uom: string;
};
const ItemConversion: React.FC<Props> = ({ uom }) => {
  const { t, i18n } = useTranslation();
  const { ItemId } = useParams<{ ItemId: string }>();

  const dispatch = useAppDispatch();

  const { UnitsOfMeasureList, loading: loading1, error: error1 } = useGetUnitsOfMeasureList();
  const { ItemConversionListByItem, loading, error } = useGetItemConversionByItemId(ItemId ?? '');

  const formik = useFormik({
    initialValues: {
      itemId: ItemId,
      uom: '',
      conversionValue: 0,
      barcode: '',
    },

    validationSchema: ItemConversionSchema(t),

    onSubmit: (values) => {
      dispatch(
        AddItemConversion({
          itemId: values.itemId,
          uom: values.uom,
          conversionValue: Number(values.conversionValue),
          barcode: values.barcode,
        })
      )
        .unwrap()
        .then((res) => {
          if (res.succeeded === true) {
            formik.resetForm();
            CustomAlert({ action: 'Add' });
            dispatch(GetItemConversionByItemId(ItemId || ''));
            formik.setSubmitting(false);
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error' });
          }
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });

  const handleDelete = async (item: any) => {
    var isConfirm = await confirmAction('هل أنت متأكد من عملية الحذف');
    if (!isConfirm) {
      return;
    }
    dispatch(
      DeleteItemConversion({
        id: item.id,
      })
    )
      .unwrap()
      .then(() => {
        dispatch(GetItemConversionByItemId(ItemId || ''));
        CustomAlert({ action: 'Delete' });
      })
      .catch((error) => {
        CustomAlert({ action: 'Error' });
      });
  };

  return (
    <Loading loading={loading || loading1} error={error1}>
      <div>
        <Form onSubmit={formik.handleSubmit}>
          <div className="row align-items-end">
            <div className="col-xl-2">
              <div className="form-group mb-3">
                <label className="form-label"> {t('من')}</label>
                <label className="form-control text-theme">
                  {i18n.language === 'en'
                    ? UnitsOfMeasureList?.find((f) => f.id === uom)?.name
                    : UnitsOfMeasureList?.find((f) => f.id === uom)?.name2}
                </label>
              </div>
            </div>

            <div className="col-xl-3">
              <div className="form-group mb-3">
                <label className="form-label"> {t('وحدة القياس')}</label>
                <Form.Select
                  className="form-select"
                  name="uom"
                  onChange={formik.handleChange}
                  value={formik.values.uom || ''}
                  isInvalid={!!(formik.touched.uom && formik.errors.uom)}>
                  <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                  {UnitsOfMeasureList &&
                    UnitsOfMeasureList?.map((item, idx) => (
                      <option key={++idx} value={item.id}>
                        {i18n.language === 'en' ? item.name : item.name2}
                      </option>
                    ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">{formik.errors.uom}</Form.Control.Feedback>
              </div>
            </div>
            <div className="col-xl-2">
              <div className="form-group mb-3">
                <label className="form-label">{t('التعادل')}</label>
                <Form.Control
                  className="form-control fw-bold"
                  type="text"
                  name="conversionValue"
                  onChange={formik.handleChange}
                  value={formik.values.conversionValue}
                  isInvalid={!!(formik.touched.conversionValue && formik.errors.conversionValue)}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.conversionValue}
                </Form.Control.Feedback>
              </div>
            </div>
            <div className="col-xl-3">
              <div className="form-group mb-3">
                <label className="form-label">{t('Barcode')}</label>
                <Form.Control
                  autoComplete="off"
                  className="form-control fw-bold"
                  type="text"
                  name="barcode"
                  onChange={formik.handleChange}
                  value={formik.values.barcode}
                  isInvalid={!!(formik.touched.barcode && formik.errors.barcode)}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.barcode}
                </Form.Control.Feedback>
              </div>
            </div>

            <div className="col-xl-2 text-end  ">
              <div className=" form-group mb-3 d-flex justify-content-end ">
                <button
                  disabled={formik.isSubmitting}
                  type="submit"
                  className="btn me-1 btn-theme mb-1 form-control">
                  {formik.isSubmitting ? (
                    <div className="spinner-border spinner-border-sm me-2"></div>
                  ) : (
                    <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
                  )}
                  {t('Add')}
                </button>
              </div>
            </div>
          </div>
        </Form>

        <div className="table-responsive  rounded-2">
          <table   className="table table-sm table-hover text-nowrap text-center">
            {/* <thead className="table-dark"> */}
            <thead className="fw-bold">
              <tr>
                <th scope="col">{t('الوحدة')}</th>
                <th scope="col">{t('التعادل')}</th>
                <th scope="col">{t('Barcode')}</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {ItemConversionListByItem?.map((item, index) => (
                <tr key={item.id}>
                  <td className="align-middle">
                    {i18n.language === 'en'
                      ? UnitsOfMeasureList?.find((f) => f.id === item.uom)?.name
                      : UnitsOfMeasureList?.find((f) => f.id === item.uom)?.name2}
                  </td>

                  <td className="align-middle">{item.conversionValue}</td>
                  <td className="align-middle">{item.barcode}</td>
                  <td className="align-middle text-end">
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-danger"
                      onClick={() => handleDelete(item)}>
                      <i className="fas fa-trash-alt"></i> {t('حذف')}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Loading>
  );
};

export default ItemConversion;
