/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';

const Step5 = ({ setCheckValidationStep5, setDataStep5 }) => {
  const { t, i18n } = useTranslation();
  const Minuts = Array.from({ length: 59 }, (_, index) => index + 1);
  const Hours = Array.from({ length: 24 }, (_, index) => index);
  const Days = Array.from({ length: 31 }, (_, index) => index + 1);
  const Month = Array.from({ length: 12 }, (_, index) => index + 1);

  const [formData, setFormData] = useState({
    PeriodType: '',
    PeriodValue: '',
    min: 0,
    hrs: 0,
    day: 0,
    mnth: 0,
    dweek: 0,
    number: 0,
  });

  useEffect(() => {
    //  "MM:dd"HH:mm"

    const getPeriodValue = () => {
      const { min, hrs, day, mnth, dweek, number } = formData;
      let value = '';

      switch (formData.PeriodType) {
        case 'Yearly':
          if (mnth !== 0) {
            value += mnth;
            if (day !== 0) {
              value += `:${day}`;
              if (hrs !== 222) {
                value += `:${hrs}`;
                if (min !== 0) {
                  value += `:${min}`;
                }
              }
            }
          }
          break;
        case 'Monthly':
          if (day !== 0) {
            value += `${day}`;
            if (hrs !== 222) {
              value += `:${hrs}`;
              if (min !== 0) {
                value += `:${min}`;
              }
            }
          }
          break;
        case 'Weekly':
          if (dweek !== 2024) {
            value += `${dweek}`;
            if (hrs !== 222) {
              value += `:${hrs}`;
              if (min !== 0) {
                value += `:${min}`;
              }
            }
          }
          break;
        case 'Daily':
          if (hrs !== 222) {
            value += `${hrs}`;
            if (min !== 0) {
              value += `:${min}`;
            }
          }
          break;
        case 'Hourly':
          if (min !== 0) {
            value += `${min}`;
          }
          break;

        case 'MinuteInterval':
          value += `${number}`;
          break;
        case 'HourInterval':
          value += `${number}`;
          break;
        case 'DayInterval':
          value += `${number}`;
          break;
        case 'MonthInterval':
          value += `${number}`;
          break;
        default:
          break;
      }
      return value;
    };

    setFormData((prevFormData) => ({
      ...prevFormData,
      PeriodValue: getPeriodValue(),
    }));
  }, [
    formData.PeriodType,
    formData.min,
    formData.hrs,
    formData.day,
    formData.mnth,
    formData.dweek,
    formData.number,
  ]);

  useEffect(() => {
    setDataStep5(formData);

    const isStep5Validion = () => {
      if (formData.PeriodType === '') {
        return false;
      } else if (
        (formData.PeriodType === 'MinuteInterval' ||
          formData.PeriodType === 'HourInterval' ||
          formData.PeriodType === 'DayInterval' ||
          formData.PeriodType === 'MonthInterval') &&
        formData.number === 0
      ) {
        return false;
      } else {
        return true;
      }
    };
    setCheckValidationStep5(isStep5Validion());
  }, [formData]);

  return (
    <>
      <div className="row">
        <div className="col-xl-9 m-auto pt-1 ">
          <div className="row ">
            <div className="row">
              <div className="col-lg-9 m-auto">
                <div className="form-group mb-3">
                  <label className="form-label">{t('Type')}</label>
                  <Form.Select
                    className="form-select mb-3 text-center"
                    value={formData.PeriodType}
                    onChange={(e) => setFormData({ ...formData, PeriodType: e.target.value })}>
                    <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                    <option value="Hourly">{i18n.language === 'en' ? 'Hourly' : 'كل ساعة'}</option>
                    <option value="Daily">{i18n.language === 'en' ? 'Daily' : 'كل يوم'}</option>
                    <option value="Weekly">{i18n.language === 'en' ? 'Weekly' : 'كل أسبوع'}</option>
                    <option value="Monthly">{i18n.language === 'en' ? 'Monthly' : 'كل شهر'}</option>
                    <option value="Yearly">{i18n.language === 'en' ? 'Yearly' : 'كل سنة'}</option>
                    <option value="MinuteInterval">
                      {i18n.language === 'en' ? 'MinuteInterval' : 'كل عدد دقائق'}
                    </option>
                    <option value="HourInterval">
                      {i18n.language === 'en' ? 'HourInterval' : 'كل عدد ساعات'}
                    </option>
                    <option value="DayInterval">
                      {i18n.language === 'en' ? 'DayInterval' : 'كل عدد أيام'}
                    </option>
                    <option value="MonthInterval">
                      {i18n.language === 'en' ? 'MonthInterval' : 'كل عدد أشهر'}
                    </option>
                  </Form.Select>
                </div>
              </div>
            </div>

            {formData.PeriodType === 'Weekly' && (
              //  {/* أيام الاسبوع */}
              <div className="col-lg-3 m-auto">
                <div className="form-group mb-3">
                  <label className="form-label">{t('أيام الأسبوع')}</label>
                  <Form.Select
                    className="form-select mb-3 text-center"
                    value={formData.dweek}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        dweek: Number(e.target.value),
                      })
                    }>
                    <option value="2024">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                    <option value="6">{i18n.language === 'en' ? 'Saturday' : 'السبت'}</option>
                    <option value="0">{i18n.language === 'en' ? 'Sunday' : 'الأحد'}</option>
                    <option value="1">{i18n.language === 'en' ? 'Monday' : 'الأثنين'}</option>
                    <option value="2">{i18n.language === 'en' ? 'Tuesday' : 'الثلاثاء'}</option>
                    <option value="3">{i18n.language === 'en' ? 'Wednesday' : 'الأربعاء'}</option>
                    <option value="4">{i18n.language === 'en' ? 'Thursday' : 'الخميس'}</option>
                    <option value="5">{i18n.language === 'en' ? 'Friday' : 'الجمعة'}</option>
                  </Form.Select>
                </div>
              </div>
            )}

            {formData.PeriodType === 'Yearly' && (
              // {/* شهر */}
              <div className="col-lg-3 m-auto">
                <div className="form-group mb-3 ">
                  <label className="form-label">{t('الشهر')}</label>
                  <Form.Select
                    className="form-select mb-3 text-center"
                    value={formData.mnth}
                    onChange={(e) => setFormData({ ...formData, mnth: Number(e.target.value) })}>
                    <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                    {Month?.map((mnth) => (
                      <option key={mnth} value={mnth}>
                        {mnth}
                      </option>
                    ))}
                  </Form.Select>
                </div>
              </div>
            )}

            {/* يوم */}
            {(formData.PeriodType === 'Monthly' || formData.PeriodType === 'Yearly') && (
              <div className="col-lg-3 m-auto">
                <div className="form-group mb-3">
                  <label className="form-label">{t('اليوم')}</label>
                  <Form.Select
                    className="form-select mb-3 text-center "
                    value={formData.day}
                    onChange={(e) => setFormData({ ...formData, day: Number(e.target.value) })}>
                    <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                    {Days?.map((day) => (
                      <option key={day} value={day}>
                        {day}
                      </option>
                    ))}
                  </Form.Select>
                </div>
              </div>
            )}

            {(formData.PeriodType === 'Daily' ||
              formData.PeriodType === 'Weekly' ||
              formData.PeriodType === 'Monthly' ||
              formData.PeriodType === 'Yearly') && (
              //  {/* ساعة */}
              <div className="col-lg-3 m-auto">
                <div className="form-group mb-3">
                  <label className="form-label">{t('الساعة')}</label>
                  <Form.Select
                    className="form-select mb-3 text-center"
                    value={formData.hrs}
                    onChange={(e) => setFormData({ ...formData, hrs: Number(e.target.value) })}>
                    <option value="222">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                    {Hours?.map((hrs) => (
                      <option key={hrs} value={hrs}>
                        {hrs}
                      </option>
                    ))}
                  </Form.Select>
                </div>
              </div>
            )}

            {(formData.PeriodType === 'Hourly' ||
              formData.PeriodType === 'Daily' ||
              formData.PeriodType === 'Weekly' ||
              formData.PeriodType === 'Monthly' ||
              formData.PeriodType === 'Yearly') && (
              //  {/* دقيقة */}
              <div className="col-lg-3 m-auto">
                <div className="form-group mb-3">
                  <label className="form-label">{t('الدقيقة')}</label>
                  <Form.Select
                    className="form-select mb-3 text-center"
                    value={formData.Minuts}
                    onChange={(e) => setFormData({ ...formData, min: Number(e.target.value) })}>
                    <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                    {Minuts?.map((min) => (
                      <option key={min} value={min}>
                        {min}
                      </option>
                    ))}
                  </Form.Select>
                </div>
              </div>
            )}

            {(formData.PeriodType === 'MonthInterval' ||
              formData.PeriodType === 'DayInterval' ||
              formData.PeriodType === 'HourInterval' ||
              formData.PeriodType === 'MinuteInterval') && (
              <div className="col-lg-6 m-auto">
                <div className="form-group mb-3">
                  <label className="form-label">{t('عدد')}</label>
                  <Form.Control
                    className="form-control  text-center"
                    type="number"
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        number: Number(e.target.value),
                      })
                    }
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Step5;

// <div className="row">
//         <div className="col-lg-2 ">
//           <div className="form-group mb-3">
//             <label className="form-label">{t('Type')}</label>
//             <Form.Select
//               className="form-select mb-3 "
//               value={formData.PeriodType}
//               onChange={(e) => setFormData({ ...formData, PeriodType: e.target.value })}>
//               <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
//               <option value="Hourly">{i18n.language === 'en' ? 'Hourly' : 'كل ساعة'}</option>
//               <option value="Daily">{i18n.language === 'en' ? 'Daily' : 'كل يوم'}</option>
//               <option value="Weekly">{i18n.language === 'en' ? 'Weekly' : 'كل أسبوع'}</option>
//               <option value="Monthly">{i18n.language === 'en' ? 'Monthly' : 'كل شهر'}</option>
//               <option value="Yearly">{i18n.language === 'en' ? 'Yearly' : 'كل سنة'}</option>
//               <option value="MinuteInterval">
//                 {i18n.language === 'en' ? 'MinuteInterval' : 'كل عدد دقائق'}
//               </option>
//               <option value="HourInterval">
//                 {i18n.language === 'en' ? 'HourInterval' : 'كل عدد ساعات'}
//               </option>
//               <option value="DayInterval">
//                 {i18n.language === 'en' ? 'DayInterval' : 'كل عدد أيام'}
//               </option>
//               <option value="MonthInterval">
//                 {i18n.language === 'en' ? 'MonthInterval' : 'كل عدد أشهر'}
//               </option>
//             </Form.Select>
//           </div>
//         </div>
//         {formData.PeriodType === 'Weekly' && (
//           //  {/* أيام الاسبوع */}
//           <div className="col-lg-2 m-auto">
//             <div className="form-group mb-3">
//               <label className="form-label">{t('أيام الأسبوع')}</label>
//               <Form.Select
//                 className="form-select mb-3 "
//                 value={formData.dweek}
//                 onChange={(e) =>
//                   setFormData({
//                     ...formData,
//                     dweek: Number(e.target.value),
//                   })
//                 }>
//                 <option value="2024">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
//                 <option value="6">{i18n.language === 'en' ? 'Saturday' : 'السبت'}</option>
//                 <option value="0">{i18n.language === 'en' ? 'Sunday' : 'الأحد'}</option>
//                 <option value="1">{i18n.language === 'en' ? 'Monday' : 'الأثنين'}</option>
//                 <option value="2">{i18n.language === 'en' ? 'Tuesday' : 'الثلاثاء'}</option>
//                 <option value="3">{i18n.language === 'en' ? 'Wednesday' : 'الأربعاء'}</option>
//                 <option value="4">{i18n.language === 'en' ? 'Thursday' : 'الخميس'}</option>
//                 <option value="5">{i18n.language === 'en' ? 'Friday' : 'الجمعة'}</option>
//               </Form.Select>
//             </div>
//           </div>
//         )}

//         {formData.PeriodType === 'Yearly' && (
//           // {/* شهر */}
//           <div className="col-lg-2 m-auto">
//             <div className="form-group mb-3">
//               <label className="form-label">{t('الشهر')}</label>
//               <Form.Select
//                 className="form-select mb-3 "
//                 value={formData.mnth}
//                 onChange={(e) => setFormData({ ...formData, mnth: Number(e.target.value) })}>
//                 <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
//                 {Month?.map((mnth) => (
//                   <option key={mnth} value={mnth}>
//                     {mnth}
//                   </option>
//                 ))}
//               </Form.Select>
//             </div>
//           </div>
//         )}

//         {/* يوم */}
//         {(formData.PeriodType === 'Monthly' || formData.PeriodType === 'Yearly') && (
//           <div className="col-lg-2 m-auto">
//             <div className="form-group mb-3">
//               <label className="form-label">{t('اليوم')}</label>
//               <Form.Select
//                 className="form-select mb-3 "
//                 value={formData.day}
//                 onChange={(e) => setFormData({ ...formData, day: Number(e.target.value) })}>
//                 <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
//                 {Days?.map((day) => (
//                   <option key={day} value={day}>
//                     {day}
//                   </option>
//                 ))}
//               </Form.Select>
//             </div>
//           </div>
//         )}

//         {(formData.PeriodType === 'Daily' ||
//           formData.PeriodType === 'Weekly' ||
//           formData.PeriodType === 'Monthly' ||
//           formData.PeriodType === 'Yearly') && (
//           //  {/* ساعة */}
//           <div className="col-lg-2 m-auto">
//             <div className="form-group mb-3">
//               <label className="form-label">{t('الساعة')}</label>
//               <Form.Select
//                 className="form-select mb-3 "
//                 value={formData.hrs}
//                 onChange={(e) => setFormData({ ...formData, hrs: Number(e.target.value) })}>
//                 <option value="222">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
//                 {Hours?.map((hrs) => (
//                   <option key={hrs} value={hrs}>
//                     {hrs}
//                   </option>
//                 ))}
//               </Form.Select>
//             </div>
//           </div>
//         )}

//         {(formData.PeriodType === 'Hourly' ||
//           formData.PeriodType === 'Daily' ||
//           formData.PeriodType === 'Weekly' ||
//           formData.PeriodType === 'Monthly' ||
//           formData.PeriodType === 'Yearly') && (
//           //  {/* دقيقة */}
//           <div className="col-lg-2 m-auto">
//             <div className="form-group mb-3">
//               <label className="form-label">{t('الدقيقة')}</label>
//               <Form.Select
//                 className="form-select mb-3 "
//                 value={formData.Minuts}
//                 onChange={(e) => setFormData({ ...formData, min: Number(e.target.value) })}>
//                 <option value="">{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
//                 {Minuts?.map((min) => (
//                   <option key={min} value={min}>
//                     {min}
//                   </option>
//                 ))}
//               </Form.Select>
//             </div>
//           </div>
//         )}

//         {(formData.PeriodType === 'MonthInterval' ||
//           formData.PeriodType === 'DayInterval' ||
//           formData.PeriodType === 'HourInterval' ||
//           formData.PeriodType === 'MinuteInterval') && (
//           <div className="col-lg-2 m-auto">
//             <div className="form-group mb-3">
//               <label className="form-label">{t('عدد')}</label>
//               <Form.Control
//                 className="form-control  text-center"
//                 type="number"
//                 onChange={(e) =>
//                   setFormData({
//                     ...formData,
//                     number: Number(e.target.value),
//                   })
//                 }
//               />
//             </div>
//           </div>
//         )}
//       </div>
