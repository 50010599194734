import React, { memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MentionsInput, Mention } from 'react-mentions';
import MentionStyle from './MentionStyle.js';
import EmailTab from './EmailTab';
import NotificationTab from './NotificationTab';
import MobileTab from './MobileTab';
import { Step4Modals } from './Step4Modals';

interface Props {
  Columns: any[];
  DataStep1: any;
  setDataStep4: React.Dispatch<React.SetStateAction<any>>;
  setCheckValidationStep4: React.Dispatch<React.SetStateAction<boolean>>;
}

//
const Step4: React.FC<Props> = ({ Columns, DataStep1, setDataStep4, setCheckValidationStep4 }) => {
  const { t, i18n } = useTranslation();
  const [suggestedList, setSuggestedList] = useState<{ id: string; display: string }[]>([]);

  const [formData, setFormData] = useState<Step4Modals>({
    messageTemplate: '',
    emailSubject: '',
    emailColumn: '',
    emailTo: [],
    emailCC: [],
    userColumn: '',
    userIds: [],
    mobileColumn: '',
    mobiles: [],
  });

  useEffect(() => {
    const outputList = Columns?.map((item) => ({
      id: item?.AliasName,
      display: item?.AliasName,
    }));
    setSuggestedList(outputList);
  }, [Columns]);

  useEffect(() => {
    setDataStep4(formData);
  }, [formData]);

  //________________________________________________________________
  useEffect(() => {
    if (formData.messageTemplate && formData.messageTemplate !== '') {
      setCheckValidationStep4(true);
    } else {
      setCheckValidationStep4(false);
    }
  }, [formData]);

  return (
    <div>
      <div className="form-group mb-3 shadow-sm">
        <label className="form-label">نص الرسالة</label>
        <MentionsInput
          style={MentionStyle}
          value={formData.messageTemplate}
          onChange={(e) => setFormData({ ...formData, messageTemplate: e.target.value })}

          // onChange={handleChange}
        >
          <Mention
            style={{
              backgroundColor: 'var(--bs-theme)',
            }}
            data={suggestedList}
            trigger="@"
            markup="@[__display__]"
          />
        </MentionsInput>
        <small className="form-text text-muted">
          عند كتابة <strong className="text-theme fw-bold">  "@" </strong> في النص، ستظهر لك الأعمدة التي تم
          اختيارها لتضمينها في رسالة الإشعار.
        </small>
      </div>

      <div className="p-1 rounded-2 pt-0 border  shadow">
        <ul className="nav nav-tabs nav-tabs-v2 ps-4 pe-4">
        {DataStep1.Notification && (
            <li className="nav-item me-3">
              <a href="#Notifications" className="nav-link pb-1 fs-6 " data-bs-toggle="tab">
                <div className="nav-field">إشعار نظام</div>
                <div className="nav-value text-center text-theme">
                  <i className="fas fa-lg fa-fw bi bi-bell mt-2"></i>
                </div>
              </a>
            </li>
          )}

          {DataStep1?.email && (
            <li className="nav-item me-3">
              <a href="#Mail" className="nav-link pb-1 fs-6" data-bs-toggle="tab">
                <div className="nav-field">ايميل</div>
                <div className="nav-value text-center text-theme ">
                  <i className="fas fa-lg fa-fw bi bi-envelope-at mt-2"></i>
                </div>
              </a>
            </li>
          )}
          {(DataStep1.whatsapp || DataStep1.sms) && (
            <li className="nav-item me-3">
              <a href="#mobile" className="nav-link pb-1 fs-6" data-bs-toggle="tab">
                <div className="nav-field">موبايل</div>
                <div className="nav-value text-center text-theme">
                  <i className="fas fa-lg fa-fw bi bi-phone mt-2"></i>
                </div>
              </a>
            </li>
          )}


        </ul>

        <div className="tab-content p-3">
          {DataStep1?.email && (
            <div className="tab-pane fade" id="Mail">
              <EmailTab Columns={Columns} formData={formData} setFormData={setFormData} />
            </div>
          )}

          {(DataStep1.whatsapp || DataStep1.sms) && (
            <div className="tab-pane fade" id="mobile">
              <MobileTab Columns={Columns} formData={formData} setFormData={setFormData} />
            </div>
          )}

          {DataStep1.Notification && (
            <div className="tab-pane fade" id="Notifications">
              <NotificationTab Columns={Columns} formData={formData} setFormData={setFormData} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(Step4);
