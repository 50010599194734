import React, { Fragment, useState } from 'react';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Card, CardBody } from '../../../../components/card/card';
import ModalComponent from '../../../../components/Modal/ModalComponent';
import Add from './Add';
import Pagination from '../../../../components/Shared/Pagination/Pagination';
import useGetPeoplesList from '../../../../hooks/NTX/useGetPeoplesList';
// import Loader from '../../../../components/Shared/Loader/Loader';
import PeoplesContacts from '../../../NTX/Peoples/PeoplesContacts/PeoplesContacts';
import useGetCustomersList from '../../../../hooks/NTX/Peoples/useGetCustomersList';
import Loading from '../../../../components/Shared/Loader/Loading';
// import CustomersContacts from "./CustomersContacts";

const Index = () => {
  const [Show, setShow] = useState(false);
  const [peopleData, setPeopleData] = useState<any>('');
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const { t, i18n } = useTranslation();
  // const Navigate = useNavigate();

 
  const {CustomersList , loading , error} = useGetCustomersList()

  const filteredSearch = CustomersList?.slice()
    ?.filter((f) => {
      const searchLowerCase = search.toLowerCase();
      return (
        f?.name?.toLowerCase()?.includes(searchLowerCase) ||
        f?.name2?.toLowerCase()?.toString()?.includes(searchLowerCase)
      );
    })
    ?.filter((f) => f.parentId === null);

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  // console.log(peopleData);

  const titleModal = () => {
    return (
      <div className="">
         معلومات الاتصال للعميــل :{'  '}
        <span className="text-theme">
          {`(${i18n.language === 'en' ? peopleData?.name : peopleData?.name2})`}
        </span>
      </div>
    );
  };

  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: t('العملاء'),
      link: null,
    },
  ];
  return (
    <Fragment>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName={t('العملاء')} />
         <div className="ms-auto">
          <Link
            to=""
            className="btn me-1 btn-theme"
            data-bs-toggle="modal"
            data-bs-target="#modalAddCustomers"
            onClick={() => setShow(true)}>
            <i className="fa fa-plus-circle fa-fw me-1"></i>
            {t('إضافة عميل جديد')}
          </Link>
          <Link
            to="AddCustomers"
            className="btn me-1 btn-theme"
            // onClick={() => setShow(true)}
            >
            <i className="fa fa-plus-circle fa-fw me-1"></i>
            {t('إضافة عميل جديد')}
          </Link>
        </div>
      </div>

      <Card>
        <Loading loading={loading} error={error}>

      
        <CardBody>
          <div className="row">
            <div className="col-7 col-md- d-flex justify-content-start">
              <div className="input-group mb-2">
                <div className="flex-fill position-relative">
                  <div className="input-group">
                    <div
                      className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                      style={{ zIndex: 1020, right: '10px' }}>
                      <i className="fa fa-search opacity-5"></i>
                    </div>
                    <input
                      type="text"
                      className="form-control ps-35px"
                      onChange={(e) => setSearch(e.target.value)}
                      placeholder={t('Search') + ' ...'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="table-responsive">
            <table className="table table-hover text-nowrap">
              <thead className="table-dark">
                <tr>
                  <th scope="col">{t('Name')}</th>
                  <th scope="col">{t('رقم العميل')}</th>
                  <th scope="col">{t('الأسم التجاري')}</th>

                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {currentData?.map((item, index) => (
                  <tr key={item.id}>
                    <td className="align-middle">
                      {i18n.language === 'en' ? item.name : item.name2}
                    </td>
                    <td className="align-middle">{item.customerNumber || '0000'}</td>
                    <td className="align-middle">{item.tradingName}</td>

                    <td className="text-end">
                      <div className="btn-group">
                        <Link
                          to={`${item.id}/BranchesCustomer`}
                          className="btn btn-outline-theme m-0  btn-sm"
                          state={{
                            name: i18n.language === 'en' ? item.name : item.name2,
                          }}>
                          <i className="far fa-lg me-1  bi bi-diagram-2 fs-4 "></i>
                          {t('الفروع')}
                        </Link>

                        <Link
                          to={``}
                          data-bs-toggle="modal"
                          data-bs-target="#CustomersContactsTest"
                          className="btn btn-outline-theme m-0  btn-sm"
                          onClick={() => setPeopleData(item)}>
                          <i className="far fa-lg me-1 bi bi-chat"></i>
                          {t('معلومات الاتصال')}
                        </Link>
                        <Link
                          to={`${item.id}/PeopleLocations`} // رابط صفحة المواقع
                          className="btn btn-outline-theme m-0 btn-sm"
                          state={{
                            name: i18n.language === 'en' ? item.name : item.name2,
                          }}>
                          <i className="far fa-lg fa-fw me-1 bi bi-geo"></i>
                          {t('المواقع')}
                        </Link>
                        <Link
                          to={`${item.id}/UpdateCustomers`}
                          className="btn btn-outline-theme m-0  btn-sm"
                          state={{
                            name: i18n.language === 'en' ? item.name : item.name2,
                          }}>
                          <i className="far fa-lg fa-fw me-1 bi bi-info-circle"></i>
                          {t('التفاصيــل')}
                        </Link>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination
            filteredData={filteredSearch}
            dataPerPage={dataPerPage}
            handlePagination={(pageNumber: number) => setCurrentPage(pageNumber)}
            currentPage={currentPage}
          />
        </CardBody>  </Loading>
      </Card>

      <ModalComponent title={t('إضافة عميل جديد')} id="modalAddCustomers">
        {Show && <Add />}
      </ModalComponent>

      <ModalComponent title={titleModal()} id="CustomersContactsTest">
        {peopleData?.id && <PeoplesContacts peopleId={peopleData.id} />}
      </ModalComponent>
    </Fragment>
  );
};

export default Index;
