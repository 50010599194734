import { useFormik } from 'formik';
import React from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../store/hooks';
import { AddCategories } from '../../../../store/INV/Categories/CategoriesSlice';
import { Form } from 'react-bootstrap';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import useGetCategoriesList from '../../../../hooks/INV/useGetCategoriesList';
import { DataIcons } from '../../../../assets/icons';
import { CategoriesINVSchema } from '../../../ValidationForm/validationSchema';

type Props = {
  CategoryId: string;
};

const AddCategoryInv = ({ CategoryId }: Props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { CategoriesList } = useGetCategoriesList();

  // console.log(CategoryId);

  const formik = useFormik({
    initialValues: {
      categoryId: CategoryId || '',
      name2: '',
      name: '',
      description: '',
      icon: '',
    },

    validationSchema: CategoriesINVSchema(t),
    enableReinitialize: true,
    onSubmit: (values) => {
      dispatch(
        AddCategories({
          categoryId: values.categoryId || null,
          name: values.name,
          name2: values.name2,
          description: values.description,
          icon: values.icon,
        })
      )
        .unwrap()
        .then((res) => {
          if (res.succeeded === true) {
            formik.resetForm();
            document.getElementById('closeModalForm')?.click();
            CustomAlert({ action: 'Add' });
            formik.setSubmitting(false);
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error' });
          }
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
      formik.setSubmitting(false);
    },
  });

   
  return (
    <div>
      <Form onSubmit={formik.handleSubmit}>
        <div className="row">

          <div className="col-xl-4">
            <div className="form-group mb-3">
              <label className="form-label">{t('LocalName')}</label>
              <Form.Control
                className="form-control fw-bold"
                type="text"
                name="name2"
                onChange={formik.handleChange}
                value={formik.values.name2}
                isInvalid={!!(formik.touched.name2 && formik.errors.name2)}
              />
              <Form.Control.Feedback type="invalid">{formik.errors.name2}</Form.Control.Feedback>
            </div>
          </div>

          <div className="col-xl-4">
            <div className="form-group mb-3">
              <label className="form-label">{t('GlobalName')}</label>
              <Form.Control
                className="form-control fw-bold"
                type="text"
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                isInvalid={!!(formik.touched.name && formik.errors.name)}
              />
              <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
            </div>
          </div>
          
          <div className="col-xl-4">
            <div className="form-group mb-3">
              <label className="form-label"> {t('الفئة الأب')}</label>
              <Form.Select
                className="form-select"
                name="categoryId"
                onChange={formik.handleChange}
                value={formik.values.categoryId}
                isInvalid={!!(formik.touched.categoryId && formik.errors.categoryId)}
                disabled>
                <option value={''}>{i18n.language === 'en' ? 'رئيسي' : 'رئيسي'}</option>
                {CategoriesList &&
                  CategoriesList?.map((item, idx) => (
                    <option key={++idx} value={item.id}>
                      {i18n.language === 'en' ? item.name : item.name2}
                    </option>
                  ))}
              </Form.Select>
            </div>
          </div>

          <div className="col-xl-6">
            <div className="form-group mb-3">
              <label className="form-label">{t('Icon')}</label>

              <Select
                classNamePrefix="react-select"
                isSearchable={true}
                options={DataIcons}
                onChange={(option) => formik.setFieldValue('icon', option?.value)}
                placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
              />
              <>{formik.errors.icon}</>
            </div>
          </div>

          <div className="col-xl-6">
            <div className="form-group mb-3">
              <label className="form-label">{t('Note')}</label>
              <Form.Control
                className="form-control fw-bold"
                type="text"
                name="description"
                onChange={formik.handleChange}
                value={formik.values.description}
                isInvalid={!!(formik.touched.description && formik.errors.description)}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.description}
              </Form.Control.Feedback>
            </div>
          </div>
        </div>

        <div className="text-center">
          <button
            disabled={formik.isSubmitting}
            type="submit"
            className="btn me-1 btn-theme mb-1"
            id="SaveSubmit">
            {formik.isSubmitting ? (
              <div className="spinner-border spinner-border-sm me-2"></div>
            ) : (
              <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
            )}
            {t('Add')}
          </button>
          <button
            type="button"
            className=" btn me-1 btn-default mb-1"
            data-bs-dismiss="modal"
            id="closeModalForm">
            <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
          </button>
        </div>
      </Form>
    </div>
  );
};

export default AddCategoryInv;
