import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {  useParams } from "react-router-dom";
import { GetEquipmentsAssignationByEquipmentsId } from "../../../store/FLEET/EquipmentsAssignment/equipmentsAssignmentSlice";
import Pagination from "../../../components/Shared/Pagination/Pagination";

interface props {
  setID: React.Dispatch<React.SetStateAction<string>>; // For Update
}

const EquipmentsAssignmentList: React.FC<props> = (props) => {
  const { Id } = useParams();
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (Id) {
      dispatch(GetEquipmentsAssignationByEquipmentsId(Id));
    }
  }, []);
  const { AssignationListByEquipmentsId } = useAppSelector(
    (state) => state.EquipmentsAssignment
  );

  const filteredSearch = AssignationListByEquipmentsId?.slice()
    ?.filter((item) => {
      const searchLowerCase = search.toLowerCase();
      return (
          item.description?.toLowerCase().includes(searchLowerCase) ||
          item.peopleName2?.toLowerCase().includes(searchLowerCase) ||
        item.peopleName?.toLowerCase().toString().includes(searchLowerCase)
      );
    })?.sort((a, b) => {
      const dateA = new Date(a.deliveryDateFrom);
      const dateB = new Date(b.deliveryDateFrom);
      return  dateB.getTime()-dateA.getTime() ;
  });

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  return (
    <div>
      <div className="col-3 mx-auto mb-2">
        <div className="input-group">
          <div className="input-group">
            <div
              className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
              style={{ zIndex: 1020, right: "10px" }}
            >
              <i className="fa fa-search opacity-5"></i>
            </div>
            <input
              type="text"
              className="form-control ps-35px"
              onChange={(e) => setSearch(e.target.value)}
              placeholder={t("Search") + " ..."}
            />
          </div>
        </div>
      </div>
      <div className="table-responsive rounded-3 ">
        <table className="table">
          <thead className="table-dark">
            <tr>
              <th scope="col">{t("DriverName")}</th>
              <th scope="col">{t("Start Date")}</th>
              <th scope="col">{t("End_Date")}</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {currentData?.map((item, index) => (
              <tr key={item.id}>
                <td className="align-middle">
                  {i18n.language === "en" ? item.peopleName : item.peopleName2}
                </td>
                <td className="align-middle">{item.deliveryDateFrom}</td>
                <td className="align-middle">{item.deliveryDateTo}</td>
                <td>
                  <button
                    type="button"
                    className="btn btn-outline-lime"
                    data-bs-toggle="modal"
                    data-bs-target="#UpdateEquipmentsAssignment"
                    onClick={() => props.setID(item.id)}
                  >
                    <i className="far fa-lg fa-fw  fa-edit"></i>
                    {/* {t("Button.Edit")} */}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        filteredData={filteredSearch}
        dataPerPage={dataPerPage}
        handlePagination={(pageNumber: any) => setCurrentPage(pageNumber)}
        currentPage={currentPage}
      />
    </div>
  );
};

export default EquipmentsAssignmentList;
