 import { useTranslation } from 'react-i18next';

const ServerPagination = ({ currentPage, totalItems, pageSize, onPageChange, loading }) => {
  const { t } = useTranslation();

  // حساب إجمالي الصفحات
  const totalPages = Math.ceil(totalItems / pageSize);

  // تحديد عدد الأزرار المعروضة حول الصفحة الحالية
  const range = 1;
  let start = Math.max(currentPage - range, 1);
  let end = Math.min(currentPage + range, totalPages);

  // التأكد من أن الصفحات معروضة بشكل صحيح
  const pageNumbers = Array.from({ length: end - start + 1 }, (_, i) => start + i);

  // إذا كان هناك صفحة واحدة فقط، عرض رسالة بدون pagination
  if (totalPages === 1 || totalItems === 0) {
    return (
      <div className="text-center">
        {t('Showing')} {totalItems} {t('entries')}
      </div>
    );
  }

  return (
    <div className="d-md-flex align-items-center">
      <div className="me-md-auto text-md-left text-center mb-2 mb-md-0">
        {t('Showing')} {totalItems} {t('entries')}
      </div>

      {loading && <div className="spinner-grow spinner-grow-sm text-theme ms-2"></div>}

      <ul className="pagination mb-0 justify-content-center p-0">
        {/* زر السابق */}
        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
          <button
            type="button"
            onClick={() => onPageChange(currentPage - 1)}
            className="page-link"
            disabled={currentPage === 1}>
            {t('Previous')}
          </button>
        </li>

        {/* زر الأول (في حال كان هناك صفحات سابقة) */}
        {start > 1 && (
          <li className="page-item">
            <button type="button" className="page-link" onClick={() => onPageChange(start - 1)}>
              &hellip;
            </button>
          </li>
        )}

        {/* أزرار الصفحات */}
        {pageNumbers.map((number) => (
          <li className={`page-item ${number === currentPage ? 'active' : ''}`} key={number}>
            <button type="button" className="page-link" onClick={() => onPageChange(number)}>
              {number}
            </button>
          </li>
        ))}

        {/* زر الأخير (في حال كان هناك صفحات لاحقة) */}
        {end < totalPages && (
          <li className="page-item">
            <button type="button" className="page-link" onClick={() => onPageChange(end + 1)}>
              &hellip;
            </button>
          </li>
        )}

        {/* زر التالي */}
        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
          <button
            type="button"
            onClick={() => onPageChange(currentPage + 1)}
            className="page-link"
            disabled={currentPage === totalPages}>
            {t('Next')}
          </button>
        </li>
      </ul>
    </div>
  );
};

export default ServerPagination;
// const ServerPagination = ({ currentPage, totalItems, pageSize, onPageChange, loading }) => {
//   const { t } = useTranslation();

//   // حساب إجمالي الصفحات
//   const totalPages = Math.ceil(totalItems / pageSize);

//   // تحديد عدد الأزرار المعروضة حول الصفحة الحالية
//   const range = 1; // عدد الأزرار على جانبي الصفحة الحالية
//   let start = currentPage - range;
//   let end = currentPage + range;

//   // التأكد من عدم تجاوز الحدود
//   if (start < 1) {
//     end += 1 - start;
//     start = 1;
//   }
//   if (end > totalPages) {
//     start -= end - totalPages;
//     end = totalPages;
//   }

//   // التأكد من أن الصفحات معروضة بشكل صحيح
//   const pageNumbers = [];
//   for (let i = start; i <= end; i++) {
//     if (i >= 1) {
//       pageNumbers.push(i);
//     }
//   }

//   // إذا كان هناك صفحة واحدة فقط، عرض رسالة بدون pagination
//   if (totalPages === 1 || totalItems === 0) {
//     return (
//       <div className="text-center">
//         {t('Showing')} {totalItems} {t('entries')}
//       </div>
//     );
//   }

//   // if (loading) {
//   //   return <></>;
//   // }

//   return (
//     <>
//       <div className="d-md-flex align-items-center  ">
//         <div className="me-md-auto text-md-left text-center mb-2 mb-md-0">
//           {t('Showing')} {totalItems} {t('entries')}
//         </div>

//         {loading && <div className="spinner-grow spinner-grow-sm text-theme"></div>}

//           <ul className="pagination mb-0 justify-content-center p-0">
//             {/* زر السابق */}
//             <li className="page-item">
//               <button
//                 type="button"
//                 onClick={() => onPageChange(currentPage - 1)}
//                 className="page-link"
//                 disabled={currentPage === 1}>
//                 {t('Previous')}
//               </button>
//             </li>

//             {/* الزر الأول */}
//             {start > 1 && (
//               <li className="page-item">
//                 <button type="button" className="page-link" onClick={() => onPageChange(start - 1)}>
//                   &hellip;
//                 </button>
//               </li>
//             )}

//             {/* أزرار الصفحات */}
//             {pageNumbers.map((number) => (
//               <li className="page-item" key={number}>
//                 <button
//                   type="button"
//                   className={number === currentPage ? 'page-link active' : 'page-link'}
//                   onClick={() => onPageChange(number)}>
//                   {number}
//                 </button>
//               </li>
//             ))}

//             {/* الزر الأخير */}
//             {end < totalPages && (
//               <li className="page-item">
//                 <button type="button" className="page-link" onClick={() => onPageChange(end + 1)}>
//                   &hellip;
//                 </button>
//               </li>
//             )}

//             {/* زر التالي */}
//             <li className="page-item">
//               <button
//                 type="button"
//                 onClick={() => onPageChange(currentPage + 1)}
//                 className="page-link"
//                 disabled={currentPage === totalPages}>
//                 {t('Next')}
//               </button>
//             </li>
//           </ul>

//       </div>
//     </>
//   );
// };
