import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import {
  GetReportChartByReportId,
} from "../../../../../store/NTX/Reports/ReportShape/reportShapeSlice";
 import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
 import useGetChartShapesList from "../../../../../hooks/NTX/useGetChartShapesList";

interface props {
  setID: React.Dispatch<React.SetStateAction<string>>; // For Update
}

const ReportChartList: React.FC<props> = (props) => {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const { t, i18n } = useTranslation();
  const { ChartShapesList } = useGetChartShapesList();

  useEffect(() => {
    if (id) {
      dispatch(GetReportChartByReportId(id));
    }
  }, []);

  const { ReportChartByReportIdList } = useAppSelector(
    (state) => state.ReportShape
  );

  return (
    <>
      {/* <Loader loading={loading} /> */}
      <div className="row">
        <div className="col-lg-9 m-auto">
          <div className=" bg-inverse bg-opacity-5 rounded-3  ">
          <div className="table-responsive rounded-2 text-center border border-theme">
              <table className="table">
              <thead className="bg-theme bg-opacity-60">
                  <tr>
                    <th scope="col">{t("Current_Charts")} </th>
                    <th scope="col">{t("Shape")} </th>
                    <th scope="col"></th>
                  </tr>
                </thead>

                <tbody>
                  {ReportChartByReportIdList &&
                    ReportChartByReportIdList?.map((item, idx) => (
                      <tr key={++idx}>
                        <td className="align-middle">
                          {i18n.language === "ar" ? item.name2 : item.name}
                        </td>

                        <td className="align-middle">
                          <i
                            className={`${
                              ChartShapesList?.find((f) => f.id === item.shapeId)
                                ?.icon
                            }
                               text-theme fs-3 `}
                          ></i>
                          {i18n.language === "en"
                            ? ChartShapesList?.find((f) => f.id === item.shapeId)
                                ?.name
                            : ChartShapesList?.find((f) => f.id === item.shapeId)
                                ?.name2}
                        </td>

                        <td className="align-middle">
                          <button
                            type="button"
                            className="btn btn-lg btn-outline-theme"
                            data-bs-toggle="modal"
                            data-bs-target="#UpdateReportShape"
                            onClick={() => props.setID(item.id)}
                          >
                            <i className="far fa-lg fa-fw m-2 fa-edit"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportChartList;
