import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { Form } from 'react-bootstrap';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';

import { addPosPaymentMethod } from '../../../../store/Sales/Pos/PosPaymentMethodSlice';
import useGetPaymentMethod from '../../../../hooks/Payrolls/use-Get-PaymentMethod';
import { useState } from 'react';

import './style.css';
type Props = {
  posId: string;
};

const PosPaymentMethod = ({ posId }: Props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { PosList } = useAppSelector((state) => state.Pos);

  const { PaymentMethodList } = useGetPaymentMethod();

  const formik = useFormik({
    initialValues: {
      posId: posId,
      paymentMethodId: '',
    },

    // validationSchema: formOrganization(t),
    enableReinitialize: true,
    onSubmit: (values) => {
      dispatch(
        addPosPaymentMethod({
          posId: values.posId,
          paymentMethodId: values.paymentMethodId,
        })
      )
        .unwrap()
        .then((res) => {
          if (res.succeeded === true) {
            formik.resetForm();
            // document.getElementById("AddPriceList")?.click();
            CustomAlert({ action: 'Add' });
            formik.setSubmitting(false);
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error' });
          }
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
      formik.setSubmitting(false);
    },
  });

  var posName =
    i18n.language === 'en'
      ? PosList?.find((f) => f.id?.toLowerCase() === posId.toLowerCase())?.name
      : PosList?.find((f) => f.id === posId)?.name2;

  ///_______________________________________

  // // بيانات ديناميكية للمنتجات
  // const [items, setItems] = useState([
  //   { id: 1, name: 'Product 1', quantity: 2, price: 10, discount: 0 },
  //   { id: 2, name: 'Product 2', quantity: 1, price: 20, discount: 0 },
  //   { id: 3, name: 'Product 3', quantity: 3, price: 15, discount: 10 },
  // ]);

  // const calculateTotal = () => {
  //   return items.reduce((total, item) => {
  //     const discountAmount = (item.price * item.quantity * item.discount) / 100;
  //     return total + (item.price * item.quantity - discountAmount);
  //   }, 0);
  // };

  return (
    <div>
      <Form onSubmit={formik.handleSubmit}>
        <div className="row mb-3">
          <div className="col-lg-6">
            <div className="form-group mb-3">
              <label className="form-label">{t('اسم نقطة البيع')}</label>
              <Form.Control className="form-control  " type="text" value={posName} />
            </div>
          </div>

          <div className="col-xl-6">
            <div className="form-group mb-3">
              <label className="form-label">{t('طريقة الدفع')}</label>
              <Form.Select
                className="form-select"
                name="paymentMethodId"
                onChange={formik.handleChange}
                value={formik.values.paymentMethodId}
                isInvalid={!!(formik.touched.paymentMethodId && formik.errors.paymentMethodId)}>
                <option key={null}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                {PaymentMethodList?.map((item: any) => (
                  <option key={item.id} value={item.id}>
                    {i18n.language === 'ar' ? item.name : item.name2}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {formik.errors.paymentMethodId}
              </Form.Control.Feedback>
            </div>
          </div>
        </div>

        <div className="text-center">
          <button
            disabled={formik.isSubmitting}
            type="submit"
            className="btn me-1 btn-theme mb-1"
            id="SaveSubmit">
            {formik.isSubmitting ? (
              <div className="spinner-border spinner-border-sm me-2"></div>
            ) : (
              <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
            )}
            {t('Add')}
          </button>
          {/* <button
            type="button"
            className=" btn me-1 btn-default mb-1"
            data-bs-dismiss="modal"
            id="closeModalForm">
            <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
          </button> */}
        </div>
      </Form>

      {/* <div className="invoice-container">
        <h2 className="invoice-header">فاتورة بيع</h2>

        <div className="invoice-details">
          <div>التاريخ: {new Date().toLocaleDateString()}</div>
          <div>العميل: اسم العميل</div>
        </div>

        <table className="invoice-table">
          <thead>
            <tr>
              <th>المنتج</th>
              <th>الكمية</th>
              <th>السعر</th>
              <th>الخصم (%)</th>
              <th>الإجمالي</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item) => (
              <tr key={item.id}>
                <td>{item.name}</td>
                <td>{item.quantity}</td>
                <td>{item.price.toFixed(2)}</td>
                <td>{item.discount}%</td>
                <td>
                  {(
                    item.quantity * item.price -
                    (item.quantity * item.price * item.discount) / 100
                  ).toFixed(2)}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={4}>المجموع الكلي:</td>
              <td>{calculateTotal().toFixed(2)}</td>
            </tr>
          </tfoot>
        </table>

        <div className="invoice-footer">
          <p>شكراً لتعاملكم معنا!</p>
        </div>
      </div>
      <button className="no-print" onClick={() => window.print()}>طباعة الفاتورة</button> */}

    </div>
  );
};

export default PosPaymentMethod;
