import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../components/Shared/Breadcrumb";
import { Card, CardBody } from "../../../components/card/card";
import { Form } from "react-bootstrap";
import CustomAlert from "../../../components/Shared/Alert/CustomAlert";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GetEquipmentsAssignationByEquipmentsId, InsertEquipmentsAssignment } from "../../../store/FLEET/EquipmentsAssignment/equipmentsAssignmentSlice";
import EquipmentsAssignmentList from "./EquipmentsAssignmentList";
import MedModalComponent from "../../../components/Modal/MedModalComponent";
import EditEquipmentsAssignment from "./EditEquipmentsAssignment";
import { EquipmentsAssignmentSchema } from "../../ValidationForm/validationSchema";
import Loader from "../../../components/Shared/Loader/Loader";
import useGetPeoplesList from "../../../hooks/NTX/useGetPeoplesList";

const EquipmentsAssignment = () => {
  const [ID, setID] = useState<string>(""); // لتعريفة من مكون القائمة ومن ثم استعماله في مكون التعديل
  const { Id  } = useParams();
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const Navigate = useNavigate();
  const {DriversList} = useGetPeoplesList({LookupType : ""})
  const loading = useAppSelector(state => state.EquipmentsAssignment.loading)
 
  const formik = useFormik({
    initialValues: {
      fleetEquipmentsId: "",
      peopleId: "",
      deliveryDateFrom: "",
      deliveryDateTo: "",
      description: "",
    },
    validationSchema: EquipmentsAssignmentSchema(t),
    onSubmit: (values) => {
      

      dispatch(
        InsertEquipmentsAssignment({
          fleetEquipmentsId: Id,
          peopleId: values.peopleId,
          deliveryDateFrom: values.deliveryDateFrom,
          // deliveryDateTo: values.deliveryDateTo,
          description: values.description,
        })
      )
        .unwrap()
        .then((res) => {
          if (res?.succeeded === true) {
            CustomAlert({ action: "Add" });
            
              dispatch(GetEquipmentsAssignationByEquipmentsId(Id || ""));
            
            formik.resetForm();
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: "Error", msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: "Error" });
        });
    },
  });


   //#region
   const location = useLocation();
   const [referenceName, setReferenceName] = useState(null); // State to hold ReferenceName
   useEffect(() => {
     if (location.state && location.state.ReferenceName) {
       const { ReferenceName } = location.state;
       if (ReferenceName) {
         setReferenceName(ReferenceName); // Update ReferenceName state
       }
     }
   }, []);
   //#endregion
  //____________________________
  var BcrumbList = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "Assets_and_Equipment",
      link: "/FLEET/Equipments",
    },
    {
      name: "Drivers",
      link: null,
    },
  ];
  return (
    <div>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName="Drivers" subtitle={referenceName} />

      <Card>
        <CardBody>
          <Loader loading={loading}/>
          <Form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-xl-4">
                <div className="form-group mb-3">
                  <label className="form-label"> {t("DriverName")}</label>
                  <Form.Select
                    className="form-select"
                    name="peopleId"
                    onChange={formik.handleChange}
                    value={formik.values.peopleId || ""}
                    isInvalid={
                      !!(formik.touched.peopleId && formik.errors.peopleId)
                    }
                  >
                    <option value="">
                      {i18n.language === "ar" ? "إختر" : "Choose"}
                    </option>
                    {DriversList &&
                      DriversList?.filter((f) => f.typeCode === "DRIVERS")?.map(
                        (item, idx) => (
                          <option key={++idx} value={item.id}>
                            {i18n.language === "ar" ? item.name2 : item.name}
                          </option>
                        )
                      )}
                  </Form.Select>
                </div>
              </div>

              <div className="col-xl-4">
                <div className="form-group mb-3">
                  <label className="form-label">{t("Start Date")}</label>
                  <Form.Control
                    className="form-control"
                    type="datetime-local"
                    name="deliveryDateFrom"
                    onChange={formik.handleChange}
                    value={formik.values.deliveryDateFrom}
                    isInvalid={
                      !!(
                        formik.touched.deliveryDateFrom &&
                        formik.errors.deliveryDateFrom
                      )
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.deliveryDateFrom}
                  </Form.Control.Feedback>
                </div>
              </div>
              {/* <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t("End_Date")}</label>
                  <Form.Control
                    className="form-control"
                    type="datetime-local"
                    name="deliveryDateTo"
                    onChange={formik.handleChange}
                    value={formik.values.deliveryDateTo}
                    isInvalid={
                      !!(
                        formik.touched.deliveryDateTo &&
                        formik.errors.deliveryDateTo
                      )
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.deliveryDateTo}
                  </Form.Control.Feedback>
                </div>
              </div> */}
                <div className="col-xl-4">
                <div className="form-group mb-3">
                  <label className="form-label">{t("Note")}</label>
                  <Form.Control
                    className="form-control"
                    as="textarea"
                    rows={1}
                    name="description"
                    onChange={formik.handleChange}
                    value={formik.values.description}
                  />
                  <div></div>
                </div>
              </div>
            </div>

            <div className="row">

            </div>

            <div className="text-center">
              <button
                disabled={formik.isSubmitting}
                type="submit"
                className="btn me-1 btn-theme mb-1"
                id="SaveSubmit"
              >
                {formik.isSubmitting ? (
                  <div className="spinner-border spinner-border-sm me-2"></div>
                ) : (
                  <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
                )}
                {t("Add")}
              </button>
              <button
                type="button"
                className=" btn me-1 btn-default mb-1"
                onClick={() => Navigate("/FLEET/Equipments", { replace: true })}
              >
                <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
              </button>
            </div>
          </Form>

          <hr />
          <div className=" bg-inverse bg-opacity-5 rounded-4 pt-1">
          <EquipmentsAssignmentList setID={setID} />
          </div>
        </CardBody>
      </Card>

      <MedModalComponent
        title={t("UpdateEquipmentsAssignment")}
        id="UpdateEquipmentsAssignment"
      >
        {ID && <EditEquipmentsAssignment ID={ID} />}
      </MedModalComponent>
    </div>
  );
};

export default EquipmentsAssignment;
