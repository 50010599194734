import { useFormik } from 'formik';
import Form from 'react-bootstrap/Form';
import { useEffect } from 'react';
import Select from 'react-select';
import {
  EditOrganization,
  cleanOrganization,
  getOrganization,
  getOrganizations,
} from '../../../../store/HR/Organizations/organizationSlice';
import { formOrganization } from '../../../ValidationForm/validationSchema';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
// import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useLookupOrgType from '../../../../hooks/Lookups/use-Lookup-OrgType';
import useLookupOrgClass from '../../../../hooks/Lookups/use-Lookup-OrgClass';
// import Loader from '../../../../components/Shared/Loader/Loader';
import useGetLocations from '../../../../hooks/HRSetting/use-get-locations';
import Loading from '../../../../components/Shared/Loader/Loading';
import useGetChartAccountsDetailsList from '../../../../hooks/Finance/useGetChartAccountsDetailsList';
import SwalAlert from '../../../../components/Shared/Alert/SwalAlert';

const EditFormDepartments = (props) => {
  // const Navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { ORG_TYPE } = useLookupOrgType();
  const { dataORGCLASS } = useLookupOrgClass();
  const { Locations } = useGetLocations();

  const { loadingById, error, Organization } = useSelector((state) => state.Organizations);
  const { OptionsAccounts, loading: loadingAccounts } = useGetChartAccountsDetailsList();

  useEffect(() => {
    dispatch(cleanOrganization());
    dispatch(getOrganization(props.id));
  }, [dispatch, props.id]);

  const formik = useFormik({
    initialValues: {
      // ID: "",
      name2: Organization ? Organization.name2 : '', // data?.name ?? '',
      name: Organization ? Organization.name : '',
      locationId: Organization ? Organization.locationId : '',
      externalFlag: Organization ? Organization.externalFlag : false,
      dateFrom: Organization ? Organization.dateFrom : '',
      description: Organization ? Organization.description : '',
      type: Organization ? Organization.type : '',
      organizationInformation: Organization ? Organization.organizationInformation : '',
      accountId: Organization ? Organization.accountId : '',
      // organizationInformation: Organization?.organizationInformation.split(",")?.map(a => a.replace(/"/g, '')),
    },

    // new Date(apiResponse.datetime).toISOString().slice(0, -1),
    enableReinitialize: true,
    validationSchema: formOrganization(t),

    onSubmit: (values, { resetForm }) => {
      debugger
      if (values.type === '30' && Organization.type !== '30' ) {
        SwalAlert({
          icon: 'info',
          text: 'يرجى التواصل مع الدعم الفني لإتمام عملية إضافة شركة جديدة.',
        });
        formik.setSubmitting(false);
        return;
      }
      dispatch(
        EditOrganization({
          id: Organization.id,
          businessGroupId: null,
          locationId: values.locationId,
          dateFrom: values.dateFrom,
          name: values.name,
          name2: values.name2,
          externalFlag: values.externalFlag,
          description: values.description,
          type: values.type,
          organizationInformation: values.organizationInformation,
          dateTo: null,
          accountId: values.accountId,
        })
      )
        .unwrap()
        .then(() => {
          resetForm();
          CustomAlert({ action: 'Edit' });
          // Navigate(-1, { replace: true });
          dispatch(getOrganizations());
        })
        .catch((error) => {
          CustomAlert({ action: 'Error' });
          formik.setSubmitting(false);
        });
    },
  });

  // جلب قيم معلومات الادارة  والوصول الى مسمياتها من خلال القيم
  const LabelArray =
    Organization &&
    formik.values.organizationInformation?.split(',')?.map((value) => {
      const label = dataORGCLASS?.find((f) => f.value === value.trim());
      return label;
    });

  console.log(LabelArray);

  return (
    <>
      <Loading loading={loadingById} error={error}>
        <Form onSubmit={formik.handleSubmit}>
          <div className="row">
            <div className="col-xl-6">
              <div className="form-group mb-3">
                <label className="form-label">{t('LocalName')}</label>
                <Form.Control
                  className="form-control"
                  type="text"
                  name="name2"
                  onChange={formik.handleChange}
                  value={formik.values.name2 || ''}
                  isInvalid={formik.touched.name2 && formik.errors.name2}
                />
                <Form.Control.Feedback type="invalid">{formik.errors.name2}</Form.Control.Feedback>
              </div>
            </div>

            <div className="col-xl-6">
              <div className="form-group mb-3">
                <label className="form-label">{t('GlobalName')}</label>
                <Form.Control
                  className="form-control"
                  placeholder="الاسم بالانجليزي"
                  type="text"
                  name="name"
                  onChange={formik.handleChange}
                  value={formik.values.name || ''}
                  isInvalid={formik.touched.name && formik.errors.name}
                />
                <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-6">
              <div className="form-group mb-3">
                <label className="form-label"> {t('location')}</label>
                <Form.Select
                  className="form-select"
                  name="locationId"
                  onChange={formik.handleChange}
                  value={formik.values.locationId || ''}
                  isInvalid={formik.touched.locationId && formik.errors.locationId}>
                  <option key={null}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                  {Locations?.map((item, idx) => (
                    <option key={++idx} value={item.id}>
                      {i18n.language === 'ar' ? item.name2 : item.name}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.locationId}
                </Form.Control.Feedback>
              </div>
            </div>

            <div className="col-xl-6">
              <div className="form-group mb-3">
                <label className="form-label">{t('InternalOrExternal')}</label>
                <Form.Check
                  style={{ margin: '10px 20px' }}
                  className="form-check form-switch"
                  type="switch"
                  id="custom-switch"
                  name="externalFlag"
                  checked={formik.values.externalFlag || false}
                  onChange={formik.handleChange}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-6">
              <div className="form-group mb-3">
                <label className="form-label">{t('Type')}</label>
                <Form.Select
                  disabled={props.type !== undefined}
                  className="form-select"
                  name="type"
                  onChange={formik.handleChange}
                  value={formik.values.type || ''}
                  isInvalid={formik.touched.type && formik.errors.type}>
                  <option key={null}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                  {ORG_TYPE?.map((item, idx) =>
                    item.list?.map((list) => (
                      <option key={++idx} value={list.lookupCode}>
                        {i18n.language === 'ar' ? list.meaning2 : list.meaning}
                      </option>
                    ))
                  )}
                </Form.Select>
                <Form.Control.Feedback type="invalid">{formik.errors.type}</Form.Control.Feedback>
              </div>
            </div>

            <div className="col-xl-6">
              <div className="form-group mb-3">
                <label className="form-label">{t('Start Date')}</label>
                <Form.Control
                  type="date"
                  id="START_DATE"
                  className="form-control"
                  name="dateFrom"
                  onChange={formik.handleChange}
                  value={formik.values.dateFrom || ''}
                  isInvalid={!!formik.errors.dateFrom}
                />

                <Form.Control.Feedback type="invalid">
                  {formik.errors.dateFrom}
                </Form.Control.Feedback>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-6">
              <div className="form-group mb-3">
                <label className="form-label">{t('Note')}</label>
                <Form.Control
                  className="form-control"
                  type="text"
                  name="description"
                  onChange={formik.handleChange}
                  value={formik.values.description || ''}
                  // isInvalid={!!formik.errors.name_ar}
                />
              </div>
            </div>
            <div className="col-xl-6">
              <div className="form-group mb-3">
                <label className="form-label">{t('الحساب')}</label>
                <Select
                  classNamePrefix="react-select"
                  // className="text-center"
                  isLoading={loadingAccounts}
                  isSearchable={true}
                  isClearable
                  options={OptionsAccounts}
                  value={OptionsAccounts?.find((f) => f.value === formik.values.accountId)}
                  onChange={(option) => formik.setFieldValue('accountId', option?.value)}
                  placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                />
              </div>
            </div>
          </div>

          {!props.type && (
            <div className="col-xl-12">
              <div className="mb-3">
                <label className="form-label">
                  {t('Classification')} <span className="text-danger">*</span>
                </label>
                <Select
                  // required
                  isMulti
                  options={dataORGCLASS}
                  classNamePrefix="react-select"
                  name="organizationInformation"
                  isSearchable={true}
                  value={LabelArray}
                  onChange={(e) => {
                    console.log(e);
                    const formattedOptions = e?.map((option) => option.value).join(',');
                    formik.setFieldValue('organizationInformation', formattedOptions);
                  }}

                  // onChange={(e) =>
                  //   setFormData({
                  //     ...formData,
                  //     // userIds: e?.map((option) => option.value)
                  //     userIds: e ? e?.map((option : any) => option.value) : []

                  //   })
                  // }
                />
              </div>
            </div>
          )}
          <div className="text-center mt-3">
            <button
              disabled={formik.isSubmitting}
              type="submit"
              className="btn me-1 btn-theme mb-1"
              id="SaveSubmit">
              {formik.isSubmitting ? (
                <div className="spinner-border spinner-border-sm me-2"></div>
              ) : (
                <i className="fa fa-save fa-fw me-1"></i>
              )}
              {t('Save')}
            </button>

            <button
              type="button"
              className=" btn me-1 btn-default mb-1"
              // onClick={() => Navigate(-1, { replace: true })}
              data-bs-dismiss="modal">
              <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
            </button>
          </div>
        </Form>
      </Loading>
    </>
  );
};

export default EditFormDepartments;
