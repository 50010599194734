import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import Pagination from '../../../../components/Shared/Pagination/Pagination.jsx';
import { getGroupEmp } from '../../../../store/HR/HRSetting/groupEmpSlice.js';
import { Card, CardBody } from '../../../../components/card/card.jsx';
import AddGroupEmployees from './AddGroupEmployees.js';
import { usePermissions } from '../../../../Helper/Permisstions/usePermissions.ts';
import Loader from '../../../../components/Shared/Loader/Loader.js';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import MedModalComponent from '../../../../components/Modal/MedModalComponent.jsx';
import Loading from '../../../../components/Shared/Loader/Loading';

const GroupEmployees = () => {
  const { HasPermission } = usePermissions();

  const [Show, setShow] = useState(false);
  const { GroupEmps, loading, error } = useSelector((state) => state.Groups);
  const dispatch = useDispatch();
  useEffect(() => {
    if (GroupEmps?.length === 0) {
      dispatch(getGroupEmp());
    }
  }, []);

  const { t, i18n } = useTranslation();
  const Navigate = useNavigate();
  //////////////////////////////////////////////////////////////////////////////////////////////////////////
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(5);

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(column);
      setSortOrder('asc');
    }
  };

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = GroupEmps?.filter((item) =>
    item.name.toLowerCase().includes(search.toLowerCase())
  )
    .sort((a, b) => {
      if (sortOrder === 'asc') {
        return a[sortBy] > b[sortBy] ? 1 : -1;
      } else {
        return b[sortBy] > a[sortBy] ? 1 : -1;
      }
    })
    ?.slice(indexOfFirstData, indexOfLastData);

  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: t('Staffgroup'),
      link: null,
    },
  ];

  return (
    <>
 

      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName={t('Staffgroup')} />
        {HasPermission('AddHrPerGroups') && (
          <div className="ms-auto">
            <Link
              to=""
              className="btn me-1 btn-outline-theme"
              data-bs-toggle="modal"
              data-bs-target="#modalAddGroup"
              onClick={() => setShow(true)}>
              <i className="fa fa-plus-circle fa-fw me-1"></i>
              {t('AddNewStaffGroup')}
            </Link>
          </div>
        )}
      </div>
      <Card>
        <Loader />
        <Loading loading={loading} error={error}>
          <CardBody>
            <div className="tab-content p-2">
              <div className="tab-pane fade show active" id="allTab">
                <div className="row">
                  <div className="col-7 col-md-6 d-flex justify-content-start">
                    <div className="input-group mb-2">
                      <div className="flex-fill position-relative">
                        <div className="input-group">
                          <div
                            className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                            style={{ zIndex: 1020, right: '10px' }}>
                            <i className="fa fa-search opacity-5"></i>
                          </div>
                          <input
                            type="text"
                            className="form-control ps-35px"
                            onChange={handleSearch}
                            placeholder={t('Search') + ' ...'}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* //  START TABLE   */}
                <div className="table-responsive">
                  <table className="table">
                    <thead className="table-dark">
                      <tr>
                        <th scope="col" onClick={() => handleSort('name')}>
                          {t('Name')}
                        </th>
                        <th scope="col" onClick={() => handleSort('email')}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* <Loading loading={loading} error={error}> */}
                      {currentData?.map((item, index) => (
                        <tr key={item.id}>
                          {/* <td className="align-middle">{++index}</td> */}
                          <td className="align-middle">
                            {i18n.language === 'ar' ? item.name2 : item.name}
                          </td>
                          <td>
                            <div className="btn-group">
                              {HasPermission('ViewDetailsHrPerGroups') && (
                                <button
                                  type="button"
                                  className="btn btn-outline-theme"
                                  onClick={() => Navigate(`${item.id}/Edit`)}>
                                  <i className="far fa-lg fa-fw me-2 fa-edit"></i>
                                  {t('ViewDetails')}
                                </button>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                      {/* </Loading> */}
                    </tbody>
                  </table>
                </div>
                <Pagination
                  filteredData={GroupEmps}
                  // ?.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))}
                  dataPerPage={dataPerPage}
                  handlePagination={(pageNumber) => setCurrentPage(pageNumber)}
                  currentPage={currentPage}
                />
              </div>
            </div>
          </CardBody>
        </Loading>
      </Card>

      <MedModalComponent title={t('AddStaffGroup')} id="modalAddGroup">
        {Show && <AddGroupEmployees />}
      </MedModalComponent>
    </>
  );
};

export default GroupEmployees;
