import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Pagination from '../../../components/Shared/Pagination/Pagination.jsx';
// import useGetMyNotificationMessage from "../../../hooks/NTX/useGetMyNotificationMessage";
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  ChangeIsRead,
  GetMyNotificationMessageList,
} from '../../../store/NTX/Notifications/notificationSlice';

const NotificationList = ({ setShow }: any) => {
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState('');
  const [date, setDate] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(6);
  const { t, i18n } = useTranslation();

  // const { MyNotificationMessageList, loading } = useGetMyNotificationMessage();

  const { MyNotificationMessageList, loading } = useAppSelector((state) => state.Notifications);
  const Ids = MyNotificationMessageList?.filter((f) => f.isRead === false)?.map((item) => item.id);

  const filteredSearch = MyNotificationMessageList?.filter((item) => {
    const searchLowerCase = search.toLowerCase();
    return (
      item.message?.toLowerCase().includes(searchLowerCase) ||
      item.notificationDate?.toLowerCase().includes(searchLowerCase)
    );
  })
    ?.filter((f) => {
      if (!date) return true; // إذا كانت قيمة التاريخ فارغة، اجلب جميع النتائج بدون تصفية
      const itemDate = new Date(f.notificationDate).toDateString(); // تحويل التاريخ إلى كائن Date واستخراج اليوم
      const selectedDate = new Date(date).toDateString(); // تحويل التاريخ المحدد إلى كائن Date واستخراج اليوم
      return itemDate === selectedDate; // مقارنة اليوم فقط (بدون الوقت)
    })
    ?.sort((a, b) => new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime());

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);
  //________________________________

  // var formattedDate = (isoString: string) => {
  //   const date = new Date(isoString);
  //   const formatDate = date?.toISOString()?.slice(0, 16)?.replace('T', ' ');
  //   return formatDate;
  // };

  const handleChangeIsRead = () => {
    dispatch(ChangeIsRead({ notificationMessageIds: Ids }))
      .unwrap()
      .then((res) => {
        if (res.succeeded === true) {
          dispatch(GetMyNotificationMessageList());
        } else {
        }
      })
      .catch((error) => {});
  };

  return (
    <div>
      <>
        <div className="row  mb-3">
          <div className="col-lg-5">
            <div className="input-group flex-nowrap">
              <span className="input-group-text fw-bold">
                {t('Search')}
                {/* <i className="fa fa-search  text-theme ms-3"></i> */}
              </span>
              <div className="input-group">
                <div className="flex-fill position-relative">
                  <div className="input-group border-0 ">
                    <div
                      className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                      style={{ zIndex: 1020, right: '10px' }}>
                      <i className="fa fa-search opacity-5 text-theme"></i>
                    </div>
                    <input
                      type="text"
                      className="form-control ps-35px"
                      // placeholder={t('Search')}
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);
                        setCurrentPage(1);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3">
            <div className="input-group flex-nowrap">
              <span className="input-group-text fw-bold">
                {t('Date')}
                {/* <i className="fa fa-search  text-theme ms-3"></i> */}
              </span>
              <div className="input-group">
                <div className="flex-fill position-relative">
                  <div className="input-group border-0 ">
                    <input
                      type="date"
                      className="form-control "
                      value={date}
                      onChange={(e) => {
                        setDate(e.target.value);
                        setCurrentPage(1);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 text-end ">
            <div className="btn-group">
              <button
                disabled={Ids?.length === 0}
                className="btn me-1 btn-outline-theme"
                onClick={() => handleChangeIsRead()}>
                {t('تمييز الكل كـمقروء')}
              </button>
              <button className="btn me-1 btn-outline-secondary " onClick={() => setShow(false)}>
                {t('Cancel')}
              </button>
            </div>
          </div>
        </div>

        {MyNotificationMessageList?.length === 0 ? (
          <div className="border border-theme text-center bg-inverse bg-opacity-10 rounded-2 p-3 fw-bold">
            {t('ليس لديك أي إشعـــارات')}
          </div>
        ) : (
          <div className="table-responsive p-0  m-0">
            <table className="table table-hover text-nowrap ">
              <thead className="bg-theme bg-opacity-60">
                <tr>
                  <th style={{ width: '25%' }}> {t('Name')}</th>

                  <th style={{ width: '50%' }}>{t('الرسالة')}</th>

                  <th style={{ width: '25%' }}>{t('Date')}</th>
                </tr>
              </thead>
              <tbody>
                {currentData?.map((item, index) => (
                  <tr
                    key={++index}
                    className={item.isRead ? '' : 'bg-warning bg-opacity-25'} // نمط خاص إذا كان الإشعار غير مقروء
                  >
                    <td className="align-middle">
                      <i className={item.icon + ' text-theme '}></i>
                      {i18n.language === 'en' ? item.notificationName : item.notificationName2}
                    </td>
                    <td className="align-middle fw-bold">{item.message}</td>

                    <td className="align-middle">{item.notificationDate}</td>
                    {/* <td className="align-middle">{formattedDate(item.notificationDate)}</td> */}
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="m-3 ">
              <Pagination
                filteredData={filteredSearch}
                dataPerPage={dataPerPage}
                handlePagination={(pageNumber: any) => setCurrentPage(pageNumber)}
                currentPage={currentPage}
              />
            </div>
          </div>
        )}
      </>
    </div>
  );
};

export default NotificationList;

// <tr>
//   <th scope="col">Name</th>
//   <th scope="col">empNo</th>
//   <th scope="col"> transitionDate</th>
//   <th scope="col">fingerprintTimeAct1</th>
//   <th scope="col">fingerprintTimeAct2</th>
//   <th scope="col">durationAct1</th>
//   <th scope="col">dely1</th>
// </tr>
