import React, { useEffect } from 'react';
import { Card, CardBody } from '../../../../components/card/card';
import Loading from '../../../../components/Shared/Loader/Loading';
import { Link, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { getApInvoicesById } from '../../../../store/Finance/AP/ApInvoices/ApInvoicesSlice';
import { useTranslation } from 'react-i18next';
import Lines from './Lines';
import InvoicePayment from './InvoicePayment';

const ViewInvoice = () => {
  const { invoicesId } = useParams();
  const dispatch = useAppDispatch();

  const { t, i18n } = useTranslation();
  useEffect(() => {
    if (invoicesId) {
      dispatch(getApInvoicesById(invoicesId));
    }
  }, [dispatch, invoicesId]);

  const { ApInvoicesById: data } = useAppSelector((a) => a.ApInvoices);

  return (
    <div>
      <Card>
        <Loading loading={false} error={null}>
          <CardBody className="pt-1">
            <ul className="nav nav-tabs nav-tabs-v2 p-0">
              <li className="nav-item me-3 ">
                <Link to="#InvoiceDetailes" className="nav-link active  " data-bs-toggle="tab">
                  <i className="fas fa-file-alt me-2 text-theme fs-5"></i>
                  تفاصيل الفاتورة
                </Link>
              </li>
              <li className="nav-item me-3 ">
                <Link to="#InvoicePayment" className="nav-link  " data-bs-toggle="tab">
                  <i className="fas fa-undo me-2 text-theme fs-5"></i>
                  سداد لفاتورة
                </Link>
              </li>
            </ul>

            <div className="tab-content pt-3">
              <div className="tab-pane fade show active" id="InvoiceDetailes">
                <div className="row">
                  <div className="col-4">
                    <div className="input-group input-group-sm flex-nowrap mb-2">
                      <span
                        className="input-group-text  d-flex justify-content-center"
                        style={{ width: '140px' }}>
                        رقم الفاتورة
                      </span>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        value={data.invoiceNum}
                        disabled
                      />
                    </div>

                    <div className="input-group input-group-sm flex-nowrap mb-2">
                      <span
                        className="input-group-text d-flex justify-content-center "
                        style={{ width: '140px' }}>
                        تاريخ الفاتورة
                      </span>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        value={data.invoiceDate?.toString()}
                        disabled
                      />
                    </div>

                    <div className="input-group input-group-sm flex-nowrap mb-2">
                      <span
                        className="input-group-text  d-flex justify-content-center"
                        style={{ width: '140px' }}>
                        تاريخ الإستحقاق
                      </span>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        value={''}
                        disabled
                      />
                    </div>
                  </div>

                  <div className="col-4">
                    <div className="input-group input-group-sm flex-nowrap mb-2">
                      <span
                        className="input-group-text d-flex justify-content-center"
                        style={{ width: '140px' }}>
                        العملة
                      </span>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        value={data.currencyCode}
                        disabled
                      />
                    </div>

                    <div className="input-group input-group-sm flex-nowrap mb-2">
                      <span
                        className="input-group-text d-flex justify-content-center "
                        style={{ width: '140px' }}>
                        نوع الفاتورة
                      </span>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        defaultValue={data.invoiceType}
                        disabled
                      />
                    </div>

                    <div className="input-group input-group-sm flex-nowrap mb-2">
                      <span
                        className="input-group-text d-flex justify-content-center "
                        style={{ width: '140px' }}>
                        peopleId
                      </span>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        defaultValue={data.peopleId}
                        disabled
                      />
                    </div>
                  </div>

                  <div className="col-4">
                    <div className="input-group input-group-sm flex-nowrap mb-2">
                      <span
                        className="input-group-text d-flex justify-content-center "
                        style={{ width: '140px' }}>
                        الفرع
                      </span>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        defaultValue={
                          i18n.language === 'en' ? data.organizationId : data.organizationId
                        }
                        disabled
                      />
                    </div>

                    <div className="input-group input-group-sm flex-nowrap mb-2">
                      <span
                        className="input-group-text d-flex justify-content-center "
                        style={{ width: '140px' }}>
                        paymentTermsId
                      </span>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        defaultValue={
                          i18n.language === 'en' ? data.paymentTermsId : data.paymentTermsId
                        }
                        disabled
                      />
                    </div>

                    <div className="input-group input-group-sm flex-nowrap mb-2">
                      <span
                        className="input-group-text d-flex justify-content-center "
                        style={{ width: '140px' }}>
                        paymentMethodId
                      </span>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        value={data.paymentMethodId}
                        disabled
                      />
                    </div>

                    <div className="input-group input-group-sm flex-nowrap mb-2">
                      <span
                        className="input-group-text d-flex justify-content-center "
                        style={{ width: '140px' }}>
                        invoiceAmount
                      </span>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        value={data.invoiceAmount}
                        disabled
                      />
                    </div>

                    <div className="input-group input-group-sm flex-nowrap mb-2">
                      <span
                        className="input-group-text d-flex justify-content-center "
                        style={{ width: '140px' }}>
                        vatAmount
                      </span>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        value={data.vatAmount}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                {Object.keys(data)?.length > 0 && <Lines data={data.apInvoiceLinesList} />}
              </div>

              <div className="tab-pane fade" id="InvoicePayment">
                {Object.keys(data)?.length > 0 && (
                  <InvoicePayment PaymentSchedulesList={data.apPaymentSchedulesList} />
                )}
              </div>
            </div>
          </CardBody>
        </Loading>
      </Card>
    </div>
  );
};

export default ViewInvoice;
