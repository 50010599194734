import { useFormik } from 'formik';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import Select from 'react-select';
import { Card } from '../../../../components/card/card';
import { CardBody, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useGetPersons from '../../../../hooks/HR/use-get-persons';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
// import InvIssueLines from './InvIssueLines';
// import IssueReceiveLines from '../Lines/IssueReceiveLines';
import IssueLines from './IssueLines';

import { addIssueTransactions } from '../../../../store/INV/Transactions/InvIssue/invIssueSlice';
import { useNavigate } from 'react-router-dom';
import useGetTransactionTypesIssueList from '../../../../hooks/INV/InvIssue/useGetTransactionTypesIssueList';
import useGetCustomersList from '../../../../hooks/NTX/Peoples/useGetCustomersList';
import { TreeSelect } from 'antd';
import useGetSuppliersList from '../../../../hooks/NTX/Peoples/useGetSuppliersList';
import Reference from '../Reference/Reference';
import Loading from '../../../../components/Shared/Loader/Loading';
import useGetSubinventoryList from '../../../../hooks/INV/Inventories/useGetSubinventoryList';
import useGetInventoryWithPermission from '../../../../hooks/NTX/Organization/useGetInventoryWithPermission';
import useGetBranchOrgWithPermission from '../../../../hooks/NTX/Organization/useGetBranchOrgWithPermission';
import NeatixModal from '../../../../components/Modal/NeatixModal';
import { useState } from 'react';
import ToastSwal from '../../../../components/Shared/Alert/ToastSwal';
import { getPersonId } from '../../../../store/Login/loginSlice';
import { AddIssueTransactionsSchema } from '../../../ValidationForm/validationSchema';

const Add = () => {
  const [showModal, setShowModal] = useState(true);
  const [inventory, setInventory] = useState<any>(null);
  const [transType, setTransType] = useState<any>(null);

  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const Navigate = useNavigate();
  const { dataEmployees, loading, error: error4 } = useGetPersons();
  const { BranchOrgOptions: BranchsOptions, loading: loading6 } = useGetBranchOrgWithPermission();
  const { InventoryOptions, loading: loading4 } = useGetInventoryWithPermission();
  const { loading: loading1, TreeSubInventoryData } = useGetSubinventoryList();
  const { treeCustomerData, error: error2, loading: loading2 } = useGetCustomersList();
  const { treeSuppliersData, error: error3, loading: loading3 } = useGetSuppliersList();
  const { OptionsTransactionTypes, loading: loading5, error } = useGetTransactionTypesIssueList();

  const personId = useAppSelector(getPersonId);

  //#region Formik
  const formik = useFormik({
    initialValues: {
      code: '',
      transactionDate: '',
      description: '',
      branchId: '',
      transactionAmount: 0,
      referenceType: '',
      referenceId: '',
      supplierId: null,
      customerId: null,
      personId: personId,
      subInventoryId: null,
      inventoryId: '',
      address: '',
      note: '',
      transactionYear: 0,
      materialTransactionsLineList: [
        {
          id: null, //
          transactionItemId: null,
          transactionUom: '',
          unitPrice: 0,
          transactionQuantityIn: 0,
          transactionQuantityOut: 0,

          lotId: '',
          // inventoryId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
          // discount: 0,
          // codeCombinationId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
          // currencyCode: 'string',
          // currencyConversionRate: 0,
          referenceIdMaster: '',
          referenceIdLine: '',
          // transactionTypeId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
          // equivalent: 0,
          // expensesAmount: 0,
          // batchId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
        },
      ],
    },
    enableReinitialize: true,
    validationSchema: AddIssueTransactionsSchema(t),
    onSubmit: (values) => {
      dispatch(
        addIssueTransactions({
          code: values.code,
          transactionDate: values.transactionDate,
          description: values.description,
          branchId: values.branchId,
          transactionAmount: 0,
          referenceType: values.code,
          referenceId: values.referenceId || null,
          supplierId: values.supplierId || null,
          customerId: values.customerId || null,
          personId: values.personId || null,
          inventoryId: values.inventoryId,
          address: values.address,
          note: values.note,
          no: 0,
          transactionYear: 0,

          subInventoryId: values.subInventoryId,

          materialTransactionsLineList: formik.values.materialTransactionsLineList?.map((item) => ({
            transactionItemId: item.transactionItemId,
            transactionUom: item.transactionUom,
            unitPrice: 0, // item.price
            transactionQuantityIn: item.transactionQuantityIn,
            transactionQuantityOut: 0,
            lotId: item.lotId,
            inventoryId: null,
            discount: 0,
            codeCombinationId: null,
            currencyCode: '',
            currencyConversionRate: 0,
            // referenceIdMaster: item.referenceIdMaster || null, ??????????????
            // referenceIdLine: item.referenceIdLine || null, ???????????????????
            referenceIdMaster: null,
            referenceIdLine: null,
            transactionTypeId: null,
            equivalent: 0,
            expensesAmount: 0,
            batchId: null,
          })),
        })
      )
        .unwrap()
        .then((res: any) => {
          // debugger;
          if (res?.succeeded === true) {
            CustomAlert({ action: 'Add' });
            formik.resetForm();
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error', msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });

  const { values, handleChange: formikhandleChange, errors, touched } = formik;
  //#endregion
 
  const handleSubmitModal = async () => {
    if (values.branchId && inventory && transType) {
      setShowModal(false);
      await formik.setFieldValue('inventoryId', inventory);
      await formik.setFieldValue('code', transType);
    } else {
      ToastSwal({ title: 'يرجى اختيار الفرع والمخزن', timer: 1000 });
    }
  };

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'InvIssue',
      link: '',
    },
    {
      name: 'Add InvIssue',
      link: null,
    },
  ];

  return (
    <>
      <Breadcrumb
        BreadcrumbList={BcrumbList}
        PageName="إذن صــرف"
        subtitle={InventoryOptions?.find((f: any) => f.value === values.inventoryId)?.label}
      />

      <Card>
        <Loading
          loading={loading || loading2 || loading3 || loading4 || loading5}
          error={error || error2 || error3}>
          <CardBody>
            <Form onSubmit={formik.handleSubmit}>
              <div className="row ">
                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('التاريـخ')}</label>
                    <Form.Control
                      className="form-control  text-center"
                      type="date"
                      name="transactionDate"
                      onChange={formikhandleChange}
                      value={values.transactionDate || ''}
                      // isInvalid={!!(touched.transactionDate && errors.transactionDate)}
                    />
                    <div className="text-danger small">
                      {formik.touched.transactionDate && formik.errors.transactionDate}
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('الفرع')}</label>
                    <Select
                      isDisabled
                      classNamePrefix="react-select"
                      isLoading={loading3}
                      isSearchable={true}
                      isClearable
                      options={BranchsOptions}
                      value={BranchsOptions?.find((f: any) => f.value === formik.values.branchId)}
                      // onChange={(option: any) => {
                      //   formik.setFieldValue('branchId', option === null ? null : option.value);
                      // }}
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                    />
                    <div className="text-danger small">
                      {formik.touched.branchId && formik.errors.branchId}
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('المخزن')}</label>
                    <Select
                      isDisabled
                      classNamePrefix="react-select"
                      isLoading={loading4}
                      isSearchable={true}
                      isClearable
                      options={InventoryOptions}
                      value={InventoryOptions?.find((f: any) => f.value === values.inventoryId)}
                      // onChange={(option: any) => {
                      //   formik.setFieldValue('inventoryId', option === null ? null : option.value);
                      //   // formik.setFieldValue('subInventoryId', null);
                      // }}
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                    />
                    <div className="text-danger small">
                      {formik.touched.inventoryId && formik.errors.inventoryId}
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('المخزن الفرعي')}</label>
                    <TreeSelect
                      showSearch
                      className="w-100"
                      treeLine
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                      allowClear
                      treeDefaultExpandAll={false}
                      value={values.subInventoryId}
                      onChange={(option: any) => {
                        formik.setFieldValue('subInventoryId', option === null ? null : option);
                      }}
                      treeData={TreeSubInventoryData?.filter(
                        (f) => f.parentId === values.inventoryId
                      )}
                      treeNodeFilterProp="title"
                    />
                    <div className="text-danger small">
                      {formik.touched.subInventoryId && formik.errors.subInventoryId}
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('نوع الحركة')}</label>
                    <Select
                      // isDisabled
                      classNamePrefix="react-select"
                      // isLoading={loading3}
                      isSearchable={true}
                      isClearable
                      options={OptionsTransactionTypes}
                      value={OptionsTransactionTypes.find(f=>f.value === values.code)}
                      onChange={(option: any) => {
                        formik.setFieldValue('code', option === null ? null : option.value);
                        formik.setFieldValue('materialTransactionsLineList', []);
                      }}
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                    />
                    <div className="text-danger small">
                      {formik.touched.code && formik.errors.code}
                    </div>
                  </div>
                </div>
                <div className="col-xl-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('المرجع')}</label>
                    <Reference formik={formik} />
                    <div className="text-danger small">
                      {formik.touched.referenceId && formik.errors.referenceId}
                    </div>
                  </div>
                </div>
                {['SPR', ''].includes(values.code) && (
                  <div className="col-lg-3">
                    <div className="form-group mb-3">
                      <label className="form-label">{t('المورد')}</label>
                      <TreeSelect
                        showSearch
                        className="w-100"
                        treeLine
                        placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                        allowClear
                        treeDefaultExpandAll={false} // لا تقم بتوسيع كل العقد افتراضيًا
                        // loadData={} // يتم تحميل البيانات عند الطلب
                        // onSearch={} // تنفيذ البحث عند كتابة المستخدم
                        value={values.supplierId}
                        onChange={(option: any) => {
                          formik.setFieldValue('supplierId', option === null ? null : option);
                        }}
                        treeData={treeSuppliersData}
                        treeNodeFilterProp="title"
                      />
                      <div className="text-danger small">
                        {formik.touched.supplierId && formik.errors.supplierId}
                      </div>
                    </div>
                  </div>
                )}
                {['INV', 'SOI'].includes(values.code) && (
                  <div className="col-lg-3">
                    <div className="form-group mb-3">
                      <label className="form-label">{t('العميل')}</label>

                      <TreeSelect
                        showSearch
                        className="w-100"
                        treeLine
                        allowClear
                        treeDefaultExpandAll={false} // لا تقم بتوسيع كل العقد افتراضيًا
                        // loadData={} // يتم تحميل البيانات عند الطلب
                        // onSearch={} // تنفيذ البحث عند كتابة المستخدم
                        // value={formik.values.supplierId ? { value: formik.values.supplierId, label: selectedSupplierLabel } : undefined}
                        value={values.customerId}
                        onChange={(option: any) => {
                          formik.setFieldValue('customerId', option === null ? null : option);
                        }}
                        treeData={treeCustomerData}
                        treeNodeFilterProp="title"
                        placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                      />
                      <div className="text-danger small">
                        {formik.touched.customerId && formik.errors.customerId}
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-lg-3">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('personId - store keeper')}</label>
                    <Select
                      isDisabled
                      classNamePrefix="react-select"
                      isLoading={loading}
                      isSearchable={true}
                      isClearable
                      name="personId"
                      options={dataEmployees}
                      value={dataEmployees?.find((f: any) => f.value === values.personId)}
                      onChange={(option: any) => {
                        formik.setFieldValue('personId', option === null ? null : option.value);
                      }}
                      placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                    />
                    {/* <div className="text-danger small">
                      {formik.touched.personId && formik.errors.personId}
                    </div> */}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('البيــان')}</label>
                    <Form.Control
                      autoComplete="off"
                      className="form-control"
                      as="textarea"
                      rows={1}
                      name="description"
                      onChange={formik.handleChange}
                      value={values.description}
                      // isInvalid={!!(touched.description && errors.description)}
                      // isValid={touched.description && !errors.description}
                    />
                  </div>
                </div>
              </div>

              <div className="row m-0 p-0">
                {values.inventoryId && (
                  <div className="bg-inverse bg-opacity-5 p-2 position-relative">
                    <IssueLines formik={formik} />
                    {/* <IssueReceiveLines formik={formik} TransType="Issus" /> */}
                  </div>
                )}
              </div>

              <div className="text-center mt-4">
                <button
                  disabled={formik.isSubmitting}
                  type="submit"
                  className="btn btn-lg me-1 btn-theme mb-1">
                  {formik.isSubmitting ? (
                    <div className="spinner-border spinner-border-sm me-2"></div>
                  ) : (
                    <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
                  )}
                  {t('Add')}
                </button>
                <button
                  type="button"
                  className="btn btn-lg  me-1 btn-default mb-1"
                  onClick={() => Navigate(-1)}>
                  <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
                </button>
              </div>
            </Form>
          </CardBody>
        </Loading>
      </Card>

      <NeatixModal
        show={showModal}
        setShow={setShowModal}
        size="md"
        bodyClassName="p-0"
        showHeader={false}
        keyboard={false}
        backdrop={true}>
        <Card>
          <CardBody>
            <div className="row">
              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label className="form-label">{t('الفرع')}</label>
                  <Select
                    classNamePrefix="react-select"
                    isLoading={loading3}
                    isSearchable={true}
                    isClearable
                    options={BranchsOptions}
                    value={BranchsOptions?.find((f: any) => f.value === formik.values.branchId)}
                    onChange={(option: any) => {
                      formik.setFieldValue('branchId', option === null ? null : option.value);
                    }}
                    placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                  />
                </div>
              </div>

              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label className="form-label">{t('المخزن')}</label>
                  <Select
                    classNamePrefix="react-select"
                    isLoading={loading4}
                    isSearchable={true}
                    isClearable
                    options={InventoryOptions}
                    value={InventoryOptions?.find((f: any) => f.value === inventory)}
                    onChange={(option: any) => {
                      // formik.setFieldValue('inventoryId', option === null ? null : option.value);
                      setInventory(option === null ? null : option.value);
                    }}
                    placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                  />
                </div>
              </div>

              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label className="form-label">{t('نوع الحركة')}</label>
                  <Select
                    classNamePrefix="react-select"
                    isSearchable={true}
                    isClearable
                    options={OptionsTransactionTypes}
                    onChange={(option: any) => {
                      // formik.setFieldValue('code', option === null ? null : option.value);
                      setTransType(option === null ? null : option.value);
                      // formik.setFieldValue('materialTransactionsLineList', []);
                    }}
                    placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                  />
                  <div className="text-danger small">
                    {formik.touched.code && formik.errors.code}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <button
                  type="button"
                  className="btn btn-lg btn-theme  w-100 rounded-0"
                  onClick={handleSubmitModal}>
                  <i className="fas fa-lg fa-fw me-2 fa-check-circle"></i>
                  {t('إختيار')}
                </button>
              </div>
              <div className="col-6">
                <button
                  type="button"
                  className="btn btn-lg  btn-default  w-100 rounded-0"
                  onClick={() => Navigate('/INV/Transactions/InvIssue', { replace: true })}>
                  <i className="fas fa-times-circle fa-fw me-1"></i> {t('Cancel')}
                </button>
              </div>
            </div>
          </CardBody>
        </Card>
      </NeatixModal>
    </>
  );
};

export default Add;
