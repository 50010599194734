import React, { useState } from 'react';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import { Card, CardBody } from '../../../../components/card/card';
import { useTranslation } from 'react-i18next';
import Pagination from '../../../../components/Shared/Pagination/Pagination';
import { Link, useNavigate } from 'react-router-dom';
import Loading from '../../../../components/Shared/Loader/Loading';

import useGetPurchaseBillList from '../../../../hooks/PUR/useGetPurchaseBillList';

const Index = () => {
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const { t, i18n } = useTranslation();
  const Navigate = useNavigate();

  const { PurchaseBillList, loading, error } = useGetPurchaseBillList();

  const filteredSearch = PurchaseBillList?.slice()?.filter((item) => {
    const searchLowerCase = search.toLowerCase();
    return (
      item.name?.toLowerCase().includes(searchLowerCase) ||
      item.name2?.toLowerCase().toString().includes(searchLowerCase)
    );
  });

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  //____________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'فواتير المشتريات',
      link: null,
    },
  ];

  return (
    <div>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName="فواتير المشتريات" />
        <div className="ms-auto">
          <Link to="AddPurchaseBill" className="btn me-1 btn-theme">
            <i className="fa fa-plus-circle fa-fw me-1"></i>
            فاتورة مشتريات جديدة
          </Link>
        </div>
      </div>
      <Card>
        <Loading loading={loading} error={error}>
          <CardBody>
            <div className=" p-2">
              <div className="row">
                <div className="col-7 col-md-6 d-flex justify-content-start">
                  <div className="input-group mb-4">
                    <div className="flex-fill position-relative">
                      <div className="input-group">
                        <div
                          className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                          style={{ zIndex: 1020, right: '10px' }}>
                          <i className="fa fa-search opacity-5"></i>
                        </div>
                        <input
                          type="text"
                          className="form-control ps-35px"
                          onChange={(e) => setSearch(e.target.value)}
                          placeholder={t('Search') + ' ...'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* //  START TABLE   */}
              <div className="table-responsive">
                <table className="table table-hover text-nowrap text-center">
                  <thead className="table-dark">
                    <tr>
                      <th scope="col">{t('رقم الفاتورة ')}</th>
                      <th scope="col">{t('تاريخ الفاتورة ')}</th>
                      <th scope="col">{t('المورد ')}</th>
                      <th scope="col">{t('الفرع ')}</th>
                      <th scope="col">{t('قيمة الفاتورة ')}</th>
                      <th scope="col">{t('حالة الإضافة في المخزن')}</th>

                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData?.map((item, index) => (
                      <tr key={item.id}>
                        <td className="align-middle"> {item.code} </td>
                        <td className="align-middle"> {item.transactionDate.toString()} </td>
                        <td className="align-middle"> {item.peopleName2} </td>
                        <td className="align-middle"> {item.organizationName2} </td>
                        <td className="align-middle"> {item.grossAmount}</td>

                        <td className="align-middle">
                          {item.inventoryFlag ? (
                            <span className=" badge border border-success  text-success  px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center ">
                              <i className="fa fa-check-circle fs-15px fa-fw me-5px"></i>
                              {t('تمت الإضافة')}
                            </span>
                          ) : (
                            <span className=" badge border border-danger  text-danger  px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center ">
                               <i className="fa fa-clock fs-15px fa-fw me-5px"></i>
                               {/* <i className="fa fa-exclamation-circle fs-15px fa-fw me-5px"></i> */}
                              {t('في إنتظار الإضافة')}
                            </span>
                          )}
                        </td>

                        <td className="text-end">
                          <button
                            type="button"
                            className="btn btn-outline-theme mx-1"
                            onClick={() => Navigate(`${item.id}/UpdatePurchaseBill`)}>
                            {/* <i className="far fa-lg fa-fw me-2 fa-edit"></i> */}
                            {t('عرض التفاصيل')}
                          </button>

                          {/* <DynamicPrint
                            PrintTemplateTypeId="868E12B7-A5D3-41F9-AA87-45BB552B3AAB" // قوالب عرض السعر
                            ReferenceId={item.id || ''}
                          /> */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Pagination
                filteredData={filteredSearch}
                dataPerPage={dataPerPage}
                handlePagination={(pageNumber: any) => setCurrentPage(pageNumber)}
                currentPage={currentPage}
              />
            </div>
          </CardBody>
        </Loading>
      </Card>
    </div>
  );
};

export default Index;
