import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import Select from 'react-select';

import {
  GetPermissionsByRuleId,
  GetPermissionsByUserId,
  UpdatePermissionRole,
  UpdatePermissionUser,
} from '../../../../store/NTX/Security/Permission/PermissionSlice';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import { Form } froms 'react-bootstrap';
import useLookupModule from '../../../../hooks/Lookups/use-Lookup-Module';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import { Card, CardBody, CardHeader } from '../../../../components/card/card';
import Loading from '../../../../components/Shared/Loader/Loading';
import confirmAction from '../../../../components/Shared/Alert/confirmAction';

const Permissions: React.FC<{ type: 'rule' | 'user' }> = ({ type }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { refrenceId } = useParams();
  const [moduleId, setModuleId] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(true);
  const { OptionsModule, loading: loading2 } = useLookupModule();

  //__
  const { PermissionList, loading, error } = useAppSelector((state) => state.Permission);
  const DynamicPermission = [
    ...new Map(
      PermissionList?.dynamic?.map((p) => [
        p.permissionType, // This acts as the unique key
        {
          type: p.permissionType,
          permissionTypeName: i18n.language === 'en' ? p.permissionTypeName : p.permissionTypeName2,
          withModuleId: p.withModuleId,
        },
      ])
    )?.values(),
  ];
  //__

  const [checkedPermissions, setCheckedPermissions] = useState<{
    [key: string]: boolean;
  }>({});
  // const [checkedPermissionsReport, setCheckedPermissionsReport] = useState<{
  //   [key: string]: boolean;
  // }>({});
  // const [checkedPermissionsAme, setCheckedPermissionsAme] = useState<{
  //   [key: string]: boolean;
  // }>({});
  const [checkedPermissionsDynamic, setCheckedPermissionsDynamic] = useState<{
    [key: string]: boolean;
  }>({});

  //______________________________
  useEffect(() => {
    if (type === 'user' && refrenceId) {
      dispatch(GetPermissionsByUserId(refrenceId));
    }

    if (type === 'rule' && refrenceId) {
      dispatch(GetPermissionsByRuleId(refrenceId));
    }
  }, [dispatch, type, refrenceId]);

  //______________________________

  useEffect(() => {
    if (PermissionList && PermissionList.menuWithPermissions) {
      const newCheckedPermissions: { [key: string]: boolean } = {};
      PermissionList.menuWithPermissions.forEach((menu) => {
        menu.permissions.forEach((permission) => {
          newCheckedPermissions[permission.id] = permission?.hasPermission;
        });
      });
      setCheckedPermissions(newCheckedPermissions);
    }

    // if (PermissionList && PermissionList.menuReport) {
    //   const newCheckedPermissionsReport: { [key: string]: boolean } = {};
    //   PermissionList.menuReport.forEach((rpt) => {
    //     newCheckedPermissionsReport[rpt.id] = rpt?.hasPermission;
    //   });
    //   setCheckedPermissionsReport(newCheckedPermissionsReport);
    // }

    // if (PermissionList && PermissionList.menuAme) {
    //   const newCheckedPermissionsAme: { [key: string]: boolean } = {};
    //   PermissionList.menuAme.forEach((ame) => {
    //     newCheckedPermissionsAme[ame.id] = ame?.hasPermission;
    //   });
    //   setCheckedPermissionsAme(newCheckedPermissionsAme);
    // }
    if (PermissionList && PermissionList.dynamic) {
      const newCheckedPermissionsDynamic: { [key: string]: boolean } = {};
      PermissionList.dynamic.forEach((m) => {
        newCheckedPermissionsDynamic[m.id] = m?.hasPermission;
      });
      setCheckedPermissionsDynamic(newCheckedPermissionsDynamic);
    }
  }, [PermissionList]);

  const handleCheckboxChange = (Id: string, type: 'General' | 'Reports' | 'Ame' | 'dynamic') => {
    if (type === 'General') {
      // Id : PermissionId
      setCheckedPermissions((prevCheckedPermissions) => ({
        ...prevCheckedPermissions,
        [Id]: !prevCheckedPermissions[Id],
      }));
    }
    // if (type === 'Reports') {
    //   // Id : ReportId
    //   setCheckedPermissionsReport((prevCheckedPermissionsReport) => ({
    //     ...prevCheckedPermissionsReport,
    //     [Id]: !prevCheckedPermissionsReport[Id],
    //   }));
    // }
    // if (type === 'Ame') {
    //   // Id : DynamicFormId
    //   setCheckedPermissionsAme((prevCheckedPermissionsAme) => ({
    //     ...prevCheckedPermissionsAme,
    //     [Id]: !prevCheckedPermissionsAme[Id],
    //   }));
    // }
    if (type === 'dynamic') {
      setCheckedPermissionsDynamic((prevCheckedPermissionsDynamic) => ({
        ...prevCheckedPermissionsDynamic,
        [Id]: !prevCheckedPermissionsDynamic[Id],
      }));
    }
  };

  const handleUpdatePermission = async () => {
    let isConfirmation = await confirmAction('هل أنت متأكد من حفظ التغييرات ؟ ');
    if (!isConfirmation) {
      return;
    }
    const RequestBody = {
      refrenceId: refrenceId,
      permissions: {
        withIn: Object.entries(checkedPermissions)
          ?.filter(([, checked]) => checked)
          ?.map(([Id]) => Id),
        withOute: Object.entries(checkedPermissions)
          ?.filter(([, checked]) => !checked)
          ?.map(([Id]) => Id),
      },
      // reports: {
      //   withIn: Object.entries(checkedPermissionsReport)
      //     ?.filter(([, checked]) => checked)
      //     ?.map(([Id]) => Id),
      //   withOute: Object.entries(checkedPermissionsReport)
      //     ?.filter(([, checked]) => !checked)
      //     ?.map(([Id]) => Id),
      // },
      // ame: {
      //   withIn: Object.entries(checkedPermissionsAme)
      //     ?.filter(([, checked]) => checked)
      //     ?.map(([Id]) => Id),
      //   withOute: Object.entries(checkedPermissionsAme)
      //     ?.filter(([, checked]) => !checked)
      //     ?.map(([Id]) => Id),
      // },
      dynamic: {
        withIn: Object.entries(checkedPermissionsDynamic)
          ?.filter(([, checked]) => checked)
          ?.map(([Id]) => ({
            id: Id,
            permissionType: PermissionList?.dynamic?.find((f) => f.id === Id)?.permissionType,
          })),
        withOute: Object.entries(checkedPermissionsDynamic)
          ?.filter(([, checked]) => !checked)
          ?.map(([Id]) => ({
            id: Id,
            permissionType: PermissionList?.dynamic?.find((f) => f.id === Id)?.permissionType,
          })),
      },
    };

    try {
      const updateAction = type === 'user' ? UpdatePermissionUser : UpdatePermissionRole;
      const getPermissionsAction =
        type === 'user' ? GetPermissionsByUserId : GetPermissionsByRuleId;

      const res = await dispatch(updateAction(RequestBody)).unwrap();
      if (res?.succeeded) {
        dispatch(getPermissionsAction(refrenceId || ''));
        CustomAlert({ action: 'Add', msg: 'تم حفظ التغييرات' });
      } else {
        CustomAlert({ action: 'Error' });
      }
    } catch (err: any) {
      console.error('Error updating permissions:', err);
      CustomAlert({ action: 'Error' });
    } finally {
      setIsSubmitting(true);
    }
  };

  //________________________________________________________________
  const handleSelectAll = (
    type: 'General' | 'Reports' | 'Ame' | 'dynamic',
    menuId?: string,
    action?: boolean,
    permissionType?: string
  ) => {
    if (type === 'General') {
      const updatedPermissions = { ...checkedPermissions };
      PermissionList?.menuWithPermissions
        ?.filter((menu) => menu.menuId === menuId)
        ?.forEach((menu) => {
          menu.permissions.forEach((permission) => {
            updatedPermissions[permission.id] = action ?? true;
          });
        });
      setCheckedPermissions(updatedPermissions);
    }
 
    if (type === 'dynamic') {
      const updatedPermissionsDynamic = { ...checkedPermissionsDynamic };
      PermissionList?.dynamic
        ?.filter((f) => f.permissionType === permissionType)
        ?.forEach((dynamicPermission) => {
          updatedPermissionsDynamic[dynamicPermission.id] = action ?? true;
        });
      setCheckedPermissionsDynamic(updatedPermissionsDynamic);
    }
  };

  const getIconForType = (type: string) => {
    switch (type) {
      case 'Dashboard':
        return <i className="fa fa-tachometer-alt fa-fw me-2 text-theme"></i>; // Example icon for Dashboard
      case 'Report':
        return <i className="fa fa-file-alt fa-fw me-2 text-theme"></i>; // Example icon for Report
      case 'Ame':
        return <i className="fa fa-tasks fa-fw me-2 text-theme"></i>; // Example icon for Ame
      case 'Inventory':
        return <i className="fa fa-warehouse fa-fw me-2 text-theme"></i>; // Example icon for Inventory
      case 'Branch':
        return <i className="fa fa-code-branch fa-fw me-2 text-theme"></i>; // Example icon for Branch
      default:
        return <i className="fa fa-folder fa-fw me-2 text-theme"></i>; // Default icon if type not found
    }
  };

   const [activeTab, setActiveTab] = useState('');
  return (
    <>
      <Loading loading={loading} error={error}>
        <Card className="   ">
          <CardHeader className="m-0">
            <div className="row  pt-2">
              <div className="col-xl-6   ">
                <div className="input-group flex-nowrap mb-3">
                  <span className="input-group-text">{t('Application')}</span>
                  <Select
                    classNamePrefix="react-select"
                    className="w-100"
                    isLoading={loading2}
                    isSearchable={true}
                    isClearable
                    options={OptionsModule}
                    onChange={(option: any) => {
                      setModuleId(option === null ? null : option.value);
                    }}
                    placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                    }}
                  />
                </div>
              </div>

              <div className="col-xl-6   text-end">
                <button
                  disabled={!isSubmitting}
                  type="button"
                  className="btn  me-1 btn-theme  "
                  // style={{ width: '230px', height: '80px' }}

                  onClick={() => handleUpdatePermission()}>
                  {!isSubmitting ? (
                    <div className="spinner-border spinner-border-sm me-2"></div>
                  ) : (
                    <i className="fa fa-save fa-fw me-2"></i>
                  )}
                  {t('Save')}
                </button>
              </div>
            </div>
          </CardHeader>
          <CardBody className="p-0">
            <ul className="nav nav-tabs nav-tabs-v2 pt-1 px-3   ">
              {/* <li className="nav-item me-3">
                <Link to="#menuWithPermissions" className="nav-link active" data-bs-toggle="tab">
                  <i className="fa fa-sliders-h fa-fw me-2 text-theme"></i> النظام
                </Link>
              </li> */}
              <li className="nav-item me-1 dropdown">
                <Link to="#/" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">
                  <i className="fa fa-sliders-h fa-fw me-2 text-theme"></i>
                  النظام{'  '}
                </Link>
                <div className="dropdown-menu">
                  {PermissionList &&
                    PermissionList?.menuWithPermissions
                      ?.filter((f) => f.moduleId === moduleId)
                      .sort((a, b) => a.sequence - b.sequence)
                      ?.map((item, idx) => (
                        <Link
                          to={`#${item.menuId}`}
                          key={item.menuId}
                          className="dropdown-item"
                          onClick={() => setActiveTab(item.menuId)}
                          data-bs-toggle="tab">
                          {i18n.language === 'ar' ? item.name2 : item.name}
                        </Link>
                      ))}
                </div>
              </li>
              {DynamicPermission?.map((item, index) => (
                <li className="nav-item me-3" key={item.type}>
                  <Link to={`#${item.type}`} className="nav-link " data-bs-toggle="tab">
                    {getIconForType(item.type)}
                    {item.permissionTypeName}
                  </Link>
                </li>
              ))}
            </ul>

            <div className="tab-content p-4">
              {/* <div className="tab-pane fade show active" id="menuWithPermissions">
                <div className="accordion">
                  {PermissionList &&
                    PermissionList?.menuWithPermissions
                      ?.filter((f) => f.moduleId === moduleId)
                      .sort((a, b) => a.sequence - b.sequence)
                      ?.map((item, idx) => (
                        <div className="accordion-item" key={idx}>
                          <h2 className="accordion-header">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#collapse${idx}`}>
                              <p className="my-0 mx-2">
                                {i18n.language === 'ar' ? item.name2 : item.name}
                              </p>
                            </button>
                          </h2>
                          <div
                            id={`collapse${idx}`}
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionExample">
                            <div className="accordion-body p-3">
                              <div className="d-flex justify-content-end mb-2">
                                <button
                                  type="button"
                                  className="btn btn-sm btn-outline-theme mx-1 "
                                  onClick={() => handleSelectAll('General', item.menuId, true)}>
                                  <i className="fas fa-check-square me-2"></i>
                                  {t('SelectAll')}
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-sm btn-outline-theme mx-1"
                                  onClick={() => handleSelectAll('General', item.menuId, false)}>
                                  <i className="fas fa-square me-2"></i>
                                  {t('UnSelectAll')}
                                </button>
                              </div>
                              <div className="row">
                                {item.permissions
                                  .slice()
                                  ?.sort((a, b) => a.sequence - b.sequence)
                                  ?.map((p, pidx) => (
                                    <div className="col-md-4 mb-2" key={pidx}>
                                      <div className="bg-inverse bg-opacity-10 p-2 rounded-3 border-bottom border-theme shadow">
                                        <div className="form-check">
                                          <label className="form-check-label" htmlFor={`${p.id}`}>
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id={`${p.id}`}
                                              checked={checkedPermissions[p.id] || false}
                                              onChange={() => handleCheckboxChange(p.id, 'General')}
                                            />
                                            {i18n.language === 'ar' ? p.name2 : p.name}
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                </div>
              </div> */}

              {PermissionList?.menuWithPermissions?.map((item, index) => (
                <div
                  className={`tab-pane fade ${activeTab === item.menuId ? 'show active' : ''}`}
                  id={item.menuId}
                  key={item.menuId}>
                  <div className="d-flex justify-content-end mb-2">
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-theme mx-1 "
                      onClick={() => handleSelectAll('General', item.menuId, true)}>
                      <i className="fas fa-check-square me-2"></i>
                      {t('SelectAll')}
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-theme mx-1"
                      onClick={() => handleSelectAll('General', item.menuId, false)}>
                      <i className="fas fa-square me-2"></i>
                      {t('UnSelectAll')}
                    </button>
                  </div>
                  <div className="row">
                    {item.permissions
                      ?.slice()
                      ?.filter((f) => f.menuId === item.menuId)
                      ?.sort((a, b) => a.sequence - b.sequence)
                      ?.map((m, idx) => (
                        <div className="col-md-4 mb-2" key={m.id}>
                          <div className="bg-inverse bg-opacity-10 p-2 rounded-3 border-bottom border-theme shadow">
                            <div className="form-check">
                              <label className="form-check-label" htmlFor={`${m.id}`}>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id={`${m.id}`}
                                  checked={checkedPermissions[m.id] || false}
                                  onChange={() => handleCheckboxChange(m.id, 'General')}
                                />
                                {i18n.language === 'ar' ? m.name2 : m.name}
                              </label>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              ))}

              {DynamicPermission?.map((item, index) => (
                <div className="tab-pane fade" id={item.type} key={index}>
                  <div className="d-flex justify-content-end mb-2">
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-theme mx-1 "
                      onClick={() => handleSelectAll('dynamic', '', true, item.type)}>
                      <i className="fas fa-check-square me-2"></i>
                      {t('SelectAll')}
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-theme mx-1"
                      onClick={() => handleSelectAll('dynamic', '', false, item.type)}>
                      <i className="fas fa-square me-2"></i>
                      {t('UnSelectAll')}
                    </button>
                  </div>
                  <div className="row">
                    {PermissionList?.dynamic
                      ?.filter((f) => f.permissionType === item.type)
                      ?.filter((f) => !f.moduleId || f.moduleId === moduleId)
                      ?.map((m, idx) => (
                        <div className="col-md-4 mb-2" key={idx}>
                          <div className="bg-inverse bg-opacity-10 p-2 rounded-3 border-bottom border-theme shadow">
                            <div className="form-check">
                              <label className="form-check-label" htmlFor={`${m.id}`}>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id={`${m.id}`}
                                  checked={checkedPermissionsDynamic[m.id] || false}
                                  onChange={() => handleCheckboxChange(m.id, 'dynamic')}
                                />
                                {i18n.language === 'ar' ? m.name2 : m.name}
                              </label>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              ))}
            </div>
          </CardBody>
        </Card>
      </Loading>
    </>
  );
};

export default Permissions;

// const handleSelectAll = (type: 'General' | 'Reports' | 'Ame', menuId?: string) => {
//   if (type === 'General') {
//     const updatedPermissions = { ...checkedPermissions };
//     const allSelected = PermissionList?.menuWithPermissions
//       ?.filter((menu) => menu.menuId === menuId)
//       ?.every((menu) =>
//         menu.permissions.every((permission) => updatedPermissions[permission.id])
//       );

//     PermissionList?.menuWithPermissions
//       ?.filter((menu) => menu.menuId === menuId)
//       ?.forEach((menu) => {
//         menu.permissions.forEach((permission) => {
//           updatedPermissions[permission.id] = !allSelected;
//         });
//       });

//     setCheckedPermissions(updatedPermissions);
//   }

//   if (type === 'Reports') {
//     const updatedPermissionsReport = { ...checkedPermissionsReport };
//     const allSelected = PermissionList?.menuReport
//       ?.filter((menu) => menu.moduleId === moduleId)
//       ?.every((report) => updatedPermissionsReport[report.id]);

//     PermissionList?.menuReport
//       ?.filter((menu) => menu.moduleId === moduleId)
//       ?.forEach((report) => {
//         updatedPermissionsReport[report.id] = !allSelected;
//       });

//     setCheckedPermissionsReport(updatedPermissionsReport);
//   }

//   if (type === 'Ame') {
//     const updatedPermissionsAme = { ...checkedPermissionsAme };
//     const allSelected = PermissionList?.menuAme
//       ?.filter((menu) => menu.moduleId === moduleId)
//       ?.every((ame) => updatedPermissionsAme[ame.id]);

//     PermissionList?.menuAme
//       ?.filter((menu) => menu.moduleId === moduleId)
//       ?.forEach((ame) => {
//         updatedPermissionsAme[ame.id] = !allSelected;
//       });

//     setCheckedPermissionsAme(updatedPermissionsAme);
//   }
// }

// <div className="row justify-content-center p-0">
// <Card className="mb-2">
//   <div className="row bg-theme bg-opacity-10 p-3">
//     <div className="col-xl-6  ">
//       <div className="input-group flex-nowrap mb-3">
//         <span className="input-group-text">{t('Application')}</span>
//         <Select
//           classNamePrefix="react-select"
//           className="w-100"
//           isLoading={loading2}
//           isSearchable={true}
//           isClearable
//           options={OptionsModule}
//           onChange={(option: any) => {
//             setModuleId(option === null ? null : option.value);
//           }}
//           placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
//         />
//       </div>
//     </div>

//     <div className="col-xl-6 p-auto  text-end">
//       <button
//         disabled={!isSubmitting}
//         type="button"
//         className="btn btn-lg me-1 btn-theme rounded-0 h-100"
//         // style={{ width: '230px', height: '80px' }}

//         onClick={() => handleUpdatePermission()}>
//         {!isSubmitting ? (
//           <div className="spinner-border spinner-border-sm me-2"></div>
//         ) : (
//           <i className="fa fa-save fa-fw me-2"></i>
//         )}
//         {t('Save')}
//       </button>
//     </div>
//   </div>
// </Card>

// <CardBody>
//   <div className="accordion ">
//     {PermissionList &&
//       PermissionList?.menuWithPermissions
//         ?.filter((f) => f.moduleId === moduleId)
//         .sort((a, b) => a.sequence - b.sequence)
//         ?.map((item, idx) => (
//           <div className="accordion-item" key={item.menuId}>
//             <h2 className="accordion-header">
//               <button
//                 className="accordion-button collapsed"
//                 type="button"
//                 data-bs-toggle="collapse"
//                 data-bs-target={`#collapse${idx}`}>
//                 <p className="my-0 mx-2">
//                   {i18n.language === 'ar' ? item.name2 : item.name}
//                 </p>
//               </button>
//             </h2>
//             <div
//               id={`collapse${idx}`}
//               className="accordion-collapse collapse"
//               data-bs-parent="#accordionExample">
//               <div className="accordion-body p-3">
//                 <div className="d-flex justify-content-end mb-2">
//                   <button
//                     type="button"
//                     className="btn btn-sm btn-outline-theme mx-1 "
//                     onClick={() => handleSelectAll('General', item.menuId, true)}>
//                     <i className="fas fa-check-square me-2"></i>
//                     {t('SelectAll')}
//                   </button>
//                   <button
//                     type="button"
//                     className="btn btn-sm btn-outline-theme mx-1"
//                     onClick={() => handleSelectAll('General', item.menuId, false)}>
//                     <i className="fas fa-square me-2"></i>
//                     {t('UnSelectAll')}
//                   </button>
//                 </div>
//                 <div className="row">
//                   {item.permissions
//                     .slice()
//                     ?.sort((a, b) => a.sequence - b.sequence)
//                     ?.map((p, pidx) => (
//                       <div className="col-md-4 mb-2" key={pidx}>
//                         <div className="bg-inverse bg-opacity-10 p-2 rounded-3 border-bottom border-theme shadow">
//                           <div className="form-check">
//                             <label className="form-check-label" htmlFor={`${p.id}`}>
//                               <input
//                                 className="form-check-input"
//                                 type="checkbox"
//                                 id={`${p.id}`}
//                                 checked={checkedPermissions[p.id] || false}
//                                 onChange={() => handleCheckboxChange(p.id, 'General')}
//                               />
//                               {i18n.language === 'ar' ? p.name2 : p.name}
//                             </label>
//                           </div>
//                         </div>
//                       </div>
//                     ))}
//                 </div>
//               </div>
//             </div>
//           </div>
//         ))}

//     {moduleId && (
//       <>
//         <div className="accordion-item">
//           <h2 className="accordion-header">
//             <button
//               className="accordion-button collapsed"
//               type="button"
//               data-bs-toggle="collapse"
//               data-bs-target="#collapseOne">
//               <p className="my-0 mx-2">{t('Reports')}</p>
//             </button>
//           </h2>
//           <div
//             id="collapseOne"
//             className="accordion-collapse collapse "
//             data-bs-parent="#accordionExample">
//             <div className="accordion-body">
//               <div className="d-flex justify-content-end mb-2">
//                 <button
//                   type="button"
//                   className="btn btn-sm btn-outline-theme mx-1 "
//                   onClick={() => handleSelectAll('Reports', '', true)}>
//                   <i className="fas fa-check-square me-2"></i>
//                   {t('SelectAll')}
//                 </button>
//                 <button
//                   type="button"
//                   className="btn btn-sm btn-outline-theme mx-1"
//                   onClick={() => handleSelectAll('Reports', '', false)}>
//                   <i className="fas fa-square me-2"></i>
//                   {t('UnSelectAll')}
//                 </button>
//               </div>
//               <div className="row">
//                 {PermissionList?.menuReport
//                   ?.filter((f) => f.moduleId === moduleId)
//                   ?.map((r, idx) => (
//                     <div className="col-md-4 mb-2" key={idx}>
//                       <div className="bg-inverse bg-opacity-10 p-2 rounded-3 border-bottom border-theme shadow">
//                         <div className="form-check">
//                           <label className="form-check-label" htmlFor={`${r.id}`}>
//                             <input
//                               className="form-check-input"
//                               type="checkbox"
//                               id={`${r.id}`}
//                               onChange={() => handleCheckboxChange(r.id, 'Reports')}
//                               checked={checkedPermissionsReport[r.id] || false}
//                             />
//                             {i18n.language === 'ar' ? r.name2 : r.name}
//                           </label>
//                         </div>
//                       </div>
//                     </div>
//                   ))}
//               </div>
//             </div>
//           </div>
//         </div>

//         <div className="accordion-item">
//           <h2 className="accordion-header">
//             <button
//               className="accordion-button collapsed"
//               type="button"
//               data-bs-toggle="collapse"
//               data-bs-target="#collapseTwo">
//               <p className="my-0 mx-2">{t('Requests')}</p>
//             </button>
//           </h2>
//           <div
//             id="collapseTwo"
//             className="accordion-collapse collapse"
//             data-bs-parent="#accordionExample">
//             <div className="accordion-body">
//               <div className="d-flex justify-content-end mb-2">
//                 <button
//                   type="button"
//                   className="btn btn-sm btn-outline-theme mx-1 "
//                   onClick={() => handleSelectAll('Ame', '', true)}>
//                   <i className="fas fa-check-square me-2"></i>
//                   {t('SelectAll')}
//                 </button>
//                 <button
//                   type="button"
//                   className="btn btn-sm btn-outline-theme mx-1"
//                   onClick={() => handleSelectAll('Ame', '', false)}>
//                   <i className="fas fa-square me-2"></i>
//                   {t('UnSelectAll')}
//                 </button>
//               </div>
//               <div className="row">
//                 {PermissionList?.menuAme
//                   ?.filter((f) => f.moduleId === moduleId)
//                   ?.map((a, idx) => (
//                     <div className="col-md-4 mb-2" key={idx}>
//                       <div className="bg-inverse bg-opacity-10 p-2 rounded-3 border-bottom border-theme shadow">
//                         <div className="form-check">
//                           <label className="form-check-label" htmlFor={`${a.id}`}>
//                             <input
//                               className="form-check-input"
//                               type="checkbox"
//                               id={`${a.id}`}
//                               onChange={() => handleCheckboxChange(a.id, 'Ame')}
//                               checked={checkedPermissionsAme[a.id] || false}
//                             />
//                             {i18n.language === 'ar' ? a.name2 : a.name}
//                           </label>
//                         </div>
//                       </div>
//                     </div>
//                   ))}
//               </div>
//             </div>
//           </div>
//         </div>
//       </>
//     )}
//   </div>
// </CardBody>
// </div>
