import React from 'react';
import { TaxManagerModels } from '../../../../store/Finance/TaxManagers/TaxManagerModels';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../store/hooks';
import useGetGlLedgersList from '../../../../hooks/Finance/useGetGlLedgersList';
import useGetAccounts from '../../../../hooks/Finance/ChartAccountsDetails/useGetAccounts';
import useLookupTaxType from '../../../../hooks/Lookups/use-Lookup-TaxType';
import { useFormik } from 'formik';
import { TaxManagersSchema } from '../../../ValidationForm/validationSchema';
import { GetTaxManagersList, UpdatTaxManagers } from '../../../../store/Finance/TaxManagers/TaxManagerSlice';
import CustomAlert from '../../../../components/Shared/Alert/CustomAlert';
import { Form } from 'react-bootstrap';
import Select from 'react-select';


interface Props {
  data: TaxManagerModels | undefined;
}

const Update = ({ data }: Props) => {
  const { i18n, t } = useTranslation();
  const dispatch = useAppDispatch();
  const { LedgersList } = useGetGlLedgersList();

  const { OptionsAccounts } = useGetAccounts();

  const { TAX_TYPE } = useLookupTaxType();

  const formik = useFormik({
    initialValues: {
      taxType: data?.taxType ?? '',
      accountId: data?.accountId ?? '',
      ledgerId: data?.ledgerId ?? '',
      taxRate: data?.taxRate ?? 0,
      enabledFlag: data?.enabledFlag ?? true,
      startDateActive: data?.startDateActive?.split('T')[0]  ?? '',
    },
    enableReinitialize: true,
    validationSchema: TaxManagersSchema(t),
    onSubmit: (values) => {
      dispatch(
        UpdatTaxManagers({
          id: data?.id,
          codeCombinationId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
          endDateActive: '2024-09-29T05:48:40.459Z',
          name: 'string',
          name2: 'string',

          taxType: values.taxType,
          ledgerId: values.ledgerId,
          taxRate: values.taxRate,
          enabledFlag: values.enabledFlag,
          startDateActive: values.startDateActive,
          // endDateActive: values.endDateActive,
          accountId: values.accountId,
        })
      )
        .unwrap()
        .then((res: any) => {
          if (res?.succeeded === true) {
            CustomAlert({ action: 'Edit' });
            document.getElementById('closeModalForm')?.click();
            formik.resetForm();
            dispatch(GetTaxManagersList());
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error', msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });

  return (
    <div>
      <Form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-xl-4">
            <div className="form-group mb-3">
              <label className="form-label">نوع الضريبة</label>
              <Form.Select
                className="form-select text-center"
                name="taxType"
                value={formik.values.taxType || ''}
                onChange={formik.handleChange}
                isInvalid={!!(formik.touched.taxType && formik.errors.taxType)}>
                <option key={null}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                {TAX_TYPE &&
                  TAX_TYPE?.map((item: any, idx: any) =>
                    item.list?.map((list: any) => (
                      <option key={++idx} value={list.lookupCode}>
                        {i18n.language === 'ar' ? list.meaning2 : list.meaning}
                      </option>
                    ))
                  )}
              </Form.Select>
              <div className="text-danger small">
                {formik.touched.taxType && formik.errors.taxType}
              </div>
            </div>
          </div>
          <div className="col-xl-4">
            <div className="form-group mb-3">
              <label className="form-label">{t('نسبة الضريبة')}</label>
              <div className="input-group flex-nowrap">
                <Form.Control
                  className="form-control text-center"
                  type="text"
                  name="taxRate"
                  onChange={formik.handleChange}
                  value={formik.values.taxRate || 0}
                  isInvalid={!!(formik.touched.taxRate && formik.errors.taxRate)}
                />
                <span className="input-group-text text-theme fw-bold">%</span>
              </div>
              <div className="text-danger small">
                {formik.touched.taxRate && formik.errors.taxRate}
              </div>
            </div>
          </div>

          <div className="col-xl-4">
            <div className="form-group">
              <label className="form-label">{t('تاريخ بدء التفعيل')}</label>
              <Form.Control
                className="form-control text-center"
                type="date"
                name="startDateActive"
                onChange={formik.handleChange}
                value={formik.values.startDateActive || ''}
                isInvalid={!!(formik.touched.startDateActive && formik.errors.startDateActive)}
              />
              <div className="text-danger small">
                {formik.touched.startDateActive && formik.errors.startDateActive}
              </div>
            </div>
          </div>

          <div className="col-xl-5">
            <div className="form-group mb-3">
              <label className="form-label">{t('الحساب')}</label>
              <Select
                classNamePrefix="react-select"
                // className="text-center"
                // isLoading={loadingAccounts}
                isSearchable={true}
                isClearable
                options={OptionsAccounts}
                value={OptionsAccounts?.find((f) => f.value === formik.values.accountId)}
                onChange={(option) => formik.setFieldValue('accountId', option?.value)}
                placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
              />
              <div className="text-danger small">
                {formik.touched.accountId && formik.errors.accountId}
              </div>
            </div>
          </div>
          <div className="col-xl-5">
            <div className="form-group mb-3">
              <label className="form-label">الأستاذ العام</label>
              <Form.Select
                className="form-select text-center"
                name="ledgerId"
                value={formik.values.ledgerId || ''}
                onChange={formik.handleChange}
                isInvalid={!!(formik.touched.ledgerId && formik.errors.ledgerId)}>
                <option key={null}>{i18n.language === 'ar' ? 'إختر' : 'Choose'}</option>
                {LedgersList &&
                  LedgersList?.map((item) => (
                    <option key={item.id} value={item.id}>
                      {i18n.language === 'ar' ? item.name2 : item.name}
                    </option>
                  ))}
              </Form.Select>
              <div className="text-danger small">
                {formik.touched.ledgerId && formik.errors.ledgerId}
              </div>
            </div>
          </div>

          <div className="col-xl-2">
            <div className="form-group mb-2">
              <Form.Check
                style={{ margin: '10px 20px' }}
                className="pt-3"
                type="checkbox"
                label="تفعيــل"
                name="enabledFlag"
                checked={formik.values.enabledFlag || false}
                onChange={formik.handleChange}
              />
            </div>
          </div>
        </div>

        <div className="text-center mt-3">
          <button disabled={formik.isSubmitting} type="submit" className="btn me-1 btn-theme mb-1">
          {formik.isSubmitting ? (
            <div className="spinner-border spinner-border-sm me-2"></div>
          ) : (
            <i className="fas fa-lg fa-fw me-2 fa fa-save"></i>
          )}
          {t('Save')}
          </button>
          <button
            type="button"
            className=" btn me-1 btn-default mb-1"
            data-bs-dismiss="modal"
            id="closeModalForm">
            <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
          </button>
        </div>
      </Form>
    </div>
  );
};

export default Update;
