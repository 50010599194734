import { useFormik } from 'formik';
import { AddSubinventory } from '../../../../../store/INV/SubInventory/SubInventorySlice';
import { useAppDispatch } from '../../../../../store/hooks';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CustomAlert from '../../../../../components/Shared/Alert/CustomAlert';
import { SubInventorySchema } from '../../../../ValidationForm/validationSchema';
import { useParams } from 'react-router-dom';

interface Props {
  subinventoryParentId: string;
}

const AddSubInventorybySubInventoryParentId = ({ subinventoryParentId }: Props) => {
  // const Navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { Id } = useParams();

  const formik = useFormik({
    initialValues: {
      name: '',
      name2: '',
      code: '',
      parentId: '',
      description: '',
    },
    validationSchema: SubInventorySchema(t),
    onSubmit: (values, { resetForm }) => {
      dispatch(
        AddSubinventory({
          name: values.name,
          name2: values.name2,
          code: values.code,
          parentId: Id,
          description: values.description,
          subinventoryParentId: subinventoryParentId,
        })
      )
        .unwrap()
        .then((res) => {
          if (res.succeeded) {
            CustomAlert({ action: 'Add' });
            formik.resetForm();
            formik.setSubmitting(false);
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error' });
          }
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });

  return (
    <div>
      <Form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-xl-6">
            <div className="form-group mb-3">
              <label className="form-label">{t('LocalName')}</label>
              <Form.Control
                className="form-control"
                type="text"
                name="name2"
                onChange={formik.handleChange}
                value={formik.values.name2 || ''}
                isInvalid={!!(formik.touched.name2 && formik.errors.name2)}
              />
              <Form.Control.Feedback type="invalid">{formik.errors.name2}</Form.Control.Feedback>
            </div>
          </div>

          <div className="col-xl-6">
            <div className="form-group mb-3">
              <label className="form-label">{t('GlobalName')}</label>
              <Form.Control
                className="form-control"
                type="text"
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name || ''}
                isInvalid={!!(formik.touched.name && formik.errors.name)}
              />
              <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="form-group mb-3">
              <label className="form-label">{t('Code')}</label>
              <Form.Control
                className="form-control"
                type="text"
                name="code"
                onChange={formik.handleChange}
                value={formik.values.code || ''}
                isInvalid={!!(formik.touched.code && formik.errors.code)}
              />
              <Form.Control.Feedback type="invalid">{formik.errors.code}</Form.Control.Feedback>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="form-group mb-3">
              <label className="form-label">{t('Note')}</label>
              <Form.Control
                className="form-control"
                as="textarea"
                rows={1}
                name="description"
                onChange={formik.handleChange}
                value={formik.values.description || ''}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.description}
              </Form.Control.Feedback>
            </div>
          </div>
        </div>

        <div className="text-center">
          <button
            disabled={formik.isSubmitting}
            type="submit"
            className="btn me-1 btn-theme "
            id="SaveSubmit">
            {formik.isSubmitting ? (
              <div className="spinner-border spinner-border-sm me-2"></div>
            ) : (
              <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
            )}
            {t('Add')}
          </button>
          <button
            type="button"
            className=" btn me-1 btn-default "
            data-bs-dismiss="modal"
            id="close">
            <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
          </button>
        </div>
      </Form>
    </div>
  );
};

export default AddSubInventorybySubInventoryParentId;
