import React, { useState } from 'react';
import { memo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card, CardBody } from '../../../../components/card/card';
import useGetLocations from '../../../../hooks/HRSetting/use-get-locations';
import ModalComponent from '../../../../components/Modal/ModalComponent';
import AddLocation from './AddLocation';
import Pagination from '../../../../components/Shared/Pagination/Pagination';
import { usePermissions } from '../../../../Helper/Permisstions/usePermissions.ts';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import Loading from '../../../../components/Shared/Loader/Loading';

const Location = () => {
  const { HasPermission } = usePermissions();

  const [Show, setShow] = useState(false);
  const { t, i18n } = useTranslation();
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(6);

  const Navigate = useNavigate();

  const { Locations, loading, error } = useGetLocations();

  const filteredSearch = Locations?.slice()?.filter((item) => {
    const searchLowerCase = search.toLowerCase();
    return (
      item.name?.toLowerCase().includes(searchLowerCase) ||
      item.name2?.toLowerCase().includes(searchLowerCase)
    );
  });

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: t('Locations'),
      link: null,
    },
  ];
  return (
    <>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName={t('Locations')} />
        {HasPermission('AddHrLocation') && (
          <div className="ms-auto">
            <Link
              to=""
              className="btn btn-outline-theme"
              data-bs-toggle="modal"
              data-bs-target="#modalAddLocation"
              onClick={() => setShow(true)}>
              <i className="fa fa-plus-circle fa-fw me-1"></i>
              {t('AddLocation')}
            </Link>
          </div>
        )}
      </div>
      <Card>
        <CardBody>
          <Loading loading={loading} error={error}>
            <div className="tab-content p-2">
              <div className="row mx-1">
                <div className="col-12   d-flex justify-content-start">
                  <div className="input-group mb-2">
                    <div className="flex-fill position-relative">
                      <div className="input-group">
                        <div
                          className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                          style={{ zIndex: 1020, right: '10px' }}>
                          <i className="fa fa-search opacity-5"></i>
                        </div>
                        <input
                          type="text"
                          className="form-control ps-35px"
                          onChange={(e) => setSearch(e.target.value)}
                          placeholder={t('Search') + ' ...'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mb-5">
                <div className="row">
                  {Locations &&
                    currentData?.map((item) => (
                      <div className="col-md-4" key={item.id}>
                        <Card className="m-2">
                          <div className="m-1 bg-white bg-opacity-15">
                            <iframe
                              title="Youtube"
                              src={`http://www.google.com/maps/embed/v1/place?q=${item.latitude},${item.longitude}&key=AIzaSyCG3Kt_jO8o7x67EsdV9RuTJP5IxSEwmeo`}
                              style={{
                                border: 0,
                                width: '100%',
                                height: '10rem',
                              }}
                              allowFullScreen></iframe>
                            <div className="list-group list-group-flush">
                              <div className="list-group-item d-flex">
                                <div className="w-30px h-40px d-flex align-items-center justify-content-center">
                                  <i className="fas fa-lg fa-fw me-2 fa-location-arrow text-theme fs-1"></i>
                                </div>
                                <div className="flex-fill px-3">
                                  <div className="fw-bold">
                                    {i18n.language === 'ar' ? item.name2 : item.name}
                                  </div>
                                </div>
                                {HasPermission('ViewDetailsHrLocation') && (
                                  <button
                                    type="button"
                                    className="btn btn-outline-theme me-1"
                                    data-toggle="tooltip"
                                    title="View Details"
                                    onClick={() => Navigate(`${item.id}/Edit`)}>
                                    <i className="fas fa-edit fa-lg"></i>
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </Card>
                      </div>
                    ))}

                  <Pagination
                    filteredData={filteredSearch}
                    dataPerPage={dataPerPage}
                    handlePagination={(pageNumber) => setCurrentPage(pageNumber)}
                    currentPage={currentPage}
                  />
                </div>
              </div>
            </div>{' '}
          </Loading>
        </CardBody>
      </Card>

      <ModalComponent title={t('AddLocation')} id="modalAddLocation">
        {Show && <AddLocation />}
      </ModalComponent>
    </>
  );
};

export default memo(Location);
