/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { ntxAPI } from "../../../../API/axiosNeatex";
import SkeletonLoader from "../../../../components/Shared/SkeletonLoader/SkeletonLine";

const TypeL = ({ item, handleSelectChange , value}) => {
  const {  i18n } = useTranslation();
 
  const [lookupList, setLookupList] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      async function GetlookupList() {
        try {
          const { data } = await ntxAPI.get(
            `Lookup/GetByTypes?LookupTypes=${item?.lookupType}`
          );
          let DataList = data?.map((item, idx) =>
            item.list?.map((list) => ({
              value: list.lookupCode,
              label: i18n.language === "ar" ? list.meaning2 : list.meaning,
            })).flat()
          );
  
          setLookupList(DataList[0]);
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
          setIsLoading(false);
        }
      }
  
      GetlookupList();
    }, [item.lookupType]);
    

  return (
    <>
      <div className="form-group mb-3">
        <label className="form-label">
        {i18n.language === "ar" ? item.lableText2 : item.lableText}
        </label>

        <div className="row">
                  {isLoading ? ( 
          <SkeletonLoader/>
        ) : (
           <Select
          classNamePrefix="react-select"
          isSearchable={true}
          options={lookupList}
          name={item.dynamic_form_column_id }
          required={item.requiredFlag}
          onChange={handleSelectChange}
          placeholder={i18n.language === "ar" ? "إختر" : "Choose"}
          // defaultValue={valueSetList?.find(f => f.value === value)}
          value={lookupList?.find(
            (f) => f.value === value
          )}
        />
  
      
        )}
        </div>



      </div>
    </>
  );
};

export default TypeL;
