import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import useGetPersons from '../../../hooks/HR/use-get-persons';
import useGetDynamicForm from '../../../hooks/Forms/useGetDynamicForm';
import { ntxAPI } from '../../../API/axiosNeatex';
import { Card, CardBody } from '../../../components/card/card';
import Breadcrumb from '../../../components/Shared/Breadcrumb';
import Pagination from '../../../components/Shared/Pagination/Pagination';
// import ModalComponent from '../../../components/Modal/ModalComponent';
import OnlyViewRequests from '../ViewRequests/OnlyViewRequests';
import { OptionStatus } from './OptionStatus';
import Loading from '../../../components/Shared/Loader/Loading';
import NeatixModal from '../../../components/Modal/NeatixModal';

const AllRequests = () => {
  const [show, setShow] = useState(false);
  const { dataEmployees, loading } = useGetPersons();
  const { DynamicFormAme, loading: loadingform } = useGetDynamicForm();
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [transactionHistoryId, setTransactionHistoryId] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const [filterData, setFilterData] = useState([]);
  const [stateFilter, setStateFilter] = useState<any>({
    personId: null,
    dynamicFormId: null,
    status: null,
    startDate: null,
    endDate: null,
  });

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filterData?.slice(indexOfFirstData, indexOfLastData);

  // const handleGetRequestByFilter = async () => {
  //   setIsLoading(true);
  //   setCurrentPage(1);
  //   setFilterData([]);
  //   const { data } = await ntxAPI.post('AmeRequest/GetRequestListByFeltr', stateFilter);
  //   if (data?.succeeded === true) {
  //     setIsLoading(false);
  //     setFilterData(data?.data);
  //   } else {
  //     setIsLoading(false);
  //     setFilterData([]);
  //   }
  // };
  const handleGetRequestByFilter = async () => {
    try {
      setIsLoading(true);
      setCurrentPage(1);
      setFilterData([]);
  
      const { data } = await ntxAPI.post('AmeRequest/GetRequestListByFeltr', stateFilter);
  
      if (data?.succeeded) {
        setFilterData(data?.data || []);
      } else {
        console.error("Failed to fetch filtered requests:", data?.message);
        setFilterData([]);
      }
    } catch (error) {
      console.error("An error occurred while fetching requests:", error);
      setFilterData([]); // إعادة تعيين البيانات في حالة الخطأ
    } finally {
      setIsLoading(false);
    }
  };

  //________________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: t('RequestsTracking'),
      link: null,
    },
  ];

  return (
    <div>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName={t('RequestsTracking')} />

      <Loading loading={loading || loadingform}>
        <Card className="p-2">
          <CardBody>
            <div className="row px-2">
              <div className="col-lg-7 text-end mb-2">
                <div className="input-group flex-nowrap mb-3">
                  <span className="input-group-text"> {t('EmployeeName')} </span>
                  <Select
                    classNamePrefix="react-select"
                    className="text-center w-100"
                    isLoading={loading}
                    isSearchable={true}
                    isClearable
                    options={dataEmployees}
                    placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                    filterOption={(option: any, inputValue) => {
                      if (option.data.employeeNumber) {
                        const employeeNumber = option?.data?.employeeNumber
                          ?.toString()
                          ?.toLowerCase();
                        return employeeNumber?.includes(inputValue?.toLowerCase());
                      }
                      return true;
                    }}
                    onChange={(option: any) =>
                      setStateFilter({
                        ...stateFilter,
                        personId: option === null ? null : option.value,
                      })
                    }
                  />
                </div>
              </div>

              <div className="col-lg-5">
                <div className="input-group flex-nowrap mb-3">
                  <span className="input-group-text">{t('Status')} </span>

                  <Select
                    classNamePrefix="react-select"
                    className="text-center w-100"
                    isLoading={loading}
                    isSearchable={true}
                    isClearable
                    options={OptionStatus}
                    placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                    onChange={(option: any) =>
                      setStateFilter({
                        ...stateFilter,
                        status: option === null ? null : option.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>

            <div className="row px-2">
              <div className="col-lg-4 ">
                <div className="input-group flex-nowrap mb-3">
                  <span className="input-group-text"> {t('TypeOfRequest')} </span>

                  <Select
                    classNamePrefix="react-select"
                    className="text-center w-100"
                    isLoading={loading}
                    isSearchable={true}
                    isClearable
                    options={DynamicFormAme?.map((d: any) => ({
                      value: d.id,
                      label: i18n.language === 'en' ? d.name : d.name2,
                      icon: <i className={d.icon + ' text-theme fs-4'}></i>,
                    }))}
                    placeholder={i18n.language === 'ar' ? 'إختر' : 'Choose'}
                    onChange={(option: any) =>
                      setStateFilter({
                        ...stateFilter,
                        dynamicFormId: option === null ? null : option.value,
                      })
                    }
                    formatOptionLabel={(option) => (
                      <div className="d-flex align-items-center py-1px ">
                        {option.icon}
                        <span className="ms-2">{option.label}</span>
                      </div>
                    )}
                  />
                </div>
              </div>

              <div className="col-lg-3">
                <div className="input-group flex-nowrap mb-3">
                  <span className="input-group-text">{t('From')} </span>

                  <input
                    type="date"
                    className="form-control text-center"
                    value={stateFilter.startDate || ''}
                    onChange={(e) =>
                      setStateFilter({
                        ...stateFilter,
                        startDate: e.target.value === '' ? null : e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col-lg-3">
                <div className="input-group flex-nowrap mb-3">
                  <span className="input-group-text"> {t('To')} </span>
                  <input
                    type="date"
                    className="form-control text-center"
                    value={stateFilter.endDate || ''}
                    onChange={(e) =>
                      setStateFilter({
                        ...stateFilter,
                        endDate: e.target.value === '' ? null : e.target.value,
                      })
                    }
                  />
                </div>
              </div>

              <div className="col-lg-2 text-end ">
                <button
                  type="button"
                  className="btn btn-outline-theme"
                  onClick={handleGetRequestByFilter}>
                  {isLoading ? (
                    <div className="spinner-border spinner-border-sm me-4"></div>
                  ) : (
                    <i className="fas fa-lg fa-fw bi bi-funnel me-3"></i>
                  )}
                  {t('ViewRequests')}
                </button>
              </div>
            </div>

            {filterData?.length > 0 && (
              <Fragment>
                <div className="table-responsive text-center rounded-2">
                  <table className="table text-nowrap">
                    <thead className="bg-inverse bg-opacity-25  p-1 mb-1">
                      <tr>
                        <th scope="col">{t('Name')}</th>
                        <th scope="col">{t('TypeOfRequest')}</th>
                        <th scope="col">{t('Date')}</th>
                        <th scope="col">{t('Status')}</th>
                        <th scope="col"> </th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentData?.map((item: any, index) => (
                        <tr key={item.transectionHistoryId}>
                          <td className="align-middle">
                            {i18n.language === 'en' ? item.personName : item.personName2}
                          </td>
                          <td className="align-middle">
                            {i18n.language === 'en' ? item.requestName : item.requestName2}
                          </td>

                          <td className="align-middle">{item.dateCreated}</td>

                          <td className="align-middle">
                            {item.status === 'COMPLETED' ? (
                              <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
                                COMPLETED
                              </span>
                            ) : item.status === 'IN PROGRESS' ? (
                              <span className="badge border border-primary text-primary px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
                                IN PROGRESS
                              </span>
                            ) : (
                              ''
                            )}
                          </td>

                          <td className="align-middle text-end">
                            {/* <button
                              className="btn me-1 btn-outline-theme"
                              data-bs-toggle="modal"
                              data-bs-target="#modalViewRequests"
                              onClick={() => setTransactionHistoryId(item.transectionHistoryId)}>
                              <i className="fas fa-lg fa-fw fa-info-circle me-2"></i>
                              {t('ViewRequest')}
                            </button> */}
                            <button
                              className="btn me-1 btn-outline-theme"
                              onClick={() => {
                                setTransactionHistoryId(item.transectionHistoryId);
                                setShow(true);
                              }}>
                              <i className="fas fa-lg fa-fw fa-info-circle me-2"></i>
                              {t('ViewRequest')}
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <Pagination
                  filteredData={filterData}
                  dataPerPage={dataPerPage}
                  handlePagination={(pageNumber: any) => setCurrentPage(pageNumber)}
                  currentPage={currentPage}
                />
              </Fragment>
            )}
          </CardBody>
        </Card>
      </Loading>

      {/* <ModalComponent id="modalViewRequests" title={t('ViewRequestDetails')}>
        {transactionHistoryId && <OnlyViewRequests TransactionHistoryId={transactionHistoryId} />}
      </ModalComponent> */}

      <NeatixModal
        show={show}
        setShow={setShow}
        size="xl"
        bodyClassName="p-0"
        showHeader={true}
        // title="عرض الطلبات"
      >
        {transactionHistoryId && <OnlyViewRequests TransactionHistoryId={transactionHistoryId} />}
      </NeatixModal>
    </div>
  );
};

export default AllRequests;
