import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Card, CardBody } from '../../../../components/card/card.jsx';
import { getPayrolls } from '../../../../store/HR/Payroll/PayrollSlice.js';
import { useTranslation } from 'react-i18next';
import Pagination from '../../../../components/Shared/Pagination/Pagination.jsx';
import Loader from '../../../../components/Shared/Loader/Loader.js';
import ModalComponent from '../../../../components/Modal/ModalComponent.jsx';
import { AddPayroll } from './AddPayroll.js';
import { usePermissions } from '../../../../Helper/Permisstions/usePermissions';
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import Loading from '../../../../components/Shared/Loader/Loading';

const Payroll = () => {
  const { HasPermission } = usePermissions();

  const [Show, setShow] = useState(false);
  const { t, i18n } = useTranslation();
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);

  const Navigate = useNavigate();
  const dispatch = useDispatch();
  /// Get all Payrolls from store
  const { Payrolls, loading, error } = useSelector((state) => state.Payrolls);
  useEffect(() => {
    if (Payrolls?.length === 0) {
      dispatch(getPayrolls());
    }
  }, [dispatch]);

  const filteredSearch = Payrolls?.slice()?.filter((item) => {
    const searchLowerCase = search.toLowerCase();
    return (
      item.name?.toLowerCase().includes(searchLowerCase) ||
      item.name2?.toLowerCase().includes(searchLowerCase)
    );
  });

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: t('Payrolls'),
      link: null,
    },
  ];

  return (
    <>
      <div className="d-flex align-items-center">
        <Breadcrumb BreadcrumbList={BcrumbList} PageName={t('Payrolls')} />
        {HasPermission('PayAddPayroll') && (
          <div className="ms-auto">
            <Link
              to=""
              className="btn btn-theme me-1"
              data-bs-toggle="modal"
              data-bs-target="#modalAddPayroll"
              onClick={() => setShow(true)}
              >
              <i className="fa fa-plus-circle fa-fw me-1"></i>
              {t('AddPayroll')}
            </Link>
          </div>
        )}
      </div>
      <Card>
        <CardBody>
       
          <Loading loading={loading} error={error}>
            <div className="tab-content p-2">
              <div className="row">
                <div className="col-7 col-md-6 d-flex justify-content-start">
                  <div className="input-group mb-2">
                    <div className="flex-fill position-relative">
                      <div className="input-group">
                        <div
                          className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                          style={{ zIndex: 1020, right: '10px' }}>
                          <i className="fa fa-search opacity-5"></i>
                        </div>
                        <input
                          type="text"
                          className="form-control ps-35px"
                          onChange={(e) => setSearch(e.target.value)}
                          placeholder={t('Search') + ' ...'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* //  START TABLE   */}
              <div className="table-responsive">
                <table className="table table-hover text-nowrap">
                  <thead className="table-dark">
                    <tr>
                      <th scope="col">{t('Name')}</th>
                      <th scope="col">{t('TypeOfDuration')}</th>

                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData?.map((item, index) => (
                      <tr key={item.id}>
                        <td className="align-middle">
                          {i18n.language === 'ar' ? item.name2 : item.name}
                        </td>
                        <td className="align-middle">
                          {item.periodType === 'M' ? 'شهري' : 'أسبوعي'}
                        </td>
                        <td className="text-end">
                          <div className="btn-group">
                            {HasPermission('PayElementPayrollLink') && (
                              <button
                                type="button"
                                onClick={() => Navigate(`${item.id}/ElementLink`)}
                                className="btn btn-outline-theme">
                                <i className="fas fa-lg fa-fw me-2 fa-link"></i>
                                {t('LinkPayrollElements')}
                              </button>
                            )}
                            {HasPermission('PayEditPayroll') && (
                              <button type="button" className="btn btn-outline-theme">
                                <i className="far fa-lg fa-fw me-2 fa-edit"></i>
                                {t('Button.Edit')}
                              </button>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Pagination
                filteredData={filteredSearch}
                dataPerPage={dataPerPage}
                handlePagination={(pageNumber) => setCurrentPage(pageNumber)}
                currentPage={currentPage}
              />

              {/* </CardBody> */}
            </div>
          </Loading>
        </CardBody>
      </Card>
      <div className="modal fade" id="modalِAPayroll">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{t('AddPayroll')}</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
            </div>
            <div className="modal-body"></div>
          </div>
        </div>
      </div>

      <ModalComponent id="modalAddPayroll" title={t('AddPayroll')}>
        {Show && <AddPayroll />}
      </ModalComponent>
    </>
  );
};

export default Payroll;
