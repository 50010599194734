import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import {
  GetSubinventoryByParentId,
  UpdatSubinventory,
} from '../../../../../store/INV/SubInventory/SubInventorySlice';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CustomAlert from '../../../../../components/Shared/Alert/CustomAlert';
import { SubInventoryModels } from '../../../../../store/INV/SubInventory/SubInventoryModels';

interface props {
  subInventoryDetails: SubInventoryModels;
}

const EditSubInventory = ({ subInventoryDetails }: props) => {
  const Navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  // console.log(subInventoryDetails);

  // const [subInventory, setSubInventory] = useState<SubInventoryModels>();
  // const { SubInventoryListByParentId } = useAppSelector(
  //   (state) => state.SubInventory
  // );

  // useEffect(() => {
  //   const dataById = SubInventoryListByParentId?.find((f) => f.id === Id);

  //   setSubInventory(dataById);
  // }, [Id, SubInventoryListByParentId]);

  const formik = useFormik({
    initialValues: {
      id: subInventoryDetails ? subInventoryDetails.id : '',
      name: subInventoryDetails ? subInventoryDetails.name : '',
      name2: subInventoryDetails ? subInventoryDetails.name2 : '',
      code: subInventoryDetails ? subInventoryDetails.code : '',
      parentId: subInventoryDetails ? subInventoryDetails.parentId : '',
      description: subInventoryDetails ? subInventoryDetails.description : '',
      subinventoryParentId: subInventoryDetails ? subInventoryDetails.subinventoryParentId : '',
    },
    // validationSchema:  ,
    enableReinitialize: true,
    onSubmit: (values) => {
      dispatch(
        UpdatSubinventory({
          id: values.id,
          name: values.name,
          name2: values.name2,
          code: values.code,
          parentId: values.parentId,
          description: values.description,
          subinventoryParentId: values.subinventoryParentId,
        })
      )
        .unwrap()
        .then((res) => {
          if (res.succeeded) {
            dispatch(GetSubinventoryByParentId(subInventoryDetails?.parentId || ''));

            CustomAlert({ action: 'Edit' });
            // document.getElementById('close')?.click();
            formik.resetForm();
            formik.setSubmitting(false);
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error' });
          }
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });

  return (
    <div>
      <Form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-xl-6">
            <div className="form-group mb-3">
              <label className="form-label">{t('LocalName')}</label>
              <Form.Control
                className="form-control"
                type="text"
                name="name2"
                onChange={formik.handleChange}
                value={formik.values.name2 || ''}
                isInvalid={!!(formik.touched.name2 && formik.errors.name2)}
              />
              <Form.Control.Feedback type="invalid">{formik.errors.name2}</Form.Control.Feedback>
            </div>
          </div>

          <div className="col-xl-6">
            <div className="form-group mb-3">
              <label className="form-label">{t('GlobalName')}</label>
              <Form.Control
                className="form-control"
                type="text"
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name || ''}
                isInvalid={!!(formik.touched.name && formik.errors.name)}
              />
              <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="form-group mb-3">
              <label className="form-label">{t('Code')}</label>
              <Form.Control
                className="form-control"
                type="text"
                name="code"
                onChange={formik.handleChange}
                value={formik.values.code || ''}
                isInvalid={!!(formik.touched.code && formik.errors.code)}
              />
              <Form.Control.Feedback type="invalid">{formik.errors.code}</Form.Control.Feedback>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="form-group mb-3">
              <label className="form-label">{t('Note')}</label>
              <Form.Control
                className="form-control"
                as="textarea"
                rows={2}
                name="description"
                onChange={formik.handleChange}
                value={formik.values.description || ''}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.description}
              </Form.Control.Feedback>
            </div>
          </div>
        </div>

        <div className="text-center">
          <button
            disabled={formik.isSubmitting}
            type="submit"
            className="btn me-1 btn-theme mb-1"
            id="SaveSubmit">
            {formik.isSubmitting ? (
              <div className="spinner-border spinner-border-sm me-2"></div>
            ) : (
              <i className="fa fa-save fa-fw me-1"></i>
            )}
            {t('Save')}
          </button>
        </div>
      </Form>
    </div>
  );
};

export default EditSubInventory;
