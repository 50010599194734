import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Card, CardBody, CardHeader } from '../../../../../components/card/card';
import Breadcrumb from '../../../../../components/Shared/Breadcrumb';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
  GetMasterItemsById,
  UpdatMasterItems,
  cleanUpMasterItemsById,
} from '../../../../../store/INV/MasterItems/MasterItemsSlice';
import { useFormik } from 'formik';
import CustomAlert from '../../../../../components/Shared/Alert/CustomAlert';
import { Form } from 'react-bootstrap';
import useGetCategoriesList from '../../../../../hooks/INV/useGetCategoriesList';
import useGetUnitsOfMeasureList from '../../../../../hooks/INV/useGetUnitsOfMeasureList';
import ItemConversion from './ItemConversion';
import useLookupGetItemType from '../../../../../hooks/Lookups/use-Lookup-GetItemType';
import Loading from '../../../../../components/Shared/Loader/Loading';
import AssignmentItemToMultiInventories from './AssignmentItemToMultiInventories';
import { MasterItemsSchema } from '../../../../ValidationForm/validationSchema';
import LotItem from './LotItems/LotItem';
import AlternativeItems from './AlternativeItems';

const MasterItemsDetails = () => {
  const { t, i18n } = useTranslation();
  const { CategoriesList } = useGetCategoriesList();
  const dispatch = useAppDispatch();
  const { ItemId } = useParams();

  const { UnitsOfMeasureList } = useGetUnitsOfMeasureList();
  const { ITEM_TYPE } = useLookupGetItemType();

  useEffect(() => {
    if (ItemId) {
      dispatch(GetMasterItemsById(ItemId));
    }
    return () => {
      dispatch(cleanUpMasterItemsById());
    };
  }, []);
  const { MasterItemsById, loading, error } = useAppSelector((a) => a.MasterItemsINV);

  const formik = useFormik({
    initialValues: {
      id: MasterItemsById ? MasterItemsById.id : null,
      itemCode: MasterItemsById ? MasterItemsById.itemCode : '',
      barcode: MasterItemsById ? MasterItemsById.barcode : '',
      name: MasterItemsById ? MasterItemsById.name : '',
      name2: MasterItemsById ? MasterItemsById.name2 : '',
      categoryId: MasterItemsById ? MasterItemsById.categoryId : '',
      // typeId: MasterItemsById ? MasterItemsById.typeId : "",

      displayName: MasterItemsById ? MasterItemsById.displayName : '',
      displayName2: MasterItemsById ? MasterItemsById.displayName2 : '',
      itemType: MasterItemsById ? MasterItemsById.itemType : '',
      serviceItemFlag: MasterItemsById ? MasterItemsById.serviceItemFlag : false,
      isActive: MasterItemsById ? MasterItemsById.isActive : false,
      purchasingEnabledFlag: MasterItemsById ? MasterItemsById.purchasingEnabledFlag : false,
      customerOrderEnabledFlag: MasterItemsById ? MasterItemsById.customerOrderEnabledFlag : false,
      internalOrderEnabledFlag: MasterItemsById ? MasterItemsById.internalOrderEnabledFlag : false,
      soTransactionsFlag: MasterItemsById ? MasterItemsById.soTransactionsFlag : false,
      invTransactionsEnabledFlag: MasterItemsById
        ? MasterItemsById.invTransactionsEnabledFlag
        : false,
      salesPrice: MasterItemsById ? MasterItemsById.salesPrice : '',
      purchasePrice: MasterItemsById ? MasterItemsById.purchasePrice : '',
      unitOfMeasure: MasterItemsById ? MasterItemsById.unitOfMeasure : '',
      image: MasterItemsById ? MasterItemsById.image : '',
      demandTimeUp: MasterItemsById ? MasterItemsById.demandTimeUp : '',
      demandTimeDown: MasterItemsById ? MasterItemsById.demandTimeDown : '',
      description: MasterItemsById ? MasterItemsById.description : '',
      discountRate: MasterItemsById ? MasterItemsById.discountRate : '',
      authorizeNegativeStock: MasterItemsById ? MasterItemsById.authorizeNegativeStock : false,
    },
    enableReinitialize: true,
    validationSchema: MasterItemsSchema(t),
    onSubmit: (values) => {
      dispatch(
        UpdatMasterItems({
          id: values.id,
          itemCode: values.itemCode,
          barcode: values.barcode,
          name: values.name,
          name2: values.name2,
          categoryId: values.categoryId,
          itemType: values.itemType,
          serviceItemFlag: values.serviceItemFlag,
          isActive: values.isActive,
          purchasingEnabledFlag: values.purchasingEnabledFlag,
          customerOrderEnabledFlag: values.customerOrderEnabledFlag,
          internalOrderEnabledFlag: values.internalOrderEnabledFlag,
          soTransactionsFlag: values.soTransactionsFlag,
          invTransactionsEnabledFlag: values.invTransactionsEnabledFlag,
          salesPrice: values.salesPrice,
          purchasePrice: values.purchasePrice,
          unitOfMeasure: values.unitOfMeasure,
          image: values.image,
          demandTimeUp: values.demandTimeUp,
          demandTimeDown: values.demandTimeDown,
          description: values.description,
          discountRate: values.discountRate,
          authorizeNegativeStock: values.authorizeNegativeStock,

          displayName: values.displayName,
          displayName2: values.displayName2,
        })
      )
        .unwrap()
        .then((res) => {
          if (res.succeeded === true) {
            formik.resetForm();
            CustomAlert({ action: 'Edit' });
            formik.setSubmitting(false);
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: 'Error' });
          }
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: 'Error' });
        });
    },
  });

  //________________________________
  var BcrumbList = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'الأصناف',
      link: '/INV/InventorySettings/MasterItems',
    },
    {
      name: 'تفاصيل الصنف',
      link: null,
    },
  ];

  return (
    <>
      <div className="d-flex align-items-center">
        <Breadcrumb
          BreadcrumbList={BcrumbList}
          PageName={t('تفاصيل الصنف')}
          subtitle={i18n.language === 'en' ? MasterItemsById.name : MasterItemsById.name2}
        />
      </div>

      <Loading loading={loading} error={error}>
        <>
          <div className="row gx-4">
            <div className="col-lg-12">
              <Card className="mb-4">
                {/* <CardHeader className="d-flex align-items-center justify-content-between bg-inverse bg-opacity-10 fw-400"> */}
                <CardHeader className="d-flex align-items-center justify-content-between fw-bold small bg-theme bg-opacity-25">

                  <div className="fw-bold">
                    <i className="fas fa-info-circle me-2"></i>
                    تفاصيل المنتج
                  </div>

                  <div>
                    <Link to="" className="btn m-0 btn-theme " onClick={() => formik.submitForm()}>
                      {formik.isSubmitting ? (
                        <div className="spinner-border spinner-border-sm me-2"></div>
                      ) : (
                        <i className="fa fa-save fa-fw me-1"></i>
                      )}
                      {t('Save')}
                    </Link>
                  </div>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={formik.handleSubmit}>
                    <div className="row">
                      <div className="col-xl-9">
                        <div className="row">
                          <div className="col-xl-4">
                            <div className="form-group mb-3">
                              <label className="form-label">{t('LocalName')}</label>
                              <Form.Control
                                className="form-control "
                                type="text"
                                name="name2"
                                onChange={formik.handleChange}
                                value={formik.values.name2}
                                isInvalid={!!(formik.touched.name2 && formik.errors.name2)}
                              />
                              <Form.Control.Feedback type="invalid">
                                {formik.errors.name2}
                              </Form.Control.Feedback>
                            </div>
                          </div>

                          <div className="col-xl-4">
                            <div className="form-group mb-3">
                              <label className="form-label">{t('GlobalName')}</label>
                              <Form.Control
                                className="form-control "
                                type="text"
                                name="name"
                                onChange={formik.handleChange}
                                value={formik.values.name}
                                isInvalid={!!(formik.touched.name && formik.errors.name)}
                              />
                              <Form.Control.Feedback type="invalid">
                                {formik.errors.name}
                              </Form.Control.Feedback>
                            </div>
                          </div>

                          <div className="col-xl-4">
                            <div className="form-group mb-3">
                              <label className="form-label">{t('Barcode')}</label>
                              <Form.Control
                                className="form-control "
                                type="text"
                                name="barcode"
                                onChange={formik.handleChange}
                                value={formik.values.barcode}
                                isInvalid={!!(formik.touched.barcode && formik.errors.barcode)}
                              />
                              <Form.Control.Feedback type="invalid">
                                {formik.errors.barcode}
                              </Form.Control.Feedback>
                            </div>
                          </div>

                          <div className="col-xl-4">
                            <div className="form-group mb-3">
                              <label className="form-label">{t('كود الصنف (SKU)')}</label>
                              <Form.Control
                                className="form-control "
                                type="text"
                                name="itemCode"
                                onChange={formik.handleChange}
                                value={formik.values.itemCode}
                                isInvalid={!!(formik.touched.itemCode && formik.errors.itemCode)}
                              />
                              <Form.Control.Feedback type="invalid">
                                {formik.errors.itemCode}
                              </Form.Control.Feedback>
                            </div>
                          </div>

                          <div className="col-xl-4">
                            <div className="form-group mb-3">
                              <label className="form-label"> {t('فئة الصنف')}</label>
                              <Form.Select
                                className="form-select"
                                name="categoryId"
                                onChange={formik.handleChange}
                                value={formik.values.categoryId || ''}
                                isInvalid={
                                  !!(formik.touched.categoryId && formik.errors.categoryId)
                                }>
                                <option value="">
                                  {i18n.language === 'ar' ? 'إختر' : 'Choose'}
                                </option>
                                {CategoriesList &&
                                  CategoriesList?.map((item, idx) => (
                                    <option key={++idx} value={item.id}>
                                      {i18n.language === 'en' ? item.name : item.name2}
                                    </option>
                                  ))}
                              </Form.Select>
                            </div>
                          </div>

                          <div className="col-xl-4">
                            <div className="form-group mb-3">
                              <label className="form-label">{t('نوع الصنف')}</label>
                              <Form.Select
                                className="form-select"
                                name="itemType"
                                onChange={formik.handleChange}
                                value={formik.values.itemType}
                                isInvalid={!!(formik.touched.itemType && formik.errors.itemType)}>
                                <option value="">
                                  {i18n.language === 'ar' ? 'إختر' : 'Choose'}
                                </option>
                                {ITEM_TYPE &&
                                  ITEM_TYPE?.map((item: any, idx: number) =>
                                    item.list?.map((list: any) => (
                                      <option key={++idx} value={list.lookupCode}>
                                        {i18n.language === 'ar' ? list.meaning2 : list.meaning}
                                      </option>
                                    ))
                                  )}
                              </Form.Select>
                            </div>
                          </div>

                          <div className="col-xl-4">
                            <div className="form-group mb-3">
                              <label className="form-label">{t('وحدة القياس')}</label>
                              <Form.Select
                                className="form-select"
                                name="unitOfMeasure"
                                onChange={formik.handleChange}
                                value={formik.values.unitOfMeasure || ''}
                                isInvalid={
                                  !!(formik.touched.unitOfMeasure && formik.errors.unitOfMeasure)
                                }>
                                <option value="">
                                  {i18n.language === 'ar' ? 'إختر' : 'Choose'}
                                </option>
                                {UnitsOfMeasureList &&
                                  UnitsOfMeasureList?.map((item, idx) => (
                                    <option key={++idx} value={item.id}>
                                      {i18n.language === 'en' ? item.name : item.name2}
                                    </option>
                                  ))}
                              </Form.Select>
                            </div>
                          </div>

                          <div className="col-xl-4">
                            <div className="form-group mb-3">
                              <label className="form-label">{t('سعر البيع')}</label>
                              <Form.Control
                                className="form-control "
                                type="text"
                                name="salesPrice"
                                onChange={formik.handleChange}
                                value={formik.values.salesPrice}
                                isInvalid={
                                  !!(formik.touched.salesPrice && formik.errors.salesPrice)
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {formik.errors.salesPrice}
                              </Form.Control.Feedback>
                            </div>
                          </div>

                          <div className="col-xl-4">
                            <div className="form-group mb-3">
                              <label className="form-label">{t('سعر الشراء')}</label>
                              <Form.Control
                                className="form-control "
                                type="text"
                                name="purchasePrice"
                                onChange={formik.handleChange}
                                value={formik.values.purchasePrice}
                                isInvalid={
                                  !!(formik.touched.purchasePrice && formik.errors.purchasePrice)
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {formik.errors.purchasePrice}
                              </Form.Control.Feedback>
                            </div>
                          </div>

                          <div className="col-xl-4">
                            <div className="form-group mb-3">
                              <label className="form-label">{t('معدل الخصم')}</label>
                              <Form.Control
                                className="form-control "
                                type="text"
                                name="discountRate"
                                onChange={formik.handleChange}
                                value={formik.values.discountRate}
                                isInvalid={
                                  !!(formik.touched.discountRate && formik.errors.discountRate)
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {formik.errors.discountRate}
                              </Form.Control.Feedback>
                            </div>
                          </div>

                          <div className="col-xl-4">
                            <div className="form-group mb-3">
                              <label className="form-label">{t('حد الطلب الأعلى')}</label>
                              <Form.Control
                                className="form-control "
                                type="text"
                                name="demandTimeUp"
                                onChange={formik.handleChange}
                                value={formik.values.demandTimeUp}
                                isInvalid={
                                  !!(formik.touched.demandTimeUp && formik.errors.demandTimeUp)
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {formik.errors.demandTimeUp}
                              </Form.Control.Feedback>
                            </div>
                          </div>

                          <div className="col-xl-4">
                            <div className="form-group mb-3">
                              <label className="form-label">{t('حد الطلب الأدنى')}</label>
                              <Form.Control
                                className="form-control "
                                type="text"
                                name="demandTimeDown"
                                onChange={formik.handleChange}
                                value={formik.values.demandTimeDown}
                                isInvalid={
                                  !!(formik.touched.demandTimeDown && formik.errors.demandTimeDown)
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {formik.errors.demandTimeDown}
                              </Form.Control.Feedback>
                            </div>
                          </div>

                          <div className="col-xl-4">
                            <div className="form-group mb-3">
                              <label className="form-label">{t('Note')}</label>
                              <Form.Control
                                className="form-control "
                                type="text"
                                name="description"
                                onChange={formik.handleChange}
                                value={formik.values.description}
                                isInvalid={
                                  !!(formik.touched.description && formik.errors.description)
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {formik.errors.description}
                              </Form.Control.Feedback>
                            </div>
                          </div>

                          {(formik.values.soTransactionsFlag ||
                            formik.values.customerOrderEnabledFlag) && (
                            <>
                              <div className="col-xl-4">
                                <div className="form-group mb-3">
                                  <label className="form-label">
                                    {t('الأسم المحلي للعرض بالفاتورة')}
                                  </label>
                                  <Form.Control
                                    autoComplete="off"
                                    className="form-control "
                                    type="text"
                                    name="displayName2"
                                    onChange={formik.handleChange}
                                    value={formik.values.displayName2}
                                    isInvalid={
                                      !!(formik.touched.displayName2 && formik.errors.displayName2)
                                    }
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {formik.errors.displayName2}
                                  </Form.Control.Feedback>
                                </div>
                              </div>
                              <div className="col-xl-4">
                                <div className="form-group mb-3">
                                  <label className="form-label">
                                    {t('الأسم العالمي للعرض بالفاتورة')}
                                  </label>
                                  <Form.Control
                                    autoComplete="off"
                                    className="form-control "
                                    type="text"
                                    name="displayName"
                                    onChange={formik.handleChange}
                                    value={formik.values.displayName}
                                    isInvalid={
                                      !!(formik.touched.displayName && formik.errors.displayName)
                                    }
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {formik.errors.displayName}
                                  </Form.Control.Feedback>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>

                      <div className="col-xl-3">
                        <CardBody>
                          <div className="p-3 bg-white bg-opacity-10 ">
                            <div className="form-group mb-0">
                              <div className="shipping-container">
                                <div className="row align-items-center">
                                  <div className="col-6 pt-1 pb-1">{t('Active')}</div>
                                  <div className="col-6 d-flex align-items-center">
                                    <div className="form-check form-switch ms-auto">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        name="isActive"
                                        checked={formik.values.isActive}
                                        onChange={formik.handleChange}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="shippingAliExpress">
                                        &nbsp;
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <hr className="mt-2 mb-2" />
                                <div className="row align-items-center">
                                  <div className="col-6 pt-1 pb-1">صنف خدمي</div>
                                  <div className="col-6 d-flex align-items-center">
                                    <div className="form-check form-switch ms-auto">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="serviceItemFlag"
                                        checked={formik.values.serviceItemFlag}
                                        onChange={formik.handleChange}
                                      />
                                      <label className="form-check-label" htmlFor="shippingFree">
                                        &nbsp;
                                      </label>
                                    </div>
                                  </div>
                                </div>

                                <hr className="mt-2 mb-2" />
                                <div className="row align-items-center">
                                  <div className="col-6 pt-1 pb-1">invTransactionsEnabledFlag</div>
                                  <div className="col-6 d-flex align-items-center">
                                    <div className="form-check form-switch ms-auto">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="invTransactionsEnabledFlag"
                                        checked={formik.values.invTransactionsEnabledFlag}
                                        onChange={formik.handleChange}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="shippingSunriseWholesale">
                                        &nbsp;
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <hr className="mt-2 mb-2" />
                                <div className="row align-items-center">
                                  <div className="col-6 pt-1 pb-1">طلبات العملاء</div>
                                  <div className="col-6 d-flex align-items-center">
                                    <div className="form-check form-switch ms-auto">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        name="customerOrderEnabledFlag"
                                        checked={formik.values.customerOrderEnabledFlag}
                                        onChange={formik.handleChange}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="shippingMegagoods">
                                        &nbsp;
                                      </label>
                                    </div>
                                  </div>
                                </div>

                                <hr className="mt-2 mb-2" />
                                <div className="row align-items-center">
                                  <div className="col-6 pt-1 pb-1">أوامر البيع</div>
                                  <div className="col-6 d-flex align-items-center">
                                    <div className="form-check form-switch ms-auto">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        name="soTransactionsFlag"
                                        checked={formik.values.soTransactionsFlag}
                                        onChange={formik.handleChange}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="shippingSunriseWholesale">
                                        &nbsp;
                                      </label>
                                    </div>
                                  </div>
                                </div>

                                <hr className="mt-2 mb-2" />
                                <div className="row align-items-center">
                                  <div className="col-6 pt-1 pb-1">قابل للشراء</div>
                                  <div className="col-6 d-flex align-items-center">
                                    <div className="form-check form-switch ms-auto">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        name="purchasingEnabledFlag"
                                        checked={formik.values.purchasingEnabledFlag}
                                        onChange={formik.handleChange}
                                      />
                                      <label className="form-check-label" htmlFor="shippingSaleHoo">
                                        &nbsp;
                                      </label>
                                    </div>
                                  </div>
                                </div>

                                <hr className="mt-2 mb-2" />
                                <div className="row align-items-center">
                                  <div className="col-6 pt-1 pb-1">تحويل داخلي</div>
                                  <div className="col-6 d-flex align-items-center">
                                    <div className="form-check form-switch ms-auto">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        name="internalOrderEnabledFlag"
                                        checked={formik.values.internalOrderEnabledFlag}
                                        onChange={formik.handleChange}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="shippingWholesale2B">
                                        &nbsp;
                                      </label>
                                    </div>
                                  </div>
                                </div>

                                <hr className="mt-2 mb-2" />
                                <div className="row align-items-center">
                                  <div className="col-6 pt-1 pb-1">سماح البيع بالسالب</div>
                                  <div className="col-6 d-flex align-items-center">
                                    <div className="form-check form-switch ms-auto">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="authorizeNegativeStock"
                                        checked={formik.values.authorizeNegativeStock}
                                        onChange={formik.handleChange}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="shippingSunriseWholesale">
                                        &nbsp;
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </CardBody>
                      </div>
                    </div>

                    {/* <div className="text-center">
                  <button
                    disabled={formik.isSubmitting}
                    type="submit"
                    className="btn me-1 btn-theme mb-1"
                  >
                    {formik.isSubmitting ? (
                      <div className="spinner-border spinner-border-sm me-2"></div>
                    ) : (
                      <i className="fa fa-save fa-fw me-1"></i>
                    )}
                    {t("Save")}
                  </button>
                </div> */}
                  </Form>
                </CardBody>
              </Card>
            </div>
          </div>

          <div className="row gx-4">
            <div className="col-lg-8">
              <Card className="mb-4">
              <CardHeader className="d-flex align-items-center fw-bold small bg-theme bg-opacity-25">
              <i className="fas fa-boxes me-2"></i>
                  وحدات الصنف
                </CardHeader>
                <CardBody>
                  <ItemConversion uom={formik.values.unitOfMeasure} />
                </CardBody>
              </Card>
            </div>
            <div className="col-lg-4">
              <Card className="mb-4">
              <CardHeader className="d-flex align-items-center fw-bold small bg-theme bg-opacity-25">
              <i className="fas fa-warehouse me-2"></i>
                  المخزن
                </CardHeader>
                <CardBody>
                  <AssignmentItemToMultiInventories />
                </CardBody>
              </Card>
            </div>
          </div>

          <div className="row gx-4">
            <div className="col-lg-8">
              <Card className="mb-4">
              <CardHeader className="d-flex align-items-center fw-bold small bg-theme bg-opacity-25">
              <i className="fas fa-hashtag me-2"></i>
                  أرقام التشغيل  
                </CardHeader>
                <CardBody>
                  <LotItem />
                </CardBody>
              </Card>
            </div>

            <div className="col-lg-4">
              <Card className="mb-4">
                {/* <CardHeader className="d-flex align-items-center bg-inverse bg-opacity-10   fw-bold"> */}
                <CardHeader className="d-flex align-items-center fw-bold small bg-theme bg-opacity-25">
                  <i className="fas fa-retweet me-2"></i>
                  الأصناف البديـلة
                </CardHeader>
                <CardBody>
                  <AlternativeItems />
                </CardBody>
              </Card>
            </div>
            <div className="col-lg-4">
              {/* <Card className="mb-4">
                <CardHeader className="d-flex align-items-center bg-inverse bg-opacity-10   fw-bold">
                  المخزن
                </CardHeader>
                <CardBody>
                  <AssignmentItemToMultiInventories />
                </CardBody>
              </Card> */}
            </div>
          </div>
        </>
      </Loading>
    </>
  );
};

export default MasterItemsDetails;
