import React from 'react';
import Chart from 'react-apexcharts';

function BarChart({ data }) {

   var themeColor = (getComputedStyle(document.body).getPropertyValue('--bs-theme')).trim();

  
  var themeFont = getComputedStyle(document.body).getPropertyValue('--bs-body-font-family').trim();
  var themeFontWeight = getComputedStyle(document.body)
    .getPropertyValue('--bs-body-font-weight')
    .trim();
  var gray500 = getComputedStyle(document.body).getPropertyValue('--bs-gray-500').trim();
  var orange = getComputedStyle(document.body).getPropertyValue('--bs-warning').trim();
  var inverse = getComputedStyle(document.body).getPropertyValue('--bs-inverse').trim();
  var inverseRgb = getComputedStyle(document.body).getPropertyValue('--bs-inverse-rgb').trim();
  var borderColor = getComputedStyle(document.body).getPropertyValue('--bs-border-color').trim();


  if (!data || data?.length === 0) {
    console.error('No data available for the chart.');
    return null;
  }

  // bar chart
  var barChartOptions = {
    // plotOptions: { bar: { horizontal: true, dataLabels: { position: 'top' } } },
    // dataLabels: { enabled: true, offsetX: -6, style: { fontSize: '12px', colors: ['#ffffff'] } },
    // colors: [orange, gray500],
    colors: [themeColor, 'rgba('+ inverseRgb +', .5)', orange],
    stroke: { show: false },
    grid: { borderColor: borderColor },
    xaxis: {
      // categories: [2013, 2014, 2015, 2016, 2017, 2018, 2019],
      categories:  data?.map((item) => item?.orgName || "غير محدد"),
      
      axisBorder: {
        show: true,
        color: 'rgba(' + inverseRgb + ', .25)',
        height: 1,
        width: '100%',
        offsetX: 0,
        offsetY: -1,
      },
      axisTicks: {
        show: true,
        borderType: 'solid',
        color: 'rgba(' + inverseRgb + ', .25)',
        height: 6,
        offsetX: 0,
        offsetY: 0,
      },
      labels: {
        show: false,
        style: {
          colors: inverse,
          fontSize: '12px',
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: 'apexcharts-xaxis-label',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: inverse,
          fontSize: '12px',
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: 'apexcharts-xaxis-label',
        },
      },
    },
    // legend: { fontFamily: themeFont, labels: { colors: inverse } },
  };





  // var barChartData = [
  //   { data: [44, 55, 41, 64, 22, 43, 21] },
  //   { data: [53, 32, 33, 52, 13, 44, 32] }
  // ];
  var barChartData = [
    {
      name: 'موظف',
      data: data?.map((item) => item?.count || 0),
    },
  ];

  return (
    <div>
      <Chart type="bar" options={barChartOptions} series={barChartData} height='100%' />
    </div>
  );
}

export default BarChart;
